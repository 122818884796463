import "./TankLevel.css";
import ReloadPict from "../../Assets/Images/reload-pict.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import BitLamp from "../BitLamp/BitLamp";

export default function TankLevel({ text, value, wireBreak = false, shortError = false, volume }) {
    return (
        <>
            <div className="tankContainer">
                <div style={{flex: 1}}>
                    <h1>{value}%</h1>
                </div>
                <div style={{flex: 3}}>
                    <h5>{text}</h5>
                    <ProgressBar striped variant="info" now={value} />
                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                        <h6>Volume M3 {volume}</h6>
                        <BitLamp text={'Wire Break'} bitlampColor={wireBreak}/>
                    </div>
                </div>
            </div>
            
        </>
    );
}