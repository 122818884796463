// import "../../../SMSApp.css";
import "./index.css";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { isTokenValid, findAllPumpMotor, getSMSCoordinateByCoordinateType, getPLCReadingValueForMotorPump } from "../../../Helpers/ApplicationHelpers";
import TankLevel from "../../../Components/TankLevel/TankLevel";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/LoadingForSMSDashboard/Loading";
import DropDownForm from "../../../Components/DropDownForm";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import moment from "moment";
import Select from 'react-select';

export default function CustomerSMSPumpMotorPage(props) {
    const { smsSerialNumber, vesselId, coordinateType, idCoordinateGroup } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listPumpMotor, setListPumpMotor] = useState([]);
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [coordinateList, setCoordinateList] = useState([]);
    const [listCoordinateValueLastResponse, setListCoordinateValueLastResponse] = useState([]);
    const [listConvertedPumpMotor, setListConvertedPumpMotor] = useState();
    const [dataToShow, setDataToShow] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [selectedPumpMotor, setSelectedPumpMotor] = useState({
        coordinate_desc: "",
        coordinate_type: "",
        coordinate_number: ""
    });

    const [dataToRender, setDataToRender] = useState([]);

    const durationOption = [
        {
            label: "1 Jam",
            value: 1,
        },
        {
            label: "1 Hari",
            value: 2,
        },
        {
            label: "1 Minggu",
            value: 3,
        },
    ];
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    useEffect(() => {
        // loadCoordinate();
        loadPumpMotorOption();
        loadReadingValue();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadReadingValue();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // console.log(selectedPumpMotor);
    }, [selectedPumpMotor])

    useEffect(() => {
        // console.log(dataToRender);
        
    }, [dataToRender])

    useEffect(() => {
        console.log('dataToShow', dataToShow);
        // setLoading(false);
        if (dataToShow) {
            let dataToRender = [];
            let idx = 0;
            dataToShow.forEach(element => {
                let x = <div className="containerForDynamicLineChart" key={idx}>
                        <DynamicLineChart
                            graphTitle={element ? element.coordinate_desc : ''}
                            dataToShow={element.value}
                            minValue={0}
                            maxValue={100}
                            stepSize={50}
                            wireBreak={element.isWireBreak}
                            thermalOverload={element.thermalOverload[0] ? element.thermalOverload[0].reading_value : 'false'}
                            runningHour={(element.value[0].total_running_hour / 3600000).toFixed(2)}
                            lastValue={(element.value[element.value.length - 1].reading_value).toFixed(2)}
                            contact={element.contact[0] ? element.contact[0].reading_value : 'false'}
                            
                            />
                </div>;
                idx += 1; 
                dataToRender.push(x);
            });
            setDataToRender(dataToRender);
            setIsDataReady(true);
            
        }

    }, [dataToShow])

    const loadPumpMotorOption = async () => {
        try {
            let response = await findAllPumpMotor(cookies.token, smsSerialNumber, vesselId);
            setListPumpMotor(response);
        }
        catch (exception) {
            console.log(exception);
        }
    }


    const loadReadingValue = async () => {
        try {
            let intervalTime = '1 Minutes';
            let responseReading = await getPLCReadingValueForMotorPump(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId, intervalTime);
            // console.log('responseReading',responseReading);
            let receivedDate = moment(responseReading[0].value[0].received_date).format("yyyy-MM-DD HH:mm:ss");
            setLastReceivedDate(receivedDate);
            setDataToShow(responseReading);

        } catch (exception) {
            console.log(exception);
        }
    }

  
    return (
        <>
        {
            isDataReady ? (<div className="mainPageMotorPumpRes">
                <div className="mainPageMotorPumpNew">
                    {

                        dataToRender && dataToRender.map((data, index) => {
                            // console.log(data);
                            return data
                        })

                    }
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
                    </div>
                </div>
            </div>):(<Loading
                        loading={loading}
                    />)
        }


        </>
    );
}