import "./index.css";
// import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Button } from "react-bootstrap";
import BoxSwitchToggle from "../../../Components/BoxSwitchToggle/BoxSwitchToggle";
import React, { useEffect, useState } from "react"
import { getPLCCoordinate, isTokenValid, getPlcValue, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
// import LampNavButton from "../../../Components/LampNavButton/LampNavButton";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import moment from "moment";

export default function SMSNavSignal(props) {
    const { smsSerialNumber, vesselId } = props;
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    //const [bitlampList, setBitlampList] = useState([]);
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([3]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    // const [valueAnchor, setValueAnchor] = useState();
    // const [valuePort, setValuePort] = useState();
    // const [valueSTBD, setValueSTBD] = useState();
    // const [valueSTERN, setValueSTERN] = useState();
    // const [valueTowing, setValueTowing] = useState();
    // const [valueMAST1, setValueMAST1] = useState();
    // const [valueMAST2, setValueMAST2] = useState();
    // const [valueMAST3, setValueMAST3] = useState();
    // const [valueSPARE1, setValueSPARE1] = useState();
    // const [valueSPARE2, setValueSPARE2] = useState();
    // const [valueNUC1, setValueNUC1] = useState();
    // const [valueNUC2, setValueNUC2] = useState();
    // const [valueNUC3, setValueNUC3] = useState();
    const [loading, setLoading] = useState(true);
    //const [navlightValue, setNavlightValue] = useState()
    const [bitlampList, setBitlampList] = useState({

        M118:
        {
            text: "ANCHOR",
            alarm: true,
            coordinate: "M118",
            value: false
        }, M106: {
            text: "NUC 1",
            alarm: false,
            coordinate: "M106",
            value: false
        }, M108: {
            text: "NUC 2",
            alarm: true,
            coordinate: "M108",
            value: false
        }, M110: {
            text: "NUC 3",
            alarm: true,
            coordinate: "M110",
            value: false
        }, M112: {
            text: "SPARE 1",
            alarm: true,
            coordinate: "M112",
            value: false
        }, M114: {
            text: "SPARE 2",
            alarm: true,
            coordinate: "M114",
            value: false
        },
        M102: {
            text: "PORT",
            alarm: true,
            coordinate: "M102",
            value: false
        },
        M96: {
            text: "MAST 1",
            alarm: true,
            coordinate: "M96",
            value: false
        },
        M98: {
            text: "MAST 2",
            alarm: true,
            coordinate: "M98",
            value: false
        },
        M100: {
            text: "MAST 3",
            alarm: true,
            coordinate: "M100",
            value: false
        },
        M104: {
            text: "STBD",
            alarm: true,
            coordinate: "M104",
            value: false
        },
        M120: {
            text: "TOWING",
            alarm: true,
            coordinate: "M120",
            value: false
        },
        M116: {
            text: "STERN",
            alarm: true,
            coordinate: "M116",
            value: false
        }
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (bitlampList.length > 0) {
    //         const interval = setInterval(async () => {
    //             // console.log('tes')
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval);
    //     }


    // }, [bitlampList]);

    // const loadBitlampValue = async () => {
    //     try {
    //         let coordinate = bitlampList.map(p => p.plc_coordinate);
    //         let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
    //         let updatedList = bitlampList.map(item => {
    //             let alarmValue = response.find(p => item.plc_coordinate === p.field);
    //             if (alarmValue) {
    //                 return { ...item, alarm: alarmValue.value === 1 };
    //             } else {
    //                 return { ...item, alarm: item.alarm };
    //             }
    //         });
    //         setBitlampList(updatedList);
    //         setLoading(false);

    //         for (let index = 0; index < bitlampList.length; index++) {
    //             const list = bitlampList[index];
    //             let alarmValue = response.find(p => list.plc_coordinate === p.plc_coordinate)
    //             if(alarmValue){
    //                 if(alarmValue.value === 1){
    //                     list.alarm = true
    //                 }else{
    //                     list.alarm = false
    //                 }
    //             }else{
    //                 list.alarm = !list.alarm
    //             }
    //         }
    //         const correspondingItem = array2.find(item2 => item1.id === item2.id);
    //         if (correspondingItem) {
    //             // Update value in array1 from corresponding element in array2
    //             item1.value = correspondingItem.value;
    //         }
    //         let valueBitlamp = bitlampList;
    //         valueBitlamp[0].alarm = !bitlampList[0].alarm;
    //         valueBitlamp[1].alarm = !bitlampList[1].alarm;
    //         valueBitlamp[2].alarm = !bitlampList[2].alarm;
    //         valueBitlamp[3].alarm = !bitlampList[3].alarm;
    //         valueBitlamp[4].alarm = !bitlampList[4].alarm;
    //         valueBitlamp[5].alarm = !bitlampList[5].alarm;
    //         valueBitlamp[6].alarm = !bitlampList[6].alarm;
    //         valueBitlamp[7].alarm = !bitlampList[7].alarm;
    //         valueBitlamp[8].alarm = !bitlampList[8].alarm;
    //         valueBitlamp[9].alarm = !bitlampList[9].alarm;
    //         valueBitlamp[10].alarm = !bitlampList[10].alarm;
    //         valueBitlamp[11].alarm = !bitlampList[11].alarm;
    //         valueBitlamp[12].alarm = !bitlampList[12].alarm;
    //         setBitlampList(valueBitlamp.slice(0))
    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    // const loadBilampCoordinate = async () => {
    //     try {
    //         let response = await getPLCCoordinate(cookies.token, "navigation_light", cookies.vesselId);
    //         setBitlampList(response);
    //         if (response.length < 1) {
    //             setLoading(false);
    //         }
    //     } catch (exception) {

    //     }
    // }

    const loadCoordinate = async () => {
        try {
            // console.log(bitlampList["M112"]);
            setLoading(true);
            const response = await getPLCReadingValue(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].received_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
            /* for (let i = 0; i < response.length; i++) {
                if (response[i].coordinate_number === 118) {
                    setValueAnchor(response[i].reading_value);
                    console.log('Anchor', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 106) {
                    setValueNUC1(response[i].reading_value);
                    console.log('NUC1', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 108) {
                    setValueNUC2(response[i].reading_value);
                    console.log('NUC2', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 110) {
                    setValueNUC3(response[i].reading_value);
                    console.log('NUC3', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 102) {
                    setValuePort(response[i].reading_value);
                    console.log('PORT', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 96) {
                    setValueMAST1(response[i].reading_value);
                    console.log('MAST1', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 98) {
                    setValueMAST2(response[i].reading_value);
                    console.log('MAST2', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 100) {
                    setValueMAST3(response[i].reading_value);
                    console.log('MAST3', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 104) {
                    setValueSTBD(response[i].reading_value);
                    console.log('STBD', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 120) {
                    setValueTowing(response[i].reading_value);
                    console.log('Towing', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 116) {
                    setValueSTERN(response[i].reading_value);
                    console.log('STERN', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 112) {
                    setValueSPARE1(response[i].reading_value);
                    console.log('Spare1', response[i].coordinate_number, response[i].reading_value);
                }
                else if (response[i].coordinate_number === 114) {
                    setValueSPARE2(response[i].reading_value);
                    console.log('Spare2', response[i].coordinate_number, response[i].reading_value);
                }
            } */
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div className="mainPageNavLight">
                <div className="containerBoxNavLight">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="imgShipShape"></div>
                        <div style={{ backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', width: '70%' }}>
                            {bitlampList && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', height: '500px', padding: '5%' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                    <BitLamp text={'ANCHOR'} bitlampColor="black" alarm={bitlampList["M118"].value} />
                                    <BitLamp text={'PORT'} bitlampColor="black" alarm={bitlampList["M102"].value} />
                                    <BitLamp text={'STBD'} bitlampColor="black" alarm={bitlampList["M104"].value} />
                                    <BitLamp text={'STERN'} bitlampColor="black" alarm={bitlampList["M116"].value} />
                                    <BitLamp text={'TOWING'} bitlampColor="black" alarm={bitlampList["M120"].value} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                    <BitLamp text={'MAST 1'} bitlampColor="black" alarm={bitlampList["M96"].value} />
                                    <BitLamp text={'MAST 2'} bitlampColor="black" alarm={bitlampList["M98"].value} />
                                    <BitLamp text={'MAST 3'} bitlampColor="black" alarm={bitlampList["M100"].value} />
                                    <BitLamp text={'SPARE 1'} bitlampColor="black" alarm={bitlampList["M112"].value} />
                                    <BitLamp text={'SPARE 2'} bitlampColor="black" alarm={bitlampList["M114"].value} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                    <BitLamp text={'NUC 1'} bitlampColor="black" alarm={bitlampList["M106"].value} />
                                    <BitLamp text={'NUC 2'} bitlampColor="black" alarm={bitlampList["M108"].value} />
                                    <BitLamp text={'NUC 3'} bitlampColor="black" alarm={bitlampList["M110"].value} />
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div >
                        {
                            lastReceivedDate && <p style={{color: "white", fontSize: 20, marginLeft: '20px'}}>Last received data on {moment(lastReceivedDate).format("yyyy-MM-DD HH:mm:ss")}</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}