import "./index.css";
import "../../SMSApp.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCCoordinate, getPlcValue } from "../../Helpers/ApplicationHelpers";
import { Col, Row } from "react-bootstrap";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/NavBar";

export default function WatertightDoor() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listBitlamp, setListBitlamp] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (listBitlamp.length > 0) {
            const interval = setInterval(async () => {
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval)
        }

    }, [listBitlamp]);

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "watertight_door", cookies.vesselId);
            setListBitlamp(response);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {
            let coordinate = listBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = listBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setListBitlamp(updatedList);

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />

            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Watertight Door'}/>
                    <BottomNavBar/>
                    <div style={{display: 'flex', justifyContent: 'flex-start', padding: '5%', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}>
                        <div style={{height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: '0px 10px'}}>
                            <BitLamp text={'WH P'} bitlampColor = "black"/>
                            <BitLamp text={'E. Room P'} bitlampColor = "black"/>
                            <BitLamp text={'E. Room SB'} bitlampColor = "black"/>
                            <BitLamp text={'Emergency Hatch E. Room'} bitlampColor = "black"/>
                            <BitLamp text={'CO2 Room'} bitlampColor = "black"/>
                        </div>
                        <div style={{height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: '0px 10px'}}>
                            <BitLamp text={'Emergency Hatch Crew Room'} bitlampColor = "black"/>
                            <BitLamp text={'WH SB'} bitlampColor = "black"/>
                            <BitLamp text={'Steering Gear Room SB'} bitlampColor = "black"/>
                            <BitLamp text={'Deck Store'} bitlampColor = "black"/>
                            <BitLamp text={'Steering Gear Room P'} bitlampColor = "black"/>
                        </div>
                    </div>
                </div>
           </div>
        </>
    );
}