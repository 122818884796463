import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal, Nav } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVesselInventoryHandoverDocumentById, insertUpdateVesselInventoryHandoverDocument,
    getVesselInventoryHandoverDocument, convertBase64, deleteVesselInventoryHandoverDocument, getDocumentSparepartById,
    getUserAccessById, getCountVesselInventoryHandoverDocument
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import Modals from "../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise, PencilFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import FormSelect from "../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import moment from 'moment';
import { Trans, useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function VesselInventoryHandoverDocumentPage() {
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [downloadId, setDownloadId] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [updatedDocumentId, setUpdatedDocumentId] = useState("");
    const [vesselInventoryHandoverDocumentId, setVesselInventoryHandoverDocumentId] = useState("");
    const [vesselHandoverDetailId, setVesselHandoverDetailId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(0.4);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("vessel_inventory_handover_document.order_name1")}`,
        value: "document_name"
    });
    const orderByList = [{
        label: `${t("vessel_inventory_handover_document.order_name1")}`,
        value: "document_name"
    }];
    const [newDocument, setNewDocument] = useState({
        id: 0,
        vessel_inventory_handover_detail_id: "",
        document_base64: "",
        document_name: "",
        note: "",
        is_active: true,
        done: false,
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        vessel_inventory_handover_detail_id: "",
        document_base64: "",
        document_name: "",
        note: "",
        is_active: true,
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            vessel_inventory_handover_detail_id: vesselHandoverDetailId,
            document_base64: "",
            document_name: "",
            note: "",
            is_active: true,
            done: false
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    useEffect(() => {
        loadDocumentData();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadDocumentData();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadDocumentData();
    }, [orderBy]);

    // useEffect(() => {
    //     if (updatedDocumentId !== "")
    //         initDocument();
    // }, [updatedDocumentId])

    useEffect(() => {
        if (vesselInventoryHandoverDocumentId !== "")
            loadDocumentById()
    }, [vesselInventoryHandoverDocumentId]);

    useEffect(() => {
        if (downloadId !== "")
            downloadData();
    }, [downloadId])

    useEffect(() => {
        if (searchQuery === "")
            loadDocumentData();
    }, [searchQuery]);

    useEffect(() => {
        if (isSearched !== 0)
            loadDocumentData()
    }, [descending]);

    const [documentFile, setDocumentFile] = useState("");



    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }


    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        setLoading(true);
        if (vesselHandoverDetailId !== "")
            removeDocument();
    }, [documentId]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                vessel_inventory_handover_detail_id: "",
                document_base64: "",
                document_name: "",
                note: "",
                is_active: true,
            });
            setVesselInventoryHandoverDocumentId("")
        }
    }, [showDocumentDetailModal]);



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setVesselHandoverDetailId(searchParams.get("id"));
                loadAccess();
                // loadDocumentData();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setNewDocument({ ...newDocument, vessel_inventory_handover_detail_id: vesselHandoverDetailId });
        loadDocumentData();

    }, [vesselHandoverDetailId])

    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateVesselInventoryHandoverDocument(cookies.token, newDocument);

                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                setDisabledButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const loadDocumentData = async () => {
        try {
            let count = await getCountVesselInventoryHandoverDocument(cookies.token, searchParams.get("id"), searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getVesselInventoryHandoverDocument(cookies.token, searchParams.get("id"), page, itemPerPage, orderBy.value, descending, searchQuery, false);

            setListDocument(response);
            setLoading(false);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    // const initDocument = async () => {
    //     try {
    //         let response = await getDocumentSparepartById(cookies.token, updatedDocumentId)
    //         if (response) {
    //             setNewDocument({
    //                 ...newDocument,
    //                 id: response.id,
    //                 document_name: response.document_name,
    //                 reference_number: response.reference_number,
    //                 note: response.note
    //             });
    //             setShowDocumentUploadModal(true);
    //             setUpdatedDocumentId("");
    //             setLoading(false);
    //         }
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }
    const removeDocument = async () => {
        let response = await deleteVesselInventoryHandoverDocument(cookies.token, documentId);
        if (response) {
            loadDocumentData();
            alert(`${t("vessel_inventory_handover_document.delete_success_alert")}`)
        } else {
            alert(`${t("vessel_inventory_handover_document.delete_failed_alert")}`);
            setLoading(false);
        }
    }

    const loadDocumentById = async () => {
        try {
            let response = await getVesselInventoryHandoverDocumentById(cookies.token, vesselInventoryHandoverDocumentId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await getVesselInventoryHandoverDocumentById(cookies.token, downloadId);
            triggerBase64Download(response.document_base64, response.document_name);
            setLoading(false);
            setDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/VesselInventoryHandover");
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("vessel_inventory_handover_document.order_name1")}`,
                value: "document_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("vessel_inventory_handover_document.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_inventory_handover_document.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("vessel_inventory_handover_document.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadDocumentData();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadDocumentData();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadDocumentData();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("vessel_inventory_handover_document.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("vessel_inventory_handover_document.table_name2")}</div>
                                    {/* <div style={{ flex: 2 }} className="table-header-content">Edit</div> */}
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_inventory_handover_document.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_inventory_handover_document.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_inventory_handover_document.table_name5")}</div>
                                </div>
                                {
                                    listDocument.map((document, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                            {/* <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!updatedButton} variant="primary" onClick={() => {
                                                        setLoading(true);
                                                        setUpdatedDocumentId(document.id)
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div> */}
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setLoading(true);
                                                        setVesselInventoryHandoverDocumentId(document.id)
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setDownloadId(document.id)
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("vessel_inventory_handover_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            let documentId = document.id
                                                            setDocumentId(documentId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                            loadingText={`${t("loading_component.text")}`}
                                loading={loading}
                            />
                        </div>
                    } />



                <Modal show={showDocumentUploadModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        resetUploadForm();
                        setShowDocumentUploadModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("vessel_inventory_handover_document.modal_add_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            setDisabledButton(true);
                            setNewDocument({ ...newDocument, done: true });
                        }} style={{ padding: 10 }}>


                            {
                                newDocument.id === 0 &&
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("vessel_inventory_handover_document.field_document_file")}</Form.Label>
                                    <Form.Control onChange={async (e) => {
                                        console.log(e.target.files[0].type)
                                        if (e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                            setDocumentFile(e.target.value)
                                            let base64Doc = await convertBase64(e.target.files[0]);
                                            setNewDocument({ ...newDocument, document_base64: base64Doc.toString() });
                                            setUploadFileImageError("");
                                            setDisabledButton(false)

                                        } else {
                                            setDocumentFile("")
                                            setNewDocument({ ...newDocument, document_base64: "" });
                                            setUploadFileImageError(`${t("vessel_inventory_handover_document.error_upload_file")}`)
                                            setDisabledButton(true)
                                        }

                                    }} type="file" required></Form.Control>
                                    {
                                        uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                    }
                                </Form.Group>
                            }


                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_inventory_handover_document.field_document_name")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                }} value={newDocument.document_name} type="text" placeholder="" required></Form.Control>

                            </Form.Group>



                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_inventory_handover_document.field_description")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, note: e.target.value })
                                }} value={newDocument.note} as="textarea" rows={3} placeholder=""></Form.Control>

                            </Form.Group>

                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flex: 1,
                            }}>
                                <Button style={{width:100}} variant="primary" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>
                                {t("vessel_inventory_handover_document.save_button")}
                                </Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    resetUploadForm();
                                    loadDocumentData();
                                    setShowDocumentUploadModal(false);
                                }}>
                                    {t("vessel_inventory_handover_document.cancel_button")}
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal>

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>

                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowDocumentDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>{t("vessel_inventory_handover_document.close_button")}</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                {
                                    documentToBeViewed.document_base64.substring(0, 10) === "data:image"
                                        ?
                                        <center> <img src={documentToBeViewed.document_base64} style={{ width: "50%" }}></img></center>
                                        :
                                        <div>
                                            <div>

                                                <Document
                                                    file={documentToBeViewed.document_base64}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                    loading={() => {

                                                    }}
                                                ><div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            overflow: "scroll",
                                                        }}>
                                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                                        </div>
                                                    </div>
                                                </Document>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                }}>
                                                    <p>
                                                    {t("vessel_inventory_handover_document.page_text")} {pageNumber} {t("vessel_inventory_handover_document.from_text")} {numPages}
                                                    </p>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        padding: 5,
                                                    }}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            disabled={zoomFactor <= 0.2}
                                                            onClick={() => {
                                                                setZoomFactor(zoomFactor * 0.8);
                                                            }}
                                                        >

                                                            <ZoomOut size={28} />
                                                        </Button>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        padding: 5
                                                    }}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            disabled={false}
                                                            onClick={() => {
                                                                setZoomFactor(1);
                                                            }}
                                                        >
                                                            <AspectRatioFill size={28} />
                                                        </Button>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        padding: 5,
                                                        flexWrap: "wrap",
                                                    }}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            disabled={zoomFactor >= 10}
                                                            onClick={() => {
                                                                setZoomFactor(zoomFactor * 2);
                                                            }}
                                                        >
                                                            <ZoomIn size={28} />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        padding: 5
                                                    }}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            disabled={pageNumber <= 1}
                                                            onClick={previousPage}
                                                        >

                                                            <ChevronDoubleLeft size={28} />
                                                        </Button>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        padding: 5
                                                    }}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            disabled={pageNumber >= numPages}
                                                            onClick={nextPage}
                                                        >
                                                            <ChevronDoubleRight size={28} />
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                }

                                {/* <div>
                                    <Document
                                        file={documentToBeViewed.document_base64}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        loading={() => {

                                        }}
                                    ><div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                overflow: "scroll",
                                            }}>
                                                <Page scale={zoomFactor} pageNumber={pageNumber} />
                                            </div>
                                        </div>
                                    </Document>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                        <p>
                                            Halaman {pageNumber} dari {numPages}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        flexWrap: "wrap",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor <= 0.2}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 0.8);
                                                }}
                                            >

                                                <ZoomOut size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={false}
                                                onClick={() => {
                                                    setZoomFactor(0.4);
                                                }}
                                            >
                                                <AspectRatioFill size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                            flexWrap: "wrap",
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor >= 10}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 2);
                                                }}
                                            >
                                                <ZoomIn size={28} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                            >

                                                <ChevronDoubleLeft size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                            >
                                                <ChevronDoubleRight size={28} />
                                            </Button>
                                        </div>
                                    </div>

                                </div> */}
                            </div>
                        } />

                </Modal>
            </Container>

        </>
    );

}