import { Button } from "react-bootstrap";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import "./index.css";
import { isTokenValid, getAlarmEventHistory, getCountalarmEventHistory } from "../../../Helpers/ApplicationHelpers";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";

export default function AlarmLog() {
    const navigate = useNavigate();
    const [listEventHistory, setListEventHistoy] = useState([]);
    const navigateAlarmLogs = () => { navigate("/Alarm/Logs") }
    const navigateAlarm = () => { navigate("/Alarm") }
    const [cookies, setCookie] = useCookies(["token"]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [isSearched, setIsSearched] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [descending, setDescending] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAlarmEvent();
            }
        }
        checkCookies();
    }, []);


    useEffect(() => {
        // if (isSearched !== 0)
        loadAlarmEvent();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadAlarmEvent();
    }, [itemPerPage]);

    //   useEffect(() => {
    //     if (isSearched !== 0)
    //       loadAlarmEvent();
    //   }, [orderBy]);


    //   useEffect(() => {
    //     if (resetSearch !== 0) {
    //       loadAlarmEvent()
    //     }
    //   }, [resetSearch])

    //   useEffect(() => {
    //     if (isSearched !== 0)
    //       loadAlarmEvent()
    //   }, [descending]);



    const loadAlarmEvent = async () => {
        try {
            let count = await getCountalarmEventHistory(cookies.token, searchQuery, false, cookies.serialNumber);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let list = await getAlarmEventHistory(cookies.token,cookies.serialNumber, page, itemPerPage, undefined, descending, searchQuery, false);
            setListEventHistoy(list)
            // setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
            setLoading(false);
        } catch (exception) {

        }
    }
    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"ALARM > LOGS"} /></div>
            <div className="contentAlarm">
                <div className="headingContentAlarm">
                    <div className="titleContent">System Event Alarm</div>
                    <div className="topBtnAlarmContainer">
                        {/* <Button
                            className="btnAlarms"
                            variant="outline-primary"
                            onClick={navigateAlarm}
                        >
                            ACKNOWLEDGE ALARM
                        </Button> */}
                        {/* <Button
                            className="btnAlarms"
                            variant="outline-primary"
                            onClick={navigateAlarmLogs}
                        >
                            ALARM LOGS
                        </Button> */}
                    </div>
                </div>
                <div className="tableAlarmLogs">
                    <div className="tableLogAlarmHead">
                        <div className="headingNo"></div>
                        <div className="headingDate">Date</div>
                        <div className="headingTime">Item</div>
                        <div className="headingEvent">Events</div>
                    </div>
                    {
                        listEventHistory.map((events, index) => {
                            return (
                                <div className="tableBodyContainer">
                                    <div className="tableLogAlarmBody">
                                        <div className="bodyNo">{(page * (itemPerPage)) + (index + 1)}</div>
                                        <div className="bodyDate">{moment(events.start_time).format("DD-MM-yyyy HH:mm")}</div>
                                        <div className="bodyTime">{events.coordinate_desc}</div>
                                        <div className="bodyEvent">{events.level}</div>
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
                <Paginations
                itemPerPage={itemPerPage}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
                // setItemPerPage={setItemPerPage}
                // itemPerPageSelection={itemPerPageSelection}
                // orderBy={orderBy}
                // setOrderBy={setOrderBy}
                // orderBySelection={orderByList}
                // isDescActive={descending}
                // setIsDescActive={setDescending}
                setIsSearched={setIsSearched}
              />
                {/* <div className="tableAlarmLogs">
                    <div className="tableLogAlarmHead">
                        <div className="headingNo"></div>
                        <div className="headingDate">Date</div>
                        <div className="headingTime">Time</div>
                        <div className="headingEvent">Events</div>
                        <div className="headingOccurrence">Occurrence</div>
                    </div>
                    <div className="tableBodyContainer">
                        <div className="tableLogAlarmBody">
                            <div className="bodyNo">3</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0 Return to Normal</div>
                            <div className="bodyOccurrence">999</div>
                        </div>
                        <div className="tableLogAlarmBody">
                            <div className="bodyNo">2</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0 Acknowledge</div>
                            <div className="bodyOccurrence">999</div>
                        </div>
                        <div className="tableLogAlarmBody">
                            <div className="bodyNo">1</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0</div>
                            <div className="bodyOccurrence">999</div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Loading
                loading={loading}
              />
            <div><BottomNavBar /></div>
        </>
    );
}