import "../App.css";
import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { Form, option, Button, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Select from 'react-select';
import { Trash, FilePlusFill, PencilFill, XSquareFill, CameraFill, Image } from "react-bootstrap-icons";
import { isTokenValid, getVesselEquipmentByEquipmentCategory, convertBase64 } from "../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import ContainerBox from "./ContainerBox";
import FormSelect from "./FormSelect";

function TroubleshootingDetail({
    troubleshooting,
    handleOnBlurTroubleshooting,
    handleOnBlurSelectedProject,
    listProject,
    troubleshootingId,
    listStatus,
    listMechanicGroup,
    listStaff,
    handleOnBlurlistCrew,
    listTroubleshootingCrew,
    disabledCustomerForm
}) {
    const [cookies, setCookie] = useCookies(["token"]);
    const [troubleshootingNew, setTroubleshootingNew] = useState({});
    const [resetComponent, setResetComponent] = useState(false);
    const [listStatusNew, setListStatusNew] = useState([]);
    const [listProjectNew, setListProjectNew] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedMechanicGroup, setSelectedMechanicGroup] = useState(null)
    const [listMechanicGroupNew, setListMechanicGroupNew] = useState([]);
    const [listStaffNew, setListStaffNew] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null)
    const [serviceDate, setServiceDate] = useState(null);
    // useEffect(() => {
    //     resetComponentProps && setResetComponent(false);
    // }, [resetComponent]);


    useEffect(() => {
        if (troubleshooting) {
            setTroubleshootingNew(troubleshootingNew);
        }
    }, []);

    useEffect(() => {
        if (troubleshooting) {
            setTroubleshootingNew(troubleshooting);
            setServiceDate(troubleshooting.service_date)
        }

        if (listProject) {
            setListProjectNew(listProject);
        }

        if (listStatus)
            setListStatusNew(listStatus);

        if (listMechanicGroup) {
            setListMechanicGroupNew(listMechanicGroup);
        }

        if (listStaff) {
            setListStaffNew(listStaff)
        }
    }, [troubleshooting]);

    useEffect(() => {
        if (listMechanicGroup) {
            setListMechanicGroupNew(listMechanicGroup)
            let selectedMechanicGroupTmp = listMechanicGroup.find(p => p.id === troubleshooting.mechanic_group_id)

            if (selectedMechanicGroupTmp) {
                setSelectedMechanicGroup(selectedMechanicGroupTmp)
            }
        }
    }, [listMechanicGroup])

    useEffect(() => {
        if (listStaff) {
            setListStaffNew(listStaff)

            let selectedStaffTmp = listStaff.find(p => p.id === troubleshooting.staff_pic_id)

            if (selectedStaffTmp) {
                setSelectedStaff(selectedStaffTmp)
            }
        }
    }, [listStaff])


    useEffect(() => {
        if (selectedProject) {
            let troubleshootingTmp = troubleshootingNew
            troubleshootingTmp.project_id = selectedProject.id
            setTroubleshootingNew(troubleshootingTmp)
            handleOnBlurTroubleshooting(troubleshootingNew)
            handleOnBlurSelectedProject(selectedProject)
        }
    }, [selectedProject]);

    useEffect(() => {
        if (serviceDate) {
            let troubleshootingTmp = troubleshootingNew
            troubleshootingTmp.service_date = serviceDate
            setTroubleshootingNew(troubleshootingTmp)
            handleOnBlurTroubleshooting(troubleshootingNew)
        }
    }, [serviceDate])

    useEffect(() => {
        if (selectedMechanicGroup) {
            let troubleshootingTmp = troubleshootingNew
            let listStaffTmp = selectedMechanicGroup.list_staff.find(p => p.is_leader === true)

            if (troubleshootingTmp.staff_pic_id && troubleshootingTmp.mechanic_group_id) {
                let staffTmp = listStaffNew.find(p => p.staff_id === troubleshootingTmp.staff_pic_id);
                if(staffTmp){
                    setSelectedStaff(staffTmp)
                }

            } else {
                let staffTmp = listStaffNew.find(p => p.staff_id === listStaffTmp.staff_id)
                if (staffTmp) {
                    setSelectedStaff(staffTmp);
                    troubleshootingTmp.staff_pic_id = listStaffTmp.staff_id

                }
            }


            troubleshootingTmp.mechanic_group_id = selectedMechanicGroup.id

            setTroubleshootingNew(troubleshootingTmp)
            handleOnBlurTroubleshooting(troubleshootingTmp)
        }
    }, [selectedMechanicGroup]);


    useEffect(() => {
        if (listProjectNew.length > 0) {
            let selectedProjectTmp = listProjectNew.find(p => p.id === troubleshooting.project_id)

            if (selectedProjectTmp) {
                setSelectedProject(selectedProjectTmp)
            }
        }
    }, [listProjectNew])


    useEffect(() => {
        if (listMechanicGroupNew.length > 0) {
            let selectedMechanicGroupTmp = listMechanicGroupNew.find(p => p.id === troubleshooting.mechanic_group_id)
            if (selectedMechanicGroupTmp) {
                setSelectedMechanicGroup(selectedMechanicGroupTmp)
            }
        }
    }, [troubleshootingNew])


    useEffect(() => {
        if (selectedStaff) {
            let troubleshootingTmp = troubleshootingNew
            troubleshootingTmp.staff_pic_id = selectedStaff.staff_id
            setTroubleshootingNew(troubleshootingTmp)
            handleOnBlurTroubleshooting(troubleshootingNew)

        }
    }, [selectedStaff])



    return (
        <div style={{ padding: 10 }}>
            <Form.Group>
                <Form.Label>Nama Proyek</Form.Label>
                <Select placeholder={""}
                    getOptionLabel={(item) => {
                        return item.project_name;
                    }} clearValue={true}
                    getOptionValue={(item) => {
                        return item.id;
                    }}
                    options={listProjectNew} value={selectedProject} onChange={(e) => {
                        if (e === null) {
                            setSelectedProject({})
                        } else {
                            setSelectedProject(e);
                        }
                    }}
                    isClearable
                    // isDisabled={disabledAddCrew}
                    isDisabled={troubleshootingId !== 0 && troubleshootingId !== ""}
                />
            </Form.Group>
            {/* {
                errors.projectName && <p style={{ color: "red" }}>{errors.projectName}</p>
            } */}
            <Form.Group>
                <Form.Label>Tanggal Pengerjaan</Form.Label>
                <Form.Control disabled={disabledCustomerForm} required type="date"  value={serviceDate ? moment(serviceDate).format("yyyy-MM-DD") : ""} onChange={(e) => {
                    // if (e.target.value === "") {
                    //     setTroubleshootingNew({ ...troubleshootingNew, service_date: null })
                    // } else {
                    //     setTroubleshootingNew({ ...troubleshootingNew, service_date: e.target.value })
                    // }
                    // handleOnBlurTroubleshooting(troubleshootingNew)
                    setServiceDate(e.target.value)
                }} ></Form.Control>
            </Form.Group>
            {/* {
                errors.serviceDate && <p style={{ color: "red" }}>{errors.serviceDate}</p>
            } */}
            <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select onBlur={e => handleOnBlurTroubleshooting(troubleshootingNew)} disabled={troubleshootingId === 0 || disabledCustomerForm === true ? true : false} required value={troubleshootingNew.status_id} onChange={(e) => {
                    if (e.target.value === "") {
                        setTroubleshootingNew({ ...troubleshootingNew, status_id: null });
                    } else {
                        setTroubleshootingNew({ ...troubleshootingNew, status_id: e.target.value });
                    }
                    // handleOnBlurTroubleshooting(troubleshootingNew)
                }}>
                    <option></option>
                    {
                        listStatusNew.map((status, index) => {
                            return (
                                <option key={index} value={status.id}>{status.status_name}</option>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group>
                <Form.Label>Nama Grup Teknisi</Form.Label>

                <Select placeholder={""}
                    getOptionLabel={(item) => {
                        return item.group_name;
                    }} clearValue={true}
                    getOptionValue={(item) => {
                        return item.id;
                    }}
                    options={listMechanicGroupNew} value={selectedMechanicGroup} onChange={(e) => {
                        if (e === null) {
                            setSelectedMechanicGroup({})
                        } else {

                            if (troubleshootingId === 0) {
                                let listCrewTmp = e.list_staff.filter(p => p.is_active === true)
                                if (listCrewTmp) {
                                    handleOnBlurlistCrew(listCrewTmp)
                                }
                                let troubleshootingTmp = troubleshootingNew
                                troubleshootingTmp.staff_pic_id = null
                                setTroubleshootingNew(troubleshootingTmp)
                                setSelectedMechanicGroup(e);
                            } else {
                                if (window.confirm("Apakah Anda Ingin Mengubah Grup Mekanik")) {
                                    let staffPicTmp = e.list_staff.find(p => p.is_leader === true);

                                    if (staffPicTmp) {
                                        let troubleshootingTmp = troubleshootingNew
                                        troubleshootingTmp.staff_pic_id = null;

                                        let staffCrew = e.list_staff.filter(p => p.is_active === true);
                                        let listStaffs = [];
                                        for (let index = 0; index < staffCrew.length; index++) {
                                            let listCrew = staffCrew[index];
                                            listCrew.id = null;
                                            listStaffs.push(listCrew);
                                        }
                                        let list = [];
                                        // let deleteListCrew = troubleshooting.list_crew;
                                        let deletedCrews = troubleshootingNew.deleted_crew
                                        if (deletedCrews !== undefined) {
                                            for (let i = 0; i < listTroubleshootingCrew.length; i++) {
                                                const list = listTroubleshootingCrew[i];
                                                deletedCrews.push(list)
                                            }
                                        } else {
                                            deletedCrews = listTroubleshootingCrew
                                        }

                                        troubleshootingTmp.deleted_crew = deletedCrews
                                        setTroubleshootingNew(troubleshootingTmp)

                                        if (staffCrew) {
                                            handleOnBlurlistCrew(listStaffs)
                                            // setTroubleshootingNew({ ...troubleshootingNew, staff_service_pic: staffPic.staff_id, list_crew: listStaffs, deleted_crew: deletedCrews })
                                        } else {
                                            handleOnBlurlistCrew([])
                                            // setTroubleshootingNew({ ...troubleshootingNew, staff_service_pic: staffPic.staff_id, list_crew: [], deleted_crew: deletedCrews })
                                        }
                                    }
                                    setSelectedMechanicGroup(e);
                                }
                            }

                        }
                    }}
                    isClearable
                    isDisabled={disabledCustomerForm}
                />

            </Form.Group>
            {/* {
                errors.mechanicGroup && <p style={{ color: "red" }}>{errors.mechanicGroup}</p>
            } */}
            <Form.Group>
                <Form.Label>Nama Staf Penanggungjawab</Form.Label>
                <Select placeholder={""}
                    getOptionLabel={(item) => {
                        return item.staff_name;
                    }} clearValue={true}
                    getOptionValue={(item) => {
                        return item.id;
                    }}
                    options={listStaff.filter((p) => {
                        return !listTroubleshootingCrew.find((x) => {
                            return p.staff_id === x.crew_name
                        }) && p.id !== troubleshootingNew.staff_pic_id && p.resign_date === null
                    })} value={selectedStaff} onChange={(e) => {
                        let list = [];

                        let indexCrew = listTroubleshootingCrew.findIndex((index) => index.crew_name === troubleshooting.staff_pic_id);
                        list.push(listTroubleshootingCrew[indexCrew]);
                        let deletedCrews = troubleshootingNew.deleted_technician
                        if (deletedCrews !== undefined) {
                            deletedCrews.push(list[0])
                        } else {
                            deletedCrews = list
                        }
                        let troubleshootingTmp = troubleshootingNew
                        troubleshootingTmp.deleted_technician = deletedCrews

                        setTroubleshootingNew(troubleshootingTmp)
                        e.is_leader = true
                        e.crew_name = e.staff_id
                        e.id = null

                        let listTechnicianTmp = listTroubleshootingCrew
                        listTechnicianTmp.splice(indexCrew, 1)
                        listTechnicianTmp.unshift(e);
                        handleOnBlurlistCrew(listTechnicianTmp)
                        setSelectedStaff(e)
                    }}
                    isClearable
                    isDisabled={disabledCustomerForm}
                />



            </Form.Group>
            <Form.Group>
                <Form.Label>Lokasi</Form.Label>
                <Form.Control disabled={disabledCustomerForm} onBlur={e => handleOnBlurTroubleshooting(troubleshootingNew)} as={"textarea"} rows={3} value={troubleshootingNew.service_location} onChange={(e) => {
                    setTroubleshootingNew({ ...troubleshootingNew, service_location: e.target.value })
                }}>
                </Form.Control>
            </Form.Group>
        </div>
    )
}



export default TroubleshootingDetail;
