import React, { useState, useEffect } from "react";
import { Button, Container, Form, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVesselxEquipmentById, getEquipment,
    getCountDetailEquipment, deleteVesselxEquipment, getVesselById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, CameraFill, Trash, PencilFill, FilePlusFill, Search, ArrowClockwise, InfoCircleFill, FileCheckFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";

export default function VesselDetailEquipmentPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState("");
    const [descending, setDescending] = useState(true);
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [listEquipment, setListEquipment] = useState([]);
    const [vesselName, setVesselName] = useState("");
    const [vesselId, setVesselId] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [detailCount, setDetailCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddEquipmentModal, setShowAddEquipmentModal] = useState("")
    const [vesselEquipment, setVesselEquipment] = useState([{ vessel_id: location.state.vesselId, equipment_id: "", title: "", description: "" }])
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Peralatan",
        value: "equipment_id"
    });
    const orderByList = [
        {
            label: "Nama Peralatan",
            value: "equipment_id"
        }
    ]
    const [removeId, setRemoveId] = useState("");


    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...vesselEquipment];
        list[index][name] = value;
        setVesselEquipment(list);

    }

    const handleremove = index => {
        const list = [...vesselEquipment];
        list.splice(index, 1);
        setVesselEquipment(list);
    }

    const handleaddclick = () => {
        setVesselEquipment([...vesselEquipment, { vessel_id: location.state.vesselId, equipment_id: '', title: '', description: '' }]);
    }

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVesselEquipment();
                setLoading(false);
                loadListEquipment();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadVesselEquipment();
    }, [page]);

    useEffect(() => {
        loadVesselEquipment();
    }, [itemPerPage]);

    useEffect(() => {
        loadVesselEquipment();
    }, [orderBy]);

    useEffect(() => {
        removeVesselEquipment();
        loadVesselEquipment();
    }, [removeId]);

    useEffect(() => {
        if (searchQuery === "")
            loadVesselEquipment();
    }, [searchQuery]);

    const removeVesselEquipment = async () => {
        try {
            let response = await deleteVesselxEquipment(cookies.token, removeId);
        } catch (exception) {

        }
    }


    const loadVesselEquipment = async () => {
        try {
            let detailCount = await getCountDetailEquipment(cookies.token, location.state.vesselId);
            setDetailCount(detailCount);
            let totalPage = detailCount / itemPerPage;
            setTotalPage(totalPage);

            let listVesselEquipment = await getVesselxEquipmentById(cookies.token, "", location.state.vesselId, undefined, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListVesselEquipment(listVesselEquipment);

            let response = await getVesselById(cookies.token, location.state.vesselId);
            setVesselName(response.vessel_name);
            setVesselId(response.id);
            setCompanyId(response.company_id);
            setLoading(false);

        } catch (exception) {
            console.log(exception)
        }
    }

    const loadListEquipment = async () => {
        try {
            let listEquipment = await getEquipment(cookies.token);
            setListEquipment(listEquipment);
        } catch (exception) {

        }
    }

    const handleResets = async () => {
        setVesselEquipment([{ vessel_id: location.state.vesselId, equipment_id: "", title: "", description: "" }])
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`Daftar Peralatan Kapal ${location.state.vesselName} `}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Detail", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><InfoCircleFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Data Kapal</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Document", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FileCheckFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Dokumen</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/VesselPhoto", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><CameraFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Foto Kapal</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{

                            }} onClick={() => navigate("/VesselEquipment/Detail", { state: { vesselEquipmentId: 0, companyId: companyId !== undefined ? companyId : location.state.companyId, vesselId: vesselId !== undefined ? vesselId : location.state.vesselId, code: 'vessel', back: 'yes', vesselName: vesselName } })}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cari (Nama Kapal)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                await loadVesselEquipment();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            await loadVesselEquipment();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadVesselEquipment();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Foto</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Nama Peralatan</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Titel</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Deskripsi</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Foto Peralatan</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listVesselEquipment.map((equipment, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p><img src={equipment.base64_img} style={{ maxWidth: 40, maxHeight: 60 }}></img></p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{equipment.equipment_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{equipment.title}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><div className="tooltip"><div className="descriptionelips">{equipment.description}</div>
                                                <span className="tooltiptext">{equipment.description}</span>
                                            </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button onClick={() => {
                                                        navigate("/VesselEquipment/Detail", { state: { vesselEquipmentId: equipment.id, back: 'yes', vesselName: location.state.vesselName, companyId: location.state.companyId } })
                                                    }}><EyeFill /> / <PencilFill /></Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                <Button className="cancel" onClick={() => {
                                                            navigate("/VesselEquipment/Photo", { state: { vesselEquipmentId: equipment.id, vesselName: equipment.vessel_name, equipmentName: equipment.equipment_name } });
                                                        }}><CameraFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button className="cancel" variant="danger" onClick={() => {
                                                        if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                            setRemoveId(equipment.id);
                                                        }
                                                    }}><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container >
        </>
    )


}