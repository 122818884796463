import { NavbarBrand } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/fontawesome-free'
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { isTokenValid, getCompanyLogo } from '../Helpers/ApplicationHelpers';
import { getAuthorizedMenu } from '../Helpers/AccountHelpers';
import WHSLogo from "../Assets/Images/WHS-logo.png"
import quantumLogo from "../Assets/Images/logo-qpd (2).png"
import { Trans, useTranslation } from "react-i18next";
// import { faAddressCard, faAirFreshener } from '@fortawesome/free-solid-svg-icons'


// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
//import "./NavBar.css";

function Navigationbar() {
  const { t, i18n } = useTranslation();
  const [listMenu, setListMenu] = useState([]);

  const [cookies, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [companyLogo, setCompanyLogo] = useState("");

  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated)
        navigate("/");
      else {
        if (cookies.language_code === "id-id") {
          i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
          i18n.changeLanguage("en");
        }
        loadAuthorizedMenu();
        loadCompanyLogo();
      }
    }
    checkCookies();
  }, []);

  const loadAuthorizedMenu = async () => {
    try {
      let listMenu = await getAuthorizedMenu(cookies.token, cookies.languageId);
      setListMenu(listMenu);
    }
    catch (exception) {
      console.log(exception);
    }
  }

  const logOut = async () => {
    try {
      // removeCookie("token");
      // navigate("/");
      removeCookie("token");
      if (cookies.logingAs === 'User') {
        navigate("/");
      } else {
        navigate("/Login/Customer");
      }
      // window.location.href = '/';
      // return false;
    } catch (exception) {
      console.log(exception);
    }
  }


  const loadCompanyLogo = async () => {
    try {
      let response = await getCompanyLogo(cookies.token, cookies.extCustomerId === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : cookies.extCustomerId);
      setCompanyLogo(response.logo_image)

    } catch (exception) {
      console.log(exception)
    }
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container className="navbar-container">
        <Navbar.Brand href="/Dashboard">

          {
            companyLogo === "" || companyLogo === null ?
              <img height={72} src={require("../Assets/Images/logo-qpd (2).png")} />
              :
              // <img height={72} src={companyLogo} />
              <img height={72} src={require("../Assets/Images/logo-qpd (2).png")} />

          }


        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {
              listMenu.map((menu, index) => {
                if (menu.parent_id === null) {
                  let children = listMenu.filter(p => p.parent_id === menu.menu_id);

                  if (children.length > 0) {

                    return (
                      <NavDropdown key={index} className="navbar-caption" title={menu.menu_name} id="basic-nav-dropdown">
                        {children.map((child, idx) => {
                          if (child.visible) {
                            return (<NavDropdown.Item key={idx} href={child.menu_path}>{child.menu_name}</NavDropdown.Item>)
                          }
                        })}
                      </NavDropdown>)

                  } else {
                    if (children.visible) {
                      return (
                        <Nav.Link key={index} className="navbar-caption" href={menu.menu_path}>{menu.menu_name}</Nav.Link>
                      )
                    }
                  }
                }
              })
            }

          </Nav>
        </Navbar.Collapse>

        {/* <FontAwesomeIcon icon="fa- fa-home" size="xs" /> */}
        {/* <FontAwesomeIcon icon={faTrashCan} ></FontAwesom              eIcon> */}


        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {t("navbar_item.sign_in_as")}: <a href="#">{cookies.userName}</a>
            {/* <span><FontAw esomeIcon icon={faCoffee} /></span> */}

          </Navbar.Text>
          {
            companyLogo === "" || companyLogo === null ?
              <img height={20} src={require("../Assets/Images/logo-qpd (2).png")} />
              :
              // <img height={20} src={companyLogo} />
              <img height={20} src={require("../Assets/Images/logo-qpd (2).png")} />

          }

          <NavDropdown className="navbar-caption">
            <NavDropdown.Item cl="navbar-text" href="#" onClick={event => {
              navigate("/User/Detail", { state: { userId: cookies.userId, page: "ubahProfil" } });
            }} >{t("navbar_item.change_profil")}</NavDropdown.Item>
            <NavDropdown.Item cl="navbar-text" href="#" onClick={event => {
              navigate("/LanguageSetting", { state: { userId: cookies.userId } });
            }} >{t("navbar_item.language")}</NavDropdown.Item>

            <NavDropdown.Item class="navbar-text" href="#" onClick={event => {
              navigate("/ChangePassword", { state: { staffId: cookies.staffId } });
            }}>{t("navbar_item.change_password")}</NavDropdown.Item>
            <NavDropdown.Item class="navbar-text" href="#" onClick={event => {
              logOut();
            }} >{t("navbar_item.log_out")}</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigationbar;