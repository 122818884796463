import "./ThrottleBar.css";
import ProgressBar from 'react-bootstrap/ProgressBar';
import BitLamp from "../BitLamp/BitLamp";

const CustomProgressBar = ({ now, variant, value }) => {
    const progressBarStyle = {
        height: '2rem',
        border: '1px solid #f1f5f9',
        position: 'relative',
        // borderRadius: '0.25rem',
        overflow: 'hidden',
    };

    const fillStyle = {
        height: '100%',
        width: `${now}%`,
        backgroundColor: variant,
        transition: 'width 0.3s ease-in-out',
        padding: "0 5px",
    };

    return (
        <>
        <div style={progressBarStyle}>
            <div style={fillStyle}>
                <p style={{  fontSize: 'large', fontWeight: 'bold', margin: '0 0', fontFamily: 'Disket Mono'}}>{value}%</p>
            </div>
        </div>
        
        </>
    );
};

export default function ThrottleBar({ text, value, unit, wireBreak = false, shortError = false, volume, highValue, lowvalue }) {
    return (
        <div className="progressBarContainer">
            <p style={{ color: 'white', fontSize: 'larger', fontWeight: 'lighter', margin: '0 0', fontFamily: 'Disket Mono'}}>{text}</p>
            <CustomProgressBar
                now={value}
                variant={value >= 80 &&  value <= 90 ? '#f59e0b' : value >= 90 ? '#e11d48' : '#f1f5f9'}
                value={value}
            />
        </div>
    );
}
