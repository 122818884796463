// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/Fonts/Disket-Mono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainBorderBoxNewMSB {
    border: 1px #f1f5f9 solid;
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 10px; */
}

.txtforBoxWithTextPositionMSB{
    /* margin-left: 5px; */
    color: #f1f5f9;
    font-size: small;
    font-family: "Disket Mono";
    font-weight: bolder;
}

.customProgressBarContainer{
    width: 100%;
    position: relative;
}

@font-face {
    font-family: "Disket Mono";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}`, "",{"version":3,"sources":["webpack://./src/Components/BoxWithTextForMSB/BoxWithText.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,4CAA6D;AACjE","sourcesContent":[".mainBorderBoxNewMSB {\n    border: 1px #f1f5f9 solid;\n    width: 300px;\n    height: 50px;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    /* padding: 10px; */\n}\n\n.txtforBoxWithTextPositionMSB{\n    /* margin-left: 5px; */\n    color: #f1f5f9;\n    font-size: small;\n    font-family: \"Disket Mono\";\n    font-weight: bolder;\n}\n\n.customProgressBarContainer{\n    width: 100%;\n    position: relative;\n}\n\n@font-face {\n    font-family: \"Disket Mono\";\n    src: url(\"../../Assets/Images/Fonts/Disket-Mono-Regular.ttf\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
