import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import { getCrewDepartment, deleteCrewDepartment, getCrewDepartmentCount, isTokenValid, insertUpdateCrewDepartment, getCrewDepartmentById,
        getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";



export default function CrewDepartmentPage() {
    const [listDepartment, setListDepartment] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [departmentId, setDepartmentId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [departmentModal, setDepartmentModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Departemen",
        value: "department_name"
    });
    const orderByList = [{
        label: "Nama Departemen",
        value: "department_name"
    },];
    const [descending, setDescending] = useState(false);
    const [department, setDepartment] = useState({
        id: "0",
        department_name: "",
        description: "",
    })

   
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadDepartment();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadDepartment();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadDepartment();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadDepartment();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadDepartment();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadDepartment()
    }, [descending]);

    useEffect(() => {
        if (departmentId !== "" && departmentId !== undefined)
            intiDepartment();
    }, [departmentId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeDepartment();
    }, [removeId])

    useEffect(() => {
        if (!departmentModal)
            setDepartmentId("");
    }, [departmentModal])


    const loadDepartment = async () => {
        try {
            let count = await getCrewDepartmentCount(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
    
    
            let response = await getCrewDepartment(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListDepartment(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    
    }

    const clearDepartmentModal = async () => {
        let departmentTmp = {};
        departmentTmp.id = 0;
        departmentTmp.department_name = "";
        departmentTmp.description = "";
        setDepartment(departmentTmp);
    }

    const saveData = async () => {
        try {
            // let departmentTmp = department;
            // departmentTmp.ext_customer_id = departmentTmp.ext_customer_id === null ? null : departmentTmp.ext_customer_id
            let response = await insertUpdateCrewDepartment(cookies.token, department);
            if (response.error_code === 0) {
                alert('Data Berhasil Disimpan');
                setDepartmentModal(false);
                clearDepartmentModal();
                loadDepartment();
                setDisabledButton(false);
            } else {
                alert('Gagal Menyimpan Data')
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const intiDepartment = async () => {
        try {
            let response = await getCrewDepartmentById(cookies.token, departmentId);
            if (response) {
                setDepartment({
                    ...department,
                    id: response.id,
                    department_name: response.department_name,
                    description: response.description
                })
            }
            setDepartmentModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeDepartment = async () => {
        try {
            let response = await deleteCrewDepartment(cookies.token, removeId);
            if (response === 0) {
                alert('Data Telah Berhasil Dihapus');
                loadDepartment();
            } else {
                alert('Data Gagal Dihapus');
            }
            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true)
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Departemen"
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false :true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setDepartmentModal(true);
                                setDepartment({ ...department, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Departemen</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Departemen, Kode Gudang, Alamat Gudang)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadDepartment();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadDepartment();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadDepartment();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Departemen</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Deskripsi</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listDepartment.map((depart, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{depart.department_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{depart.description}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idDepart = depart.id;
                                                        setDepartmentId(idDepart);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true:!deletedButton} variant="danger" onClick={() => {
                                                        let idDepart = depart.id;
                                                        if (window.confirm(`Apakah Anda Yakin Untuk menghapus Data  ${depart.department_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idDepart);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                <Modal show={departmentModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setDepartmentModal(false);
                        clearDepartmentModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Data Departemen </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>Nama Departemen</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setDepartment({ ...department, department_name: e.target.value })
                                }} value={department.department_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                       

                            <Form.Group className="mb-3">
                                <Form.Label>Keterangan</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setDepartment({ ...department, description: e.target.value })
                                }} value={department.description} as="textarea" row={3} placeholder="" ></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? 
                                <></>
                                :
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center"
                                }}>
                                    <Button type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>Simpan</Button>
                                    <Button className="cancel" variant="danger" onClick={() => {
                                         clearDepartmentModal();
                                        setDepartmentModal(false);
                                    }}>Batal</Button>
                                </div>
                            }
                         

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}