import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, deleteVesselEquipmentDocument, insertUpdateVesselEquipmentDocument, getDocument,
    convertBase64, getSparepartCatalogDocument, getSparepartCatalogDocumentById, getVesselEquipmentDocumentById,
    getUserAccessById, insertUpdateMaintenancePlanDocument,findMasterIdFromEquipment, getCountMaintenancePlanDocument,
    findEquipmentDocumentById, findMaintenancePlanDocumentById, findSelectedByIdForMaintenancePlanDocument, deleteMaintenancePlanDocument
} from "../../../../../../Helpers/ApplicationHelpers";
import {getSelectedDocumentById, deleteInventoryDocument} from "../../../../../../Helpers/InventoryHelpers";
import "../../../../../../App.css";
import Navbar from "../../../../../../Components/NavBar";
import Paginations from "../../../../../../Components/Pagination";
import Modals from "../../../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill,XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise
} from "react-bootstrap-icons";
import ContainerBox from "../../../../../../Components/ContainerBox";
import Loading from "../../../../../../Components/Loading";
import TextBox from "../../../../../../Components/Textbox";
import FormSelect from "../../../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../../../Helpers/Base64Downloader";
import moment from 'moment';
import { Trans, useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function VesselEquipmentMaintenancePlanDocumentPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [listDocumentMaster, setListDocumentMaster]= useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [documentId, setDocumentId] = useState("");
    const [listDocumentSparepart, setListDocumentSparepart] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [sparepartCatalogId, setSparepartCatalogId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [vesselEquipmentDocumentId, setVesselEquipmentDocumentId] = useState("");
    const [sparepartCatalogDocumentId, setSparepartCatalogDocumentId] = useState("");
    const [downloadSparepartCatalogDocumentId, setdownloadSparepartCatalogDocumentId] = useState("")
    const [downloadVesselEquipmentDocumentId, setDownloadVesselEquipmentDocumentId] = useState("")
    const [listDocumentToBeView, setListDocumentToBeView] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("")
    const [vesselName, setVesselName] = useState("")
    const [equipmentName, setEquipmentName] = useState("")
    const [equipmentCategoryId, setEquipmentCategoryId] = useState("")
    const [vesselId, setVesselId] = useState("")
    const [selectedDownloadIdMaster, setSelectedDownloadIdMaster] = useState();
    const [listinventoryMasterId, setListInventoryMasterId] = useState([]);
    const [selectedDocumentIdMaster, setSelectedDocumentIdMaster] = useState();
    const [selectedDocumentIdEquipment, setSelectedDocumentIdEquipment] = useState();
    const [selectedDocumentDownloadIdEquipment, setSelectedDocumentDownloadIdEquipment] = useState();
    const [selectedDeleteIdEquipment, setSelectedDeleteIdEquipment] = useState();
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `Created Date`,
        value: "created_date"
    });
    const orderByList = [{
        label: "Created Date",
        value: "created_date"
    }];
    const [newDocument, setNewDocument] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        maintenance_plan_id: searchParams.get('maintenancePlanId'),
        is_active: true,
        done: false,
        reference_number: ""
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        is_active: true,
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        reference_number: "",
    });

    // const [dataInventoryMaster, setDataInventoryMaster] = useState({
    //     id: 0,
    //     inventory_master_name: "",
    //     is_active: true,
    // });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadAccess();
                loadDocumentData();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (!showDocumentDetailModal) {
    //         setDocumentToBeViewed({
    //             id: 0,
    //             document_base64: "",
    //             document_name: "",
    //             reference_number: "",
    //             description: "",
    //             vessel_x_equipment_id: "",
    //             is_active: true
    //         });
    //         setSparepartCatalogDocumentId("");
    //         setVesselEquipmentDocumentId("");
    //     }
    // }, [showDocumentDetailModal]);

    useEffect(() => {
        if (selectedDocumentIdEquipment !== "")
            loadDocumentByIdEquipment();
    }, [selectedDocumentIdEquipment])

    useEffect(() => {
        if (selectedDocumentDownloadIdEquipment !== "")
            downloadData();
    }, [selectedDocumentDownloadIdEquipment])

    useEffect(() => {
        if (selectedDeleteIdEquipment !== "")
            removeDocument();
    }, [selectedDeleteIdEquipment])

    // useEffect(() => {
    //     if (selectedDownloadIdMaster !== "")
    //         removeInventoryDocument();
    // }, [selectedDownloadIdMaster])

    // useEffect(() => {
    //     if (vesselEquipmentDocumentId !== "")
    //         loadVesselEquipmentDocumentById();
    // }, [vesselEquipmentDocumentId])

    // useEffect(() => {
    //     if (downloadVesselEquipmentDocumentId !== "")
    //         downloadVesselEquipmentDocument()
    // }, [downloadVesselEquipmentDocumentId])

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            document_base64: "",
            document_name: "",
            reference_number: "",
            description: "",
            vessel_x_equipment_id: vesselEquipmentId,
            is_active: true,
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    // useEffect(() => {
    //     loadDocumentData();
    // }, [page]);

    // useEffect(() => {
    //     loadDocumentData();
    // }, [itemPerPage]);

    // useEffect(() => {
    //     loadDocumentData();
    // }, [orderBy]);

    // useEffect(() => {
    //     // console.log(sparepartCatalogId);
    //     // if (sparepartCatalogId !== "") {
    //     //     console.log('tes');
    //     //     loadDocumentDataSparepart()
    //     // }
    //     async function loadSparepartDoc() {
    //         if (sparepartCatalogId !== "") {
    //             await loadDocumentDataSparepart();
    //         }
    //     }
    //     loadSparepartDoc();
    //     forceUpdate();
    // }, [sparepartCatalogId]);

    // useEffect(() => {

    //     if (listDocument.length > 0) {
    //         console.log(sparepartCatalogId);
    //         loadDocumentDataSparepart()
    //     }
    //     // forceUpdate();
    // }, [listDocument])

    // useEffect(() => {
    //     if (searchQuery === "")
    //         loadDocumentData();
    // }, [searchQuery]);

    // useEffect(() => {
    //     setLoading(false);
    //     loadDocumentData()
    // }, [descending]);
    const [documentFile, setDocumentFile] = useState("");



    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    


    // useEffect(() => {
    //     async function submitNewDocument() {
    //         if (newDocument.done) {
    //             await uploadDocument();
    //         }
    //     }
    //     submitNewDocument();
    // }, [newDocument]);

    useEffect(() => {

        console.log(newDocument);
    }, [newDocument])

    useEffect(() => {

        forceUpdate();
        console.log('LIST DOCUMENT EQUIPMENT',listDocument)
    }, [listDocument])

    useEffect(() => {

        // forceUpdate();
        console.log('LIST DOCUMENT MASTER', listDocumentMaster)
    }, [listDocumentMaster])

    // useEffect(() => {
    //     console.log(showDocumentDetailModal)
    // }, [showDocumentDetailModal])

    useEffect(() => {
        console.log(documentToBeViewed);
        setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    // useEffect(() => {
    //     // console.log(listinventoryMasterId);
    //     setDataInventoryMaster({
    //         id: listinventoryMasterId.id,
    //         inventory_master_name: listinventoryMasterId.inventory_master_name,
    //         is_active: listinventoryMasterId.is_active,
    //     })
    //     console.log('DATA INVENTORY MASTER', dataInventoryMaster)
    // }, [listinventoryMasterId]);



    

    // useEffect(() => {
    //     setNewDocument({ ...newDocument, vessel_x_equipment_id: vesselEquipmentId });

    //     // setLoading(false);
    //     // loadDocumentData();
    // }, [vesselEquipmentId])

    const loadDocumentByIdMaster = async () => {
        try {
            let response = await getSelectedDocumentById(cookies.token, selectedDocumentIdMaster);
            console.log(response);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDocumentByIdEquipment = async () => {
        try {
            let response = await findSelectedByIdForMaintenancePlanDocument(cookies.token, selectedDocumentIdEquipment);
            console.log(response);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await findSelectedByIdForMaintenancePlanDocument(cookies.token, selectedDocumentDownloadIdEquipment);
            triggerBase64Download(response.document_base64, response.document_name);
            // setLoading(false);
            setSelectedDeleteIdEquipment("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const uploadDocument = async () => {

        try {
            console.log(newDocument);
            let response = await insertUpdateMaintenancePlanDocument(cookies.token, newDocument);
            if (response) {
                alert(`${t("vessel_equipment_document.alert_success_save_data")}`);
                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                setDisabledButton(false);
            }
            
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteMaintenancePlanDocument(cookies.token, selectedDeleteIdEquipment);
        if (response === 0) {
            // window.location.reload();
            // loadInventoryDocuments();
            loadDocumentData();
        }
    }

    // const removeInventoryDocument = async () => {
    //     let response = await deleteInventoryDocument(cookies.token, selectedDownloadIdMaster);
    //     if (response === 0) {
    //         // window.location.reload();
    //         loadDocumentData();
    //     }
    // }

    // const loadInventoryMasterId = async () => {
    //     try{
    //         console.log('loadinventorymasterid')
    //         let getInventoryMasterId = await findMasterIdFromEquipment(cookies.token, location.state.vesselEquipmentId);
    //         console.log(getInventoryMasterId);
    //         setListInventoryMasterId(getInventoryMasterId);
    //     }catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadDocumentData = async () => {
        try {
            // setShowDocumentDetailModal(false);
            // let countMaster = await getCountMasterDocument(cookies.token, searchParams.get('inventoryMasterId'));
            // console.log(countMaster);
            let countEquipment = await getCountMaintenancePlanDocument(cookies.token, searchParams.get('maintenancePlanId'));
            // console.log(countEquipment);
            // let count = countMaster + countEquipment
            let totalPage = countEquipment / itemPerPage;
            setTotalPage(totalPage);

            // if (countMaster > 0){
            //     let responseMaster = await findMasterDocumentById(cookies.token, searchParams.get('inventoryMasterId'), page, itemPerPage, orderBy.value, descending);
            //     // console.log(responseMaster);
            //     setListDocumentMaster(responseMaster);
            // }
            let responseEqp = await findMaintenancePlanDocumentById(cookies.token, searchParams.get('maintenancePlanId'), page, itemPerPage, orderBy.value, descending);
            // console.log(responseEqp);
            setListDocument(responseEqp);
            

            setLoading(false);
            // setLoading(false);
        } catch (exception) {
            setLoading(false);
            console.log(exception);
        }
    }

    

    // const loadSparepartCatalogDocumentById = async () => {
    //     try {
    //         let response = await getSparepartCatalogDocumentById(cookies.token, sparepartCatalogDocumentId);
    //         setDocumentToBeViewed(response);
    //         setLoading(false);
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    // const loadVesselEquipmentDocumentById = async () => {
    //     try {
    //         let response = await getVesselEquipmentDocumentById(cookies.token, vesselEquipmentDocumentId);
    //         setDocumentToBeViewed(response);
    //         setLoading(false);
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    // const downloadSparepartCatalogDocument = async () => {
    //     try {
    //         let response = await getSparepartCatalogDocumentById(cookies.token, downloadSparepartCatalogDocumentId);
    //         triggerBase64Download(response.document_base64, response.document_name);
    //         setLoading(false);
    //         setdownloadSparepartCatalogDocumentId("");
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    // const downloadVesselEquipmentDocument = async () => {
    //     try {
    //         let response = await getVesselEquipmentDocumentById(cookies.token, downloadVesselEquipmentDocumentId);
    //         triggerBase64Download(response.document_base64, response.document_name);
    //         setLoading(false);
    //         setDownloadVesselEquipmentDocumentId("");
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/Vessel");
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={` ${searchParams.get('maintenancePlanName')} Documents`}
                    useActionContainer={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_equipment_document.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            {/* <div className="master-table-searchbar-container">
                            <div className="master-table-searchbar-textbox">
                                <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Dokumen)"
                                    onKeyPress={async (e) => {
                                        if (e.charCode === 13) {
                                            setLoading(true);
                                            await loadDocumentData();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                    }} />
                            </div>
                            <div className="master-table-searchbar-button">
                                <div style={{ paddingRight: 5 }}>
                                    <Button onClick={async () => {
                                        setLoading(true);
                                        await loadDocumentData();
                                    }}><Search /></Button>
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                    <Button onClick={async () => {
                                        setLoading(true);
                                        setSearchQuery("");
                                        await loadDocumentData();
                                    }}><ArrowClockwise /></Button>
                                </div>
                            </div>
                        </div> */}
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("vessel_equipment_document.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name6")}</div>
                                </div>
                                {
                                    listDocument.map((document, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setSelectedDocumentIdEquipment(document.id)
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setSelectedDocumentDownloadIdEquipment(document.id);
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="danger" disabled={document.type === 'sparepart' ||  (cookies.extCustomerId !== "null" && cookies.logingAs === 'Customer') ? true : !deletedButton} onClick={() => {
                                                        if (window.confirm(`${t("vessel_equipment_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            let documentId = document.id
                                                            setSelectedDeleteIdEquipment(documentId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            {/* <Paginations
                            itemPerPage={itemPerPage}
                            totalPage={totalPage}
                            page={page}
                            setPage={setPage}
                            setItemPerPage={setItemPerPage}
                            itemPerPageSelection={itemPerPageSelection}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            orderBySelection={orderByList}
                            isDescActive={descending}
                            setIsDescActive={setDescending}
                        /> */}


                            <Loading
                                loading={loading}
                            />
                        </div>


                    } />



                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name} 
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
    
                            }} onClick={() => { 
                                setShowDocumentDetailModal(false);
                                }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_equipment_document.button_closed")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed.document_base64}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                    {t("vessel_equipment_document.page_text")} {pageNumber} {t("vessel_equipment_document.from_text")} {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.8);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(0.5);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 2);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

                </Modal>

                {/* <Modal show={showDocumentUploadModal} >
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Upload Dokumen`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    setLoading(true);
                                    setNewDocument({ ...newDocument, done: true });
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>File Dokumen</Form.Label>
                                            <Form.Control onChange={async (e) => {
                                                console.log(e.target.files[0])
                                                setDocumentFile(e.target.value)
                                                let base64Doc = await convertBase64(e.target.files[0]);
                                                setNewDocument({ ...newDocument, document_base64: base64Doc.toString() });
                                            }} type="file" required></Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Dokumen</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                                }} value={newDocument.document_name} type="text" placeholder="Nama Dokumen" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nomor Refensi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewDocument({ ...newDocument, reference_number: e.target.value })
                                                }} value={newDocument.reference_number} type="text" placeholder="Nomor Referensi" ></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Deskripsi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setNewDocument({ ...newDocument, description: e.target.value })
                                                }} value={newDocument.description} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" disabled={disabledButton} type="submit">
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            resetUploadForm();

                                            setShowDocumentUploadModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />


                </Modal> */}

                <Modal show={showDocumentUploadModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setShowDocumentUploadModal(false);
                        resetUploadForm();
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("vessel_equipment_document.modal_add_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButton(true);
                            setLoading(true);
                            // setNewDocument({ ...newDocument, done: true });
                            uploadDocument();
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_equipment_document.field_document")}</Form.Label>
                                <Form.Control onChange={async (e) => {

                                    if (e.target.files[0].type === "application/pdf") {
                                        setDocumentFile(e.target.value)
                                        let base64Doc = await convertBase64(e.target.files[0]);
                                        setNewDocument({ ...newDocument, document_base64: base64Doc.toString(), maintenance_plan_id: searchParams.get('maintenancePlanId')});
                                        setUploadFileImageError("");
                                        setDisabledButton(false)

                                    } else {
                                        setDocumentFile("")
                                        setNewDocument({ ...newDocument, document_base64: "" });
                                        setUploadFileImageError(`${t("vessel_equipment_document.error_pdf_file")}`)
                                        setDisabledButton(true)
                                    }

                                }} type="file" required></Form.Control>
                                 {
                                    uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                }
                            </Form.Group>

                            <Form.Group style={{
                                display: "flex",
                                minWidth: "100%",
                                flexDirection: "column",
                            }} className="mb-3">
                                <Form.Label>{t("vessel_equipment_document.field_document_name")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                }} value={newDocument.document_name} type="text" placeholder="" required></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_equipment_document.field_reference_number")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, reference_number: e.target.value })
                                }} value={newDocument.reference_number} type="text" placeholder="" ></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_equipment_document.field_description")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, description: e.target.value })
                                }} value={newDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>

                            </Form.Group>

                            <Button style={{width:100}} variant="primary" disabled={disabledButton} type="submit">
                            {t("vessel_equipment_document.save_button")}
                            </Button>
                            <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                resetUploadForm();

                                setShowDocumentUploadModal(false);
                            }}>
                                {t("vessel_equipment_document.cancel_button")}
                            </Button>

                        </Form>

                    </div>

                </Modal>
            </Container>

        </>
    );

}