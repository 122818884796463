import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getProject, deleteProject, getProjectCount } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { EyeFill, ArrowLeft, PencilFill, Trash, Search, ArrowClockwise, FilePlusFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import ContainerBox from "../../../../Components/ContainerBox";

export default function ListProjectContractPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listProject, setListProject] = useState([]);
    const [loading, setLoading] = useState(true);
    const [projectCount, setProjectCount] = useState(0);
    const [page, setPage] = useState(0);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("")
    const [itemPerPage, setItemPerPage] = useState(50);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nomor Proyek",
        value: "project_number"
    });

    const orderByList = [
        {
            label: "Nomor Proyek",
            value: "project_number"
        }
    ]
    const [isDescActive, setIsDescActive] = useState(true);



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadProject();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadProject();
    }, [page]);

    useEffect(() => {
        loadProject();
    }, [itemPerPage]);

    useEffect(() => {
        loadProject();
    }, [orderBy]);

    useEffect(() => {
        loadProject();
    }, [isDescActive]);

    useEffect(() => {
        if (searchQuery === "") {
            loadProject()
        }
    }, [searchQuery]);


    const loadProject = async () => {
        try {
            // let projectCount = await getProjectCount(cookies.token);

            let descending = isDescActive;
            let listProject = await getProject(cookies.token, cookies.languageId,cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            let newList = listProject.filter(p => p.contract_id === location.state.contractId);
            setProjectCount(newList.length);
            let totalPage = newList.length / itemPerPage;
            setTotalPage(totalPage);
            setListProject(newList);
            setLoading(false);
        } catch (exception) {

        }

    }

    const deleteProjectData = async (id) => {
        try {
            // let text;
            if (window.confirm("Anda ingin menghapus Project ini?") == true) {
                let deleteSelectedProject = await deleteProject(cookies.token, id);
                if (deleteSelectedProject.success) {
                    if (deleteSelectedProject.error_code === 0) {
                        alert("Data Telah Dihapus!");
                    }
                    else {
                        alert(deleteSelectedProject.error_message);
                    }
                }
                else {
                    alert(deleteSelectedProject.error_message);
                }
                loadProject();
            }

        } catch (exception) {
            alert("Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin");
            console.log(exception);
        }
    }




    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`Daftar Proyek ${location.state.contractName}`}

                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Contract"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Proyek)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadProject();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadProject();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadProject();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Proyek</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Tipe Proyek</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Perusahaan</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Kapal</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listProject.map((project, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 0.5 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{project.project_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{project.project_type_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{project.company_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{project.vessel_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/Project/Detail", { state: { projectId: project.id } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={disabledCustomerForm} variant="danger"
                                                        onClick={event => {
                                                            deleteProjectData(project.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}   