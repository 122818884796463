import React, { useEffect, useState, useRef } from "react";
import { Modal, Col, Button, Row, Form, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import { isTokenValid } from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import "./index.css";
import RPILogo from "../../Assets/Images/logo-qpd (2).png";
import { Font } from "@react-pdf/renderer";

export default function CustomerLoginPageNew() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
  const [cookies, setCookie] = useCookies(["token", "userId", "userName", "userEmail"]);
  const [errorMessage, setErrorMessage] = useState("");
  const txtUsername = useRef(null);
  const txtPassword = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function checkCookies() {
      if (cookies.token) {
        let isAuthenticated = await isTokenValid(cookies.token);
        if (isAuthenticated)
          navigate("/Customer/Dashboard");
      } else {
        navigate("/Customer/Login");
      }
    }
    checkCookies();
    let interval = setInterval(() => {
      if (txtPassword.current) {
        setPassword(txtPassword.current.value);
        clearInterval(interval);
      }
    }, 100);
    let interval2 = setInterval(() => {
      if (txtUsername.current) {
        setUsername(txtUsername.current.value)
        clearInterval(interval2)

      }
    }, 100);

  }, []);

  useEffect(() => {
    checkUsernamePasswordLength();
  }, [username]);

  useEffect(() => {
    checkUsernamePasswordLength();
  }, [password]);

  const login = async () => {
    try {
      let response = await axios.post(`${config.API_ENDPOINT}/api/UserAccount/login`, {
        username: username,
        password: password
      });
      console.log(response);
      if (response.data.error_code === 0 ) {
        let loginData = response.data.data;
        loginData.loging_as = 'User';
        setCookie("token", loginData.token, { path: "/" });
        setCookie("userId", loginData.user_account_id, { path: "/" });
        setCookie("userName", loginData.login_username, { path: "/" });
        setCookie("userEmail", loginData.email, { path: "/" });
        setCookie("languageId", loginData.language_id, { path: "/" });
        setCookie("staffId", loginData.staff_id, { path: "/" });
        setCookie("roleId", loginData.role_id, { path: "/" });
        setCookie("level", loginData.user_level, { path: "/" });
        setCookie("division", loginData.division_code, { path: "/" });
        setCookie("extCustomerId", loginData.customer_id, { path: "/" });
        setCookie("logingAs", loginData.loging_as, { path: "/" });
        setCookie("language_code", loginData.language_iso_code, { path: "/" });
        navigate("/Customer/Dashboard");
      } else {
        setErrorMessage("Failed to sign in, wrong username and/or password.");
      }

    }
    catch (exception) {
      console.log(exception);
      setErrorMessage("Error has been occurred, please contact system administrator");
    }
  }

  const checkUsernamePasswordLength = () => {
    if (username.length > 0 && password.length > 0)
      setLoginButtonDisabled(false);
    else
      setLoginButtonDisabled(true);
  }

  return (

    <>
      <div className="mainPageLogin">
        <div className="mainLoginLogin">
          <img className="titleImg" src={RPILogo} />
          <h2 className="titleTxt">VESSEL MANAGEMENT SYSTEM</h2>
          <p className="paragraphTxtLogin">Login To Continue</p>
          <Form className="formGroup" onSubmit={(e) => {
            e.preventDefault();
            login();
          }}>
            <Form.Group controlId="formBasicUsername">
              <Form.Label className="titleForm">Username</Form.Label>
              <Form.Control onChange={(e) => {
                setUsername(e.target.value);
                checkUsernamePasswordLength();
              }} value={username} ref={txtUsername} type="text" placeholder="Username" />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className="titleForm">Password</Form.Label>
              <Form.Control onChange={(e) => {
                setPassword(e.target.value);
                checkUsernamePasswordLength();
              }} value={password} ref={txtPassword} type="password" placeholder="Password" />
            </Form.Group>
            {
              //   <p className="errorTxt">Error Message</p>
              errorMessage && <p className="errorTxt">{errorMessage}</p>
            }


            <div className="d-grid mt-3">
              <Button disabled={loginButtonDisabled} type="submit" className="btn btn-primary btn-login" >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
    // <>
    //       <div className="mainPageLogin">
    //           <div className="mainLoginLogin">
    //               <img className="titleImg" src={RPILogo} />
    //               <h2 className="titleTxt">VESSEL MANAGEMENT SYSTEM</h2>
    //               <Form onSubmit={(e) => {
    //                   e.preventDefault();
    //                   login();
    //               }}>
    //                   <p className="paragraphTxt">Login To Continue</p>
    //                   <Form.Group className="formGroup" controlId="formBasicUsername">
    //                       <Form.Label className="titleForm">Username</Form.Label>
    //                       <Form.Control onChange={(e) => {
    //                           setUsername(e.target.value);
    //                           checkUsernamePasswordLength();
    //                       }} value={username} ref={txtUsername} type="text" placeholder="Username" />
    //                   </Form.Group>

    //                   <Form.Group className="formGroup" controlId="formBasicPassword">
    //                       <Form.Label className="titleForm">Password</Form.Label>
    //                       <Form.Control onChange={(e) => {
    //                           setPassword(e.target.value);
    //                           checkUsernamePasswordLength();
    //                       }} value={password} ref={txtPassword} type="password" placeholder="Password" />
    //                   </Form.Group>
    //                   {
    //                       //   <p className="errorTxt">Error Message</p>
    //                       errorMessage && <p className="errorTxt">{errorMessage}</p>
    //                   }


    //                   <div className="d-grid mt-3">
    //                       <Button disabled={loginButtonDisabled} type="submit" className="btn btn-primary btn-login" >
    //                           Login
    //                       </Button>
    //                   </div>
    //               </Form>

    //           </div>
    //       </div>
          
    // </>

  );
}
