// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bitLamp3 {
    display: flex;
    align-items: center;
}

.bitLamp3On {
    background: #40C110;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 17px;
    height: 17px;
}

.bitLamp3Off {
    background: #E72D2D;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 17px;
    height: 17px;
}

.textBitLamp3 {
    margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/BitLamp3/BitLamp3.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".bitLamp3 {\n    display: flex;\n    align-items: center;\n}\n\n.bitLamp3On {\n    background: #40C110;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 17px;\n    height: 17px;\n}\n\n.bitLamp3Off {\n    background: #E72D2D;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 17px;\n    height: 17px;\n}\n\n.textBitLamp3 {\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
