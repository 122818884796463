import "./index.css";
import BitLamp from "../../../Components/NewBitLamp/NewBitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import moment from "moment";

export default function CustomerSMSWatertightDoorPage(props) {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const { smsSerialNumber, vesselId} = props;
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([13, 6]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [bitlampList, setBitlampList] = useState({

        M0:
        {
            text: "WHP",
            alarm: true,
            coordinate: "M0",
            value: false
        }, 
        M2: {
            text: "WHSB",
            alarm: true,
            coordinate: "M2",
            value: false
        }, 
        M264: {
            text: "Deck Store",
            alarm: true,
            coordinate: "M264",
            value: false
        }, 
        M266: {
            text: "E. Room P",
            alarm: true,
            coordinate: "M266",
            value: false
        }, 
        M268: {
            text: "E. Room SB",
            alarm: true,
            coordinate: "M268",
            value: false
        }, 
        M270: {
            text: "CO2 Room",
            alarm: true,
            coordinate: "M270",
            value: false
        },
        M272: {
            text: "Steering Gear Room P",
            alarm: true,
            coordinate: "M272",
            value: false
        },
        M274: {
            text: "Steering Gear Room SB",
            alarm: true,
            coordinate: "M274",
            value: false
        },
        M276: {
            text: "Emergency Hatch Crew Room",
            alarm: true,
            coordinate: "M276",
            value: false
        },
        M278: {
            text: "Emergency Hatch E. Room",
            alarm: true,
            coordinate: "M278",
            value: false
        },
        M360:
        {
            text: "Engine Room 1",
            alarm: true,
            coordinate: "M360",
            value: false
        }, 
        M362: {
            text: "Engine Room 2",
            alarm: true,
            coordinate: "M362",
            value: false
        },
        M364: {
            text: "Void",
            alarm: true,
            coordinate: "M364",
            value: false
        }
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    

    useEffect(() => {
        console.log(bitlampList);
    }, [bitlampList]);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId);
            console.log('rsp', response);
            let receivedDate = moment(response[0].read_date).format("yyyy-MM-DD HH:mm:ss");
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].text = find.coordinate_desc;
                    bitlampListTmp[bl].alarm = find.alarm;
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            setLoading(false);
           
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
       
        <>
            <div className="WTDNewRes">
                <div className="containerBoxWatertightDoor">
                    <div className="marginForTitle">
                        <p className="titleWatertightdoor">{'Watertight Door'}</p>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        flexWrap: "wrap",
                        paddingLeft: '40px'
                    }}>
                        {/* <div className="innercontainerBoxWatertightDoorLeft"> */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M270"].text} bitlampColor="black" alarm={bitlampList["M270"].value} />
                            </div>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M264"].text} bitlampColor="black" alarm={bitlampList["M264"].value} />
                            </div>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M276"].text} bitlampColor="black" alarm={bitlampList["M276"].value} />
                            </div>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M278"].text} bitlampColor="black" alarm={bitlampList["M278"].value} />
                            </div>
                        </div>
                        {/* <div className="innercontainerBoxWatertightDoorMiddle"> */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1

                        }}>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M266"].text} bitlampColor="black" alarm={bitlampList["M266"].value} />
                            </div>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M268"].text} bitlampColor="black" alarm={bitlampList["M268"].value} />
                            </div>
                            <div className="marginForBitlamp">
                                <BitLamp text={"VOID"} bitlampColor="black" alarm={bitlampList["M274"].value} />
                            </div>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M0"].text} bitlampColor="black" alarm={bitlampList["M0"].value} />
                            </div>
                        </div>
                        {/* <div className="innercontainerBoxWatertightDoorRight"> */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1

                        }}>
                            <div className="marginForBitlamp">
                                <BitLamp text={bitlampList["M2"].text} bitlampColor="black" alarm={bitlampList["M2"].value} />
                            </div>
                        </div>
                        {/* <div className="marginForBitlamp">
                                    <BitLamp text={bitlampList["M272"].text} bitlampColor="black" alarm={bitlampList["M272"].value} />
                                </div> */}


                    </div>
                </div>
                {/*-----------------------------BILGES-----------------------------*/}
                <div className="containerBoxBILGES">
                        <div className="marginForTitle">
                            <p className="titleWatertightdoor">{'Bilges'}</p>
                        </div>
                        <div className="innercontainerBoxWatertightDoor">
                            {/* <div className="innercontainerBoxWatertightDoorLeft"> */}
                                <div className="marginForBitlamp">
                                    <BitLamp text={bitlampList["M360"].text} bitlampColor = "black" alarm={bitlampList["M360"].value}/>
                                </div>
                            {/* </div> */}
                            {/* <div className="innercontainerBoxWatertightDoorMiddle"> */}
                                <div className="marginForBitlamp">
                                    <BitLamp text={bitlampList["M362"].text} bitlampColor = "black" alarm={bitlampList["M362"].value}/>
                                </div>
                            {/* </div> */}
                            {/* <div className="innercontainerBoxWatertightDoorRight"> */}
                                <div className="marginForBitlamp">
                                    <BitLamp text={bitlampList["M364"].text} bitlampColor = "black" alarm={bitlampList["M364"].value}/>
                                </div>
                            {/* </div> */}
                        </div>
                </div>
                <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
                    </div>
            </div>
        </>
    );
}
