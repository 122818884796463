import axios from "axios";
import Config from "../Config/Config";
import moment from "moment";

export const getSurveyScheduleByVesselIdCount = async (token, vesselId, languageId, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveySchedule/count`, {
            params: {
                vessel_id: vesselId,
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}

export const getSurveyScheduleByVesselId = async (token, vesselId, languageId, offset = 0, limit = 50, orderBy = "created_date", descending = false, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveySchedule/findAll`, {
            params: {
                vessel_id: vesselId,
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}

export const getScheduleForDashboard = async (token, languageId, startDate, endDate) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveySchedule/findForDashboard`, {
            params: {
                language_id: languageId,
                start_date: startDate,
                end_date: endDate
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}

export const deleteSurveySchedule = async (token, surveyScheduleId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SurveySchedule/delete`, { id: surveyScheduleId },
            {
                headers: {
                    token: token
                }
            });
        if (response.data.error_code === 0) {
            return (true);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}

export const insertUpdateSurveySchedule = async (token, schedule) => {
    try {
        let payload = schedule;
        // if (moment(schedule.last_survey_date).isValid())
        //     payload.last_survey_date = new Date(schedule.last_survey_date).toDateString();
        // else
        //     payload.last_survey_date = null;

        // if (moment(schedule.range_start_date).isValid())
        //     payload.range_start_date = new Date(schedule.range_start_date).toDateString();
        // else
        //     payload.range_start_date = null;

        // if (moment(schedule.range_end_date).isValid())
        //     payload.range_end_date = new Date(schedule.range_end_date).toDateString();
        // else
        //     payload.range_end_date = null;

        // if (moment(schedule.due_date).isValid())
        //     payload.due_date = new Date(schedule.due_date).toDateString();
        // else
        //     payload.due_date = null;

        // if (moment(schedule.scheduled_survey_date).isValid())
        //     payload.scheduled_survey_date = new Date(schedule.scheduled_survey_date).toDateString();
        // else
        //     payload.scheduled_survey_date = null;
        
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SurveySchedule/insertUpdate`, payload,
            {
                headers: {
                    token: token
                }
            });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}

export const getScheduleCrweForDashboard = async (token, languageId, startDate, endDate,extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveySchedule/findForDashboardCrew`, {
            params: {
                language_id: languageId,
                start_date: startDate,
                end_date: endDate,
                ext_customer_id: extCustomerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}