import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config/Config";
import { useCookies } from "react-cookie";
import { getVesselSchedule, isTokenValid, getCountVesselSchedule, deleteVesselSchedule, getUserAccessById, getVesselScheduleForCalender } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash, XSquare, XSquareFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "../../../../react-big-calendar.css";
import Moment from "moment";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import TextBox from "../../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);




export default function VesselSchedulePage() {
  const { t, i18n } = useTranslation();
  const [listVesselSchedule, setListVesselSchedule] = useState([]);
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [vesselScheduleModal, setVesselScheduleModal] = useState("");
  const [vesselScheduleId, setVesselScheduleId] = useState("");
  const [updatedButton, setUpdatedButton] = useState("");
  const [isSearched, setIsSearched] = useState(0);
  const [deletedButton, setDeletedButton] = useState("");
  const [resetSearch, setResetSearch] = useState(0);
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [removeId, setRemoveId] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemPerPageSelection, setItemPerPageSelection] = useState([2, 10, 15, 20, 50]);
  const [orderBy, setOrderBy] = useState({
    label: `${t("index_vessel_schedule.order_name1")}`,
    value: "vessel.vessel_name"
  });
  const orderByList = [{
    label: `${t("index_vessel_schedule.order_name1")}`,
    value: "vessel.vessel_name"
  }];
  const [countryCount, setCountryCount] = useState(0);
  const [descending, setDescending] = useState(false);
  const [dateRangeCrew, setDateRangeCrew] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
  });
  const [calendarCrewEvent, setCalendarCrewEvent] = useState([]);
  const localizer = momentLocalizer(Moment)
  const [crewScheduleToBeViewed, setCrewScheduleToBeViewed] = useState({
    id: 0,
    vessel_name: "",
    start_date: "",
    end_date: "",
    list_crew: []
  });
  const [listCrewSchedule, setListCrewSchedule] = useState([]);




  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated)
        navigate("/");
      else {
        if (cookies.language_code === "id-id") {
          i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
          i18n.changeLanguage("en");
        }
        loadVesselSchedule();
        loadAccess();
        initDashboardCrew()
      }
    }
    checkCookies();
  }, []);

  useEffect(() => {
    // if (isSearched !== 0)
    loadVesselSchedule();
  }, [page]);

  useEffect(() => {
    if (isSearched !== 0)
      loadVesselSchedule();
  }, [itemPerPage]);

  useEffect(() => {
    if (isSearched !== 0)
      loadVesselSchedule();
  }, [orderBy]);

  // useEffect(() => {
  //   if (searchQuery === "")
  //     loadVesselSchedule();
  // }, [searchQuery]);

  useEffect(() => {
    if (resetSearch !== 0) {
      loadVesselSchedule()
    }
  }, [resetSearch])

  useEffect(() => {
    if (isSearched !== 0)
      loadVesselSchedule()
  }, [descending]);

  useEffect(() => {
    if (removeId !== "")
      removeVesselSchedule();
  }, [removeId])

  useEffect(() => {
    setLoading(true);
    initDashboardCrew();
  }, [dateRangeCrew]);



  const loadVesselSchedule = async () => {
    try {
      let count = await getCountVesselSchedule(cookies.token, location.state.vesselId, searchQuery, false);
      let totalPage = count / itemPerPage;
      setTotalPage(totalPage);

      let listVesselSchedule = await getVesselSchedule(cookies.token, location.state.vesselId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
      console.log(listVesselSchedule)
      setListVesselSchedule(listVesselSchedule)
      setLoading(false);
      setResetSearch(0);
      setIsSearched(0);
    } catch (exception) {
      console.log(exception)
    }
  }

  const removeVesselSchedule = async () => {
    try {
      let response = await deleteVesselSchedule(cookies.token, removeId);
      if (response === 0) {
        alert(`${t("index_vessel_schedule.delete_success_alert")}`);
        initDashboardCrew();
        setRemoveId("")
        setVesselScheduleModal(false);
      }

    } catch (exception) {
      console.log(exception)
    }
  }

  const loadAccess = async () => {
    try {
      // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
      let response = await getUserAccessById(cookies.token, window.location.pathname);
      if (response) {
        setDeletedButton(response.deleted);
        setUpdatedButton(response.updated)
      }
      setDeletedButton(true);
      setUpdatedButton(true);
    } catch (exception) {
      console.log(exception);
    }
  }

  const initDashboardCrew = async () => {
    try {
      let listSchedule = await getVesselScheduleForCalender(cookies.token, location.state.vesselId, cookies.languageId, dateRangeCrew.start || new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        dateRangeCrew.end || new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), cookies.extCustomerId);
      setListCrewSchedule(listSchedule);


      let listEvent = [];
      for (let i = 0; i < listSchedule.length; i++) {
        if (listSchedule[i].event_type === "schedule_range_date") {
            if (new Date(listSchedule[i].start_date).getMonth() === new Date().getMonth() && new Date(listSchedule[i].start_date).getFullYear() === new Date().getFullYear()) {
                let evt = {
                    id: listSchedule[i].id,
                    title: `${listSchedule[i].vessel_name} `,
                    allDay: true,
                    start: new Date(listSchedule[i].start_date),
                    end: new Date(listSchedule[i].end_date),
                    event_type: listSchedule[i].event_type,
                    detail: listSchedule[i],
                    list_crew: listSchedule[i].list_crew
                };
                listEvent.push(evt);
            } else {
                let evt = {
                    id: listSchedule[i].id,
                    title: `${listSchedule[i].vessel_name} `,
                    allDay: true,
                    start: new Date(listSchedule[i].start_date),
                    end: new Date(listSchedule[i].end_date),
                    event_type: listSchedule[i].event_type,
                    detail: listSchedule[i],
                    list_crew: listSchedule[i].list_crew
                };
                listEvent.push(evt);
            }

        }

        if (listSchedule[i].event_type === "schedule_due_date") {
          let evt = {
            id: listSchedule[i].id,
            title: `${listSchedule[i].vessel_name} `,
            allDay: true,
            start: new Date(listSchedule[i].end_date),
            end: new Date(listSchedule[i].end_date),
            event_type: listSchedule[i].event_type,
            detail: listSchedule[i],
            list_crew: listSchedule[i].list_crew
          };
          listEvent.push(evt);
        }

        if (listSchedule[i].event_type === "scheduled_date") {
          let evt = {
            id: listSchedule[i].id,
            title: `${listSchedule[i].vessel_name} `,
            allDay: true,
            start: new Date(listSchedule[i].start_date),
            end: new Date(listSchedule[i].start_date),
            event_type: listSchedule[i].event_type,
            detail: listSchedule[i],
            list_crew: listSchedule[i].list_crew
          };
          listEvent.push(evt);
        }
      }
      // console.log(listEvent)
      setCalendarCrewEvent(listEvent);
      setLoading(false);
    } catch (exception) {
      console.log(exception);
    }
  }

  const getDayPropsCrew = (e) => {
    let dayProps = {
      style: {}
    };
    let evtIdx = listCrewSchedule.findIndex(p => new Date(p.start_date).getDate() === new Date(e).getDate() &&
      new Date(p.start_date).getMonth() === new Date(e).getMonth() && new Date(p.start_date).getFullYear() === new Date(e).getFullYear());
    if (evtIdx) {
      dayProps.style = {
        backgroundColor: "#fff"
      }
    } else {
      dayProps.style = {
        backgroundColor: "#74ad31"
      }
    }
    return (dayProps);
  }

  const getEventPropsCrew = (e) => {
    let eventProps = {
      style: {}
    };
    if (e.event_type === "schedule_range_date") {
      eventProps.style = {
        backgroundColor: "#b1eb34",
        color: "black"
      }
    } else if (e.event_type === "schedule_due_date") {
      eventProps.style = {
        backgroundColor: "#ff0000"
      }
    } else if (e.event_type === "scheduled_date") {
      eventProps.style = {
        backgroundColor: "#00bfff"
      }
    }

    return (eventProps);
  }

  return (
    <>
      <Navbar />
      <Container fluid style={{
        display: "flex",
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}>
        <ContainerBox
          titleCaption={`${t("index_vessel_schedule.header_title")} ${location.state.vesselName}`}
          useActionContainer={true}
          actionContainerChild={
            <div>
              <Link to={`/Vessel/Vesselschedule/Detail?id=&vesselId=${location.state.vesselId}`} target="_blank">
                <Button disabled={!updatedButton} variant="secondary" style={{

                }} >
                  <div style={{
                    display: "flex",
                    flex: 1,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignSelf: "center",
                  }}>
                    <div style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      flex: 1,
                    }}><FilePlusFill size={32} /></div>
                    <div style={{
                      display: "flex",
                      flex: 8,
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      paddingLeft: 10,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}>{t("index_vessel_schedule.add_button")}</div>
                  </div>
                </Button>
              </Link>
            </div>
          }
          childContent={
            <div>
              {/* <div className="master-table-inner-container">
               <div className="master-table-searchbar-container">
                 <div className="master-table-searchbar-textbox">
                   <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Kapal)"
                    onKeyPress={async (e) => {
                      if (e.charCode === 13) {
                        setPage(0);
                        setLoading(true);
                        await loadVesselSchedule();
                      }
                    }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }} />
                </div>
                <div className="master-table-searchbar-button">
                  <div style={{ paddingRight: 5 }}>
                    <Button onClick={async () => {
                      setLoading(true);
                      setPage(0);
                      await loadVesselSchedule();
                    }}><Search /></Button>
                  </div>
                  <div style={{ paddingLeft: 5 }}>
                    <Button onClick={async () => {
                      setLoading(true);
                      setSearchQuery("");
                      setResetSearch(1);
                      // await loadVesselSchedule();
                    }}><ArrowClockwise /></Button>
                  </div>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <div style={{ flex: 1 }} className="table-header-content">#</div>
                  <div style={{ flex: 4 }} className="table-header-content">Nama Kapal</div>
                  <div style={{ flex: 4 }} className="table-header-content">Tanggal Mulai</div>
                  <div style={{ flex: 4 }} className="table-header-content">Tanggal Selesai</div>
                  <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                  <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                </div>
                {
                  listVesselSchedule.map((vesselSched, index) => {
                    return (<div className="table-body">
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{vesselSched.vessel_name}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{vesselSched.start_date ? Moment(vesselSched.start_date).format("DD/MM/yyyy"): ""}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{vesselSched.end_date? Moment(vesselSched.end_date).format("DD/MM/yyyy"): ""}</p></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                        <Link to={`/Vessel/VesselSchedule/Detail?id=${vesselSched.id}`} target="_blank">
                          <Button variant="primary"><PencilFill />
                          </Button>
                          </Link>
                        </div></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button disabled={!deletedButton} variant="danger" onClick={() => {
                            if (window.confirm(`Apakah Anda Yakin Menghapus Data Jadwal ${vesselSched.vessel_name}`))
                              setRemoveId(vesselSched.id)
                          }}><Trash />
                          </Button>
                        </div></div>


                    </div>)
                  }
                  )
                }
              </div>
              <div style={{
                paddingTop: 20,
              }}>

              </div>
              <Paginations
                itemPerPage={itemPerPage}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                itemPerPageSelection={itemPerPageSelection}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                orderBySelection={orderByList}
                isDescActive={descending}
                setIsDescActive={setDescending}
                setIsSearched={setIsSearched}
              />
              <Loading
                loading={loading}
              />
            </div> */}


              <div style={{
                display: "flex",
                flexDirection: "row",
                padding: 10
              }}>


                <div style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                  padding: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#282c34",
                  borderRadius: 5,
                  marginRight: 5,
                  flexDirection: "column"
                }}>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    borderRadius: 5,
                    fontWeight: "bold",
                    fontSize: 20
                  }}>
                    {t("index_vessel_schedule.activity_calendar")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 800,
                  }}>
                    <Calendar
                      localizer={localizer}
                      events={calendarCrewEvent}
                      defaultDate={new Date()}
                      selectable
                      popup
                      startAccessor="start"
                      endAccessor="end"
                      messages={{
                        agenda: `${t("index_vessel_schedule.agenda")}`,
                        allDay: `${t("index_vessel_schedule.all_day")}`,
                        month: `${t("index_vessel_schedule.month")}`,
                        day: `${t("index_vessel_schedule.day")}`,
                        today: `${t("index_vessel_schedule.today")}`,
                        previous: `${t("index_vessel_schedule.previous")}`,
                        next: `${t("index_vessel_schedule.next")}`,
                        date: `${t("index_vessel_schedule.date")}`,
                        noEventsInRange: `${t("index_vessel_schedule.text_no_event")}`,
                        time: `${t("index_vessel_schedule.time")}`,
                        tomorrow: `${t("index_vessel_schedule.tomorrow")}`,
                        week: `${t("index_vessel_schedule.week")}`,
                        work_week: `${t("index_vessel_schedule.work_week")}`,
                        yesterday: `${t("index_vessel_schedule.yesteday")}`
                      }}
                      onSelectEvent={(e) => {
                        setCrewScheduleToBeViewed(e.detail);
                        setVesselScheduleModal(true);
                      }}
                      onRangeChange={(e) => {
                        // console.log(e);
                        setDateRangeCrew(e);
                      }}
                      dayPropGetter={getDayPropsCrew}
                      eventPropGetter={getEventPropsCrew}
                    />
                  </div>
                </div>






              </div>

            </div>

          } />

        <Modal size={"xl"} show={vesselScheduleModal} onHide={() => {
          setVesselScheduleModal(false);
        }}>

          <ContainerBox
            containerPos="inner"
            useActionContainer={true}
            actionContainerChild={
              <div id="button-color">
                <Button variant="primary" onClick={() => {
                  setVesselScheduleModal(false);
                }}>
                  <div style={{
                    display: "flex",
                    flex: 1,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignSelf: "center",
                  }}>
                    <div style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      flex: 1,
                    }}><XSquare size={32} /></div>

                  </div>
                </Button>

              </div>
            }
            titleCaption={`${t("index_vessel_schedule.schedule_modal")}`}
            childContent={
              <div style={{
                display: "flex",
                minWidth: "100%",
                justifyContent: "center",
                flex: 1,
                padding: 10,
              }}>
                <Form style={{
                  width: "100%"
                }} onSubmit={(e) => {
                  // e.preventDefault();
                  // setShowScheduleDetailModal(false);
                }}>
                  <div style={{
                    display: "flex",
                    minWidth: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <TextBox disabledForm={true} value={crewScheduleToBeViewed.vessel_name} placeholder=""
                        caption={`${t("index_vessel_schedule.field_vessel_name")}`} setValueFunction={(e) => {

                        }} />
                    </Form.Group>
                  </div>

                  <div style={{
                    display: "flex",
                    minWidth: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <TextBox disabledForm={true} value={Moment(crewScheduleToBeViewed.start_date).format("DD/MM/yyyy")} placeholder=""
                        caption={`${t("index_vessel_schedule.field_start_date")}`} setValueFunction={(e) => {

                        }} />
                    </Form.Group>
                  </div>
                  <div style={{
                    display: "flex",
                    minWidth: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <TextBox disabledForm={true} value={Moment(crewScheduleToBeViewed.end_date).format("DD/MM/yyyy")} placeholder=""
                        caption={`${t("index_vessel_schedule.field_end_date")}`} setValueFunction={(e) => {

                        }} />
                    </Form.Group>
                  </div>
                  <div style={{
                    display: "flex",
                    minWidth: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <Form.Label>{t("index_vessel_schedule.field_from")}</Form.Label>
                      <Form.Control disabled={true} value={crewScheduleToBeViewed.departing_from}></Form.Control>
                    </Form.Group>
                  </div>

                  <div style={{
                    display: "flex",
                    minWidth: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <Form.Label>{t("index_vessel_schedule.field_to")}</Form.Label>
                      <Form.Control disabled={true} value={crewScheduleToBeViewed.departure_destination}></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="table-container">
                    <div className="table-header">
                      <div style={{ flex: 1 }} className="table-header-content">#</div>
                      <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_schedule.vessel_table_name1")}</div>
                      <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_schedule.vessel_table_name2")}</div>
                      <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_schedule.vessel_table_name3")}</div>
                      <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel_schedule.vessel_table_name4")}</div>
                      <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel_schedule.vessel_table_name5")}</div>
                    </div>
                    {
                      crewScheduleToBeViewed.list_crew.map((crew, index) => {
                        return (
                          <div className="table-body">
                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.staff_name}</p></div>
                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.position_name}</p></div>
                            <div style={{ flex: 3 }} className="table-body-content"><p></p></div>
                            <div style={{ flex: 2 }} className="table-body-content"><p>{crew.start_date ? Moment(crew.start_date).format("DD-MM-yyyy") : ""}</p></div>
                            <div style={{ flex: 2 }} className="table-body-content"><p>{crew.end_date ? Moment(crew.end_date).format("DD-MM-yyyy") : ""}</p></div>


                          </div>
                        )
                      })
                    }
                  </div>
                  <div style={{ paddingBottom: 20 }}></div>

                  <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    flex: 1,
                  }}>
                    <Link to={`/Vessel/VesselSchedule/Detail?id=${crewScheduleToBeViewed.id}`} target="_blank">
                      <Button className="cancel" variant="primary" onClick={() => {



                      }}>
                        <PencilFill />

                      </Button>
                    </Link>
                    <Button className="cancel" variant="danger" onClick={() => {
                      if (window.confirm(`${t("index_vessel_schedule.delete_confirmation")} ${crewScheduleToBeViewed.vessel_name}`))
                        setRemoveId(crewScheduleToBeViewed.id)
                    }}>
                      <Trash />
                    </Button>
                  </div>
                </Form>
              </div>
            }
          />
        </Modal>
      </Container>
    </>
  );

}