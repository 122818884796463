import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useState, useEffect } from "react";
import moment from "moment";

export default function DynamicLineChartForFuelConsumption({dataFuel, minValue, maxValue, stepSize}) {
  const [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    setDataToShow(dataFuel);
  }, [dataFuel])
  // Hardcoded dummy data
  // const dummyData = Array.from({ length: 10 }, (_, index) => ({
  //   received_date: new Date(Date.now() - (10 - index) * 1000 * 60 * 60).toISOString(), 
  //   reading_value: Math.random() * 1000 + 2000 
  // }));

  const dummyData = [
    {
        received_date: "Mon",
        reading_value: 25
    },
    {
        received_date: "Tue",
        reading_value: 3
    },
    {
        received_date: "Wed",
        reading_value: 10
    },
    {
        received_date: "Thu",
        reading_value: 16
    },
    {
        received_date: "Fri",
        reading_value: 8
    },
  ]

  // const minValue = 0;
  // const maxValue = 400;
  // const stepSize = 100;

  // const lastValue = dummyData[dummyData.length - 1].reading_value.toFixed(2);

  // const xAxisValues = dummyData.map(data => {
  //   const date = new Date(data.received_date);
  //   return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  // });

  const xAxisValues = dataToShow ? dataToShow.map(data => moment(data.received_date).format("MM-DD HH:mm")) : [];
  const yAxisValues = dataToShow ? dataToShow.map(data => Number(data.reading_value)) : [];

  
  // const xAxisValues = dummyData ? dummyData.map(data => data.received_date) : [];
  // const yAxisValues = dummyData ? dummyData.map(data => data.reading_value) : [];

  const data = {
    labels: xAxisValues,
    datasets: [
      {
        label: 'Total Fuel Usage',
        data: yAxisValues,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#031e67',
        borderWidth: 2,
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      y: {
        min: minValue,
        max: maxValue,
        ticks: {
          stepSize: stepSize,
          color: '#031e67'
        },
      },
      x: {
        ticks: {
          color: '#031e67'
        }
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    animation: false
  };

  return (
        <Line
          data={data}
          options={options}
        />
  );
}
