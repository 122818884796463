import "../App.css";
import {Form} from "react-bootstrap";

export default function TextBox({
    caption,
    placeholder,
    value,
    controlType = "text",
    setValueFunction,
    asType,
    disabledForm = false,
    req =false,
}) {

    return (
        
        <div style={{ paddingBottom: 5 }}>
            {
                asType === undefined ?
            <div>
                <div style={{ fontWeight: "bold", paddingBottom: 5 }}>{caption} </div> 
                <Form.Control value={value} type={controlType} placeholder={placeholder}
                    onChange={setValueFunction} disabled={disabledForm} required={req}/>
            </div>
            :
            <div>
                <div style={{ fontWeight: "bold", paddingBottom: 5 }}>{caption}</div>
                <Form.Control value={value} as={asType} rows={3} placeholder={placeholder}
                    onChange={setValueFunction} disabled={disabledForm} required={req} />
            </div>

            }

        </div>
        

        
    )
};