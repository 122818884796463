import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ButtonSplashScreen.css"
// import { faCoffee } from "@fortawesome/free-solid-svg-icons";

export default function ButtonSplashScreen({ caption, icon, handleOnClick }) {

  return (
    <div>
      <Button
        variant="outline-primary"
        className="buttonSplashScreen"
        onClick={() => { handleOnClick() }}
        onTouchEnd={() => { handleOnClick() }}
      >
        {caption}
        <FontAwesomeIcon icon={icon} className="iconButton" />

      </Button>
    </div>
  );
}
