import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, getStaff, getDivision } from "../../../Helpers/ApplicationHelpers";
import { getInventoryMaster, getInventoryMasterCount, deleteInventoryMaster, getinventoryListToPrint, findAllInventoryMasterFromInventoryMasterList, getInventoryMasterCountFromInventoryMasterList } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import { BoxArrowUpRight, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, PrinterFill, Printer } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function InventoryMasterPage() {
    const { t, i18n } = useTranslation();

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listInventoryMaster, setListInventoryMaster] = useState([]);
    const [staffName, setStaffName] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [listPrint, setListPrint] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(false);
    const [idStock, setIdStock] = useState("");
    const [idDetailStock, setIdDetailStock] = useState("");
    const [totalStock, setTotalStock] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [inventoryMasterCount, setinventoryMasterCount] = useState(0);
    const [division, setDivision] = useState("");
    const [dateNow, setDateNow] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [listDivision, setListDivision] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    // const [orderBy, setOrderBy] = useState(["Inventory Name"]);
    const [orderBySelection, setOrderBySelection] = useState(["Inventory Name", "Inventory Type", "Divisi", "Unit", "Reference Price", "Brand", "Model", "Quantity"]);
    //Import React and Select 
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);

    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_master.order_name2")}`,
        value: "inventory_master_name"
    });

    const orderByList = [
        {
            label: `${t("index_inventory_master.order_name2")}`,
            value: "inventory_master_name"
        },
        {
            label: `${t("index_inventory_master.order_name3")}`,
            value: "type_name"
        },
        // {
        //     label: "Unit",
        //     value: "unit"
        // },
        // {
        //     label: "Harga Referensi",
        //     value: "reference_price"
        // },
        // {
        //     label: "Merk",
        //     value: "brand"
        // },
        // {
        //     label: "Model",
        //     value: "model"
        // },
        // {
        //     label: "Jumlah Stock",
        //     value: "Quantity"
        // },
        // {
        //     label: "Tanggal Input",
        //     value: "inventory.created_date"
        // }


    ]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadAccess();
                loadStaff();
                loadDivision();
                checkBackButton();
                // loadInventoryMaster();
                
            }
        }
        checkCookies();
        console.log(cookies);
    }, []);

    useEffect(() => {
        setLoading(true);
        loadInventoryMaster();
    }, [page]);

    useEffect(() => {
        // console.log('searchqueryfrombackbutton:', searchQuery);
        loadInventoryMaster();
    }, [searchQuery]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryMaster();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryMaster();
        }
    }, [orderBy]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryMaster();
        }
    }, [descending]);

    useEffect(() => {
        // console.log(division);
        if (division !== "") {
            loadInventoryMaster();
        }
    }, [division])

    useEffect(() => {
        if (resetSearch !== 0)
            loadInventoryMaster();
    }, [resetSearch]);

    // useEffect(() => {
    //     if (listPrint.length > 0) {
    //         handlesum();
    //     }
    // }, [listPrint]);

    useEffect(()=>{
        if(idStock !== "")
        loadPrintList();
    },[idStock]);

    useEffect(()=>{
        if(listPrint.length > 0){
            // handlesum();
            if(idStock === 0){
                printPageArea();
                setIdStock("")
            }else if(idStock === 1){
                printPageStock();
                setIdStock("")
            }
            setLoading(false);
        }
           
        
    },[listPrint])

    const checkBackButton = async()=>{
        try{
            if (location.state.backButton !== null){
                console.log(location.state.backButton);
                setSearchQuery(location.state.backButton);
            } else {
                loadInventoryMaster();
            }
        } catch (exception){
            console.log(exception);
        }
    }

    const loadPrintList = async()=>{
        try {
            let total = 0;
            let responseList = await getinventoryListToPrint(cookies.token, cookies.languageId, cookies.extCustomerId, division, orderBy.value, descending, searchQuery, false);
            for (let index = 0; index < responseList.length; index++) {
                total += Number(responseList[index].quantity);
            }
            setTotalStock(total);
            setListPrint(responseList);
         
            if(responseList.length === 0){
                setIdStock("");
                setLoading(false)
                alert(`${t("index_inventory_master.text_not_data")}`);
               
            }
        } catch (exception) {
            
        }
    }

    const handlesum = async () => {
        try {
            let total = 0;
            for (let index = 0; index < listPrint.length; index++) {
                total += Number(listPrint[index].quantity);
            }
            setTotalStock(total);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, "null");
            let newStaff = response.find(p => p.id === cookies.staffId);
            if (newStaff.staff_name) {
                setStaffName(newStaff.staff_name);
            }
        } catch (exception) {

        }
    }


    const loadInventoryMaster = async () => {
        console.log(cookies)
        let inventoryMasterCount = await getInventoryMasterCountFromInventoryMasterList(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery, false, division);
        // let inventoryMasterCount = await getInventoryMasterCount(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery, false, division);
        console.log(inventoryMasterCount);
        setinventoryMasterCount(inventoryMasterCount);
        let totalPage = inventoryMasterCount / itemPerPage;
        setTotalPage(totalPage);
        let tmpSearchQuery = undefined;
        if (searchQuery) {
            tmpSearchQuery = searchQuery;
        }
        // console.log('searchquery get:', searchQuery);
        let listInventoryMaster = await findAllInventoryMasterFromInventoryMasterList(cookies.token, cookies.languageId, cookies.extCustomerId, division, page, itemPerPage, orderBy.value, descending, searchQuery, false);
        // let listInventoryMaster = await getInventoryMaster(cookies.token, cookies.languageId, cookies.extCustomerId, division, page, itemPerPage, orderBy.value, descending, searchQuery, false);
        setListInventoryMaster(listInventoryMaster);
        console.log(listInventoryMaster);
        let dates = new Date()
        setDateNow(moment(dates).format("DD-MM-yyyy HH:mm"))
        setLoading(false);
        setResetSearch(0);
        setIsSearched(0)
    }

    const deleteInventory = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_inventory_master.delete_confirmation")}`) == true) {
                let deleteSelectedProject = await deleteInventoryMaster(cookies.token, id);
                if (deleteSelectedProject.success) {
                    if (deleteSelectedProject.error_code === 0) {
                        alert(`${t("index_inventory_master.delete_success_alert")}`);
                        // setSearchQuery(searchQuery);
                        // navigate('/InventoryMaster');
                        loadInventoryMaster();
                    }
                    else {
                        alert(`${t("index_inventory_master.delete_faield_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_inventory_master.delete_failed_alert")}`);
                }
                // loadInventoryMaster();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_master.order_name2")}`,
                value: "inventory_master_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }



    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = require("../../../Assets/Images/quantums.png");
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("index_inventory_master.header_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = require("../../../Assets/Images/quantums.png");
    }

    const printPageStock = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printStock");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = require("../../../Assets/Images/quantums.png");
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("index_inventory_master.header_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = require("../../../Assets/Images/quantums.png");
    }

    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_inventory_master.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={false} variant="secondary" style={{

                            }} onClick={() => { navigate("/InventoryMaster/Detail", { state: { inventoryMasterId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_master.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <div style={{
                                        paddingRight: 10
                                    }}>

                                        <Form.Select required onChange={(e) => {
                                            setDivision(e.target.value);
                                        }} value={division} >
                                            <option selected value="">
                                            {t("index_inventory_master.dropdown_division")}
                                            </option>
                                            {listDivision.map((division, index) => (
                                                <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                            ))}
                                        </Form.Select>

                                    </div>
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_inventory_master.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                setLoading(true);
                                                await loadInventoryMaster();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0)
                                            setLoading(true);
                                            await loadInventoryMaster();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setDivision("");
                                            // await loadInventoryMaster();
                                            setResetSearch(1);

                                        }}><ArrowClockwise /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={() => {
                                            setLoading(true);
                                            setIdStock(1);
                                            // printPageStock();
                                        }}><Printer /> {t("index_inventory_master.button_print_stock")}</Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={() => {
                                            setIdStock(0);
                                            setLoading(true);
                                            // printPageStock();
                                            // printPageArea
                                        }}
                                            ><PrinterFill /> {t("index_inventory_master.button_print_detail")}</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_master.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_master.table_name23")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_master.table_name22")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_master.table_name4")}</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_master.table_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_master.table_name8")}</div>
                                    {/* <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_master.table_name9")}</div> */}
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_master.table_name10")}</div>
                                </div>
                                {
                                    listInventoryMaster.map((inventory, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.inventory_master_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{inventory.part_number === null || inventory.part_number === ""? '-' : inventory.part_number}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{`${inventory.inventory_group_code || ""}-${inventory.brand_code || ""}-${inventory.inventory_subgroup_code || ""}-${inventory.inventory_material_code || ""}`}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{inventory.brand_name}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.model}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // console.log(searchQuery)
                                                        navigate("/InventoryMaster/Detail", { state: { inventoryMasterId: inventory.id, backButton: searchQuery} });
                                                        // console.log(inventory.id);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            {/* <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={event => {
                                                            navigate("/InventoryStock", { state: { inventoryMasterId: inventory.id, divisionCode: inventory.division_code } });
                                                        }}
                                                    ><BoxArrowUpRight /></Button>
                                                </div></div> */}
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        deleteInventory(inventory.id);
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />

                <div id="printableArea" style={{ display: 'none', padding: 10 }}>
                    {/* <div id="printableArea"> */}
                    <div style={{  width: '70%' }}>
                        <img src={require("../../../Assets/Images/quantums.png")} id="radian-img" alt="foto radian" />
                    </div>
                  
                    <div style={{
                        textAlign:"center",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >{t("index_inventory_master.header_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%", fontWeight: "bold" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{staffName}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{dateNow}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ paddingBottom: 5 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr style={{ fontWeight: "bold" }}>
                            <td>No </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name1")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name2")} </td>
                            <td>{t("index_inventory_master.table_name3")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name4")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name5")} </td>
                            <td>{t("index_inventory_master.table_name7")} </td>
                            <td>{t("index_inventory_master.table_name6")} </td>
                            <td>{t("index_inventory_master.table_name17")} </td>
                            <td>{t("index_inventory_master.table_name18")} </td>
                        </tr>

                        {listPrint && listPrint.map((print, index) => {
                            return (
                                <div>
                                    <tr key={index} className="table-caption-body">
                                        <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.inventory_master_name}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.type_name}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.division_code}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.brand}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.model}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.is_consumeable ? print.quantity :print.list_stock.length}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.unit_name}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.quantity}</td>
                                        <td style={{ fontWeight: "bold" }}>{!print.is_consumeable ?Number(print.list_stock.length) - Number(print.quantity) : 0}</td>

                                    </tr>

                                    {
                                        print.list_stock.length > 0 ?
                                            <tr >
                                                <td className="tableClass"  ></td>
                                                {/* <td></td> */}
                                                <td colSpan={5} className="tableClass" >
                                                    <Table className="tableClass">
                                                        <thead className="tableClass" style={{ fontWeight: "bold" }}>
                                                            <tr className="tableClass">
                                                                {/* <td colSpan={3}>No </td> */}
                                                                <th className="tableClass">{t("index_inventory_master.table_name11")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name12")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name13")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name14")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name15")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name16")} </th>
                                                            </tr>

                                                        </thead>
                                                        {print.list_stock && print.list_stock.map((stock, index) => {
                                                            return (
                                                                <div>
                                                                    <tbody key={index} className="tableClass">
                                                                        {/* <td colSpan={3}>{index + 1}</td> */}
                                                                        <td className="tableClass">{stock.inventory_code}</td>
                                                                        <td className="tableClass">{stock.inventory_status_name}</td>
                                                                        <td className="tableClass">{stock.inventory_condition_name}</td>
                                                                        <td className="tableClass">{moment(stock.created_date).format("DD-MM-yyyy")}</td>
                                                                        <td className="tableClass">{moment(stock.purchase_date).format("DD-MM-yyyy")}</td>
                                                                        <td className="tableClass">{stock.staff_name}</td>

                                                                    </tbody>

                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </Table>

                                                </td>
                                                <td className="tableClass"  ></td>
                                                <td className="tableClass"  ></td>
                                            </tr>
                                            :
                                            <tr>
                                                <td className="tableClass" colSpan={10} style={{ textAlign: "center" }} >{t("index_inventory_master.text_no_stock")}</td>
                                            </tr>
                                    }



                                </div>
                            )
                        })
                        }
                        <tr style={{ fontWeight: "bold" }} >
                            <td style={{ textAlign: "right" }} colSpan={7}>{t("index_inventory_master.total_stock")} :</td>
                            <td colSpan={7}>{totalStock}</td>
                        </tr>

                    </table>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        borderStyle: "solid",
                        borderWidth: 1
                    }}>
                        {/* <div style={{
                            display: "flex",
                            flexDirection:"row",
                            flex:7,
                            justifyContent:"flex-end",
                            fontWeight:"bold"
                        }}>
                            <div>Total Stok :</div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection:"row",
                            flex:1,
                            justifyContent:"center",
                            fontWeight:"bold"
                        }}>
                            <div>{totalStock}</div>
                        </div> */}

                        {/* <tr className="tableClass">
                            <td className="tableClass">Total Stok</td>
                            <td className="tableClass">:</td>
                            <td className="tableClass">{totalStock}</td>
                        </tr> */}

                    </div>
                    {/* <div style={{ float: 'right', width: '65%', marginBottom: '20px' }}>
                        <Table className="tableClass" border={1}>

                            <tr className="tableClass">
                                <td className="tableClass">Total Stok</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{totalStock}</td>
                            </tr>

                        </Table>

                    </div> */}

                </div>

                <div id="printStock" style={{ display: 'none', padding: 10 }}>
                    {/* <div id="printableArea"> */}
                    <div style={{  width: '70%' }}>
                        <img src={require("../../../Assets/Images/quantums.png")} id="radian-img" alt="foto radian" />
                    </div>
                  
                    <div style={{
                        // marginLeft: 300,
                        textAlign:"center",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >{t("index_inventory_master.header_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%", fontWeight: "bold" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{staffName}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{dateNow}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ paddingBottom: 5 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr style={{ fontWeight: "bold" }}>
                            <td>No </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name1")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name2")} </td>
                            <td>{t("index_inventory_master.table_name3")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name4")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name5")} </td>
                            <td>{t("index_inventory_master.table_name7")} </td>
                            <td>{t("index_inventory_master.table_name6")} </td>
                          
                        </tr>

                        {listPrint && listPrint.map((print, index) => {
                            return (
                                <div>
                                    <tr key={index} className="table-caption-body">
                                        <td>{index + 1}</td>
                                        <td>{print.inventory_master_name}</td>
                                        <td>{print.type_name}</td>
                                        <td>{print.division_code}</td>
                                        <td>{print.brand}</td>
                                        <td>{print.model}</td>
                                        <td>{print.quantity}</td>
                                        <td>{print.unit_name}</td>
                                      

                                    </tr>




                                </div>
                            )
                        })
                        }
                        <tr style={{ fontWeight: "bold" }} >
                            <td style={{ textAlign: "right" }} colSpan={6}>{t("index_inventory_master.text_total_stock")} :</td>
                            <td colSpan={7}>{totalStock}</td>
                        </tr>

                    </table>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        borderStyle: "solid",
                        borderWidth: 1
                    }}>


                    </div>


                </div>
            </Container>

        </>
    );
}   