import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, createCountry, getCountryById, convertBase64, getUserAccessById,convertBase64Image } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import ButtonForm from "../../../../Components/ButtonForm";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function DetailCountryPage() {

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [country, setCountry] = useState({
        country_name: "",
        country_code: "",
        country_flag_url: null
    })
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
        if (location.state.countryId === 0) {
            setLoading(false);
        } else {
            initCountry();
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [country]);



    const addCountry = async () => {
        try {
            let response = await createCountry(cookies.token, country, location.state.countryId);
            if (response.error_code === 0) {
                alert(`${t("detail_country.message_save_data")}`);
                navigate("/Country")
            } else {
                alert(`${t("detail_country.failed_save_data")}`);
            }
        } catch (exception) {
            setErrorMessage(`${t("detail_country.failed_save_data")}`);
        }
    }

    const initCountry = async () => {
        try {
            let response = await getCountryById(cookies.token, location.state.countryId);
            if (response) {
                setCountry({
                    ...country,
                    country_name: response.country_name,
                    country_code: response.country_code,
                    country_flag_url: response.country_flag_url
                })
                setLoading(false);
            } 
        }
        catch (exception) {
            // console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (country.country_name === undefined || country.country_name === "") {
                    setErrors({
                        ...errors,
                        countryName: `${t("detail_country.error_country_name")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "countryName");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_country.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Country"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_country.container_title")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <TextBox value={country.country_name} placeholder=""
                                                    caption={`${t("detail_country.field1_name")}`} setValueFunction={(e) => {
                                                        setCountry({ ...country, country_name: e.target.value });
                                                    }} />
                                                {
                                                    errors.countryName && <p style={{ color: "red" }}>{errors.countryName}</p>
                                                }
                                                <TextBox value={country.country_code} placeholder=""
                                                    caption={`${t("detail_country.field2_name")}`} setValueFunction={(e) => {
                                                        setCountry({ ...country, country_code: e.target.value });
                                                    }} />
                                                <TextBox controlType="file" placeholder=""
                                                    caption={`${t("detail_country.field3_name")}`} setValueFunction={async (e) => {
                                                        // console.log(e.target.files[0])
                                                        let base64Img = await convertBase64Image(e.target.files[0]);
                                                        const base64ImgString = base64Img.toString();
                                                        setCountry({ ...country, country_flag_url: base64ImgString });
                                                    }} />
                                                
                                                <img src={country.country_flag_url} width={"30%"} />

                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>

                            <ButtonForm addData={() => {
                                addCountry();
                            }}
                            saveButton={`${t("detail_country.save_button")}`}
                            cancelButton={`${t("detail_country.cancel_button")}`}
                             path="/Country" button={disabledButton === true ? disabledButton : !updatedButton} />
                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}