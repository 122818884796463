import React, { useState, useEffect } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { ArrowLeft } from "react-bootstrap-icons";
import { isTokenValid, getUserAccessById } from "../../../../../Helpers/ApplicationHelpers";
import {
    getVesselClassificationApplicationById, insertUpdateApplication
} from "../../../../../Helpers/VesselClassification.helpers";
import "../../../../../App.css";
import Navbar from "../../../../../Components/NavBar";
import Loading from "../../../../../Components/Loading";
import ContainerBox from "../../../../../Components/ContainerBox";
import DropDownForm from "../../../../../Components/DropDownForm";
import { Trans, useTranslation } from "react-i18next";

export default function VesselTypeDetailPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [listLanguage, setListLanguage] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useState({
        languageId: "",
        countryId: "",
        applicationName: "",
        applicationDesc: "",
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
        }
        checkCookies();
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
          } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
          }
        if (location.state.applicationId === 0) {
            loadAccess();
            setApplication({ ...application, languageId: cookies.languageId });
            setLoading(false);
        } else {
            initApplication();
            loadAccess();
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [application])



    const initApplication = async () => {
        try {
            let response = await getVesselClassificationApplicationById(cookies.token, location.state.languageId, location.state.applicationId);
            if (response) {
                setApplication({
                    ...application,
                    languageId: response.language_id,
                    applicationName: response.application_name,
                    applicationDesc: response.application_desc,
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (application.applicationName === undefined || application.applicationName === "") {
                    setErrors({
                        ...errors,
                        applicationNameError: `${t("detail_class_aplication.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "applicationNameError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const saveApplication = async () => {
         let response = await insertUpdateApplication(cookies.token, application, location.state.applicationId);
        if (response) {
            alert(`${t("detail_class_aplication.alert_success_save_data")}`);
            setLoading(false);
            navigate("../.././VesselClassification/Application");
        } else {
            alert(`${t("detail_class_aplication.alert_failed_save_data")}`);
            setLoading(false);
        }

    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
               setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_class_aplication.header_title")}`} useActionContainer={false} useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate(-1); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setLoading(true);
                                saveApplication();
                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_class_aplication.field_aplication_name")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setApplication({ ...application, applicationName: e.target.value })
                                    }} type="text" value={application.applicationName} ></Form.Control>
                                    {
                                        errors.applicationNameError && <p style={{ color: "red" }}>{errors.applicationNameError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_class_aplication.field_description")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setApplication({ ...application, applicationDesc: e.target.value })
                                    }} as="textarea" value={application.applicationDesc} ></Form.Control>
                                </Form.Group>
                                <Button style={{width:100}} disabled={!updatedButton} type="submit">{t("detail_class_aplication.save_button")}</Button>
                                <Button style={{width:100}} variant="danger" className="cancel" onClick={() => {
                                    navigate("../.././VesselClassification/Application");
                                }}>{t("detail_class_aplication.cancel_button")}</Button>
                            </Form>
                        </div>} />
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />

        </>
    )
}