import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal, Nav } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, convertBase64, getDailReportJobPhoto, insertUpdateDailyReportJobPhoto, deleteDailyReportJobPhoto,
    getUserAccessById, convertBase64Image
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import Modals from "../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import FormSelect from "../../../../Components/FormSelect";
import { Trans, useTranslation } from "react-i18next";

import moment from 'moment';


export default function DailyReportPhotoPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [dailyReportPhotoId, seteDailyReportPhotoId] = useState("");
    const [listPhoto, setListPhoto] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [descending, setDescending] = useState(false);
    const [removeId, setRemoveId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [totalPage, setTotalPage] = useState(0);
    const [fotoModal, setFotoModal] = useState("");
    const [insertFoto, setInsertFoto] = useState({
        list_photo: []
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 16, 20]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("service_photo.order_name1")}`,
        value: "taken_date"
    });
    const orderBySelection = [{
        label: `${t("service_photo.order_name1")}`,
        value: "taken_date"
    }];

    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [page]);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [descending]);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [itemPerPage]);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [orderBy]);

    useEffect(() => {
        if (removeId !== "") {
            removeData();
            setLoading(true);
        }
    }, [removeId])




    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                seteDailyReportPhotoId(searchParams.get("id"));
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
                // loadDocumentData();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     console.log(insertFoto);
    // }, [insertFoto])

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [dailyReportPhotoId])


    const loadTroubleshootingPhoto = async () => {
        try {
            let count = await getDailReportJobPhoto(cookies.token, searchParams.get("id"))
            let totalPage = count.length / itemPerPage;
            setTotalPage(totalPage);

            let response = await getDailReportJobPhoto(cookies.token, searchParams.get("id"), page, itemPerPage, orderBy.value, descending);

            setListPhoto(response);
            setLoading(false)
        } catch (exception) {
            console.log(exception);
        }
    }


    const saveData = async () => {
        try {
            let response = await insertUpdateDailyReportJobPhoto(cookies.token, insertFoto);
            if (response.error_code === 0) {
                alert(`${t("service_photo.alert_success_save_data")}`)
                setFotoModal(false);
                resetListPhoto();
                loadTroubleshootingPhoto()
            } else {
                alert(`${t("service_photo.alert_failed_save_data")}`);
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
           
        }
    }

    const removeData = async () => {
        try {
            // let text;
            let response = await deleteDailyReportJobPhoto(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("service_photo.delete_success_alert")}`);
            } else {
                alert(`${t("service_photo.delete_failed_alert")}`);
            }
            loadTroubleshootingPhoto();
            setRemoveId("");

        } catch (exception) {
            // alert("Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin");
            console.log(exception);
        }
    }



    const resetListPhoto = async () => {
        let newList = {
            image_base64: "",
            taken_date: null,
            label: "",
            description: ""
        };
        setInsertFoto({ ...insertFoto, list_photo: [] });
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("service_photo.order_name1")}`,
                value: "taken_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("service_photo.header_title")}`}
                    useActionContainer={!disabledCustomerForm}
                    actionContainerChild={
                        <div>

                            <Button disabled={!updatedButton} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setFotoModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("service_photo.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <Col md={12}>
                                <Row>
                                    {
                                        listPhoto.map((photo, index) => {
                                            return (

                                                <Col md={3} key={index}>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        flexWrap: "nowrap",
                                                        flexDirection: "column",
                                                        padding: 10,
                                                        borderWidth: 1,
                                                        margin: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "#2f71bd",
                                                        borderRadius: 5,
                                                        height: 400,

                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 5,
                                                            alignContent: "flex-start",
                                                            alignItems: "flex-start",
                                                            justifyContent: "center",
                                                            alignSelf: "center",
                                                            flexWrap: "wrap",

                                                        }}>
                                                            <img src={photo.image_base64} onClick={() => {
                                                                // handleClick(vessel, index)
                                                            }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                        </div>
                                                        <div style={{
                                                            flex: 1,
                                                            alignContent: "flex-end",
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                            alignSelf: "flex-end",
                                                            flexWrap: "nowrap",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                        }} >
                                                            <div style={{
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                alignContent: "flex-end",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                alignSelf: "flex-end",
                                                                textAlign: "center"
                                                            }}><p>{!photo.label ? "" : photo.label}</p></div>
                                                            <div style={{
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                alignContent: "flex-end",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                alignSelf: "flex-end",
                                                                textAlign: "center"
                                                            }}><p>{!photo.taken_date ? "" : moment(photo.taken_date).format("yyyy-MM-DD")}</p>

                                                                {
                                                                    disabledCustomerForm === false &&
                                                                    <div style={{
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        justifyContent: "flex-end",
                                                                        alignSelf: "flex-end",
                                                                    }}>
                                                                        <Button disabled={!deletedButton} onClick={() => {
                                                                            if (window.confirm(`${t("service_photo.delete_confirmation")}`)) {
                                                                                let photoId = photo.id

                                                                                setRemoveId(photoId);

                                                                            }
                                                                        }} variant="danger"><Trash /></Button>
                                                                    </div>
                                                                }


                                                            </div>


                                                        </div>

                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>

                            <div style={{ paddingBottom: 10 }}></div>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderBySelection}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />


                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>} />
                <Modal size="xl" show={fotoModal} onHide={
                    () => {
                        setFotoModal(false);
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={`${t("service_photo.modal_add_photo")}`} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>


                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        saveData();
                                        setDisabledButton(true);
                                        setLoading(true);

                                    }}>

                                        {
                                            insertFoto.list_photo && insertFoto.list_photo.map((foto, index) => {
                                                return (
                                                    <div>
                                                        <div key={index} style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            borderWidth: 2,
                                                            borderRadius: 5,
                                                            borderStyle: "solid",
                                                            borderColor: "rgba(3, 30, 103, 1)",
                                                            padding: 10
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                <img src={foto.base64_image}
                                                                    style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("service_photo.field_photo")}</Form.Label>
                                                                    <Form.Control required type="file" onChange={async (e) => {

                                                                        let base64Img = await convertBase64Image(e.target.files[0]);
                                                                        const base64ImgString = base64Img.toString();
                                                                        // setListPhoto({ ...listPhoto, base64_image: base64ImgString })
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].base64_image = base64ImgString;
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }}></Form.Control>
                                                                </Form.Group>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                flex: 1

                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>{t("service_photo.field_label")}</Form.Label>
                                                                        <Form.Control required type="text" onChange={(e) => {
                                                                            let detailJobs = insertFoto.list_photo;
                                                                            detailJobs[index].label = e.target.value;
                                                                            setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                        }} value={foto.label}></Form.Control>
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    paddingLeft: 5

                                                                }}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>{t("service_photo.field_taken_date")}</Form.Label>
                                                                        <Form.Control required type="date" onChange={(e) => {
                                                                            let detailJobs = insertFoto.list_photo;
                                                                            detailJobs[index].taken_date = e.target.value;
                                                                            setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                        }} value={foto.taken_date ? moment(foto.taken_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                    </Form.Group>
                                                                </div>


                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("service_photo.field_explanation")}</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].description = e.target.value;
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }} value={foto.description}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                            }}>
                                                                <div style={{ paddingTop: 10 }}></div>
                                                                <Button onClick={() => {
                                                                    if (window.confirm(`${t("service_photo.delete_confirmation")}`)) {
                                                                        let list = [...insertFoto.list_photo];
                                                                        list.splice(index, 1);
                                                                        setInsertFoto({ ...insertFoto, list_photo: list })
                                                                    }

                                                                }} variant="danger"><Trash size={20} /></Button>

                                                            </div>

                                                        </div>
                                                        <div style={{ paddingBottom: 10 }}></div>


                                                    </div>



                                                )
                                            })
                                        }
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Button variant="primary" onClick={() => {
                                                let newParameter = {
                                                    base64_image: "",
                                                    label: "",
                                                    taken_date: null,
                                                    description: "",
                                                    daily_report_job_id: searchParams.get("id"),
                                                    daily_report_id: searchParams.get("reportId")
                                                };

                                                if (!insertFoto.list_photo) {

                                                    let list = [];
                                                    list.push(newParameter);
                                                    setInsertFoto({ ...insertFoto, list_photo: list })
                                                } else {

                                                    let list = insertFoto.list_photo;
                                                    list.push(newParameter);
                                                    setInsertFoto({ ...insertFoto, list_photo: list });
                                                }

                                            }}>{t("service_photo.button_add_photo")}</Button>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5,
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                paddingRight: 5,
                                            }}><Button style={{width:100}} variant="primary" disabled={disabledButton} type="submit">{t("service_photo.save_button")}</Button></div>


                                            <div style={{
                                                paddingLeft: 5,
                                            }}>
                                                <Button style={{width:100}} variant="danger" onClick={() => {
                                                    setFotoModal(false);



                                                }}>{t("service_photo.cancel_button")}</Button>

                                            </div>
                                        </div>
                                    </Form>




                                </div>






                            </div>

                        }>
                    </ContainerBox>
                </Modal>
            </Container>

        </>
    );

}