// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    align-self: flex-end;
    justify-content: center;
    display: flex;
    font-weight: bold;
    color: white;
    z-index: 9999;
    font-family: 'Roboto';
    font-size: 14;
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,SAAS;IACT,WAAW;IACX,oBAAoB;IACpB,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".footer {\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    align-self: flex-end;\n    justify-content: center;\n    display: flex;\n    font-weight: bold;\n    color: white;\n    z-index: 9999;\n    font-family: 'Roboto';\n    font-size: 14;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
