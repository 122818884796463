import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVessel, getSparepartCatalog, getStaff, insertUpdateVesselSparepartRequisition, 
    getVesselSparepartRequisitionById,getRequisitionStatus, getUserAccessById, getCrewOnVesselToday
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";

export default function VesselSparepartRequisitionDetailPage() {
    const [vesselOptions, setVesselOptions] = useState([]);
    const [listSparepart, setListSparepart] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [disabledAddButton, setDisableButtonAdd] = useState(false);
    const [listCrewVessel, setListCrewVessel] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [approvalButton, setApprovalButton] = useState("");
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [actionButton, setActionButton] = useState("");
    const [staffId, setStaffId] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [selectedProjectType, setSelectedProjectType] = useState("");
    const [sparepartRequisition, setSparepartRequisition] = useState({
        id: 0,
        vessel_id: null,
        staff_id: null,
        request_date: null,
        status_id: null,
        approved_date: null,
        list_requisition: [],
        approved_by: null,
    })



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVessel();
                
                loadAccess();
                loadStatus();
                setStaffId(cookies.staffId);
            }
        }
        checkCookies();
        if (location.state.vesselSparepartRequisitionId === 0) {
            setLoading(false);
            
        } else {
            initVesselSparepartRequisition();
            loadStaff();
        }
    }, []);

    useEffect(() => {
        if (sparepartRequisition.vessel_id === null || sparepartRequisition.staff_id === null) {
            setErrors({
                ...errors,
                message: '*Harus Diisi'
            },
                setDisabledButton(true));
        }

        else if (moment(sparepartRequisition.approved_date).format("yyyy-MM-DD") < moment(sparepartRequisition.request_date).format("yyyy-MM-DD")) {
            setErrors({
                ...errors,
                dateMessageApproved: '*Tanggal Disetujui Tidak Bisa < Tanggal Request'
            },
                setDisabledButton(true))
        }

        else if (sparepartRequisition.approved_date !== null && sparepartRequisition.approved_by === null) {
            setErrors({
                ...errors,
                givingStaffError: '*Harus Diisi'
            },
                setDisabledButton(true))
        }
        else if (sparepartRequisition.list_requisition.length === 0) {
            setErrors({
                ...errors,
                listRequisitionError: 'Data Barang Harus Diisi'
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "message", "dateMessageApproved",  "givingStaffError", "listRequisitionError");
            setErrors(errorMsg);
            validationForm();


        }
      
    }, [sparepartRequisition]);

    useEffect(()=>{
        if(sparepartRequisition.vessel_id !== null || sparepartRequisition. vessel_id !== ""){
            loadSparepartCatalog();
            loadCrewVessel();
        }
    },[sparepartRequisition.vessel_id])

    
    useEffect(() => {
        if (actionButton !== "")
            saveData();
    }, [actionButton])

    useEffect(() => {
        if (location.state.vesselSparepartRequisitionId === 0) {
            let today = new Date();
            let date = moment(today).format("yyyy-MM-DD")
            setSparepartRequisition({ ...sparepartRequisition, request_date: date });
        }

    }, [listStatusRequisition])

    

    const loadVessel = async () => {
        try {
            let response = await getVessel(cookies.token, cookies.languageId, cookies.extCustomerId);

            let listVessel = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id

                };

                listVessel.push(obj);

            }
            setVesselOptions(listVessel);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadSparepartCatalog = async () => {
        try {
            let response = await getSparepartCatalog(cookies.token, "null");
            let listCatalog = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].manufacturer_name ? (response[i].manufacturer_name + ' - ' + response[i].model + `(${response[i].part_number !== null ? response[i].part_number : ""})`) : '- ' + response[i].model,
                    id: response[i].id
                };

                listCatalog.push(obj);

            }
            setListSparepart(listCatalog);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, true, undefined, undefined, 2);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id,
                    resign_date: response[i].resign_date

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewVessel = async () => {
        try {
            let response = await getCrewOnVesselToday(cookies.token, sparepartRequisition.vessel_id);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id,
                };
                listStaff.push(obj);

            }
            setListCrewVessel(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            // let sparepartRequisitionTmp = sparepartRequisition;
            // sparepartRequisitionTmp.ext_customer_id = sparepartRequisitionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : sparepartRequisition.ext_customer_id
            let response = await insertUpdateVesselSparepartRequisition(cookies.token, sparepartRequisition);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data');
                navigate('/VesselSparepartRequisition');

            } else {
                alert('Gagal Menyimpan Data');
            }

            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initVesselSparepartRequisition = async () => {
        try {
            let response = await getVesselSparepartRequisitionById(cookies.token, location.state.vesselSparepartRequisitionId);
            if (response) {
                setSparepartRequisition({
                    ...sparepartRequisition,
                    id: response[0].id,
                    vessel_id: response[0].vessel_id,
                    staff_id: response[0].staff_id,
                    request_date: response[0].request_date,
                    status_id: response[0].status_id,
                    approved_date: response[0].approved_date,
                    list_requisition: response[0].list_requisition,
                    approved_by: response[0].approved_by,
                })
            }

            let status = await getRequisitionStatus(cookies.token);
            if (status) {
                let statusCode = status.find(p => p.id === response[0].status_id)
                if (statusCode.status_code === "APPROVED" || statusCode.status_code === "REJECTED") {
                    setDisabledForm(true);
                }
            }

            // setSelectedProjectType(`${response[0].is_project}`);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
             setListStatusRequisition(response);
            if (location.state.vesselSparepartRequisitionId === 0) {
                let status = await response.find(p => p.status_code === "REQ")
                setSparepartRequisition({ ...sparepartRequisition, status_id: status.id })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (sparepartRequisition.list_requisition.length > 0) {
                    for (let index = 0; index < sparepartRequisition.list_requisition.length; index++) {
                        const list = sparepartRequisition.list_requisition[index];
                        if (list.sparepart_catalog_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage("Nama Inventaris Harus Diisi");
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");
                            setDisableButtonAdd(false);
                            setDisabledButton(false);
                        }
                    }
                } else {
                    setErrorMessage("")
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }


    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
                setApprovalButton(response.approval);
            }
            setDeletedButton(true);
            setUpdatedButton(true)
            setApprovalButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Permintaan Sparepart"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/VesselSparepartRequisition"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Permintaan Sparepart Kapal"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                              
                                                                <FormSelect caption="Nama Kapal"  placeholder="Pilih Kapal..."
                                                                    options={vesselOptions} value={vesselOptions.filter(function (option) {
                                                                        return option.id === sparepartRequisition.vessel_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartRequisition({ ...sparepartRequisition, vessel_id: e.id });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, vessel_id: null });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }


                                                                <FormSelect caption="Kru Yang Mengajukan" placeholder="Pilih Kru..."
                                                                    options={listCrewVessel} value={listCrewVessel.filter(function (option) {
                                                                        return option.id === sparepartRequisition.staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartRequisition({ ...sparepartRequisition, staff_id: e.id });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, staff_id: null });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }
                                                                <DropDownForm value={sparepartRequisition.status_id} caption="Status Permintaan" placeholder="Status Permintaan"
                                                                    setValueFunction={(e) => {
                                                                        // setSparepartRequisition({ ...sparepartRequisition, status_id: e.target.value });
                                                                        let status = listStatusRequisition.find(p => p.id === e.target.value);
                                                                        if (status.status_code === "APPROVED") {
                                                                            var today = new Date(),
                                                                                // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                                date = moment(today).format("yyyy-MM-DD")
                                                                            setSparepartRequisition({ ...sparepartRequisition, status_id: e.target.value, approved_date: date });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, status_id: e.target.value, approved_date: null, approved_by: null });

                                                                        }
                                                                    }} listOption={listStatusRequisition} valueKey="id" labelKey="status_name" disabledForm={true} />

                                                                <FormSelect caption="Staf Yang Menyetujui"  placeholder="Pilih Staf..."
                                                                    options={staffOptions} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartRequisition.approved_by;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartRequisition({ ...sparepartRequisition, approved_by: e.id });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, approved_by: null });
                                                                        }

                                                                    }} disabledForm={sparepartRequisition.approved_date ? disabledForm : true}

                                                                />
                                                                {
                                                                    errors.givingStaffError && <p style={{ color: "red" }}>{errors.givingStaffError}</p>
                                                                }
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>



                                                                <TextBox value={sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Request" controlType="date"
                                                                    caption="Tanggal Request" setValueFunction={(e) => {
                                                                        setSparepartRequisition({ ...sparepartRequisition, request_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <TextBox value={sparepartRequisition.approved_date ? moment(sparepartRequisition.approved_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption="Tanggal Disetujui" setValueFunction={(e) => {
                                                                        setSparepartRequisition({ ...sparepartRequisition, approved_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                                {
                                                                    errors.dateMessageApproved && <p style={{ color: "red" }}>{errors.dateMessageApproved}</p>
                                                                }
                                                               

                                                            </div>

                                                        </div>
                                                        {
                                                            approvalButton && location.state.vesselSparepartRequisitionId !== 0 &&

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                padding: 10,
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                fontWeight: "bold"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Button onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "APPROVED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setSparepartRequisition({ ...sparepartRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Approve");
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="success">Setujui</Button>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Button onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "REJECTED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setSparepartRequisition({ ...sparepartRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Reject")
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="danger">Tolak</Button>
                                                                </div>


                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {/* {
                                        location.state.vesselSparepartRequisitionId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >Cetak <Printer /></Button>
                                            :
                                            <></>
                                    } */}
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Daftar Barang"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>


                                                    {
                                                        sparepartRequisition.list_requisition && sparepartRequisition.list_requisition.map((list, index) => {
                                                            return (
                                                                <div style={{ padding: 5 }}>
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5,
                                                                        borderWidth: 2,
                                                                        borderRadius: 5,
                                                                        borderStyle: "solid",
                                                                        borderColor: "rgba(3, 30, 103, 1)"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 20,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Select placeholder={"Pilih Sparepart"}
                                                                                    options={listSparepart.filter((p) => {
                                                                                        return !sparepartRequisition.list_requisition.find((x) => {
                                                                                            return p.id === x.sparepart_catalog_id
                                                                                        })
                                                                                    })} value={listSparepart.filter(function (option) {
                                                                                        return option.id === list.sparepart_catalog_id;
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        let listRequisition = sparepartRequisition.list_requisition;
                                                                                        listRequisition[index].sparepart_catalog_id = e.id;
                                                                                        setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                                    }}
                                                                                    isDisabled={disabledForm}
                                                                                />

                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                // width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 6,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                      if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                                      } else {
                                                                                        let listRequisition = sparepartRequisition.list_requisition;
                                                                                        listRequisition[index].quantity = e.target.value;
                                                                                        setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                                      }
                                                                                    
                                                                                }} type="text" style={{ textAlign: "right" }} value={list.quantity} required placeholder="Jumlah" disabled={disabledForm} ></Form.Control>


                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 1,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Button onClick={() => {
                                                                                    if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                        let list = [...sparepartRequisition.list_requisition];
                                                                                        list.splice(index, 1);
                                                                                        setSparepartRequisition({ ...sparepartRequisition, list_requisition: list });
                                                                                    }

                                                                                }} style={{ maxWidth: 50 }} variant="danger" disabled={disabledForm === true ? disabledForm : !deletedButton}><Trash /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            paddingTop: 10,
                                                                            paddingBottom: 10

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                    let listRequisition = sparepartRequisition.list_requisition;
                                                                                    listRequisition[index].note = e.target.value;
                                                                                    setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                                }} as="textarea" rows={3} value={list.note} placeholder="Deskripsi" disabled={disabledForm}></Form.Control>


                                                                            </div>

                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            );
                                                        })
                                                    }

                                                    {
                                                        errors.listRequisitionError && <p style={{ color: "red" }}>{errors.listRequisitionError}</p>
                                                    }
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                sparepart_catalog_id: "",
                                                                quantity: null,
                                                                note: ""
                                                            };
                                                            if (!sparepartRequisition.list_requisition) {
                                                                let listRequisition = [];
                                                                listRequisition.push(newParameter);
                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                            } else {
                                                                let listRequisition = sparepartRequisition.list_requisition;
                                                                listRequisition.push(newParameter);
                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                            }

                                                        }} disabled={disabledForm === false ? disabledAddButton : disabledForm}>Tambah Barang</Button>
                                                    </div>
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button className="save-button" disabled={disabledForm === false ? disabledButton : !updatedButton} type="submit">Simpan</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button className="cancel-button" onClick={() => {
                                                        navigate("/SparepartRequisition")
                                                    }} variant="danger">Batal</Button>
                                                </div>


                                            </div>
                                    }



                                </Form>



                            </div>

                           
                        </div>

                    }>
                </ContainerBox>
           
            </Container>
            <Loading
                loading={loading}
            />
        </>
    );

}