// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainerOn{
    width: 100px;
    height: 30px;
    border: 1px #e11d48  solid;
    background-color: #e11d48  ;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.mainContainerOff{
    width: 100px;
    height: 30px;
    border: 1px #020617   solid;
    background-color: #020617   ;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.containerLampOff{
    border: 1px #020617 solid;
    background-color: #020617 ;
    width: 100%;
    height: 15%;
    /* margin-top: 20px; */
}

.containerLampOn{
    border: 1px #e11d48 solid;
    background-color: #e11d48 ;
    width: 100%;
    height: 15%;
    /* margin-top: 20px; */
}

.containerTextTitleNew{
    /* border: 1px #e11d48 solid; */
    /* background-color: #e11d48 ; */
    width: 100%;
    /* height: 20%; */
    /* margin-top: 20px; */
}

.textBitLampNav {
    /* margin-left: 5px; */
    color: #f1f5f9;
    font-size: small;
    font-family: "Disket Mono";
    font-weight: lighter;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewNavLightContainerPosition/NewNavLightContainer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,WAAW;IACX,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,WAAW;IACX,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,+BAA+B;IAC/B,gCAAgC;IAChC,WAAW;IACX,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,0BAA0B;IAC1B,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".mainContainerOn{\n    width: 100px;\n    height: 30px;\n    border: 1px #e11d48  solid;\n    background-color: #e11d48  ;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n}\n\n.mainContainerOff{\n    width: 100px;\n    height: 30px;\n    border: 1px #020617   solid;\n    background-color: #020617   ;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n}\n\n.containerLampOff{\n    border: 1px #020617 solid;\n    background-color: #020617 ;\n    width: 100%;\n    height: 15%;\n    /* margin-top: 20px; */\n}\n\n.containerLampOn{\n    border: 1px #e11d48 solid;\n    background-color: #e11d48 ;\n    width: 100%;\n    height: 15%;\n    /* margin-top: 20px; */\n}\n\n.containerTextTitleNew{\n    /* border: 1px #e11d48 solid; */\n    /* background-color: #e11d48 ; */\n    width: 100%;\n    /* height: 20%; */\n    /* margin-top: 20px; */\n}\n\n.textBitLampNav {\n    /* margin-left: 5px; */\n    color: #f1f5f9;\n    font-size: small;\n    font-family: \"Disket Mono\";\n    font-weight: lighter;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
