// import "../../SMSApp.css";
import "./index.css";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { isTokenValid, findAllTank, getSMSCoordinateByCoordinateType, getPLCReadingValueForTankLevel,getReadingValueNew, convertTankLevelIntegerValue } from "../../../Helpers/ApplicationHelpers";
import TankLevel from "../../../Components/NewTankLevel/NewTankLevel";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import DropDownForm from "../../../Components/DropDownForm";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import moment from "moment";
import Select from 'react-select';

export default function CustomerSMSTankLevelPage(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listTankLevel, setListTankLevel] = useState([]);
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [coordinateType, setCoordinateType] = useState('');
    const [coordinateList, setCoordinateList] = useState([]);
    const [listCoordinateValueLastTwenty, setListCoordinateValueLastTwenty] = useState([]);
    const [listConvertedTankValue, setListConvertedTankValue] = useState();
    const [selectedTankLevel, setSelectedTankLevel] = useState({
        coordinate_desc: "",
        coordinate_type: "",
        coordinate_number: ""
    });
    const durationOption = [
        {
          label: "1 Jam",
          value: 1,
        },
        {
          label: "1 Hari",
          value: 2,
        },
        {
          label: "1 Minggu",
          value: 3,
        },
      ];
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    useEffect(() => {
        loadTankOption();
        loadCoordinate();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log(selectedTankLevel);
    }, [selectedTankLevel])

    useEffect(() => {
        console.log('coordinateList:', coordinateList);
        loadReadingValue();
    }, [coordinateList])

    useEffect(() => {
        console.log('listConvertedTankValue',listConvertedTankValue);
    }, [listConvertedTankValue])

    useEffect(() => {
        console.log('listCoordinateValueLastTwenty:', listCoordinateValueLastTwenty);
        loadConverter();
    }, [listCoordinateValueLastTwenty])

    const loadTankOption = async () => {
        try {
            let response = await findAllTank(cookies.token, smsSerialNumber, vesselId);
            setListTankLevel(response);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadConverter = async () => {
        try {
            let listResponseFromConvertedValue = [];
            for (let i = 0; i < listCoordinateValueLastTwenty.length; i++) {
                // console.log("execute");
                let response = await convertTankLevelIntegerValue(cookies.token, smsSerialNumber, vesselId, listCoordinateValueLastTwenty[i].coordinate_type, listCoordinateValueLastTwenty[i].coordinate_number, listCoordinateValueLastTwenty[i].reading_value);
                // console.log('data', i, response);
                listResponseFromConvertedValue.push(response.data);
            }
            setListConvertedTankValue(listResponseFromConvertedValue);
            console.log("--------listconvertedTANKVALUE----------",listResponseFromConvertedValue);
        } catch(exception){
            console.log(exception);
        }
    }

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            // hard coding, the value of column id_coordinate_group
            let coordinateGroupId = [2];
            setCoordinateType('AI');
            let response = await getSMSCoordinateByCoordinateType(cookies.token, smsSerialNumber, coordinateGroupId, vesselId, coordinateType);
            setCoordinateList(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadReadingValue = async () => {
        try {
            console.log('sms serial number:', smsSerialNumber);
            console.log('vesselId:', vesselId);
            console.log('start date:', selectStartDate);
            console.log('end date:', selectEndDate);
            setLoading(true);
            setCoordinateType('AI');
            let idCoordinateGroup = 2;
            let responseReading = await getPLCReadingValueForTankLevel(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId);
            // console.log(responseReading);
            let receivedDate = moment(responseReading[0].received_date).format("yyyy-MM-DD HH:mm:ss");
            setLastReceivedDate(receivedDate);
            setListCoordinateValueLastTwenty(responseReading);
            setLoading(false);

        } catch (exception) {
            console.log(exception);
        }
    }
    
    return (
        <>
            <div className="tankNewRes">
                <div className="mainPageTank">
                    {
                        listConvertedTankValue && listConvertedTankValue.map((data, index) => {
                            return (<>
                                {
                                    data.coordinate_type !== '' ?
                                    <div key={index} className="innerTank">
                                        <TankLevel text={data.tank_data ? data.tank_data.tank_full_name : ""} value={Number(data.value_percentage).toFixed(2)} wireBreak={data.wire_break} m3Value = {data.value_m3}/> : <></>
                                    </div>
                                :
                                <></>
                                }
                               </>


                            )
                        })
                    }
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
                    </div>

                </div>
            </div>
        </>
    );
}