// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ThrottleBar.css */
/* .mainProgressbar {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 2px red solid;
} */

.mainProgressbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center ;
    align-items: flex-start !important;
    /* border: 2px red solid; */
}

.txtforNewTankTitle {
    color: white;
    font-size: medium;
    font-weight: lighter;
}

.progressBarContainer {
    width: 100%;
    padding: 10px;
}

@media (max-width: 600px) {
    .txtforNewTankTitle {
        font-size: 0.9rem; 
    }

    .progressBarContainer {
        margin-top: 0.25rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/ThrottleBar/ThrottleBar.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;;;;;GAKG;;AAEH;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,wBAAwB;IACxB,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":["/* ThrottleBar.css */\n/* .mainProgressbar {\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n    border: 2px red solid;\n} */\n\n.mainProgressbar {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    justify-content: center ;\n    align-items: flex-start !important;\n    /* border: 2px red solid; */\n}\n\n.txtforNewTankTitle {\n    color: white;\n    font-size: medium;\n    font-weight: lighter;\n}\n\n.progressBarContainer {\n    width: 100%;\n    padding: 10px;\n}\n\n@media (max-width: 600px) {\n    .txtforNewTankTitle {\n        font-size: 0.9rem; \n    }\n\n    .progressBarContainer {\n        margin-top: 0.25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
