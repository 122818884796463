import Navbar from "../../../Components/NavBar";
import { Container, Button, Form } from "react-bootstrap";
import { FilePlusFill,EyeFill, Search, ArrowClockwise } from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import { useEffect, useState } from "react";
import { getListSMSDevice, getListSMSDeviceCount, isTokenValid } from "../../../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";


export default function ListSmsDevicePage() {
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [serialNumber, setSerialNumber] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [isDescending, setIsDescending] = useState(true);
  const [listSMS, setListSMS] = useState();
  const [page, setPage] = useState(0);
  const [resetSearch, setResetSearch] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [totalPage, setTotalPage] = useState(0);
  const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
  const [loading, setLoading] = useState(true);
  const [refreshed, setRefreshed] = useState(false);
  const [orderBy, setOrderBy] = useState({
    label: "Commissioned Date",
    value: "commisioned_date"
});
  const [isSearched, setIsSearched] = useState(0);

  const orderByList = [
    {
      label: "Serial Number",
      value: "smsDevice.sms_serial_number"
    },
    {
      label: "Vessel Name",
      value: "vessel.vessel_name"
    },
    {
      label: "Customer Name",
      value: "customer.customer_name"
    },
    {
      label: "Commissioned Date",
      value: "smsDevice.commisioned_date"
    }
  ]

  useEffect ( () => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token)
      if (!isAuthenticated) {
        navigate("/")
      }
      else {
        loadListSMSDevice();
      }
    }
    checkCookies();
  }, [])

  useEffect ( () => {
    setLoading(true);
    loadListSMSDevice();
  }, [page, refreshed])

  useEffect ( () => {
    if (listSMS) {
    }
  }, [listSMS])



  useEffect(() => {
    if (resetSearch !== 0)
        loadListSMSDevice();
}, [resetSearch])

useEffect(() => {
  if (isSearched !== 0) {
      setLoading(true);
      loadListSMSDevice();
      console.log(orderBy)
  }
}, [isDescending, orderBy, itemPerPage]);

  const loadListSMSDevice = async () => {
    try {
      let listSMSDeviceCount = await getListSMSDeviceCount(cookies.token, orderBy, searchQuery, isDescending);
      let totalPage = listSMSDeviceCount / itemPerPage;
     
      setTotalPage(totalPage);
      let response = await getListSMSDevice(cookies.token, page, itemPerPage, orderBy.value, isDescending, searchQuery);
      console.log(response)
      setListSMS(response)
      setResetSearch(0);
      setRefreshed(false);
      setLoading(false);
      setIsSearched(0);
      // let customerName = response.map( (a) => a.customer_name)
    }
    catch(exception){
      console.log(exception);
    }
  }
  return (
    <>
      <Navbar />
      <Container
        fluid
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <ContainerBox
            titleCaption={"List SMS Device"}
            useActionContainer={true}
            actionContainerChild={
              <Button variant="secondary" style={{}}>
                  <div style={{display: "flex", justifyContent:'space-between'}}>
                    <div>
                      <FilePlusFill size={32} />
                    </div>
                    <div style={{fontSize:'18px'}}>
                      {'Add Data'}
                    </div>
                  </div>
                </Button>
            }
            childContent={
              <div className="master-table-inner-container">
                {/* <Button variant="secondary" style={{width: '15%'}}>
                  <div style={{display: "flex", justifyContent:'space-around'}}>
                    <div>
                      <FilePlusFill size={32} />
                    </div>
                    <div>
                      {'Add Data'}
                    </div>
                  </div>
                </Button> */}
                <div className="master-table-searchbar-container">
                  <div className="master-table-searchbar-textbox">
                    <Form.Control
                        value = {searchQuery}
                        type = "text"
                        placeholder= "Keywords (Name, Number)"
                        onChange= { (e) => {
                          setSearchQuery(e.target.value)
                        }
                        }
                    />
                  </div>

                  <div className="master-table-searchbar-button">
                    <div style={{ paddingRight: 5 }}>
                            <Button onClick={async () => {
                              setPage(0);
                              await loadListSMSDevice();
                            }}>
                              <Search />
                            </Button>
                    </div>
                    <div style={{ paddingLeft: 5 }}>
                            <Button onClick={async () => {
                              setSearchQuery("");
                              setRefreshed(true);
                            }}>
                              <ArrowClockwise />
                            </Button>
                  </div>
                </div>
                
                
              </div>
              <div className="table-container">
                  <div className="table-header">
                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                    <div style={{ flex: 2 }} className="table-header-content">Customer Name</div>
                    <div style={{ flex: 2 }} className="table-header-content">Serial Number</div>
                    <div style={{ flex: 2 }} className="table-header-content">Vessel Name</div>
                    <div style={{ flex: 2 }} className="table-header-content">Commissioned Date</div>
                    <div style={{ flex: 2 }} className="table-header-content">Dashboard</div>
                  </div>
                    {
                      listSMS && listSMS.map((data, index) => {
                        return(
                          <div key={index} className="table-body">
                            <div style={{ flex: 1 }} className="table-body-content">{index + 1}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{data.customer_name}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{data.sms_serial_number}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{data.vessel_name}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{moment(data.commisioned_date).format("YYYY-MM-DD")}</div>
                            <div style={{ flex: 2 }} className="table-body-content">
                                <Button style={{marginLeft:'35%', marginRight:'35%'}} variant="primary" onClick={() => {
                                  if(data.is_sms_lite){
                                    navigate("/SMSVesselPosition", { state: {smsSerialnumber: data.sms_serial_number, customerName: data.customer_name, vesselName: data.vessel_name, vesselId: data.id}});
                                  } else {
                                    navigate("/SMSDashboard", { state: {smsSerialnumber: data.sms_serial_number, customerName: data.customer_name, vesselName: data.vessel_name, vesselId: data.id}});
                                  }
                                     
                                   }}><EyeFill />
                                </Button>
                            </div>
                          </div>
                        )
                      })
                    }
                </div>
              <Paginations
                    itemPerPage={itemPerPage}
                    totalPage={totalPage}
                    page={page}
                    setPage={setPage}
                    setItemPerPage={setItemPerPage}
                    itemPerPageSelection={itemPerPageSelection}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    orderBySelection={orderByList}
                    isDescActive={isDescending}
                    setIsDescActive={setIsDescending}
                    setIsSearched={setIsSearched}
                />
              <Loading
                  loading={loading}
              />
              </div>
          }
        >
        </ContainerBox>
      </Container>
    </>
  );
}
