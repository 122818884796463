import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Table, Modal, InputGroup, Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, getProject, getStaff, getListStaffGroupMechanic, getStaffPosition,
    getSpecializaton, getVesselEquipmentByVessel, getEquipment, insertUpdateTroubleshooting,
    getTroubleshootingById, convertBase64, getTroubleshootingCrewHistory, getTroubleshootingCrewHistoryCount,
    getTroubeleshootingStatus, getTroubleshootingReport, getSubSpecialization, getUserAccessById, convertBase64Image
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import FormSelect from "../../../../Components/FormSelect";
import Loading from "../../../../Components/Loading";
import { Editor } from '@tinymce/tinymce-react';
import TextBox from "../../../../Components/Textbox";
import { Trash, ArrowLeft, Clock, ArrowClockwise, Search, XSquareFill, PencilFill, CameraFill, PrinterFill, Cameraplus, Image, FilePlusFill } from "react-bootstrap-icons";
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import Paginations from "../../../../Components/Pagination";
import { convert } from 'html-to-text'
import TroubleshootingDetail from "../../../../Components/TroubleshottingDetail";
import TroubleshootingEquipment from "../../../../Components/TroubleshootingEquipment";

export default function TroubleshootingDetailPage() {
    const ref = useRef();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [resetComponent, setResetComponent] = useState(false);
    const [troubleshootingId, setTroubleshootingId] = useState("");
    const [listProject, setListProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState({ id: "" });
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [errorJobsFinding, setErrorJobsFinding] = useState("");
    const [errorJobName, setErrorJobName] = useState("");
    const [disabledPlusButtonJob, setDisabledPlusButtonJob] = useState(false);
    const [disabledPlusButtonFinding, setDisabledPlusButtonFinding] = useState(false);
    const [listMechanicGroup, setListMechanicGroup] = useState([]);
    const [selectedMechanicGroup, setSelectedMechanicGroup] = useState({ id: "" });
    const [listStaff, setListStaff] = useState([]);
    const [crewErrorMessage, setCrewErrorMessage] = useState("");
    const [listStaffPosition, setListStaffPosition] = useState([]);
    const [fotoModal, setFotoModal] = useState("");
    const [descriptionModal, setDescriptionModal] = useState("");
    const [indexDescription, setIndexDescription] = useState("");
    const [indexDescriptionJob, setIndexDescriptionJob] = useState("");
    const [indexDetailFoto, setIndexDetailFoto] = useState("");
    const [indexFoto, setIndexFoto] = useState("");
    const [listStatus, setListStatus] = useState([]);
    const [listStaffSpecialization, setListStaffSpecialization] = useState([]);
    const [disabledButtonCrew, setDisabledButtonCrew] = useState(false);
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [svcEqpCount, setSvcEqpCount] = useState([]);
    const [listEquipmentCategory, setListEquipmentCategory] = useState([]);
    const [newListVesselEquipment, setNewListVesselEquipment] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [disabledAddButton, setDisabledAddButton] = useState(false);
    const [equipmentErrorMessage, setEquipmentErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [historyModal, setHistoryModal] = useState("");
    const [listCrewHistory, setListCrewHistory] = useState([]);
    const [descending, setDescending] = useState(true);
    const [disabledPrint, setDisabledPrint] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [docxData, setDocxData] = useState("");
    const [itemPerPage, setItemPerPage] = useState(5);
    const [searchQueryHistory, setSearchQueryHistory] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [disabledAddFinding, setDisabledAddFinding] = useState(false);
    const [findingErrorMessage, setFindingErrorMessage] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [orderBy, setOrderBy] = useState({
        label: "Waktu Keberangkatan",
        value: "crewHistory.departure_date"
    });

    const orderByList = [
        {
            label: "Waktu Keberangkatan",
            value: "crewHistory.departure_date"
        }
    ]

    const options = {
        wordwrap: 130
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 30,
            overflowY: "auto"
        }),
    }

    const [troubleshooting, setTroubleshooting] = useState({
        id: 0,
        project_id: "",
        service_date: "",
        status_id: null,
        mechanic_group_id: null,
        staff_pic_id: "",
        service_location: "",
        list_job: "",
        list_technician: [],
        detail_job: [],
        list_finding: [],
        ext_customer_id: cookies.extCustomerId
    })

    const [listTroubleshootingCrew, setListTroubleshootingCrew] = useState([]);
    const [listTroubleshootingFinding, setListTroubleshootingFinding] = useState([]);
    const [listTroubleshootingEquipment, setListTroubleshootingEquipment] = useState([]);
    const [listTroubleshootingJob, setListTroubleshootingJob] = useState([]);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadStaff();
                loadEquipmentCategory();
                loadProject();
                loadMechanicGroup();
                loadStaffPosition();
                loadAccess();
                loadStaffSpecialization();
                loadStatus();
                setTroubleshootingId(location.state.troubleshootingId);
                if (location.state.troubleshootingId === 0) {
                    setLoading(false);
                }
                if(cookies.extCustomerId !== "null" && cookies.logingAs === "Customer"){
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (troubleshootingId === 0) {
            let response = listStatus.find(p => p.status_code === "NEW");
            if (response) {
                setTroubleshooting({ ...troubleshooting, status_id: response.id })
            }
        }
    }, [listStatus]);

    useEffect(() => {
        if (docxData.size > 0) {
            downloadDocxFile();
        }
    }, [docxData]);


    useEffect(() => {
        if (resetComponent)
            setResetComponent(false);
    }, [resetComponent])


    useEffect(() => {
        if (troubleshootingId !== "" && troubleshootingId !== 0) {
            initTroubleshooting()
            loadTroubleshootingCrewHistory();
        }
    }, [troubleshootingId]);


    useEffect(() => {
        console.log(troubleshooting);
        if (troubleshootingId !== 0 && troubleshootingId !== "") {
            if (troubleshooting.project_id) {
                let projects = listProject.find(p => p.id === troubleshooting.project_id);
                if (projects) {
                    setSelectedProject(projects);
                }

            }
            // if (troubleshooting.mechanic_group_id) {
            //     let groups = listMechanicGroup.find(p => p.id === troubleshooting.mechanic_group_id);
            //     if (groups) {
            //         setSelectedMechanicGroup(groups);
            //     }

            // }
        }

        validationForm();



    }, [troubleshooting]);

    useEffect(() => {
        // console.log(listTroubleshootingEquipment);
        if (listTroubleshootingEquipment.length > 0) {
            for (let index = 0; index < listTroubleshootingEquipment.length; index++) {
                const list = listTroubleshootingEquipment[index];
                if (list.equipment_id === null) {
                    setEquipmentErrorMessage("*Kategori Peralatan Harus Diisi");
                    setDisabledButton(true);
                    setDisabledAddButton(true);
                } else {
                    setEquipmentErrorMessage("");
                    setDisabledAddButton(false);
                    validationForm();
                }

                // if (list.list_job.length > 0) {
                //     for (let index = 0; index < list.list_job.length; index++) {
                //         const listJob = list.list_job[index];
                //         if (listJob.job_name === "") {
                //             setDisabledPlusButtonJob(true);
                //             setErrorJobName("*Nama Pekerjaan Harus Diisi")
                //         } else {
                //             setDisabledPlusButtonJob(false);
                //             setErrorJobName("")
                //         }
                //     }
                // } else {
                //     setDisabledPlusButtonJob(false);
                //     setErrorJobName("")
                // }

            }
        } else {
            setDisabledAddButton(false);
            setEquipmentErrorMessage("")
        }

        setResetComponent(true);
        setSvcEqpCount(listTroubleshootingEquipment.length);
        forceUpdate();
    }, [listTroubleshootingEquipment]);

    useEffect(() => {
        // console.log(listTroubleshootingFinding);
        if (listTroubleshootingFinding.length > 0) {
            for (let index = 0; index < listTroubleshootingFinding.length; index++) {
                const list = listTroubleshootingFinding[index];
                if (list.equipment_id === null) {
                    setFindingErrorMessage("*Kategori Peralatan Harus Diisi");
                    setDisabledButton(true);
                    setDisabledAddFinding(true);
                } else {
                    setFindingErrorMessage("");
                    setDisabledAddFinding(false);
                    validationForm();
                }
                if (list.detail_job.length > 0) {
                    for (let index = 0; index < list.detail_job.length; index++) {
                        const listJob = list.detail_job[index];
                        if (listJob.job_name === "") {
                            setDisabledPlusButtonFinding(true);
                            setErrorJobsFinding("*Detail Pekerjaan Harus Diisi")
                        } else {
                            setDisabledPlusButtonFinding(false);
                            setErrorJobsFinding("")
                        }
                    }
                } else {
                    setDisabledPlusButtonFinding(false);
                    setErrorJobsFinding("")
                }
                // troubleshooting.detail_job.push(list);

            }
        } else {
            setDisabledAddFinding(false);
            setFindingErrorMessage("")
        }
    }, [listTroubleshootingFinding]);


    useEffect(() => {
        // console.log(listTroubleshootingCrew);
        if (listTroubleshootingCrew.length > 0) {
            for (let index = 0; index < listTroubleshootingCrew.length; index++) {
                const listTechnician = listTroubleshootingCrew[index];
                if (listTechnician.staff_id === "") {
                    setCrewErrorMessage("*Nama Teknisi Harus Diisi");
                    setDisabledButton(true);
                    setDisabledButtonCrew(true);
                } else {
                    setCrewErrorMessage("");
                    setDisabledButtonCrew(false);
                    validationForm();
                }

            }
        } else {
            setDisabledButtonCrew(false);
            setCrewErrorMessage("");
        }
    }, [listTroubleshootingCrew])

    useEffect(() => {
        if (selectedProject.project_name) {

            setTroubleshooting({ ...troubleshooting, project_id: selectedProject.id });
            loadVesselEquipment();
        } else {
            setTroubleshooting({ ...troubleshooting, project_id: "" });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (indexDescriptionJob !== "") {
            setDescriptionModal(true);
        }
    }, [indexDescriptionJob]);


    // useEffect(() => {
    //     if (selectedMechanicGroup.group_name) {
    //         let list = [];
    //         for (let index = 0; index < selectedMechanicGroup.list_staff.length; index++) {
    //             const staff = selectedMechanicGroup.list_staff[index];
    //             staff.id = null
    //             list.push(staff)
    //         }
    //         let leader = selectedMechanicGroup.list_staff.find(p => p.is_leader === true);
    //         setTroubleshooting({ ...troubleshooting, mechanic_group_id: selectedMechanicGroup.id, staff_pic_id: leader.crew_name });
    //         setListTroubleshootingCrew(list.slice(0))
    //     } else {
    //         setTroubleshooting({ ...troubleshooting, mechanic_group_id: null, staff_pic_id: null });
    //         setListTroubleshootingCrew([])
    //     }
    // }, [selectedMechanicGroup]);

    useEffect(() => {
        if (indexDetailFoto !== "") {
            setFotoModal(true);
        }
    }, [indexDetailFoto]);

    useEffect(() => {
        // console.log(listTroubleshootingJob);

    }, [listTroubleshootingJob])

    useEffect(() => {
        if (troubleshootingId !== 0 && troubleshootingId !== "")
            loadTroubleshootingCrewHistory();
    }, [page]);

    useEffect(() => {
        if (troubleshootingId !== 0 && troubleshootingId !== "")
            loadTroubleshootingCrewHistory();
    }, [itemPerPage]);

    useEffect(() => {
        if (troubleshootingId !== 0 && troubleshootingId !== "")
            loadTroubleshootingCrewHistory();
    }, [orderBy]);

    useEffect(() => {
        if (troubleshootingId !== 0 && troubleshootingId !== "")
            loadTroubleshootingCrewHistory();
    }, [descending])

    useEffect(() => {
        if (resetSearch !== 0)
            loadTroubleshootingCrewHistory()
    }, [resetSearch]);




    const handleOnBlurTroubleshooting = (troubleshootings) => {
        setTroubleshooting(troubleshootings)

    };

    const handleOnBlurSelectedProject = (projects) => {
       
        if(troubleshootingId === 0){
            setListTroubleshootingFinding([])
            setListTroubleshootingJob([]);
            setListTroubleshootingEquipment([])
        }
     
        setSelectedProject(projects)
    };

    const handleOnBlurlistCrew = (listCrews) => {
        setListTroubleshootingCrew(listCrews.slice(0))

    };


    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${projectName}-${moment(troubleshooting.service_date).format("yyyy-MM-DD")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();


        URL.revokeObjectURL(file);
        setLoading(false);

        setDisabledPrint(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getTroubleshootingReport(cookies.token, troubleshootingId, troubleshooting.project_id)
            if (docx.size) {
                setDocxData(docx);
            } else {
                alert('Gagal Mengunduh Dokumen');
                setDisabledPrint(false);
                setLoading(false)
            }
            // setServiceJobId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, cookies.extCustomerId);
            let projects = response.filter(p => p.project_type_name === 'Troubleshooting & Repair')
            setListProject(projects);
        } catch (exception) {
            console.log(exception)
        }
    }

    const validationForm = async () => {
        try {
            if (troubleshooting.project_id === undefined || troubleshooting.project_id === "") {
                setErrors({
                    ...errors,
                    projectName: '*Harus Diisi'
                },
                    setDisabledButton(true));
            }
            // else if (troubleshooting.service_date === undefined || troubleshooting.service_date === "") {
            //     setErrors({
            //         ...errors,
            //         serviceDate: '*Harus Diisi'
            //     },
            //         setDisabledButton(true));
            // }
            else if (troubleshooting.mechanic_group_id === undefined || troubleshooting.mechanic_group_id === null) {
                setErrors({
                    ...errors,
                    mechanicGroup: '*Harus Diisi'
                },
                    setDisabledButton(true));
            }
            // else if (troubleshooting.staff_pic_id === undefined || troubleshooting.staff_pic_id === "") {
            //     setErrors({
            //         ...errors,
            //         staffPIC: '*Harus Diisi'
            //     })
            //     setDisabledButton(true)
            // }
            else {
                let errorMsg = omit(errors, "projectName", "mechanicGroup");
                setErrors(errorMsg);
                setDisabledButton(false);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadMechanicGroup = async () => {
        try {
            let response = await getListStaffGroupMechanic(cookies.token, "null");
            setListMechanicGroup(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, "null", undefined, undefined, undefined, true, undefined, undefined, 2);
            let listStaffTmp = response.filter(p => p.is_field_staff === true)
            setListStaff(listStaffTmp);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStaffPosition = async () => {
        try {
            let response = await getStaffPosition(cookies.token, cookies.languageId);
            setListStaffPosition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffSpecialization = async () => {
        try {
            let response = await getSubSpecialization(cookies.token, cookies.languageId);
            let listSpecialization = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    id: response[i].id,
                    label: response[i].sub_specialization_name

                };
                listSpecialization.push(obj);
            }
            setListStaffSpecialization(listSpecialization);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselEquipment = async () => {
        try {
            let response = await getVesselEquipmentByVessel(cookies.token, selectedProject.vessel_id, searchQuery, false);
            setListVesselEquipment(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadEquipmentCategory = async () => {
        try {
            let response = await getEquipment(cookies.token);
            setListEquipmentCategory(response);
        } catch (exception) {
            console.log(exception)
        }
    };

    const initTroubleshooting = async () => {
        try {
            let response = await getTroubleshootingById(cookies.token, troubleshootingId);
            if (response) {
                setTroubleshooting({
                    ...troubleshooting,
                    id: response.id,
                    project_id: response.project_id,
                    service_date: response.service_date,
                    mechanic_group_id: response.mechanic_group_id,
                    status_id: response.status_id,
                    staff_pic_id: response.staff_pic_id,
                    service_location: response.service_location,
                    list_job: response.list_job,
                    // list_finding: response.list_finding,
                    // list_technician: response.list_technician,
                    // detail_job: response.detail_job
                });
                setProjectName(response.project_name);
                setListTroubleshootingCrew(response.list_technician);
                setListTroubleshootingFinding(response.list_finding);

                let listTroubleshootingEquipmentTmp = []
                if (response.detail_job.length > 0) {
                    let listJobTmp = []
                    for (let index = 0; index < response.detail_job.length; index++) {
                        const list = response.detail_job[index];
                        listJobTmp.push(list.list_job)
                        list.list_job = []
                        listTroubleshootingEquipmentTmp.push(list)
                    }
                    setListTroubleshootingJob(listJobTmp);
                }
                // console.log(listTroubleshootingEquipmentTmp)
                setListTroubleshootingEquipment(listTroubleshootingEquipmentTmp);
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const saveData = async () => {
        try {
            let troubleshootingTmp = troubleshooting;
            troubleshootingTmp.ext_customer_id = troubleshootingTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : troubleshooting.ext_customer_id;
            troubleshootingTmp.list_technician = listTroubleshootingCrew
            troubleshootingTmp.list_finding = listTroubleshootingFinding
            
            let listEqpTmp = listTroubleshootingEquipment
            if (listEqpTmp.length > 0) {
                for (let index = 0; index < listEqpTmp.length; index++) {
                    const listJob = listEqpTmp[index];
                    listJob.list_job = listTroubleshootingJob[index]
                }
            }
            troubleshootingTmp.detail_job = listEqpTmp
            // console.log(troubleshootingTmp)

            let response = await insertUpdateTroubleshooting(cookies.token, troubleshootingTmp);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data');
                if (troubleshootingId === 0) {
                    setTroubleshootingId(response.data.id)
                } else {
                    initTroubleshooting()
                }
            } else {
                alert('Gagal Menyimpan Data')
                setLoading(false);
            }
        } catch (exception) {
            console.log(exception)
        }
    }


    const loadTroubleshootingCrewHistory = async () => {
        try {
            let count = await getTroubleshootingCrewHistoryCount(cookies.token, troubleshootingId);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getTroubleshootingCrewHistory(cookies.token, troubleshootingId, page, itemPerPage, orderBy.value, descending, searchQueryHistory, false);
            setListCrewHistory(response);
            setResetSearch(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getTroubeleshootingStatus(cookies.token);
            setListStatus(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleOnBlur = (troubleshootingEquipment, listTroubleshootingJob2, index) => {
        let listTroubleshootingEquipmentTmp = listTroubleshootingEquipment;
        listTroubleshootingEquipmentTmp[index] = troubleshootingEquipment;
        setListTroubleshootingEquipment(listTroubleshootingEquipmentTmp.slice(0));
        let listTroubleshootingJobTmp = listTroubleshootingJob;
        listTroubleshootingJobTmp[index] = listTroubleshootingJob2;
        setListTroubleshootingJob(listTroubleshootingJobTmp.slice(0));

    };

    const handleDelete = (listDeleted, index) => {
        setTroubleshooting({ ...troubleshooting, deleted_job: listDeleted.deleted_job })
    };

    const handleDeleteTroubleshootingEquipment = (index) => {

        // console.log(listTroubleshootingEquipment);
        let listTroubleshootingEquipmentTmp = listTroubleshootingEquipment;
        let troubleshootingTmp = troubleshooting
        if (!troubleshootingTmp.deleted_detail) {
            let list = [];
            list.push(listTroubleshootingEquipmentTmp[index]);
            setTroubleshooting({ ...troubleshooting, deleted_detail: list })
        } else {
            let list = troubleshooting.deleted_detail;
            list.push(listTroubleshootingEquipmentTmp[index])
            setTroubleshooting({ ...troubleshooting, deleted_detail: list })
        }
        listTroubleshootingEquipmentTmp.splice(index, 1);
        setListTroubleshootingEquipment(listTroubleshootingEquipmentTmp.slice(0));

        let listJobTmp = listTroubleshootingJob
        listJobTmp.splice(index, 1)

        setListTroubleshootingJob(listJobTmp.slice(0))


    };


    const renderTroubleshootingEquipment = () => {
        let renderObj = [];
        if (listEquipmentCategory.length > 0) {
            for (let i = 0; i < listTroubleshootingEquipment.length; i++) {
                const svcEqp = <div key={i}>
                    <TroubleshootingEquipment
                        resetComponent={resetComponent}
                        index={i}
                        handleOnBlur={handleOnBlur}
                        handleDelete={handleDelete}
                        handleDeleteTroubleshootingEquipment={handleDeleteTroubleshootingEquipment}
                        troubleshootingEquipment={listTroubleshootingEquipment[i]}
                        listTroubleshootingJob={listTroubleshootingJob[i]}
                        listEquipment={listEquipmentCategory}
                        listVesselEquipment={listVesselEquipment}
                        disabledAddButton={disabledAddButton}
                        vesselId={selectedProject.vessel_id}
                        troubleshootingId={troubleshootingId}
                        troubleshooting={troubleshooting}
                        listTroubleshootingFinding={listTroubleshootingFinding}
                        disabledCustomerForm={disabledCustomerForm}
                    />
                </div>;

                renderObj.push(svcEqp);
            }
        }
        return renderObj;
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Troubleshooting"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Troubleshooting"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={troubleshootingId === 0 ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={disabledPrint} variant="secondary" style={{

                            }} onClick={() => {
                                setDisabledPrint(true)
                                createDocument();
                                setLoading(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><PrinterFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Cetak</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>


                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    setDisabledButton(true);
                                    saveData();
                                    setTimeout(function () {
                                        window.scrollTo(0, 0);
                                    }, 2);
                                }}>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={"Informasi Troubleshooting"} childContent={
                                                <TroubleshootingDetail
                                                    troubleshooting={troubleshooting}
                                                    handleOnBlurTroubleshooting={handleOnBlurTroubleshooting}
                                                    handleOnBlurSelectedProject={handleOnBlurSelectedProject}
                                                    listProject={listProject}
                                                    troubleshootingId={troubleshootingId}
                                                    listStatus={listStatus}
                                                    listMechanicGroup={listMechanicGroup}
                                                    listStaff={listStaff}
                                                    handleOnBlurlistCrew={handleOnBlurlistCrew}
                                                    listTroubleshootingCrew={listTroubleshootingCrew}
                                                    disabledCustomerForm={disabledCustomerForm}
                                                />

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={"Daftar Temuan"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {
                                                        listTroubleshootingFinding && listTroubleshootingFinding.map((listFinding, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <Accordion>
                                                                        <Accordion.Item eventKey="0">
                                                                            <Accordion.Header>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    flexWrap: "nowrap",
                                                                                    flex: 1,
                                                                                    width: "100%"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flex: 6,
                                                                                        flexWrap: "nowrap",
                                                                                        flexDirection: "column",
                                                                                        color: "black",
                                                                                        fontSize: 18
                                                                                    }}>
                                                                                        <p>{listFinding.equipment_name ? listFinding.equipment_name : "Pilih Kategori Peralatan"}</p>
                                                                                    </div>


                                                                                </div>



                                                                            </Accordion.Header>
                                                                            <Accordion.Body style={{
                                                                                backgroundColor: "rgba(219, 235, 255, 0.9)"
                                                                            }}>


                                                                                <div >
                                                                                    <Form.Label>Kategori Peralatan</Form.Label>
                                                                                    <Select placeholder={""}
                                                                                        getOptionLabel={(item) => {
                                                                                            return item.equipment_name;
                                                                                        }} clearValue={true}
                                                                                        getOptionValue={(item) => {
                                                                                            return item.id;
                                                                                        }}
                                                                                        options={listEquipmentCategory} value={listEquipmentCategory.filter(function (option) {
                                                                                            return option.id === listFinding.equipment_id;
                                                                                        })} onChange={(e) => {
                                                                                            let listFindingTmp = listTroubleshootingFinding;
                                                                                            listFindingTmp[index].equipment_id = e.id;
                                                                                            listFindingTmp[index].equipment_name = e.equipment_name;
                                                                                            listFindingTmp[index].vessel_equipment_id = null;
                                                                                            listFindingTmp[index].detail_job = [];
                                                                                            // listFindingTmp[index].index_finding = listTroubleshootingFinding.length <= 1 ? 'listFinding' + (listTroubleshootingFinding.length) : 'listFinding' + (Number(listFindingTmp[index - 1].index_finding.substr(listFindingTmp[index - 1].index_finding.length - 1)) + 1)

                                                                                            let newVesselEquipment = listVesselEquipment.filter(p => p.equipment_id === e.id);

                                                                                            setNewListVesselEquipment(newVesselEquipment);
                                                                                            setListTroubleshootingFinding(listFindingTmp.slice(0))
                                                                                            let ordersNumber = ""
                                                                                            if (listTroubleshootingEquipment.length > 0) {
                                                                                                ordersNumber = Number(listTroubleshootingEquipment[listTroubleshootingEquipment.length - 1].order_number) + 1
                                                                                            } else {
                                                                                                ordersNumber = listTroubleshootingEquipment.length + 1
                                                                                            }
                                                                                            let newParameter = {
                                                                                                equipment_id: e.id,
                                                                                                vessel_equipment_id: null,
                                                                                                equipment_name: e.equipment_name,
                                                                                                start_date: null,
                                                                                                end_date: null,
                                                                                                order_number: ordersNumber,
                                                                                                index_finding: listTroubleshootingFinding[index].index_finding
                                                                                            }
                                                                                            if (listTroubleshootingEquipment.length < 1) {
                                                                                                let details = [];
                                                                                                details.push(newParameter);
                                                                                                setListTroubleshootingEquipment(details.slice(0));
                                                                                            } else {
                                                                                                let indexFinding = listTroubleshootingEquipment.findIndex((newIndex) => newIndex.index_finding === listTroubleshootingFinding[index].index_finding);
                                                                                                let details = listTroubleshootingEquipment;
                                                                                                if (indexFinding >= 0) {
                                                                                                    details[indexFinding].equipment_id = e.id
                                                                                                    details[indexFinding].equipment_name = e.equipment_name
                                                                                                    details[indexFinding].vessel_equipment_id = null;
                                                                                                    details[indexFinding].manufacturer_name = "";
                                                                                                    details[indexFinding].serial_number = "";
                                                                                                    details[indexFinding].position_name = ""
                                                                                                    details[indexFinding].model = "";
                                                                                                    let troubleshootingJobTmp = listTroubleshootingJob
                                                                                                    troubleshootingJobTmp[indexFinding] = []
                                                                                                    setListTroubleshootingJob(troubleshootingJobTmp.slice(0))
                                                                                                } else {
                                                                                                    let newParameters = {
                                                                                                        equipment_id: e.id,
                                                                                                        vessel_equipment_id: null,
                                                                                                        equipment_name: e.equipment_name,
                                                                                                        start_date: null,
                                                                                                        end_date: null,
                                                                                                        order_number: ordersNumber,
                                                                                                        index_finding: listTroubleshootingFinding[index].index_finding
                                                                                                    }
                                                                                                    details.push(newParameters)
                                                                                                }
                                                                                                setListTroubleshootingEquipment(details.slice(0))

                                                                                            }

                                                                                        }}
                                                                                        isClearable
                                                                                        isDisabled={disabledCustomerForm}

                                                                                    />

                                                                                    <div style={{ paddingBottom: 10 }}></div>

                                                                                    <Form.Label>Peralatan Kapal</Form.Label>
                                                                                    <Select placeholder={""} onFocus={() => {
                                                                                        let newList = listVesselEquipment.filter(p => p.equipment_id === listTroubleshootingFinding[index].equipment_id);
                                                                                        setNewListVesselEquipment(newList);
                                                                                    }} isDisabled={!listFinding.equipment_id || disabledCustomerForm === true}
                                                                                        getOptionLabel={(item) => {
                                                                                            if (item.id) {
                                                                                                return (item.manufacturer_name ? item.manufacturer_name : " ") + ' - ' + item.model + `(${item.serial_number ? item.serial_number : ""})`
                                                                                            } else {
                                                                                                return ("");
                                                                                            }
                                                                                        }} clearValue={true}
                                                                                        getOptionValue={(item) => {
                                                                                            return item.id
                                                                                        }}
                                                                                        options={newListVesselEquipment} value={listVesselEquipment.filter(function (option) {
                                                                                            return option.id === listFinding.vessel_equipment_id;
                                                                                        })} onChange={(e) => {
                                                                                            let listFindingTmp = listTroubleshootingFinding;
                                                                                            listFindingTmp[index].vessel_equipment_id = e.id
                                                                                            setListTroubleshootingFinding(listFindingTmp.slice(0));

                                                                                            let indexFinding = listTroubleshootingEquipment.findIndex((newIndex) => newIndex.index_finding === listTroubleshootingFinding[index].index_finding);
                                                                                            let details = listTroubleshootingEquipment
                                                                                            if (indexFinding >= 0) {
                                                                                                details[indexFinding].vessel_equipment_id = e.id
                                                                                                details[indexFinding].manufacturer_name = e.manufacturer_name;
                                                                                                details[indexFinding].serial_number = e.serial_number;
                                                                                                details[indexFinding].position_name = e.position_name
                                                                                                details[indexFinding].model = e.model;
                                                                                            } else {
                                                                                            }
                                                                                            setListTroubleshootingEquipment(details.slice(0))
                                                                                        }}
                                                                                        isClearable

                                                                                    />
                                                                                    <div style={{ paddingBottom: 10 }}></div>

                                                                                    {!listFinding.vessel_equipment_id &&
                                                                                        <div
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                fontSize: 20,
                                                                                                color: "red"
                                                                                            }}>
                                                                                            <p>Peralatan Tidak Tersedia</p>

                                                                                        </div>
                                                                                    }

                                                                                    {
                                                                                        listFinding.vessel_equipment_id &&
                                                                                        <div>
                                                                                            <Editor disabled={disabledCustomerForm}
                                                                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                                                                onEditorChange={(evt, editor) => {
                                                                                                    let findingTmp = listTroubleshootingFinding;
                                                                                                    findingTmp[index].findings = evt
                                                                                                    setListTroubleshootingFinding(findingTmp.slice(0));
                                                                                                }}
                                                                                                value={listFinding.findings}
                                                                                                init={{
                                                                                                    height: 300,
                                                                                                    menubar: false,
                                                                                                    plugins: [
                                                                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                                                    ],
                                                                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                                                    image_title: true,
                                                                                                    automatic_uploads: true,
                                                                                                    /*
                                                                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                                                      images_upload_url: 'postAcceptor.php',
                                                                                                      here we add custom filepicker only to Image dialog
                                                                                                    */
                                                                                                    file_picker_types: 'image',
                                                                                                    /* and here's our custom image picker*/
                                                                                                    file_picker_callback: (cb, value, meta) => {
                                                                                                        const input = document.createElement('input');
                                                                                                        input.setAttribute('type', 'file');
                                                                                                        input.setAttribute('accept', 'image/*');

                                                                                                        input.addEventListener('change', (e) => {
                                                                                                            const file = e.target.files[0];
                                                                                                            const reader = new FileReader();
                                                                                                            reader.addEventListener('load', () => {
                                                                                                                /*
                                                                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                                                                  registry. In the next release this part hopefully won't be
                                                                                                                  necessary, as we are looking to handle it internally.
                                                                                                                */

                                                                                                                // console.log(editorRef.current.getContent())
                                                                                                                const id = 'blobid' + (new Date()).getTime();
                                                                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                                                                //   editorRef.current.editorUpload.blobCache

                                                                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                                                                const base64 = reader.result.split(',')[1];
                                                                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                                                                blobCache.add(blobInfo);

                                                                                                                /* call the callback and populate the Title field with the file name */
                                                                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                                                                            });
                                                                                                            reader.readAsDataURL(file);
                                                                                                        });

                                                                                                        input.click();
                                                                                                    },
                                                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                                                }}
                                                                                            />



                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                width: "100%",
                                                                                                flexDirection: "column",
                                                                                                padding: 5
                                                                                            }}>


                                                                                                {
                                                                                                    listFinding.detail_job && listFinding.detail_job.map((detail, i) => {
                                                                                                        return (
                                                                                                            <div key={i} style={{ padding: 5 }}>
                                                                                                                <div style={{
                                                                                                                    display: "flex",
                                                                                                                    width: "100%",
                                                                                                                    flexDirection: "column",
                                                                                                                    padding: 5,
                                                                                                                    borderWidth: 2,
                                                                                                                    borderRadius: 5,
                                                                                                                    borderStyle: "solid",
                                                                                                                    borderColor: "rgba(3, 30, 103, 1)"
                                                                                                                }}>
                                                                                                                    <div style={{
                                                                                                                        display: "flex",
                                                                                                                        flexDirection: "row"
                                                                                                                    }}>
                                                                                                                        <div style={{
                                                                                                                            display: "flex",
                                                                                                                            width: "100%",
                                                                                                                            flexDirection: "column",
                                                                                                                            flex: 9,
                                                                                                                            paddingLeft: 5,
                                                                                                                            paddingRight: 5
                                                                                                                        }}>
                                                                                                                            <Form.Label>Detail Pekerjaan</Form.Label>
                                                                                                                            <Form.Control disabled={disabledCustomerForm} required type="text" value={detail.job_name} onChange={(e) => {

                                                                                                                                let detailJobs = listTroubleshootingFinding
                                                                                                                                detailJobs[index].detail_job[i].job_name = e.target.value
                                                                                                                                // detailJobs[index].detail_job[i].index_job = detailJobs[index].detail_job.length <= 1 ? 'listJob' + (i + 1) : 'listJob' + (Number(detailJobs[index].detail_job[i - 1].index_job.substr(detailJobs[index].detail_job[i - 1].index_job.length - 1)) + 1)
                                                                                                                                setListTroubleshootingFinding(detailJobs.slice(0))

                                                                                                                                let indexFinding = listTroubleshootingEquipment.findIndex((newIndex) => newIndex.index_finding === detailJobs[index].index_finding);
                                                                                                                                if (indexFinding >= 0) {
                                                                                                                                    if (listTroubleshootingJob[indexFinding].length < 1) {
                                                                                                                                        let newParameter = {
                                                                                                                                            job_name: e.target.value,
                                                                                                                                            quality: null,
                                                                                                                                            progress: null,
                                                                                                                                            index_job: detail.index_job,
                                                                                                                                            order_number: listTroubleshootingJob[indexFinding].length + 1
                                                                                                                                        }
                                                                                                                                        let details = listTroubleshootingJob;
                                                                                                                                        details[indexFinding].push(newParameter)
                                                                                                                                        setListTroubleshootingJob(details.slice(0));
                                                                                                                                    } else {
                                                                                                                                        let indexJob = listTroubleshootingJob[indexFinding].findIndex((newIndex) => newIndex.index_job === detail.index_job);

                                                                                                                                        if (indexJob >= 0) {
                                                                                                                                            let details = listTroubleshootingJob;
                                                                                                                                            details[indexFinding][indexJob].job_name = e.target.value;
                                                                                                                                            setListTroubleshootingJob(details.slice(0));
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            let newParameters = {
                                                                                                                                                job_name: e.target.value,
                                                                                                                                                quality: null,
                                                                                                                                                progress: null,
                                                                                                                                                index_job: detail.index_job,
                                                                                                                                                order_number: Number(listTroubleshootingJob[indexFinding][listTroubleshootingJob[indexFinding].length - 1].order_number) + 1
                                                                                                                                            }
                                                                                                                                            let details = listTroubleshootingJob;
                                                                                                                                            details[indexFinding].push(newParameters)
                                                                                                                                            setListTroubleshootingJob(details.slice(0));
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }}></Form.Control>

                                                                                                                        </div>


                                                                                                                    </div>
                                                                                                                    <div style={{ paddingTop: 10 }}></div>
                                                                                                                    <div style={{
                                                                                                                        display: "flex",
                                                                                                                        width: "100%",
                                                                                                                        // flexDirection: "column",
                                                                                                                        justifyContent: "center"
                                                                                                                    }}>

                                                                                                                        <Button disabled={disabledCustomerForm} onClick={() => {
                                                                                                                            if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                                                let list = listTroubleshootingFinding
                                                                                                                                if (!listFinding.deleted_jobs) {
                                                                                                                                    // console.log('1')
                                                                                                                                    let listJobs = [];
                                                                                                                                    listJobs.push(list[index].detail_job[i]);
                                                                                                                                    list[index].deleted_jobs = listJobs;
                                                                                                                                    list[index].detail_job.splice(i, 1);
                                                                                                                                    setListTroubleshootingFinding(list.slice(0))
                                                                                                                                } else {
                                                                                                                                    // console.log('2')
                                                                                                                                    let listJobs = listTroubleshootingFinding;
                                                                                                                                    listJobs[index].deleted_jobs.push(listJobs[index].detail_job[i]);
                                                                                                                                    listJobs[index].detail_job.splice(i, 1)
                                                                                                                                    setListTroubleshootingFinding(listJobs.slice(0));
                                                                                                                                }
                                                                                                                                let newList = listTroubleshootingEquipment
                                                                                                                                let indexFinding = listTroubleshootingEquipment.findIndex((newIndex) => newIndex.index_finding === listTroubleshootingFinding[index].index_finding);
                                                                                                                                if (indexFinding >= 0) {
                                                                                                                                    let indexJob = listTroubleshootingJob[indexFinding].findIndex((newIndex) => newIndex.index_job === detail.index_job); {
                                                                                                                                        if (indexJob >= 0) {
                                                                                                                                            if (!troubleshooting.deleted_job) {
                                                                                                                                                let list = [];
                                                                                                                                                let newList = listTroubleshootingJob
                                                                                                                                                list.push(newList[indexFinding][indexJob])

                                                                                                                                                setTroubleshooting({ ...troubleshooting, deleted_job: list })
                                                                                                                                                newList[indexFinding].splice(indexJob, 1)
                                                                                                                                                setListTroubleshootingJob(newList.slice(0));
                                                                                                                                            } else {
                                                                                                                                                let newList = listTroubleshootingJob
                                                                                                                                                let listDeleted = troubleshooting.deleted_job
                                                                                                                                                listDeleted.push(newList[indexFinding][indexJob]);
                                                                                                                                                setTroubleshooting({ ...troubleshooting, deleted_job: listDeleted })
                                                                                                                                                newList[indexFinding].splice(indexJob, 1)
                                                                                                                                                setListTroubleshootingJob(newList.slice(0));
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }

                                                                                                                                }

                                                                                                                            }

                                                                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                                                                    </div>


                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    })
                                                                                                }

                                                                                            </div>
                                                                                            {
                                                                                                errorJobsFinding && <p style={{ color: "red" }}>{errorJobsFinding}</p>
                                                                                            }

                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                width: "100%",
                                                                                                flexDirection: "column",
                                                                                                padding: 5
                                                                                            }}>
                                                                                                <Button disabled={disabledCustomerForm === true ? true:disabledPlusButtonFinding} variant="primary" onClick={() => {
                                                                                                    let newParameter = {
                                                                                                        job_name: "",
                                                                                                        index_job: listTroubleshootingFinding[index].detail_job.length <= 1 ? 'listJob' + (listTroubleshootingFinding[index].detail_job.length + 1) : 'listJob' + (Number(listTroubleshootingFinding[index].detail_job[listTroubleshootingFinding[index].detail_job.length - 1].index_job.substr(listTroubleshootingFinding[index].detail_job[listTroubleshootingFinding[index].detail_job.length - 1].index_job.length - 1)) + 1)
                                                                                                    };
                                                                                                    if (!listFinding.detail_job) {
                                                                                                        let detailJobs = listTroubleshootingFinding
                                                                                                        detailJobs[index].detail_job = [];
                                                                                                        detailJobs[index].detail_job.push(newParameter);
                                                                                                        setListTroubleshootingFinding(detailJobs.slice(0))
                                                                                                    } else {
                                                                                                        let detailJobs = listTroubleshootingFinding;
                                                                                                        detailJobs[index].detail_job.push(newParameter);
                                                                                                        setListTroubleshootingFinding(detailJobs.slice(0))
                                                                                                    }

                                                                                                }}>Tambah Pekerjaan</Button>
                                                                                            </div>







                                                                                        </div>
                                                                                    }



                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "row",
                                                                                        padding: 5,
                                                                                        justifyContent: "center"

                                                                                    }}>


                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <Button disabled={disabledCustomerForm} variant="danger" onClick={() => {
                                                                                                if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                    let detailJobs = listTroubleshootingFinding;
                                                                                                    let indexFinding = listTroubleshootingEquipment.findIndex((newIndex) => newIndex.index_finding === listTroubleshootingFinding[index].index_finding);
                                                                                                    // console.log('IndexFinding2', indexFinding)
                                                                                                    let troubleshootingDetailJob = listTroubleshootingEquipment;
                                                                                                    let deletedDetailTmp
                                                                                                    if (indexFinding >= 0) {
                                                                                                        if (!troubleshooting.deleted_detail) {
                                                                                                            // console.log('deleted1');
                                                                                                            let list = [];
                                                                                                            list.push(troubleshootingDetailJob[indexFinding]);

                                                                                                            troubleshootingDetailJob.splice(indexFinding, 1);
                                                                                                            // setTroubleshooting({ ...troubleshooting, deleted_detail: list, });
                                                                                                            deletedDetailTmp = list
                                                                                                            setListTroubleshootingEquipment(troubleshootingDetailJob.slice(0));

                                                                                                            let listJobTmp = listTroubleshootingJob;
                                                                                                            listJobTmp.splice(indexFinding, 1);
                                                                                                            setListTroubleshootingJob(listJobTmp.slice(0));
                                                                                                        } else {
                                                                                                            // console.log('deleted2');
                                                                                                            let list = troubleshooting.deleted_detail;
                                                                                                            list.push(troubleshootingDetailJob[indexFinding]);
                                                                                                            troubleshootingDetailJob.splice(indexFinding, 1);
                                                                                                            deletedDetailTmp = list
                                                                                                            // setTroubleshooting({ ...troubleshooting, deleted_detail: list, });
                                                                                                            setListTroubleshootingEquipment(troubleshootingDetailJob.slice(0));
                                                                                                            let listJobTmp = listTroubleshootingJob;
                                                                                                            listJobTmp.splice(indexFinding, 1);
                                                                                                            setListTroubleshootingJob(listJobTmp.slice(0));
                                                                                                        }
                                                                                                    }

                                                                                                    if (!troubleshooting.deleted_finding) {
                                                                                                        let list = [];
                                                                                                        list.push(detailJobs[index]);

                                                                                                        detailJobs.splice(index, 1);
                                                                                                        setTroubleshooting({ ...troubleshooting, deleted_finding: list, deleted_detail: deletedDetailTmp });
                                                                                                        setListTroubleshootingFinding(detailJobs.slice(0))
                                                                                                    } else {
                                                                                                        let list = troubleshooting.deleted_finding;
                                                                                                        list.push(detailJobs[index]);
                                                                                                        detailJobs.splice(index, 1);
                                                                                                        setTroubleshooting({ ...troubleshooting, deleted_finding: list, deleted_detail: deletedDetailTmp });
                                                                                                        setListTroubleshootingFinding(detailJobs.slice(0))
                                                                                                    }





                                                                                                }


                                                                                            }}><Trash size={30} /></Button>
                                                                                        </div>



                                                                                    </div>



                                                                                </div>



                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </Accordion>
                                                                    <div style={{ paddingBottom: 10 }}></div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        findingErrorMessage && <p style={{ color: "red" }}>{findingErrorMessage}</p>
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button disabled={disabledCustomerForm === true?  true :disabledAddFinding} variant="primary" onClick={() => {
                                                          
                                                            let newParameter = {
                                                                equipment_id: null,
                                                                vessel_equipment_id: null,
                                                                index_finding: listTroubleshootingFinding.length <= 1 ? 'listFinding' + (listTroubleshootingFinding.length) : 'listFinding' + (Number(listTroubleshootingFinding[listTroubleshootingFinding.length - 1].index_finding.substr(listTroubleshootingFinding[listTroubleshootingFinding.length - 1].index_finding.length - 1)) + 1),
                                                                detail_job: []

                                                            };
                                                            if (listTroubleshootingFinding.length === 0) {
                                                                // console.log('2');
                                                                let listDetail = [];
                                                                listDetail.push(newParameter);
                                                                setListTroubleshootingFinding(listDetail.slice(0))
                                                            } else {
                                                                // console.log('1')
                                                                let listDetail = listTroubleshootingFinding;
                                                                listDetail.push(newParameter);
                                                                setListTroubleshootingFinding(listDetail.slice(0));
                                                            }

                                                        }}>Tambah Temuan</Button>
                                                    </div>

                                                </div>
                                            }>

                                            </ContainerBox>


                                        </div>
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={"Grup Teknisi"}
                                                useActionContainer={troubleshootingId !== 0 && troubleshootingId !== "" ? true : false}
                                                actionContainerChild={
                                                    <div>
                                                        <Button variant="secondary" style={{

                                                        }} onClick={() => {
                                                            setHistoryModal(true)
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><Clock size={22} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 10,
                                                                    fontWeight: "bold",
                                                                    fontSize: 18,
                                                                }}>Lihat Riwayat</div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                }
                                                childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>


                                                            {
                                                                listTroubleshootingCrew && listTroubleshootingCrew.map((technician, index) => {
                                                                    return (
                                                                        <div key={index} style={{ padding: 5 }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                padding: 5,
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                                borderStyle: "solid",
                                                                                borderColor: "rgba(3, 30, 103, 1)"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>Nama Teknisi</Form.Label>
                                                                                        <FormSelect placeholder=""
                                                                                            options={listStaff.filter((p) => {
                                                                                                return !listTroubleshootingCrew.find((x) => {
                                                                                                    return p.staff_id === x.staff_id
                                                                                                }) && p.id !== troubleshooting.staff_pic_id && p.resign_date === null
                                                                                            })} getOptionLabel={(item) => {
                                                                                                return item.staff_name;
                                                                                            }} clearValue={true}
                                                                                            getOptionValue={(item) => {
                                                                                                return item.staff_id;
                                                                                            }} setValueFunction={(e) => {
                                                                                                let listTechnician = listTroubleshootingCrew;
                                                                                                listTechnician[index].staff_id = e.staff_id;
                                                                                                listTechnician[index].crew_position = e.crew_position;
                                                                                                listTechnician[index].specialization = e.specialization
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                            }} value={listStaff.filter(function (option) {
                                                                                                return option.staff_id === technician.staff_id;
                                                                                            })}
                                                                                            disabledForm={disabledCustomerForm}
                                                                                        />

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>
                                                                                        <Form.Label>Jabatan Teknisi</Form.Label>
                                                                                        <FormSelect placeholder=""
                                                                                            options={listStaffPosition} getOptionLabel={(item) => {
                                                                                                return item.position_name;
                                                                                            }} clearValue={true}
                                                                                            getOptionValue={(item) => {
                                                                                                return item.id;
                                                                                            }} setValueFunction={(e) => {

                                                                                            }} value={listStaffPosition.filter(function (option) {
                                                                                                return option.id === technician.crew_position;
                                                                                            })} disabledForm={true}
                                                                                        />


                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>

                                                                                        <Form.Label>Spesialisasi Teknisi</Form.Label>
                                                                                        <div style={{ paddingTop: 5 }}></div>
                                                                                        <Select styles={styles} isMulti={true} placeholder=""
                                                                                            clearValue={true} closeMenuOnSelect={false}
                                                                                            options={listStaffSpecialization} value={technician.specialization} onChange={(e) => {

                                                                                            }} isClearable isDisabled={true}

                                                                                        />

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        alignItems: "flex-end",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <div style={{ paddingTop: 35 }}></div>
                                                                                        <Button disabled={technician.is_leader === true || disabledCustomerForm === true ? true : false} onClick={() => {
                                                                                            if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                let list = listTroubleshootingCrew
                                                                                                if (!troubleshooting.deleted_technician) {
                                                                                                    let listTechnician = [];
                                                                                                    listTechnician.push(list[index]);

                                                                                                    list.splice(index, 1);
                                                                                                    setTroubleshooting({ ...troubleshooting, deleted_technician: listTechnician });
                                                                                                    setListTroubleshootingCrew(list.slice(0))
                                                                                                } else {
                                                                                                    let listTechnician = troubleshooting.deleted_technician;
                                                                                                    listTechnician.push(list[index]);
                                                                                                    list.splice(index, 1);
                                                                                                    setTroubleshooting({ ...troubleshooting, deleted_technician: listTechnician });
                                                                                                    setListTroubleshootingCrew(list.slice(0))
                                                                                                }
                                                                                            }

                                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                                    </div>

                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>Waktu Keberangkatan</Form.Label>
                                                                                        <Form.Control disabled={disabledCustomerForm} required type="datetime-local" onChange={(e) => {
                                                                                            let listTechnician = listTroubleshootingCrew;
                                                                                            if (e.target.value === "") {
                                                                                                listTechnician[index].departure_date = null;
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                            } else {
                                                                                                listTechnician[index].departure_date = e.target.value;
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                            }


                                                                                        }} value={technician.departure_date ? moment(technician.departure_date).format("yyyy-MM-DD HH:mm") : ""} placeholder=""></Form.Control>

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>Waktu Tiba</Form.Label>
                                                                                        <Form.Control disabled={disabledCustomerForm} type="datetime-local" onChange={(e) => {
                                                                                            let listTechnician = listTroubleshootingCrew;
                                                                                            if (e.target.value === "") {
                                                                                                listTechnician[index].arrived_date = null;
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0));
                                                                                            } else {
                                                                                                listTechnician[index].arrived_date = e.target.value;
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                            }


                                                                                        }} value={technician.arrived_date ? moment(technician.arrived_date).format("yyyy-MM-DD HH:mm") : ""} placeholder=""></Form.Control>

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>Waktu Turun</Form.Label>
                                                                                        <Form.Control disabled={disabledCustomerForm} type="datetime-local" onChange={(e) => {
                                                                                            let listTechnician = listTroubleshootingCrew;
                                                                                            if (e.target.value === "") {
                                                                                                listTechnician[index].leave_date = null;
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                            } else {
                                                                                                listTechnician[index].leave_date = e.target.value;
                                                                                                setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                            }


                                                                                        }} value={technician.leave_date ? moment(technician.leave_date).format("yyyy-MM-DD HH:mm") : ""} placeholder=""></Form.Control>

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        // width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>
                                                                                        <Form.Label>Tiba Dari</Form.Label>
                                                                                        <Form.Control disabled={disabledCustomerForm} type="text" onChange={(e) => {
                                                                                            let listTechnician = listTroubleshootingCrew;
                                                                                            listTechnician[index].arrived_from = e.target.value;
                                                                                            setListTroubleshootingCrew(listTechnician.slice(0))
                                                                                        }} value={technician.arrived_from} placeholder=""></Form.Control>

                                                                                    </div>


                                                                                </div>





                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                crewErrorMessage && <p style={{ color: "red" }}>{crewErrorMessage}</p>
                                                            }
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>
                                                            <Button disabled={disabledCustomerForm === true ? true : disabledButtonCrew} variant="primary" onClick={() => {
                                                                let newParameter = {
                                                                    staff_id: "",
                                                                    departure_date: null,
                                                                    arrived_date: null,
                                                                    arrived_from: "",
                                                                    leave_date: null
                                                                };
                                                                if (listTroubleshootingCrew.length === 0) {
                                                                    let listCrew = [];
                                                                    listCrew.push(newParameter);
                                                                    setListTroubleshootingCrew(listCrew.slice(0))
                                                                } else {
                                                                    let listCrew = listTroubleshootingCrew;
                                                                    listCrew.push(newParameter);
                                                                    setListTroubleshootingCrew(listCrew.slice(0))
                                                                }

                                                            }}>Tambah Teknisi</Button>
                                                        </div>

                                                    </div>
                                                }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={"Detail Pekerjaan"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {

                                                        renderTroubleshootingEquipment()

                                                    }
                                                    {
                                                        equipmentErrorMessage && <p style={{ color: "red" }}>{equipmentErrorMessage}</p>
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button disabled={disabledCustomerForm === true ? true :disabledAddButton} variant="primary" onClick={() => {
                                                            let ordersNumber = ""
                                                            if (listTroubleshootingEquipment.length > 0) {
                                                                ordersNumber = Number(listTroubleshootingEquipment[listTroubleshootingEquipment.length - 1].order_number) + 1
                                                            } else {
                                                                ordersNumber = listTroubleshootingEquipment.length + 1
                                                            }
                                                            // console.log(ordersNumber);
                                                            let newParameter = {
                                                                equipment_id: null,
                                                                vessel_equipment_id: null,
                                                                start_date: null,
                                                                end_date: null,
                                                                order_number: ordersNumber,
                                                                // list_job: []

                                                            };
                                                            if (listTroubleshootingEquipment.length === 0) {
                                                                let list = [];
                                                                list.push(newParameter);
                                                                // setTroubleshooting({ ...troubleshooting, detail_job: list });
                                                                setListTroubleshootingEquipment(list.slice(0))
                                                            } else {
                                                                let list = listTroubleshootingEquipment;
                                                                list.push(newParameter);
                                                                // setTroubleshooting({ ...troubleshooting, detail_job: list });
                                                                setListTroubleshootingEquipment(list.slice(0))
                                                            }

                                                        }}>Tambah Detail</Button>
                                                    </div>

                                                </div>
                                            }>

                                            </ContainerBox>

                                        </div>
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        padding: 10
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            paddingRight: 5
                                        }}>
                                            <Button style={{ width: 100 }} type="submit" disabled={disabledButton === true || disabledCustomerForm === true ? true : !updatedButton}>Simpan</Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            paddingLeft: 5
                                        }}>
                                            <Button style={{ width: 100 }} variant="danger" onClick={() => {
                                                navigate('/Troubleshooting')
                                            }}>Batal</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>


                        </div >

                    }>


                </ContainerBox >


                <Modal size="xl" show={fotoModal} onHide={
                    () => {
                        setFotoModal(false);
                        setIndexFoto("");
                        setIndexDetailFoto("")
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={"Foto Hasil Kerja"}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setFotoModal(false);
                                    setIndexFoto("");
                                    setIndexDetailFoto("")
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Tutup</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>


                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        setFotoModal(false);
                                        setIndexFoto("");
                                        setIndexDetailFoto("")

                                    }}>
                                        {
                                            indexFoto !== "" && listTroubleshootingEquipment[indexFoto].list_job[indexDetailFoto].list_photo.map((foto, index) => {
                                                return (
                                                    <div>
                                                        <div key={index} style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            borderWidth: 2,
                                                            borderRadius: 5,
                                                            borderStyle: "solid",
                                                            borderColor: "rgba(3, 30, 103, 1)",
                                                            padding: 10
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                <img src={foto.base64_image}
                                                                    style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Foto</Form.Label>
                                                                    <Form.Control required type="file" onChange={async (e) => {

                                                                        let base64Img = await convertBase64Image(e.target.files[0]);
                                                                        const base64ImgString = base64Img.toString();
                                                                        let detailJobs = listTroubleshootingEquipment
                                                                        detailJobs[indexFoto].list_job[indexDetailFoto].list_photo[index].base64_image = base64ImgString;
                                                                        // setTroubleshooting({ ...troubleshooting, detail_job: detailJobs })
                                                                        setListTroubleshootingEquipment(detailJobs.slice(0))
                                                                    }}></Form.Control>
                                                                </Form.Group>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                flex: 1

                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Label</Form.Label>
                                                                        <Form.Control required type="text" onChange={(e) => {
                                                                            let detailJobs = listTroubleshootingEquipment
                                                                            detailJobs[indexFoto].list_job[indexDetailFoto].list_photo[index].label = e.target.value;
                                                                            // setTroubleshooting({ ...troubleshooting, detail_job: detailJobs })
                                                                            setListTroubleshootingEquipment(detailJobs.slice(0))
                                                                        }} value={foto.label}></Form.Control>
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    paddingLeft: 5

                                                                }}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Tanggal Foto Diambil</Form.Label>
                                                                        <Form.Control required type="date" onChange={(e) => {
                                                                            let detailJobs = listTroubleshootingEquipment;
                                                                            detailJobs[indexFoto].list_job[indexDetailFoto].list_photo[index].taken_date = e.target.value;
                                                                            // setTroubleshooting({ ...troubleshooting, detail_job: detailJobs })
                                                                            setListTroubleshootingEquipment(detailJobs.slice(0))
                                                                        }} value={foto.taken_date ? moment(foto.taken_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                    </Form.Group>
                                                                </div>


                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Keterangan</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                        let detailJobs = listTroubleshootingEquipment;
                                                                        detailJobs[indexFoto].list_job[indexDetailFoto].list_photo[index].description = e.target.value;
                                                                        // setTroubleshooting({ ...troubleshooting, detail_job: detailJobs })
                                                                        setListTroubleshootingEquipment(detailJobs.slice(0))
                                                                    }} value={foto.description}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                flex: 1,
                                                                paddingLeft: 5,
                                                                paddingRight: 5
                                                            }}>
                                                                <div style={{ paddingTop: 35 }}></div>
                                                                <Button onClick={() => {
                                                                    if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                        let detailJobs = listTroubleshootingEquipment;
                                                                        detailJobs[indexFoto].list_job[indexDetailFoto].list_photo.splice(index, 1);
                                                                        // setTroubleshooting({ ...troubleshooting, detail_job: detailJobs })
                                                                        setListTroubleshootingEquipment(detailJobs.slice(0))
                                                                    }

                                                                }} variant="danger"><Trash size={20} /></Button>

                                                            </div>
                                                            <div style={{ paddingBottom: 10 }}></div>
                                                        </div>
                                                        <div style={{ paddingBottom: 10 }}></div>
                                                    </div>



                                                )
                                            })
                                        }
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Button variant="primary" onClick={() => {
                                                let newParameter = {
                                                    base64_image: "",
                                                    label: "",
                                                    taken_date: null,
                                                    description: ""
                                                };
                                                if (!listTroubleshootingEquipment[indexFoto].list_photo) {
                                                    let newList = listTroubleshootingEquipment
                                                    newList[indexFoto].list_job[indexDetailFoto].list_photo.push(newParameter);
                                                    // setTroubleshooting({ ...troubleshooting, detail_job: newList });
                                                    setListTroubleshootingEquipment(newList.slice(0))
                                                } else {
                                                    let newList = listTroubleshootingEquipment
                                                    newList[indexFoto].list_job[indexDetailFoto].list_photo.push(newParameter);
                                                    // setTroubleshooting({ ...troubleshooting, detail_job: newList });
                                                    setListTroubleshootingEquipment(newList.slice(0))
                                                }

                                            }}>Tambah Foto</Button>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5,
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                paddingRight: 5,
                                            }}><Button variant="primary" type="submit">Simpan</Button></div>


                                            <div style={{
                                                paddingLeft: 5,
                                            }}>
                                                <Button variant="danger" onClick={() => {
                                                    setFotoModal(false);
                                                    setIndexFoto("")
                                                }}>Batal</Button>

                                            </div>
                                        </div>
                                    </Form>


                                </div>






                            </div>

                        }>
                    </ContainerBox>
                </Modal>

                <Modal show={historyModal}
                    size={"xl"}
                    onHide={() => {
                        setHistoryModal(false);
                        setLoading(false);
                        setSearchQueryHistory("");
                    }}>
                    <div className="details m-2" >

                        <div className="detailscontent">
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: "100%"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "flex-start"
                                }}>
                                    <h3>Riwayat Teknisi </h3>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignContent: "flex-end",
                                    alignItems: "flex-end"
                                }}>
                                    <Button variant="secondary" style={{

                                    }} onClick={() => {
                                        setHistoryModal(false);
                                        setLoading(false);
                                        setSearchQueryHistory("");
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><XSquareFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>Tutup</div>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>

                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQueryHistory} type="text" placeholder="Cari (Nama Teknisi)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                await loadTroubleshootingCrewHistory();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQueryHistory(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            await loadTroubleshootingCrewHistory();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQueryHistory("");
                                            // await loadTroubleshootingCrewHistory();
                                            setResetSearch(1);

                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container" style={{ padding: 10 }}>
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Teknisi</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Jabatan Teknisi</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Waktu Keberangkatan</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Waktu Tiba</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Waktu Turun</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Tiba Dari</div>

                                </div>
                                {
                                    listCrewHistory.length === 0 &&
                                    <div>
                                        <div colSpan={7} style={{ textAlign: "center" }}>Tidak Ada Data</div>
                                    </div>
                                }

                                {
                                    listCrewHistory.map((crew, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.mechanic_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.position_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.departure_date ? moment(crew.departure_date).format("yyyy-MM-DD") : ""}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.arrived_date ? moment(crew.arrived_date).format("yyyy-MM-DD") : ""}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.leave_date ? moment(crew.leave_date).format("yyyy-MM-DD") : ""}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.arrived_from}</p></div>


                                        </div>
                                        );
                                    })
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                        </div>





                    </div>

                </Modal>

                <Modal size="xl" show={descriptionModal} onHide={
                    () => {
                        setDescriptionModal(false);
                        setIndexDescription("");
                        setIndexDescriptionJob("");
                    }
                }>
                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Catatan Pekerjaan"}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setDescriptionModal(false);
                                    setIndexDescription("");
                                    setIndexDescriptionJob("");
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Tutup</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 3
                                        }}>Deskripsi Pekerjaan</div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 3,
                                            paddingLeft: 5,
                                            paddingRight: 5
                                        }}>

                                            {
                                                indexDescription !== "" &&
                                                <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                    // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                    onEditorChange={(evt, editor) => {
                                                        let detailJobs = listTroubleshootingEquipment;
                                                        detailJobs[indexDescription].list_job[indexDescriptionJob].description = evt;
                                                        // setTroubleshooting({ ...troubleshooting, detail_job: detailJobs })
                                                        setListTroubleshootingEquipment(detailJobs.slice(0))
                                                    }}
                                                    value={listTroubleshootingEquipment[indexDescription].list_job[indexDescriptionJob].description}
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                            'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                        ],
                                                        toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                        toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                        image_title: true,
                                                        automatic_uploads: true,
                                                        /*
                                                          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                          images_upload_url: 'postAcceptor.php',
                                                          here we add custom filepicker only to Image dialog
                                                        */
                                                        file_picker_types: 'image',
                                                        /* and here's our custom image picker*/
                                                        file_picker_callback: (cb, value, meta) => {
                                                            const input = document.createElement('input');
                                                            input.setAttribute('type', 'file');
                                                            input.setAttribute('accept', 'image/*');

                                                            input.addEventListener('change', (e) => {
                                                                const file = e.target.files[0];
                                                                const reader = new FileReader();
                                                                reader.addEventListener('load', () => {
                                                                    /*
                                                                      Note: Now we need to register the blob in TinyMCEs image blob
                                                                      registry. In the next release this part hopefully won't be
                                                                      necessary, as we are looking to handle it internally.
                                                                    */

                                                                    // console.log(editorRef.current.getContent())
                                                                    const id = 'blobid' + (new Date()).getTime();
                                                                    const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                    //   editorRef.current.editorUpload.blobCache

                                                                    //Editor.activeEditor.editorUpload.blobCache;

                                                                    const base64 = reader.result.split(',')[1];
                                                                    const blobInfo = blobCache.create(id, file, base64);
                                                                    blobCache.add(blobInfo);

                                                                    /* call the callback and populate the Title field with the file name */
                                                                    cb(blobInfo.blobUri(), { title: file.name });
                                                                });
                                                                reader.readAsDataURL(file);
                                                            });

                                                            input.click();
                                                        },
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />
                                            }



                                        </div>

                                    </div>


                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    {/* <Button variant="primary" onClick={() => {
                                        setNoteModal(false);
                                        setIndexDetail("");
                                        setIndexDetailJob("");

                                    }}>Simpan</Button> */}
                                    <div style={{ paddingBottom: 10 }}></div>
                                    {/* <Button variant="danger" onClick={() => {
                                        // let detailJobs = serviceJob.detail_job;
                                        // detailJobs[indexDetail].list_job[indexDetailJob].description = "";
                                        // setServiceJob({ ...serviceJob, detail_job: detailJobs });

                                        setNoteModal(false);
                                        setIndexDetail("");
                                        setIndexDetailJob("");

                                    }}>Batal</Button> */}
                                </div>

                            </div>

                        }>
                    </ContainerBox>
                </Modal>

            </Container >
            <Loading
                loading={loading}
            />


        </>
    )
}