import "./Footer.css";

export default function Footer() {
    return (
        <div className="footer">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    PT RADIAN PENINSULA INDONESIA
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    Deployed : Dec 7, 2022 :: IVS 16.1.170.22.11.25.2.R0
                </div>
            </div>
            <div style={{paddingBottom:80}}></div>
        </div>
    )
}