// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numStat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: #14163F;
    border-radius: 7px;
    font-size: 15px;
    padding: 10px;
}

.leftContentNumStat {
    display: flex;
    flex-direction: column;
}

.rightContentNumStat {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    margin-left: auto;
    margin-right: 10px;
}

.text2NumStat {
    color: #298BFE;
    font-size: 13px;
    font-style: italic;
}

.iconNumStat {
    display: flex;
    align-items: flex-end;
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NumStat/NumStat.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,WAAW;AACf","sourcesContent":[".numStat {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    height: 60px;\n    background: #14163F;\n    border-radius: 7px;\n    font-size: 15px;\n    padding: 10px;\n}\n\n.leftContentNumStat {\n    display: flex;\n    flex-direction: column;\n}\n\n.rightContentNumStat {\n    display: flex;\n    align-items: flex-end;\n    font-weight: bold;\n    margin-left: auto;\n    margin-right: 10px;\n}\n\n.text2NumStat {\n    color: #298BFE;\n    font-size: 13px;\n    font-style: italic;\n}\n\n.iconNumStat {\n    display: flex;\n    align-items: flex-end;\n    width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
