import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, convertBase64, getMPImageById, insertUpdateMPImage, deleteMPImage, getMPImageByCount, checkPhotoIsCover,
        getUserAccessById
} from "../../../../../Helpers/ApplicationHelpers";
import "../../../../../App.css";
import Navbar from "../../../../../Components/NavBar";
import { omit } from 'lodash';
import Modals from "../../../../../Components/modal";
import { Trash, FilePlusFill, InfoCircleFill, FileCheckFill, GearWideConnected } from "react-bootstrap-icons";
import Paginations from "../../../../../Components/Pagination";
import Loading from "../../../../../Components/Loading";
import ContainerBox from "../../../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function MaintenancePlanImagePage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [listMaintenancePlan, setListMaintenancePlan] = useState([]);
    const [show, setShow] = useState(false);
    const [updatedButton,setUpdatedButton] = useState("");
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const handleShow = () => setShow(true);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 20, 40]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("maintenance_plan_image.date_taken")}`,
        value: "taken_date"
    });
    // const [orderBySelection, setOrderBySelection] = useState(["Title", "Taken Date"]);
    const orderBySelection = [
        {
            label: `${t("maintenance_plan_image.date_taken")}`,
            value: "taken_date"
        }
    ]
    const [mpImageId, setMPImageId] = useState("");
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [description, setDescription] = useState(null);
    const [mpImageCount, setMPImageCount] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [mpImage, setMPImage] = useState({
        maintenancePlanId: "",
        base64Img: "",
        description: "",
        takenDate: null,
        name: ""
    })
    const [fileImg, setFileImg] = useState("");
    const [coverId, setCoverId] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadMaintenancePlan();
                setLoading(false);
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
        console.log(cookies);
    }, []);

    useEffect(() => {
        if (mpImageId !== "") {
            removeMPImage();
        }

    }, [mpImageId]);

    useEffect(() => {
        loadMaintenancePlan();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadMaintenancePlan();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadMaintenancePlan();
    }, [orderBy]);

    useEffect(() => {
        if (coverId !== "") {
            setCoverPhoto();
            loadMaintenancePlan();
        }

    }, [coverId]);



    const loadMaintenancePlan = async () => {
        try {
            let mpImageCount = await getMPImageByCount(cookies.token, location.state.maintenancePlanId);
            setMPImageCount(mpImageCount);
            let totalPage = mpImageCount / itemPerPage;
            setTotalPage(totalPage);
            // console.log('cookiestoken:', cookies.token)
            // console.log('MPID:', location.state.maintenancePlanId)
            // console.log('page:', page)
            // console.log('itemperpage', itemPerPage)
            // console.log('orderby:', orderBy.value)
            let listMaintenancePlan = await getMPImageById(cookies.token, location.state.maintenancePlanId, page, itemPerPage, orderBy.value);
            console.log('listmaintenanceplan: ', listMaintenancePlan)
            setListMaintenancePlan(listMaintenancePlan);
            if (listMaintenancePlan) {
                setMPImage({
                    ...mpImage,
                    maintenancePlanId: listMaintenancePlan[0].maintenance_plan_name,
                    image: listMaintenancePlan.base64_image,
                    takenDate: listMaintenancePlan.taken_date,
                    name: listMaintenancePlan.name,
                    isCover: listMaintenancePlan.is_cover
                })

            } else {

            }
            setIsSearched(0)
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const addImage = async () => {
        try {
            setDisabledButton(true);
            console.log(location.state.maintenancePlanId);
            let response = await insertUpdateMPImage(cookies.token, mpImage, location.state.maintenancePlanId);
            if (response === 0) {
                alert(`${t("maintenance_plan_image.alert_success_save_data")}`);
                // window.location.reload();
                handleClose();
                loadMaintenancePlan();
                // loadVessel();
                // resetInputdata();

            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.base64_image);
        setDescription(item.description)
    };

    const handelRotationRight = () => {
        const totalLength = listMaintenancePlan.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listMaintenancePlan[0].base64_image;
            const descUrl = listMaintenancePlan[0].description;
            setClickedImg(newUrl);
            setDescription(descUrl)
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listMaintenancePlan.filter((item) => {
            return listMaintenancePlan.indexOf(item) === newIndex;
        });
        const descUrl = listMaintenancePlan.filter((item) => {
            return listMaintenancePlan.indexOf(item) === newIndex;
        })
        const newItem = newUrl[0].base64_image;
        const descNew = descUrl[0].description
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const handelRotationLeft = () => {
        const totalLength = listMaintenancePlan.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listMaintenancePlan[totalLength - 1].base64_image;
            const descUrl = listMaintenancePlan[totalLength - 1].description;

            setClickedImg(newUrl);
            setDescription(descUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listMaintenancePlan.filter((item) => {
            return listMaintenancePlan.indexOf(item) === newIndex;
        });
        const descUrl = listMaintenancePlan.filter((item) => {
            return listMaintenancePlan.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_image;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const removeMPImage = async () => {
        let response = await deleteMPImage(cookies.token, mpImageId);
        if (response === 0) {
            // window.location.reload();
            loadMaintenancePlan();
        }
    }

    const handleResets = async () => {

        setMPImage({
            maintenancePlanIdId: "",
            base64Img: "",
            description: "",
            takenDate: "",
            name: ""
        });

        setFileImg("");

    }

    const setCoverPhoto = async () => {
        try {
            let response = await checkPhotoIsCover(cookies.token, location.state.maintenancePlanId, coverId);
            if (response === 0) {
                alert(`${t("maintenance_plan_image.alert_set_cover")}`);
                window.location.reload();
                loadMaintenancePlan();
            }
        } catch (exception) {
            console.loge(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")))
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            console.log('respon from load access:', response)
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
                
            }
            setOrderBy({
                label: `${t("maintenance_plan_image.date_taken")}`,
                value: "taken_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container>
                <ContainerBox titleCaption={location.state.maintenancePlanName}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                                                     
                            
                            <Button disabled={
                                false
                                // disabledCustomerForm === true ? true :!updatedButton
                            } variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setMPImage({
                                    maintenancePlanId: "",
                                    base64Img: "",
                                    description: "",
                                    takenDate: "",
                                    name: ""
                                });
                                setFileImg("");
                                handleShow();
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("maintenance_plan_image.button_add_image")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <Col md={12}>
                                <Row>
                                    {
                                        listMaintenancePlan.map((mp, index) => {
                                            return (

                                                <Col md={3} key={index}>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        flexWrap: "nowrap",
                                                        flexDirection: "column",
                                                        padding: 10,
                                                        borderWidth: 1,
                                                        margin: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "#2f71bd",
                                                        borderRadius: 5,
                                                        height: 400,

                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 5,
                                                            alignContent: "flex-start",
                                                            alignItems: "flex-start",
                                                            justifyContent: "center",
                                                            alignSelf: "center",
                                                            flexWrap: "wrap",

                                                        }}>
                                                            <img src={mp.base64_image} onClick={() => {
                                                                handleClick(mp, index)
                                                            }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                        </div>
                                                        <div style={{
                                                            flex: 1,
                                                            alignContent: "flex-end",
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                            alignSelf: "flex-end",
                                                            flexWrap: "nowrap",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                        }} >
                                                            <div style={{
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                alignContent: "flex-end",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                alignSelf: "flex-end",
                                                                textAlign: "center"
                                                            }}><p>{!mp.name ? "" : mp.name}</p></div>
                                                            <div style={{
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                alignContent: "flex-end",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                alignSelf: "flex-end",
                                                                textAlign: "center"
                                                            }}><p>{!mp.taken_date ? "" : mp.taken_date}</p>
                                                                <div style={{
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    justifyContent: "flex-end",
                                                                    alignSelf: "flex-end",
                                                                }}>
                                                                    <Button disabled={
                                                                        false
                                                                        // disabledCustomerForm === true ? true : !deletedButton
                                                                    } onClick={() => {
                                                                        if (window.confirm(`${t("maintenance_plan_image.delete_confirmation")}`)) {
                                                                            let mpImageId = mp.id
                                                                            setMPImageId(mpImageId);
                                                                            // removeStaff();
                                                                        }
                                                                    }} variant="danger"><Trash /></Button>
                                                                </div>
                                                            </div>
                                                            {
                                                                mp.is_cover === true ?
                                                                    <Form.Check onClick={(e) => {
                                                                        setCoverId(mp.id);
                                                                    }} type="checkbox" defaultChecked={mp.is_cover} label={`${t("maintenance_plan_image.cover_label")}`} />
                                                                    :
                                                                    <>
                                                                        <input onClick={() => {
                                                                            setCoverId(mp.id);
                                                                        }} type="checkbox" />
                                                                        <label>&nbsp;{t("maintenance_plan_image.cover_label")}</label>
                                                                    </>

                                                            }
                                                            {/* <Form.Check onClick={(e) => {
                                                    setCoverId(vessel.id);
                                                }} type="checkbox" defaultChecked={vessel.is_cover} label="Is Cover" /> */}
                                                            {/* <p>{`'${vessel.is_cover}'`}</p> */}

                                                        </div>

                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderBySelection}
                                setIsSearched={setIsSearched}
                            />

                            <Loading
                                loading={loading}
                            />
                        </div>} />
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>{t("maintenance_plan_image.modal_add_image")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        addImage();
                    }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("maintenance_plan_image.field_mp_image")}</Form.Label>
                            <Form.Control onChange={async (e) => {
                                if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                    setFileImg(e.target.value)
                                    let base64Img = await convertBase64(e.target.files[0]);
                                    const base64ImgString = base64Img.toString();
                                    setMPImage({ ...mpImage, base64Img: base64ImgString, file: e.target.value });
                                    setUploadFileImageError("");
                                    setDisabledButton(false)

                                } else {
                                    setFileImg("")
                                    setMPImage({ ...mpImage, base64Img: "", file: "" });
                                    setUploadFileImageError(`${t("maintenance_plan_image.error_image_validation")}`);
                                    setDisabledButton(true)
                                }



                            }} type="file" value={fileImg} required></Form.Control>
                            {
                                uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("maintenance_plan_image.field_image_label")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setMPImage({ ...mpImage, name: e.target.value })

                            }} value={mpImage.name} type="text" placeholder="" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("maintenance_plan_image.field_taken_date")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setMPImage({ ...mpImage, takenDate: e.target.value })
                            }} value={mpImage.takenDate} type="date" placeholder="" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("maintenance_plan_image.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setMPImage({ ...mpImage, description: e.target.value })
                            }} value={mpImage.description} as="textarea" rows={3} placeholder=""></Form.Control>
                        </Form.Group>
                        <Button style={{width:100}} variant="primary" disabled={
                            false
                            // disabledButton === true ? disabledButton : !updatedButton
                            } type="submit">
                        {t("maintenance_plan_image.save_button")}
                        </Button>
                        <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                            handleResets();
                            handleClose();
                        }}>
                            {t("maintenance_plan_image.cancel_button")}
                        </Button>
                    </Form>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            {clickedImg && (
                <Modals
                    clickedImg={clickedImg}
                    handelRotationRight={handelRotationRight}
                    setClickedImg={setClickedImg}
                    handelRotationLeft={handelRotationLeft}
                    image={listMaintenancePlan}
                    description={description}
                    setDescription={setDescription}
                />
            )}

        </>
    )


}