import "./index.css";
import Navbar from "../../Components/NavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import SMSNavContainer from "../../Components/SMSNavContainer/SMSNavContainer";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid } from "../../Helpers/ApplicationHelpers";
import Loading from "../../Components/Loading";
import SMSMainEngine from "./SMSMainEngine";
import SMSAuxEngine from "./SMSAuxEngine";
import SMSPumpMotor from "./SMSPumpMotor";
import SMSTankLevel from "./SMSTankLevel";
import SMSWatertightDoor from "./SMSWatertightDoor";
import SMSMainSwitchBoard from "./SMSMSB";
import SMSNavSignal from "./SMSNavSignal";
import SMSLocation from "./SMSLocation";
import SMSBilges from "./SMSBilges";
import SMSFireAlarm from "./SMSFireAlarm";

export default function SMSDashboard() {
  // ============ SMS Dashboard as Parent ============
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["token"]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [currentChild, setCurrentChild] = useState(null);
  const [smsSerialNumber, setSmsSerialNumber] = useState("");
  // const [vesselId, setVesselId] = useState("");
  const [titleBar, setTitleBar] = useState("");

  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated)
        navigate("/");
      else {
        if (location.state.smsSerialnumber) {
          setSmsSerialNumber(location.state.smsSerialnumber);
          // setVesselId(location.state.vesselId);
        } else {
          //navigate("/");
        }
      }
    }
    checkCookies();
    console.log(cookies);
    // console.log(vesselId);
  }, []);

  const handleMenuClick = (val) => {
    console.log(val);
    setActiveTab(val);
  }

  useEffect(() => {
    if (smsSerialNumber !== "") {
      setCurrentChild(<SMSMainEngine smsSerialNumber={smsSerialNumber} />);
    }
  }, [smsSerialNumber]);

  useEffect(() => {
    if (smsSerialNumber !== "") {
      switch (activeTab) {
        case 0:
          setCurrentChild(<SMSMainEngine smsSerialNumber={smsSerialNumber} />);
          setTitleBar("Main Engine");
          break;
        case 1:
          setCurrentChild(<SMSAuxEngine smsSerialNumber={smsSerialNumber} />);
          setTitleBar("Auxiliary Engine");
          break;
        case 2:
          setCurrentChild(<SMSPumpMotor smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Pump and Motor");
          break;
        case 3:
          setCurrentChild(<SMSTankLevel smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Tank Level");
          break;
        case 4:
          setCurrentChild(<SMSWatertightDoor smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Watertight Door");
          break;
        case 5:
          setCurrentChild(<SMSMainSwitchBoard smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Main Switch Board");
          break;
        case 6:
          setCurrentChild(<SMSNavSignal smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Navigation Signal Light");
          break;
        case 7:
          setCurrentChild(<SMSBilges smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Bilges");
          break;
        case 8:
          setCurrentChild(<SMSLocation smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Vessel Location");
          break;
        case 9:
          setCurrentChild(<SMSFireAlarm smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
          setTitleBar("Fire Alarm");
          break;
        // case 7:
          /* setCurrentChild(<SMSLocation smsSerialNumber={smsSerialNumber} />);
          setTitleBar("Alarm List"); */
          // break;
        default:
          return (null);
          break;
      }
    }
  }, [activeTab]);




  return (
    <>
      <Navbar />
      <div className="mainPage">
        <div className="containerBox">
          <TitleAlarmBar
            text={titleBar}
            smsSerialNumber={smsSerialNumber}
          />
          <SMSNavContainer
            handleMenuClick={handleMenuClick}
            activeTab={activeTab}
            childContent={currentChild ? currentChild : null}
            smsSerialNumber={smsSerialNumber}
          />

        </div>
      </div>
      <Loading loading={false} />
    </>
  );
}
