// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  background-color: "#ff0000";  
}

.rpi-logo-container{
  padding-top: 15px;
  
}

.rpi-tagline {
  text-align: center;

}

.login-label {

  font-size: medium;
  
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;;AAEnB;;AAEA;EACE,kBAAkB;;AAEpB;;AAEA;;EAEE,iBAAiB;;AAEnB","sourcesContent":[".body {\n  background-color: \"#ff0000\";  \n}\n\n.rpi-logo-container{\n  padding-top: 15px;\n  \n}\n\n.rpi-tagline {\n  text-align: center;\n\n}\n\n.login-label {\n\n  font-size: medium;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
