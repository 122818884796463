import "./NumStat.css";
import iconNumStat from "../../Assets/Images/numStatIcon.png";

export default function NumStat({ text1, text2, icon = false, number }) {
    return (
        <div className="numStat">
            <div className="leftContentNumStat">
                <div>{text1}</div>
                <div className="text2NumStat">
                    {text2}
                </div>
            </div>
            <div className="rightContentNumStat">
                <div>{number}</div>
            </div>
            <div className="iconNumStat">
                <div>{icon ? <img src={iconNumStat} alt="" /> : ""}</div>
            </div>
        </div>
    )
}