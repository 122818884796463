import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVesselxEquipmentById, insertUpdateVesselxEquipment, getVessel, getEquipment,
    getParameter, getVesselByCompany, getCompany, getVesselXEquipmentPhotoCount, getVesselxEquipmentPhotoById,
    convertBase64, insertUpdateVesselxEquipmentPhoto, getDocument, getParameterEquipment, getProject, getSparepartCatalog,
    getSparepartCatalogById, getSparepartParameter, insertUpdateVesselEquipmentDocument, deleteVesselEquipmentDocument,
    getEquipmentPosition,getVesselById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import Modals from "../../../../Components/modal";
import { CameraFill, FilePlusFill, EyeFill, Trash, Download,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import ButtonForm from "../../../../Components/ButtonForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from 'moment';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function VesselEquipmentDetailPage() {
    const [sparepartCatalogOptions, setSparepartCatalogOptions] = useState([]);
    const [vesselOptions, setVesselOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    // const [sparepartCatalogId, setSparepartCatalogId] = useState(0);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [documentId, setDocumentId] = useState("");
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [listCompany, setListCompany] = useState([]);
    const [zoomFactor, setZoomFactor] = useState(1);
    const [errors, setErrors] = useState({});
    const [companyId, setCompanyId] = useState(0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [vesselEquipment, setVesselEquipment] = useState({
        vessel_id: "",
        equipment_id: "",
        title: "",
        description: "",
        model: "",
        manufacture_name: "",
        sparepart_catalog_id: null,
        serial_number: "",
        equipment_position_id: null
    });
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [parameter, setParameter] = useState([])
    const [vesselEquipmentPhotoCount, setVesselEquipmentPhotoCount] = useState(0)
    const [listPhoto, setListPhoto] = useState([]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [totalPage, setTotalPage] = useState(0);
    const handleClose = () => setShow(false);
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const showFormPhoto = () => setShow(true);
    const [show, setShow] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
    const [showFormDocument, setShowFormDocument] = useState(false);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 16, 20]);
    const [orderBy, setOrderBy] = useState({
        label: "Taken Data",
        value: "taken_date"
    });
    // const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
    const MAX_COUNT = 5;
    const [description, setDescription] = useState(null);
    // const [orderBySelection, setOrderBySelection] = useState(["Taken Date"]);
    const orderBySelection = [{
        label: "Taken Data",
        value: "taken_date"
    }]
    const [vesselPhotoEquipment, setvesselPhotoEquipment] = useState({
        vessel_x_equipment_id: '',
        vessel_id: '',
        equipment_id: '',
        base64_img: '',
        title: '',
        description: '',
        taken_date: ''
    });
    const [fileImg, setFileImg] = useState("");
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");




    // const handleUploadFiles = async (files) => {
    //     const uploaded = [...uploadedFiles];
    //     let limitExceeded = false;
    //     let tes = [];
    //     for (let index = 0; index < files.length; index++) {

    //         let base64Img = await convertBase64(files[index]);
    //         const base64ImgString = base64Img.toString();
    //         tes.push(base64ImgString);
    //     }

    //     files.some((file, index) => {

    //         if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    //             if (uploaded.findIndex((f) => f.name === file.name) === -1) {

    //                 uploaded.push({
    //                     document_base64: tes[index],
    //                     document_type: file.type,
    //                     document_name: file.name
    //                 });
    //                 if (uploaded.length === MAX_COUNT) setFileLimit(true);
    //                 if (uploaded.length > MAX_COUNT) {
    //                     alert(`You can only add a maximum of ${MAX_COUNT} files`);
    //                     setFileLimit(false);
    //                     limitExceeded = true;
    //                     return true;
    //                 }

    //             }
    //         } else {
    //             // alert('File yang bisa diupload hanya word,excel dan pdf')
    //             console.log("Tes");
    //             console.log(file.type);

    //         }

    //     })
    //     if (!limitExceeded) setUploadedFiles(uploaded)

    // }
    // const handleFileEvent = (e) => {
    //     const chosenFiles = Array.prototype.slice.call(e.target.files)
    //     handleUploadFiles(chosenFiles);
    // }

    const [newDocument, setNewDocument] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        vessel_x_equipment_id: "",
        is_active: true,
        done: false,
        reference_number:""
    });

    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        reference_number: "",
        description: "",
        vessel_x_equipment_id: "",
        is_active: true
    });

    const [documentFile, setDocumentFile] = useState("");

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            document_base64: "",
            document_name: "",
            reference_number: "",
            description: "",
            vessel_x_equipment_id: vesselEquipmentId,
            is_active: true,
        };
        setNewDocument({ ...newDocument, newDocument });

    }

    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...parameter];
        list[index][name] = value;
        setParameter(list);

    }

    const handleremove = index => {
        const list = [...parameter];
        list.splice(index, 1);
        setParameter(list);
    }

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);


    useEffect(() => {
        if (location.state.vesselEquipmentId !== 0)
            initVesselEquipment();
    }, [page]);

    useEffect(() => {
        setLoading(true);
        if (vesselEquipmentId !== "")
            removeDocument();
    }, [documentId]);

    useEffect(() => {
        if (vesselEquipment.sparepart_catalog_id !== null)
            loadSparepartCatalogById();
    }, [vesselEquipment.sparepart_catalog_id]);

    useEffect(() => {
        if (location.state.vesselEquipmentId !== 0)
            initVesselEquipment();
    }, [itemPerPage]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (location.state.vesselEquipmentId !== 0)
            initVesselEquipment();
    }, [orderBy]);

    useEffect(() => {
        validationForm();
    }, [vesselEquipment]);

    useEffect(() => {
        if (vesselEquipment.equipment_id !== "") {
            loadEquipment();
        }
        // console.log(vesselEquipment.equipment_id);

    }, [vesselEquipment.equipment_id])


    const handleaddclick = () => {
        setParameter([...parameter, { parameter_name: '', parameter_value: '', parameter_desc: '' }]);
    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.base64_img);
        setDescription(item.description)
    };

    const handelRotationRight = () => {
        const totalLength = listPhoto.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listPhoto[0].base64_img;
            const descUrl = listPhoto[0].description;
            setClickedImg(newUrl);
            setDescription(descUrl)
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        })
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const handelRotationLeft = () => {
        const totalLength = listPhoto.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listPhoto[totalLength - 1].base64_img;
            const descUrl = listPhoto[totalLength - 1].description;

            setClickedImg(newUrl);
            setDescription(descUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };


    const [listVessel, setListVessel] = useState([]);
    const [listEquipment, setListEquipment] = useState([]);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadEquipment();
                loadCompany();
                loadEquipmentPosition();
                loadSparepartCatalog();
                setVesselEquipmentId(location.state.vesselEquipmentId);
                if (location.state.code === 'vessel') {
                    if(location.state.companyId !== undefined){
                        setCompanyId(location.state.companyId);
                    }else{
                        let companyy = await getVesselById(cookies.token, location.state.vesselId,cookies.languageId)
                        setCompanyId(companyy.company_id);
                    }

                    setVesselEquipment({ ...vesselEquipment, vessel_id: location.state.vesselId })
                }
            }
        }
        checkCookies();
        if (location.state.vesselEquipmentId === 0) {
            setLoading(false);
        } else {
            initVesselEquipment();
        }
    }, []);

    useEffect(() => {
        loadVessel();
    }, [companyId]);

    useEffect(() => {
        setNewDocument({ ...newDocument, vessel_x_equipment_id: vesselEquipmentId });
        loadDocumentData();
        setLoading(false);
    }, [vesselEquipmentId])



    const addVesselEquipment = async () => {
        try {
            let response = await insertUpdateVesselxEquipment(cookies.token, vesselEquipment, location.state.vesselEquipmentId, parameter, undefined, cookies.languageId);
            if (response.error_code === 0) {
                alert("Berhasil Menyimpan Data");
                if (location.state.vesselEquipmentId === 0) {
                    navigate('/vesselEquipment/Detail', { state: { vesselEquipmentId: response.data.id } })
                }
                else {
                    initVesselEquipment();
                }
                setVesselEquipmentId(response.data.id);
            }
            else {
                alert('Gagal Menyimpan Data')
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception)
            setDisabledButton(false);
        }
    }

    const loadVessel = async () => {
        try {
            let listVessel = await getVesselByCompany(cookies.token, cookies.languageId, companyId);
            setListVessel(listVessel);
            let listVesselNew = [];
            for (let i = 0; i < listVessel.length; i++) {
                var obj = {
                    value: listVessel[i].id,
                    label: listVessel[i].vessel_name,
                    id: listVessel[i].id

                };

                listVesselNew.push(obj);

            }
            setVesselOptions(listVesselNew);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadCompany = async () => {
        try {
            let listCompany = await getCompany(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListCompany(listCompany);
            let listCompanyNew = [];
            for (let i = 0; i < listCompany.length; i++) {
                var obj = {
                    value: listCompany[i].id,
                    label: listCompany[i].company_name,
                    id: listCompany[i].id

                };

                listCompanyNew.push(obj);

            }
            setCompanyOptions(listCompanyNew);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadSparepartCatalog = async () => {
        try {
            let response = await getSparepartCatalog(cookies.token, cookies.extCustomerId);
            let listsparepartCatalog = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: `${response[i].manufacture_name} - ${response[i].model} (${response[i].equipment_name})`,
                    id: response[i].id

                };

                listsparepartCatalog.push(obj);

            }
            setSparepartCatalogOptions(listsparepartCatalog);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadEquipment = async () => {
        try {
            let listEquipment = await getEquipment(cookies.token);
            setListEquipment(listEquipment);
            let listEquipmentNew = [];
            for (let i = 0; i < listEquipment.length; i++) {
                var obj = {
                    value: listEquipment[i].id,
                    label: listEquipment[i].equipment_name,
                    id: listEquipment[i].id

                };

                listEquipmentNew.push(obj);

            }
            setEquipmentOptions(listEquipmentNew);

            if (location.state.vesselEquipmentId === 0) {
                if (vesselEquipment.equipment_id !== "" && vesselEquipment.sparepart_catalog_id === null) {
                    let equipment = await getParameterEquipment(cookies.token, vesselEquipment.equipment_id)
                    setParameter(equipment);
                }
            }


        } catch (exception) {

        }
    }

    const initVesselEquipment = async () => {
        try {
            let response = await getVesselxEquipmentById(cookies.token, location.state.vesselEquipmentId);
            setVesselEquipment({
                ...vesselEquipment,
                id: response[0].id,
                vessel_id: response[0].vessel_id,
                equipment_id: response[0].equipment_id,
                idParameter: response[0].parameter_id,
                title: response[0].title,
                company: response[0].company_id,
                description: response[0].description,
                base64_img: response[0].base64_img,
                vesselName: response[0].vessel_name,
                equipmentName: response[0].equipment_name,
                model: response[0].model,
                manufacture_name: response[0].manufacture_name,
                sparepart_catalog_id: response[0].sparepart_catalog_id,
                serial_number: response[0].serial_number,
                equipment_position_id: response[0].equipment_position_id
            });
            setCompanyId(response[0].company_id);

            let parameters = await getParameter(cookies.token, location.state.vesselEquipmentId);

            setParameter(parameters);
            setDisabledSelected(true);


            // setUploadedFiles(docs)
            let vesselEquipmentPhotoCount = await getVesselXEquipmentPhotoCount(cookies.token, location.state.vesselEquipmentId);
            setVesselEquipmentPhotoCount(vesselEquipmentPhotoCount);
            let totalPage = vesselEquipmentPhotoCount / itemPerPage;
            setTotalPage(totalPage);
            let listPhoto = await getVesselxEquipmentPhotoById(cookies.token, location.state.vesselEquipmentId, page, itemPerPage, orderBy.value);
            setListPhoto(listPhoto);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDocumentData = async () => {
        try {
            let response = await getDocument(cookies.token, location.state.vesselEquipmentId);
            setListDocument(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteVesselEquipmentDocument(cookies.token, documentId);
        if (response) {
            loadDocumentData();
        } else {
            alert("Terjadi kesalahan sistem, mohon hubungi administrator.");
            setLoading(false);
        }

    }

    const photoParameter = async () => {
        try {
            let response = await getVesselxEquipmentById(cookies.token, location.state.vesselEquipmentId);
            setvesselPhotoEquipment({
                ...vesselPhotoEquipment,
                vessel_x_equipment_id: response[0].id,
                vessel_id: response[0].vessel_id,
                equipment_id: response[0].equipment_id
            })
        } catch (exception) {
            console.log(exception)

        }
    }

    const handleResets = async () => {
        setvesselPhotoEquipment({
            base64_img: '',
            title: '',
            description: '',
            taken_date: ''
        });
        setFileImg("");
    }
    const addVesselEquipmentPhoto = async () => {
        try {
            let response = await insertUpdateVesselxEquipmentPhoto(cookies.token, vesselPhotoEquipment, location.state.vesselEquipmentId);
            if (response === 0) {
                // alert("Vessel Photo Equipment Successfully Saved");
                addVesselEquipment();
                setShow(false);
                handleResets();
                initVesselEquipment();
            }
            else {
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (vesselEquipment.vessel_id === undefined || vesselEquipment.vessel_id === "") {
                    setErrors({
                        ...errors,
                        vesselName: '*Harus Diisi'
                    },
                        setDisabledButton(true));
                } else if (vesselEquipment.equipment_id === undefined || vesselEquipment.equipment_id === "") {
                    setErrors({
                        ...errors,
                        equipmentName: '*Harus Diisi'
                    },
                        setDisabledButton(true));
                }
                else if (vesselEquipment.title === undefined || vesselEquipment.title === "") {
                    setErrors({
                        ...errors,
                        equipmentTitle: '*Harus Diisi'
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "vesselName", "equipmentName", "equipmentTitle");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadSparepartCatalogById = async () => {
        try {
            let response = await getSparepartCatalogById(cookies.token, vesselEquipment.sparepart_catalog_id);
            if (response) {
                setVesselEquipment({
                    ...vesselEquipment,
                    equipment_id: response[0].equipment_id,
                    title: response[0].title,
                    description: response[0].description,
                    model: response[0].model,
                    manufacture_name: response[0].manufacture_name,
                    equipment_position_id: response[0].equipment_position_id
                });

                if (location.state.vesselEquipmentId === 0) {
                    let param = await getSparepartParameter(cookies.token, vesselEquipment.sparepart_catalog_id);
                    setParameter(param);

                }
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const uploadDocument = async () => {

        try {
            setDisabledButton(true)
            let res = await insertUpdateVesselEquipmentDocument(cookies.token, newDocument);
            setShowFormDocument(false);
            resetUploadForm();
            loadDocumentData();
            setDisabledButton(false);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentPosition = async () => {
        try {
            let response = await getEquipmentPosition(cookies.token);
            setListEquipmentPosition(response);
        } catch (exception) {
            console.log(exception)
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Peralatan Kapal"} useActionContainer={true}
                    actionContainerChild={

                        <div>

                            {
                                location.state.vesselEquipmentId === 0
                                    ?
                                    <Button variant="secondary" style={{

                                    }} onClick={() => navigate('/VesselEquipment/Copy')}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                                fontWeight: "bold"
                                            }}>Salin Data</div>

                                        </div>
                                    </Button> : <></>

                            }

                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <center><img src={vesselEquipment.base64_img} style={{ width: "50%" }} /></center>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Peralatan Kapal"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <FormSelect caption="Katalog Suku Cadang" autoFocusValue={vesselEquipment.sparepart_catalog_id === undefined} placeholder="Pilih Sukucadang..."
                                                                options={sparepartCatalogOptions} value={sparepartCatalogOptions.filter(function (option) {
                                                                    return option.id === vesselEquipment.sparepart_catalog_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setVesselEquipment({ ...vesselEquipment, sparepart_catalog_id: e.id })
                                                                        // setProject({ ...project, contract_id: e.id, company_id: e.companyId });
                                                                    } else {
                                                                        setVesselEquipment({ ...vesselEquipment, sparepart_catalog_id: null, title: "", model: "", manufacture_name: "", equipment_position_id: "", equipment_id: null })
                                                                        // setProject({ ...project, contract_id: null })
                                                                    }

                                                                }} disabledForm={location.state.vesselEquipmentId === 0 ? false : true}
                                                            />
                                                            {
                                                                location.state.code === 'vessel' ?
                                                                    <>
                                                                        <DropDownForm value={companyId} caption="Nama Perusahaan" placeholder="Pilih Perusahaan "
                                                                            setValueFunction={(e) => {
                                                                                setCompanyId(e.target.value);
                                                                            }} listOption={listCompany} valueKey="id" labelKey="company_name" disabledForm="true" />
                                                                        {/* {
                                                                        errors.company_category_ids && <p style={{ color: "red" }}>{errors.company_category_ids}</p>
                                                                    } */}
                                                                        <DropDownForm value={vesselEquipment.vessel_id} caption="Nama Kapal" placeholder="Pilih Kapal"
                                                                            setValueFunction={(e) => {
                                                                                setVesselEquipment({ ...vesselEquipment, vessel_id: e.target.value });
                                                                            }} listOption={listVessel} valueKey="id" labelKey="vessel_name" disabledForm="true" />
                                                                        {
                                                                            errors.vesselName && <p style={{ color: "red" }}>{errors.vesselName}</p>
                                                                        }

                                                                    </>
                                                                    :
                                                                    <div>

                                                                        <FormSelect caption="Nama Perusahaan" autoFocusValue={companyId === undefined} placeholder="Pilih Perusahaan..."
                                                                            options={companyOptions} value={companyOptions.filter(function (option) {
                                                                                return option.id === companyId;
                                                                            })} setValueFunction={(e) => {
                                                                                setCompanyId(e.id);
                                                                            }}
                                                                        />
                                                                        {/* <DropDownForm value={vesselEquipment.company} caption="Nama Perusahaan" placeholder="Pilih Perusahaan "
                                                                            setValueFunction={(e) => {
                                                                                setCompanyId(e.target.value);
                                                                            }} listOption={listCompany} valueKey="id" labelKey="company_name" /> */}
                                                                        {/* {
                                                                        errors.equipmentName && <p style={{ color: "red" }}>{errors.equipmentName}</p>
                                                                    } */}
                                                                        {/* <DropDownForm value={vesselEquipment.vessel_id} caption="Nama Kapal" placeholder="Pilih Kapal"
                                                                            setValueFunction={(e) => {
                                                                                setVesselEquipment({ ...vesselEquipment, vessel_id: e.target.value });
                                                                            }} listOption={listVessel} valueKey="id" labelKey="vessel_name" /> */}
                                                                        {/* <Select autoFocus={vesselEquipment.vessel_id === undefined} placeholder= "Pilih Kapal..."  required
                                                                            options={options} value={options.filter(function (option) {
                                                                                return option.id === vesselEquipment.vessel_id;
                                                                            })} onChange={(e) => {
                                                                                setVesselEquipment({ ...vesselEquipment, vessel_id: e.id });
                                                                            }}
                                                                        /> */}
                                                                        <FormSelect caption="Nama Kapal" autoFocusValue={vesselEquipment.vessel_id === undefined} placeholder="Pilih Kapal..."
                                                                            options={vesselOptions} value={vesselOptions.filter(function (option) {
                                                                                return option.id === vesselEquipment.vessel_id;
                                                                            })} setValueFunction={(e) => {
                                                                                setVesselEquipment({ ...vesselEquipment, vessel_id: e.id });
                                                                            }}
                                                                        />
                                                                        {
                                                                            errors.vesselName && <p style={{ color: "red" }}>{errors.vesselName}</p>
                                                                        }
                                                                    </div>
                                                            }
                                                            {/* <DropDownForm value={vesselEquipment.equipment_id} caption="Nama Peralatan" placeholder="Pilih Peralatan"
                                                                setValueFunction={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, equipment_id: e.target.value });
                                                                }} listOption={listEquipment} valueKey="id" labelKey="equipment_name" /> */}
                                                            <FormSelect caption="Nama Peralatan" autoFocusValue={vesselEquipment.equipment_id === undefined} placeholder="Pilih Peralatan..."
                                                                options={equipmentOptions} value={equipmentOptions.filter(function (option) {
                                                                    return option.id === vesselEquipment.equipment_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setVesselEquipment({ ...vesselEquipment, equipment_id: e.id });
                                                                        // setProject({ ...project, contract_id: e.id, company_id: e.companyId });
                                                                    } else {
                                                                        setVesselEquipment({ ...vesselEquipment, equipment_id: null });
                                                                        // setProject({ ...project, contract_id: null })
                                                                    }

                                                                }} disabledForm={vesselEquipment.sparepart_catalog_id !== null ? true : false}
                                                            />
                                                            {
                                                                errors.equipmentName && <p style={{ color: "red" }}>{errors.equipmentName}</p>
                                                            }
                                                            <TextBox value={vesselEquipment.title} placeholder="Titel"
                                                                caption="Titel" setValueFunction={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, title: e.target.value });
                                                                }} />
                                                            {
                                                                errors.equipmentTitle && <p style={{ color: "red" }}>{errors.equipmentTitle}</p>
                                                            }

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            paddingLeft: 10
                                                        }}>
                                                            <TextBox value={vesselEquipment.serial_number} placeholder="Serial Number"
                                                                caption="serial Number" setValueFunction={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, serial_number: e.target.value });
                                                                }} />
                                                            <Form.Group>
                                                                <Form.Label>Posisi Peralatan</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    let selectedEquipPosition = listEquipmentPosition.find(p => p.id === e.target.value);
                                                                    if (selectedEquipPosition !== undefined) {
                                                                        setVesselEquipment({ ...vesselEquipment, equipment_position_id: e.target.value })
                                                                    } else {
                                                                        setVesselEquipment({ ...vesselEquipment, equipment_position_id: null })
                                                                    }

                                                                }} value={vesselEquipment.equipment_position_id} >
                                                                    <option selected value="">
                                                                        Pilih Posisi...
                                                                    </option>
                                                                    {listEquipmentPosition.map((position, index) => (
                                                                        <option key={index} value={position.id}>{position.position}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                            <TextBox value={vesselEquipment.model} placeholder="Model"
                                                                caption="Model" setValueFunction={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, model: e.target.value });
                                                                }} />
                                                            <TextBox value={vesselEquipment.manufacture_name} placeholder="Nama Manufaktur"
                                                                caption="Nama manufaktur" setValueFunction={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, manufacture_name: e.target.value });
                                                                }} />
                                                            <TextBox value={vesselEquipment.description} asType="textarea" placeholder="Deskripsi"
                                                                caption="Deskripsi" setValueFunction={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, description: e.target.value });
                                                                }} />


                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            location.state.vesselEquipmentId !== 0 || parameter.length !== 0 ?
                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Parameter Detail"} childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>

                                                        {
                                                            parameter.length === 0 ?
                                                                <></>
                                                                :
                                                                <Table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Nama Parameter</th>
                                                                            <th>Nilai Parameter</th>
                                                                            <th>Deskripsi Parameter</th>
                                                                            <th>Hapus</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {
                                                                            parameter.map((x, i) => {
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            parameter.length === 0 ?
                                                                                                <></>
                                                                                                :

                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control>

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control name="parameter_value" value={x.parameter_value} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nilai Parameteer"></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control name="parameter_desc" value={x.parameter_desc} type="text" onChange={e => handleinputchange(e, i)} placeholder="Deskripsi Parameter"></Form.Control>
                                                                                                    </td>
                                                                                                    <td>

                                                                                                        {
                                                                                                            parameter.length >= 1 &&
                                                                                                            <Button variant="danger" onClick={() => {
                                                                                                                if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                                    handleremove(i)
                                                                                                                }
                                                                                                            }}><Trash /></Button>
                                                                                                        }

                                                                                                    </td>
                                                                                                </tr>
                                                                                        }


                                                                                    </>

                                                                                )


                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </Table>
                                                        }

                                                        {
                                                            location.state.vesselEquipmentId === 0 && parameter.length === 0 ?
                                                                <></>
                                                                :
                                                                <center><Button className="add-button" variant="primary" onClick={handleaddclick}>Tambah Parameter</Button></center>
                                                        }
                                                    </div>

                                                }>
                                                </ContainerBox>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            location.state.vesselEquipmentId !== 0 ?
                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Foto Peralatan Kapal"} useActionContainer={true}
                                                    actionContainerChild={
                                                        <div>
                                                            <Button variant="secondary" style={{

                                                            }} onClick={() => navigate("/VesselEquipment/Photo", { state: { vesselEquipmentId: location.state.vesselEquipmentId, vesselName: vesselEquipment.vesselName, equipmentName: vesselEquipment.equipmentName } })}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    flexDirection: "row",
                                                                    alignSelf: "center",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flex: 1,
                                                                    }}><CameraFill size={20} /></div>

                                                                </div>
                                                            </Button>
                                                        </div>
                                                    } childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>



                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                paddingBottom: 10,
                                                                // width: "100%"
                                                            }}>
                                                                {
                                                                    listPhoto.map((photo, index) => {
                                                                        return (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                flexDirection: "row",
                                                                                flexWrap: "nowrap",
                                                                                borderStyle: "solid",
                                                                                paddingRight: 10,
                                                                                BorderWidth: 1,
                                                                                justifyContent: "center",
                                                                                width: "100%",
                                                                                margin: 5,
                                                                                borderRadius: 10,
                                                                                borderColor: "#2f71bd"
                                                                            }}>



                                                                                <img src={photo.base64_img} onClick={() => {
                                                                                    handleClick(photo, index)
                                                                                }} style={{ maxWidth: "50%" }} />
                                                                                <div style={{ paddingRight: 10 }}></div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>



                                                            <center><Button className="add-button" onClick={() => {
                                                                showFormPhoto();
                                                                setvesselPhotoEquipment({
                                                                    base64_img: '',
                                                                    title: '',
                                                                    description: '',
                                                                    taken_date: ''

                                                                });
                                                                photoParameter();
                                                                setFileImg("");
                                                            }}>Tambah Foto</Button></center>
                                                            <br />
                                                            {
                                                                listPhoto.length === 0 ?
                                                                    <></>
                                                                    :
                                                                    <Paginations
                                                                        itemPerPage={itemPerPage}
                                                                        totalPage={totalPage}
                                                                        page={page}
                                                                        setPage={setPage}
                                                                        // setItemPerPage={setItemPerPage}
                                                                        itemPerPageSelection={itemPerPageSelection}
                                                                        orderBy={orderBy}
                                                                        setOrderBy={setOrderBy}
                                                                        orderBySelection={orderBySelection}
                                                                    />

                                                            }
                                                        </div>

                                                    }>
                                                </ContainerBox>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            location.state.vesselEquipmentId !== 0
                                                ?
                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Dokumen"}
                                                    useActionContainer={true}
                                                    actionContainerChild={
                                                        <div>
                                                            <Button variant="secondary" onClick={() => {
                                                                setShowFormDocument(true);
                                                            }} style={{

                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    flexDirection: "row",
                                                                    alignSelf: "center",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flex: 1,
                                                                    }}><FilePlusFill size={20} /></div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 8,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        paddingLeft: 5,
                                                                        fontWeight: "bold",
                                                                        fontSize: 12,
                                                                    }}>Tambah Dokumen</div>
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    }
                                                    childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>

                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">Nama Dokumen</div>
                                                                        <div style={{ flex: 3 }} className="table-header-content">Nomor Refensi</div>
                                                                        <div style={{ flex: 3 }} className="table-header-content">Tanggal Upload</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">Lihat Dokumen</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">Download</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                                                    </div>
                                                                    {
                                                                        listDocument.map((document, index) => {
                                                                            return (<div key={index} className="table-body">
                                                                                <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setDocumentToBeViewed(document);
                                                                                        }}><EyeFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            triggerBase64Download(document.document_base64, document.document_name);
                                                                                        }}><Download />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="danger" onClick={() => {
                                                                                            if (window.confirm(`Apakah Anda Yakin Menghapus  ${document.document_name}?`)) {
                                                                                                let documentId = document.id
                                                                                                setDocumentId(documentId);
                                                                                            }
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div></div>
                                                                            </div>)
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}></div>
                                                            </div>


                                                        </div>
                                                    }>
                                                </ContainerBox>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>

                            </div>

                            <ButtonForm addData={() => {
                                addVesselEquipment();
                                setDisabledButton(true);
                            }} path={location.state.back === "yes" ? "/Vessel/DetailEquipment" : "/vesselEquipment"} idVessel={vesselEquipment.vessel_id} button={disabledButton} />
                        </div>

                    }>


                </ContainerBox >

            </Container >
            <Loading
                loading={loading}
            />


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>Tambah Foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        addVesselEquipmentPhoto();
                    }}>

                        <Form.Group className="mb-3">
                            <Form.Label>Foto Peralatan Kapal</Form.Label>
                            <Form.Control onChange={async (e) => {
                                setFileImg(e.target.value)
                                let base64Img = await convertBase64(e.target.files[0]);
                                const base64ImgString = base64Img.toString();
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, base64_img: base64ImgString })


                            }} type="file" value={fileImg} required></Form.Control>
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Vessel Name</Form.Label>
                            <Form.Control onChange={(e) => {

                            }} value="{vesselPhoto.name}" type="text" placeholder="Enter Photo Name" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Equipment Name</Form.Label>
                            <Form.Control onChange={(e) => {

                            }} value="{vesselPhoto.name}" type="text" placeholder="Enter Photo Name" required></Form.Control>
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, title: e.target.value })
                            }} value={vesselPhotoEquipment.title} type="text" placeholder="Nama Foto" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Tanggal Pengambilan Gambar</Form.Label>
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, taken_date: e.target.value })
                            }} value={vesselPhotoEquipment.takenDate} type="date" placeholder="Tanggal Pengambilan Gambar" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Deskripsi</Form.Label>
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, description: e.target.value })
                            }} value={vesselPhotoEquipment.description} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, vessel_x_equipment_id: e.target.value })
                            }} value={vesselPhotoEquipment.vessel_x_equipment_id} type="text" placeholder="Enter description" hidden={true}></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, vessel_id: e.target.value })
                            }} value={vesselPhotoEquipment.vessel_id} type="text" placeholder="Enter description" hidden={true}></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, equipment_id: e.target.value })
                            }} value={vesselPhotoEquipment.equipment_id} type="text" placeholder="Enter description" hidden={true}></Form.Control>
                        </Form.Group>
                        <div className="buttonForm">
                            <Button className="save-button" variant="primary" type="submit">
                                Simpan
                            </Button>
                            <div style={{ paddingRight: 10 }}></div>
                            <Button className="cancel-button" variant="danger" onClick={() => {
                                handleResets();
                                handleClose();
                            }}>
                                Batal
                            </Button>
                        </div>

                    </Form>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            <Modal show={showFormDocument} >
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`Upload Dokumen`}
                    childContent={
                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            flex: 1,
                            padding: 10,
                        }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setNewDocument({ ...newDocument, done: true });
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>File Dokumen</Form.Label>
                                        <Form.Control onChange={async (e) => {
                                            setDocumentFile(e.target.value)
                                            let base64Doc = await convertBase64(e.target.files[0]);
                                            setNewDocument({ ...newDocument, document_base64: base64Doc.toString() });
                                        }} type="file" required></Form.Control>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>Nama Dokumen</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                borderWidth: 1,
                                                borderStyle: "solid",
                                            }} onChange={(e) => {
                                                setNewDocument({ ...newDocument, document_name: e.target.value })
                                            }} value={newDocument.document_name} type="text" placeholder="Nama Dokumen" required></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>Nomor Refensi</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                borderWidth: 1,
                                                borderStyle: "solid",
                                            }} onChange={(e) => {
                                                setNewDocument({ ...newDocument, reference_number: e.target.value })
                                            }} value={newDocument.reference_number} type="text" placeholder="Nomor referensi" required></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>Deskripsi</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control onChange={(e) => {
                                                setNewDocument({ ...newDocument, description: e.target.value })
                                            }} value={newDocument.description} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Button variant="primary" type="submit" disabled={disabledButton}>
                                        Simpan
                                    </Button>
                                    <Button className="cancel" variant="danger" onClick={() => {
                                        resetUploadForm();
                                        loadDocumentData();
                                        setShowFormDocument(false);
                                    }}>
                                        Batal
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    }
                />


            </Modal>

            <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name} childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed.document_base64}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                        Halaman {pageNumber} dari {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.8);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(1);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 2);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

                </Modal>

            {
                clickedImg && (
                    <Modals
                        clickedImg={clickedImg}
                        handelRotationRight={handelRotationRight}
                        setClickedImg={setClickedImg}
                        handelRotationLeft={handelRotationLeft}
                        image={listPhoto}
                        description={description}
                        setDescription={setDescription}
                    />
                )
            }




        </>
    );

}