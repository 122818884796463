import "./LampNavButton.css"
import { Button } from "react-bootstrap";

export default function LampNavButton({ text1, text2, handleOnClick, lamp }) {
    return (
        <>
            <Button style={{
                width: "160px",
            }} variant="outline-light" className="mainBtnLamp" onClick={() => handleOnClick()}>
                <p className="titleBtn">{text1} {text2}</p>
                <p className="titleBtn"></p>
                <div className={lamp === false ? "menuBitLampBlack" : "menuBitLampGreen"}></div>
            </Button>
        </>
    );
}