import "./index.css";
import "../../SMSApp.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCCoordinate, getPlcValue } from "../../Helpers/ApplicationHelpers";
import { Col, Row } from "react-bootstrap";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/NavBar";

export default function MSBPage() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listBitlamp, setListBitlamp] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (listBitlamp.length > 0) {
            const interval = setInterval(async () => {
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval)
        }

    }, [listBitlamp]);

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "msb", cookies.vesselId);
            let newList = response.filter(p => p.is_output === "0")
            setListBitlamp(newList);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {
            let coordinate = listBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = listBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setListBitlamp(updatedList);
            setLoading(false);

        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Main Switch Board'}/>
                    <BottomNavBar/>
                    <div style={{display: 'flex', justifyContent: 'space-around', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>GENERATOR 1</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '300px', height: '500px'}}>
                                <BitLamp text={'MCB ON Status'} bitlampColor = "black"/>
                                <BitLamp text={'MCB OFF Status'} bitlampColor = "black"/>
                                <BitLamp text={'Power Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Overcurrent Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Rev Power Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Voltage Relay'} bitlampColor = "black"/>
                                <BitLamp text={'AE 1 MCCB ON'} bitlampColor = "black"/>
                                <BitLamp text={'AE 1 MCCB OFF'} bitlampColor = "black"/>
                            </div>
                        </div>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>SYNCHRONIZER</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '300px', height: '500px', marginTop: '15px'}}>
                                <BitLamp text={'Synchronizer'} bitlampColor = "black"/>
                            </div>
                        </div>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>GENERATOR 2</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '300px', height: '500px'}}>
                                <BitLamp text={'MCB ON Status'} bitlampColor = "black"/>
                                <BitLamp text={'MCB OFF Status'} bitlampColor = "black"/>
                                <BitLamp text={'Power Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Overcurrent Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Rev Power Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Voltage Relay'} bitlampColor = "black"/>
                                <BitLamp text={'AE 2 MCCB ON'} bitlampColor = "black"/>
                                <BitLamp text={'AE 2 MCCB OFF'} bitlampColor = "black"/>
                            </div>
                        </div>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>SHORE CONNECTION</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '300px', height: '500px'}}>
                                <BitLamp text={'MCB ON Status'} bitlampColor = "black"/>
                                <BitLamp text={'MCB OFF Status'} bitlampColor = "black"/>
                                <BitLamp text={'Power Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Overcurrent Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Rev Power Relay'} bitlampColor = "black"/>
                                <BitLamp text={'Voltage Relay'} bitlampColor = "black"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}