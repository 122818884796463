// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoTopNav {
    margin-left: 15px;
    margin-right: 30px;
}

.logoTopNav img {
    width: 110px;
    height: 70px;
}

.paddingNavBar {
    padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/TopNavBar/TopNavBar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".logoTopNav {\n    margin-left: 15px;\n    margin-right: 30px;\n}\n\n.logoTopNav img {\n    width: 110px;\n    height: 70px;\n}\n\n.paddingNavBar {\n    padding: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
