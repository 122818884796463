import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselSparepartReturn, getVesselSparepartReturnCount, deleteVesselSparepartReturn,
        getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";

export default function VesselSparepartReturnPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listReturn, setListReturn] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Staf",
        value: "staff.staff_name"
    });
    const orderByList = [{
        label: "Nama Staf",
        value: "staff.staff_name"
    }
    ]

    const [descending, setDescending] = useState(false);
    const [removeId, setRemoveId] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVesselInventoryReturn();
                loadAccess();
                // setLoading(false);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadVesselInventoryReturn();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVesselInventoryReturn();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVesselInventoryReturn();
    }, [orderBy]);

    useEffect(() => {
        if(removeId !== "")
        deleteData();
    }, [removeId]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadVesselInventoryReturn();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVesselInventoryReturn();
    }, [descending]);



    const loadVesselInventoryReturn = async () => {
        try {
            let count = await getVesselSparepartReturnCount(cookies.token,  searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
       
            let response = await getVesselSparepartReturn(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListReturn(response);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const deleteData = async () => {
        try {
            let response = await deleteVesselSparepartReturn(cookies.token, removeId);
            if(response === 0){
                loadVesselInventoryReturn();
                setRemoveId("");
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true)
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Pengembalian Sparepart Kapal"
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/VesselSparepartReturn/Detail", { state: { returnId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cari ( Nama Staf)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                setPage(0);
                                                await loadVesselInventoryReturn();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadVesselInventoryReturn();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadVesselInventoryReturn();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Kapal</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Staf</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listReturn.map((inventoryReturn, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{inventoryReturn.vessel_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{inventoryReturn.staff_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button onClick={() => {
                                                        navigate("/VesselSparepartReturn/Detail", { state: { returnId: inventoryReturn.id } })
                                                    }}> <PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true : !deletedButton} className="cancel" onClick={() => {
                                                        if (window.confirm('Apakah Anda Ingin Menghapus Data Ini?')) {
                                                            setRemoveId(inventoryReturn.id);
                                                        }
                                                    }} variant="danger"><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>


        </>
    );

}