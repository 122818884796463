import SwitchToggle from "../SwitchToggle/SwitchToggle";
import "./BoxSwitchToggle.css";

export default function BoxSwitchToggle({ text }) {
    return (
        <>
            <div className="boxSwitchToggle">
                <p>{text}</p>
                <div><SwitchToggle /></div>
            </div>
        </>
    );
}
