// MyGoogleMapComponent.jsx

import React, { useState, useRef, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from "google-maps-react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GeoFill } from "react-bootstrap-icons";
import marker from "../Assets/Images/logo.png";

const GoogleMapContainer = ({ google, devices, vessel }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [vesselName, setVesselName] = useState();
  const degreeSymbol = "\u00b0";
  const navigate = useNavigate();
  const buttonRef = useRef();

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setSelectedDevice(props.device);
  };

  const onClose = () => {
    setActiveMarker(null);
    setSelectedDevice(null);
  };

  const handleButtonClick = (deviceId) => {
    console.log("tes");
    navigate("/loadCell", { state: { deviceId } });
  };

  const defaultCenter = {
    lat: -6.127283373056311,
    lng: 106.85529133081604,
  };
  
  useEffect(() => {
    // loadPolyLine();
    const button = document.getElementById("tes");

    const handleButtonClick = () => {
      console.log("Button clicked");
    };

    if (button) {
      button.addEventListener("click", handleButtonClick);
    }

    return () => {
      if (button) {
        button.removeEventListener("click", handleButtonClick);
      }
    };
  }, []);

  // useEffect(() => {
  //   const newCoordinates = ({
  //     lat: latitudeForPolyLine,
  //     lng: longitudeForPolyLine
  //   });
    
  //   setPolylineCoordinates(newCoordinates);
  // }, [longitudeForPolyLine, latitudeForPolyLine]);

  useEffect(() => {
    const newCoordinates = devices.map(device => ({
      lat: device.latitude,
      lng: device.longitude
    }));
    
    setPolylineCoordinates(newCoordinates);
  }, [devices]);

  useEffect(() => {
    setVesselName(vessel);
  }, [vessel]);

  return (
    <div
      style={{
        display: "flex",
        flex: 0,
        alignContent: "center",
        justifyContent: "center",
        
      }}
    >
      <Map
        containerStyle={{
          position: "relative",
          width: '100%',
          height: 500,
          x: 0,
          y: 0,
        }}
        google={google}
        zoom={14}
        style={{
          position: "absolute",
          width: 1000,
          height: 500,
        }}
        initialCenter={defaultCenter}
      >
        {devices.map((device) => (
          <Marker key={device.id}
            position={{ lat: device.latitude, lng: device.longitude }}
            title={device.device_name}
            onClick={onMarkerClick}
            device={device}>
            {/* <img style={{ width: '5%', height: '5%' }} src={marker} /> */}
          </Marker>
        ))
        }


        <InfoWindow
          marker={activeMarker}
          visible={activeMarker !== null}
          onClose={onClose}
          disableAutoPan={true}
          zIndex={1000}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: '150px',
              flexWrap: 'wrap'
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                borderBottomStyle: "solid",
              }}
            >
              <div style={{ display: "flex", paddingRight: 2 }}>
                <GeoFill size={25} />
              </div>
              <div style={{ display: "flex", paddingLeft: 2 }}>
                {/* <h5>{selectedDevice && selectedDevice.customer_name}</h5> */}
                <h5 style={{color: 'black'}}>{vesselName}</h5>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                paddingTop: 10,
              }}
            >
              {selectedDevice && (
                <table style={{ padding: 5 }}>
                  <tr>
                    <td style={{color: 'black', fontSize: '12px'}}>Last Seen: </td>
                  </tr>
                  <tr>
                    <td style={{color: 'black', fontSize: '12px'}}>{selectedDevice.timestamp_gps}</td>
                  </tr>
                </table>
              )}
            </div>
          </div>
        </InfoWindow>
        {polylineCoordinates.length > 1 && (
          <Polyline
            path={polylineCoordinates}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
          />
        )}
      </Map>
    </div>
  );
};


export default GoogleApiWrapper({
  apiKey: "AIzaSyAiJLI_QqRdHV6SHSkfe4etYhTpi5LsJ9Q", // Replace with your Google Maps API key
})(GoogleMapContainer);
