import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getProject, getSparepartCatalog, getStaff, insertUpdateSparepartRequisition, getSparepartRequisitionById,
    getRequisitionStatus, getSparepartCatalogStock, getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function SparepartRequisitionDetailPage() {
    const [projectOptions, setProjectOptions] = useState([]);
    const { t, i18n } = useTranslation();
    const [sparepartCatalogOptions, setSparepartCatalogOptions] = useState([]);
    const [listSparepartStock, setListSparepartStock] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [disabledAddButton, setDisableButtonAdd] = useState(false);
    const [staffOptions, setStaffOptions] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [approvalButton, setApprovalButton] = useState("");
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [actionButton, setActionButton] = useState("");
    const [staffId, setStaffId] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [selectedProjectType, setSelectedProjectType] = useState("");
    const [sparepartRequisition, setSparepartRequisition] = useState({
        id: 0,
        project_id: null,
        requesting_staff_id: null,
        request_date: null,
        status_id: null,
        approved_date: null,
        arrived_date: null,
        is_project: null,
        list_requisition: [],
        approved_by: null,
        ext_customer_id: cookies.extCustomerId
    })

    // useEffect(() => {
    //     if (selectedProjectType) {
    //         loadProject()
    //     }
    // }, [selectedProjectType]);




    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadProject();
                loadStaff();
                loadSparepartCatalog();
                loadAccess();
                loadStatus();
                setStaffId(cookies.staffId);


            }
        }
        checkCookies();
        if (location.state.sparepartRequisitionId === 0) {
            setLoading(false);
        } else {
            initSparepartRequisition();
        }
    }, []);

    useEffect(() => {
        if (sparepartRequisition.project_id === null || sparepartRequisition.requesting_staff_id === null) {
            setErrors({
                ...errors,
                message: `${t("detail_sparepart_requisition.error_empty_field")}`
            },
                setDisabledButton(true));
        }

        else if (moment(sparepartRequisition.approved_date).format("yyyy-MM-DD") < moment(sparepartRequisition.request_date).format("yyyy-MM-DD")) {
            setErrors({
                ...errors,
                dateMessageApproved: `${t("detail_sparepart_requisition.error_approved_date")}`
            },
                setDisabledButton(true))
        }

        else if (moment(sparepartRequisition.arrived_date).format("yyyy-MM-DD") < moment(sparepartRequisition.request_date).format("yyyy-MM-DD")) {
            setErrors({
                ...errors,
                dateMessageArrived: `${t("detail_sparepart_requisition.error_arrived_date")}`
            },
                setDisabledButton(true))
        }
        else if (sparepartRequisition.approved_date !== null && sparepartRequisition.approved_by === null) {
            setErrors({
                ...errors,
                givingStaffError: `${t("detail_sparepart_requisition.error_empty_field")}`
            },
                setDisabledButton(true))
        }
        else if (sparepartRequisition.list_requisition.length === 0) {
            setErrors({
                ...errors,
                listRequisitionError: `${t("detail_sparepart_requisition.error_list_sparepart")}`
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "message", "dateMessageApproved", "dateMessageArrived", "givingStaffError", "listRequisitionError");
            setErrors(errorMsg);
            validationForm();


        }



    }, [sparepartRequisition]);


    useEffect(() => {
        if (actionButton !== "")
            saveData();
        // console.log('tes')

    }, [actionButton])

    useEffect(() => {
        if (location.state.sparepartRequisitionId === 0) {
            let today = new Date();
            let date = moment(today).format("yyyy-MM-DD")
            setSparepartRequisition({ ...sparepartRequisition, request_date: date });
        }

    }, [listStatusRequisition])

    useEffect(() => {
        if (listSparepartStock.length > 0) {
            if (sparepartRequisition.list_requisition.length > 0) {
                for (let index = 0; index < sparepartRequisition.list_requisition.length; index++) {
                    let list = sparepartRequisition.list_requisition[index];
                    let stock = listSparepartStock.find(p => p.id === sparepartRequisition.list_requisition[index].sparepart_catalog_id);
                    if (stock) {
                        list.title = stock.title
                    } else {
                        list.title = ""
                    }
                }
            }
        }
    }, [listSparepartStock])

    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, cookies.extCustomerId);

            // if (selectedProjectType === "true") {
            //     projectList = response.filter(p => p.is_project === true);

            // } else {
            //     projectList = response.filter(p => p.is_project === false);

            // }
            let listProject = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].project_name,
                    id: response[i].id

                };

                listProject.push(obj);

            }
            setProjectOptions(listProject);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadSparepartCatalog = async () => {
        try {
            let response = await getSparepartCatalog(cookies.token, cookies.extCustomerId);
            setListSparepartStock(response);
            let listSparepartCatalog = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].manufacturer_name ? (response[i].manufacturer_name + ' - ' + response[i].model + `(${response[i].part_number !== null ? response[i].part_number : ""})`) : '- ' + response[i].model,
                    id: response[i].id
                };

                listSparepartCatalog.push(obj);

            }
            setSparepartCatalogOptions(listSparepartCatalog);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, true, undefined, undefined, 2);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id,
                    resign_date: response[i].resign_date

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let sparepartRequisitionTmp = sparepartRequisition;
            sparepartRequisitionTmp.ext_customer_id = sparepartRequisitionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : sparepartRequisition.ext_customer_id
            let response = await insertUpdateSparepartRequisition(cookies.token, sparepartRequisition);
            if (response.error_code === 0) {
                alert(`${t("detail_sparepart_requisition.alert_success_save_data")}`);
                navigate('/SparepartRequisition');

            } else {
                alert(`${t("detail_sparepart_requisition.alert_failed_save_data")}`);
            }

            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initSparepartRequisition = async () => {
        try {
            let response = await getSparepartRequisitionById(cookies.token, location.state.sparepartRequisitionId);
            if (response) {
                setSparepartRequisition({
                    ...sparepartRequisition,
                    id: response[0].id,
                    project_id: response[0].project_id,
                    requesting_staff_id: response[0].requesting_staff_id,
                    request_date: response[0].request_date,
                    status_id: response[0].status_id,
                    approved_date: response[0].approved_date,
                    arrived_date: response[0].arrived_date,
                    is_project: response[0].is_project,
                    list_requisition: response[0].list_requisition,
                    project_name: response[0].project_name,
                    staff_name: response[0].staff_name,
                    approved_by: response[0].approved_by,
                    approved_name: response[0].approved_name
                })
            }

            let status = await getRequisitionStatus(cookies.token);
            if (status) {
                let statusCode = status.find(p => p.id === response[0].status_id)
                if (statusCode.status_code === "APPROVED" || statusCode.status_code === "REJECTED") {
                    setDisabledForm(true);
                }
            }

            // setSelectedProjectType(`${response[0].is_project}`);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
            if (location.state.sparepartRequisitionId === 0) {
                let status = await response.find(p => p.status_code === "REQ")
                setSparepartRequisition({ ...sparepartRequisition, status_id: status.id })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (sparepartRequisition.list_requisition.length > 0) {
                    for (let index = 0; index < sparepartRequisition.list_requisition.length; index++) {
                        const list = sparepartRequisition.list_requisition[index];
                        if (list.sparepart_catalog_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage(`${t("detail_sparepart_requisition.error_list_sparepart")}`);
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");
                            setDisableButtonAdd(false);
                            setDisabledButton(false);
                        }
                    }
                } else {
                    setErrorMessage("")
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("detail_sparepart_requisition.print_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
                setApprovalButton(response.approval);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_sparepart_requisition.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/SparepartRequisition"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_sparepart_requisition.header_sparepart_requisition")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                                {/* <Form.Group className="mb-3">
                                                                <Form.Label>Jenis Proyek</Form.Label>
                                                                <Form.Select value={sparepartRequisition.is_project} onChange={(e)=>{
                                                                    setSparepartRequisition({...sparepartRequisition, is_project: e.target.value})
                                                                }}>
                                                                    <option value="">Pilih Project</option>
                                                                    <option value={true}>Project</option>
                                                                    <option value={false}>Non Project(Marketing)</option>
                                                                </Form.Select>
                                                            </Form.Group> */}
                                                                {/* <div style={{ paddingBottom: 5 }}>
                                                                    <div style={{ fontWeight: "bold", paddingBottom: 5 }}>Jenis Proyek</div>
                                                                    <Form.Select value={sparepartRequisition.is_project} onChange={(e) => {
                                                                        if (e.target.value === "") {
                                                                            setSparepartRequisition({ ...sparepartRequisition, is_project: null, project_id: null });
                                                                            setSelectedProjectType("");
                                                                            setProjectOptions([]);
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, is_project: e.target.value, project_id: null });
                                                                            setSelectedProjectType(e.target.value);
                                                                        }

                                                                    }} disabled={disabledForm}>
                                                                        <option value="">Pilih Proyek</option>
                                                                        <option value={true}>Proyek</option>
                                                                        <option value={false}>Non Proyek(Marketing)</option>
                                                                    </Form.Select>
                                                                </div> */}

                                                                <FormSelect caption={`${t("detail_sparepart_requisition.field_project_name")}`} autoFocusValue={sparepartRequisition.project_id === undefined} placeholder=""
                                                                    options={projectOptions} value={projectOptions.filter(function (option) {
                                                                        return option.id === sparepartRequisition.project_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartRequisition({ ...sparepartRequisition, project_id: e.id });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, project_id: null });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }


                                                                <FormSelect caption={`${t("detail_sparepart_requisition.field_request_staff")}`} autoFocusValue={sparepartRequisition.requesting_staff_id === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== sparepartRequisition.approved_by && p.resign_date === null)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartRequisition.requesting_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartRequisition({ ...sparepartRequisition, requesting_staff_id: e.id });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, requesting_staff_id: null });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }
                                                                <DropDownForm value={sparepartRequisition.status_id} caption={`${t("detail_sparepart_requisition.field_request_status")}`} placeholder=""
                                                                    setValueFunction={(e) => {
                                                                        // setSparepartRequisition({ ...sparepartRequisition, status_id: e.target.value });
                                                                        let status = listStatusRequisition.find(p => p.id === e.target.value);
                                                                        if (status.status_code === "APPROVED") {
                                                                            var today = new Date(),
                                                                                // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                                date = moment(today).format("yyyy-MM-DD")
                                                                            setSparepartRequisition({ ...sparepartRequisition, status_id: e.target.value, approved_date: date });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, status_id: e.target.value, approved_date: null, approved_by: null });

                                                                        }
                                                                    }} listOption={listStatusRequisition} valueKey="id" labelKey="status_name" disabledForm={true} />

                                                                <FormSelect caption={`${t("detail_sparepart_requisition.field_request_by")}`} autoFocusValue={sparepartRequisition.approved_by === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== sparepartRequisition.requesting_staff_id)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartRequisition.approved_by;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartRequisition({ ...sparepartRequisition, approved_by: e.id });
                                                                        } else {
                                                                            setSparepartRequisition({ ...sparepartRequisition, approved_by: null });
                                                                        }

                                                                    }} disabledForm={sparepartRequisition.approved_date ? disabledForm : true}

                                                                />
                                                                {
                                                                    errors.givingStaffError && <p style={{ color: "red" }}>{errors.givingStaffError}</p>
                                                                }
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>



                                                                <TextBox value={sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Request" controlType="date"
                                                                    caption={`${t("detail_sparepart_requisition.field_request_date")}`} setValueFunction={(e) => {
                                                                        setSparepartRequisition({ ...sparepartRequisition, request_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <TextBox value={sparepartRequisition.approved_date ? moment(sparepartRequisition.approved_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption={`${t("detail_sparepart_requisition.field_approved_date")}`} setValueFunction={(e) => {
                                                                        setSparepartRequisition({ ...sparepartRequisition, approved_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                                {
                                                                    errors.dateMessageApproved && <p style={{ color: "red" }}>{errors.dateMessageApproved}</p>
                                                                }
                                                                <TextBox value={sparepartRequisition.arrived_date ? moment(sparepartRequisition.arrived_date).format("yyyy-MM-DD") : ""} placeholder="" controlType="date"
                                                                    caption={`${t("detail_sparepart_requisition.field_item_arrived_date")}`} setValueFunction={(e) => {
                                                                        setSparepartRequisition({ ...sparepartRequisition, arrived_date: e.target.value });
                                                                    }} disabledForm={location.state.sparepartRequisitionId === 0 ? true : disabledForm} />
                                                                {
                                                                    errors.dateMessageArrived && <p style={{ color: "red" }}>{errors.dateMessageArrived}</p>
                                                                }

                                                            </div>

                                                        </div>
                                                        {
                                                            approvalButton && location.state.sparepartRequisitionId !== 0 &&

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                padding: 10,
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                fontWeight: "bold"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Button onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "APPROVED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setSparepartRequisition({ ...sparepartRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Approve");
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="success">{t("detail_sparepart_requisition.approved_button")}</Button>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Button onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "REJECTED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setSparepartRequisition({ ...sparepartRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Reject")
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="danger">{t("detail_sparepart_requisition.rejected_button")}</Button>
                                                                </div>


                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {
                                        location.state.sparepartRequisitionId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >{t("detail_sparepart_requisition.print_button")} <Printer /></Button>
                                            :
                                            <></>
                                    }
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_sparepart_requisition.header_sparepart_list")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>


                                                    {
                                                        sparepartRequisition.list_requisition && sparepartRequisition.list_requisition.map((list, index) => {
                                                            return (
                                                                <div style={{ padding: 5 }}>
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5,
                                                                        borderWidth: 2,
                                                                        borderRadius: 5,
                                                                        borderStyle: "solid",
                                                                        borderColor: "rgba(3, 30, 103, 1)"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 20,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Select placeholder={`${t("detail_sparepart_requisition.field_sparepart")}`}
                                                                                    options={sparepartCatalogOptions.filter((p) => {
                                                                                        return !sparepartRequisition.list_requisition.find((x) => {
                                                                                            return p.id === x.sparepart_catalog_id
                                                                                        })
                                                                                    })} value={sparepartCatalogOptions.filter(function (option) {
                                                                                        return option.id === list.sparepart_catalog_id;
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        let listRequisition = sparepartRequisition.list_requisition;
                                                                                        listRequisition[index].sparepart_catalog_id = e.id;
                                                                                        setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                                    }}
                                                                                    isDisabled={disabledForm}
                                                                                />

                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                // width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 6,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                    let listRequisition = sparepartRequisition.list_requisition;
                                                                                    listRequisition[index].quantity = formatCurrency(e.target.value);
                                                                                    setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                                }} type="text" style={{ textAlign: "right" }} value={list.quantity} required placeholder={`${t("detail_sparepart_requisition.field_total")}`} disabled={disabledForm} ></Form.Control>


                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 1,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Button onClick={() => {
                                                                                    if (window.confirm(`${t("detail_sparepart_requisition.delete_confirmation")}`)) {
                                                                                        let list = [...sparepartRequisition.list_requisition];
                                                                                        list.splice(index, 1);
                                                                                        setSparepartRequisition({ ...sparepartRequisition, list_requisition: list });
                                                                                    }

                                                                                }} style={{ maxWidth: 50 }} variant="danger" disabled={disabledForm === true ? disabledForm : !deletedButton}><Trash /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            paddingTop: 10,
                                                                            paddingBottom: 10

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                    let listRequisition = sparepartRequisition.list_requisition;
                                                                                    listRequisition[index].note = e.target.value;
                                                                                    setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                                }} as="textarea" rows={3} value={list.note} placeholder={`${t("detail_sparepart_requisition.field_description")}`} disabled={disabledForm}></Form.Control>


                                                                            </div>

                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            );
                                                        })
                                                    }

                                                    {
                                                        errors.listRequisitionError && <p style={{ color: "red" }}>{errors.listRequisitionError}</p>
                                                    }
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                sparepart_catalog_id: "",
                                                                quantity: null,
                                                                quantity: "",
                                                                note: ""
                                                            };
                                                            if (!sparepartRequisition.list_requisition) {
                                                                let listRequisition = [];
                                                                listRequisition.push(newParameter);
                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                            } else {
                                                                let listRequisition = sparepartRequisition.list_requisition;
                                                                listRequisition.push(newParameter);
                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                            }

                                                        }} disabled={disabledForm === false ? disabledAddButton : disabledForm}>{t("detail_sparepart_requisition.button_add_sparepart")}</Button>
                                                    </div>
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button style={{width:100}} className="save-button" disabled={disabledForm === false ? disabledButton : !updatedButton} type="submit">{t("detail_sparepart_requisition.save_button")}</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button style={{width:100}} className="cancel-button" onClick={() => {
                                                        navigate("/SparepartRequisition")
                                                    }} variant="danger">{t("detail_sparepart_requisition.cancel_button")}</Button>
                                                </div>


                                            </div>
                                    }



                                </Form>



                            </div>


                        </div>

                    }>
                </ContainerBox>
                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Radian Peninsula Indoneia</p>
                        {/* <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">No.Dok : {returns.numberForm}</p> */}
                    </div>
                    {/* <div style={{
                        marginLeft: 300,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >Form Permintaan Suku Cadang </h3>

                    </div> */}

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%" }}>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_requisition.field_project_name")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartRequisition.project_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_requisition.field_request_staff")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartRequisition.staff_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_requisition.field_request_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartRequisition.approved_name}</td>
                            </tr>
                        </Table>
                        {/* <p style={{ margin: '0' }} className="text-danger">Nama Proyek :  {sparepartRequisition.project_name}</p>
                        <p style={{ margin: '0' }} className="text-danger">Nama Staff   :  {sparepartRequisition.staff_name}</p> */}
                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <Table className="tableClass" >
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_requisition.field_request_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_requisition.field_approved_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartRequisition.approved_date ? moment(sparepartRequisition.approved_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>

                        </Table>
                        {/* <p style={{ margin: '0' }} className="text-danger">Tanggal Pengajuan :  {sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("DD-MMM-yyyy") : ""}</p> */}
                        {/* <p style={{ margin: '0' }} className="text-danger">NO&nbsp;&nbsp;&nbsp;:  {returns.form_number}</p> */}
                    </div>
                    <div style={{ paddingBottom: 20 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_sparepart_requisition.table_name1")} </td>
                            <td>{t("detail_sparepart_requisition.table_name2")} </td>
                            <td>{t("detail_sparepart_requisition.table_name3")} </td>
                            <td>{t("detail_sparepart_requisition.table_name4")} </td>
                            <td>{t("detail_sparepart_requisition.table_name5")} </td>
                        </tr>

                        {sparepartRequisition.list_requisition && sparepartRequisition.list_requisition.map((listRequisition, index) => {
                            return (
                                <tr key={index} className="table-caption-body">
                                    <td>{index + 1}</td>
                                    <td>{listRequisition.part_number}</td>
                                    <td>{listRequisition.manufacturer_name}</td>
                                    <td>{listRequisition.model}</td>
                                    <td>{listRequisition.quantity}</td>
                                    <td>{listRequisition.note}</td>
                                    {/* <td>{listReturn.return_inventory_description}</td> */}
                                </tr>
                            )
                        })
                        }


                    </table>
                    <div style={{ float: 'left', width: '70%', marginTop: '30px' }}>
                        <span >{t("detail_sparepart_requisition.field_request_staff")} </span>
                        <p style={{ marginTop: '100px' }}>{sparepartRequisition.staff_name}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '30px' }}>
                        <span >{t("detail_sparepart_requisition.field_request_by")} </span>
                        <p style={{ marginTop: '100px' }}>{sparepartRequisition.approved_name ? sparepartRequisition.approved_name : ""}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>

                </div>
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    );

}