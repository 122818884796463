import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, getStaffSpecializationById, insertUpdateStaffSpecialization, getLanguage,
    getSubStaffSpecialization, getSubStaffSpecializationById, deleteSubStaffSpecialization, insertUpdateSubStaffSpecialization,
    getUserAccessById, getCountSubStaffSpecialization
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { FilePlusFill, Search, ArrowClockwise, PencilFill, Trash } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function StaffSpecializationDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const location = useLocation();
    const [disabledButtonModal, setDisabledButtonModal] = useState(false);
    const [isSearched, setIsSearched] = useState(0);
    const [subStaffSpecializationId, setSubStaffSpecializationId] = useState("");
    const [subSpecializationModal, setSubSpecializationModal] = useState(false);
    const [specializationId, setSpecializationId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [listSubStaffSpecialization, setListSubStaffSpecialization] = useState([]);
    const [removeId, setRemoveId] = useState("");
    const [staffSpecialization, setStaffSpecialization] = useState({
        id: 0,
        specializationName: "",
        specializationDesc: "",
        languageId: ""
    })

    const [subStaffSpecialization, setSubStaffSpecialization] = useState({
        id: 0,
        specialization_id: "",
        sub_specialization_name: "",
        description: "",
    });

    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([2, 10, 15, 20, 50]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_specialization_technician.order_name1")}`,
        value: "sub_specialization_name"
    });
    const orderByList = [{
        label: `${t("detail_specialization_technician.order_name1")}`,
        value: "sub_specialization_name"
    }];
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setSpecializationId(location.state.staffSpecializationId);
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (specializationId !== 0 && specializationId !== "") {
            initStaffSpecialization();
            loadSubStaffSpecialization();
        }
    }, [specializationId]);


    useEffect(() => {
        if (subStaffSpecializationId !== "")
            initSubStaffSpecialization();
    }, [subStaffSpecializationId]);

    useEffect(() => {
        if (removeId !== "")
            removeData();
    }, [removeId])

    useEffect(() => {
        if (specializationId !== 0 && specializationId !== "")
            loadSubStaffSpecialization();
    }, [page]);

    useEffect(() => {
        if (specializationId !== 0 && specializationId !== "" && isSearched !== 0)
            loadSubStaffSpecialization();
    }, [itemPerPage]);

    useEffect(() => {
        if (specializationId !== 0 && specializationId !== "" && isSearched !== 0)
            loadSubStaffSpecialization();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0) {
            loadSubStaffSpecialization();
        }
    }, [resetSearch])

    useEffect(() => {
        if (specializationId !== 0 && specializationId !== "" && isSearched !== 0)
            loadSubStaffSpecialization()
    }, [descending]);


    const initStaffSpecialization = async () => {
        try {
            let response = await getStaffSpecializationById(cookies.token, specializationId);
            if (response) {
                setStaffSpecialization({
                    ...staffSpecialization,
                    id: response.id,
                    specializationName: response.specialization_name,
                    specializationDesc: response.specialization_desc,
                    languageId: response.language_id
                })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const addStaffSpecialization = async () => {
        try {
            let response = await insertUpdateStaffSpecialization(cookies.token, staffSpecialization, cookies.languageId);
            if (response.error_code === 0) {
                alert(`${t("detail_specialization_technician.alert_success_save_data")}`);
                if (specializationId === 0) {
                    setSpecializationId(response.data.id)
                } else {
                    initStaffSpecialization();
                }
            } else {
                alert(`${t("detail_specialization_technician.alert_failed_save_data")}`);
            }
            setLoading(false);
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadSubStaffSpecialization = async () => {
        try {
            let count = await getCountSubStaffSpecialization(cookies.token, specializationId, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getSubStaffSpecialization(cookies.token, specializationId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListSubStaffSpecialization(response);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initSubStaffSpecialization = async () => {
        try {
            let response = await getSubStaffSpecializationById(cookies.token, subStaffSpecializationId);
            if (response) {
                setSubStaffSpecialization({
                    ...subStaffSpecialization,
                    id: response.id,
                    specialization_id: response.specialization_id,
                    sub_specialization_name: response.sub_specialization_name,
                    description: response.description
                })
                setSubSpecializationModal(true);
                setSubStaffSpecializationId("");
                setLoading(false);
            } else {
                alert(`${t("detail_specialization_technician.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const removeData = async () => {
        try {
            let response = await deleteSubStaffSpecialization(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("detail_specialization_technician.delete_success_alert")}`);
                loadSubStaffSpecialization();
                setRemoveId("");
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveSubSpecializationData = async () => {
        try {
            // let responseGroup = await insertUpdateMechanicGroup(cookies.token, mechanicGroup, location.state.mechanicGroupId);

            // if (responseGroup.error_code === 0) {
            //     setMechanicGroupId(responseGroup.data.id);
            //     // loadStaff();
            // }
            let response = await insertUpdateSubStaffSpecialization(cookies.token, subStaffSpecialization);
            if (response.error_code === 0) {
                alert(`${t("detail_specialization_technician.alert_success_save_data")}`);
                setSubSpecializationModal(false);
                loadSubStaffSpecialization();
            } else {
                alert(`${t("detail_specialization_technician.alert_failed_save_data")}`)
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_specialization_technician.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/StaffSpecialization"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_specialization_technician.container_specialization_title")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>

                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            setDisabledButton(true)
                                                            addStaffSpecialization()
                                                        }}>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_specialization_technician.field_specialization_name")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setStaffSpecialization({ ...staffSpecialization, specializationName: e.target.value });
                                                                }} type="text" value={staffSpecialization.specializationName} required></Form.Control>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_specialization_technician.field_specialization_description")} </Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setStaffSpecialization({ ...staffSpecialization, specializationDesc: e.target.value });
                                                                }} as="textarea" rows={3} value={staffSpecialization.specializationDesc}  ></Form.Control>
                                                            </Form.Group>
                                                            {
                                                                (cookies.logingAs === "User" || cookies.extCustomerId === "null") &&
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexWrap: "nowrap",
                                                                    flexDirection: "row",
                                                                    justifyContent: "center",
                                                                    padding: 5
                                                                }}>

                                                                    <Button style={{width:100}} type="submit" variant="primary" disabled={disabledButton === true ? true : !updatedButton}>{t("detail_specialization_technician.save_button")}</Button>

                                                                    <Button style={{width:100}} onClick={() => {
                                                                        navigate("/StaffSpecialization")
                                                                    }} className="cancel" type="reset" variant="danger" >{t("detail_specialization_technician.cancel_button")}</Button>

                                                                </div>

                                                            }


                                                        </Form>



                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            specializationId !== 0 &&
                                            <ContainerBox containerPos="inner" titleCaption={"Sub Spesialisasi"}
                                                useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                actionContainerChild={
                                                    <div>
                                                        <Button disabled={!updatedButton} variant="secondary" style={{

                                                        }} onClick={() => {
                                                            setSubStaffSpecialization({
                                                                ...subStaffSpecialization,
                                                                id: 0,
                                                                specialization_id: specializationId,
                                                                sub_specialization_name: "",
                                                                description: ""
                                                            })
                                                            setSubSpecializationModal(true);
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><FilePlusFill size={32} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 10,
                                                                    fontWeight: "bold",
                                                                    fontSize: 18,
                                                                }}>{t("detail_specialization_technician.add_button")}</div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                }
                                                childContent={
                                                    <div className="master-table-inner-container">
                                                        <div className="master-table-searchbar-container">
                                                            <div className="master-table-searchbar-textbox">
                                                                <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_specialization_technician.search_box")}`}
                                                                    onKeyPress={async (e) => {
                                                                        if (e.charCode === 13) {
                                                                            setPage(0);
                                                                            setLoading(true);
                                                                            await loadSubStaffSpecialization();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setSearchQuery(e.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="master-table-searchbar-button">
                                                                <div style={{ paddingRight: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setPage(0);
                                                                        setLoading(true);
                                                                        await loadSubStaffSpecialization();
                                                                    }}><Search /></Button>
                                                                </div>
                                                                <div style={{ paddingLeft: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setLoading(true);
                                                                        setSearchQuery("");
                                                                        setResetSearch(1);
                                                                    }}><ArrowClockwise /></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-container">
                                                            <div className="table-header">
                                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_specialization_technician.table_name1")}</div>
                                                                <div style={{ flex: 2 }} className="table-header-content">{t("detail_specialization_technician.table_name2")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_specialization_technician.table_name3")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_specialization_technician.table_name4")}</div>
                                                            </div>

                                                            {
                                                                listSubStaffSpecialization.map((subSpecialization, index) => {
                                                                    return (<div key={index} className="table-body">
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{subSpecialization.sub_specialization_name}</p></div>
                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{subSpecialization.description}</p></div>
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={!updatedButton} variant="primary" onClick={() => {
                                                                                    setSubStaffSpecializationId(subSpecialization.id);
                                                                                }}><PencilFill /></Button>
                                                                            </div></div>

                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                                                    onClick={() => {
                                                                                        if (window.confirm(`${t("detail_specialization_technician.delete_confirmation")}  ${subSpecialization.sub_specialization_name}?`))
                                                                                            setRemoveId(subSpecialization.id);
                                                                                    }}
                                                                                ><Trash /></Button>
                                                                            </div></div>
                                                                    </div>)
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                        <div style={{
                                                            paddingTop: 20,
                                                        }}>

                                                        </div>
                                                        <Paginations
                                                            itemPerPage={itemPerPage}
                                                            totalPage={totalPage}
                                                            page={page}
                                                            setPage={setPage}
                                                            setItemPerPage={setItemPerPage}
                                                            itemPerPageSelection={itemPerPageSelection}
                                                            orderBy={orderBy}
                                                            setOrderBy={setOrderBy}
                                                            orderBySelection={orderByList}
                                                            isDescActive={descending}
                                                            setIsDescActive={setDescending}
                                                            setIsSearched={setIsSearched}
                                                        />
                                                        <Loading
                                                            loading={loading}
                                                        />
                                                    </div>
                                                } />
                                        }


                                    </div>
                                </div>


                            </div>


                        </div>

                    }>


                </ContainerBox>
                <Modal show={subSpecializationModal}
                    size={"lg"}
                    onHide={() => {
                        setSubSpecializationModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("detail_specialization_technician.container_add_sub_specialization")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButtonModal(true);
                            saveSubSpecializationData();
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_specialization_technician.field_sub_specialization_name")}</Form.Label>
                                <Form.Control required onChange={(e) => {
                                    setSubStaffSpecialization({ ...subStaffSpecialization, sub_specialization_name: e.target.value })
                                }} value={subStaffSpecialization.sub_specialization_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_specialization_technician.field_description")}</Form.Label>
                                <Form.Control as={"textarea"} rows={3} onChange={(e) => {
                                    setSubStaffSpecialization({ ...subStaffSpecialization, description: e.target.value })
                                }} value={subStaffSpecialization.description}></Form.Control>
                            </Form.Group>

                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                (cookies.logingAs === "User" || cookies.extCustomerId === "null") &&
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center"
                                }}>
                                    <Button style={{width:100}} type="submit" disabled={disabledButtonModal === true ? disabledButtonModal : !updatedButton}>{t("detail_specialization_technician.save_button")}</Button>
                                    <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                        // clearInventoryTypeModal();
                                        setSubSpecializationModal(false);
                                    }}>{t("detail_specialization_technician.cancel_button")}</Button>
                                </div>
                            }


                        </Form>



                    </div>

                </Modal>
            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}