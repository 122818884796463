import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, getCountInventoryType } from "../../../Helpers/ApplicationHelpers";
import { getInventoryMaterial, getInventoryMaterialCount, deleteInventoryMaterial, getInventoryTypeById, insertUpdateInventoryMaterial, checkMaterialCodeExists } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import PaginationsInventory from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function InventoryMaterialPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [listInventoryMaterial, setListInventoryMaterial] = useState([]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageInventory, setPageInventory] = useState(0);
    const [itemPerPageInventory, setItemPerPageInventory] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [totalPageInventory, setTotalPageInventory] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInventory, setSeacrhInventory] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [listSearchInventory, setListSearchInventory] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_type.order_name1")}`,
        value: "inventory_material_name"
    });
    const orderByList = [{
        label: `${t("index_inventory_type.order_name1")}`,
        value: "inventory_material_code"
    }];
    const [inventoryMaterialCount, setInventoryMaterialCount] = useState(0);
    const [inventoryMaterialDetail, setInventoryMaterialDetail] = useState({
        id: 0,
        inventory_material_name: "",
        inventory_material_code: "",
        inventory_material_desc: ""
    });
    const [descending, setDescending] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState("");

    const [inventoryType, setInventoryType] = useState({
        id: 0,
        inventory_type_name: "",
        inventory_type_description: "",
        language_id: cookies.languageId,
        type: []
    });
    const [isCodeExists, setIsCodeExists] = useState(true);
    const [existingCodeMaterialName, setExistingCodeMaterialName] = useState("");
    const [isInventoryMaterialNameValid, setIsInventoryMaterialNameValid] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryMaterial();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setLoading(true);
        console.log(page);
        loadInventoryMaterial();
    }, [page]);

    useEffect(() => {
        if (listInventoryMaterial) {
        }
    }, [listInventoryMaterial])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryMaterial();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryMaterial();
        }
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadInventoryMaterial();
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryMaterial();
        }
    }, [descending]);

    useEffect(() => {
        async function checkCodeExists(){
            if(inventoryMaterialDetail.inventory_material_code.length >= 2){
                let checkRes = await checkMaterialCodeExists(cookies.token, inventoryMaterialDetail.inventory_material_code);
                if(checkRes){
                    setIsCodeExists(true);
                    setExistingCodeMaterialName(checkRes);
                }
                else{
                    setIsCodeExists(false);
                    setExistingCodeMaterialName("");
                }
            }

            if(inventoryMaterialDetail.inventory_material_name.length >= 2){
                setIsInventoryMaterialNameValid(true);
            } else{
                setIsInventoryMaterialNameValid(false);
            }
        }

        checkCodeExists();
        
    }, [inventoryMaterialDetail]);

    /* useEffect(() => {
        setListSearchInventory(inventoryType.type.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
        let count = inventoryType.type.length / itemPerPageInventory;
        setTotalPageInventory(count);
    }, [inventoryType])
 */

   


    const initInventoryMaterial = async (inventoryTypeId) => {
        try {
            let response = await getInventoryTypeById(cookies.token, inventoryTypeId, cookies.languageId);
            if (response) {
                setInventoryType({
                    id: response.id,
                    inventory_type_name: response.inventory_type_name,
                    inventory_type_description: response.inventory_type_description,
                    language_id: response.language_id,
                    type: response.type
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }



    const clearInventoryTypeModal = async () => {
        inventoryType.id = 0;
        inventoryType.inventory_type_name = "";
        inventoryType.inventory_type_description = "";
        inventoryType.type = [];
        setSeacrhInventory("");
        setPageInventory(0);
    }


    const saveInventoryMaterial = async () => {
        let response = await insertUpdateInventoryMaterial(cookies.token, inventoryMaterialDetail);
        if (response === 0) {
            alert(t("index_inventory_material.save_success_alert"));
            loadInventoryMaterial();
        }
        setShowDetailModal(false);
    }

    const loadInventoryMaterial = async () => {
        try {
            let inventoryMaterialCount = await getInventoryMaterialCount(cookies.token, searchQuery, false);
            setInventoryMaterialCount(inventoryMaterialCount);
            console.log(inventoryMaterialCount);
            let totalPage = inventoryMaterialCount / itemPerPage;
            setTotalPage(totalPage);

            let listInventoryMaterial = await getInventoryMaterial(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
           
            setListInventoryMaterial(listInventoryMaterial);
            setLoading(false);
            setResetSearch(0)
            setIsSearched(0)
        }
        catch (exception) {
            console.log(exception)
        }
    }




    const deleteMaterial = async (id) => {
        try {
            console.log(id);
            if (window.confirm(`${t("index_inventory_material.delete_confirmation")}`) === true) {
                let result = await deleteInventoryMaterial(cookies.token, id);
                console.log(result);
                if (result.success) {
                    if (result.error_code === 0) {
                        alert(`${t("index_inventory_material.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_inventory_material.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_inventory_material.delete_failed_alert")}`);
                }
                
            }
            loadInventoryMaterial();

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_type.order_name1")}`,
                value: "inventory_type_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_inventory_type.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={false} variant="secondary" style={{

                            }} onClick={() => {
                                setInventoryMaterialDetail({
                                    id: 0,
                                    inventory_material_name: "",
                                    inventory_material_code: "",
                                    inventory_material_desc: ""
                                });
                                setExistingCodeMaterialName("");
                                setIsCodeExists(false);
                                setShowDetailModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_type.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_inventory_type.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                setLoading(true);
                                                await loadInventoryMaterial();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0)
                                            setLoading(true);
                                            await loadInventoryMaterial();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadInventoryType();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_material.table_header_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_material.table_header_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_material.table_header_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_material.table_header_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_material.table_header_name5")}</div>
                                </div>
                                {
                                    listInventoryMaterial.map((material, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{material.inventory_material_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{material.inventory_material_code}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{material.inventory_material_desc}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setInventoryMaterialDetail({
                                                            id: material.id,
                                                            inventory_material_name: material.inventory_material_name,
                                                            inventory_material_code: material.inventory_material_code,
                                                            inventory_material_desc: material.inventory_material_desc
                                                        });
                                                        setShowDetailModal(true);
                                                    }}><PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                        onClick={event => {
                                                            deleteMaterial(material.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>
            <Modal show={showDetailModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setShowDetailModal(false);

                }}>
                <div className="details m-2" >
                    <div className="detailscontent">

                        {inventoryMaterialDetail.id == 0 ?
                            <h3>{t("index_inventory_material.add_material_title")}</h3> :
                            <h3>{t("index_inventory_material.edit_material_title")}</h3>
                        }
                    </div>

                    <Form onSubmit={async (e) => {
                        e.preventDefault();
                        console.log(inventoryMaterialDetail);
                        await saveInventoryMaterial();
                    }} style={{ padding: 10 }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_material.table_header_name1")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryMaterialDetail({ ...inventoryMaterialDetail, inventory_material_name: e.target.value })
                            }} type="text" value={inventoryMaterialDetail.inventory_material_name} required placeholder={t("index_inventory_material.placeholder_input_material_name")}></Form.Control>
                            {!isInventoryMaterialNameValid && <p style={{ color: "red" }}>{t("index_inventory_material.message_material_name_error")}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_material.table_header_name2")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryMaterialDetail({ ...inventoryMaterialDetail, inventory_material_code: e.target.value })
                            }} type="text" value={inventoryMaterialDetail.inventory_material_code} required placeholder={t("index_inventory_material.placeholder_input_material_code")}></Form.Control>
                            {isCodeExists && <p style={{ color: "red" }}>{t("index_inventory_material.message_code_exists", {material_name: existingCodeMaterialName})}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_material.table_header_name3")}</Form.Label>
                            <textarea
                                className="form-control"
                                value={inventoryMaterialDetail.inventory_material_desc}
                                placeholder={t("index_inventory_material.placeholder_input_material_desc")}
                                onChange={(e) => {
                                    setInventoryMaterialDetail({ ...inventoryMaterialDetail, inventory_material_desc: e.target.value })
                                }}
                                rows="4"
                            />
                        </Form.Group>

                        <div style={{ paddingBottom: 10 }}></div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <Button disabled={isCodeExists || !isInventoryMaterialNameValid} type="submit">{t(inventoryMaterialDetail.id === 0 ? "index_inventory_material.button_add_detail" : "index_inventory_material.button_edit_detail")}</Button>
                            <Button className="cancel" variant="danger" onClick={() => {
                                setShowDetailModal(false);
                            }}>{t("index_inventory_material.button_cancel")}</Button>
                        </div>

                    </Form>
                </div>
            </Modal>


        </>
    )
}   