import "../../../SMSApp.css";
import "./index.css";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import WidthButton from "../../../Components/WidthButton/WidthButton";
import BitLamp2 from "../../../Components/BitLamp2/BitLamp2";
import { useNavigate } from "react-router-dom";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { Col, Row } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import { getPLCCoordinate, isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import { useState, useEffect } from "react";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import { Button } from "react-bootstrap";


export default function MainDeck(props) {
    const { smsSerialNumber } = props;
    const navigate = useNavigate();
    const navigateWheelHouse = () => { navigate("/FireAlarm/WheelHouse") };
    const navigateBelowMainDeck = () => { navigate("/FireAlarm/BelowMainDeck") };
    const [cookies, setCookie] = useCookies(["token"]);
    const [mainDeckBitlamp, setMainDeckBitlamp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idCoodinateGroup, setIdCoodinateGroup] = useState(8);
    const [coordinateType, setCoordinateType] = useState('M');
    const [bitlampList, setBitlampList] = useState({
        M52:
        {
            text: "Deck Store",
            alarm: true,
            coordinate: "M52",
            value: false
        }, 
        M40: {
            text: "Saloon",
            alarm: true,
            coordinate: "M40",
            value: false
        },
        M38: {
            text: "Laundry",
            alarm: true,
            coordinate: "M38",
            value: false
        }, 
        M268: {
            text: "Prov Store",
            alarm: true,
            coordinate: "M268",
            value: false
        }, 
        M270: {
            text: "CO2 Room",
            alarm: true,
            coordinate: "M270",
            value: false
        },
        M272: {
            text: "Steering Gear Room P",
            alarm: true,
            coordinate: "M272",
            value: false
        },
        M274: {
            text: "Steering Gear Room SB",
            alarm: true,
            coordinate: "M274",
            value: false
        },
        M276: {
            text: "Emergency Hatch Crew Room",
            alarm: true,
            coordinate: "M276",
            value: false
        },
        M278: {
            text: "Emergency Hatch E. Room",
            alarm: true,
            coordinate: "M278",
            value: false
        }
    })
    // const [mainDeckBitlamp, setMainDeckBitlamp] = useState([
    //     {text: "Deck Store", alarm: false, coordinate:"M50"},
    //     {text: "Saloon", alarm: true, coordinate:"M42"},
    //     {text: "Laundry", alarm: false, coordinate:"M48"},
    //     {text: "C/E", alarm: false, coordinate:"M38"},
    //     {text: "Captain", alarm: true, coordinate:"M36"},
    //     {text: "Manual Call Point Dining Room", alarm: true, coordinate:"M54"},
    //     {text: "Prev Store", alarm: true, coordinate:"M44"},
    //     {text: "Galley Heat", alarm: false, coordinate:"M46"},
    //     {text: "Manual Call Point Galley", alarm: true, coordinate:"M56"},
    // ])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoodinateGroup, coordinateType)
            
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "fire_alarm", cookies.vesselId);
            let newLisst = response.filter(p => p.sub_coordinate_group === "main_deck")
            setMainDeckBitlamp(newLisst);
           
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }
    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Fire Alarm - Main Deck'}/>
                    <BottomNavBar/>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm")}}>Events Alarm</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm/Logs")}}>Alarm Logs</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/MainDeck")}}>Main Deck</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/BelowMainDeck")}}>Below Main Deck</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/WheelHouse")}}>Wheel House</Button>
                    <div className="containerImgMD">
                        <div className="imgMainDeck">
                            
                            {/* <div className="lampInImg1">
                                <BitLamp2 text={"DINING ROOM"} alarm={false} gapText={10} />
                            </div>
                            <div className="lampInImg2">
                                <BitLamp2 text={"SALOON"} alarm={true} gapText={10} />
                            </div>
                            <div className="lampInImg3">
                                <BitLamp2 text={"CAPTAIN"} alarm={true} gapText={0} />
                            </div>
                            <div className="lampInImg4">
                                <BitLamp2 text={"GALLEY"} alarm={true} gapText={0} />
                            </div>
                            <div className="lampInImg5">
                                <BitLamp2 text={"C/ENG"} alarm={true} gapText={0} />
                            </div> */}
                        </div>
                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '400px', height: '500px', padding: '5%'}}>
                                <BitLamp text={'Deck Store'} bitlampColor = "black"/>
                                <BitLamp text={'Saloon'} bitlampColor = "black"/>
                                <BitLamp text={'Laundry'} bitlampColor = "black"/>
                                <BitLamp text={'Prov Store'} bitlampColor = "black"/>
                                <BitLamp text={'Manual Call Point Dining Room'} bitlampColor = "black"/>
                                <BitLamp text={'Galley Heat'} bitlampColor = "black"/>
                                <BitLamp text={'C/E'} bitlampColor = "black"/>
                                <BitLamp text={'Captain'} bitlampColor = "black"/>
                                <BitLamp text={'Manual Call Point Galley'} bitlampColor = "black"/>
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </>
    );
}