import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clock from "react-live-clock";
import "./TitleAlarmBar.css"
import { Accordion, Button } from "react-bootstrap";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useNavigate } from "react-router-dom";

export default function TitleAlarmBar(props) {
    const { text, smsSerialNumber } = props;
    const navigate = useNavigate();
    return (
        <div className="titleAlarmBar">

            <div className="titleTextBar">
                <div className="contentTitleTextBar">
                    <h5>{props.text}</h5>
                    <h5>{props.smsSerialNumber}</h5>
                </div>
            </div>

            <div style={{flex: 2}}></div>

            <div style={{display:'flex', flex: 2, justifyContent: "space-between"}}>
                <div className="alarmBar">
                    <div className="contentAlarmBar">
                        <FontAwesomeIcon icon={"fa-bell"} fontSize={20} />
                        <Clock format={"DD/MM/YYYY"} ticking={true} timezone={"Asia/Jakarta"} className="date1" />
                        <Clock format={"hh:mm:ss"} ticking={true} timezone={"Asia/Jakarta"} className="date2" />
                        <div>Event 0</div>
                    </div>
                </div>

                <div className="historyBar">
                    <div className="contentHistoryBar">
                        <FontAwesomeIcon icon={"fa-history"} fontSize={20} onClick={() => { 
                            navigate('/DataHistoryChart', {state: {smsSerialNumber: props.smsSerialNumber}});
                            // console.log(text, smsSerialNumber)
                        }}/>
                    </div>
                </div>
                
            </div>

        </div>
    )
}