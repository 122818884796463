import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
// import { isTokenValid, findSmsVesselPosition } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import { Form, Button } from "react-bootstrap";
// import DynamicLineChart from "../../../Components/DynamicLineChart";
import DropDownForm from "../../../Components/DropDownForm";
import moment from "moment";

export default function HistoryChartHandler(props) {
    
    return(
        <>
            <div>{props.childContent}</div>
        </>
    )
}