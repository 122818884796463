import "../../../SMSApp.css";
import "./index.css";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import NumStat from "../../../Components/NumStat/NumStat";
import { useState } from "react";

export default function MainEnginePortCalibrationPage() {

    // LO Temp Bef Engine
    const [dinValueLOTempBefEngine, setDinValueLOTempBefEngine] = useState(0);
    const [correctionFactorLOTempBefEngine, setCorrectionFactorLOTempBefEngine] = useState(0);
    const [realTimeValueLOTempBefEngine, setRealTimeValueLOTempBefEngine] = useState(0);

    // Shaft B Temp 1
    const [dinValueShaftBTemp, setDinValueShaftBTemp] = useState(0);
    const [correctionFactorShaftBTemp, setCorrectionFactorShaftBTemp] = useState(0);
    const [realTimeValueShaftBTemp, setRealTimeValueShaftBTemp] = useState(0);

    // FW Temp HT Circuit
    const [dinValueFWTemp, setDinValueFWTemp] = useState(0);
    const [correctionFactorFWTemp, setCorrectionFactorFWTemp] = useState(0);
    const [realTimeValueFWTemp, setRealTimeValueFWTemp] = useState(0);

    // FW Temp HT Circuit
    const [dinValueFWTempHT, setDinValueFWTempHT] = useState(0);
    const [correctionFactorFWTempHT, setCorrectionFactorFWTempHT] = useState(0);
    const [realTimeValueFWTempHT, setRealTimeValueFWTempHT] = useState(0);

    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"MAIN ENGINE > ANALOG OUT PORT CALIBRATION"} /></div>
            <div className="flexRow contentAnalogPort">
                <div className="greyBox">
                    <div className="titleContent">LO Temp Bef Engine</div>
                    <div className="numStatContainer">
                        <NumStat text1={"DIN VALUE"} number={dinValueLOTempBefEngine} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"CORRECTION FACTOR"} text2={"Default: 100"} number={correctionFactorLOTempBefEngine} icon={true} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"REAL TIME VALUE"} number={realTimeValueLOTempBefEngine} />
                    </div>
                </div>
                <div className="greyBox">
                    <div className="titleContent">Shaft B Temp 1</div>
                    <div className="numStatContainer">
                        <NumStat text1={"DIN VALUE"} number={dinValueShaftBTemp} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"CORRECTION FACTOR"} text2={"Default: 100"} number={correctionFactorShaftBTemp} icon={true} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"REAL TIME VALUE"} number={realTimeValueShaftBTemp} />
                    </div>
                </div>
            </div>
            <div className="flexRow contentAnalogPort">
                <div className="greyBox">
                    <div className="titleContent">FW Temp HT Circuit</div>
                    <div className="numStatContainer">
                        <NumStat text1={"DIN VALUE"} number={dinValueFWTemp} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"CORRECTION FACTOR"} text2={"Default: 100"} number={correctionFactorFWTemp} icon={true} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"REAL TIME VALUE"} number={realTimeValueFWTemp} />
                    </div>
                </div>
                <div className="greyBox">
                    <div className="titleContent">FW Temp HT Circuit</div>
                    <div className="numStatContainer">
                        <NumStat text1={"DIN VALUE"} number={dinValueFWTempHT} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"CORRECTION FACTOR"} text2={"Default: 100"} number={correctionFactorFWTempHT} icon={true} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"REAL TIME VALUE"} number={realTimeValueFWTempHT} />
                    </div>
                </div>
            </div>
            <div style={{paddingBottom:120}}></div>
            <div><BottomNavBar /></div>
        </>
    )
}