import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getReadingValueForFuelConsumption, getReadingValueForFuelConsumptionAverageAndCurrent } from "../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../Components/DropDownForm";
import DynamicLineChart from "../../Components/DynamicLineChartForFuelConsumption"
import DynamicLineChartDoubleAxis from "../../Components/DynamicLineChartForFuelConsumptionAvgAndCurrent"
import moment from "moment";

export default function CustomerFuelConsumptionPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [fuelMEP, setFuelMEP] = useState([]);
    const [fuelMES, setFuelMES] = useState([]);
    const [fuelAEP, setFuelAEP] = useState([]);
    const [fuelAES, setFuelAES] = useState([]);
    const [fuelAvgMEP, setFuelAvgMEP] = useState([]);
    const [fuelAvgMES, setFuelAvgMES] = useState([]);
    const [fuelAvgAEP, setFuelAvgAEP] = useState([]);
    const [fuelAvgAES, setFuelAvgAES] = useState([]);
    const [fuelCurrentMEP, setFuelCurrentMEP] = useState([]);
    const [fuelCurrentMES, setFuelCurrentMES] = useState([]);
    const [fuelCurrentAEP, setFuelCurrentAEP] = useState([]);
    const [fuelCurrentAES, setFuelCurrentAES] = useState([]);
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    const durationOption = [
        {
          label: "1 Hour Ago",
          value: 1,
        },
        {
          label: "1 Day Ago",
          value: 2,
        },
        {
          label: "1 Week Ago",
          value: 3,
        },
      ];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])


    useEffect(() => {
        loadFuelConsumption();
     }, [vesselId])

     useEffect(() => {
        console.log('FUEL MEP', fuelMEP);
        console.log('FUEL MES', fuelMES);
        console.log('FUEL AEP', fuelAEP);
        console.log('FUEL AES', fuelAES);
        setLoading(false);
     }, [fuelMEP])

     useEffect(() => {
        console.log('FUEL Current MEP', fuelCurrentMEP);
        console.log('FUEL Current MES', fuelCurrentMES);
        console.log('FUEL Current AEP', fuelCurrentAEP);
        console.log('FUEL Current AES', fuelCurrentAES);
        setLoading(false);
     }, [fuelAvgMEP])


    const loadFuelConsumption = async () => {
        try {
            setLoading(true);
            console.log('start date:', selectStartDate);
            console.log('end date', selectEndDate);
            let results = await getReadingValueForFuelConsumption(cookies.token, smsSerialNumber, vesselId, selectStartDate, selectEndDate);
            if (results){
                let fuelMEP = results.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 19);
                let fuelMES = results.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 19);
                let fuelAEP = results.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 19);
                let fuelAES = results.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 19);
                setFuelMEP(fuelMEP);
                setFuelMES(fuelMES);
                setFuelAEP(fuelAEP);
                setFuelAES(fuelAES);
            }
            let resultsAvgCurrent = await getReadingValueForFuelConsumptionAverageAndCurrent(cookies.token, smsSerialNumber, vesselId, selectStartDate, selectEndDate);
            if (resultsAvgCurrent){
                let fuelAvgMEP = resultsAvgCurrent.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 17);
                let fuelAvgMES = resultsAvgCurrent.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 17);
                let fuelAvgAEP = resultsAvgCurrent.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 17);
                let fuelAvgAES = resultsAvgCurrent.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 17);
                let fuelCurrentMEP = resultsAvgCurrent.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 13);
                let fuelCurrentMES = resultsAvgCurrent.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 13);
                let fuelCurrentAEP = resultsAvgCurrent.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 18);
                let fuelCurrentAES = resultsAvgCurrent.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 18);
                setFuelAvgMEP(fuelAvgMEP);
                setFuelAvgMES(fuelAvgMES);
                setFuelAvgAEP(fuelAvgAEP);
                setFuelAvgAES(fuelAvgAES);
                setFuelCurrentMEP(fuelCurrentMEP);
                setFuelCurrentMES(fuelCurrentMES);
                setFuelCurrentAEP(fuelCurrentAEP);
                setFuelCurrentAES(fuelCurrentAES);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <div className="mainFuelConsumptionPage">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerFuelConsumption">
                    <div className="innerChildMainContainerFuelConsumption">
                        <ContainerBox
                            titleCaption={vesselName + ' FUEL CONSUMPTION'}
                            childContent={
                                <>
                                    <div className="formDateForFuelConsumption">
                                        {/* <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Select required onChange={(e) => {
                                                setSelectedTank(e.target.value);
                                            }} value={selectedTank} >
                                                <option selected value="">
                                                    Select Tank ...
                                                </option>
                                                {listTank.map((tank, index) => (
                                                    <option key={index} value={tank.id}>{tank.coordinate_desc}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group> */}
                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectStartDate(e.target.value);
                                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                }}
                                                value={
                                                    selectStartDate
                                                        ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectEndDate(e.target.value);
                                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                }}
                                                value={
                                                    selectEndDate
                                                        ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ margin: "10px"}}>
                                            <DropDownForm
                                                caption={"Time"}
                                                placeholder={"Select Time ..."}
                                                listOption={durationOption}
                                                valueKey={"value"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    console.log(e);
                                                    try {
                                                        let dateTime = new Date();
                                                        if (e.target.value === "1") {
                                                            console.log("1");
                                                            let startDate = moment(dateTime)
                                                                // .utc()
                                                                .subtract(60, "minutes")
                                                                .format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime)
                                                                // .utc()
                                                                .format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        } else if (e.target.value === "2") {
                                                            console.log("2");
                                                            let startDate = moment(dateTime)
                                                                // .utc()
                                                                .subtract(1, "day")
                                                                .format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime)
                                                                // .utc()
                                                                .format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                            console.log("tes", startDate, endDate);
                                                        } else if (e.target.value === "3") {
                                                            console.log("3");
                                                            let startDate = moment(dateTime)
                                                                // .utc()
                                                                .subtract(1, "week")
                                                                .format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime)
                                                                // .utc()
                                                                .format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        }
                                                    } catch (exception) {
                                                        console.log(exception);
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Button
                                            style={{ height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                loadFuelConsumption();
                                            }}
                                        >
                                            Show Fuel Consumption
                                        </Button>

                                        {/* <Form.Label style={{ color: 'white' }}>
                                            <input
                                                style={{ marginTop: "30px" }}
                                                type="checkbox"
                                                checked={refreshChecked}
                                                onChange={(e) => {
                                                    setRefreshChecked(e.target.checked);
                                                }}
                                            />
                                            Auto Refresh
                                        </Form.Label> */}
                                    </div>
                                    <div className="outerBorderFuelConsumption">
                                        <h4 className="txtFortankNameFuelConsumption">Total Fuel Usage</h4>
                                        <div className="lineChartContainerFuelConsumption">
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                                <DynamicLineChart dataFuel={fuelMEP} minValue={0} maxValue={400} stepSize={100}/>
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                                <DynamicLineChart dataFuel={fuelMES} minValue={0} maxValue={400} stepSize={100}/>
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                                <DynamicLineChart dataFuel={fuelAEP} minValue={0} maxValue={40} stepSize={10}/>
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                                <DynamicLineChart dataFuel={fuelAES} minValue={0} maxValue={40} stepSize={10}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="outerBorderFuelConsumption">
                                        <h4 className="txtFortankNameFuelConsumption">Average & Current Fuel Consumption</h4>
                                        <div className="lineChartContainerFuelConsumption">
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                                <DynamicLineChartDoubleAxis dataAvg={fuelAvgMEP} dataCurrent={fuelCurrentMEP} 
                                                minValue={0} maxValue={400} stepSize={100}/>
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                                <DynamicLineChartDoubleAxis dataAvg={fuelAvgMES} dataCurrent={fuelCurrentMES} 
                                                minValue={0} maxValue={400} stepSize={100}/>
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                                <DynamicLineChartDoubleAxis dataAvg={fuelAvgAEP} dataCurrent={fuelCurrentAEP} 
                                                minValue={0} maxValue={40} stepSize={10}/>
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                                <DynamicLineChartDoubleAxis dataAvg={fuelAvgAES} dataCurrent={fuelCurrentAES} 
                                                minValue={0} maxValue={40} stepSize={10}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </div>
                    <Loading
                    loading={loading}
                    loadingText={`${t("loading_component.text")}`}
                />
                </Container>
                
            </div>
        </>
    )
}