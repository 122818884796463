import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid } from "../../../Helpers/ApplicationHelpers";
import { getSurveyType, getSurveyTypeCount, deleteSurveyType, insertUpdateSurveyType } from "../../../Helpers/SurveyHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import Paginations from "../../../Components/Pagination";
import {
    BoxArrowUpRight, FileCheckFill, PencilFill, Trash, CameraFill,
    FilePlusFill, Search, ArrowClockwise, GearWideConnected
} from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function VesselPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [listSurveyType, setListSurveyType] = useState([]);
    const [loading, setLoading] = useState(true);
    const [surveyTypeCount, setSurveyTypeCount] = useState(0);
    const [surveyTypeId, setSurveyTypeId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [resetSearch, setResetSearch] = useState(0);
    const [isSearched, setIsSearched] = useState(0)
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_survey_type.order_name1")}`,
        value: "created_date"
    });
    const [newSurveyType, setNewSurveyType] = useState({
        id: "",
        survey_type_name: "",
        survey_type_desc: "",
        language_id: "",
        done: false,
    });
    const [descending, setDescending] = useState(true);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const orderByList = [
        {
            label: `${t("index_survey_type.order_name1")}`,
            value: "created_date"
        },
        {
            label: `${t("index_survey_type.order_name2")}`,
            value: "survey_type_name"
        }]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadSurveyType();
                setLoading(false);
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);


    useEffect(() => {
        if (resetSearch !== 0) {
            loadSurveyType();
        }
    }, [resetSearch]);

    useEffect(() => {
        if (newSurveyType.done) {
            saveSurveyType();
        }
    }, [newSurveyType]);

    useEffect(() => {
        setLoading(true);
        loadSurveyType();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadSurveyType();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadSurveyType();
        }
    }, [orderBy]);

    useEffect(() => {
        if (surveyTypeId !== "") {
            setLoading(true);
            removeSurveyType();
        }
    }, [surveyTypeId]);

    // useEffect(() => {
    //     setLoading(true);
    //     loadSurveyType();
    // }, [searchQuery])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadSurveyType();
        }
    }, [descending]);



    const loadSurveyType = async () => {
        try {
            let surveyTypeCount = await getSurveyTypeCount(cookies.token, cookies.languageId, searchQuery, false);
            setSurveyTypeCount(surveyTypeCount);
            let totalPage = surveyTypeCount / itemPerPage;
            setTotalPage(totalPage);

            let listSurveyType = await getSurveyType(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery);
            setListSurveyType(listSurveyType);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
            setOrderBy({
                label: `${t("index_survey_type.order_name1")}`,
                value: "created_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeSurveyType = async () => {
        let response = await deleteSurveyType(cookies.token, surveyTypeId);
        if (response) {
            loadSurveyType();
            alert(`${t("index_survey_type.delete_success_alert")}`)
        } else {
            alert(`${t("index_survey_type.delete_failed_alert")}`);
        }
        setLoading(false);
    }

    const resetDetail = async () => {
        setNewSurveyType({
            id: "",
            survey_type_name: "",
            survey_type_desc: "",
            language_id: "",
            done: false,
        });
    }

    const saveSurveyType = async () => {
        try {
            let res = await insertUpdateSurveyType(cookies.token, newSurveyType);
            setLoading(false);
            await loadSurveyType();
        }
        catch (exception) {
            console.log(exception);
        }
    };

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_survey_type.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                resetDetail();
                                setShowDetailModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_survey_type.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_survey_type.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadSurveyType();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadSurveyType();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                            // await loadSurveyType();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 16 }} className="table-header-content">{t("index_survey_type.table_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_survey_type.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_survey_type.table_name3")}</div>
                                </div>
                                {
                                    listSurveyType.map((surveyType, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 0.5 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 16 }} className="table-body-content"><p>{surveyType.survey_type_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        resetDetail();
                                                        setNewSurveyType({ ...newSurveyType, ...surveyType });
                                                        setShowDetailModal(true);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_survey_type.delete_confirmation")}  ${surveyType.survey_type_name}?`)) {
                                                            let surveyTypeId = surveyType.id;
                                                            setSurveyTypeId(surveyTypeId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
                <Modal size="lg" show={showDetailModal} onHide={() => {
                    setShowDetailModal(false);
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("index_survey_type.modal_add_survey_type")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                            }}>
                                <Form style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flex: 1,
                                    padding: 10,
                                }} onSubmit={(e) => {
                                    e.preventDefault();
                                    setShowDetailModal(false);
                                    setNewSurveyType({ ...newSurveyType, language_id: cookies.languageId, done: true });
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_type.field_survey_type_name")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewSurveyType({ ...newSurveyType, survey_type_name: e.target.value })
                                                }} value={newSurveyType.survey_type_name} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_type.field_description")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setNewSurveyType({ ...newSurveyType, survey_type_desc: e.target.value })
                                                }} value={newSurveyType.survey_type_desc} as="textarea" rows={3} placeholder=""></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button style={{ width: 100 }} variant="primary" type="submit">
                                        {t("index_survey_type.save_button")}
                                        </Button>
                                        <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {

                                            //loadDocumentData();
                                            setShowDetailModal(false);
                                        }}>
                                            {t("index_survey_type.cancel_button")}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal>
            </Container >
        </>
    )


}