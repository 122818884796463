// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomNavBarContainer {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
}

/* .contentNavBar {
    display: flex;
    gap: 10px;
} */`, "",{"version":3,"sources":["webpack://./src/Components/SMSNavContainer/SMSNavContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;;;GAGG","sourcesContent":[".bottomNavBarContainer {\n    display: flex;\n    justify-content: space-around;\n    margin: 10px 0;\n}\n\n/* .contentNavBar {\n    display: flex;\n    gap: 10px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
