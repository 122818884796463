import "./index.css";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getSMSAlarmEventHistoryNew } from "../../../Helpers/ApplicationHelpers";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import moment from "moment";
import Select from 'react-select';
import DropDownForm from "../../../Components/DropDownForm";

export default function CustomerSMSAlarmEventHistory(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [itemPerPage, setItemPerPage] = useState(9);
    const [cookies, setCookie] = useCookies(["token"]);
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [listAlarmEventHistory, setListAlarmEventHistory] = useState([]);
    const [listCoordinateValueLastReadData, setListCoordinateValueLastReadData] = useState([]);
    
    const durationOption = [
        {
          label: "1 Jam",
          value: 1,
        },
        {
          label: "1 Hari",
          value: 2,
        },
        {
          label: "1 Minggu",
          value: 3,
        },
      ];
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );
    

    useEffect(() => {
        // loadEventHistory();
    }, []);

    useEffect(() => {
        console.log(listAlarmEventHistory);
    }, [listAlarmEventHistory]);

    const loadEventHistory = async () => {
        try {
            setLoading(true);
            console.log('sms serial number: ', smsSerialNumber);
            console.log('vessel id: ', vesselId);
            console.log('start date: ', selectStartDate);
            console.log('end date: ',selectEndDate );
            const response =  await getSMSAlarmEventHistoryNew(cookies.token, smsSerialNumber, selectStartDate, selectEndDate);
            setListAlarmEventHistory(response);
            // let receivedDate = response[0].read_date;
            // setLastReceivedDate(receivedDate);
            // let bitlampListTmp = bitlampList;
            // for (let bl in bitlampList) {
            //     let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
            //     if (find) {
            //         bitlampListTmp[bl].value = find.reading_value;
            //     }
            // }
            // // console.log(bitlampListTmp);
            // setBitlampList(bitlampListTmp);
            // console.log(bitlampList)
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainPageEventHistoryRes">
                <div className="containerBoxEventHistoryBottom">
                    <div className="formDate">
                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                            <Form.Label style={{ color: 'white', fontFamily: 'Disket Mono'}}>Tanggal Mulai</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    // setSelectStartDate(e.target.value);
                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                }}
                                value={
                                    selectStartDate
                                        ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                        : ""
                                }
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                            <Form.Label style={{ color: 'white', fontFamily: 'Disket Mono'}}>Tanggal Akhir</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    // setSelectEndDate(e.target.value);
                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                }}
                                value={
                                    selectEndDate
                                        ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                        : ""
                                }
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group style={{ margin: "10px", color: 'white' }}>
                            <DropDownForm
                                caption={"Waktu Terkini"}
                                placeholder={"Pilih Waktu"}
                                listOption={durationOption}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                    console.log(e);
                                    try {
                                        let dateTime = new Date();
                                        if (e.target.value === "1") {
                                            console.log("1");
                                            let startDate = moment(dateTime)
                                                // .utc()
                                                .subtract(60, "minutes")
                                                .format("yyyy-MM-DD HH:mm:ss");
                                            let endDate = moment(dateTime)
                                                // .utc()
                                                .format("yyyy-MM-DD HH:mm:ss");
                                            setSelectStartDate(startDate);
                                            setSelectEndDate(endDate);
                                        } else if (e.target.value === "2") {
                                            console.log("2");
                                            let startDate = moment(dateTime)
                                                // .utc()
                                                .subtract(1, "day")
                                                .format("yyyy-MM-DD HH:mm:ss");
                                            let endDate = moment(dateTime)
                                                // .utc()
                                                .format("yyyy-MM-DD HH:mm:ss");
                                            setSelectStartDate(startDate);
                                            setSelectEndDate(endDate);
                                            console.log("tes", startDate, endDate);
                                        } else if (e.target.value === "3") {
                                            console.log("3");
                                            let startDate = moment(dateTime)
                                                // .utc()
                                                .subtract(1, "week")
                                                .format("yyyy-MM-DD HH:mm:ss");
                                            let endDate = moment(dateTime)
                                                // .utc()
                                                .format("yyyy-MM-DD HH:mm:ss");
                                            setSelectStartDate(startDate);
                                            setSelectEndDate(endDate);
                                        }
                                    } catch (exception) {
                                        console.log(exception);
                                    }
                                }}
                            />
                        </Form.Group>

                        <Button
                            variant="dark"
                            style={{ height: "40px", margin: "10px", marginTop: "35px", backgroundColor: '#050512' }}
                            onClick={() => {
                                loadEventHistory();
                            }}
                        >
                            Tampilkan Data
                        </Button>

                        {/* <Form.Label style={{ color: 'white' }}>
                            <input
                                style={{ marginTop: "30px" }}
                                type="checkbox"
                                checked={refreshChecked}
                                onChange={(e) => {
                                    setRefreshChecked(e.target.checked);
                                }}
                            />
                            Auto Refresh
                        </Form.Label> */}
                    </div>

                    <div className="tableAlarmLogsNew">
                        <div className="tableLogAlarmHeadNew">
                            <div style={{ flex: 1, color: "white", fontFamily: 'Disket Mono' }} className="table-header-content"></div>
                            <div style={{ flex: 3, color: "white", fontFamily: 'Disket Mono' }} className="table-header-content">Alarm Event</div>
                            <div style={{ flex: 2, color: "white", fontFamily: 'Disket Mono' }} className="table-header-content">Event Time</div>
                            <div style={{ flex: 2, color: "white", fontFamily: 'Disket Mono' }} className="table-header-content">Alarm Type</div>
                        </div>
                        <div className="scrollableTableContainer">
                        {
                            listAlarmEventHistory.map((events, index) => {
                                return (
                                    <div className="tableBodyContainer">
                                        <div className="tableLogAlarmBodyNew" style={{ color: "white", backgroundColor: index % 2 === 0 ? 'rgba(100, 116, 139, 0.3)' : 'none'}}>
                                            <div style={{ flex: 1, fontFamily: 'Disket Mono'}} className="table-body-content">{(index + 1)}</div>
                                            <div style={{ flex: 3, fontFamily: 'Disket Mono'}} className="table-body-content">{events.coordinate_desc}</div>
                                            <div style={{ flex: 2, fontFamily: 'Disket Mono'}} className="table-body-content">{moment(events.start_time).format("DD-MM-yyyy HH:mm")}</div>
                                            <div style={{ flex: 2, fontFamily: 'Disket Mono'}} className="table-body-content">{events.level}</div>
                                        </div>
                                    </div>
                                )

                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
        // <>
        //     <div className="mainPageWater">
        //         <div className="containerBoxWater">
                
                    
        //         </div>
        //     </div>
        //     <Loading loading={loading} />
        // </>
    );
}