// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPartPartCatalogNew{
    display: flex;
    width: 100%;
}

.mainContainerPartCatalogNew {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    padding: 10px;
}

.childMainContainerPartCatalogNew {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffffee;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
    width: 100%;
    /* height: 900px;  */
    overflow-y: scroll; 
}`, "",{"version":3,"sources":["webpack://./src/CustomerPages/VesselEquipment/Document/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".mainPartPartCatalogNew{\n    display: flex;\n    width: 100%;\n}\n\n.mainContainerPartCatalogNew {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    width: 100%;\n    padding: 10px;\n}\n\n.childMainContainerPartCatalogNew {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    background-color: #ffffffee;\n    border-radius: 10px;\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    -ms-border-radius: 10px;\n    -o-border-radius: 10px;\n    padding: 10px;\n    width: 100%;\n    /* height: 900px;  */\n    overflow-y: scroll; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
