import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById,  getVesselInventoryCategoryById, insertUpdateVesselInventoryCategory,
     } from "../../../../../Helpers/ApplicationHelpers";

import "../../../../../App.css";
import Navbar from "../../../../../Components/NavBar";
import ContainerBox from "../../../../../Components/ContainerBox";
import Loading from "../../../../../Components/Loading";
import { ArrowLeft,} from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function InventoryCategoryDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [categoryId, setCategoryId] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [inventoryCategory, setInventoryCategory] = useState({
        id: 0,
        category_name: "",
        category_description: "",
    })



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadAccess();
                setCategoryId(location.state.categoryId);
            }
        }
        checkCookies();
       
    }, []);


    useEffect(()=>{
        if(categoryId !== 0 && categoryId !== ""){
            initInventoryCategory();
        }else{
            setLoading(false);
        }
    },[categoryId])

    const saveDataCategory = async () => {
        try {
            let response = await insertUpdateVesselInventoryCategory(cookies.token, inventoryCategory);            
            if (response.error_code === 0) {
                alert(`${t("detail_vessel_inventory_category.alert_success_save_data")}`); 
                navigate("/Vessel/InventoryCategory")
            } else {
                alert(`${t("detail_vessel_inventory_category.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const initInventoryCategory = async () => {
        try {
            let response = await getVesselInventoryCategoryById(cookies.token, categoryId);
            if (response) {
                setInventoryCategory({
                    ...inventoryCategory,
                    id: response.id,
                    category_name: response.category_name,
                    category_description: response.category_description,
                })
                setLoading(false);
            } else {
                alert(`${t("detail_vessel_inventory_category.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated)
            }
            
           
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_vessel_inventory_category.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Vessel/InventoryCategory"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 10,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" titleCaption={`${t("detail_vessel_inventory_category.inventory_category_header")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setDisabledButton(true);
                                                    saveDataCategory();
                                                }}>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_vessel_inventory_category.field_category")}</Form.Label>
                                                        <Form.Control value={inventoryCategory.category_name} onChange={(e) => {
                                                            setInventoryCategory({ ...inventoryCategory, category_name: e.target.value });
                                                        }} required placeholder=""></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_vessel_inventory_category.field_explanation")}</Form.Label>
                                                        <Form.Control as={"textarea"} rows={3} value={inventoryCategory.category_description} onChange={(e) => {
                                                            setInventoryCategory({ ...inventoryCategory, category_description: e.target.value });
                                                        }} placeholder=""></Form.Control>
                                                    </Form.Group>

                                                    {
                                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"?
                                                        <></>
                                                        :
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            flexWrap: "nowrap",
                                                            padding: 10,
                                                            width: "100%",
                                                            justifyContent: "center",
                                                            fontWeight: "bold"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                paddingRight: 5,
                                                            }}><Button style={{width:100}} className="save-button" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("detail_vessel_inventory_category.save_button")}</Button> </div>
                                                            <div style={{
                                                                display: "flex",
                                                                paddingLeft: 5,
                                                                paddingRight: 5
                                                            }}>
                                                                <Button style={{width:100}} onClick={() => {
                                                                    navigate("/InventoryType")
                                                                }} className="cancel-button" variant="danger">{t("detail_vessel_inventory_category.cancel_button")}</Button></div>
    
                                                        </div>

                                                    }

                                                    
                                                </Form>

                                            </div>
                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>

                            </div>


                        </div>

                    }>

                </ContainerBox >
            </Container >
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}