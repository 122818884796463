import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getStaff, getDivision, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import {
    getInventoryRequisitionById, insertUpdateInventoryRequisition, getRequisitionStatus, getInventoryMaster
} from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function InventoryRequisitionDetailPage() {
    const { t, i18n } = useTranslation();
    const [inventoryMasterOptions, setInventoryMasterOptions] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [actionButton, setActionButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [approvalButton, setApprovalButton] = useState("");
    const [divisionId, setDivisionId] = useState("");
    const [staffId, setStaffId] = useState("");
    const [addButtonDetail, setAddButtonDetail] = useState(false);
    const [equipmentErrorMessage, setEquipmentErrorMessage] = useState(false);
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [selectedFromDivision, setSelectedFromDivision] = useState({ id: "" });
    const [selectedToDivision, setSelectedToDivision] = useState({ id: "" });
    const [listDivision, setListDivision] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [inventoryRequisition, setInventoryRequisition] = useState({
        id: 0,
        requesting_staff_id: null,
        request_date: null,
        status_id: null,
        approved_date: null,
        approved_by: null,
        from_division_id: null,
        to_division_id: null,
        list_requisition: [],
        ext_customer_id: cookies.extCustomerId
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadStaff();
                loadDivision();
                // loadInventoryMaster();
                loadStatus();
                loadAccess();
                setStaffId(cookies.staffId);
            }
        }
        checkCookies();
        if (location.state.inventoryRequisitionId === 0) {
            setLoading(false);
        } else {
            initInventoryRequisition();
        }
    }, []);

    useEffect(() => {
        if (divisionId !== "")
            loadInventoryMaster();
    }, [divisionId])
    useEffect(() => {
        // if (inventoryRequisition.from_division_id) {
        //     let division = listDivision.find(p => p.id === inventoryRequisition.from_division_id);
        //     if (division) {
        //         setSelectedFromDivision(division);
        //     }

        // }
        // if (inventoryRequisition.to_division_id) {
        //     let division = listDivision.find(p => p.id === inventoryRequisition.to_division_id);
        //     if (division) {
        //         setSelectedToDivision(division);
        //     }

        // }

        validationForm();

        if (inventoryRequisition.list_requisition.length > 0) {
            for (let index = 0; index < inventoryRequisition.list_requisition.length; index++) {
                const listRequest = inventoryRequisition.list_requisition[index];
                if (listRequest.inventory_master_id === "") {
                    setEquipmentErrorMessage(`${t("detail_inventory_requisition.error_list_inventory")}`);
                    setDisabledButton(true);
                    setAddButtonDetail(true);
                } else {
                    if (errorMessage === "") {
                        setAddButtonDetail(false);
                    }

                    setEquipmentErrorMessage("");
                    validationForm();
                }
            }


        }

    }, [inventoryRequisition])

    // useEffect(() => {
    //     console.log(selectedFromDivision)
    //     if (selectedFromDivision.division_name) {
    //         setInventoryRequisition({ ...inventoryRequisition, from_division_id: selectedFromDivision.id });
    //     } else {
    //         setInventoryRequisition({ ...inventoryRequisition, from_division_id: null });
    //     }
    // }, [selectedFromDivision]);

    // useEffect(() => {
    //     if (selectedToDivision.division_name) {
    //         setInventoryRequisition({ ...inventoryRequisition, to_division_id: selectedToDivision.id });
    //     } else {
    //         setInventoryRequisition({ ...inventoryRequisition, to_division_id: null });
    //     }
    // }, [selectedToDivision]);

    useEffect(() => {
        if (location.state.inventoryRequisitionId === 0) {
            let today = new Date();
            let date = moment(today).format("yyyy-MM-DD")
            setInventoryRequisition({ ...inventoryRequisition, request_date: date });
        }

    }, [listStatusRequisition])

    useEffect(() => {
        if (actionButton !== "")
            saveData()
    }, [actionButton])

    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId,);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryMaster = async () => {
        try {
            let response = await getInventoryMaster(cookies.token, cookies.languageId, cookies.extCustomerId);
            let newList = response.filter(p => p.division_id === divisionId);
            let listInventory = [];
            for (let i = 0; i < newList.length; i++) {
                var obj = {
                    value: newList[i].id,
                    label: newList[i].inventory_master_name,
                    id: newList[i].id,
                    quantity: newList[i].quantity,
                    unit_name: newList[i].unit_name
                };

                listInventory.push(obj);

            }
            setInventoryMasterOptions(listInventory);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {

            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, undefined, undefined, undefined, 2);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id,
                    resign_date: response[i].resign_date

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let inventoryRequisitionTmp = inventoryRequisition;
            inventoryRequisitionTmp.ext_customer_id = inventoryRequisitionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : inventoryRequisition.ext_customer_id
            let response = await insertUpdateInventoryRequisition(cookies.token, inventoryRequisitionTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_inventory_requisition.alert_success_save_data")}`);
                navigate('/InventoryRequisition');

            } else {
                alert(`${t("detail_inventory_requisition.alert_faield_save_data")}`);
            }

            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initInventoryRequisition = async () => {
        try {
            let response = await getInventoryRequisitionById(cookies.token, location.state.inventoryRequisitionId);
            if (response) {
                setDivisionId(response.from_division_id);
                setInventoryRequisition({
                    ...inventoryRequisition,
                    id: response.id,
                    requesting_staff_id: response.requesting_staff_id,
                    request_date: response.request_date,
                    status_id: response.status_id,
                    approved_date: response.approved_date,
                    approved_by: response.approved_by,
                    list_requisition: response.list_requisition,
                    requested_name: response.requested_name,
                    approved_name: response.approved_name,
                    from_division_id: response.from_division_id,
                    to_division_id: response.to_division_id
                })
            }

            let status = await getRequisitionStatus(cookies.token);
            if (status) {
                let statusCode = status.find(p => p.id === response.status_id)
                if (statusCode.status_code === "APPROVED" || statusCode.status_code === "REJECTED") {
                    setDisabledForm(true);
                }
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
            if (location.state.inventoryRequisitionId === 0) {
                let status = await response.find(p => p.status_code === "REQ")
                setInventoryRequisition({ ...inventoryRequisition, status_id: status.id })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (inventoryRequisition.requesting_staff_id === null || inventoryRequisition.request_date === null || inventoryRequisition.status_id === null || inventoryRequisition.from_division_id === null || inventoryRequisition.to_division_id === null) {
                    setErrors({
                        ...errors,
                        message: `${t("detail_inventory_requisition.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }

                else if (moment(inventoryRequisition.approved_date).format("yyyy-MM-DD") < moment(inventoryRequisition.request_date).format("yyyy-MM-DD")) {
                    setErrors({
                        ...errors,
                        dateMessageApproved: '*Tanggal Disetujui Tidak Bisa < Tanggal Request'
                    },
                        setDisabledButton(true))
                }

                else if (inventoryRequisition.approved_date !== null && inventoryRequisition.approved_by === null) {
                    setErrors({
                        ...errors,
                        approvedStaff: `${t("detail_inventory_requisition.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (inventoryRequisition.list_requisition.length === 0) {
                    setErrors({
                        ...errors,
                        listRequisitionError: `${t("detail_inventory_requisition.error_list_inventory")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "message", "dateMessageApproved", "approvedStaff");
                    setErrors(errorMsg);
                    if (errorMessage === "") {
                        setDisabledButton(false);
                    }

                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = require("../../../../Assets/Images/quantums.png");
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("detail_inventory_requisition.print_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = require("../../../../Assets/Images/quantums.png");
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated);
                setApprovalButton(response.approval);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_inventory_requisition.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/InventoryRequisition"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_inventory_requisition.header_vessel_inventory_requisition")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>

                                                                <FormSelect caption={`${t("detail_inventory_requisition.field_requested_staff")}`} autoFocusValue={inventoryRequisition.requesting_staff_id === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== inventoryRequisition.approved_by && p.resign_date === null)} value={staffOptions.filter(function (option) {
                                                                        return option.id === inventoryRequisition.requesting_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryRequisition({ ...inventoryRequisition, requesting_staff_id: e.id });
                                                                        } else {
                                                                            setInventoryRequisition({ ...inventoryRequisition, requesting_staff_id: null });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_inventory_requisition.field_division_from")}</Form.Label>
                                                                    <Select placeholder={""}
                                                                        getOptionLabel={(item) => {
                                                                            return item.division_code;
                                                                        }} clearValue={true}
                                                                        getOptionValue={(item) => {
                                                                            return item.id;
                                                                        }}
                                                                        options={listDivision} value={listDivision.filter(function (option) {
                                                                            return option.id === inventoryRequisition.from_division_id;
                                                                        })} onChange={(e) => {
                                                                            if (e === null) {
                                                                                setInventoryRequisition({ ...inventoryRequisition, from_division_id: null });
                                                                                setDivisionId("")
                                                                            } else {

                                                                                setInventoryRequisition({ ...inventoryRequisition, from_division_id: e.id })
                                                                                setDivisionId(e.id);
                                                                            }
                                                                        }}
                                                                        isClearable
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_inventory_requisition.field_for_division")}</Form.Label>
                                                                    <Select placeholder={""}
                                                                        getOptionLabel={(item) => {
                                                                            return item.division_code;
                                                                        }} clearValue={true}
                                                                        getOptionValue={(item) => {
                                                                            return item.id;
                                                                        }}
                                                                        options={listDivision} value={listDivision.filter(function (option) {
                                                                            return option.id === inventoryRequisition.to_division_id;
                                                                        })} onChange={(e) => {
                                                                            if (e === null) {
                                                                                setInventoryRequisition({ ...inventoryRequisition, to_division_id: null })
                                                                            } else {
                                                                                setInventoryRequisition({ ...inventoryRequisition, to_division_id: e.id })
                                                                            }
                                                                        }}
                                                                        isClearable
                                                                    />
                                                                </Form.Group>
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }
                                                                <DropDownForm value={inventoryRequisition.status_id} caption={`${t("detail_inventory_requisition.field_request_status")}`} placeholder=""
                                                                    setValueFunction={(e) => {
                                                                        let status = listStatusRequisition.find(p => p.id === e.target.value);
                                                                        if (status.status_code === "APPROVED") {
                                                                            var today = new Date(),
                                                                                // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                                date = moment(today).format("yyyy-MM-DD")
                                                                            setInventoryRequisition({ ...inventoryRequisition, status_id: e.target.value, approved_date: date });
                                                                        } else {
                                                                            setInventoryRequisition({ ...inventoryRequisition, status_id: e.target.value, approved_date: null, approved_by: null });

                                                                        }
                                                                    }} listOption={listStatusRequisition} valueKey="id" labelKey="status_name" disabledForm={true} />


                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>

                                                                <FormSelect caption={`${t("detail_inventory_requisition.field_approved_by")}`} autoFocusValue={inventoryRequisition.approved_by === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== inventoryRequisition.requesting_staff_id)} value={staffOptions.filter(function (option) {
                                                                        return option.id === inventoryRequisition.approved_by;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryRequisition({ ...inventoryRequisition, approved_by: e.id });
                                                                        } else {
                                                                            setInventoryRequisition({ ...inventoryRequisition, approved_by: null });
                                                                        }

                                                                    }} disabledForm={inventoryRequisition.approved_date ? false : true}

                                                                />
                                                                {
                                                                    errors.approvedStaff && <p style={{ color: "red" }}>{errors.approvedStaff}</p>
                                                                }

                                                                <TextBox value={inventoryRequisition.request_date ? moment(inventoryRequisition.request_date).format("yyyy-MM-DD") : ""} placeholder="" controlType="date"
                                                                    caption={`${t("detail_inventory_requisition.field_request_date")}`} setValueFunction={(e) => {
                                                                        setInventoryRequisition({ ...inventoryRequisition, request_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <TextBox value={inventoryRequisition.approved_date ? moment(inventoryRequisition.approved_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption={`${t("detail_inventory_requisition.field_approved_date")}`} setValueFunction={(e) => {
                                                                        setInventoryRequisition({ ...inventoryRequisition, approved_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                                {
                                                                    errors.dateMessageApproved && <p style={{ color: "red" }}>{errors.dateMessageApproved}</p>
                                                                }


                                                            </div>
                                                        </div>

                                                        {
                                                            approvalButton && location.state.inventoryRequisitionId !== 0 &&

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                padding: 10,
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                fontWeight: "bold"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Button style={{ width: 100 }} onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "APPROVED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setInventoryRequisition({ ...inventoryRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Approve");
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="success">{t("detail_inventory_requisition.approved_button")}</Button>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Button style={{ width: 100 }} onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "REJECTED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setInventoryRequisition({ ...inventoryRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Reject")
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="danger">{t("detail_inventory_requisition.rejected_button")}</Button>
                                                                </div>


                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {
                                        location.state.inventoryRequisitionId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >{t("detail_inventory_requisition.button_print")} <Printer /></Button>
                                            :
                                            <></>
                                    }
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_inventory_requisition.header_inventory_list")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            padding: 5
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 3
                                                            }}>{t("detail_inventory_requisition.table_name1")}</div>
                                                            {/* <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flex: 2
                                                        }}>Jumlah</div> */}
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>{t("detail_inventory_requisition.table_name2")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>{t("detail_inventory_requisition.table_name3")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 1
                                                            }}>{t("detail_inventory_requisition.table_name4")}</div>
                                                        </div>
                                                        {
                                                            inventoryRequisition.list_requisition && inventoryRequisition.list_requisition.map((list, index) => {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5,
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 3,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Select placeholder={""}
                                                                                options={inventoryMasterOptions.filter((p) => {
                                                                                    return !inventoryRequisition.list_requisition.find((x) => {
                                                                                        return p.id === x.inventory_master_id
                                                                                    })
                                                                                })} value={inventoryMasterOptions.filter(function (option) {
                                                                                    return option.id === list.inventory_master_id;
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    let listRequisition = inventoryRequisition.list_requisition;
                                                                                    listRequisition[index].inventory_master_id = e.id;
                                                                                    listRequisition[index].stock_quantity = e.quantity;
                                                                                    listRequisition[index].unit_name = e.unit_name;
                                                                                    setInventoryRequisition({ ...inventoryRequisition, list_requisition: listRequisition });
                                                                                }}
                                                                                isDisabled={disabledForm}

                                                                            />


                                                                        </div>

                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "row",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                width: "70%",

                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                    if (e.target.value >= 0) {
                                                                                        let listRequisition = inventoryRequisition.list_requisition;
                                                                                        if (e.target.value > listRequisition[index].stock_quantity) {
                                                                                            setErrorMessage(`${t("detail_inventory_requisition.stock_available")} ${list.stock_quantity}`)
                                                                                            setAddButtonDetail(true);
                                                                                            setDisabledButton(true)

                                                                                        } else {
                                                                                            setErrorMessage("");
                                                                                            listRequisition[index].quantity = e.target.value;
                                                                                            setAddButtonDetail(false);
                                                                                            setDisabledButton(false);
                                                                                        }
                                                                                        setInventoryRequisition({ ...inventoryRequisition, list_requisition: listRequisition });

                                                                                    }

                                                                                }} type="number" value={list.quantity} required placeholder="" disabled={disabledForm} ></Form.Control>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                width: "30%",

                                                                            }}>
                                                                                <Form.Control type="text" value={list.unit_name? list.unit_name : ""} required placeholder="" disabled></Form.Control>
                                                                            </div>



                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                let listRequisition = inventoryRequisition.list_requisition;
                                                                                listRequisition[index].note = e.target.value;
                                                                                setInventoryRequisition({ ...inventoryRequisition, list_requisition: listRequisition });
                                                                            }} type="text" value={list.note} placeholder="" disabled={disabledForm}></Form.Control>


                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Button onClick={() => {
                                                                                if (window.confirm(`${t("detail_inventory_requisition.delete_confirmation")}`)) {
                                                                                    let list = [...inventoryRequisition.list_requisition];
                                                                                    list.splice(index, 1);
                                                                                    setInventoryRequisition({ ...inventoryRequisition, list_requisition: list });
                                                                                }

                                                                            }} style={{ maxWidth: 50 }} variant="danger" disabled={disabledForm === true ? disabledForm : !deletedButton}><Trash /></Button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }
                                                    {
                                                        equipmentErrorMessage && <p style={{ color: "red" }}>{equipmentErrorMessage}</p>
                                                    }
                                                    {
                                                        errors.listRequisitionError && <p style={{ color: "red" }}>{errors.listRequisitionError}</p>
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                inventory_master_id: "",
                                                                quantity: null,
                                                                note: ""
                                                            };
                                                            if (!inventoryRequisition.list_requisition) {
                                                                let listRequisition = [];
                                                                listRequisition.push(newParameter);
                                                                setInventoryRequisition({ ...inventoryRequisition, list_requisition: listRequisition });
                                                            } else {
                                                                let listRequisition = inventoryRequisition.list_requisition;
                                                                listRequisition.push(newParameter);
                                                                setInventoryRequisition({ ...inventoryRequisition, list_requisition: listRequisition });
                                                            }

                                                        }} disabled={disabledForm === false ? addButtonDetail : disabledForm}>{t("detail_inventory_requisition.button_add_inventory")}</Button>
                                                    </div>
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button style={{ width: 100 }} className="save-button" disabled={disabledForm === false && updatedButton === true ? disabledButton : true} type="submit">{t("detail_inventory_requisition.save_button")}</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button style={{ width: 100 }} onClick={() => {
                                                        navigate('/InventoryRequisition')
                                                    }} className="cancel-button" disabled={disabledForm === false ? disabledButton : true} variant="danger">{t("detail_inventory_requisition.cancel_button")}</Button>
                                                </div>
                                            </div>
                                    }


                                </Form>



                            </div>


                        </div>

                    }>
                </ContainerBox>
                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/quantums.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Quantum Pesona Dunia</p>
                        {/* <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">No.Dok : {returns.numberForm}</p> */}
                    </div>
                    <div style={{
                        marginLeft: '250px', width: '100%'
                    }}>
                        <h3 >{t("detail_inventory_requisition.print_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%', marginTop: 45 }}>
                        <Table className="tableClass" style={{ width: "70%" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_inventory_requisition.field_requested_staff")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{inventoryRequisition.requested_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_inventory_requisition.field_approved_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{inventoryRequisition.approved_name}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <Table className="tableClass" >
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_inventory_requisition.field_request_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{inventoryRequisition.request_date ? moment(inventoryRequisition.request_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_inventory_requisition.field_approved_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{inventoryRequisition.approved_date ? moment(inventoryRequisition.approved_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>

                        </Table>
                        {/* <p style={{ margin: '0' }} className="text-danger">Tanggal Pengajuan :  {sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("DD-MMM-yyyy") : ""}</p> */}
                        {/* <p style={{ margin: '0' }} className="text-danger">NO&nbsp;&nbsp;&nbsp;:  {returns.form_number}</p> */}
                    </div>
                    <div style={{ paddingBottom: 20 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_inventory_requisition.table_name1")} </td>
                            <td>{t("detail_inventory_requisition.table_name2")} </td>
                            <td>{t("detail_inventory_requisition.unit_name")} </td>
                            <td>{t("detail_inventory_requisition.table_name3")} </td>
                        </tr>

                        {inventoryRequisition.list_requisition && inventoryRequisition.list_requisition.map((listRequisition, index) => {
                            return (
                                <tr key={index} className="table-caption-body">
                                    <td>{index + 1}</td>
                                    <td>{listRequisition.inventory_master_name}</td>
                                    <td>{listRequisition.quantity}</td>
                                    <td>{listRequisition.unit_name}</td>
                                    <td>{listRequisition.note}</td>
                                    {/* <td>{listReturn.return_inventory_description}</td> */}
                                </tr>
                            )
                        })
                        }


                    </table>
                    <div style={{ float: 'left', width: '70%', marginTop: '30px' }}>
                        <span >{t("detail_inventory_requisition.field_requested_staff")} </span>
                        <p style={{ marginTop: '100px' }}>{inventoryRequisition.requested_name}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '30px' }}>
                        <span >{t("detail_inventory_requisition.field_approved_by")} </span>
                        <p style={{ marginTop: '100px' }}>{inventoryRequisition.approved_name ? inventoryRequisition.approved_name : " "}</p>
                        {inventoryRequisition.approved_name ? "" : <div style={{ paddingBottom: 30 }}></div>}
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>

                </div>
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    );

}