import "./index.css";
import Navbar from "../../../Components/NavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import SMSNavContainer from "../../../Components/SMSNavContainer/SMSNavContainer";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getPLCReadingValueForConnectionStatus } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import CustomerNavigatonBar from "../../../Components/CustomerNavBar";
import CustomerSMSMainEnginePage from "../SMSMainEngine";
import CustomerSMSAuxEnginePage from "../SMSAuxEngine";
import CustomerSMSGearBoxPage from "../SMSGearBox";
import CustomerSMSPumpMotorPage from "../SMSPumpMotor";
import CustomerSMSTankLevelPage from "../SMSTankLevel";
import CustomerSMSWatertightDoorPage from "../SMSWatertightDoor";
import CustomerSMSMainSwitchBoardPage from "../SMSMSB";
import CustomerSMSNavLightpage from "../SMSNavLight";
import CustomerSMSBilgesPage from "../SMSBilges";
import CustomerSMSLocationPage from "../SMSLocation";
import CustomerSMSFireAlarmPage from "../SMSFireAlarm";
import CustomerSMSAlarmEventHistory from "../SMSAlarmEventHistory";
import CustomerSMSMainMenu from "../SMSMainMenu";
// import LampNavButton from "../../../Components/NewLampNavButton/NewLampNavButton";
import NewSMSNavContainer from "../../../Components/NewSMSNavContainer/NewSMSNavContainer"
import NewSMSNavContainerRight from "../../../Components/NewSMSNavContainerRight/NewSMSNavContainerRight"
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";
import Clock from 'react-live-clock';
import RFB from './core/rfb.js';

export default function CustomerSMSVNCPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [currentChild, setCurrentChild] = useState(null);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [titleBar, setTitleBar] = useState("");
    const [connectionStatus, setConnectionStatus] = useState([]);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                // console.log(searchParams.get("smsSerialnumber").replace(/'/g, ""));
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            }
        }
        checkCookies();
        console.log(cookies);
        // console.log(vesselId);
    }, []);

    useEffect(() => {
        if (smsSerialNumber !== "") {
            setCurrentChild(<CustomerSMSMainMenu smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
        }
    }, [smsSerialNumber]);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])

    useEffect(() => {
        if (smsSerialNumber !== "") {
            loadConnection();
            console.log('SMS SERIAL NUMBER: ', smsSerialNumber);
            console.log('Customer Name: ', customerName);
            console.log('Vessel Name: ', vesselName);
            console.log('Vessel ID: ', vesselId);
            switch (activeTab) {
                case 0:
                    setCurrentChild(<CustomerSMSMainMenu smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]} />);
                    setTitleBar("Main Menu");
                    break;
                case 1:
                    setCurrentChild(<CustomerSMSMainEnginePage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]} />);
                    setTitleBar("Main Engine");
                    break;
                case 2:
                    setCurrentChild(<CustomerSMSAuxEnginePage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]} />);
                    setTitleBar("Auxiliary Engine");
                    break;
                case 3:
                    setCurrentChild(<CustomerSMSGearBoxPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]} />);
                    setTitleBar("Gearbox");
                    break;
                case 4:
                    setCurrentChild(<CustomerSMSPumpMotorPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={'AI'} idCoordinateGroup={[1]} />);
                    setTitleBar("Pump and Motor");
                    break;
                case 5:
                    setCurrentChild(<CustomerSMSTankLevelPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]} />);
                    setTitleBar("Tank Level");
                    break;
                case 6:
                    setCurrentChild(<CustomerSMSMainSwitchBoardPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]} />);
                    setTitleBar("Main Switchboard");
                    break;
                case 7:
                    setCurrentChild(<CustomerSMSWatertightDoorPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
                    setTitleBar("Common Alarm");
                    break;
                case 8:
                    setCurrentChild(<CustomerSMSFireAlarmPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
                    setTitleBar("Fire Alarm");
                    break;
                case 9:
                    setCurrentChild(<CustomerSMSNavLightpage smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
                    setTitleBar("Navigation Light");
                    break;
                case 10:
                    setCurrentChild(<CustomerSMSAlarmEventHistory smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
                    setTitleBar("Alarm Event History");
                    break;
                case 11:
                    setCurrentChild(<CustomerSMSLocationPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} />);
                    setTitleBar("Vessel Location");
                    break;
            }
        }
    }, [activeTab])

    useEffect(() => {
        console.log('connectionStatus', connectionStatus);
    }, [connectionStatus])

    const handleMenuClick = (val) => {
        console.log(val);
        setActiveTab(val);
    }

    const loadConnection = async () => {
        try {
            let response = await getPLCReadingValueForConnectionStatus(cookies.token, smsSerialNumber, vesselId);
            setConnectionStatus(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    let rfb;
    let desktopName;

    function connectedToServer(e) {
        status("Connected to " + desktopName);
    }

    // This function is called when we are disconnected
    function disconnectedFromServer(e) {
        if (e.detail.clean) {
            status("Disconnected");
        } else {
            status("Something went wrong, connection is closed");
        }
    }

    // When this function is called, the server requires
    // credentials to authenticate
    function credentialsAreRequired(e) {
        const password = prompt("Password Required:");
        rfb.sendCredentials({ password: password });
    }

    // When this function is called we have received
    // a desktop name from the server
    function updateDesktopName(e) {
        desktopName = e.detail.name;
    }

    // Since most operating systems will catch Ctrl+Alt+Del
    // before they get a chance to be intercepted by the browser,
    // we provide a way to emulate this key sequence.
    function sendCtrlAltDel() {
        rfb.sendCtrlAltDel();
        return false;
    }

    // Show a status text in the top bar
    function status(text) {
        document.getElementById('status').textContent = text;
    }

    // This function extracts the value of one variable from the
    // query string. If the variable isn't defined in the URL
    // it returns the default value instead.
    function readQueryVariable(name, defaultValue) {
        // A URL with a query parameter can look like this:
        // https://www.example.com?myqueryparam=myvalue
        //
        // Note that we use location.href instead of location.search
        // because Firefox < 53 has a bug w.r.t location.search
        const re = new RegExp('.*[?&]' + name + '=([^&#]*)'),
            match = document.location.href.match(re);

        if (match) {
            // We have to decode the URL since want the cleartext value
            return decodeURIComponent(match[1]);
        }

        return defaultValue;
    }
    useEffect(() => {


        // When this function is called we have
        // successfully connected to a server


        document.getElementById('sendCtrlAltDelButton')
            .onclick = sendCtrlAltDel;

        // Read parameters specified in the URL query string
        // By default, use the host and port of server that served this file
        const host = readQueryVariable('host', window.location.hostname);
        let port = readQueryVariable('port', window.location.port);
        const password = readQueryVariable('password');
        const path = readQueryVariable('path', 'websockify');

        // | | |         | | |
        // | | | Connect | | |
        // v v v         v v v

        status("Connecting");

        // Build the websocket URL used to connect
        let url;
        if (window.location.protocol === "https:") {
            url = 'wss';
        } else {
            url = 'ws';
        }
        url += '://' + host;
        if (port) {
            url += ':' + port;
        }
        url += '/' + path;

        // Creating a new RFB object will start a new connection
        // rfb = new RFB(document.getElementById('screen'), url,
        //               { credentials: { password: password } });
        rfb = new RFB(document.getElementById('screen'), 'wss://p201.quantum-pesonadunia.com/vnc_lite.html',
            { credentials: { password: 'Y3awqbkEB8TR' } });

        // Add listeners to important events from the RFB module
        rfb.addEventListener("connect", connectedToServer);
        rfb.addEventListener("disconnect", disconnectedFromServer);
        rfb.addEventListener("credentialsrequired", credentialsAreRequired);
        rfb.addEventListener("desktopname", updateDesktopName);

        // Set parameters that can be changed on an active connection
        // rfb.viewOnly = readQueryVariable('view_only', false);
        // rfb.scaleViewport = readQueryVariable('scale', false);
        rfb.viewOnly = false;
        rfb.scaleViewport = false;
    })

    return (
        <>
            <div class="menu-wrapper">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
            </div>

            <div id="top_bar">
                <div id="status">Loading</div>
                <div id="sendCtrlAltDelButton">Send CtrlAltDel</div>
            </div>

            <div id="screen">
            </div>
        </>
    )
}