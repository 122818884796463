import "../App.css";
import { Form, option } from "react-bootstrap";
import Select from 'react-select';

export default function FormSelect({
    caption,
    placeholder,
    options,
    value,
    // value,
    setValueFunction,
    // listOption = [],
    // valueKey,
    // labelKey
    autoFocusValue,
    multi = false,
    addNew,
    showModal,
    getOptionValue,
    getOptionLabel,
    clearValue,
    disabledForm = false,
}) {
    return (
        <div style={{ paddingBottom: 5 }}>
            <div style={{ fontWeight: "bold", paddingBottom: 5 }}>{caption} {addNew === undefined? <></>:<a onClick={showModal} role="button" style={{color:"blue", textDecoration:"underline" }}> <i  className='fa fa-plus text-primary'></i> {addNew} </a>}</div>
            <Select isMulti={multi} autoFocus={autoFocusValue} placeholder={placeholder}
                getOptionLabel={getOptionLabel} clearValue={clearValue}
                getOptionValue={getOptionValue}
                options={options} value={value} onChange={setValueFunction} isDisabled = {disabledForm} isClearable
                
            />
        </div>
    )
};