import React, { useState, useEffect } from "react";
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CustomerNavigatonBar from "../../Components/CustomerNavBar";
import { Container, Button, Form, Collapse } from 'react-bootstrap';
import Clock from 'react-live-clock';
import ContainerBox from "../../Components/ContainerBox";
import { isTokenValid, getVesselLocationForCustomer, getVesselForCustomer, 
    findVesselByIdForCustomerDashboard, getHistorisVesselLocationFromMaterializedView,
    getDistanceFromLatLonInKm } from "../../Helpers/ApplicationHelpers";
import { CircleFill, Download, EyeFill } from "react-bootstrap-icons";
import Quantum from "../../../src/Assets/Images/tugboatdamen.png";
import GoogleMapContainer from "../../Components/GoogleMapContainerCustomer";
import OpenSeaMap from "../../Components/OpenSeaMapContainer";
import moment from "moment";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import vesselImg from "../../Assets/Images/new_arrow_pointer.png";
// import QuantumLogo from "../Assets/Images/ship-solid-red-resized.png";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet-rotatedmarker';
import L from 'leaflet';
import RotatedMarker from "../../Components/RotatedMarker";
import DmsCoordinates from "dms-conversion";
import DropDownForm from "../../Components/DropDownForm";
import Loading from "../../Components/Loading";
import Accordion from 'react-bootstrap/Accordion';
import { List, Icon1Circle, Search, Circle, Newspaper} from "react-bootstrap-icons";

export default function CustomerDasboardPage() {
    const navigate = useNavigate();
    const [smsSerialNumber, setSmsSerialNumber] = useState('SMS-000-000001');
    const [vesselId, setVesselId] = useState('2d965d55-1311-4214-8383-4b9fb2ee0516');
    const [cookies, setCookie] = useCookies(["token"]);
    const [gpsData, setGpsData] = useState([]);
    const [markerData, setMarkerData] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalDuration, setTotalDuration] = useState();
    const [averageSpeed, setAverageSpeed] = useState(0);
    const [averageMEP, setAverageMEP] = useState(0);
    const [averageMES, setAverageMES] = useState(0);
    const [averageGBP, setAverageGBP] = useState(0);
    const [averageGBS, setAverageGBS] = useState(0);
    const [averageTP, setAverageTP] = useState(0);
    const [averageTS, setAverageTS] = useState(0);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [listVesselData, setListVesselData] = useState([]);
    const [isAutoRefresh, setIsAutoRefresh] = useState(false);
    const [openAccordionSearch, setOpenAccordionSearch] = useState(false);
    const [openAccordionResult, setOpenAccordionResult] = useState(false);
    const [detailVesselData, setDetailVesselData] = useState({
        vessel_type_name: "",
        country_name: "",
        customer_name: "",
        id: "",
        vessel_name: "",
        company_id: "",
        vessel_type_id: "",
        imo_number: "",
        serial_number: "",
        built_date: "",
        built_shipyard: "",
        created_date: "",
        updated_date: "",
        created_by: "",
        updated_by: "",
        is_active: true,
        vessel_code: "",
        vessel_project_counter: 0,
        gross_register_tonnage: 0,
        deadweight_tonnage: 0,
        lbp: 0,
        bmld: 0,
        hmld: 0,
        flag: "",
        callsign: "",
        class_register_number: "",
        customer_id: "",
        ext_customer_id: "",
        sms_serial_number: "",
        shift_session_id: "",
        part_number: ""
    });

    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            //   .utc()
            .subtract(60, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).format("yyyy-MM-DD HH:mm:ss")
    );
    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                loadDetailVesselDataForSidebar();

            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log(listVesselData);
        if (isAutoRefresh) {
            loadCurrentVesselCoordinate();
        }
    }, [listVesselData]);

    useEffect(() => {
        loadDetailVesselData();
    }, [detailVesselData])

    useEffect(() => {
        // if (isAutoRefresh){
        console.log('auto refresh inside useefect', isAutoRefresh);
        if (!isDataReady) {
            setTimeout(() => loadCurrentVesselCoordinate(), 1000);
        }
        // }
    }, [isDataReady])

    useEffect(() => {
        console.log('auto refresh: ', isAutoRefresh);
        if (isAutoRefresh) {
            loadCurrentVesselCoordinate();
        }
        // else {
        //     loadHistorisCoordinate();
        // }
    }, [isAutoRefresh])

    useEffect(() => {
        console.log(markerData);
    }, [markerData])

    useEffect(() => {
        if (gpsData) {
            console.log(gpsData);
            if (gpsData.length > 0) {
                let listMarker = [];
                let lastMarker = null;
                let listLine = [];
                let mepRPMArray = [];
                let idxForDistance = 0;
                let idxForSpeed = 0;
                let idxForMEP = 0;
                let idxForMES = 0;
                let idxForGBP = 0;
                let idxForGBS = 0;
                let idxForTP = 0;
                let idxForTS = 0;
                let totalDistance = 0;
                let totalSpeed = 0;
                let totalMEP = 0;
                let totalMES = 0;
                let totalGBP = 0;
                let totalGBS = 0;
                let totalTP = 0;
                let totalTS = 0;
                let idx = 0;
                gpsData.forEach(data => {
                    const dmsCoords = new DmsCoordinates(parseFloat(data.latitude), parseFloat(data.longitude));
                    
                    let markerEl =
                        <RotatedMarker key={idx} position={[data.latitude, data.longitude]} icon={vesselIcon} rotationAngle={
                            data.course > 359.9 ? (data.course - 359.9).toFixed(1) : data.course
                        } rotationOrigin='center' timseStamp={data.timestamp_gps} speed={parseFloat(data.speed)} 
                        rpmME={[parseFloat(data.rpmPort), parseFloat(data.rpmStarboard)]}
                        rpmGB={[parseFloat(data.rpmGearboxPort), parseFloat(data.rpmGearboxStarboard)]}
                        throttle={[parseFloat(data.throttlePort), parseFloat(data.throttleStarboard)]}>
                            <Popup>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                                    <div style={{ border: '1px white solid', width: '250px', height: '1px', backgroundColor: 'white', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'large', margin: '0px', fontWeight: 'bold' }}>{detailVesselData.vessel_name}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Position'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.latitude.toString()}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.longitude.toString()}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Time'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{data.timestamp_gps}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Speed'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.speed)).toFixed(1)} knot</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'COG'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>
                                                {
                                                    data.course > 359.9 ? (data.course - 359.9).toFixed(1) : data.course
                                                } °
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Main Engine'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmPort)).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmStarboard)).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Propeller'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmGearboxPort)).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmGearboxStarboard)).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttlePort)).toFixed(1)}%</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttleStarboard)).toFixed(1)}%</p>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        </RotatedMarker>;
                    
                    if(lastMarker !== null){
                        let distance = getDistanceFromLatLonInKm(lastMarker.props.position[0], lastMarker.props.position[1], markerEl.props.position[0], markerEl.props.position[1]);
                        let speed = lastMarker.props.speed;
                        let MEPrpm = lastMarker.props.rpmME[0];
                        let MESrpm = lastMarker.props.rpmME[1];
                        let GBPrpm = lastMarker.props.rpmGB[0];
                        let GBSrpm = lastMarker.props.rpmGB[1];
                        let throttleP = lastMarker.props.throttle[0];
                        let throttleS = lastMarker.props.throttle[1];
                        console.log("Distance: ", distance);
                        if (!isNaN(distance)){
                            totalDistance += distance;
                            idxForDistance++;
                        }
                        if (!isNaN(speed)){
                            totalSpeed += speed;
                            idxForSpeed++;
                        }
                        if (!isNaN(MEPrpm)){
                            totalMEP += MEPrpm;
                            // mepRPMArray.push(MEPrpm);
                            idxForMEP++;
                        }
                        if (!isNaN(MESrpm)){
                            totalMES += MESrpm;
                            idxForMES++;
                        }
                        if (!isNaN(GBPrpm)){
                            totalGBP += GBPrpm;
                            idxForGBP++;
                        }
                        if (!isNaN(GBSrpm)){
                            totalGBS += GBSrpm;
                            idxForGBS++;
                        }
                        if (!isNaN(throttleP)){
                            totalTP += throttleP;
                            idxForTP++;
                        }
                        if (!isNaN(throttleS)){
                            totalTS += throttleS;
                            idxForTS++;
                        }

                    }

                    listLine.push(markerEl);
                    console.log('markerE1', markerEl);
                    //console.log("MARKER", markerChild.listMarker[0].props);

                    if (!lastMarker) {
                        listMarker.push(markerEl);
                        lastMarker = markerEl;
                    } else {
                        console.log(lastMarker.props);
                        if (Math.abs(parseFloat(lastMarker.props.rotationAngle) - parseFloat(markerEl.props.rotationAngle)) > 10) {
                            listMarker.push(markerEl);
                        }
                    }

                    idx++;
                    lastMarker = markerEl;
                });
                console.log('PANJANG', listMarker)
                let lastIndexFromListMarker = listMarker.length - 1;
                let startTime = listMarker[0].props.timseStamp;
                let endTime = listMarker[lastIndexFromListMarker].props.timseStamp;
                let getDuration = moment(endTime).diff(moment(startTime))
                let duration = moment.duration(getDuration).format("d[d]  hh:mm:ss");
                let averageSpeed = totalSpeed / idxForSpeed + 1;
                let averageMEP = totalMEP / idxForMEP + 1;
                let averageMES = totalMES / idxForMES + 1;
                let averageGBP = totalGBP / idxForGBP + 1;
                let averageGBS = totalGBS / idxForGBS + 1;
                let averageTP = totalTP / idxForTP + 1;
                let averageTS = totalTS / idxForTP + 1;
                console.log("Duration", duration);
                console.log("Total Distance: ", totalDistance);
                console.log("Average Speed", averageSpeed);
                setAverageSpeed(averageSpeed);
                setStartTime(startTime);
                setEndTime(endTime);
                setTotalDuration(duration);
                setTotalDistance(totalDistance);
                setAverageMEP(averageMEP);
                setAverageMES(averageMES);
                setAverageGBP(averageGBP);
                setAverageGBS(averageGBS);
                setAverageTP(averageTP);
                setAverageTS(averageTS);

                let markerObj = {
                    listVessel: [],
                    listMarker: listMarker,
                    listLine: listLine
                }
                setMarkerData(markerObj);
                setIsDataReady(true);
                setLoading(false);
            } else {
                let listMarker = [];
                let markerObj = {
                    listVessel: [],
                    listMarker: listMarker,
                    listLine: []
                }
                setMarkerData(markerObj);
                setIsDataReady(true);
                setLoading(false);
            }
        }
    }, [gpsData])


    const loadCurrentVesselCoordinate = async () => {
        try {
            const response = await getVesselLocationForCustomer(cookies.token, cookies.extCustomerId);
            if (response) {
                let actualGpsData = response.map((a) => {
                    return {
                        vesselName: a.vessel_name,
                        latitude: a.latitude,
                        longitude: a.longitude,
                        timestamp_gps: moment(a.received_date).format("yyyy-MM-DD HH:mm:ss"),
                        cogCorrection: a.cog_correction,
                        course: (parseFloat(a.course) + parseFloat(a.cog_correction)).toFixed(1),
                        speed: a.speed,
                        rpmPort: a.rpm_port,
                        rpmStarboard: a.rpm_stbd,
                        throttlePort: a.throttle_port,
                        throttleStarboard: a.throttle_stbd,
                        rpmGearboxPort: a.rpm_gearbox_port,
                        rpmGearboxStarboard: a.rpm_gearbox_stbd,
                    };
                });
                setGpsData(actualGpsData);
            }
            if (isAutoRefresh) {
                setIsDataReady(false);
            } else {
                setIsDataReady(true);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDetailVesselDataForSidebar = async () => {
        try {
            let response = await findVesselByIdForCustomerDashboard(cookies.token, vesselId);
            // console.log(response);
            setDetailVesselData({
                vessel_type_name: response[0].vessel_type_name,
                country_name: response[0].country_name,
                customer_name: response[0].customer_name,
                id: response[0].id,
                vessel_name: response[0].vessel_name,
                company_id: response[0].company_id,
                vessel_type_id: response[0].vessel_type_id,
                imo_number: response[0].imo_number,
                serial_number: response[0].serial_number,
                built_date: response[0].built_date,
                built_shipyard: response[0].built_shipyard,
                created_date: response[0].created_date,
                updated_date: response[0].updated_date,
                created_by: response[0].created_by,
                updated_by: response[0].updated_by,
                is_active: response[0].is_activeue,
                vessel_code: response[0].vessel_code,
                vessel_project_counter: response[0].vessel_project_counter,
                gross_register_tonnage: response[0].gross_register_tonnage,
                deadweight_tonnage: response[0].deadweight_tonnage,
                lbp: response[0].lbp,
                bmld: response[0].bmld,
                hmld: response[0].hmld,
                flag: response[0].flag,
                callsign: response[0].callsign,
                class_register_number: response[0].class_register_number,
                customer_id: response[0].customer_id,
                ext_customer_id: response[0].ext_customer_id,
                sms_serial_number: response[0].sms_serial_number,
                shift_session_id: response[0].shift_session_id,
                part_number: response[0].part_number
            });
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadHistorisCoordinate = async () => {
        try {
            setIsAutoRefresh(false);
            setLoading(true);
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            console.log('start', selectStartDate);
            console.log('end', selectEndDate);
            console.log('monthStart', monthStart);
            console.log('monthEnd', monthEnd);
            const response = await getHistorisVesselLocationFromMaterializedView(cookies.token, smsSerialNumber, vesselId, selectStartDate, selectEndDate, monthStart, monthEnd);
            let actualGpsData = response.map((a) => {
                return {
                    latitude: a.latitude,
                    longitude: a.longitude,
                    timestamp_gps: moment(a.received_date).format("yyyy-MM-DD HH:mm:ss"),
                    course: (parseFloat(a.course) + 19).toFixed(1),
                    speed: a.speed,
                    rpmPort: a.mep_rpm,
                    rpmStarboard: a.mes_rpm,
                    throttlePort: a.mep_throttle,
                    throttleStarboard: a.mes_throttle,
                    rpmGearboxPort: parseFloat(a.mep_rpm) / 4.5,
                    rpmGearboxStarboard: parseFloat(a.mes_rpm) / 4.5,
                };
            });
            setGpsData(actualGpsData);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDetailVesselData = async () => {
        try {
            let response = await getVesselForCustomer(cookies.token, cookies.extCustomerId);
            console.log(response);
            setListVesselData(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainVT">
                <Sidebar companyName={detailVesselData.customer_name} smsSerialNumber={detailVesselData.sms_serial_number} vesselId={detailVesselData.id} vesselName={detailVesselData.vessel_name} customerName={cookies.userName} />
                <Container fluid className="mainContainerVT">
                    <div className="childMainContainerVT">
                        <div style={{ width: '100%', backgroundColor: 'rgba(3, 30, 103, 1)', borderRadius: '12px' }}>
                            <ContainerBox
                                titleCaption={detailVesselData.vessel_name + ' VESSEL TRACKING'}
                            />
                        </div>
                        <Button  onClick={() => {
                            setOpenAccordionSearch(!openAccordionSearch) 
                            setOpenAccordionResult(false)}} className='btnStyleForAccordion'>
                            <Search size={30} style = {{padding: '5px'}}/>
                        </Button>
                        <Collapse in={openAccordionSearch}>
                            <div className="containerForAccordion">
                                <div className="formDateForVesselTracking">
                                    <Form.Group className="mb-3" style={{width: '100%'}}>
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            onChange={(e) => {
                                                setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                            }}
                                            value={
                                                selectStartDate
                                                    ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                    : ""
                                            }
                                        ></Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-3" style={{width: '100%'}}>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            onChange={(e) => {
                                                setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                            }}
                                            value={
                                                selectEndDate
                                                    ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                    : ""
                                            }
                                        ></Form.Control>
                                    </Form.Group>

                                    <Form.Group style={{width: '100%'}}>
                                        <DropDownForm
                                            caption={"Duration"}
                                            placeholder={"Select Time ..."}
                                            listOption={durationOption}
                                            valueKey={"value"}
                                            labelKey={"label"}
                                            setValueFunction={(e) => {
                                                console.log(e);
                                                try {
                                                    let dateTime = new Date();
                                                    if (e.target.value === "1") {
                                                        console.log("1");
                                                        let startDate = moment(dateTime).subtract(60, "minutes").format("yyyy-MM-DD HH:mm:ss");
                                                        let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                        setSelectStartDate(startDate);
                                                        setSelectEndDate(endDate);
                                                    } else if (e.target.value === "2") {
                                                        console.log("2");
                                                        let startDate = moment(dateTime).subtract(1, "day").format("yyyy-MM-DD HH:mm:ss");
                                                        let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                        setSelectStartDate(startDate);
                                                        setSelectEndDate(endDate);
                                                        console.log("tes", startDate, endDate);
                                                    } else if (e.target.value === "3") {
                                                        console.log("3");
                                                        let startDate = moment(dateTime).subtract(1, "week").format("yyyy-MM-DD HH:mm:ss");
                                                        let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                        setSelectStartDate(startDate);
                                                        setSelectEndDate(endDate);
                                                    }
                                                } catch (exception) {
                                                    console.log(exception);
                                                }
                                            }}
                                        />
                                    </Form.Group>

                                    <Button
                                        style={{ width: '100%', margin: '10px 0'}}
                                        onClick={() => {
                                            loadHistorisCoordinate();
                                        }}
                                    >
                                        <div className="btnContainer">
                                            <div className="btnContainerRight">{'Vessel Tracking Detail'}</div>
                                        </div>
                                    </Button>

                                    <Button
                                        style={{ width: '100%', margin: '10px 0'}}
                                        onClick={() => {
                                            // setIsAutoRefresh(true);
                                            loadCurrentVesselCoordinate();
                                        }}
                                    >
                                        <div className="btnContainer">
                                            <div className="btnContainerRight">{'Vessel Current Position'}</div>
                                        </div>
                                    </Button>
                                    
                                </div>
                            </div>
                        </Collapse>
                        <Button onClick={() => {
                            setOpenAccordionResult(!openAccordionResult)
                            setOpenAccordionSearch(false)}} className='btnStyleForAccordionResult'>
                            <Newspaper size={30} style = {{padding: '5px'}}/>
                        </Button>
                        <Collapse in={openAccordionResult}>
                            <div className="containerForAccordionResult">
                                <div className="containerForAccordionResultHeader">
                                    <p className="titleForcontainerForAccordionResultHeader">{detailVesselData.vessel_name}</p>
                                </div>
                                <div className="containerForAccordionResultBody">
                                <div className="containerForAccordionResultBodyTop">
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{' AVG SPEED'}</p>
                                            <p className="txtFordataonAccordionResult"> {!isNaN(averageSpeed) ? `${averageSpeed.toFixed(1)} KNOT` : `N/A`} </p>
                                        </div>
                                        <div style={{width: '1px', border: '1px rgb(201, 201, 201) solid', height: '100%'}}></div>
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'TOTAL DISTANCE'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(totalDistance) ? `${parseFloat(totalDistance).toFixed(1)} KM` : `N/A`} | {!isNaN(totalDistance) ? `${(parseFloat(totalDistance) / 1.852).toFixed(1)} NM` : `N/A`}</p>
                                        </div>
                                    </div>
                                    <div className="containerForAccordionResultBodyTop">
                                        <Circle size={40} style={{flex:2}}/>
                                        <div style={{width: '100%', border: '1px rgb(201, 201, 201) solid', borderStyle: 'dashed', flex: 1}}></div>
                                        <Circle size={40} style={{flex:2}}/>
                                    </div>
                                    <div className="containerForAccordionResultBodyTop">
                                        <p className="txtFordataonAccordionResult" style={{textAlign: 'center'}}>START <br/>{moment(startTime).format("YYYY-MM-DD")} <br/> {moment(startTime).format("HH:mm:ss")}</p>
                                        <p className="txtFordataonAccordionResult">{">>"}</p>
                                        <p className="txtFordataonAccordionResult" style={{textAlign: 'center'}}>END <br/>{moment(endTime).format("YYYY-MM-DD")} <br/> {moment(endTime).format("HH:mm:ss")}</p>
                                    </div>
                                    <p className="txtFordataonAccordionResult" style={{fontSize: 'medium', textAlign: 'center', margin: '5px 0'}}>DURATION <br/>{totalDuration}</p>
                                    <p className="txtFordataonAccordionResult" style={{fontSize: 'medium', textAlign: 'center', margin: '5px 0'}}>{'AVERAGE MAIN ENGINE RPM'}</p>
                                    <div className="containerForAccordionResultBodyTop">
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'PORT'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(averageMEP) ? `${parseFloat(averageMEP).toFixed(1)} RPM` : `N/A`}</p>
                                        </div>
                                        <div style={{width: '1px', border: '1px rgb(201, 201, 201) solid', height: '100%'}}></div>
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'STARBOARD'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(averageMES) ? `${parseFloat(averageMES).toFixed(1)} RPM` : `N/A`}</p>
                                        </div>
                                    </div>
                                    <p className="txtFordataonAccordionResult" style={{fontSize: 'medium', textAlign: 'center', margin: '5px 0'}}>{'AVERAGE PROPELLER RPM'}</p>
                                    <div className="containerForAccordionResultBodyTop">
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'PORT'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(averageGBP) ? `${parseFloat(averageGBP).toFixed(1)} RPM` : `N/A`}</p>
                                        </div>
                                        <div style={{width: '1px', border: '1px rgb(201, 201, 201) solid', height: '100%'}}></div>
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'STARBOARD'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(averageGBS) ? `${parseFloat(averageGBS).toFixed(1)} RPM` : `N/A`}</p>
                                        </div>
                                    </div>
                                    <p className="txtFordataonAccordionResult" style={{fontSize: 'medium', textAlign: 'center', margin: '5px 0'}}>{'AVERAGE THROTTLE PERCENTAGE'}</p>
                                    <div className="containerForAccordionResultBodyTop">
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'PORT'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(averageTP) ? `${parseFloat(averageTP).toFixed(1)} %` : `N/A`}</p>
                                        </div>
                                        <div style={{width: '1px', border: '1px rgb(201, 201, 201) solid', height: '100%'}}></div>
                                        <div className="containerForAccordionResultBodyTopEachData">
                                            <p className="txtFordataonAccordionResult">{'STARBOARD'}</p>
                                            <p className="txtFordataonAccordionResult">{!isNaN(averageTS) ? `${parseFloat(averageTS).toFixed(1)} %` : `N/A`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <div style={{ width: '100%' }}>
                            {
                                <OpenSeaMap markerChild={markerData} isTracking={true} />
                            }
                        </div>
                    </div>
                </Container>
                <div className="loadingOverlay">
                    <Loading loading={loading} />
                </div>
            </div>

        </>
    )
}
