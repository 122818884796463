import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getSparepartCatalogById, insertUpdateSparepartCatalog, getEquipment,
    getSparepartParameter, getParameterEquipment, insertUpdateSparepartCatalogDocument,
    getSparepartCatalogDocument, convertBase64, deleteSparepartCatalogDocument, getEquipmentPosition
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import Modals from "../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import ButtonForm from "../../../../Components/ButtonForm";
import FormSelect from "../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import moment from 'moment';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function SparepartCatalogDetailPage() {
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
    const [listDocument, setListDocument] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [parameter, setParameter] = useState([]);
    const [sparepartCatalog, setSparepartCatalog] = useState({
        id: 0,
        equipment_id: null,
        title: "",
        description: "",
        model: "",
        manufacture_name: "",
        equipment_position_id: null,
    });

    const [documentId, setDocumentId] = useState("");
    const [sparepartCatalogId, setSparepartCatalogId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(1);
    const [newDocument, setNewDocument] = useState({
        id: 0,
        sparepart_catalog_id: "",
        document_base64: "",
        document_name: "",
        description: "",
        is_active: true,
        done: false,
        reference_number: ""
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        sparepart_catalog_id: "",
        document_base64: "",
        document_name: "",
        description: "",
        reference_number: "",
        is_active: true,
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            sparepart_catalog_id: sparepartCatalogId,
            document_base64: "",
            document_name: "",
            description: "",
            reference_number: "",
            is_active: true,
            done: false
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    const [documentFile, setDocumentFile] = useState("");

    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...parameter];
        list[index][name] = value;
        setParameter(list);

    }

    const handleremove = index => {
        const list = [...parameter];
        list.splice(index, 1);
        setParameter(list);
    }

    const handleaddclick = () => {
        setParameter([...parameter, { parameter_name: '', parameter_value: '', parameter_description: '' }]);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    useEffect(() => {
        if (parameter.length > 0) {
            for (let index = 0; index < parameter.length; index++) {
                const listParameter = parameter[index];
                if (listParameter.parameter_name === "") {
                    // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                    setErrorMessage("*Harus Diisi")
                    setDisabledButton(true);
                }
                else {
                    setErrorMessage("");
                    setDisabledButton(false);
                }
            }
        }
    }, [parameter])

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        setLoading(true);
        if (sparepartCatalogId !== "")
            removeDocument();
    }, [documentId]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        setNewDocument({ ...newDocument, sparepart_catalog_id: sparepartCatalogId });

        setLoading(false);
        loadDocumentData();
    }, [sparepartCatalogId])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadEquipment();
                setSparepartCatalogId(location.state.sparepartCatalogId);
                loadEquipmentPosition();
            }
        }
        checkCookies();
        // if (location.state.sparepartCatalogId === 0) {
        //     setLoading(false);
        // } else {
        //     initSparepartCatalog();
        // }
    }, []);

    useEffect(() => {
        if (sparepartCatalogId !== 0)
            initSparepartCatalog()
    }, [sparepartCatalogId])

    useEffect(() => {
        if (sparepartCatalogId === 0) {
            loadParameterEquipment();
        }
    }, [sparepartCatalog.equipment_id]);

    const loadParameterEquipment = async () => {
        try {
            let param = await getParameterEquipment(cookies.token, sparepartCatalog.equipment_id);
            setParameter(param);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            let listEquipment = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].equipment_name,
                    id: response[i].id

                };

                listEquipment.push(obj);

            }
            setEquipmentOptions(listEquipment);
        } catch (exception) {
            console.log(exception);

        }
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateSparepartCatalog(cookies.token, sparepartCatalog);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data');
                if (sparepartCatalogId === 0) {
                    setSparepartCatalogId(response.data.id);
                } else {
                    initSparepartCatalog()
                }
            } else {
                alert('Gagal Menyimpan Data');
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initSparepartCatalog = async () => {
        try {
            let response = await getSparepartCatalogById(cookies.token, sparepartCatalogId);
            if (response) {
                setSparepartCatalog({
                    ...sparepartCatalog,
                    id: response[0].id,
                    equipment_id: response[0].equipment_id,
                    title: response[0].title,
                    description: response[0].description,
                    model: response[0].model,
                    manufacture_name: response[0].manufacture_name,
                    equipment_position_id: response[0].equipment_position_id
                })
            }

            let param = await getSparepartParameter(cookies.token, response[0].id);
            setParameter(param);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateSparepartCatalogDocument(cookies.token, newDocument);
                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const loadDocumentData = async () => {
        try {
            let response = await getSparepartCatalogDocument(cookies.token, sparepartCatalogId);
            setListDocument(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteSparepartCatalogDocument(cookies.token, documentId);
        if (response) {
            loadDocumentData();
        } else {
            alert("Terjadi kesalahan sistem, mohon hubungi administrator.");
            setLoading(false);
        }
    }

    const loadEquipmentPosition = async () => {
        try {
            let response = await getEquipmentPosition(cookies.token);
            setListEquipmentPosition(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Katalog Suku Cadang"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/SparepartCatalog"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingTop: 15
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Detail Katalog"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>

                                                            <FormSelect caption="Nama Peralatan" autoFocusValue={sparepartCatalog.equipment_id === undefined} placeholder="Pilih Peralatan..."
                                                                options={equipmentOptions} value={equipmentOptions.filter(function (option) {
                                                                    return option.id === sparepartCatalog.equipment_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setSparepartCatalog({ ...sparepartCatalog, equipment_id: e.id });
                                                                    } else {
                                                                        setSparepartCatalog({ ...sparepartCatalog, equipment_id: null });
                                                                    }

                                                                }} disabledForm={sparepartCatalogId === 0 ? false : true}
                                                            />
                                                            <TextBox value={sparepartCatalog.title} placeholder="Titel"
                                                                caption="Titel" setValueFunction={(e) => {
                                                                    setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                }} />
                                                            <TextBox value={sparepartCatalog.description} placeholder="Deskripsi"
                                                                caption="Deskripsi" setValueFunction={(e) => {
                                                                    setSparepartCatalog({ ...sparepartCatalog, description: e.target.value });
                                                                }} />
                                                            {/* {
                                                                errors.equipmentName && <p style={{ color: "red" }}>{errors.equipmentName}</p>
                                                            } */}

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            paddingLeft: 10
                                                        }}>
                                                            <TextBox value={sparepartCatalog.model} placeholder="Model"
                                                                caption="Model" setValueFunction={(e) => {
                                                                    setSparepartCatalog({ ...sparepartCatalog, model: e.target.value });
                                                                }} />
                                                            {/* {
                                                                errors.equipmentTitle && <p style={{ color: "red" }}>{errors.equipmentTitle}</p>
                                                            } */}
                                                            <TextBox value={sparepartCatalog.manufacture_name} placeholder="Nama Manufaktur"
                                                                caption="Nama Manufaktur" setValueFunction={(e) => {
                                                                    setSparepartCatalog({ ...sparepartCatalog, manufacture_name: e.target.value });
                                                                }} />

                                                            <Form.Group>
                                                                <Form.Label>Posisi Peralatan</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    let selectedEquipPosition = listEquipmentPosition.find(p => p.id === e.target.value);
                                                                    if (selectedEquipPosition !== undefined) {
                                                                        setSparepartCatalog({ ...sparepartCatalog, equipment_position_id: e.target.value })
                                                                    } else {
                                                                        setSparepartCatalog({ ...sparepartCatalog, equipment_position_id: null })
                                                                    }

                                                                }} value={sparepartCatalog.equipment_position_id} >
                                                                    <option selected value="">
                                                                        Pilih Posisi...
                                                                    </option>
                                                                    {listEquipmentPosition.map((position, index) => (
                                                                        <option key={index} value={position.id}>{position.position}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            sparepartCatalogId !== 0 || parameter.length !== 0 ?
                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Parameter Detail"} childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>

                                                        {
                                                            parameter.length === 0 ?
                                                                <></>
                                                                :
                                                                <Table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Nama Parameter</th>
                                                                            <th>Nilai Parameter</th>
                                                                            <th>Deskripsi Parameter</th>
                                                                            <th>Hapus</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {
                                                                            parameter.map((x, i) => {
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            parameter.length === 0 ?
                                                                                                <></>
                                                                                                :

                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control>

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control name="parameter_value" value={x.parameter_value} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nilai Parameteer"></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control name="parameter_description" value={x.parameter_description} type="text" onChange={e => handleinputchange(e, i)} placeholder="Deskripsi Parameter"></Form.Control>
                                                                                                    </td>
                                                                                                    <td>

                                                                                                        {
                                                                                                            parameter.length >= 1 &&
                                                                                                            <Button variant="danger" onClick={() => {
                                                                                                                if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                                    handleremove(i)
                                                                                                                }
                                                                                                            }}><Trash /></Button>
                                                                                                        }

                                                                                                    </td>
                                                                                                </tr>
                                                                                        }


                                                                                    </>

                                                                                )


                                                                            })
                                                                        }

                                                                    </tbody>
                                                                    {
                                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                                    }
                                                                </Table>
                                                        }

                                                        {
                                                            sparepartCatalogId === 0 ?
                                                                <></>
                                                                :
                                                                <center><Button className="add-button" disabled={disabledButton} variant="primary" onClick={handleaddclick}>Tambah Parameter</Button></center>
                                                        }
                                                    </div>

                                                }>
                                                </ContainerBox>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {sparepartCatalogId !== 0 ? <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Dokumen"}
                                            useActionContainer={true}
                                            actionContainerChild={
                                                <div>
                                                    <Button variant="secondary" onClick={() => {
                                                        setShowDocumentUploadModal(true);
                                                    }} style={{

                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            alignSelf: "center",
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                flex: 1,
                                                            }}><FilePlusFill size={20} /></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 8,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                paddingLeft: 5,
                                                                fontWeight: "bold",
                                                                fontSize: 12,
                                                            }}>Tambah Dokumen</div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            }
                                            childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>

                                                        <div className="table-container">
                                                            <div className="table-header">
                                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                <div style={{ flex: 4 }} className="table-header-content">Nama Dokumen</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">Nomor Referensi</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">Tanggal Upload</div>
                                                                <div style={{ flex: 2 }} className="table-header-content">Lihat Dokumen</div>
                                                                <div style={{ flex: 2 }} className="table-header-content">Download</div>
                                                                <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                                            </div>
                                                            {
                                                                listDocument.map((document, index) => {
                                                                    return (<div key={index} className="table-body">
                                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button variant="primary" onClick={() => {
                                                                                    setDocumentToBeViewed(document);
                                                                                }}><EyeFill />
                                                                                </Button>
                                                                            </div></div>
                                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button variant="primary" onClick={() => {
                                                                                    triggerBase64Download(document.document_base64, document.document_name);
                                                                                }}><Download />
                                                                                </Button>
                                                                            </div></div>
                                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button variant="danger" onClick={() => {
                                                                                    if (window.confirm(`Apakah Anda Yakin Menghapus  ${document.document_name}?`)) {
                                                                                        let documentId = document.id
                                                                                        setDocumentId(documentId);
                                                                                    }
                                                                                }}><Trash />
                                                                                </Button>
                                                                            </div></div>
                                                                    </div>)
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                        <div style={{
                                                            paddingTop: 20,
                                                        }}></div>
                                                    </div>


                                                </div>
                                            }>
                                        </ContainerBox> : <></>}

                                    </div>
                                </div>
                            </div>

                            <ButtonForm addData={() => {
                                saveData();
                                setDisabledButton(true);
                            }} button={disabledButton} />
                        </div>

                    }>


                </ContainerBox>

                <Modal show={showDocumentUploadModal} >
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Upload Dokumen`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setNewDocument({ ...newDocument, done: true });
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>File Dokumen</Form.Label>
                                            <Form.Control onChange={async (e) => {
                                                setDocumentFile(e.target.value)
                                                let base64Doc = await convertBase64(e.target.files[0]);
                                                setNewDocument({ ...newDocument, document_base64: base64Doc.toString() });
                                            }} type="file" required></Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Dokumen</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                                }} value={newDocument.document_name} type="text" placeholder="Nama Dokumen" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nomor Refensi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewDocument({ ...newDocument, reference_number: e.target.value })
                                                }} value={newDocument.reference_number} type="text" placeholder="Nomor Referensi" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Deskripsi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setNewDocument({ ...newDocument, description: e.target.value })
                                                }} value={newDocument.description} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit">
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            resetUploadForm();
                                            loadDocumentData();
                                            setShowDocumentUploadModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />


                </Modal>

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name} childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed.document_base64}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                        Halaman {pageNumber} dari {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.8);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(1);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 2);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

                </Modal>

            </Container>
            <Loading
                loading={loading}
            />



        </>
    );

}