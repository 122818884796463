import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid,
    getPortalContentById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import moment from 'moment';
import { convert } from "html-to-text";


export default function BlogDetailPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const options = {
        wordwrap: 130
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [detailContent, setDetailContent] = useState({});
    const [contentId, setContentId] = useState("");




    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                //moment.locale('id');
                setContentId(searchParams.get("id"));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (contentId !== "" && contentId !== null)
            loadContentDetail();
    }, [contentId])

    const loadContentDetail = async () => {
        try {
            let response = await getPortalContentById(cookies.token, contentId);
            setDetailContent(response);
        } catch (exception) {

        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                width: "90%"
            }}>

                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start"
                    }}>
                        <h2 className="dashboard-main-header">{`Selamat Datang, ${cookies.userName} !`}</h2>
                    </div>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end"
                    }}>
                        <Clock
                            className="dashboard-main-header"
                            format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                            ticking={true}
                            timezone={'Asia/Jakarta'} />
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10,
                        width: "100%",
                        flexWrap: "nowrap"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: 38,
                                borderBottomStyle: "groove"
                            }}> <div style={{textAlign:"center"}} dangerouslySetInnerHTML={{ __html: detailContent.content_title }} ></div></div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: 10

                            }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>{detailContent.login_username}</div>
                                <div style={{ display: "flex", justifyContent: "center" }}>{moment(detailContent.content_date).format("DD-MMMM-yyyy")}</div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                flexWrap: "nowrap",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingBottom: 10
                            }}>
                                <img src={detailContent.content_photo} width={"70%"}></img>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 5,
                                width: "70%",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                alignSelf: "center",

                            }}>
                                <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: detailContent.content_description }} ></div>
                            </div>
                        </div>

                    </div>

                </div>



                <Loading
                    loading={loading}
                />
            </Container>
        </>
    );

}