import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getReadingValueForFuelConsumption, getReadingValueForFuelConsumptionAverageCurrentLoadFromMaterializedView, getReadingValueForFuelConsumptionFromMaterializedView, getReadingValueForFuelConsumptionAverageAndCurrentInterval } from "../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { FiletypePdf, FiletypeCsv, ClipboardData, Printer } from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../Components/DropDownForm";
import DynamicLineChart from "../../Components/DynamicLineChartForFuelConsumption"
import DynamicLineChartCurrent from "../../Components/DynamicLineChartForCurrentFuelUsage"
import DynamicLineChartDoubleAxis from "../../Components/DynamicLineChartForFuelConsumptionAvgAndCurrent"
import moment from "moment";
import { CSVLink } from "react-csv";
import WMI from "../../Assets/Images/CustomerDashboard/WMI_logo.png";
import { constructFrom } from "date-fns";

export default function CustomerFuelConsumptionPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [fuelMEP, setFuelMEP] = useState([]);
    const [fuelMES, setFuelMES] = useState([]);
    const [fuelAEP, setFuelAEP] = useState([]);
    const [fuelAES, setFuelAES] = useState([]);
    const [fuelAvgMEP, setFuelAvgMEP] = useState([]);
    const [fuelAvgMES, setFuelAvgMES] = useState([]);
    const [fuelAvgAEP, setFuelAvgAEP] = useState([]);
    const [fuelAvgAES, setFuelAvgAES] = useState([]);
    const [fuelCurrentMEP, setFuelCurrentMEP] = useState([]);
    const [fuelCurrentMES, setFuelCurrentMES] = useState([]);
    const [fuelCurrentAEP, setFuelCurrentAEP] = useState([]);
    const [fuelCurrentAES, setFuelCurrentAES] = useState([]);
    const [engineLoadMEP, setEngineLoadMEP] = useState([]);
    const [engineLoadMES, setEngineLoadMES] = useState([]);
    const [engineLoadAEP, setEngineLoadAEP] = useState([]);
    const [engineLoadAES, setEngineLoadAES] = useState([]);
    const [summaryAverageMEP, setSummaryAverageMEP] = useState(0);
    const [summaryAverageMES, setSummaryAverageMES] = useState(0);
    const [summaryAverageAEP, setSummaryAverageAEP] = useState(0);
    const [summaryAverageAES, setSummaryAverageAES] = useState(0);
    
    const [summaryCurrentMEP, setSummaryCurrentMEP] = useState(0);
    const [summaryCurrentMES, setSummaryCurrentMES] = useState(0);
    const [summaryCurrentAEP, setSummaryCurrentAEP] = useState(0);
    const [summaryCurrentAES, setSummaryCurrentAES] = useState(0);


    const [summaryAverageEngineLoadAEP, setSummaryAverageEngineLoadAEP] = useState(0);
    const [summaryAverageEngineLoadAES, setSummaryAverageEngineLoadAES] = useState(0);
    const [useDropDown, setUseDropDown] = useState(false);
    const [intervalTime, setIntervalTime] = useState(0);
    const [intervalUnit, setIntervalUnit] = useState('');
    const [modCount, setModCount] = useState(0);
    const [dataToShow, setDataToShow] = useState([]);
    const [dataToShowAvgCurr, setDataToShowAvgCurr] = useState([]);
    const [isPrint, setIsPrint] = useState(false);
    const [compiledDataForReport, setCompiledDataForReport] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);


    // const headersforCSV = [
    //     { label: "Received Date", key: "0" },
    //     { label: "main_engine_port", key: "1" },
    //     { label: "main_engine_stbd", key: "2" },
    //     { label: "aux_engine_port", key: "3" },
    //     { label: "aux_engine_stbd", key: "4" },
    //     { label: "me_average_fuel_consumption_port", key: "5" },
    //     { label: "me_average_fuel_consumption_stbd", key: "6" },
    //     { label: "ae_average_fuel_consumption_port", key: "7" },
    //     { label: "ae_average_fuel_consumption_stbd", key: "8" },

    //     { label: "me_current_fuel_consumption_port", key: "9" },
    //     { label: "me_current_fuel_consumption_stbd", key: "10" },
    //     { label: "ae_current_fuel_consumption_port", key: "11" },
    //     { label: "ae_current_fuel_consumption_stbd", key: "12" },

    //     { label: "ae_engine_load_port", key: "13" },
    //     { label: "ae_engine_load_stbd", key: "14" }
    // ];
    
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])



    useEffect(() => {
        // loadFuelConsumption();
    }, [vesselId])

    useEffect(() => {
        if (isPrint) {
            printFuelConsumptionData();
        }
    }, [isPrint])

    // useEffect(() => {
    //     console.log("interval", intervalTime);
    //     console.log("mod", modCount);
    // }, [useDropDown])

    useEffect(() => {
        console.log('FUEL MEP', fuelMEP);
        console.log('FUEL MES', fuelMES);
        console.log('FUEL AEP', fuelAEP);
        console.log('FUEL AES', fuelAES);
        // setLoading(false);
    }, [fuelMEP])

    useEffect(() => {
        if (engineLoadAES){
            setIsDataReady(true);
            setLoading(false);
        }
    }, [engineLoadAES])

    useEffect(() => {
        console.log('data to show total fuel->', dataToShow);
        if (dataToShow) {

            let fuelMEP = dataToShow.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 19);
            let fuelMES = dataToShow.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 19);
            let fuelAEP = dataToShow.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 19);
            let fuelAES = dataToShow.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 19);

            setFuelMEP(fuelMEP);
            setFuelMES(fuelMES);
            setFuelAEP(fuelAEP);
            setFuelAES(fuelAES);
            
        }
        loadFuelConsumptionAvgAndCurrent();
    }, [dataToShow])

    useEffect(() => {
        console.log("data to show avg curr engine load->", dataToShowAvgCurr);
        if (dataToShowAvgCurr) {
            let compileDataForReport = [];
            let fuelAvgMEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 17),
            summaryAvgMEP = fuelAvgMEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgMEP.length;
            let fuelAvgMES = dataToShowAvgCurr.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 17),
            summaryAvgMES = fuelAvgMES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgMES.length;
            let fuelAvgAEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 17),
            summaryAvgAEP = fuelAvgAEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgAEP.length;
            let fuelAvgAES = dataToShowAvgCurr.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 17),
            summaryAvgAES = fuelAvgAES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgAES.length;
            
            let fuelCurrentMEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 13),
            summaryCurrMEP = fuelCurrentMEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentMEP.length;
            let fuelCurrentMES = dataToShowAvgCurr.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 13),
            summaryCurrMES = fuelCurrentMES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentMES.length;
            let fuelCurrentAEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 18),
            summaryCurrAEP = fuelCurrentAEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentAEP.length;
            let fuelCurrentAES = dataToShowAvgCurr.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 18),
            summaryCurrAES = fuelCurrentAES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentAES.length;
            
            let eLoadAEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 20),
            summaryAvgeLoadAEP = eLoadAEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / eLoadAEP.length;
            let eLoadAES = dataToShowAvgCurr.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 20),
            summaryAvgeLoadAES = eLoadAES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / eLoadAES.length;

            setFuelAvgMEP(fuelAvgMEP);
            setFuelAvgMES(fuelAvgMES);
            setFuelAvgAEP(fuelAvgAEP);
            setFuelAvgAES(fuelAvgAES);
            setSummaryAverageMEP(summaryAvgMEP);
            setSummaryAverageMES(summaryAvgMES);
            setSummaryAverageAEP(summaryAvgAEP);
            setSummaryAverageAES(summaryAvgAES);
            setFuelCurrentMEP(fuelCurrentMEP);
            setFuelCurrentMES(fuelCurrentMES);
            setFuelCurrentAEP(fuelCurrentAEP);
            setFuelCurrentAES(fuelCurrentAES);

            setSummaryCurrentMEP(summaryCurrMEP);
            setSummaryCurrentMES(summaryCurrMES);
            setSummaryCurrentAEP(summaryCurrAEP);
            setSummaryCurrentAES(summaryCurrAES);

            setEngineLoadAEP(eLoadAEP);
            setEngineLoadAES(eLoadAES);
            setSummaryAverageEngineLoadAEP(summaryAvgeLoadAEP);
            setSummaryAverageEngineLoadAES(summaryAvgeLoadAES);
        }
    }, [dataToShowAvgCurr])

    const loadFuelConsumption = async () => {
        try {
            setLoading(true);
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            let results = await getReadingValueForFuelConsumptionFromMaterializedView(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId);
            setDataToShow(results);
            // if (useDropDown === true){
            //     let resultsInterval = await getReadingValueForFuelConsumptionInterval(cookies.token, smsSerialNumber, vesselId, intervalTime, modCount);
            //     setDataToShow(resultsInterval);
            // } else {

            // }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const generateFuelConsumptionCSV = () => {
        let compileDataForReport = [];
        let fuelMEP = [];
        let fuelMES = [];
        let fuelAEP = [];
        let fuelAES = [];
        
        let fuelAvgMEP = [];
        let fuelAvgMES = [];
        let fuelAvgAEP = [];
        let fuelAvgAES = [];
        let fuelCurrentMEP = [];
        let fuelCurrentMES = [];
        let fuelCurrentAEP = [];
        let fuelCurrentAES = [];
        let eLoadAEP = [];
        let eLoadAES = [];
        let arrTotalFuelLength = [];
        let arrAvgCurrentFuelLength = [];

        let listAllFuelConsumptionData = [];
        if (dataToShow) {
            if (dataToShow.length > 0) {
                fuelMEP = dataToShow.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 19);
                fuelMES = dataToShow.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 19);
                fuelAEP = dataToShow.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 19);
                fuelAES = dataToShow.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 19);

                listAllFuelConsumptionData.push(fuelMEP);
                listAllFuelConsumptionData.push(fuelMES);
                listAllFuelConsumptionData.push(fuelAEP);
                listAllFuelConsumptionData.push(fuelAES);
                arrTotalFuelLength = [fuelMEP.length, fuelMES.length, fuelAEP.length, fuelAES.length];
            }
        }


        if (dataToShowAvgCurr) {
            if (dataToShowAvgCurr.length > 0) {
                fuelAvgMEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 17);
                fuelAvgMES = dataToShowAvgCurr.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 17);
                fuelAvgAEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 17);
                fuelAvgAES = dataToShowAvgCurr.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 17);
                fuelCurrentMEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 13);
                fuelCurrentMES = dataToShowAvgCurr.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 13);
                fuelCurrentAEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 18);
                fuelCurrentAES = dataToShowAvgCurr.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 18);
                eLoadAEP = dataToShowAvgCurr.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 20);
                eLoadAES = dataToShowAvgCurr.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 20);

                listAllFuelConsumptionData.push(fuelAvgMEP);
                listAllFuelConsumptionData.push(fuelAvgMES);
                listAllFuelConsumptionData.push(fuelAvgAEP);
                listAllFuelConsumptionData.push(fuelAvgAES);
                listAllFuelConsumptionData.push(fuelCurrentMEP);
                listAllFuelConsumptionData.push(fuelCurrentMES);
                listAllFuelConsumptionData.push(fuelCurrentAEP);
                listAllFuelConsumptionData.push(fuelCurrentAES);
                listAllFuelConsumptionData.push(eLoadAEP);
                listAllFuelConsumptionData.push(eLoadAES);

                arrAvgCurrentFuelLength = [fuelAvgMEP.length, fuelAvgMES.length, fuelAvgAEP.length, fuelAvgAES.length,
                    fuelCurrentMEP.length, fuelCurrentMES.length, fuelCurrentAEP.length, fuelCurrentAES.length, fuelCurrentAES.length,
                    eLoadAEP.length, eLoadAES.length
                ];
            }
        }

        
        arrTotalFuelLength = arrTotalFuelLength.concat(arrAvgCurrentFuelLength);
        arrTotalFuelLength.sort(function(a, b){return a.length - b.length});

        console.log(arrTotalFuelLength);
        arrTotalFuelLength.reverse();
        console.log('listAllFuelConsumptionData',listAllFuelConsumptionData);
        let tmpArrIdx = listAllFuelConsumptionData.findIndex(p=>p.length===arrTotalFuelLength[0]);
        let tmpArr = listAllFuelConsumptionData[tmpArrIdx];

        let csvHeaders = ['received_date',
            'main_engine_port',
            'main_engine_stbd',
            'aux_engine_port',
            'aux_engine_stbd',
            'me_average_fuel_consumption_port',
            'me_average_fuel_consumption_stbd',
            'ae_average_fuel_consumption_port',
            'ae_average_fuel_consumption_stbd',
            'me_current_fuel_consumption_port',
            'me_current_fuel_consumption_stbd',
            'ae_current_fuel_consumption_port',
            'ae_current_fuel_consumption_stbd',
            'ae_engine_load_port',
            'ae_engine_load_stbd',
        ]
        let listCSV = [];
        listCSV.push(csvHeaders);
        for(let i=0;i<tmpArr.length;i++){
            let csvElement = [];
            csvElement.push(tmpArr[i].received_date);
            for(let j=0;listAllFuelConsumptionData.length>j;j++){
                let validValue = listAllFuelConsumptionData[j].find(p=>p.received_date === csvElement[0]);
                if(validValue){
                    csvElement.push(validValue.reading_value);
                } else{
                    csvElement.push(null);
                }
            }
            csvElement[0] = moment(csvElement[0]).local().format("YYYY-MM-DD HH:mm:ss");
            listCSV.push(csvElement);
        }
        console.log(listCSV);
        setCompiledDataForReport(listCSV);
    }

    const loadFuelConsumptionAvgAndCurrent = async () => {
        try {
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            let resultsAvgCurrent = await getReadingValueForFuelConsumptionAverageCurrentLoadFromMaterializedView(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId);
            setDataToShowAvgCurr(resultsAvgCurrent);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const printFuelConsumptionData = () => {
        var prtContent = document.getElementById("printFuelConsumption");
        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"
        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `Fuel Consumption`;
        WinPrint.print();
        setLoading(false);
        setIsPrint(false);
    }
    return (
        <>
            <div className="mainFuelConsumptionPage">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerFuelConsumption">
                    <div className="innerChildMainContainerFuelConsumption">
                        <ContainerBox
                            titleCaption={vesselName + ' FUEL CONSUMPTION'}
                            childContent={
                                <>
                                    <div className="formDateForFuelConsumption">
                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectStartDate(e.target.value);
                                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                    setUseDropDown(false);
                                                }}
                                                value={
                                                    selectStartDate
                                                        ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectEndDate(e.target.value);
                                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                    setUseDropDown(false);
                                                }}
                                                value={
                                                    selectEndDate
                                                        ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ margin: "10px" }}>
                                            <DropDownForm
                                                caption={"Time"}
                                                placeholder={"Select Time ..."}
                                                listOption={durationOption}
                                                valueKey={"value"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    console.log(e);
                                                    try {
                                                        let dateTime = new Date();
                                                        if (e.target.value === "1") {
                                                            console.log("1");
                                                            let startDate = moment(dateTime).subtract(60, "minutes").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        } else if (e.target.value === "2") {
                                                            console.log("2");
                                                            let startDate = moment(dateTime).subtract(1, "day").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                            console.log("tes", startDate, endDate);
                                                        } else if (e.target.value === "3") {
                                                            console.log("3");
                                                            let startDate = moment(dateTime).subtract(1, "week").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        }
                                                    } catch (exception) {
                                                        console.log(exception);
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                loadFuelConsumption();
                                            }}
                                        >
                                            <div className="btnContainer">
                                                <div className="btnContainerLeft" ><ClipboardData size={25} /></div>
                                                <div className="btnContainerRight">{'Show Data'}</div>
                                            </div>
                                        </Button>
                                        {/* <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                printFuelConsumptionData();
                                            }}
                                        >
                                            <div className="btnContainer" >
                                                <div className="btnContainerLeft" ><Printer size={25} /></div>
                                                <div className="btnContainerRight" >{'Print Document'}</div>
                                            </div>
                                        </Button> */}
                                        <CSVLink
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            data={compiledDataForReport}
                                            separator={","}
                                            filename={"fuel-consumption-data.csv"}
                                            className="btn btn-primary"
                                            target="_blank"
                                        >
                                            <div className="btnContainer"  onClick={() => {
                                                generateFuelConsumptionCSV();
                                            }}>
                                                <div className="btnContainerLeft" ><FiletypeCsv size={25} /></div>
                                                <div className="btnContainerRight" >{'Export to CSV'}</div>
                                            </div>
                                        </CSVLink>
                                        {/* <br/> */}
                                        <div className="summaryContainerFuelConsumption">
                                            <h4 className="txtFortankNameFuelConsumption">Fuel Consumption Summary</h4>
                                            {
                                                isDataReady ? (
                                                    <div className="table-container" style={{width: '100%'}}>
                                                        <div className="table-header">
                                                            <div style={{ flex: 2 }} className="table-header-content">{''}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{'Main Engine Total Fuel Usage'}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{'Aux Engine Total Fuel Usage'}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{'Main Engine Average Fuel Consumption'}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{'Aux Engine Average Fuel Consumption'}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{'Aux Engine Average Load'}</div>
                                                        </div>
                                                        <div className="table-header">
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Start Date'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'End Date'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>

                                                        </div>
                                                        <div className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{selectStartDate}</p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{selectEndDate}</p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{fuelMEP[fuelMEP.length - 1] ? fuelMEP[fuelMEP.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{fuelMES[fuelMES.length - 1] ? fuelMES[fuelMES.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{fuelAEP[fuelAEP.length - 1] ? fuelAEP[fuelAEP.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{fuelAES[fuelAES.length - 1] ? fuelAES[fuelAES.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{summaryCurrentMEP ? summaryCurrentMEP.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{summaryCurrentMES ? summaryCurrentMES.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{summaryCurrentAEP ? summaryCurrentAEP.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{summaryCurrentAES ? summaryCurrentAES.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{summaryAverageEngineLoadAEP ? summaryAverageEngineLoadAEP.toFixed(2) + ' KW' : "N/A"} </p></div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{summaryAverageEngineLoadAES ? summaryAverageEngineLoadAES.toFixed(2) + ' KW' : "N/A"} </p></div>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            
                                        </div>
                                        {/* <Form.Label style={{ color: 'white' }}>
                                            <input
                                                style={{ marginTop: "30px" }}
                                                type="checkbox"
                                                checked={refreshChecked}
                                                onChange={(e) => {
                                                    setRefreshChecked(e.target.checked);
                                                }}
                                            />
                                            Auto Refresh
                                        </Form.Label> */}
                                    </div>
                                    <div className="outerBorderFuelConsumption">
                                        <h4 className="txtFortankNameFuelConsumption">Total Fuel Usage</h4>
                                        <div className="lineChartContainerFuelConsumption">
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                                <DynamicLineChart dataFuel={fuelMEP} minValue={0} maxValue={fuelMEP.length > 0 ? parseFloat(fuelMEP[fuelMEP.length - 1].compensated_value) : 220} stepSize={fuelMEP.length > 0 ? parseFloat(fuelMEP[fuelMEP.length - 1].compensated_value)/5: 10} />
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                                <DynamicLineChart dataFuel={fuelMES} minValue={0} maxValue={fuelMES.length > 0 ? parseFloat(fuelMEP[fuelMEP.length - 1].compensated_value) : 220} stepSize={fuelMES.length > 0 ? parseFloat(fuelMES[fuelMES.length - 1].compensated_value)/5: 10} />
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                                <DynamicLineChart dataFuel={fuelAEP} minValue={0} maxValue={fuelAEP.length > 0 ? parseFloat(fuelAEP[fuelAEP.length - 1].compensated_value) : 220} stepSize={fuelAEP.length > 0 ? parseFloat(fuelAEP[fuelAEP.length - 1].compensated_value)/5: 10} />
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                                <DynamicLineChart dataFuel={fuelAES} minValue={0} maxValue={fuelAES.length > 0 ? parseFloat(fuelAES[fuelAES.length - 1].compensated_value) : 220} stepSize={fuelAES.length > 0 ? parseFloat(fuelAES[fuelAES.length - 1].compensated_value)/5: 10} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="outerBorderFuelConsumption">
                                        <h4 className="txtFortankNameFuelConsumption">Average & Current Fuel Consumption</h4>
                                        <div className="lineChartContainerFuelConsumption">
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                                <DynamicLineChartCurrent dataFuel={fuelCurrentMEP} minValue={0} maxValue={200} stepSize={20} />
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                                <DynamicLineChartCurrent dataFuel={fuelCurrentMES} minValue={0} maxValue={200} stepSize={20} />
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                                <DynamicLineChartDoubleAxis dataCurrent={fuelCurrentAEP} dataEngineLoad={engineLoadAEP}
                                                    minValue={0} maxValue={40} stepSize={4} minValueEngineLoad={0} maxValueEngineLoad={40} stepSizeEngineLoad={4} />
                                            </div>
                                            <div className="eachlineChartContainerFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                                <DynamicLineChartDoubleAxis dataCurrent={fuelCurrentAES} dataEngineLoad={engineLoadAES}
                                                    minValue={0} maxValue={40} stepSize={4} minValueEngineLoad={0} maxValueEngineLoad={40} stepSizeEngineLoad={4} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </div>
                    <Loading
                        loading={loading}
                        loadingText={`${t("loading_component.text")}`}
                    />
                </Container>
                <div id="printFuelConsumption" style={{ display: 'none', padding: 10 }}>
                    <div style={{width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{width: '90%', border: '1px black solid', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px'}}>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img style={{width: '50%'}}src={WMI} />
                            </div>
                            <div style={{flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                <p style={{fontSize: 'large', fontWeight: 'bold'}}>{vesselName}</p>
                                <p style={{fontSize: 'medium', fontWeight: 'lighter'}}>{customerName}</p>
                            </div>
                        </div>
                        
                        <div style={{width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px'}}>
                            <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>FUEL CONSUMPTION SUMMARY</h2>
                        </div>

                        <div style={{width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px'}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                <p style={{fontSize: 'large', fontWeight: 'bold', margin: '0'}}>{'Start Date'}</p>
                                <p style={{fontSize: 'medium', fontWeight: 'lighter', margin: '0'}}>{moment(selectStartDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                <p style={{fontSize: 'large', fontWeight: 'bold', margin: '0'}}>{'End Date'}</p>
                                <p style={{fontSize: 'medium', fontWeight: 'lighter', margin: '0'}}>{moment(selectEndDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                        </div>

                        <div style={{width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px'}}>
                            <table style={{width: '100%', border: '1px black soild'}}>
                                <thead>
                                    <tr>
                                        <th style={{padding: '8px', border: '1px black soild'}}>{''}</th>
                                        <th style={{padding: '8px', border: '1px black soild'}}>{''}</th>   
                                        <th style={{padding: '8px', border: '1px black soild'}}>Total Fuel Consumption</th>
                                        <th style={{padding: '8px', border: '1px black soild'}}>Average Fuel Usage</th>
                                        <th style={{padding: '8px', border: '1px black soild'}}>Engine Load</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{padding: '8px', border: '1px black soild'}}>Main Engine</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>Port</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{fuelMEP[fuelMEP.length - 1] ? fuelMEP[fuelMEP.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{summaryAverageMEP ? summaryAverageMEP.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{''}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>Starboard</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{fuelMES[fuelMES.length - 1] ? fuelMES[fuelMES.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{summaryAverageMES ? summaryAverageMES.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '8px', border: '1px black soild'}}>Aux Engine</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>Port</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{fuelAEP[fuelAEP.length - 1] ? fuelAEP[fuelAEP.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{summaryAverageAEP ? summaryAverageAEP.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{summaryAverageEngineLoadAEP ? summaryAverageEngineLoadAEP.toFixed(2) + ' KW' : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{''}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>Starboard</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{fuelAES[fuelAES.length - 1] ? fuelAES[fuelAES.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{summaryAverageAES ? summaryAverageAES.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{padding: '8px', border: '1px black soild'}}>{summaryAverageEngineLoadAES ? summaryAverageEngineLoadAES.toFixed(2) + ' KW' : "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}