import "../../../SMSApp.css";
import "./index.css"
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import Speedometer, { Arc, Needle, Progress, Marks, Indicator, DangerPath } from 'react-speedometer';
import BarMeter from "../../../Components/BarMeter/BarMeter";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useState } from "react";

export default function SpeedTemp() {
    //============================PORT STATUS============================
    // top bar meter
    const [ecrLeverPortStatus, setEcrLeverPortStatus] = useState(0);
    const [brgLeverPortStatus, setBrgLeverPortStatus] = useState(0);
    const [speedComPortStatus, setSpeedComPortStatus] = useState(0);

    //speedo meter
    const [valueSpeedoMeterPortStatus, setValueSpeedoMeterPortStatus] = useState(10);

    // left bitlamp
    const [ecrHeadPortStatus, setEcrHeadPortStatus] = useState(false);
    const [ecrStopPortStatus, setEcrStopPortStatus] = useState(false);
    const [ecrAstemPortStatus, setEcrAstemPortStatus] = useState(false);

    // right bitlamp
    const [brgAheadPortStatus, setBrgAheadPortStatus] = useState(false);
    const [brgStopPortStatus, setBrgStopPortStatus] = useState(false);
    const [brgAstemPortStatus, setBrgAstemPortStatus] = useState(false);

    // bot bar meter
    const [cyl1PortStatus, setCyl1PortStatus] = useState(0);
    const [cyl2PortStatus, setCyl2PortStatus] = useState(0);
    const [cyl3PortStatus, setCyl3PortStatus] = useState(0);
    const [cyl4PortStatus, setCyl4PortStatus] = useState(0);
    const [cyl5PortStatus, setCyl5PortStatus] = useState(0);
    const [cyl6PortStatus, setCyl6PortStatus] = useState(0);
    const [aftTurboPortStatus, setAftTurboPortStatus] = useState(0);

    //============================STBD STATUS============================\
    // top bar meter
    const [ecrLeverStbdStatus, setEcrLeverStbdStatus] = useState(0);
    const [brgLeverStbdStatus, setBrgLeverStbdStatus] = useState(0);
    const [speedComStbdStatus, setSpeedComStbdStatus] = useState(0);

    //speedo meter
    const [valueSpeedoMeterStbdStatus, setValueSpeedoMeterStbdStatus] = useState(10);

    // left bitlamp
    const [ecrHeadStbdStatus, setEcrHeadStbdStatus] = useState(false);
    const [ecrStopStbdStatus, setEcrStopStbdStatus] = useState(false);
    const [ecrAstemStbdStatus, setEcrAstemStbdStatus] = useState(false);

    // right bitlamp
    const [brgAheadStbdStatus, setBrgAheadStbdStatus] = useState(false);
    const [brgStopStbdStatus, setBrgStopStbdStatus] = useState(false);
    const [brgAstemStbdStatus, setBrgAstemStbdStatus] = useState(false);

    // bot bar meter
    const [cyl1StbdStatus, setCyl1StbdStatus] = useState(0);
    const [cyl2StbdStatus, setCyl2StbdStatus] = useState(0);
    const [cyl3StbdStatus, setCyl3StbdStatus] = useState(0);
    const [cyl4StbdStatus, setCyl4StbdStatus] = useState(0);
    const [cyl5StbdStatus, setCyl5StbdStatus] = useState(0);
    const [cyl6StbdStatus, setCyl6StbdStatus] = useState(0);
    const [aftTurboStbdStatus, setAftTurboStbdStatus] = useState(0);

    return (
        <>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"MAIN ENGINE > SPEED & TEMPERATURE"} />
            </div>
            <div className="contentSpeedTemp">
                <div className="leftContentSpeedTemp">
                    <div className="titleContent">PORT Status</div>
                    <div className="barMeterNGauge">
                        <div className="barMeter">
                            <div className="flexRow topBarMtr">
                                <div className="topBarMtrClm">
                                    <div className="stby">STBY</div>
                                    <p>ECR Lever</p>
                                    <BarMeter valueBar={ecrLeverPortStatus} />
                                </div>
                                <div className="topBarMtrClm">
                                    <div className="stbyRed">STBY</div>
                                    <p>BRG Lever</p>
                                    <BarMeter valueBar={brgLeverPortStatus} />
                                </div>
                                <div className="topBarMtrClm">
                                    <p>Speed Com %</p>
                                    <BarMeter valueBar={speedComPortStatus} />
                                </div>
                            </div>
                        </div>
                        <div className="gauge">
                            <Speedometer
                                width={300}
                                height={300}
                                rotation={-150}
                                min={0}
                                max={800}
                                value={valueSpeedoMeterPortStatus}
                                angle={250}
                                fontFamily='squada-one'>
                                {/* <Background
                                    angle={250}
                                /> */}
                                <Arc color="#D9D9D9" />
                                <Needle color="#D9D9D9" />
                                <DangerPath angle={62} />
                                <Progress color="#D9D9D9" />
                                <Marks step={100} />
                                <Indicator>

                                </Indicator>

                            </Speedometer>
                        </div>
                    </div>
                    <div className="bitLampSpdMtr">
                        <div className="leftbitLampSpdMtr">
                            <BitLamp alarm={ecrHeadPortStatus} text={"ECR Ahead"} />
                            <BitLamp alarm={ecrStopPortStatus} text={"ECR Stop"} />
                            <BitLamp alarm={ecrAstemPortStatus} text={"ECR Astem"} />
                        </div>
                        <div className="rightbitLampSpdMtr">
                            <BitLamp alarm={brgAheadPortStatus} text={"BRG Ahead"} />
                            <BitLamp alarm={brgStopPortStatus} text={"BRG Stop"} />
                            <BitLamp alarm={brgAstemPortStatus} text={"BRG Astem"} />
                        </div>
                    </div>
                    <div className="botBarMtr">
                        <div className="botBarMtrClm">
                            <p>Cyl 1</p>
                            <BarMeter valueBar={cyl1PortStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 2</p>
                            <BarMeter valueBar={cyl2PortStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 3</p>
                            <BarMeter valueBar={cyl3PortStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 4</p>
                            <BarMeter valueBar={cyl4PortStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 5</p>
                            <BarMeter valueBar={cyl5PortStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 6</p>
                            <BarMeter valueBar={cyl6PortStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>AFT Turbo</p>
                            <BarMeter valueBar={aftTurboPortStatus} />
                        </div>
                    </div>

                </div>
                <div style={{paddingRight: 20, paddingLeft :20}}></div>
                <div className="rightContentSpeedTemp">
                    <div className="titleContent">STBD Status</div>
                    <div className="barMeterNGauge">
                        <div className="barMeter">
                            <div className="flexRow topBarMtr">
                                <div className="topBarMtrClm">
                                    <div className="stby">STBY</div>
                                    <p>ECR Lever</p>
                                    <BarMeter valueBar={ecrLeverStbdStatus} />
                                </div>
                                <div className="topBarMtrClm">
                                    <div className="stbyRed">STBY</div>
                                    <p>BRG Lever</p>
                                    <BarMeter valueBar={brgLeverStbdStatus} />
                                </div>
                                <div className="topBarMtrClm">
                                    <p>Speed Com %</p>
                                    <BarMeter valueBar={speedComStbdStatus} />
                                </div>
                            </div>
                        </div>
                        <div className="gauge">
                            <Speedometer
                                width={300}
                                height={300}
                                rotation={-150}
                                min={0}
                                max={800}
                                value={valueSpeedoMeterStbdStatus}
                                angle={250}
                                fontFamily='squada-one'>
                                {/* <Background
                                    angle={250}
                                /> */}
                                <Arc color="#D9D9D9" />
                                <Needle color="#D9D9D9" />
                                <DangerPath angle={62} />
                                <Progress color="#D9D9D9" />
                                <Marks step={100} />
                                <Indicator>

                                </Indicator>

                            </Speedometer>
                        </div>
                    </div>
                    <div className="bitLampSpdMtr">
                        <div className="leftbitLampSpdMtr">
                            <BitLamp alarm={ecrHeadStbdStatus} text={"ECR Ahead"} />
                            <BitLamp alarm={ecrStopStbdStatus} text={"ECR Stop"} />
                            <BitLamp alarm={ecrAstemStbdStatus} text={"ECR Astem"} />
                        </div>
                        <div className="rightbitLampSpdMtr">
                            <BitLamp alarm={brgAheadStbdStatus} text={"BRG Ahead"} />
                            <BitLamp alarm={brgStopStbdStatus} text={"BRG Stop"} />
                            <BitLamp alarm={brgAstemStbdStatus} text={"BRG Astem"} />
                        </div>
                    </div>
                    <div className="botBarMtr">
                        <div className="botBarMtrClm">
                            <p>Cyl 1</p>
                            <BarMeter valueBar={cyl1StbdStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 2</p>
                            <BarMeter valueBar={cyl2StbdStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 3</p>
                            <BarMeter valueBar={cyl3StbdStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 4</p>
                            <BarMeter valueBar={cyl4StbdStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 5</p>
                            <BarMeter valueBar={cyl5StbdStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>Cyl 6</p>
                            <BarMeter valueBar={cyl6StbdStatus} />
                        </div>
                        <div className="botBarMtrClm">
                            <p>AFT Turbo</p>
                            <BarMeter valueBar={aftTurboStbdStatus} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{paddingBottom:120}}></div>
            <div><BottomNavBar /></div>
        </>
    )
}