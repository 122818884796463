import { Button, Table } from "react-bootstrap";
import "../../App.css";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/NavBar";
import { useState } from "react";

export default function Alarm() {
    const navigate = useNavigate();
    const location = useLocation();
    // const [smsSerialNumber, setSmsSerialNumber] = useState(l);
    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'System Alarm Events'} smsSerialNumber={location.state.datasmsSerialNumber}/>
                    <BottomNavBar/>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm", { state: { smsSerialNumber: location.state.datasmsSerialNumber } })}}>Events Alarm</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm/Logs", { state: { smsSerialNumber: location.state.datasmsSerialNumber } })}}>Alarm Logs</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/MainDeck", { state: { smsSerialNumber: location.state.datasmsSerialNumber } })}}>Fire Alarm</Button>
                    <Table responsive="md">
                        <thead>
                            <tr className="tableRowHead">
                                <th className="tableRowHeadContent">#</th>
                                <th className="tableRowHeadContent">Date</th>
                                <th className="tableRowHeadContent">Time</th>
                                <th className="tableRowHeadContent">Events</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tableRowBodyContent"></td>
                                <td className="tableRowBodyContent">04/30/23</td>
                                <td className="tableRowBodyContent">03:29:55</td>
                                <td className="tableRowBodyContent">Event 0 Return to Normal</td>
                            </tr>
                            <tr>
                                <td className="tableRowBodyContent"></td>
                                <td className="tableRowBodyContent">04/30/23</td>
                                <td className="tableRowBodyContent">03:29:55</td>
                                <td className="tableRowBodyContent">Event 0 Return to Normal</td>
                            </tr>
                        </tbody>
                    </Table>

                    {/* <div className="tableAlarm">
                    <div className="tableAlarmHead">
                        <div className="headingNo"></div>
                        <div className="headingDate">Date</div>
                        <div className="headingTime">Time</div>
                        <div className="headingEvent">Events</div>
                    </div>
                    <div className="tableBodyContainer">
                        <div className="tableAlarmBody">
                            <div className="bodyNo">3</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0 Return to Normal</div>
                        </div>
                        <div className="tableAlarmBody">
                            <div className="bodyNo">2</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0 Acknowledge</div>
                        </div>
                        <div className="tableAlarmBody">
                            <div className="bodyNo">1</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0</div>
                        </div>
                    </div> */}
                </div>
            </div>
            
            {/* <div className="contentAlarm">
                <div className="headingContentAlarm">
                    <div className="titleContent">System Event Alarm</div>
                    <div className="topBtnAlarmContainer">
                        <Button
                            className="btnAlarms"
                            variant="outline-primary"
                        >
                            ACKNOWLEDGE ALARM
                        </Button>
                        <Button
                            className="btnAlarms"
                            variant="outline-primary"
                            onClick={navigateAlarmLogs}
                        >
                            ALARM LOGS
                        </Button>
                    </div>
                </div>
                
            </div>
            <div><BottomNavBar /></div> */}
        </>
    );
}