import "./index.css"
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid, findSmsVesselPosition } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import { Form, Button } from "react-bootstrap";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import DropDownForm from "../../../Components/DropDownForm";
import moment from "moment";


export default function SMSMainEngine(props) {
    // ============ SMS Main Engine as Child ============
    const navigate = useNavigate();
    // const location = useLocation();
    const { smsSerialNumber } = props;
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    // const [mainEngineData, setMainEngineData] = useState([]);
    const [selectStartDate, setSelectStartDate] = useState(moment(new Date()).utc().subtract(30, "minutes").format("yyyy-MM-DD HH:mm:ss"));
    const [selectEndDate, setSelectEndDate] = useState(moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss"));
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [dataShowPort, setDataShowPort] = useState();
    const [dataShowSTBD, setDataShowSTBD] = useState();
    const [dataCreatedDate, setDataCreatedDate] = useState();
    // const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    // const [equipmentModal, setEquipmentModal] = useState("");
    // const [detailEquipment, setDetailEquipment] = useState({});
    // const [ecuValue, setEcuValue] = useState({});
    // useEffect(() => {
    //     if (listCoordinate.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listCoordinate]);

    // useEffect(() => {
    //     console.log("Child ", props.smsSerialNumber);
    //     loadMainEngineData();
    // }, []);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated){
                navigate("/");
            }
            else {
                loadMainEngineData();
                // console.log(cookies);
            }
        }
        checkCookies();
        console.log(smsSerialNumber);
    }, []);

    useEffect( () => {
        if (refreshChecked) {
            const interval = setInterval( async() => {
                loadMainEngineData();
                console.log("REFRESHED");
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [refreshChecked])

    // useEffect(() => {
    //     if (vesselEquipmentId !== "")
    //         loadEquipmentDetail();
    // }, [vesselEquipmentId]);

    // const loadEquipmentDetail = async () => {
    //     try {
    //         let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
    //         console.log(response);
    //         setDetailEquipment(response);
    //         setEquipmentModal(true);
    //     } catch (exception) {

    //     }
    // }

    const loadMainEngineData = async () => {
        try{
            setLoading(true);
            let modConst = 50;
            let durationDiff = moment.duration(
                moment(selectEndDate).diff(moment(selectStartDate))
            );
            if (durationDiff.asDays() > 7) {
                modConst = 1000;
            } else if (durationDiff.asDays() >= 1 && durationDiff.asDays() <= 7) {
                modConst = 250;
            }
            console.log('smsserialnumber = ', props);
            console.log('start date = ',selectStartDate);
            console.log('end date = ',selectEndDate);
            console.log('mod const = ',modConst);
            let response = await findSmsVesselPosition(cookies.token,smsSerialNumber,selectStartDate,selectEndDate,modConst);
            console.log(response)
            let createdDate = response.map((a) => a.created_date);
            let portRpm = response.map((a) => a.port_rpm);
            let stbdRpm = response.map((a) => a.stbd_rpm);
            setDataCreatedDate(createdDate);
            setDataShowPort(portRpm);
            setDataShowSTBD(stbdRpm);
            /* setDataShowPort([
                ...dataShowPort,
                {label: createdDate, value: portRpm}
            ])
            setDataShowSTBD([
                ...dataShowSTBD,
                {label: createdDate, value: stbdRpm}
            ]) */
            console.log(portRpm);
            console.log(stbdRpm);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    const durationOption = [
        {
          label: "1 Jam",
          value: 1,
        },
        {
          label: "1 Hari",
          value: 2,
        },
        {
          label: "1 Minggu",
          value: 3,
        },
      ];



    return (
        <>
           <div className="mainPageEngine">
                <div className="containerBoxEngine">
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexDirection: "row",
                        padding:'5px'}}>
                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Mulai</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    setSelectStartDate(e.target.value);
                                }}
                                value={
                                    selectStartDate
                                      ? moment(selectStartDate).format("yyyy-MM-DD HH:mm")
                                      : ""
                                  }
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Akhir</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                   setSelectEndDate(e.target.value);
                                }}
                                value={
                                    selectEndDate
                                      ? moment(selectEndDate).format("yyyy-MM-DD HH:mm")
                                      : ""
                                  }
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group style={{margin: "10px", color: 'white'}}>
                            <DropDownForm
                                caption={"Rentang Waktu"}
                                placeholder={"Pilih Waktu"}
                                listOption={durationOption}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                console.log(e);
                                try {
                                    let dateTime = new Date();
                                    if (e.target.value === "1") {
                                        console.log("1");
                                        let startDate = moment(dateTime)
                                          .utc()
                                          .subtract(60, "minutes")
                                          .format("yyyy-MM-DD HH:mm:ss");
                                        let endDate = moment(dateTime)
                                          .utc()
                                          .format("yyyy-MM-DD HH:mm:ss");
                                        setSelectStartDate(startDate);
                                        setSelectEndDate(endDate);
                                      } else if (e.target.value === "2") {
                                        console.log("2");
                                        let startDate = moment(dateTime)
                                          .utc()
                                          .subtract(1, "day")
                                          .format("yyyy-MM-DD HH:mm:ss");
                                        let endDate = moment(dateTime)
                                          .utc()
                                          .format("yyyy-MM-DD HH:mm:ss");
                                        setSelectStartDate(startDate);
                                        setSelectEndDate(endDate);
                                        console.log("tes", startDate, endDate);
                                      } else if (e.target.value === "3") {
                                        console.log("3");
                                        let startDate = moment(dateTime)
                                          .utc()
                                          .subtract(1, "week")
                                          .format("yyyy-MM-DD HH:mm:ss");
                                        let endDate = moment(dateTime)
                                          .utc()
                                          .format("yyyy-MM-DD HH:mm:ss");
                                        setSelectStartDate(startDate);
                                        setSelectEndDate(endDate);
                                      }
                                } catch (exception) {
                                    console.log(exception);
                                }
                                }}
                            />
                            </Form.Group>

                            <Button
                                variant="dark"
                                style={{ height: "40px", margin: "10px", marginTop: "35px", backgroundColor:'#050512' }}
                                onClick={() => {
                                    loadMainEngineData();
                                }}
                            >
                                Tampilkan Data
                            </Button>

                            <Form.Label style={{color: 'white'}}>
                                <input
                                style={{marginTop:"30px"}}
                                type="checkbox"
                                checked={refreshChecked}
                                onChange={(e) => {
                                    setRefreshChecked(e.target.checked);
                                }}
                                />
                                Auto Refresh
                            </Form.Label>
                    </div>
                    <div style={{display: 'flex', margin:'25px 0px'}}>
                        <DynamicLineChart colorShow={'yellow'} labelData={dataCreatedDate} valueData={dataShowPort} labelText={'PORT'} minValue={0} maxValue={3000} stepSize={200}/>
                        <div className="statusTable">
                            <h4>MAIN ENGINE (PORT)</h4>
                            <h1>ECU NOT CONNECTED</h1>
                            <table>
                                <tr>
                                    <td width={'80%'}>Battery Voltage</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>Volt</td>
                                </tr>
                                <tr>
                                    <td width={'80%'}>Boost Pressure</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>Kpa</td>
                                </tr>
                                <tr>
                                    <td width={'80%'}>Rail Pressure</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>Mpa</td>
                                </tr>
                                <tr>
                                    <td width={'80%'}>Coolant Temp</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>°C</td>
                                </tr>
                                <tr>
                                    <td width={'70%'}>Fuel Temp</td>
                                    <td width={'15%'}>0.0</td>
                                    <td width={'15%'}>°C</td>
                                </tr>
                                <tr>
                                    <td width={'70%'}>Ambient Pressure</td>
                                    <td width={'15%'}>0.0</td>
                                    <td width={'15%'}>Kpa</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div style={{display: 'flex', margin:'25px 0px'}}>
                        <DynamicLineChart colorShow={'green'} labelData={dataCreatedDate} valueData={dataShowSTBD} labelText={'Starboard'} minValue={0} maxValue={3000} stepSize={200}/>
                        <div className="statusTable">
                            <h4>MAIN ENGINE (STBD)</h4>
                            <h1>ECU NOT CONNECTED</h1>
                            <table>
                            <tr>
                                    <td width={'80%'}>Battery Voltage</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>Volt</td>
                                </tr>
                                <tr>
                                    <td width={'80%'}>Boost Pressure</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>Kpa</td>
                                </tr>
                                <tr>
                                    <td width={'80%'}>Rail Pressure</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>Mpa</td>
                                </tr>
                                <tr>
                                    <td width={'80%'}>Coolant Temp</td>
                                    <td width={'10%'}>0.0</td>
                                    <td width={'10%'}>°C</td>
                                </tr>
                                <tr>
                                    <td width={'70%'}>Fuel Temp</td>
                                    <td width={'15%'}>0.0</td>
                                    <td width={'15%'}>°C</td>
                                </tr>
                                <tr>
                                    <td width={'70%'}>Ambient Pressure</td>
                                    <td width={'15%'}>0.0</td>
                                    <td width={'15%'}>Kpa</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    
                </div>
           </div>
           <Loading loading={loading}/>
        </>
    );
}