import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation, useAsyncError } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getTroubleshooting, getTroubleshootingCount, deleteTroubleshooting, getTroubleshootingReport, getUserAccessById} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { EyeFill, PencilFill, Trash, Search, ArrowClockwise, FilePlusFill, PrinterFill } from "react-bootstrap-icons";
import Moment from "moment";
import Paginations from "../../../Components/Pagination";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";


export default function TroubleshootingPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listTroubleshooting, setListTroubleshooting] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [page, setPage] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("")
    const [itemPerPage, setItemPerPage] = useState(50);
    const [projectId, setProjectId] = useState("");
    const [troubleshootingId, setTroubleshootingId] = useState("");
    const [projectName, setProjectName] = useState("");
    const [serviceDate, setServiceDate] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 20, 50, 100]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Proyek",
        value: "project.project_name"
    });
    const [docxData, setDocxData] = useState("");

    const orderByList = [
        {
            label: "Nama Proyek",
            value: "project.project_name"
        }
    ]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
               loadTroubleshooting();
               loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadTroubleshooting();
    }, [page]);

    useEffect(() => {
      loadTroubleshooting();
    }, [itemPerPage]);

    useEffect(() => {
        loadTroubleshooting();
    }, [orderBy]);

    useEffect(()=>{
        loadTroubleshooting();
    },[descending])

  

    useEffect(() => {
        if (removeId !== "")
            removeData();
    }, [removeId]);

    useEffect(() => {
        if (troubleshootingId !== "")
            createDocument();
    }, [troubleshootingId]);

    useEffect(() => {
        if (docxData.size > 0) {
            downloadDocxFile();
        }
    }, [docxData]);


   

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        
        element.href = URL.createObjectURL(file);
        element.download = `${projectName} - ${Moment(serviceDate).format("DD-MM-yyyy")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        
        URL.revokeObjectURL(file);
        setLoading(false);
        setDisabledButton(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getTroubleshootingReport(cookies.token, troubleshootingId, projectId)
            if (docx.size) {
                setDocxData(docx);
            }else{
                alert('Gagal Mengunduh Dokumen');
                setLoading(false);
                setDisabledButton(false);
            }
            setTroubleshootingId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadTroubleshooting = async() => {
        try {
            let count = await getTroubleshootingCount(cookies.token, cookies.extCustomerId);
            let totalPage = count / itemPerPage
            setTotalPage(totalPage);

            let response = await getTroubleshooting(cookies.token,cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListTroubleshooting(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeData = async()=>{
        try {
            let response = await deleteTroubleshooting(cookies.token, removeId);
            if(response === 0){
                alert('Data Telah Dihapus');
                loadTroubleshooting();
            }else{
                alert('Data Gagal Dihapus')
            }
            setRemoveId("");
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Troubleshooting"
                    useActionContainer={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/Troubleshooting/Detail", { state: { troubleshootingId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Troubleshooting</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Proyek)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadTroubleshooting();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadTroubleshooting();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadTroubleshooting()
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 5 }} className="table-header-content">Nama Proyek</div>
                                    <div style={{ flex: 5 }} className="table-header-content">Tanggal</div>
                                    <div style={{ flex: 7 }} className="table-header-content">Kapal</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Cetak</div>

                                </div>
                                {
                                    listTroubleshooting.length === 0 &&
                                    <div>
                                        <div colSpan={7} style={{ textAlign: "center" }}>Tidak Ada Data</div>
                                    </div>
                                }
                                {
                                    listTroubleshooting.length > 0 && listTroubleshooting.map(
                                        (troubleshooting, index) => {
                                            return (<div key={index} className="table-body">
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                <div style={{ flex: 5 }} className="table-body-content"><p>{troubleshooting.project_name}</p></div>
                                                <div style={{ flex: 5 }} className="table-body-content"><p>{troubleshooting.service_date? moment(troubleshooting.service_date).format("DD/MM/yyyy"):""}</p></div>
                                                <div style={{ flex: 7 }} className="table-body-content"><p>{troubleshooting.vessel_name}</p></div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button variant="primary" onClick={() => {
                                                            navigate("/Troubleshooting/Detail", { state: { troubleshootingId: troubleshooting.id } });
                                                        }}><PencilFill />
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true : !deletedButton} variant="danger"
                                                            onClick={(event) => {
                                                                if (window.confirm(`Apakah Anda Yakin Menghapus Data ini?`)) {
                                                                    let idRemove = troubleshooting.id
                                                                    setRemoveId(idRemove)
                                                                }
                                                            }}
                                                        ><Trash /></Button>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button disabled={disabledButton} variant="primary" onClick={() => {
                                                            setDisabledButton(true);
                                                            setProjectName(troubleshooting.project_name);
                                                            setServiceDate(troubleshooting.service_date);
                                                            setProjectId(troubleshooting.project_id);
                                                            setTroubleshootingId(troubleshooting.id);
                                                            setLoading(true);
                                                        }}><PrinterFill />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}   