// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableBorder {
    border: 1px black solid;
    background-color:rgba(233, 233, 250, 0.1);
    padding: 3px;
    flex: 1 1;
    color: aliceblue;
    align-items: center;
}

.tableHeader{
    display: flex;
    justify-content: space-between;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/DataHistoryChart/WatertightDoorHistory/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yCAAyC;IACzC,YAAY;IACZ,SAAO;IACP,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB","sourcesContent":[".tableBorder {\n    border: 1px black solid;\n    background-color:rgba(233, 233, 250, 0.1);\n    padding: 3px;\n    flex: 1;\n    color: aliceblue;\n    align-items: center;\n}\n\n.tableHeader{\n    display: flex;\n    justify-content: space-between;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
