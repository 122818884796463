import { useState } from "react";
import LampNavButton from "../LampNavButton/LampNavButton";
import NavButton from "../NavButton/NavButton";
import "./BottomNavBar.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function BottomNavBar(state) {
    // const location = useLocation();
    const navigate = useNavigate();
    const navigateMainEngine = () => { navigate("/MainEngine") }
    const navigateSpeedNTemp = () => { navigate("/MainEngine/SpeedTemp") }
    const navigatePortCalibration = () => { navigate("/MainEngine/PortCalibration") }
    const navigatePortCyl1 = () => { navigate("/MainEngine/PortCyl1") }
    const navigateLOPressLastBearing = () => { navigate("/MainEngine/LOPressLastBearing") }
    const navigateAuxEngine = () => { navigate("/AuxEngine")};
    const navigateGearBox = () => { navigate("/GearBox") }
    const navigateBilgesTanks = () => { navigate("/BilgesTanks") }
    const navigateLevelSoundingTanks = () => { navigate("/LevelSoundingTanks") }
    const navigateMiscellaneous = () => { navigate("/Miscellaneous") }
    const navigateAlarm = () => { navigate("/Alarm") }
    const navigateWatertightDoor = () => {navigate("/WatertightDoor")}
    const navigateMSB = () =>{navigate("/MSB")}
    const navigatePumpMotor = () =>{navigate("/PumpMotor")}
    const navigateNavSignalLight = () => { navigate("/NavSignalLightPanel")}

    return (
        <>
        <div className="bottomNavBar">
                <LampNavButton text1={"MAIN"} text2={"ENGINE"} lamp={false} handleOnClick={navigateMainEngine} />
                <LampNavButton text1={"AUX"} text2={"ENGINE"} lamp={false} handleOnClick={navigateAuxEngine} />
                <LampNavButton text1={"PUMP AND"} text2={"MOTOR"} lamp={false} handleOnClick={navigatePumpMotor} />
                <LampNavButton text1={"TANK"} text2={"LEVEL"} lamp={false} handleOnClick={navigateBilgesTanks} />
                <LampNavButton text1={"WATERTIGHT"} text2={"DOOR"} lamp={false} handleOnClick={navigateWatertightDoor} />
                <LampNavButton text1={"MAIN"} text2={"SWITCHBOARD"} lamp={false} handleOnClick={navigateMSB} />
                <LampNavButton text1={"NAV"} text2={"SIGNAL LIGHT"} lamp={false} handleOnClick={navigateNavSignalLight} />
                <NavButton text1={"ALARM"} handleOnClick={navigateAlarm} />
                <NavButton text1={"CALIBRATE"} handleOnClick={navigateMainEngine} />
        </div>
        </>
    )
}