import React, { useEffect, useState } from "react";
import { Modal, Col, Button, Row, Form, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config/Config";
import { useCookies } from "react-cookie";
import { isTokenValid, updatePassword } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
// import "./Login.css";

export default function ChangePasswordPage() {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const [user, setUser] = useState({
    id: "",
    name: "",
    pass: "",
    confirPass: "",
    login_password: "",
    email: "",
    roleId: "",
    languageId: "",
    staffId: "",
  })

  const navigate = useNavigate();

  useEffect(() => {
    async function checkCookies() {
      if (cookies.token) {
        let isAuthenticated = await isTokenValid(cookies.token);
        if (isAuthenticated)
          setUser({
            ...user,
            id: cookies.userId,
            languageId: cookies.languageId,
            name: cookies.userName,
            email: cookies.userEmail,
            roleId: cookies.roleId,
            staffId: cookies.staffId,
          });
      } else {
        navigate("/");
      }
    }
    checkCookies();
  }, []);


  const changePassword = async () => {

    if (user.login_password == user.confirPass) {
      setMessage("");
      let res = await updatePassword(cookies.token, user.id, user.login_password)
      if (res.success) {
        if (res.error_code === 0) {
          removeCookie("token");
          navigate("/");
          alert("Data Telah Disimpan");
        }
        else {
          alert(res.error_message);
        }
      } else {
        alert(res.error_message);
      }

    }
    else {
      setMessage("Kata Sandi dan Konfirmasi Kata Sandi Tidak Sesuai");
    }

  }


  const checkPasswordLength = () => {
    if (user.login_password.length > 0 && user.confirPass.length > 0)
      setSaveButtonDisabled(false);
    else
      setSaveButtonDisabled(true);
  }


  return (
    <Container fluid style={{
      display: "flex",
      flex: 1,
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      // alignSelf: "center"
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        width: "40%",
        borderStyle: "groove",
        marginTop: 150,
        borderRadius: 5,
        backgroundColor: "rgba(219, 235, 255, 0.9)",
        padding: 10
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          fontSize: 30,
          textAlign: "center",
          padding: 5
        }}>
          <div>Atur Kata Sandi Baru</div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          fontSize: 15,
          textAlign: "center",
          padding: 5
        }}>
          <div>Masukkan Kata Sandi Baru Untuk User <b>{cookies.userEmail}</b></div>
        </div>
        <div style={{
          display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "center",
          padding: 10,
          width: "70%",
          borderStyle: "groove",
          height: 400,
          backgroundColor: "white"
        }}>
          <Form onSubmit={(e) => {
            e.preventDefault();
            changePassword();

          }}>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Kata Sandi Baru</Form.Label>
              <Form.Control onChange={(e) => {
                setUser({ ...user, login_password: e.target.value })
                checkPasswordLength();
              }} value={user.login_password} type="password" placeholder="" />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Konfirmasi Kata Sandi</Form.Label>
              <Form.Control onChange={(e) => {
                setUser({ ...user, confirPass: e.target.value });
                checkPasswordLength();
              }} value={user.confirPass} type="password" placeholder="" />
            </Form.Group>
            <p className="text-danger">{message}</p>

            <div className="d-flex justify-content-between">
              <div className="mb-3 form-check">

              </div>

              {/* <div>
                    <a href="#" className="link">Forgot Password ?</a>
                  </div> */}
            </div>

            <div className="d-grid mt-2" >
              <button disabled={saveButtonDisabled} type="submit" className="btn btn-primary btn-block mb-3">Simpan</button>

              <button className="btn btn-outline-secondary  btn-block" onClick={() => {
                navigate("/Dashboard");
              }}>Batal</button>
            </div>
          </Form>
        </div>
      </div>
    </Container>

  );
}

