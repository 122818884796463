// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWheelHouse {
    margin: 20px;
    color: white;
}

.contentWheelHouse .topBtn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-right: 55px;
}

.containerImgWH {
    display: flex;
    flex: 1 1;
    justify-content: space-around;
    margin: 35px 0px;
}

.containerBitLampsWH {
    display: flex;
    justify-content: center;
}

.boxBitLampWH {
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 7px;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 220px;
}

.containerBitLampsWH .flexRow {
    flex: 1 1;
    justify-content: space-around;
}

.containerBitLampsWH .flexColumn {
    gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/FireAlarm/WheelHouse/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,6BAA6B;AACjC;;AAEA;IACI,SAAS;AACb","sourcesContent":[".contentWheelHouse {\n    margin: 20px;\n    color: white;\n}\n\n.contentWheelHouse .topBtn {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n    padding-right: 55px;\n}\n\n.containerImgWH {\n    display: flex;\n    flex: 1;\n    justify-content: space-around;\n    margin: 35px 0px;\n}\n\n.containerBitLampsWH {\n    display: flex;\n    justify-content: center;\n}\n\n.boxBitLampWH {\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 7px;\n    display: flex;\n    flex: 1;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-around;\n    height: 220px;\n}\n\n.containerBitLampsWH .flexRow {\n    flex: 1;\n    justify-content: space-around;\n}\n\n.containerBitLampsWH .flexColumn {\n    gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
