import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, getFullContractById, getProject, insertUpdateFullContract, getEquipment,
        getVesselType,getContractTaskDetail
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import FormSelect from "../../../../Components/FormSelect";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { Trash } from "react-bootstrap-icons";

export default function FullContractPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const [contractTaskDetail, setContractTaskDetail] = useState([]);
    const [vesselTypeOptions, setVessselTypeOptions] = useState([]);
    const [contractOptions, setContractOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [projectOptions, setProjectOptions] = useState([]);
    const [contract, setContract] = useState({
        id: 0,
        contract_name: "",
        contract_date: null,
        contract_external_number: null,
        project_id: null,
        description: "",
        vessel_type_id: null,
        equipment_id: null
    });

    const [contractTask, setContractTask] = useState([{vessel_type_id: '', equipment_id: '', contract_task_detail_name:[]}])
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadProject();
                loadVesselType();
                loadEquipment();
                setContract({...contract, project_id:location.state.projectId})
            }
        }
        checkCookies();
        if (location.state.contractId === 0) {
            setLoading(false);
        } else {
            initContract();
        }
    }, []);

    useEffect(()=>{
        validationForm();
    },[contract]);


    const initContract = async () => {
        try {
            let response = await getFullContractById(cookies.token, location.state.contractId);
            if (response) {
                setContract({
                    ...contract,
                    id: response.id,
                    contract_name: response.contract_name,
                    contract_date: response.contract_date,
                    contract_external_number: response.contract_external_number,
                    project_id: response.project_id,
                    description: response.description,
                    vessel_type_id: response.vessel_type_id,
                    equipment_id: response.equipment_id,
                    contractTaskId: response.contract_task_id
                })
            }
            let detail = await getContractTaskDetail(cookies.token, response.contract_task_id);
            setContractTaskDetail(detail);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.language_id, cookies.extCustomerId);
            let listProject = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].project_name,
                    id: response[i].id

                };
                listProject.push(obj);
            }
            setProjectOptions(listProject);
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const addContract = async()=>{
        try {
            let response = await insertUpdateFullContract(cookies.token, contract,contractTaskDetail);
            if(response){
                alert('Berhasil Menyimpan Data')
                if(location.state.contractId === 0){
                    navigate("/Contract/FullContract", {state:{contractId : response.id}});
                }else{
                    initContract();
                }
            }else{
                alert('Gagal Menyimpan Data');
            }
            
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const validationForm = async()=>{

        return new Promise(async (resolve, reject) => {
            try {
                if (contract.contract_name === undefined || contract.contract_name === "") {
                    setErrors({
                        ...errors,
                        contractName: 'Harus Diisi'
                    },
                        setDisabledButton(true));
                } else if (contract.project_id === undefined || contract.project_id === null) {
                    setErrors({
                        ...errors,
                        projectId: 'Harus Diisi'
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "contractName", "projectId");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...contractTaskDetail];
        list[index][name] = value;
        setContractTaskDetail(list);

    }

    const handleremove = index => {
        const list = [...contractTaskDetail];
        list.splice(index, 1);
        setContractTaskDetail(list);
    }

    const handleaddclick = () => {
        setContractTaskDetail([...contractTaskDetail, { contract_task_detail_name: '' }]);
    }

    const loadVesselType = async () => {
        try {
            let response = await getVesselType(cookies.token, cookies.languageId);
            let listVesselType = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_type_name,
                    id: response[i].id

                };
                listVesselType.push(obj);
            }
            setVessselTypeOptions(listVesselType);
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            let listEquipment = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].equipment_name,
                    id: response[i].id

                };
                listEquipment.push(obj);
            }
            setEquipmentOptions(listEquipment);
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

   

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={"Detail Kontrak"} childContent={
                    <div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            padding: 5,
                            width: "100%",
                            flexWrap: "nowrap",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingRight: 5,
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    paddingBottom: 5,
                                    width: "100%",
                                    flexWrap: "nowrap",
                                }}>
                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Kontrak"} childContent={
                                        <div style={{
                                            padding: 10,
                                        }}>

                                            <TextBox value={contract.contract_name} placeholder="Nama Kontrak"
                                                caption="Nama Kontrak" setValueFunction={(e) => {
                                                    setContract({ ...contract, contract_name: e.target.value });
                                                }} />
                                            {
                                                errors.contractName && <p style={{ color: "red" }}>{errors.contractName}</p>
                                            }
                                            <TextBox value={contract.contract_date} controlType="date" placeholder="Tanggal Kontrak"
                                                caption="Tanggal Kontrak" setValueFunction={(e) => {
                                                    setContract({ ...contract, contract_date: e.target.value });
                                                }} />
                                            <TextBox value={contract.contract_external_number} placeholder="Nomor Kontrak"
                                                caption="Nomor Kontrak" setValueFunction={(e) => {
                                                    setContract({ ...contract, contract_external_number: e.target.value });
                                                }} />
                                            <TextBox value={contract.description} placeholder="Deskripsi"
                                                caption="Deskripsi" setValueFunction={(e) => {
                                                    setContract({ ...contract, description: e.target.value });
                                                }} />

                                            <FormSelect caption="Nama Proyek" autoFocusValue={contract.project_id === undefined} placeholder="Pilih Proyek..."
                                                options={projectOptions} value={projectOptions.filter(function (option) {
                                                    return option.id === contract.project_id;
                                                })} setValueFunction={(e) => {
                                                    setContract({ ...contract, project_id: e.id });
                                                }}
                                            />
                                            {
                                                errors.projectId && <p style={{ color: "red" }}>{errors.projectId}</p>
                                            }

                                        </div>
                                    }>
                                    </ContainerBox>
                                </div>
                            </div>

                            <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>


                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Kontrak Pekerjaan"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                {/* <FormSelect caption="Nama Kontrak" autoFocusValue={contractTask.contract_id === undefined} placeholder="Pilih Kontrak..."
                                                    options={contractOptions} value={contractOptions.filter(function (option) {
                                                        return option.id === contractTask.contract_id;
                                                    })} setValueFunction={(e) => {
                                                        setContractTask({ ...contractTask, contract_id: e.id });
                                                    }}
                                                /> */}

                                                <FormSelect caption="Tipe Kapal" autoFocusValue={contract.vessel_type_id === undefined} placeholder="Pilih Tipe Kapal..."
                                                    options={vesselTypeOptions} value={vesselTypeOptions.filter(function (option) {
                                                        return option.id === contract.vessel_type_id;
                                                    })} setValueFunction={(e) => {
                                                        setContract({ ...contract, vessel_type_id: e.id });
                                                    }}
                                                />

                                                <FormSelect caption="Tipe Peralatan" autoFocusValue={contract.equipment_id === undefined} placeholder="Pilih Peralatan..."
                                                    options={equipmentOptions} value={equipmentOptions.filter(function (option) {
                                                        return option.id === contract.equipment_id;
                                                    })} setValueFunction={(e) => {
                                                        setContract({ ...contract, equipment_id: e.id });
                                                    }}
                                                />
                                            </div>

                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Detail Pekerjaan"} useActionContainer={true}
                                            childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {
                                                        contractTaskDetail.length === 0 ?
                                                            <></>
                                                            :
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Detail Pekerjaan</th>
                                                                        <th>Hapus</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        contractTaskDetail.map((x, i) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        contractTaskDetail.length === 0 ?
                                                                                            <></>
                                                                                            :

                                                                                            <tr>
                                                                                                <td>
                                                                                                    <Form.Control name="contract_task_detail_name" value={x.contract_task_detail_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Detail Pekerjaan" required></Form.Control>

                                                                                                </td>
                                                                                               
                                                                                                <td>

                                                                                                    {
                                                                                                        contractTaskDetail.length >= 1 &&
                                                                                                        <Button variant="danger" onClick={() => {
                                                                                                            if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                                handleremove(i)
                                                                                                            }
                                                                                                        }}><Trash /></Button>
                                                                                                    }

                                                                                                </td>
                                                                                            </tr>
                                                                                    }


                                                                                </>

                                                                            )


                                                                        })
                                                                    }

                                                                </tbody>
                                                            </Table>
                                                    }


                                                            <center><Button className="add-button" variant="primary" onClick={handleaddclick}>Tambah Detail </Button></center>
                                                    
                                                </div>

                                            }>
                                        </ContainerBox>

                                    </div>
                                </div>

                                

                        </div>

                        <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        padding: 10,
                        width: "100%",
                        justifyContent: "center",
                        fontWeight: "bold"
                    }}>
                        <div style={{
                            display: "flex",
                            paddingRight: 5,
                        }}><Button onClick={() => {
                            addContract();
                        }}

                            className="save-button" >Simpan</Button> </div>
                        <div style={{
                            display: "flex",
                            paddingLeft: 5,
                            paddingRight: 5
                        }}>
                            <Button onClick={() => {
                                navigate('/Project/ListContract', {state:{projectId: contract.project_id}})
                            }}
                                className="cancel-button" variant="danger">Batal</Button></div>

                    </div>
                    </div>

                }>

                </ContainerBox>

            </Container>
        </>
    )
}