// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tankContainer {
    margin: 10px 10px;
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 12px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

h5, h6{
    color: aliceblue;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TankLevel/TankLevel.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,0CAA0C;IAC1C,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".tankContainer {\n    margin: 10px 10px;\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 12px;\n    width: 100%;\n    height: 100px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 10px;\n    -webkit-border-radius: 12px;\n    -moz-border-radius: 12px;\n    -ms-border-radius: 12px;\n    -o-border-radius: 12px;\n}\n\nh5, h6{\n    color: aliceblue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
