import "./index.css";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import PictBelowMainDeck from "../../../Assets/Images/belowMainDeck.png";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import moment from "moment";

export default function SMSFireAlarmBelowMainDeck(props) {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(false);
    const { smsSerialNumber, vesselId} = props;
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([21]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [bitlampList, setBitlampList] = useState({

        M296:
        {
            text: "MCP E/R ENT",
            alarm: true,
            coordinate: "M296",
            value: false
        },
        M298:
        {
            text: "Smoke E/R ENT",
            alarm: true,
            coordinate: "M298",
            value: false
        },
        M300:
        {
            text: "Heat E/R ENT",
            alarm: true,
            coordinate: "M300",
            value: false
        },
        M302:
        {
            text: "Smoke 1 ER",
            alarm: true,
            coordinate: "M302",
            value: false
        },
        M304:
        {
            text: "MCP 1 ER",
            alarm: true,
            coordinate: "M302",
            value: false
        },
        M306:
        {
            text: "Heat 1 65C",
            alarm: true,
            coordinate: "M306",
            value: false
        },
        M308:
        {
            text: "MCP 2 ER",
            alarm: true,
            coordinate: "M308",
            value: false
        },
        M310:
        {
            text: "Smoke 2 ER",
            alarm: true,
            coordinate: "M310",
            value: false
        },
        M312:
        {
            text: "Heat 2 65C",
            alarm: true,
            coordinate: "M312",
            value: false
        },
        M314:
        {
            text: "Smoke Steering Gear",
            alarm: true,
            coordinate: "M314",
            value: false
        },
        M316:
        {
            text: "MCP Crew Below Main Deck",
            alarm: true,
            coordinate: "M316",
            value: false
        },
        M318:
        {
            text: "Smoke Crew room",
            alarm: true,
            coordinate: "M318",
            value: false
        },
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log(bitlampList)
    }, [bitlampList]);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].received_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainPageWater">
                <div style={{display: 'flex', justifyContent: 'space-between'}} className="containerBoxWater">
                    <div className="containerImgWH">
                        <img src={PictBelowMainDeck} alt="" />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-start', padding: '5%'}}>
                        <div style={{height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: '0px 10px'}}>
                            <BitLamp text={'MCP E/R ENT'} bitlampColor = "black" alarm={bitlampList["M296"].value}/>
                            <BitLamp text={'Smoke E/R ENT'} bitlampColor = "black" alarm={bitlampList["M298"].value}/>
                            <BitLamp text={'Heat E/R ENT'} bitlampColor = "black" alarm={bitlampList["M300"].value}/>
                            <BitLamp text={'Smoke 1 ER'} bitlampColor = "black" alarm={bitlampList["M302"].value}/>
                            <BitLamp text={'MCP 1 ER'} bitlampColor = "black" alarm={bitlampList["M304"].value}/>
                            <BitLamp text={'Heat 1 65C'} bitlampColor = "black" alarm={bitlampList["M306"].value}/>
                            <BitLamp text={'MCP 2 ER'} bitlampColor = "black" alarm={bitlampList["M308"].value}/>
                            <BitLamp text={'Smoke 2 ER'} bitlampColor = "black" alarm={bitlampList["M310"].value}/>
                            <BitLamp text={'Heat 2 65C'} bitlampColor = "black" alarm={bitlampList["M312"].value}/>
                            <BitLamp text={'Smoke Steering Gear'} bitlampColor = "black" alarm={bitlampList["M314"].value}/>
                            <BitLamp text={'MCP Crew Below Main Deck'} bitlampColor = "black" alarm={bitlampList["M316"].value}/>
                            <BitLamp text={'Smoke Crew room'} bitlampColor = "black" alarm={bitlampList["M318"].value}/>
                        </div>
                    </div>
                </div>
                <div >
                        {
                            lastReceivedDate && <p style={{color: "white", fontSize: 20, marginLeft: '20px'}}>Last received data on {moment(lastReceivedDate).format("yyyy-MM-DD HH:mm:ss")}</p>
                        }
                </div>
           </div>
           <Loading loading={loading}/>
        </>
    );
}