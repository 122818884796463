import "./NewBitLamp.css"

export default function BitLamp({ text, alarm, bitlampColor = "" , fontSize=16}) {
    return (
        // <div className="bitLamp">
            // {
            //     alarm === "true" ?
            //         <div className="bitLampOn"></div> : <div className="bitlampBlack" ></div>
            // }

        //     <div className="textBitLamp" style={{ fontSize }}>{text}</div>
        // </div>

        <div className="bitLampNew">
            <div className="innerBitLampContainerLeft">
            {
                alarm === "true" ?
                    <div className="innerBitLampContainerLeftOn"></div> : <div className="innerBitLampContainerLeftOff" ></div>
            }
            </div>
            <div className="innerBitLampContainerRight">
                <p className="textBitLamp">{text}</p>
            </div>
        </div>
    )
}