// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainBtn {
    width: 100px;
    height: 50px;
    border: none;
}

p{
    font-size: small;
}

.menuBitLampBlack {
    height: 10%;
    width: 100%;
    background: #050512;
}

.menuBitLampRed {
    height: 10%;
    width: 100%;
    background: #E72D2D;
}`, "",{"version":3,"sources":["webpack://./src/Components/NavButton/NavButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".mainBtn {\n    width: 100px;\n    height: 50px;\n    border: none;\n}\n\np{\n    font-size: small;\n}\n\n.menuBitLampBlack {\n    height: 10%;\n    width: 100%;\n    background: #050512;\n}\n\n.menuBitLampRed {\n    height: 10%;\n    width: 100%;\n    background: #E72D2D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
