import "./index.css";
import "../../App.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import { useCookies } from "react-cookie";
import BitLamp from "../../Components/BitLamp/BitLamp";
import BitLamp3 from "../../Components/BitLamp3/BitLamp3";
import { Button } from "react-bootstrap";
import BoxSwitchToggle from "../../Components/BoxSwitchToggle/BoxSwitchToggle";
import React, { useEffect, useState } from "react"
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../Helpers/ApplicationHelpers";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/NavBar";
import LampNavButton from "../../Components/LampNavButton/LampNavButton";

export default function NavSignalLightPanel() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [bitlampList, setBitlampList] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [bitlampList, setBitlampList] = useState([
    //     {
    //         text: "ANCHOR",
    //         alarm: true,
    //         coordinate: "M118"
    //     }, {
    //         text: "NUC 1",
    //         alarm: false,
    //         coordinate: "M106"
    //     }, {
    //         text: "NUC 2",
    //         alarm: true,
    //         coordinate: "M108"
    //     }, {
    //         text: "NUC 3",
    //         alarm: true,
    //         coordinate: "M110"
    //     }, {
    //         text: "CUSTOM 1",
    //         alarm: true,
    //         coordinate: "M112"
    //     }, {
    //         text: "CUSTOM 2",
    //         alarm: true,
    //         coordinate: "M114"
    //     },
    //     {
    //         text: "PORT",
    //         alarm: true,
    //         coordinate: "M102"
    //     },
    //     {
    //         text: "MAST 1",
    //         alarm: true,
    //         coordinate: "M96"
    //     },
    //     {
    //         text: "MAST 2",
    //         alarm: true,
    //         coordinate: "M98"
    //     },
    //     {
    //         text: "MAST 3",
    //         alarm: true,
    //         coordinate: "M100"
    //     },
    //     {
    //         text: "STBD",
    //         alarm: true,
    //         coordinate: "M104"
    //     },
    //     {
    //         text: "TOWING",
    //         alarm: true,
    //         coordinate: "M120"
    //     },
    //     {
    //         text: "STERN",
    //         alarm: true,
    //         coordinate: "M116"
    //     }])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (bitlampList.length > 0) {
            const interval = setInterval(async () => {
                // console.log('tes')
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval);
        }


    }, [bitlampList]);

    const loadBitlampValue = async () => {
        try {
            let coordinate = bitlampList.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = bitlampList.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setBitlampList(updatedList);
            setLoading(false);

            // for (let index = 0; index < bitlampList.length; index++) {
            //     const list = bitlampList[index];
            //     let alarmValue = response.find(p => list.plc_coordinate === p.plc_coordinate)
            //     if(alarmValue){
            //         if(alarmValue.value === 1){
            //             list.alarm = true
            //         }else{
            //             list.alarm = false
            //         }
            //     }else{
            //         list.alarm = !list.alarm
            //     }
            // }
            // const correspondingItem = array2.find(item2 => item1.id === item2.id);
            // if (correspondingItem) {
            //     // Update value in array1 from corresponding element in array2
            //     item1.value = correspondingItem.value;
            // }
            // let valueBitlamp = bitlampList;
            // valueBitlamp[0].alarm = !bitlampList[0].alarm;
            // valueBitlamp[1].alarm = !bitlampList[1].alarm;
            // valueBitlamp[2].alarm = !bitlampList[2].alarm;
            // valueBitlamp[3].alarm = !bitlampList[3].alarm;
            // valueBitlamp[4].alarm = !bitlampList[4].alarm;
            // valueBitlamp[5].alarm = !bitlampList[5].alarm;
            // valueBitlamp[6].alarm = !bitlampList[6].alarm;
            // valueBitlamp[7].alarm = !bitlampList[7].alarm;
            // valueBitlamp[8].alarm = !bitlampList[8].alarm;
            // valueBitlamp[9].alarm = !bitlampList[9].alarm;
            // valueBitlamp[10].alarm = !bitlampList[10].alarm;
            // valueBitlamp[11].alarm = !bitlampList[11].alarm;
            // valueBitlamp[12].alarm = !bitlampList[12].alarm;
            // setBitlampList(valueBitlamp.slice(0))
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "navigation_light", cookies.vesselId);
            setBitlampList(response);
            if (response.length < 1) {
                setLoading(false);
            }
        } catch (exception) {

        }
    }


    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Navigation & Signal Light'}/>
                    <BottomNavBar/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="imgShipShape"></div>
                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', width: '70%'}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', height: '500px', padding: '5%'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"ANCHOR"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"PORT"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"STBD"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"STERN"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"TOWING"}lamp={false} /></div>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"MAST 1"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"MAST 2"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"MAST 3"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"SPARE 1"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"SPARE 2"}lamp={false} /></div>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"NUC 1"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"NUC 2"}lamp={false} /></div>
                                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}><LampNavButton text1={"NUC 3"}lamp={false} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}