import "./index.css";
// import "../../SMSApp.css";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import { useStateManager } from "react-select";
import moment from "moment";

export default function SMSMainSwitchBoard(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([5, 11, 12]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    // const [valueMCBOnG1, setValueMCBOnG1] = useState();
    // const [valueMCBOffG1, setValueMCBOffG1] = useState();
    // const [valuePowerRelayG1, setValuePowerRelayG1] = useState();
    // const [valueOvercurrentRelayG1, setValueOvercurrentRelayG1] = useState();
    // const [valueRevPowerRelayG1, setValueRevPowerRelayG1] = useState();
    // const [valueVoltageRelayG1, setValueVoltageRelayG1] = useState();
    // const [valueAE1MCCBOnG1, setValueAE1MCCBOnG1] = useState();
    // const [valueAE1MCCBOffG1, setValueAE1MCCBOffG1] = useState();
    // const [valueSynchronizer, setValueSynchronizer] = useState();
    // const [valueMCBOnG2, setValueMCBOnG2] = useState();
    // const [valueMCBOffG2, setValueMCBOffG2] = useState();
    // const [valuePowerRelayG2, setValuePowerRelayG2] = useState();
    // const [valueOvercurrentRelayG2, setValueOvercurrentRelayG2] = useState();
    // const [valueRevPowerRelayG2, setValueRevPowerRelayG2] = useState();
    // const [valueVoltageRelayG2, setValueVoltageRelayG2] = useState();
    // const [valueAE2MCCBOnG2, setValueAE2MCCBOnG2] = useState();
    // const [valueAE2MCCBOffG2, setValueAE2MCCBOffG2] = useState();
    // const [valueMCBOnShore, setValueMCBOnShore] = useState();
    // const [valueMCBOffShore, setValueMCBOffShore] = useState();
    // const [valuePowerRelayShore, setValuePowerRelayShore] = useState();
    // const [valueOvercurrentRelayShore, setValueOvercurrentRelayShore] = useState();
    // const [valueRevPowerRelayShore, setValueRevPowerRelayShore] = useState();
    // const [valueVoltageRelayShore, setValueVoltageRelayShore] = useState();
    // const [listBitlamp, setListBitlamp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bitlampList, setBitlampList] = useState({

        M328:
        {
            text: "MCBOnG1",
            alarm: true,
            coordinate: "M328",
            value: "false"
        },
        M340:
        {
            text: "MCBOnG2",
            alarm: true,
            coordinate: "M340",
            value: "false"
        },
        M352:
        {
            text: "MCBOnShore",
            alarm: true,
            coordinate: "M352",
            value: "false"
        },
        M330:
        {
            text: "MCBOffG1",
            alarm: true,
            coordinate: "M330",
            value: "false"
        },
        M342:
        {
            text: "MCBOffG2",
            alarm: true,
            coordinate: "M342",
            value: "false"
        },
        M354:
        {
            text: "MCBOffShore",
            alarm: true,
            coordinate: "M354",
            value: "false"
        },
        M332:
        {
            text: "Power Relay G1",
            alarm: true,
            coordinate: "M332",
            value: "false"
        },
        M344:
        {
            text: "Power Relay G2",
            alarm: true,
            coordinate: "M344",
            value: "false"
        },
        // M310:
        // {
        //     text: "Power Relay Shore",
        //     alarm: true,
        //     coordinate: "M310",
        //     value: "false"
        // },
        M334:
        {
            text: "Overcurrent Relay G1",
            alarm: true,
            coordinate: "M334",
            value: "false"
        },
        M346:
        {
            text: "Overcurrent Relay G2",
            alarm: true,
            coordinate: "M346",
            value: "false"
        },
        M336:
        {
            text: "Phase Failure Indicator G1",
            alarm: true,
            coordinate: "M336",
            value: "false"
        },
        M348:
        {
            text: "Phase Failure Indicator G2",
            alarm: true,
            coordinate: "M348",
            value: "false"
        },
        // M330:
        // {
        //     text: "Overcurrent Relay Shore",
        //     alarm: true,
        //     coordinate: "M330",
        //     value: "false"
        // },
        // M314:
        // {
        //     text: "Rev Power Relay G1",
        //     alarm: true,
        //     coordinate: "M314",
        //     value: "false"
        // },
        // M348:
        // {
        //     text: "Rev Power Relay G2",
        //     alarm: true,
        //     coordinate: "M348",
        //     value: "false"
        // },
        // M332:
        // {
        //     text: "Rev Power Relay Shore",
        //     alarm: true,
        //     coordinate: "M332",
        //     value: "false"
        // },
        // M350:
        // {
        //     text: "Voltage Relay G1",
        //     alarm: true,
        //     coordinate: "M350",
        //     value: "false"
        // },
        // M316:
        // {
        //     text: "Voltage Relay G2",
        //     alarm: true,
        //     coordinate: "M316",
        //     value: "false"
        // },
        // M334:
        // {
        //     text: "Voltage Relay Shore",
        //     alarm: true,
        //     coordinate: "M334",
        //     value: "false"
        // },
        // M392:
        // {
        //     text: "AE 1 MCCB On",
        //     alarm: true,
        //     coordinate: "M392",
        //     value: "false"
        // },
        // M394:
        // {
        //     text: "AE 1 MCCB Off",
        //     alarm: true,
        //     coordinate: "M394",
        //     value: "false"
        // },
        // M396:
        // {
        //     text: "AE 2 MCCB On",
        //     alarm: true,
        //     coordinate: "M396",
        //     value: "false"
        // },
        // M398:
        // {
        //     text: "AE 2 MCCB Off",
        //     alarm: true,
        //     coordinate: "M398",
        //     value: "false"
        // },
        M318:
        {
            text: "Synchronizer",
            alarm: true,
            coordinate: "M318",
            value: "false"
        },
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                // console.log(vesselId);
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     console.log(bitlampList)
    //     console.log(bitlampList["M324"].value);
    //     console.log(bitlampList["M346"].value);
    // }, [bitlampList]);
    // useEffect(() => {
    //     if (listBitlamp.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listBitlamp]);

    // const loadBilampCoordinate = async () => {
    //     try {
    //         let response = await getPLCCoordinate(cookies.token, "msb", cookies.vesselId);
    //         let newList = response.filter(p => p.is_output === "0")
    //         setListBitlamp(newList);
    //         if(response.length < 1){
    //             setLoading(false);
    //         }
    //     } catch (exception) {

    //     }
    // }

    // const loadBilampCoordinate = async () => {
    //     try {
    //         let response = await getPLCCoordinate(cookies.token, "msb", smsSerialNumber);
    //         let newList = response.filter(p => p.is_output === "0")
    //         setListBitlamp(newList);
    //         if(response.length < 1){
    //             setLoading(false);
    //         }
    //     } catch (exception) {

    //     }
    // }

    // const loadBitlampValue = async () => {
    //     try {
    //         let coordinate = listBitlamp.map(p => p.plc_coordinate);
    //         let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
    //         let updatedList = listBitlamp.map(item => {
    //             let alarmValue = response.find(p => item.plc_coordinate === p.field);
    //             if (alarmValue) {
    //                 return { ...item, alarm: alarmValue.value === 1 };
    //             } else {
    //                 return { ...item, alarm: item.alarm };
    //             }
    //         });
    //         setListBitlamp(updatedList);
    //         setLoading(false);

    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].received_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
        } catch (exception){
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainPageMSB">
                <div className="containerBoxMSB">
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>GENERATOR 1</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '300px', height: '500px'}}>
                                <BitLamp text={'MCCB ON Status'} bitlampColor = "black"  alarm={bitlampList["M328"].value}/>
                                <BitLamp text={'MCCB OFF Status'} bitlampColor = "black" alarm={bitlampList["M330"].value}/>
                                <BitLamp text={'Power Relay'} bitlampColor = "black" alarm={bitlampList["M332"].value}/>
                                <BitLamp text={'Overcurrent Relay'} bitlampColor = "black" alarm={bitlampList["M334"].value}/>
                                <BitLamp text={'Phase Failure Indicator'} bitlampColor = "black" alarm={bitlampList["M336"].value}/>
                                {/* <BitLamp text={'Rev Power Relay'} bitlampColor = "black" alarm={bitlampList["M314"].value}/>
                                <BitLamp text={'Voltage Relay'} bitlampColor = "black" alarm={bitlampList["M350"].value}/>
                                <BitLamp text={'AE 1 MCCB ON'} bitlampColor = "black" alarm={bitlampList["M392"].value}/>
                                <BitLamp text={'AE 1 MCCB OFF'} bitlampColor = "black" alarm={bitlampList["M394"].value}/> */}
                            </div>
                        </div>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>SYNCHRONIZER</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '300px', height: '500px', marginTop: '15px'}}>
                                <BitLamp text={'Synchronizer'} bitlampColor = "black" alarm={bitlampList["M318"].value}/>
                            </div>
                        </div>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>GENERATOR 2</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '300px', height: '500px'}}>
                                <BitLamp text={'MCCB ON Status'} bitlampColor = "black" alarm={bitlampList["M340"].value}/>
                                <BitLamp text={'MCCB OFF Status'} bitlampColor = "black" alarm={bitlampList["M342"].value}/>
                                <BitLamp text={'Power Relay'} bitlampColor = "black" alarm={bitlampList["M344"].value}/>
                                <BitLamp text={'Overcurrent Relay'} bitlampColor = "black" alarm={bitlampList["M346"].value}/>
                                <BitLamp text={'Phase Failure Indicator'} bitlampColor = "black" alarm={bitlampList["M348"].value}/>
                                {/* <BitLamp text={'Rev Power Relay'} bitlampColor = "black" alarm={bitlampList["M348"].value}/>
                                <BitLamp text={'Voltage Relay'} bitlampColor = "black" alarm={bitlampList["M316"].value}/>
                                <BitLamp text={'AE 2 MCCB ON'} bitlampColor = "black" alarm={bitlampList["M396"].value}/>
                                <BitLamp text={'AE 2 MCCB OFF'} bitlampColor = "black" alarm={bitlampList["M398"].value}/> */}
                            </div>
                        </div>
                        <div style={{width: '300px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px', padding: '2%'}}>
                            <h4 style={{color: 'white'}}>SHORE CONNECTION</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '300px', height: '500px'}}>
                                <BitLamp text={'MCCB ON Status'} bitlampColor = "black" alarm={bitlampList["M352"].value}/>
                                <BitLamp text={'MCCB OFF Status'} bitlampColor = "black" alarm={bitlampList["M354"].value}/>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                {/* <BitLamp text={'Power Relay'} bitlampColor = "black" alarm={bitlampList["M310"].value}/>
                                <BitLamp text={'Overcurrent Relay'} bitlampColor = "black" alarm={bitlampList["M330"].value}/>
                                <BitLamp text={'Rev Power Relay'} bitlampColor = "black" alarm={bitlampList["M332"].value}/>
                                <BitLamp text={'Voltage Relay'} bitlampColor = "black" alarm={bitlampList["M334"].value}/> */}
                            </div>
                        </div>
                    </div>
                    <div >
                        {
                            lastReceivedDate && <p style={{color: "white", fontSize: 20, marginLeft: '20px'}}>Last received data on {moment(lastReceivedDate).format("yyyy-MM-DD HH:mm:ss")}</p>
                        }
                    </div>
                </div>
            </div>
            <Loading loading={loading}/>
        </>
    );
}