// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer{
    width: 100px;
    height: 90px;
    border: 1px #1e3a8a solid;
    background-color: #0d1a3d;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.containerLampOff{
    border: 1px #020617 solid;
    background-color: #020617 ;
    width: 100%;
    height: 15%;
    /* margin-top: 20px; */
}

.containerLampOn{
    border: 1px #e11d48 solid;
    background-color: #e11d48 ;
    width: 100%;
    height: 15%;
    /* margin-top: 20px; */
}

.containerTextTitle{
    /* border: 1px #e11d48 solid;
    background-color: #e11d48 ; */
    width: 100%;
    height: 20%;
    /* margin-top: 20px; */
}

.textBitLampNav {
    /* margin-left: 5px; */
    color: #f1f5f9;
    font-size: small;
    /* font-family: "Disket Mono"; */
    font-weight: lighter;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewNavLightBitLamp/NewNavLightBitLamp.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,WAAW;IACX,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,WAAW;IACX,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI;iCAC6B;IAC7B,WAAW;IACX,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,gCAAgC;IAChC,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".mainContainer{\n    width: 100px;\n    height: 90px;\n    border: 1px #1e3a8a solid;\n    background-color: #0d1a3d;\n    padding: 0 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n}\n\n.containerLampOff{\n    border: 1px #020617 solid;\n    background-color: #020617 ;\n    width: 100%;\n    height: 15%;\n    /* margin-top: 20px; */\n}\n\n.containerLampOn{\n    border: 1px #e11d48 solid;\n    background-color: #e11d48 ;\n    width: 100%;\n    height: 15%;\n    /* margin-top: 20px; */\n}\n\n.containerTextTitle{\n    /* border: 1px #e11d48 solid;\n    background-color: #e11d48 ; */\n    width: 100%;\n    height: 20%;\n    /* margin-top: 20px; */\n}\n\n.textBitLampNav {\n    /* margin-left: 5px; */\n    color: #f1f5f9;\n    font-size: small;\n    /* font-family: \"Disket Mono\"; */\n    font-weight: lighter;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
