import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getSupplierTypeCount, getSupplierType, isTokenValid, insertUpdateSupplierType, getSupplierTypeById,
    deleteSupplierType, getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function SupplierTypePage() {
    const [listSupplierType, setListSupplierType] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [supplierTypeId, setSupplierTypeId] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [supplierTypeModal, setSupplierTypeModal] = useState("")
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_supplier_type.order_name1")}`,
        value: "supplier_type_code"
    });
    const orderByList = [{
        label: `${t("index_supplier_type.order_name1")}`,
        value: "supplier_type_name"
    }, {
        label: `${t("index_supplier_type.order_name2")}`,
        value: "supplier_type_code"
    }];
    const [supplierTypeCount, setSupplierTypeCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [supplierType, setSupplierType] = useState({
        id: "",
        supplier_type_name: "",
        supplier_type_code: "",
        ext_customer_id: cookies.extCustomerId
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadSupplierType();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadSupplierType();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadSupplierType();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadSupplierType();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadSupplierType();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadSupplierType()
    }, [descending]);

    useEffect(() => {
        if (supplierTypeId !== "" && supplierTypeId !== undefined) {
            initSupplierType();
        }

    }, [supplierTypeId]);

    useEffect(() => {
        if (!supplierTypeModal) {
            setSupplierTypeId("")
        }
    }, [supplierTypeModal])


    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeSupplierType();
    }, [removeId])

    const loadSupplierType = async () => {
        try {
            let count = await getSupplierTypeCount(cookies.token, cookies.extCustomerId, searchQuery, false);
            setSupplierTypeCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
    
    
            let response = await getSupplierType(cookies.token, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListSupplierType(response)
            setLoading(false);
            setResetSearch(0);      
            setIsSearched(0)          
        } catch (exception) {
            console.log(exception)
        }
    }

    const clearSupplierTypeModal = async () => {
        let supplierTypeTmp = {};
        supplierTypeTmp.id = 0;
        supplierTypeTmp.supplier_type_name = "";
        supplierTypeTmp.supplier_type_code = "";
        supplierTypeTmp.ext_customer_id = cookies.extCustomerId
        setSupplierType(supplierTypeTmp);
    }

    const saveData = async () => {
        try {
            let supplierTypeTmp = supplierType;
            supplierTypeTmp.ext_customer_id =  supplierTypeTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : supplierType.ext_customer_id
            let response = await insertUpdateSupplierType(cookies.token, supplierTypeTmp);
            if (response.error_code === 0) {
                alert(`${t("index_supplier_type.alert_success_save_data")}`);
                setSupplierTypeModal(false);
                clearSupplierTypeModal();
                loadSupplierType();
                setDisabledButton(false);
            } else {
                alert(`${t("index_supplier_type.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initSupplierType = async () => {
        try {
            let response = await getSupplierTypeById(cookies.token, supplierTypeId);
            if (response) {
                setSupplierType({
                    ...supplierType,
                    id: response.id,
                    supplier_type_name: response.supplier_type_name,
                    supplier_type_code: response.supplier_type_code,
                })
            }
            setSupplierTypeModal(true);

        } catch (exception) {
            console.log(exception)
        }
    }

    const removeSupplierType = async () => {
        try {
            let response = await deleteSupplierType(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_supplier_type.delete_success_alert")}`);
                loadSupplierType();
            } else {
                alert(`${t("index_supplier_type.delete_failed_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_supplier_type.order_name1")}`,
                value: "supplier_type_code"
            })
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_supplier_type.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                // navigate("/Country/Detail", { state: { countryId: 0 } }) 
                                setSupplierType({ ...supplierType, id: 0 })
                                setSupplierTypeModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_supplier_type.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_supplier_type.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadSupplierType();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadSupplierType();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadSupplierType();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_supplier_type.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_supplier_type.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_supplier_type.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_supplier_type.table_name4")}</div>
                                </div>
                                {
                                    listSupplierType.map((typeSupplier, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{typeSupplier.supplier_type_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{typeSupplier.supplier_type_code}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                        let idType = typeSupplier.id;
                                                        setSupplierTypeId(idType);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                        let idType = typeSupplier.id;
                                                        if (window.confirm(`${t("index_supplier_type.delete_confirmation")} ${typeSupplier.supplier_type_name}`))
                                                            setRemoveId(idType);
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
                {/* <Modal show={supplierTypeModal} onHide={() => {
                    setSupplierTypeModal(false);
                    clearSupplierTypeModal();
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Data Tipe Supplier`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    setDisabledButton(true);
                                    e.preventDefault();
                                    saveData()
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flexWrap: "nowrap",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Tipe Supplier</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setSupplierType({ ...supplierType, supplier_type_name: e.target.value })
                                                }} value={supplierType.supplier_type_name} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Kode Tipe Supplier</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setSupplierType({ ...supplierType, supplier_type_code: e.target.value })
                                                }} value={supplierType.supplier_type_code} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        paddingTop: 20,
                                    }}>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            clearSupplierTypeModal();
                                            setSupplierTypeModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal> */}

                <Modal show={supplierTypeModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setSupplierTypeModal(false);
                        clearSupplierTypeModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_supplier_type.container_modal_add_data")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_supplier_type.field_supplier_type_name")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setSupplierType({ ...supplierType, supplier_type_name: e.target.value })
                                }} value={supplierType.supplier_type_name} type="text" placeholder="" required></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_supplier_type.field_supplier_type_code")}</Form.Label>
                
                                    <Form.Control onChange={(e) => {
                                        setSupplierType({ ...supplierType, supplier_type_code: e.target.value })
                                    }} value={supplierType.supplier_type_code} type="text" placeholder="" required></Form.Control>
                                
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{width: 100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_supplier_type.save_button")}</Button>
                                <Button style={{width: 100}} className="cancel" variant="danger" onClick={() => {
                                 clearSupplierTypeModal();
                                 setSupplierTypeModal(false);
                                }}>{t("index_supplier_type.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container>
        </>
    );

}