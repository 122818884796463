import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVessel, getVesselCount, deleteVessel, getUserAccessById, insertUpdateVesselCopy } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import Paginations from "../../../Components/Pagination";
import {
    BoxArrowUpRight, FileCheckFill, PencilFill, Trash, CameraFill,
    FilePlusFill, Search, ArrowClockwise, GearWideConnected, Files, EyeFill,
    BoxSeam, ListTask, ClipboardCheck, Clipboard2Data, Calendar, CalendarFill, Calendar3
} from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function VesselPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [listVessel, setListVessel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vesselCount, setVesselCount] = useState(0);
    const [updatedButton, setUpdatedButton] = useState("");
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const [vesselsId, setVesselsId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_vessel.order_name1")}`,
        value: "created_date"
    });
    const [descending, setDescending] = useState(true);
    const orderByList = [
        {
            label: `${t("index_vessel.order_name2")}`,
            value: "created_date"
        },
        {
            label: `${t("index_vessel.order_name2")}`,
            value: "vessel_name"
        },
        {
            label: `${t("index_vessel.order_name3")}`,
            value: "vessel_type_id",

        }]
    const [vesselCopyModal, setVesselCopyModal] = useState("");

    const [vesselCopy, setVesselCopy] = useState({
        id: 0,
        vessel_name: "",
        customer_id: null,
        vessel_type_id: null,
        source_vessel_id: null,
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadVesselData();
                loadAccess()
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setLoading(true);
        console.log(page);
        loadVesselData();
    }, [page]);

    useEffect(() => {
        if (listVessel) {
        }
    }, [listVessel])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadVesselData();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadVesselData();
        }
    }, [orderBy]);

    useEffect(() => {
        // setLoading(true);
        if (vesselsId) {
            removeVessel();
        }

    }, [vesselsId]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadVesselData();
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadVesselData();
        }
    }, [descending]);



    const loadVesselData = async () => {
        try {
            let vesselCount = await getVesselCount(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery, false);
            setVesselCount(vesselCount);
            let totalPage = vesselCount / itemPerPage;
            setTotalPage(totalPage);
            let response = await getVessel(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);

            setListVessel(response);
            console.log(response);
            setResetSearch(0);
            setLoading(false);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeVessel = async () => {
        let response = await deleteVessel(cookies.token, vesselsId);
        if (response === 0) {
            loadVesselData();
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_vessel.order_name1")}`,
                value: "created_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateVesselCopy(cookies.token, vesselCopy);
            if (response.error_code === 0) {
                alert(`${t("index_vessel.alert_success_save_data")}`);
                setVesselCopyModal(false);
            } else {
                alert(`${t("index_vessel.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}> 
                <ContainerBox
                    titleCaption={`${t("index_vessel.header_title")}`}
                    useActionContainer={!disabledCustomerForm}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/Vessel/Detail", { state: { vesselId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_vessel.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_vessel.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadVesselData();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadVesselData();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadVesselData();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    {/* <div style={{ flex: 2 }} className="table-header-content">Foto</div> */}
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_vessel.table_name1")}</div>
                                    {/* <div style={{ flex: 5 }} className="table-header-content">Perusahaan</div> */}
                                    <div style={{ flex: 5 }} className="table-header-content">{t("index_vessel.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name4")}</div>
                                    <div style={{ flex: 3, textAlign:"center" }} className="table-header-content">{t("index_vessel.table_name7")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name6")}</div>
                                    
                                    <div style={{ flex: 2, textAlign:"center" }} className="table-header-content">{t("index_vessel.table_name8")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name9")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name10")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name11")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel.table_name12")}</div>
                                    <div hidden style={{ flex: 2 }} className="table-header-content" >{t("index_vessel.table_name13")}</div>
                                </div>
                                {
                                    listVessel.map((vessel, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            {/* <div style={{ flex: 2 }} className="table-body-content"><p><img src={vessel.base64_image} style={{ maxWidth: 80, maxHeight: 80 }} /></p></div> */}
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{vessel.vessel_name}</p></div>
                                            {/* <div style={{ flex: 5 }} className="table-body-content"><p>{vessel.company_name}</p></div> */}
                                            <div style={{ flex: 5 }} className="table-body-content"><p>{vessel.customer_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{vessel.vessel_type_name}</p></div>
                                           
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/Vessel/Detail", { state: { vesselId: vessel.id } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                                <div style={{ flex: 3, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Link to={`/EquipmentMaintenanceSchedule?vesselId=${vessel.id}`}target="_blank">
                                                        <Button variant="primary"
                                                            onClick={() => {
                                                                // navigate("/EquipmentMaintenanceSchedule", { state: { vesselId: vessel.id } })
                                                            }}
                                                        ><Calendar3 /></Button>
                                                    </Link>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <Button disabled={vessel.sms_serial_number === null} variant="primary" onClick={() => {
                                                            navigate('/SMSDashboard', {state: {smsSerialNumber: vessel.sms_serial_number}});
                                                            // navigate("/Splashscreen");
                                                            // console.log(vessel.sms_serial_number);
                                                            // setCookie("serialNumber", vessel.sms_serial_number, { path: "/" });
                                                            // setCookie("vesselId", vessel.id, { path: "/" });
                                                        }}><EyeFill />
                                                        </Button>
                                                {/* <div style={{ padding: 5 }}>
                                                    <Link to="/MainEngine" target="_blank" style={{
                                                        pointerEvents: vessel.sms_serial_number === null ? "" : ""
                                                    }}>

                                                        <Button disabled={vessel.sms_serial_number === null} variant="primary" onClick={() => {
                                                            // navigate("/Splashscreen");
                                                            setCookie("serialNumber", vessel.sms_serial_number, { path: "/" });
                                                            setCookie("vesselId", vessel.id, { path: "/" });
                                                        }}><EyeFill />
                                                        </Button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={event => {
                                                            navigate("/Vessel/EquipmentStructView", { state: { vesselId: vessel.id, vesselName: vessel.vessel_name, companyId: vessel.company_id } })
                                                            
                                                        }}
                                                    ><GearWideConnected /></Button>
                                                </div></div>
                                           
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={event => {
                                                            navigate("/Vessel/InventoryMaster", { state: { vesselId: vessel.id, vesselName: vessel.vessel_name } })
                                                        }}
                                                    ><BoxSeam /></Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={event => {
                                                            navigate("/Vessel/VesselPhoto", { state: { vesselId: vessel.id, vesselName: vessel.vessel_name } })
                                                        }}
                                                    ><CameraFill /></Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={event => {
                                                            navigate("/Vessel/VesselSchedule", { state: { vesselId: vessel.id, vesselName: vessel.vessel_name } })
                                                        }}
                                                    ><ListTask /></Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={event => {
                                                            navigate("/Vessel/Document", { state: { vesselId: vessel.id, vesselName: vessel.vessel_name } })
                                                        }}
                                                    ><FileCheckFill /></Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={disabledCustomerForm === true ? true : !deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_vessel.delete_confirmation")}  ${vessel.vessel_name}?`)) {
                                                            let vesselsId = vessel.id
                                                            setVesselsId(vesselsId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>

                                            <div hidden style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button className="cheat" variant="primary" onClick={() => {
                                                        setVesselCopyModal(true);
                                                        setVesselCopy({
                                                            ...vesselCopy,
                                                            vessel_name: "",
                                                            customer_id: vessel.customer_id,
                                                            vessel_type_id: vessel.vessel_type_id,
                                                            source_vessel_id: vessel.id
                                                        })
                                                    }}><Files />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />


                <Modal show={vesselCopyModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setVesselCopyModal(false);
                        // clearWarehouseModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Data Kapal </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButton(true);
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>Nama Kapal</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setVesselCopy({ ...vesselCopy, vessel_name: e.target.value })
                                }} value={vesselCopy.vessel_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>




                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>Simpan</Button>
                                <Button className="cancel" variant="danger" onClick={() => {
                                    setVesselCopyModal(false);
                                }}>Batal</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    )


}