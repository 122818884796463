import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import { getTroubeleshootingStatus, deleteTroubleshootingStatus, getTroubleshootingStatusCount, isTokenValid, insertUpdateTroubleshootingStatus, getTroubleshootingStatusById,
        getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";

import { Trans, useTranslation } from "react-i18next";

export default function TroubleshootingStatusPage() {
    const { t, i18n } = useTranslation();

    const [listStatus, setListStatus] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [statusId, setStatusId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [statusModal, setStatusModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_troubleshooting_status.order_name1")}`,
        value: "status_name"
    });
    const orderByList = [{
        label: `${t("index_troubleshooting_status.order_name1")}`,
        value: "status_name"
    }];
    const [descending, setDescending] = useState(false);
    const [troubleshootingStatus, setTroubleshootingStatus] = useState({
        id: "0",
        status_name: "",
        status_code: "",
    })

    
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadTroubleshootingStatus();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadTroubleshootingStatus();
    }, [page]);

    useEffect(() => {
        loadTroubleshootingStatus();
    }, [itemPerPage]);

    useEffect(() => {
        loadTroubleshootingStatus();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadTroubleshootingStatus();
    }, [resetSearch]);

    useEffect(() => {
        loadTroubleshootingStatus()
    }, [descending]);

    useEffect(() => {
        if (statusId !== "" && statusId !== undefined)
            initTroubleshootingStatus();
    }, [statusId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeTroubleshootingStatus();
    }, [removeId])

    useEffect(() => {
        if (!statusModal)
            setStatusId("");
    }, [statusModal])


    const loadTroubleshootingStatus = async () => {
        try {
            let count = await getTroubleshootingStatusCount(cookies.token);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
    
    
            let response = await getTroubeleshootingStatus(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListStatus(response)
            setLoading(false);
            setResetSearch(0)
        } catch (exception) {
            console.log(exception);
        }
    
    }

    const clearStatusModal = async () => {
        let statusTmp = {};
        statusTmp.id = 0;
        statusTmp.status_name = "";
        statusTmp.status_code = "";
        setTroubleshootingStatus(statusTmp);
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateTroubleshootingStatus(cookies.token, troubleshootingStatus);
            if (response.error_code === 0) {
                alert(`${t("index_troubleshooting_status.alert_success_save_data")}`);
                setStatusModal(false);
                clearStatusModal();
                loadTroubleshootingStatus();
                setDisabledButton(false);
            } else {
                alert(`${t("index_troubleshooting_status.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initTroubleshootingStatus = async () => {
        try {
            let response = await getTroubleshootingStatusById(cookies.token, statusId);
            if (response) {
                setTroubleshootingStatus({
                    ...troubleshootingStatus,
                    id: response.id,
                    status_name: response.status_name,
                    status_code: response.status_code,
                })
            }
            setStatusModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeTroubleshootingStatus = async () => {
        try {
            let response = await deleteTroubleshootingStatus(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_troubleshooting_status.delete_success_alert")}`);
                loadTroubleshootingStatus();
            } else {
                alert(`${t("index_troubleshooting_status.delete_failed_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_troubleshooting_status.order_name1")}`,
                value: "status_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_troubleshooting_status.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setStatusModal(true);
                                setTroubleshootingStatus({ ...troubleshootingStatus, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_troubleshooting_status.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_troubleshooting_status.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadTroubleshootingStatus();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadTroubleshootingStatus();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadTroubleshootingStatus();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_troubleshooting_status.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_troubleshooting_status.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_troubleshooting_status.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_troubleshooting_status.table_name4")}</div>
                                </div>
                                {
                                    listStatus.map((status, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{status.status_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{status.status_code}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idStatus = status.id;
                                                        setStatusId(idStatus);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                           let idStatus = status.id;
                                                        if (window.confirm(`${t("index_troubleshooting_status.delete_confirmation")}  ${status.status_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idStatus);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />


               

                <Modal show={statusModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setStatusModal(false);
                        clearStatusModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_troubleshooting_status.modal_add_status")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_troubleshooting_status.field_status")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setTroubleshootingStatus({ ...troubleshootingStatus, status_name: e.target.value })
                                }} value={troubleshootingStatus.status_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_troubleshooting_status.field_status_code")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setTroubleshootingStatus({ ...troubleshootingStatus, status_code: e.target.value })
                                }} value={troubleshootingStatus.status_code} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                        
                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{width:100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_troubleshooting_status.save_button")}</Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                     clearStatusModal();
                                    setStatusModal(false);
                                }}>{t("index_troubleshooting_status.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}