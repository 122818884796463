import './index.css';
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";
import {
    isTokenValid, findAllDueJobInternalStatus, getVesselxEquipmentById, insertNewDueJob,
    findAllDueJobType, updateDueJobStatusToDB, getDueJobById, updateDueJobToDB
} from "../../../../Helpers/ApplicationHelpers";
import { CameraFill, FileCheckFill, Trash, X, Download, EyeFill, FilePlusFill } from "react-bootstrap-icons";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import ContainerBox from "../../../../Components/ContainerBox";
import Sidebar from "../../../../Components/NewSideBarForCustomerPage/NewSideBar";
import DropDownForm from "../../../../Components/DropDownForm";
import { Editor } from '@tinymce/tinymce-react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import Loading from "../../../../Components/Loading";
import templateImg from '../../../../Assets/Images/default-logo.png';
import moment from "moment";
import Select from 'react-select';


export default function CustomerCorrectiveMaintenancePlanDetailPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [smsDeviceId, setSmsDeviceId] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [dueJobId, setDueJobId] = useState("");
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [vesselEquipment, setVesselEquipment] = useState({});
    const [listPeriod, setListPeriod] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState();
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [documentFile, setDocumentFile] = useState();
    const [uploadFIleImageError, setUploadFileImageError] = useState();
    const [disabledButton, setDisabledButton] = useState(false);
    const [showPhotoUploadModal, setShowPhotoUploadModal] = useState(false);
    const [fileImg, setFileImg] = useState();
    const [listDueJobStatus, setListDueJobStatus] = useState([]);
    const [selectedDueJobStatus, setSelectedDueJobStatus] = useState();
    const [currentDueJobStatus, setCurrentDueJobStatus] = useState("NEW");
    const [isRunningHour, setIsRunningHour] = useState(false);
    const [isContactCount, setIsContactCount] = useState(false);
    const [isPeriodic, setIsPeriodic] = useState(false);
    const [isUsingTime, setIsUsingTime] = useState(false);
    const [isRunningHourExecuted, setIsRunningHourExecuted] = useState(false);
    const [isContactCountExecuted, setIsContactCountExecuted] = useState(false);
    const [isPeriodicExecuted, setIsPeriodicExecuted] = useState(false);
    const [isUsingTimeExecuted, setIsUsingTimeExecuted] = useState(false);
    const [isPerformedByCrew, setIsPerformedByCrew] = useState(false);
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [listMaintenanceDueJobType, setListMaintenanceDueJobType] = useState([]);
    const [selectedMaintenanceDueJobType, setSelectedMaintenanceDueJobType] = useState();
    const [selectedVesselEquipment, setSelectedVeselEquipmnet] = useState();
    const [maintenanceProgress, setMaintenanceProgress] = useState();
    const [selectedMaintenanceProgress, setSelectedMaintenanceProgress] = useState();
    const [selectedStatusOption, setSelectedStatusOption] = useState();
    const [disabledButtonOnDueJobDetail, setDisabledButtonOnDueJobDetail] = useState(true);
    const [isCreateDueJobButtonDisabled, setIsCreateDueJobButtonDisabled] = useState(false);
    const [isSaveDueJobButtonDisabled, setIsSaveDueJobButtonDisabled] = useState(false);
    const [isPerformDueJobButtonDisabled, setIsPerformDueJobButtonDisabled] = useState(false);
    const [isSuspendDueJobButtonDisabled, setIsSuspendDueJobButtonDisabled] = useState(false);
    const [isCancelDueJobButtonDisabled, setIsCancelDueJobButtonDisabled] = useState(false);
    const [dueJobDetail, setDueJobDetail] = useState({});
    const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showSuspendModal, setShowSuspendModal] = useState(false);
    const [shouldUpdateToDB, setShouldUpdateToDB] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                initializeDueJobForm();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log(dueJobId);
        if (dueJobId !== "") {
            if (isFirstTimeLoad) {
                console.log("First time load");
                loadExistingDueJob();
            }
        } else {
            setVesselEquipmentId(searchParams.get("vesselEquipmentId"));
        }
    }, [dueJobId]);

    useEffect(() => {
        if (vesselEquipmentId) {
            loadVesselEquipment();
        }
    }, [vesselEquipmentId]);

    useEffect(() => {
        if (vesselEquipment) {
            //setSmsSerialNumber(searchParams.get("smsSerialNumber"));
            setVesselId(vesselEquipment.vessel_id)
            setVesselName(vesselEquipment.vessel_name);
        }
    }, [vesselEquipment]);

    useEffect(() => {
        if (dueJobId === "") {
            if (vesselId) {

            }
        }
    }, [vesselId])

    useEffect(() => {
        if (selectedDueJobStatus) {
            setCurrentDueJobStatus(selectedDueJobStatus.status_name);
            setDueJobDetail({ ...dueJobDetail, maintenance_due_job_internal_status_id: selectedDueJobStatus.id });

            if (selectedDueJobStatus.status_name.toUpperCase() === "In Progress".toUpperCase()) {

            }
        }

    }, [selectedDueJobStatus]);

    useEffect(() => {
        if (selectedMaintenanceDueJobType) {

        }
    }, [selectedMaintenanceDueJobType]);


    useEffect(() => {
        if (listDueJobStatus) {
            setSelectedDueJobStatus(listDueJobStatus.find(p => p.status_name.toUpperCase() === "NEW".toUpperCase()));
            if (location.state) {
                setDueJobId(location.state.dueJobId);

                console.log(dueJobDetail);
                let dueJobStatus = listDueJobStatus.find(p => p.id === dueJobDetail.maintenance_due_job_internal_status_id)
                setSelectedDueJobStatus(dueJobStatus);
            }

        }
    }, [listDueJobStatus]);

    useEffect(() => {
        console.log(currentDueJobStatus);
        console.log(dueJobId);
        if (currentDueJobStatus.toUpperCase() === "Open".toUpperCase()) {
            if (!dueJobId) {
                console.log("Create New Due Job");
                createNewDueJob();
            }
        }
        if (currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase()) {

        }
    }, [currentDueJobStatus]);

    useEffect(() => {
        if (dueJobDetail) {
            if (shouldUpdateToDB) {
                updateDueJob();
            }
        }
    }, [dueJobDetail])

    useEffect(() => {
        if (listMaintenanceDueJobType) {
            if (searchParams.get("maintenancePlanId")) {
                setSelectedMaintenanceDueJobType(listMaintenanceDueJobType.find(p => p.type_name.toUpperCase() === "Preventive".toUpperCase()));
            } else {
                setSelectedMaintenanceDueJobType(listMaintenanceDueJobType.find(p => p.type_name.toUpperCase() === "Corrective".toUpperCase()));
            }
            loadDueJobStatus();
        }
    }, [listMaintenanceDueJobType])

    const loadDueJobStatus = async () => {
        try {
            let result = await findAllDueJobInternalStatus(cookies.token);
            console.log(result);
            setListDueJobStatus(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const initializeDueJobForm = () => {
        loadMaintenanceDueJobType();


        //setCurrentDueJobStatus("New");

    }

    const loadExistingDueJob = async () => {
        try {
            console.log("Load Existing Due Job");
            let result = await getDueJobById(cookies.token, dueJobId);
            console.log(result);
            setDueJobDetail(result);

            setVesselEquipmentId(result.vessel_equipment_id);
            if (result.vessel_x_equipment_mp_id) {
                setSelectedMaintenanceDueJobType(listMaintenanceDueJobType.find(p => p.type_name.toUpperCase() === "Preventive".toUpperCase()));
            } else {
                setSelectedMaintenanceDueJobType(listMaintenanceDueJobType.find(p => p.type_name.toUpperCase() === "Corrective".toUpperCase()));
            }


        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadMaintenanceDueJobType = async () => {
        try {
            let result = await findAllDueJobType(cookies.token);
            setListMaintenanceDueJobType(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselEquipment = async () => {
        try {
            let result = await getVesselxEquipmentById(cookies.token, vesselEquipmentId);
            setVesselEquipment(result);

        } catch (exception) {
            console.log(exception);
        }
    }

    const createNewDueJob = async () => {
        try {
            console.log(listMaintenanceDueJobType);
            console.log(selectedMaintenanceDueJobType);
            let newDueJob = {
                id: 0,
                vessel_equipment_id: vesselEquipmentId,
                vessel_id: vesselId,
                sms_serial_number: smsSerialNumber ? smsSerialNumber : null,
                sms_device_id: smsDeviceId ? smsDeviceId : null,
                vessel_x_equipment_mp_id: dueJobDetail.vessel_x_equipment_mp_id,
                maintenance_due_job_internal_status_id: selectedDueJobStatus.id,
                maintenance_due_job_type_id: selectedMaintenanceDueJobType.id,
                due_job_start_date: dueJobDetail.due_job_start_date,
                due_job_deadline: dueJobDetail.due_job_deadline,
                due_job_running_hour: dueJobDetail.due_job_running_hour,
                due_job_contact_count: dueJobDetail.due_job_contact_count,
                due_job_location: dueJobDetail.due_job_location,
                due_job_performed_by_company: dueJobDetail.due_job_performed_by_company,
                due_job_description: dueJobDetail.due_job_description,
                due_job_pic_name: dueJobDetail.due_job_pic_name,
                due_job_pic_phone_number: dueJobDetail.due_job_pic_phone_number,
                due_job_pic_email: dueJobDetail.due_job_pic_email
            };
            console.log(newDueJob);
            let result = await insertNewDueJob(cookies.token, newDueJob);
            if (result.error_code === 0) {
                console.log(result);
                setDueJobId(result.data.id);
                setDueJobDetail({ ...dueJobDetail, due_job_id: result.data.id });
                alert("Due job successfully saved");
            }
            else {
                alert("Failed to create due job");
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const updateDueJobStatus = async () => {
        try {
            let result = await updateDueJobStatusToDB(cookies.token, dueJobId, selectedDueJobStatus.id);
            if (result.error_code === 0) {
                updateDueJob();
            }
            else {
                alert("Failed to save");
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const updateDueJob = async () => {
        try {
            let result = await updateDueJobToDB(cookies.token, dueJobDetail);
            if (result.error_code === 0) {
                alert("Due job successfully saved");
            }
            else {
                alert("Failed to save");
            }
        }
        catch (exception) {
            console.log(exception);
        }
        setShouldUpdateToDB(false);
    }


    const renderMaintenanceDetail = () => {
        if (currentDueJobStatus.toUpperCase() === "IN PROGRESS" || currentDueJobStatus.toUpperCase() === "CLOSED") {
            return (
                <ContainerBox
                    titleCaption={'MAINTENANCE DETAIL'}
                    useActionContainer={true}
                    actionContainerChild={
                        <Link to={`/Customer/DueJob/Detail/PhotoAndDocument?maintenanceDueJobId=${dueJobId}`} target="_blank">
                        <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                            // setLoading(true);
                            // setIsPrint(true);
                        }}>
                            <div className="btnContainer">
                                <div className="btnContainerLeft"><FilePlusFill size={32} /></div>
                                <div className="btnContainerRight">{'Photo and Document'}</div>
                            </div>
                        </Button>
                        </Link>
                    }
                    childContent={
                        <div className='formTopContanerChild'>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Maintenance Start Date'}</Form.Label>
                                        <Form.Control
                                            disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            type="datetime-local"
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_start_date: moment(e.target.value).format("yyyy-MM-DD HH:mm:ss") });
                                            }}
                                            value={moment(dueJobDetail.maintenance_start_date).isValid() ? moment(dueJobDetail.maintenance_start_date).format("yyyy-MM-DD HH:mm:ss") : null}
                                        />
                                    </Form.Group>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Maintenance End Date'}</Form.Label>
                                        <Form.Control
                                            disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            type="datetime-local"
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_end_date: moment(e.target.value).format("yyyy-MM-DD HH:mm:ss") });
                                            }}
                                            value={moment(dueJobDetail.maintenance_end_date).isValid() ? moment(dueJobDetail.maintenance_end_date).format("yyyy-MM-DD HH:mm:ss") : null}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Running Hour'}</Form.Label>
                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_running_hour} placeholder=""
                                            caption={"Running Hours"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_running_hour: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Contact Count'}</Form.Label>
                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_contact_count} placeholder=""
                                            caption={"Contact Count"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_contact_count: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Check
                                            style={{ fontSize: "large" }}
                                            checked={isPerformedByCrew}
                                            label={"Is Performed By Crew"}
                                            name="selected_is_repeating"
                                            type={"checkbox"}
                                            id={`selected_repeating-chk`}
                                            onChange={(e) => {
                                                if (!isPerformedByCrew) {
                                                    setIsPerformedByCrew(true);
                                                } else {
                                                    setIsPerformedByCrew(false);
                                                }
                                            }}
                                            disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                        />
                                    </Form.Group>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Performed By'}</Form.Label>
                                        <Form.Control type='text'
                                            disabled={(!isPerformedByCrew && currentDueJobStatus.toUpperCase()) === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_performed_by_company}
                                            placeholder=""
                                            caption={"Performed By"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_performed_by_company: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'PIC Name'}</Form.Label>
                                        <Form.Control type='text'
                                            disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_pic_name} placeholder=""
                                            caption={"Name"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_pic_name: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'PIC Phone Number'}</Form.Label>
                                        <Form.Control type='phone' disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_pic_phone_number} placeholder=""
                                            caption={"Phone Number"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_pic_phone_number: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'PIC Email'}</Form.Label>
                                        <Form.Control type='email' disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_pic_email} placeholder=""
                                            caption={"Email"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_pic_email: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Maintenance Location'}</Form.Label>
                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            value={dueJobDetail.maintenance_location} placeholder=""
                                            caption={"Location"}
                                            req={true}
                                            onChange={(e) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_location: e.target.value });
                                            }} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                        <Form.Label>Maintenance Summary</Form.Label>
                                        <Editor
                                            apiKey='pbjm9oydw65b4p46e89nz1eav5rqqpy66e0ltrlnr15kc5dq'
                                            disabled={currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase() ? false : true}
                                            onEditorChange={(evt, editor) => {
                                                setDueJobDetail({ ...dueJobDetail, maintenance_summary: evt });
                                            }}
                                            value={dueJobDetail.maintenance_summary}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink',
                                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                    'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                ],
                                                toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                image_title: true,
                                                automatic_uploads: true,
                                                /*
                                                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                  images_upload_url: 'postAcceptor.php',
                                                  here we add custom filepicker only to Image dialog
                                                */
                                                file_picker_types: 'image',
                                                /* and here's our custom image picker*/
                                                file_picker_callback: (cb, value, meta) => {
                                                    const input = document.createElement('input');
                                                    input.setAttribute('type', 'file');
                                                    input.setAttribute('accept', 'image/*');

                                                    input.addEventListener('change', (e) => {
                                                        const file = e.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            /*
                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                              registry. In the next release this part hopefully won't be
                                                              necessary, as we are looking to handle it internally.
                                                            */

                                                            // console.log(editorRef.current.getContent())
                                                            const id = 'blobid' + (new Date()).getTime();
                                                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                            //   editorRef.current.editorUpload.blobCache

                                                            //Editor.activeEditor.editorUpload.blobCache;

                                                            const base64 = reader.result.split(',')[1];
                                                            const blobInfo = blobCache.create(id, file, base64);
                                                            blobCache.add(blobInfo);

                                                            /* call the callback and populate the Title field with the file name */
                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                        });
                                                        reader.readAsDataURL(file);
                                                    });

                                                    input.click();
                                                },
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                        </div>
                    }
                />
            )
        }
        return (<></>);
    }

    return (
        <>
            <div className="mainCMDetailDashboard">
                <Sidebar />
                <Container fluid className="mainContainerCMDetailDashboard">
                    <div className="innerChildMainContainerCMDetailDashboard">
                        <ContainerBox
                            titleCaption={`${vesselName} DUE JOB`}
                            childContent={
                                <div style={{ display: "flex", width: "100%", flex: 1, flexDirection: "column", alignItems: 'center'}}>
                                    {/* <div className="mainCMDetailDashboard">
                                        <Container fluid className="mainContainerCMDetailDashboard">
                                            <div className="innerChildMainContainerCMDetailDashboard"> */}
                                                <div style={{ width: '100%', height: '20px' }}></div>

                                                <ContainerBox
                                                    titleCaption={'EQUIPMENT DETAIL'}
                                                    childContent={

                                                        <div className='formTopContanerChild'>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormBotContaner">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Group'}</Form.Label>
                                                                        <Form.Control type='text' disabled value={vesselEquipment && vesselEquipment.inventory_group_name} placeholder="" />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormBotContaner">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Position'}</Form.Label>
                                                                        <Form.Control type='text' disabled value={vesselEquipment && vesselEquipment.equipment_position} placeholder="" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormBotContaner">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Brand'}</Form.Label>
                                                                        <Form.Control type='text' disabled value={vesselEquipment && vesselEquipment.brand_name} placeholder="" />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormBotContaner">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Inventory Master Name'}</Form.Label>
                                                                        <Form.Control type='text' disabled value={vesselEquipment && vesselEquipment.inventory_master_name} placeholder="" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormBotContaner">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Part Number'}</Form.Label>
                                                                        <Form.Control type='text' disabled value={vesselEquipment && vesselEquipment.part_number} placeholder="" />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormBotContaner">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Serial Number'}</Form.Label>
                                                                        <Form.Control type='text' disabled value={vesselEquipment && vesselEquipment.serial_number} placeholder="" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                />

                                                <div style={{ width: '100%', height: '20px' }}></div>

                                                <ContainerBox
                                                    titleCaption={'DUE JOB DETAIL'}
                                                    useActionContainer={true}
                                                    actionContainerChild={
                                                        <div style={{ marginRight: 20, width: "100%", flex: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                            <p style={{ color: "white", fontSize: "x-large", fontWeight: "bold" }}>DUE JOB STATUS: </p>
                                                            <p style={{ color: "white", fontSize: "x-large", fontWeight: "bold" }} >{currentDueJobStatus}</p>
                                                        </div>
                                                    }
                                                    childContent={
                                                        <div className='formTopContanerChild'>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Start Date'}</Form.Label>
                                                                        <Form.Control
                                                                            disabled={currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() ? false : true}
                                                                            type="date"
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_start_date: moment(e.target.value).format("yyyy-MM-DD") });
                                                                            }}
                                                                            value={moment(dueJobDetail.due_job_start_date).format("yyyy-MM-DD")}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Deadline Date'}</Form.Label>
                                                                        <Form.Control
                                                                            disabled={currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() ? false : true}
                                                                            type="date"
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_deadline: moment(e.target.value).format("yyyy-MM-DD") });
                                                                            }}
                                                                            value={moment(dueJobDetail.due_job_deadline).format("yyyy-MM-DD")}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Running Hour'}</Form.Label>
                                                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() || currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() ? false : true} value={dueJobDetail.due_job_running_hour} placeholder=""
                                                                            caption={"Running Hours"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_running_hour: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Contact Count'}</Form.Label>
                                                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() || currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() ? false : true} value={dueJobDetail.due_job_contact_count} placeholder=""
                                                                            caption={"Contact Count"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_contact_count: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'PIC Name'}</Form.Label>
                                                                        <Form.Control type='text'
                                                                            disabled={currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() || currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() ? false : true}
                                                                            value={dueJobDetail.due_job_pic_name} placeholder=""
                                                                            caption={"Name"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_pic_name: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'PIC Phone Number'}</Form.Label>
                                                                        <Form.Control type='phone' disabled={currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() || currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() ? false : true}
                                                                            value={dueJobDetail.due_job_pic_phone_number} placeholder=""
                                                                            caption={"Phone Number"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_pic_phone_number: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'PIC Email'}</Form.Label>
                                                                        <Form.Control type='email' disabled={currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() || currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() ? false : true}
                                                                            value={dueJobDetail.due_job_pic_email} placeholder=""
                                                                            caption={"Email"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_pic_email: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Maintenance Location'}</Form.Label>
                                                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() || currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() ? false : true}
                                                                            value={dueJobDetail.due_job_location} placeholder=""
                                                                            caption={"Location"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_location: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Perform By'}</Form.Label>
                                                                        <Form.Control type='text' disabled={currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() || currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() ? false : true}
                                                                            value={dueJobDetail.due_job_performed_by_company} placeholder=""
                                                                            caption={"Email"}
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_performed_by_company: e.target.value });
                                                                            }} />
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{ flex: 1 }}>

                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Form.Group className="formGroupFormPlanningContanerNew">
                                                                        <Form.Label>Due Job Description</Form.Label>
                                                                        <Editor
                                                                            apiKey='pbjm9oydw65b4p46e89nz1eav5rqqpy66e0ltrlnr15kc5dq'
                                                                            disabled={currentDueJobStatus.toUpperCase() === "OPEN".toUpperCase() || currentDueJobStatus.toUpperCase() === "NEW".toUpperCase() ? false : true}
                                                                            value={dueJobDetail.due_job_description}
                                                                            onEditorChange={(evt, editor) => {
                                                                                setDueJobDetail({ ...dueJobDetail, due_job_description: evt });
                                                                            }}
                                                                            init={{
                                                                                height: 500,
                                                                                menubar: false,
                                                                                plugins: [
                                                                                    'advlist', 'autolink',
                                                                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                                    'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                                ],
                                                                                toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                                toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                                image_title: true,
                                                                                automatic_uploads: true,
                                                                                /*
                                                                                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                                  images_upload_url: 'postAcceptor.php',
                                                                                  here we add custom filepicker only to Image dialog
                                                                                */
                                                                                file_picker_types: 'image',
                                                                                /* and here's our custom image picker*/
                                                                                file_picker_callback: (cb, value, meta) => {
                                                                                    const input = document.createElement('input');
                                                                                    input.setAttribute('type', 'file');
                                                                                    input.setAttribute('accept', 'image/*');

                                                                                    input.addEventListener('change', (e) => {
                                                                                        const file = e.target.files[0];
                                                                                        const reader = new FileReader();
                                                                                        reader.addEventListener('load', () => {
                                                                                            /*
                                                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                                                              registry. In the next release this part hopefully won't be
                                                                                              necessary, as we are looking to handle it internally.
                                                                                            */

                                                                                            // console.log(editorRef.current.getContent())
                                                                                            const id = 'blobid' + (new Date()).getTime();
                                                                                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                                            //   editorRef.current.editorUpload.blobCache

                                                                                            //Editor.activeEditor.editorUpload.blobCache;

                                                                                            const base64 = reader.result.split(',')[1];
                                                                                            const blobInfo = blobCache.create(id, file, base64);
                                                                                            blobCache.add(blobInfo);

                                                                                            /* call the callback and populate the Title field with the file name */
                                                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                                                        });
                                                                                        reader.readAsDataURL(file);
                                                                                    });

                                                                                    input.click();
                                                                                },
                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }
                                                />

                                                <div style={{ width: '100%', height: '20px' }}></div>

                                                {renderMaintenanceDetail()}
                                            {/* </div>
                                        </Container>

                                    </div> */}
                                    <div style={{ display: "flex", flexDirection: "column", padding: '10px', width: '100%' }}>
                                        {
                                            currentDueJobStatus.toUpperCase() === "New".toUpperCase() &&
                                            <>
                                                <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={isCreateDueJobButtonDisabled}
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to create this due job?")) {
                                                            setSelectedDueJobStatus(listDueJobStatus.find(p => p.status_name.toUpperCase() === "Open".toUpperCase()));
                                                        }
                                                    }}>
                                                    {'CREATE DUE JOB'}
                                                </Button>
                                            </>
                                        }
                                        {
                                            (currentDueJobStatus.toUpperCase() !== "New".toUpperCase() && currentDueJobStatus.toUpperCase() !== "Closed".toUpperCase()
                                                && currentDueJobStatus.toUpperCase() !== "Canceled".toUpperCase()) &&
                                            <>
                                                <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={isSaveDueJobButtonDisabled}
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to save this progress?")) {
                                                            updateDueJob();
                                                        }
                                                    }}>
                                                    {'SAVE DUE JOB'}
                                                </Button>
                                            </>
                                        }
                                        {
                                            (currentDueJobStatus.toUpperCase() === "Open".toUpperCase()
                                                || currentDueJobStatus.toUpperCase() === "Pending".toUpperCase()) &&
                                            <>
                                                <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={isSaveDueJobButtonDisabled}
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to perform maintenance?")) {
                                                            setShouldUpdateToDB(true);
                                                            setSelectedDueJobStatus(listDueJobStatus.find(p => p.status_name.toUpperCase() === "In Progress".toUpperCase()));
                                                        }
                                                    }}>
                                                    {'PERFORM MAINTENANCE'}
                                                </Button>
                                            </>
                                        }
                                        {/* 
                                    (currentDueJobStatus.toUpperCase() === "Open".toUpperCase()
                                        || currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase()) &&
                                    <>
                                        <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={isSaveDueJobButtonDisabled}
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to save this progress?")) {

                                                }
                                            }}>
                                            {'SUSPEND MAINTENANCE'}
                                        </Button>
                                    </>
                                 */}
                                        {
                                            (currentDueJobStatus.toUpperCase() === "Open".toUpperCase()
                                                || currentDueJobStatus.toUpperCase() === "Pending".toUpperCase() || currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase()) &&
                                            <>
                                                <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={isSaveDueJobButtonDisabled}
                                                    onClick={() => {
                                                        setShowCancelModal(true);
                                                    }}>
                                                    {'CANCEL DUE JOB'}
                                                </Button>
                                            </>
                                        }
                                        {
                                            currentDueJobStatus.toUpperCase() === "In Progress".toUpperCase()
                                            &&
                                            <>
                                                <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={isSaveDueJobButtonDisabled}
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to finish this due job?")) {
                                                            setShouldUpdateToDB(true);
                                                            setSelectedDueJobStatus(listDueJobStatus.find(p => p.status_name.toUpperCase() === "Closed".toUpperCase()));
                                                        }
                                                    }}>
                                                    {'FINISH MAINTENANCE'}
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div >
                            } />
                    </div>
                </Container>
                <Modal enforceFocus={true} onBackdropClick={() => {

                }} show={showCancelModal}
                    size="xl"
                    onHide={() => {
                        setShowCancelModal(false);
                    }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={"Cancel Maintenance Due Job"}
                        useActionContainer={false}

                        childContent={
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                minHeight: 600,
                                maxHeight: 800,
                                overflowY: 'scroll',
                                padding: 10
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label>Cancel Reason</Form.Label>
                                            <Editor
                                                apiKey='pbjm9oydw65b4p46e89nz1eav5rqqpy66e0ltrlnr15kc5dq'
                                                disabled={false}
                                                value={dueJobDetail.cancel_reason}
                                                onEditorChange={(evt, editor) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_reason: evt });
                                                }}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Authorized By'}</Form.Label>
                                            <Form.Control type='text' disabled={false}
                                                value={dueJobDetail.cancel_authorized_by_1} placeholder=""
                                                caption={"Authorized By"}
                                                req={true}
                                                onChange={(e) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_authorized_by_1: e.target.value });
                                                }} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Authorized By'}</Form.Label>
                                            <Form.Control type='text' disabled={false}
                                                value={dueJobDetail.cancel_authorized_by_2} placeholder=""
                                                caption={"Authorized By"}
                                                req={true}
                                                onChange={(e) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_authorized_by_2: e.target.value });
                                                }} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Authorized By'}</Form.Label>
                                            <Form.Control type='text' disabled={false}
                                                value={dueJobDetail.cancel_authorized_by_3} placeholder=""
                                                caption={"Authorized By"}
                                                req={true}
                                                onChange={(e) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_authorized_by_3: e.target.value });
                                                }} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div>
                                    <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={false}
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to cancel this due job?")) {
                                                setShowCancelModal(false);
                                                setShouldUpdateToDB(true);
                                                setSelectedDueJobStatus(listDueJobStatus.find(p => p.status_name.toUpperCase() === "Canceled".toUpperCase()));
                                            }
                                        }}>
                                        {'CANCEL DUE JOB'}
                                    </Button>
                                    <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={false}
                                        onClick={() => {
                                            setShowCancelModal(false);
                                        }}>
                                        {'CLOSE'}
                                    </Button>
                                </div>
                            </div>
                        } />
                </Modal>
                <Modal enforceFocus={true} onBackdropClick={() => {

                }} show={showSuspendModal}
                    size="xl"
                    onHide={() => {
                        setShowCancelModal(false);
                    }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={"Suspend Maintenance Due Job"}
                        useActionContainer={false}

                        childContent={
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                minHeight: 600,
                                maxHeight: 800,
                                overflowY: 'scroll',
                                padding: 10
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label>Cancel Reason</Form.Label>
                                            <Editor
                                                apiKey='pbjm9oydw65b4p46e89nz1eav5rqqpy66e0ltrlnr15kc5dq'
                                                disabled={false}
                                                value={dueJobDetail.cancel_reason}
                                                onEditorChange={(evt, editor) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_reason: evt });
                                                }}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Authorized By'}</Form.Label>
                                            <Form.Control type='text' disabled={false}
                                                value={dueJobDetail.cancel_authorized_by_1} placeholder=""
                                                caption={"Authorized By"}
                                                req={true}
                                                onChange={(e) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_authorized_by_1: e.target.value });
                                                }} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Authorized By'}</Form.Label>
                                            <Form.Control type='text' disabled={false}
                                                value={dueJobDetail.cancel_authorized_by_2} placeholder=""
                                                caption={"Authorized By"}
                                                req={true}
                                                onChange={(e) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_authorized_by_2: e.target.value });
                                                }} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "center", alignSelf: "flex-start", width: "100%", flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Group className="formGroupFormPlanningContanerNew">
                                            <Form.Label className='titleForEachFormGroupOnCMDetail'>{'Authorized By'}</Form.Label>
                                            <Form.Control type='text' disabled={false}
                                                value={dueJobDetail.cancel_authorized_by_3} placeholder=""
                                                caption={"Authorized By"}
                                                req={true}
                                                onChange={(e) => {
                                                    setDueJobDetail({ ...dueJobDetail, cancel_authorized_by_3: e.target.value });
                                                }} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div>
                                    <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={false}
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to cancel this due job?")) {
                                                setShowCancelModal(false);
                                                setShouldUpdateToDB(true);
                                                setSelectedDueJobStatus(listDueJobStatus.find(p => p.status_name.toUpperCase() === "Canceled".toUpperCase()));
                                            }
                                        }}>
                                        {'CANCEL DUE JOB'}
                                    </Button>
                                    <Button style={{ width: '100%', margin: 5 }} variant="primary" disabled={false}
                                        onClick={() => {
                                            setShowCancelModal(false);
                                        }}>
                                        {'CLOSE'}
                                    </Button>
                                </div>
                            </div>
                        } />
                </Modal>

            </div >

        </>
    )
}