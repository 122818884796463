// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageEngine {
    margin: 0 0%;
}

.containerBoxEngine {
    border: 1px solid black;
    background-color: rgba(233, 233, 250, 0.1);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.statusTable {
    padding: 10px;
    width: 80%;
}

h4, h1, td{
    color: white;
}

table {
    font-size:x-large;
}`, "",{"version":3,"sources":["webpack://./src/Pages/SMSDashboard/SMSMainEngine/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".mainPageEngine {\n    margin: 0 0%;\n}\n\n.containerBoxEngine {\n    border: 1px solid black;\n    background-color: rgba(233, 233, 250, 0.1);\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n    border-radius: 12px;\n    -webkit-border-radius: 12px;\n    -moz-border-radius: 12px;\n    -ms-border-radius: 12px;\n    -o-border-radius: 12px;\n}\n\n.statusTable {\n    padding: 10px;\n    width: 80%;\n}\n\nh4, h1, td{\n    color: white;\n}\n\ntable {\n    font-size:x-large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
