import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal,Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getStaff, getCurrency,
} from "../../../../Helpers/ApplicationHelpers";
import { getInventoryPeriod, getInventoryStatus, getInventoryCondition, 
    checkBarcodeNumber, insertUpdateInventoryStock, getInventoryStockById } from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";



export default function InventoryStockDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listCurrency, setListCurrency] = useState([]);
    const [listInventoryStatus, setlistInventoryStatus] = useState([]);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [listInventoryPeriod, setlistInventoryPeriod] = useState([]);
    const [listStaff, setListStaff] = useState([]);
    const [options, setOptions]  =useState([]);
    



    const [inventoryStock, setInventoryStock] = useState({
        id: 0,
        inventory_master_id: "",
        inventory_code: "",
        purchase_date: "",
        received_date: "",
        inventory_period_id: "",
        period_number: "",
        purchase_price: "",
        currency_id: "",
        currency_value_idr: "",
        staff_id: "",
        inventory_status_id: "",
        remove_date: "",
        inventory_condition_id: "",
        depreciation_rate: "",
        inventory_detail_description: "",
        inventory_detail_note: ""

    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadStaff();
                loadCurrency();
                loadInventoryPeriod();
                loadInventoryStatus();
                loadInventoryCondition();

                            
            }
        }
        checkCookies();
        if (location.state.inventoryStockId === 0) {

        } else {
            initInventoryStock();
        }
    }, []);

   
    const initInventoryStock = async () => {
        try {
            let response = await getInventoryStockById(cookies.token, location.state.inventoryStockId);
            if (response) {
                setInventoryStock({
                    id:response.id,
                    inventory_master_id:response.inventory_master_id,
                    inventory_code:response.inventory_code,
                    purchase_date:response.purchase_date,
                    received_date:response.received_date,
                    inventory_period_id:response.inventory_period_id,
                    period_number:response.period_number,
                    purchase_price:response.purchase_price,
                    currency_id:response.currency_id,
                    currency_value_idr:response.currency_value_idr,
                    staff_id:response.staff_id,
                    inventory_status_id:response.inventory_status_id,
                    remove_date:response.remove_date,
                    inventory_condition_id:response.inventory_condition_id,
                    depreciation_rate:response.depreciation_rate,
                    inventory_detail_description:response.inventory_detail_description,
                    inventory_detail_note:response.inventory_detail_note
            
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const generateCode = async () => {
        try {
        var today = new Date(); 
        var yyyy = today.getFullYear();
        var randomNumber = Math.floor(Math.random() * 100000000);
        let invCode="RPI/"+location.state.departmentCode+"/EQP/"+yyyy+"/"+randomNumber;
        let checkCode= await checkBarcodeNumber(cookies.token, invCode);
        while (checkCode !=0) {
            generateCode();           
          }     
          return  invCode

        } catch (exception) {
            console.log(exception);
        }
    }


    const addInventoryStock = async () => {
        if (location.state.inventoryStockId === 0) {
           
                inventoryStock.inventory_code=await generateCode();  
                setInventoryStock({ ...inventoryStock, id: 0 });
                inventoryStock.id=0;       
                if(inventoryStock.staff_id=="")inventoryStock.staff_id = undefined;         
                inventoryStock.inventory_master_id=location.state.inventoryMasterId;                
                let response = await insertUpdateInventoryStock(cookies.token, inventoryStock);
                if (response === 0) {
                    alert("Inventory Stock Successfully Added");
                    navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });                }

        }
        else {
            //update
            setInventoryStock({ ...inventoryStock, id: location.state.inventoryStockId });
            let response = await insertUpdateInventoryStock(cookies.token, inventoryStock);
            if (response === 0) {
                alert("Inventory Stock Successfully Updated");
                navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });            }

        }
    }


    const loadCurrency = async () => {
        try {
            let listCurrency = await getCurrency(cookies.token, cookies.languageId);
            setListCurrency(listCurrency);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setlistInventoryPeriod(listInventoryPeriod);
         }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setlistInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadStaff = async () => {
        try {
            let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListStaff(listStaff); 
            let listNewStaff = [];
            for (let i = 0; i < listStaff.length; i++) {
                 var obj = {
                    value:listStaff[i].employee_number,
                    label:listStaff[i].staff_name + " (" + listStaff[i].employee_number + ") " ,
                    id:listStaff[i].staff_id

                 };
            
                  listNewStaff.push(obj);
                    
            }    
            setOptions(listNewStaff); 
            

        } catch (exception) {
            console.log(exception);
        }


    }





    return (
        <>
            <Navbar />
            <Container>
            <div className="details">
                    <div className="detailscontent">

                {location.state.inventoryStockId === 0 ?
                    <h3>Add Inventory Stock Item </h3> :
                    <h3>Inventory Stock Item Detail</h3>
                }
                </div>

                <Form onSubmit={(e) => {

                    e.preventDefault();
                    addInventoryStock();

                }} style={{ padding: 10 }}>
                    
                    <Form.Group className="mb-3">
                        <Form.Label>Purchase Date</Form.Label>
                        <Form.Control onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, purchase_date: e.target.value })
                        }} type="date" value={inventoryStock.purchase_date} required placeholder="Enter Purchase Date"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Received Date</Form.Label>
                        <Form.Control onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, received_date: e.target.value })
                        }} type="date" value={inventoryStock.received_date} required placeholder="Enter Received Date"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Remove Date</Form.Label>
                        <Form.Control onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, remove_date: e.target.value })
                        }} type="date" value={inventoryStock.remove_date} required placeholder="Enter Remove Date"></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Period </Form.Label>
                        <Col md={12}>
                            <Row>
                                <Col md={4}>
                                    <Form.Control onChange={(e) => {
                                        setInventoryStock({ ...inventoryStock, period_number: e.target.value })
                                    }} type="number" value={inventoryStock.period_number} required placeholder="Enter Period Number"></Form.Control>
                                </Col>
                                <Col md={4}>
                                <Form.Select  required onChange={(e) => {
                                        setInventoryStock({ ...inventoryStock, inventory_period_id: e.target.value })
                                            }} value={inventoryStock.inventory_period_id} >
                                                <option selected value="">  
                                                    Choose Period ...
                                                </option>
                                                {listInventoryPeriod.map((period, index) => (
                                                    <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Purchase Price</Form.Label>
                        <Form.Control onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, purchase_price: e.target.value })
                        }} type="number" step='1000' value={inventoryStock.purchase_price} required placeholder="Enter Purchase Price"></Form.Control>
                    </Form.Group> 
                    <Form.Group className="mb-3">   
                        <Form.Label>Currency</Form.Label>
                        <Form.Select required onChange={(e) => {
                                 setInventoryStock({ ...inventoryStock, currency_id: e.target.value })
                        }} value={inventoryStock.currency_id} >
                            <option selected value="">  
                                Choose Currency...
                            </option>
                            {listCurrency.map((currency, index) => (
                                <option key={index} value={currency.id}>{currency.currency_name} (
                                    {currency.currency_code }
                                )</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Currency Value (IDR)</Form.Label>
                        <Form.Control onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, currency_value_idr: e.target.value })
                        }} type="number" step='1000' value={inventoryStock.currency_value_idr} required placeholder="Enter Currency Value (IDR)"></Form.Control>
                    </Form.Group>  
                    <Form.Group className="mb-3">
                        <Form.Label>Depreciation Rate(%)</Form.Label>
                        <Form.Control onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, depreciation_rate: e.target.value })
                        }} type="number"  value={inventoryStock.depreciation_rate} required placeholder="Enter Depreciation Rate(%)"></Form.Control>
                    </Form.Group>  
                    <Form.Group className="mb-3">   
                        <Form.Label> Inventory Status</Form.Label>
                        <Form.Select required onChange={(e) => {
                                 setInventoryStock({ ...inventoryStock, inventory_status_id: e.target.value })
                        }} value={inventoryStock.inventory_status_id} >
                            <option selected value="">  
                                Choose Inventory Status...
                            </option>
                            {listInventoryStatus.map((status, index) => (
                                <option key={index} value={status.id}>{status.inventory_status_name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>  
                    <Form.Group className="mb-3">   
                        <Form.Label>Inventory Condition</Form.Label>
                        <Form.Select required onChange={(e) => {
                                 setInventoryStock({ ...inventoryStock, inventory_condition_id: e.target.value })
                        }} value={inventoryStock.inventory_condition_id} >
                            <option selected value="">  
                                Choose Inventory Condition...
                            </option>
                            {listInventoryCondition.map((condition, index) => (
                                <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>  
                    <Form.Group className="mb-3">   
                        <Form.Label>Person in Charge</Form.Label>
                    <Select  autoFocus={inventoryStock.staff_id == undefined} placeholder={inventoryStock.staff_id=="" ? "Choose Person in Charge..." : <span className="text-danger">Choose  Person in Charge!</span>}  required  
                            options = { options } value={options.filter(function (option) {
                                return option.id === inventoryStock.staff_id;
                              })} onChange={(e) => {
                                    setInventoryStock({ ...inventoryStock, staff_id : e.id });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <textarea
                        className="form-control"
                        value={inventoryStock.inventory_detail_description} 
                        placeholder="Inventory Description"
                        onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, inventory_detail_description: e.target.value })
                        }}
                        rows="4"
                    />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Note</Form.Label>
                        <textarea
                        className="form-control"
                        value={inventoryStock.inventory_detail_note}
                        placeholder="Inventory Note"
                        onChange={(e) => {
                            setInventoryStock({ ...inventoryStock, inventory_detail_note: e.target.value })
                        }}
                        rows="4"
                    />
                    </Form.Group>
                   
                    <Button type="submit">Save Data</Button>
                    <Button className="cancel" variant="danger" onClick={() => {
                        navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });
                    }}>Cancel</Button>
                </Form>


                
                </div>
            </Container>

        </>
    )
}