import "../../SMSApp.css";
import "./index.css";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { useState } from "react";
import { Button } from "react-bootstrap";

export default function Miscellaneous() {

    const [page, setPage] = useState(1);

    const changePage = (nextPage) => {
        if (nextPage >= 1 && nextPage <= 4)
            setPage(nextPage);
    }

    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"MISCELLANEOUS"} /></div>
            <div className="contentMisc">
                <div className="headingContentMisc">
                    <div className="titleContent">Miscellaneous {">"} Page {page} of 2</div>
                    <div className="btnPrevNext">
                        <Button
                            className="btnNext"
                            variant="outline-primary"
                            disabled={page <= 1 ? true : false}
                            onClick={() => {
                                let tmpPage = page - 1;
                                changePage(tmpPage);
                            }}
                        >
                            <img src={require("../../Assets/Images/vectorPrev.png")} alt="" />
                            PREV
                        </Button>
                        <Button
                            className="btnNext"
                            variant="outline-primary"
                            disabled={page >= 2 ? true : false}
                            onClick={() => {
                                let tmpPage = page + 1;
                                changePage(tmpPage);
                            }}
                        >
                            NEXT
                            <img src={require("../../Assets/Images/vectorNext.png")} alt="" />
                        </Button>
                    </div>
                </div>
                {page === 1 &&
                    <div className="miscBitLampContainer">
                        <div className="flexColumn">
                            <div className="bitLampContainer">
                                <BitLamp text={"Press Drop Quick Cl. Valve"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Pressure Drop Sprinkler"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Pressure Drop Fire Main"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fire Alarm Engine Room"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Ground Failure Main Supply"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Ground Fail Emergency Supply"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Freq Main Supply"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Batt Charge Autom Battery"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Batt Charge 220V Emer Light"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Batt Charge 24V Mis Cons"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Main Supply"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail 24V CB ECR Desk"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail 24V Distr Automation"} alarm={true} />
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail Fan Engine Room"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail Supply Light Colum"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Tripping Unness Consumer"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Suction V Sprinkler Close"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail Fans AC Distr 1-3"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Fans PD 8"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Start of a Standby Pump"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Temp Freeze Proc Rooms"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Steering Gear 1"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Steering Gear 2"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail LO Separator"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail FO Separator"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail Fire Detection Plan"} alarm={true} />
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail Public Address Plan"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail BT Plan"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail Boiler Plan"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Start AC Comp 1 Fail"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Start AC Comp 2 Fail"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Start AC Comp 3 Fail"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"AC Comp 1 Fail"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"AC Comp 2 Fail"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"AC Comp 3 Fail"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Sewage Plant 1 Fail"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Sewage Plant 2 Fail"} alarm={true} />
                            </div>
                        </div>
                    </div>
                }
                {page === 2 &&
                    <div className="miscBitLampContainer">
                        <div className="flexColumn">
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Bilges & Ballas Plan"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Provisi Cool Plant Failure"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail UV PLant 1"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Fail UV PLant 2"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"OWS 15 PPM Fail"} alarm={true} />
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Emerg Diesel"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Incinerator Fail"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Winches Forecastle Fail"} alarm={false} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Winches Stren Fail"} alarm={true} />
                            </div>
                            <div className="bitLampContainer">
                                <BitLamp text={"Failure Eng Call System"} alarm={true} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div style={{paddingBottom: 120}}></div>
            <div><BottomNavBar /></div>
        </>
    );
}