import "../../SMSApp.css";
import "./index.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BarGraph from "../../Components/BarGraph/BarGraph";
import { Button } from "react-bootstrap";
import { useState } from "react";

export default function LevelSoundingTanks() {
    const [page, setPage] = useState(1);

    const changePage = (nextPage) => {
        if (nextPage >= 1 && nextPage <= 4)
            setPage(nextPage);
    }

    return (
        <div>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"Level Sounding Tanks"} />
            </div>
            <div className="boxLST">
                <div className="headingContent">
                    <div className="titleContent">Level Sounding {">"} Page {page} of 4</div>
                    <div className="btnPrevNext">
                        <Button
                            className="btnNext"
                            variant="outline-primary"
                            disabled={page <= 1 ? true : false}
                            onClick={() => {
                                let tmpPage = page - 1;
                                changePage(tmpPage);
                            }}
                        >
                            <img src={require("../../Assets/Images/vectorPrev.png")} alt="" />
                            PREV
                        </Button>
                        <Button
                            className="btnNext"
                            variant="outline-primary"
                            disabled={page >= 4 ? true : false}
                            onClick={() => {
                                let tmpPage = page + 1;
                                changePage(tmpPage);
                            }}
                        >
                            NEXT
                            <img src={require("../../Assets/Images/vectorNext.png")} alt="" />
                        </Button>
                    </div>
                </div>
                {page === 1 &&
                    <div className="contentLST">
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={10}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={true}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={false}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={false}
                                    shortError={true}
                                />
                            </div>
                        </div>
                    </div>
                }
                {page === 2 &&
                    <div className="contentLST">
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"802 FW Tank 53 SB"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 111 PS"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"813 FW Tank 111 SB"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"821 FW Tank 20 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"814 FW Tank 112 PS"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                        </div>
                    </div>
                }
                {page === 3 &&
                    <div className="contentLST">
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"803 FO Tank 30 CL"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"822 T. FO Storage Tank 40 P"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"823 T. FO Storage Tank 40 SB"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"824 FO Storage 60 P"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={true}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"825 FO Storage 60 SB"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"826 FO Storage Tank 70 CL"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"831 FO Overflow Tank 80 C"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"827 FO Daytank Tank 103 PS"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"828 FO Daytank Tank 103 SB"}
                                    valueBG={99}
                                    wireBreak={false}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"829 FO Settling Tank 104 PS"}
                                    valueBG={99}
                                    wireBreak={false}
                                    shortError={true}
                                />
                            </div>
                        </div>
                    </div>
                }
                {page === 4 &&
                    <div className="contentLST">
                        <div className="flexColumnP4">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"830 FO Settling Tank 104 SB"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="botBtnP4Container">
                                <Button
                                    className="botBtnP4"
                                    variant="outline-primary"
                                >
                                    Restore SS1 Tank Calibration
                                </Button>
                                <Button
                                    className="botBtnP4"
                                    variant="outline-primary"
                                >
                                    Restore SS2 Tank Calibration
                                </Button>
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"841 LO Circulating Tank 93 P"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                            <div className="barGraphContainer">
                                <BarGraph
                                    text={"842 LO Circulating Tank 93 SB"}
                                    valueBG={99}
                                    wireBreak={true}
                                    shortError={false}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div style={{paddingBottom: 120}}></div>
            <div>
                <BottomNavBar />
            </div>
        </div>
    );
}