import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import {
    getInventoryGroupById, getInventorySubGroupByGroupId, getInventorySubGroupByGroupIdCount,
    insertUpdateInventorySubGroup, deleteInventorySubGroup,
    getInventoryTypeById, insertUpdateInventoryType, insertUpdateInventoryTypeDetail, getInventoryTypeDetail,
    getInventoryTypeDetailById, getInventoryTypeDetailCount, deleteInventoryTypeDetail
} from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import ButtonForm from "../../../../Components/ButtonForm";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft, FilePlusFill, Search, ArrowClockwise, PencilFill, Trash } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function InventoryGroupDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [listInventoryTypeDetail, setListInventoryTypeDetail] = useState([]);
    const [errors, setErrors] = useState({});
    const [inventoryTypeDetailCount, setInventorySubGroupDetailCount] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledButtonModal, setDisabledButtonModal] = useState(false);
    const [isSearched, setIsSearched] = useState(0);
    const [listSubGroupCount, setListSubGroupCount] = useState(0);
    const [showSubGroupModal, setShowSubGroupModal] = useState("");
    const [page, setPage] = useState(0);
    const [inventoryTypeDetailId, setInventorySubGroupDetailId] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(10);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [inventoryType, setInventoryType] = useState({
        id: 0,
        inventory_type_name: "",
        inventory_type_code: "",
        inventory_type_description: "",
        language_id: cookies.languageId,
        ext_customer_id: cookies.extCustomerId
    })

    const [inventoryGroup, setInventoryGroup] = useState({
        id: 0,
        inventory_group_name: "",
        inventory_group_code: "",
        inventory_group_desc: "",
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        ext_customer_id: "",
        language_id: ""

    });

    const [inventorySubGroupDetail, setInventorySubGroupDetail] = useState({
        id: 0,
        inventory_subgroup_name: "",
        inventory_subgroup_code: "",
        inventory_subgroup_desc: "",
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        ext_customer_id: "",
        inventory_group_id: location.state.inventoryGroupId,
        language_id: ""
    });
    const [listInventorySubGroup, setListInventorySubGroup] = useState([]);

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_inventory_group.order_name1")}`,
        value: "inventory_type_name"
    });
    const orderByList = [{
        label: `${t("detail_inventory_group.order_name1")}`,
        value: "inventory_type_name"
    }];
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                console.log(location.state.inventoryGroupId);
                if (location.state.inventoryGroupId) {
                    loadAccess();
                    initInventoryGroup();
                    setLoading(false);
                } else {
                    navigate("/InventoryGroup");
                }

            }
        }
        checkCookies();

    }, []);

    useEffect(() => {
        if (inventoryGroup.id) {
            loadInventorySubGroup();
        }
    }, [inventoryGroup]);

    useEffect(() => {
        loadInventorySubGroup();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventorySubGroup();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventorySubGroup();
    }, [orderBy]);

    // useEffect(() => {
    //     if (searchQuery === "")
    //     loadInventoryTypeDetail();
    // }, [searchQuery]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryTypeDetail()
    }, [descending]);

    useEffect(() => {
        // if (inventoryTypeDetailId !== "")
        //     initInventoryTypeDetail()
    }, [inventoryTypeDetailId])

    useEffect(() => {
        if (removeId !== "")
            deleteData();
    }, [removeId]);


    const initInventoryGroup = async () => {
        let inventoryGroup = await getInventoryGroupById(cookies.token, cookies.languageId, location.state.inventoryGroupId);
        setInventoryGroup(inventoryGroup);

    }

    const loadInventorySubGroup = async () => {
        let listInventorySubGroupCount = await getInventorySubGroupByGroupIdCount(cookies.token, cookies.languageId, cookies.ext_customer_id, location.state.inventoryGroupId, searchQuery);
        setListSubGroupCount(listInventorySubGroupCount);
        let totalPage = listInventorySubGroupCount / itemPerPage;
        setTotalPage(totalPage);

        let listInventorySubGroup = await getInventorySubGroupByGroupId(cookies.token, cookies.languageId, cookies.ext_customer_id, location.state.inventoryGroupId, searchQuery);
        setListInventorySubGroup(listInventorySubGroup);
        setLoading(false);
        setIsSearched(0);
    }

    const saveData = async () => {
        try {
            let inventoryTypeTmp = inventoryType;
            inventoryTypeTmp.ext_customer_id = inventoryTypeTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : inventoryType.ext_customer_id
            let response = await insertUpdateInventoryType(cookies.token, inventoryTypeTmp);
            if (response === 0) {
                alert(`${t("detail_inventory_group.alert_success_save_data")}`);
                navigate("/InventoryType")
            } else {
                alert(`${t("detail_inventory_group.alert_failed_save_data")}`)
            }
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const saveDetailInventory = async () => {
        /* try {
            let inventoryTypeDetailTmp = inventoryTypeDetail;
            inventoryTypeDetailTmp.ext_customer_id = inventoryTypeDetailTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : inventoryTypeDetail.ext_customer_id
            let response = await insertUpdateInventoryTypeDetail(cookies.token, inventoryTypeDetail);
            if (response === 0) {
                alert(`${t("detail_inventory_group.alert_success_save_data")}`);
                setShowSubGroupModal(false);
                clearInventoryTypeModal();
                loadInventoryTypeDetail();
            } else {
                alert(`${t("detail_inventory_group.alert_failed_save_data")}`)
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            console.log(exception)
        } */
    }

    const saveSubGroupDetail = async () => {
        try {
            let subgroupDetail = inventorySubGroupDetail;
            console.log(cookies);
            subgroupDetail.ext_customer_id = "fab7b2b6-cb86-4b74-ba8e-036361f24115";
            subgroupDetail.language_id = cookies.languageId;
            console.log(subgroupDetail);
            let response = await insertUpdateInventorySubGroup(cookies.token, subgroupDetail);
            if (response === 0) {
                alert(`${t("detail_inventory_group.alert_success_save_data")}`);
                setShowSubGroupModal(false);
                clearInventorySubGroupModal();
                loadInventorySubGroup();
            } else {
                alert(`${t("detail_inventory_group.alert_failed_save_data")}`)
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const clearInventorySubGroupModal = () => {
        let inventorySubGroupTmp = {
            id: 0,
            inventory_subgroup_name: "",
            inventory_subgroup_code: "",
            inventory_subgroup_desc: "",
            inventory_group_id: location.state.inventoryGroupId,
            ext_customer_id: "",
            language_id: cookies.languageId
        };
        setInventorySubGroupDetail(inventorySubGroupTmp);
    }

    const loadInventoryTypeDetail = async () => {
        try {
            let count = await getInventoryTypeDetailCount(cookies.token, location.state.inventoryTypeId, cookies.extCustomerId, searchQuery, false);
            setInventorySubGroupDetailCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getInventoryTypeDetail(cookies.token, cookies.languageId, location.state.inventoryTypeId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListInventoryTypeDetail(response);
            console.log(listInventoryTypeDetail);
            setLoading(false);
            setIsSearched(0);

        } catch (exception) {
            console.log(exception);
        }
    }

    const deleteData = async () => {
        try {
            let response = await deleteInventoryTypeDetail(cookies.token, removeId);
            if (response.error_code === 0) {
                loadInventoryTypeDetail();
                setRemoveId("");
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_group.order_name1")}`,
                value: "inventory_type_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_inventory_group.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/InventoryGroup"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 10,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" titleCaption={`${t("detail_inventory_group.header_inventory_type")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setDisabledButton(true);
                                                    saveData();
                                                }}>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_inventory_group.group_name")}</Form.Label>
                                                        <Form.Control value={inventoryGroup.inventory_group_name} onChange={(e) => {
                                                            setInventoryType({ ...inventoryGroup, inventory_group_name: e.target.value });
                                                        }} required placeholder=""></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_inventory_group.group_code")}</Form.Label>
                                                        <Form.Control value={inventoryGroup.inventory_group_code} onChange={(e) => {
                                                            setInventoryType({ ...inventoryGroup, inventory_group_code: e.target.value });
                                                        }} required placeholder=""></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_inventory_group.group_desc")}</Form.Label>
                                                        <Form.Control value={inventoryGroup.inventory_group_desc} onChange={(e) => {
                                                            setInventoryType({ ...inventoryGroup, inventory_group_desc: e.target.value });
                                                        }} placeholder=""></Form.Control>
                                                    </Form.Group>
                                                    {
                                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                            <></>
                                                            :
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                padding: 10,
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                fontWeight: "bold"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingRight: 5,
                                                                }}><Button style={{ width: 100 }} className="save-button" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("detail_inventory_group.save_button")}</Button> </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Button style={{ width: 100 }} onClick={() => {
                                                                        navigate("/InventoryType")
                                                                    }} className="cancel-button" variant="danger">{t("detail_inventory_group.cancel_button")}</Button></div>

                                                            </div>

                                                    }


                                                </Form>

                                            </div>
                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            location.state.inventoryGroupId &&
                                            <ContainerBox containerPos="inner" titleCaption={`${t("detail_inventory_group.header_subgroup_list")}`}
                                                useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                actionContainerChild={
                                                    <div>
                                                        <Button disabled={!updatedButton} variant="secondary" style={{

                                                        }} onClick={() => {
                                                            clearInventorySubGroupModal();
                                                            setShowSubGroupModal(true);
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><FilePlusFill size={32} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 10,
                                                                    fontWeight: "bold",
                                                                    fontSize: 18,
                                                                }}>{t("detail_inventory_group.add_button")}</div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                }
                                                childContent={
                                                    <div className="master-table-inner-container">
                                                        <div className="master-table-searchbar-container">
                                                            <div className="master-table-searchbar-textbox">
                                                                <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_inventory_group.search_box")}`}
                                                                    onKeyPress={async (e) => {
                                                                        if (e.charCode === 13) {
                                                                            setPage(0);
                                                                            setLoading(true);
                                                                            await loadInventorySubGroup();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setSearchQuery(e.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="master-table-searchbar-button">
                                                                <div style={{ paddingRight: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setLoading(true);
                                                                        setPage(0);
                                                                        await loadInventoryTypeDetail();
                                                                    }}><Search /></Button>
                                                                </div>
                                                                <div style={{ paddingLeft: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setLoading(true);
                                                                        setSearchQuery("");
                                                                        await loadInventoryTypeDetail();
                                                                    }}><ArrowClockwise /></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-container">
                                                            <div className="table-header">
                                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_inventory_group.table_name1")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_inventory_group.table_name2")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_inventory_group.table_name3")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_inventory_group.table_name4")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_inventory_group.table_name5")}</div>
                                                            </div>
                                                            {
                                                                listInventorySubGroup && listInventorySubGroup.length > 0 && listInventorySubGroup.map((subgroup, index) => {
                                                                    return (<div className="table-body">
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{subgroup.inventory_subgroup_name}</p></div>
                                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{subgroup.inventory_subgroup_code}</p></div>
                                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{subgroup.inventory_subgroup_desc}</p></div>
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={!updatedButton} variant="primary" onClick={() => {
                                                                                    setInventorySubGroupDetail({
                                                                                        id: subgroup.id,
                                                                                        inventory_subgroup_name: subgroup.inventory_subgroup_name,
                                                                                        inventory_subgroup_code: subgroup.inventory_subgroup_code,
                                                                                        inventory_subgroup_desc: subgroup.inventory_subgroup_desc,
                                                                                        language_id: subgroup.language_id,
                                                                                        ext_customer_id: subgroup.ext_customer_id
                                                                                    });
                                                                                    setShowSubGroupModal(true);
                                                                                    //setInventorySubGroupDetailId(subgroup.id);
                                                                                }}><PencilFill /></Button>
                                                                            </div></div>

                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                                                    onClick={() => {
                                                                                        if (window.confirm(`${t("detail_inventory_group.delete_confirmation")} ${subgroup.inventory_subgroup_name}?`))
                                                                                            setRemoveId(subgroup.id);
                                                                                    }}
                                                                                ><Trash /></Button>
                                                                            </div></div>
                                                                    </div>)
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                        <div style={{
                                                            paddingTop: 20,
                                                        }}>

                                                        </div>
                                                        <Paginations
                                                            itemPerPage={itemPerPage}
                                                            totalPage={totalPage}
                                                            page={page}
                                                            setPage={setPage}
                                                            setItemPerPage={setItemPerPage}
                                                            itemPerPageSelection={itemPerPageSelection}
                                                            orderBy={orderBy}
                                                            setOrderBy={setOrderBy}
                                                            orderBySelection={orderByList}
                                                            isDescActive={descending}
                                                            setIsDescActive={setDescending}
                                                            setIsSearched={setIsSearched}
                                                        />
                                                        <Loading
                                                            loading={loading}
                                                            loadingText={`${t("loadin_component.text")}`}
                                                        />
                                                    </div>
                                                } />
                                        }



                                    </div>
                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox >

                <Modal show={showSubGroupModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setShowSubGroupModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            {inventorySubGroupDetail.id === 0 && <h3>{t("detail_inventory_group.modal_add_inventory_subgroup")}</h3>}
                            {inventorySubGroupDetail.id !== 0 && <h3>{t("detail_inventory_group.modal_edit_inventory_subgroup")}</h3>}
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButtonModal(true)
                            saveSubGroupDetail();
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_group.field_inventory_subgroup_name")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setInventorySubGroupDetail({ ...inventorySubGroupDetail, inventory_subgroup_name: e.target.value })
                                }} type="text" value={inventorySubGroupDetail.inventory_subgroup_name} required placeholder=""></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_group.field_inventory_subgroup_code")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setInventorySubGroupDetail({ ...inventorySubGroupDetail, inventory_subgroup_code: e.target.value })
                                }} type="text" value={inventorySubGroupDetail.inventory_subgroup_code} required placeholder=""></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_group.field_description")}</Form.Label>
                                <textarea
                                    className="form-control"
                                    value={inventorySubGroupDetail.inventory_subgroup_desc}
                                    placeholder=""
                                    onChange={(e) => {
                                        setInventorySubGroupDetail({ ...inventorySubGroupDetail, inventory_subgroup_desc: e.target.value })
                                    }}
                                    rows={3}
                                />
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "center"
                                    }}>
                                        <Button style={{ width: 100 }} type="submit" disabled={disabledButtonModal === true ? disabledButtonModal : !updatedButton}>{t("detail_inventory_group.save_button")}</Button>
                                        <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                            setShowSubGroupModal(false);
                                        }}>{t("detail_inventory_group.cancel_button")}</Button>
                                    </div>
                            }


                        </Form>



                    </div>

                </Modal>

            </Container >
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}