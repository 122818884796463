import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { CookiesProvider } from "react-cookie";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faCoffee, fas } from "@fortawesome/free-solid-svg-icons";
import "./i18n"

library.add(fas, faCheckSquare, faCoffee);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>
);


