// import "../../SMSApp.css";
import "./index.css";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { isTokenValid, getConvertedTanksReadingValues, findAllTank, getSMSCoordinateByCoordinateType, getPLCReadingValueForTankLevel,getReadingValueNew, convertTankLevelIntegerValue } from "../../../Helpers/ApplicationHelpers";
import TankLevel from "../../../Components/NewTankLevel/NewTankLevel";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/LoadingForSMSDashboard/Loading";
import DropDownForm from "../../../Components/DropDownForm";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import moment from "moment";
import Select from 'react-select';

export default function CustomerSMSTankLevelPage(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listTankAndValue, setListTankAndValue] = useState();
    const [isDataReady, setIsDataReady] = useState(false);

    // const [selectedTankLevel, setSelectedTankLevel] = useState({
    //     coordinate_desc: "",
    //     coordinate_type: "",
    //     coordinate_number: ""
    // });
    // const durationOption = [
    //     {
    //       label: "1 Jam",
    //       value: 1,
    //     },
    //     {
    //       label: "1 Hari",
    //       value: 2,
    //     },
    //     {
    //       label: "1 Minggu",
    //       value: 3,
    //     },
    //   ];
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    useEffect(() => {
        // loadTankOption();
        loadCoordinate();
    }, []);

    useEffect(() => {
        if (listTankAndValue !== undefined) {
            console.log('listTankAndValue ->', listTankAndValue);
            setLoading(false);
            setIsDataReady(true);
        }
        
    }, [listTankAndValue]);

    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            // console.log('SMS Serial Number: ', smsSerialNumber);
            // console.log('vesselId', vesselId);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);



    const loadCoordinate = async () => {
        try {
            let response = await getConvertedTanksReadingValues(cookies.token, smsSerialNumber, vesselId);
            console.log(response);
            let receivedDate = moment(response[0].received_date).format("yyyy-MM-DD HH:mm:ss");
            setLastReceivedDate(receivedDate);
            setListTankAndValue(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    
    return (
        <>
        <div className="tankNewRes">
            <div className="mainPageTank">
                {
                    listTankAndValue && listTankAndValue.map((data, index) => {
                        return (
                            <div key={index} className="innerTank">
                                    <TankLevel text={data ? data.tank_full_name : ""} value={Number(data.value_percentage).toFixed(2)} wireBreak={data.wirebreak} m3Value = {data.capacity_m3}/> : <></>
                            </div>
                        )
                    })
                    
                }
                <div className="lastReceivedDateContainerNew">
                    <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
                </div>

            </div>
            
        </div>
        <Loading
                    loading={loading}
                />
        </>
    );
}