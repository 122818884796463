// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxSwitchToggle {

    background: #14163F;
    border: 2px solid #298BFE;
    border-radius: 7px;
    padding: 10px;
}

.boxSwitchToggle p {
    margin: 0px;
}

.boxSwitchToggle div {
    display: flex;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/Components/BoxSwitchToggle/BoxSwitchToggle.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".boxSwitchToggle {\n\n    background: #14163F;\n    border: 2px solid #298BFE;\n    border-radius: 7px;\n    padding: 10px;\n}\n\n.boxSwitchToggle p {\n    margin: 0px;\n}\n\n.boxSwitchToggle div {\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
