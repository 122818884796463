import "./index.css";
// import "../../SMSApp.css";
import BitLamp from "../../../Components/NewBitLampForMSB/NewBitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValueForMSBNew } from "../../../Helpers/ApplicationHelpers";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import { useStateManager } from "react-select";
import moment from "moment";
import BoxWithText from "../../../Components/BoxWithTextForMSB/BoxWithText";

export default function CustomerSMSMainSwitchBoardPage(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([5, 11, 12]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [loading, setLoading] = useState(true);
    const [bitlampList, setBitlampList] = useState({

        M328:
        {
            text: "MCBOnG1",
            alarm: true,
            coordinate: "M328",
            value: "false"
        },
        M340:
        {
            text: "MCBOnG2",
            alarm: true,
            coordinate: "M340",
            value: "false"
        },
        M352:
        {
            text: "MCBOnShore",
            alarm: true,
            coordinate: "M352",
            value: "false"
        },
        M330:
        {
            text: "MCBOffG1",
            alarm: true,
            coordinate: "M330",
            value: "false"
        },
        M342:
        {
            text: "MCBOffG2",
            alarm: true,
            coordinate: "M342",
            value: "false"
        },
        M354:
        {
            text: "MCBOffShore",
            alarm: true,
            coordinate: "M354",
            value: "false"
        },
        M332:
        {
            text: "Power Relay G1",
            alarm: true,
            coordinate: "M332",
            value: "false"
        },
        M344:
        {
            text: "Power Relay G2",
            alarm: true,
            coordinate: "M344",
            value: "false"
        },
        // M310:
        // {
        //     text: "Power Relay Shore",
        //     alarm: true,
        //     coordinate: "M310",
        //     value: "false"
        // },
        M334:
        {
            text: "Overcurrent Relay G1",
            alarm: true,
            coordinate: "M334",
            value: "false"
        },
        M346:
        {
            text: "Overcurrent Relay G2",
            alarm: true,
            coordinate: "M346",
            value: "false"
        },
        M336:
        {
            text: "Phase Failure Indicator G1",
            alarm: true,
            coordinate: "M336",
            value: "false"
        },
        M348:
        {
            text: "Phase Failure Indicator G2",
            alarm: true,
            coordinate: "M348",
            value: "false"
        },
        AEP20:
        {
            text: "Gen. 1 Engine Load",
            alarm: true,
            coordinate: "AEP20",
            value: ""
        },
        AES20:
        {
            text: "Gen. 2 Engine Load",
            alarm: true,
            coordinate: "AES20",
            value: ""
        },
        // M348:
        // {
        //     text: "Rev Power Relay G2",
        //     alarm: true,
        //     coordinate: "M348",
        //     value: "false"
        // },
        // M332:
        // {
        //     text: "Rev Power Relay Shore",
        //     alarm: true,
        //     coordinate: "M332",
        //     value: "false"
        // },
        // M350:
        // {
        //     text: "Voltage Relay G1",
        //     alarm: true,
        //     coordinate: "M350",
        //     value: "false"
        // },
        // M316:
        // {
        //     text: "Voltage Relay G2",
        //     alarm: true,
        //     coordinate: "M316",
        //     value: "false"
        // },
        // M334:
        // {
        //     text: "Voltage Relay Shore",
        //     alarm: true,
        //     coordinate: "M334",
        //     value: "false"
        // },
        // M392:
        // {
        //     text: "AE 1 MCCB On",
        //     alarm: true,
        //     coordinate: "M392",
        //     value: "false"
        // },
        // M394:
        // {
        //     text: "AE 1 MCCB Off",
        //     alarm: true,
        //     coordinate: "M394",
        //     value: "false"
        // },
        // M396:
        // {
        //     text: "AE 2 MCCB On",
        //     alarm: true,
        //     coordinate: "M396",
        //     value: "false"
        // },
        // M398:
        // {
        //     text: "AE 2 MCCB Off",
        //     alarm: true,
        //     coordinate: "M398",
        //     value: "false"
        // },
        M318:
        {
            text: "Synchronizer",
            alarm: true,
            coordinate: "M318",
            value: "false"
        },
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                // console.log(vesselId);
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response = await getPLCReadingValueForMSBNew(cookies.token, smsSerialNumber, vesselId)
            console.log(response);
            let receivedDate = moment(response[0].read_date).format("yyyy-MM-DD HH:mm:ss");
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p => p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].text = find.coordinate_desc;
                    bitlampListTmp[bl].alarm = find.is_alarm;
                    bitlampListTmp[bl].value = find.reading_value;
                }
                let find2 = response.find(p => p.coordinate_type === "AEP" && p.coordinate_number === 20)
                if (find2) {
                    bitlampListTmp['AEP20'].text = find2.coordinate_desc;
                    bitlampListTmp['AEP20'].alarm = find2.is_alarm;
                    bitlampListTmp['AEP20'].value = find2.reading_value;
                }
                let find3 = response.find(p => p.coordinate_type === "AES" && p.coordinate_number === 20)
                if (find3) {
                    bitlampListTmp['AES20'].text = find3.coordinate_desc;
                    bitlampListTmp['AES20'].alarm = find3.is_alarm;
                    bitlampListTmp['AES20'].value = find3.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log('bitlampList', bitlampList)
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="MSBNewRes">
                <div className="containerBoxMSBNEW">
                    <div className="marginForTitle">
                        <p className="titleWatertightdoor">{'Main Switchboard'}</p>
                    </div>
                    {/* <div className="innercontainerBoxMSBNew"> */}
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        flexWrap: "wrap"
                    }}>
                        {/* <div className="innercontainerBoxWatertightDoorLeft"> */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M330"].text} bitlampColor="black" alarm={bitlampList["M330"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M328"].text} bitlampColor="black" alarm={bitlampList["M328"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M334"].text} bitlampColor="black" alarm={bitlampList["M334"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M336"].text} bitlampColor="black" alarm={bitlampList["M336"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M332"].text} bitlampColor="black" alarm={bitlampList["M332"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BoxWithText title={'GEN 1 LOAD'} value={bitlampList["AEP20"].value} unit={' KWH'} />
                            </div>
                        </div>
                        {/* <div className="innercontainerBoxWatertightDoorMiddle"> */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1

                        }}>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M342"].text} bitlampColor="black" alarm={bitlampList["M342"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M340"].text} bitlampColor="black" alarm={bitlampList["M340"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M346"].text} bitlampColor="black" alarm={bitlampList["M346"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M348"].text} bitlampColor="black" alarm={bitlampList["M348"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M344"].text} bitlampColor="black" alarm={bitlampList["M344"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BoxWithText title={'GEN 2 LOAD'} value={bitlampList["AES20"].value} unit={' KWH'} />
                            </div>
                        </div>
                        {/* <div className="innercontainerBoxWatertightDoorRight"> */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1

                        }}>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M354"].text} bitlampColor="black" alarm={bitlampList["M354"].value} />
                            </div>
                            <div className="marginForBitlampMSB">
                                <BitLamp text={bitlampList["M352"].text} isGreen={true} bitlampColor="black" alarm={bitlampList["M352"].value} />
                            </div>
                        </div>


                    </div>
                    
                </div>
                <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
                    </div>
            </div>
        </>
    );
}