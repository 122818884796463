// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainMainSMSDashboardContainer {
    display: flex;
    width: 100%;
    height: 100%;
    /* border: 2px rgb(240, 0, 0) solid; */
}


.leftNavBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
    width: 100%;
    height: 100%;
    /* max-width: max-content; */
    padding: 5px;
    /* border: 2px rgb(255, 0, 242) solid; */
}

.rightSMSDasboardContainer {
    display: flex;
    flex-direction: column;
    /* border: 2px rgb(255, 0, 242) solid; */
    width: 100%;
    height: 100%;
    max-height: max-content;
    margin: 0 15px;
}

.containerBoxTop{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0%;
    width: 100%;
    max-width: auto;
    height: 120px;
    /* border: 2px rgb(255, 0, 0) solid; */
}

.containerBoxBottom{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0%;
    width: 100%;
    height: 100%;
    /* border: 2px rgb(255, 0, 0) solid; */
}
/* .contentNavBar {
    display: flex;
    gap: 10px;
} */

`, "",{"version":3,"sources":["webpack://./src/Components/NewSMSNavContainer/NewSMSNavContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,sCAAsC;AAC1C;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,YAAY;IACZ,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,wCAAwC;IACxC,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,WAAW;IACX,eAAe;IACf,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,sCAAsC;AAC1C;AACA;;;GAGG","sourcesContent":[".mainMainSMSDashboardContainer {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    /* border: 2px rgb(240, 0, 0) solid; */\n}\n\n\n.leftNavBarContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    /* flex-wrap: wrap; */\n    width: 100%;\n    height: 100%;\n    /* max-width: max-content; */\n    padding: 5px;\n    /* border: 2px rgb(255, 0, 242) solid; */\n}\n\n.rightSMSDasboardContainer {\n    display: flex;\n    flex-direction: column;\n    /* border: 2px rgb(255, 0, 242) solid; */\n    width: 100%;\n    height: 100%;\n    max-height: max-content;\n    margin: 0 15px;\n}\n\n.containerBoxTop{\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0 0%;\n    width: 100%;\n    max-width: auto;\n    height: 120px;\n    /* border: 2px rgb(255, 0, 0) solid; */\n}\n\n.containerBoxBottom{\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0 0%;\n    width: 100%;\n    height: 100%;\n    /* border: 2px rgb(255, 0, 0) solid; */\n}\n/* .contentNavBar {\n    display: flex;\n    gap: 10px;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
