import "./index.css";
import "../../App.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import BoxSwitchToggle from "../../Components/BoxSwitchToggle/BoxSwitchToggle";

export default function OutsideLighting() {
    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"Outside Lighting"} /></div>
            <div className="contentOutsideLighting">
                <div className="flexColumn">
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"SEARCH LIGHT AFT"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"FLOOD FWD PS"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"FLOOD FWD SB"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"FLOOD AFT PS"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"FLOOD AFT SB"} />
                    </div>
                </div>
                <div className="flexColumn">
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"MORSE PS"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"MORSE SB"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"WH PS"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"WH SB"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"WH AFT"} />
                    </div>
                </div>
                <div className="flexColumn">
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"M/D AFT"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"M/D PS"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"M/D SB"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"SHIPNAME PS/SB"} />
                    </div>
                    <div className="sizedBoxSwitch">
                        <BoxSwitchToggle text={"CANOPY"} />
                    </div>
                </div>
            </div>
            <div><BottomNavBar /></div>
        </>
    );
}