import "./NewNavLightContainer.css"

export default function BitLamp({ text, alarm, bitlampColor = "" , fontSize=16}) {
    return (
        // <div className="bitLamp">
            // {
            //     alarm === "true" ?
            //         <div className="bitLampOn"></div> : <div className="bitlampBlack" ></div>
            // }

        //     <div className="textBitLamp" style={{ fontSize }}>{text}</div>
        // </div>
        <>
            {
                alarm === 'true' ?
                    <div className="mainContainerMPOn">
                        <div className="containerTextTitleNew">
                            <p className="textBitLampMP">{text}</p>
                            
                        </div>
                    </div> :
                    <div className="mainContainerMPOff">
                        <div className="containerTextTitleNew">
                            <p className="textBitLampMP">{text}</p>
                            
                        </div>
                    </div>
            }
        </>
    )
}