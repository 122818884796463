import React, { useState, useEffect, Fragment, useRef } from "react";
import { Button, Form, Container, Col, Row, Table, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, createStaff, getMechanic, getStaffByid, getGender, getStaffPosition, convertBase64, getSpecializaton, getStaffxSpecializationById, getUser, insertUpdateUser, getUserRole, getUserById, deleteUserAccount, checkUsername } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import AvatarIcon from "../../../../Assets/Images/default.jpg"
import { EyeFill, PencilFill, Trash, ArrowLeft} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import TextBox from "../../../../Components/Textbox";
import ButtonForm from "../../../../Components/ButtonForm";
import Loading from "../../../../Components/Loading";
import DropDownForm from "../../../../Components/DropDownForm";
import FormSelect from "../../../../Components/FormSelect";



export default function MechanicDetailPage() {
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listGender, setListGender] = useState([]);
    const [listStaffPosition, setListStaffPosition] = useState([]);
    const [staffPositionOptions, setStaffPositionOptions] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [saveButtonModalDisabled, setSaveButtonModalDisabled] = useState(false);
    const [listSpecialization, setListSpecialization] = useState([]);
    const [listUserRole, setListUserRole] = useState([]);
    const [formHidden, setFormHidden] = useState(true);
    const [formFieldStaff, setFormFieldStaff] = useState(false);
    const [staffxSpecialization, setStaffxSpecialization] = useState([]);
    const [listAccount, setListAccount] = useState([]);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState("");
    const [userIdRemove, setUserIdRemove] = useState("");
    const [isAllowLoginChecked, setIsAllowLoginChecked] = useState(false);
    const [isFieldStaffChecked, setIsFieldStaffChecked] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);

    const [staff, setStaff] = useState({
        staff_id: 0,
        staff_name: "",
        employee_number: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        phone_number_1: "",
        phone_number_2: "",
        email: "",
        gender: "",
        position_id: null,
        file: "",
        photo_base64: "",
        special: [],

    });

    const [user, setUser] = useState({
        id: 0,
        login_username: "",
        login_password: "",
        login_password_confirmation: "",
        email: "",
        role_id: "",
        language_id: "",
        staff_id: "",
    });

    const [fileImg, setFileImg] = useState("");



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadGender();
                loadStaffPosition();
                loadStaffSpecialization();
                loadStaffxSpecialization();
                loadUserRole();
            }
        }
        checkCookies();
        if (location.state.staffId === 0) {
            setLoading(false);
        } else {
            initStaff();
            loadStaffAccount();
        }
    }, []);

    useEffect(() => {
        if (userId !== "")
            loadUserByid();
    }, [userId]);

    useEffect(() => {
        removeUser();
    }, [userIdRemove]);

    useEffect(() => {
        if (user.login_username !== "")
            checkUsernames();
    }, [user.login_username]);

    useEffect(() => {
        validationForm();
    }, [staff])

    const loadGender = async () => {
        try {
            let listGender = await getGender(cookies.token, cookies.languageId);
            setListGender(listGender);
        }
        catch (exception) {
            console.log(exception);

        }
    }


    const checkUsernames = async () => {

        let response = await checkUsername(cookies.token, user.login_username);
        if (response) {
            if (response.login_username === user.login_username) {
                setErrors({
                    ...errors,
                    loginUsername: 'Username Telah Digunakan'
                })
                setSaveButtonModalDisabled(true);
            }

        } else {
            let check = omit(errors, "loginUsername");
            setErrors(check);
            setSaveButtonModalDisabled(false);
        }
        
    }

    const loadStaffPosition = async () => {
        try {
            let listStaffPosition = await getStaffPosition(cookies.token, cookies.languageId);
            setListStaffPosition(listStaffPosition);
            let listStaffPositionNew = [];
            for (let i = 0; i < listStaffPosition.length; i++) {
                var obj = {
                    value: listStaffPosition[i].id,
                    label: listStaffPosition[i].position_name,
                    id: listStaffPosition[i].id

                };

                listStaffPositionNew.push(obj);

            }
            setStaffPositionOptions(listStaffPositionNew);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffAccount = async () => {
        try {
            let listAccount = await getUser(cookies.token, location.state.staffId, cookies.extCustomerId);
            setListAccount(listAccount);

        } catch (exception) {
            console.log(exception);
        }
    }

    const initStaff = async () => {
        try {
            let response = await getStaffByid(cookies.token, cookies.languageId, location.state.staffId);
            if (response) {
                setStaff({
                    ...staff,
                    Id: response.staff_id,
                    staff_name: response.staff_name,
                    employee_number: response.employee_number,
                    date_of_birth: response.date_of_birth,
                    place_of_birth: response.place_of_birth,
                    address: response.address,
                    phone_number_1: response.phone_number_1,
                    phone_number_2: response.phone_number_2,
                    email: response.email,
                    gender: response.gender_id,
                    position_id: response.position_id,
                    photo_base64: response.photo_base64,
                    is_field_staff: response.is_field_staff,
                    allow_login: response.allow_login

                });
                setIsFieldStaffChecked(response.is_field_staff);
                setIsAllowLoginChecked(response.allow_login);
                if (response.is_field_staff === true) {
                    setFormFieldStaff(false);
                }
                if (response.allow_login === true) {
                    setFormHidden(false);
                }
                setLoading(false);
                setLoading(false);
            } else {
            }

        }
        catch (exception) {
            console.log(exception);
        }

    }

    const addStaff = async () => {
        try {
            let response = await createStaff(cookies.token, staff, location.state.staffId, listSpecialization, isFieldStaffChecked, isAllowLoginChecked);
            if (response.error_code === 0) {
                alert("Berhasil Menyimpan Data");
                if (location.state.staffId === 0) {
                    navigate("/Mechanic");
                } else {
                    initStaff();
                }
            } else {
                alert("Gagal Menyimpan Data");
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception)
            setDisabledButton(false);
        }

    }

    const loadStaffxSpecialization = async () => {
        try {
            let staffxSpecialization = await getStaffxSpecializationById(cookies.token, location.state.staffId);
            setStaffxSpecialization(staffxSpecialization);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffSpecialization = async () => {
        try {
            let listSpecialization = await getSpecializaton(cookies.token, cookies.languageId);
            if (listSpecialization.length > 0) {
                for (let i = 0; i < listSpecialization.length; i++) {
                    let response = await getStaffxSpecializationById(cookies.token, location.state.staffId, listSpecialization[i].id)
                    if (response.length > 0) {
                        listSpecialization[i].checked = true
                    } else {
                        listSpecialization[i.checked] = false
                    }
                }
            }
            setListSpecialization(listSpecialization);
        } catch (exception) {
            console.log(exception);
        }
    }

    const addUser = async () => {
        let response = await insertUpdateUser(cookies.token, user);
        if (response === 0) {
            loadStaffAccount();
            handleClose();
        } else {
        }
    }

    const loadUserRole = async () => {
        try {
            let listUserRole = await getUserRole(cookies.token);
            setListUserRole(listUserRole);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleResets = async () => {

        setUser({
            name: "",
            pass: "",
            confirPass: "",
            email: "",
            roleId: "",
            languageId: cookies.languageId,
            staffId: location.state.staffId,
            staffName: location.state.staffName
        });

    }

    const loadUserByid = async () => {
        try {
            let response = await getUserById(cookies.token, userId);
            if (response) {
                setUser({
                    ...user,
                    name: response.login_username,
                    pass: response.password,
                    confirPass: response.password,
                    email: response.email,
                    roleId: response.role_id
                })
            }
        } catch (exception) {

        }
    }

    const removeUser = async () => {
        let response = await deleteUserAccount(cookies.token, userIdRemove);
        if (response === undefined) {
            handleClose();
            loadStaffAccount();
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (staff.staff_name === undefined || staff.staff_name === "") {
                    setErrors({
                        ...errors,
                        staff_names: 'Harus Diisi'
                    },
                        setDisabledButton(true))
                } else if (staff.gender === undefined || staff.gender === "") {
                    setErrors({
                        ...errors,
                        staff_gender: 'Harus Diisi'
                    },
                        setDisabledButton(true));
                }
                else if (staff.date_of_birth === undefined || staff.date_of_birth === "") {
                    setErrors({
                        ...errors,
                        staff_birthdate: 'Harus Diisi'
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "staff_names", "staff_gender", "staff_birthdate");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                if (staff.email !== "") {
                    if (!new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(staff.email)) {
                        setErrors({
                            ...errors,
                            staffEmail: 'Masukkan Format Email Dengan Benar'
                        },
                            setDisabledButton(true))
                    } else {

                        let email = omit(errors, "staffEmail", "staff_names");
                        setErrors(email);
                        setDisabledButton(false)
                    }
                    // if (!new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(staff.email)) {
                    //     setErrors({
                    //         ...errors,
                    //         userEmail: 'Enter the correct email format'
                    //     },
                    //         setDisabledButton(true))
                    // } else {

                    //     let emails = omit(errors, "userEmail", "staff_names");
                    //     setErrors(emails);
                    //     setDisabledButton(false)
                    // }
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={"Detail Mekanik"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Mechanic"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    } childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Profil Mekanik"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    padding: 10
                                                }}>

                                                    {

                                                        staff.photo_base64 === "" || staff.photo_base64 === undefined ?
                                                            <img className="student-portrait-img" src={AvatarIcon} />
                                                            :
                                                            <img className="student-portrait-img" src={staff.photo_base64} />

                                                    }
                                                </div>
                                                <div style={{
                                                    padding: 2,
                                                    borderStyle: "solid",
                                                    borderRadius: 5,
                                                    borderWidth: 1,
                                                    borderColor: "#bf0000",
                                                    marginBottom: 10,
                                                    width: "50%",
                                                    marginLeft: "auto",
                                                    marginRight: "auto"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 2,
                                                            flexDirection: "row",
                                                        }}>
                                                            <Button onClick={() => {
                                                                inputFile.current.click();
                                                            }}>Pilih Photo</Button>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 5,
                                                            width: "100%",
                                                            flexDirection: "column"
                                                        }}>
                                                            <Form.Control onClick={() => {
                                                                inputFile.current.click();
                                                            }} placeholder="Pilih Photo" type="text" readOnly={true} />
                                                            <input onChange={async (e) => {
                                                                let base64Img = await convertBase64(e.target.files[0]);
                                                                const base64ImgString = base64Img.toString();
                                                                setStaff({ ...staff, photo_base64: base64ImgString, file: e.target.value });
                                                               
                                                            }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%",
                                                        paddingRight: 10
                                                    }}>
                                                        <TextBox value={staff.staff_name} placeholder="Nama Mekanik"
                                                            caption="Nama Mekanik" setValueFunction={(e) => {
                                                                setStaff({ ...staff, staff_name: e.target.value });
                                                            }} />
                                                        {
                                                            errors.staff_names && <p style={{ color: "red" }}>{errors.staff_names}</p>
                                                        }
                                                        <DropDownForm value={staff.gender} caption="Jenis Kelamin" placeholder="Pilih Jenis Kelamin"
                                                            setValueFunction={(e) => {
                                                                setStaff({ ...staff, gender: e.target.value });
                                                            }} listOption={listGender} valueKey="id" labelKey="gender" />
                                                        {
                                                            errors.staff_gender && <p style={{ color: "red" }}>{errors.staff_gender}</p>
                                                        }
                                                        <TextBox value={staff.employee_number} placeholder="NIK Mekanik "
                                                            caption="NIK Mekanik" setValueFunction={(e) => {
                                                                setStaff({ ...staff, employee_number: e.target.value });
                                                            }} />
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%",
                                                        paddingLeft: 10
                                                    }}>
                                                        <TextBox value={staff.date_of_birth} controlType="date" placeholder="Tanggal Lahir"
                                                            caption="Tanggal Lahir" setValueFunction={(e) => {
                                                                setStaff({ ...staff, date_of_birth: e.target.value });
                                                            }} />
                                                        {
                                                            errors.staff_birthdate && <p style={{ color: "red" }}>{errors.staff_birthdate}</p>
                                                        }
                                                        <TextBox value={staff.place_of_birth} placeholder="Tempat Lahir"
                                                            caption="Tempat Lahir" setValueFunction={(e) => {
                                                                setStaff({ ...staff, place_of_birth: e.target.value });
                                                            }} />

                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Staf Lapangan</Form.Label>
                                                            <Form.Check onClick={(e) => {
                                                                if (e.target.checked) {
                                                                    setIsFieldStaffChecked(true);
                                                                    setFormFieldStaff(false);
                                                                    setStaff({ ...staff, is_field_staff: "true" })
                                                                }
                                                                else {
                                                                    setIsFieldStaffChecked(false);
                                                                    setFormFieldStaff(true);
                                                                    setStaff({ ...staff, is_field_staff: false })
                                                                }


                                                            }} label={"Staf Lapangan?"} defaultChecked={true} disabled={true}  type={"switch"}></Form.Check>

                                                        </Form.Group>

                                                    </div>

                                                </div>


                                                {/* {
                                                errors.company_category_id && <p style={{ color: "red" }}>{errors.company_category_id}</p>
                                            } */}

                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>

                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Informasi Kontak"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap"
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>
                                                        <TextBox value={staff.address} placeholder="Alamat Mekanik"
                                                            caption="Alamat Mekanik" setValueFunction={(e) => {
                                                                setStaff({ ...staff, address: e.target.value });
                                                            }} />
                                                        {/* {
                                                errors.company_address && <p style={{ color: "red" }}>{errors.company_address}</p>
                                            } */}
                                                        <TextBox value={staff.phone_number_1} placeholder="Nomor Telepon"
                                                            caption="Nomor Telepon" setValueFunction={(e) => {
                                                                setStaff({ ...staff, phone_number_1: e.target.value });
                                                            }} />
                                                        {/* {
                                                errors.company_phone && <p style={{ color: "red" }}>{errors.company_phone}</p>
                                            } */}
                                                        <TextBox value={staff.phone_number_2} placeholder="Nomor HP"
                                                            caption="Nomor HP" setValueFunction={(e) => {
                                                                setStaff({ ...staff, phone_number_2: e.target.value });
                                                            }} />
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        paddingLeft: 10
                                                    }}>
                                                        <TextBox value={staff.email} placeholder="Email"
                                                            caption="Email" setValueFunction={(e) => {
                                                                setStaff({ ...staff, email: e.target.value });

                                                            }} />
                                                        {
                                                            errors.staffEmail && <p style={{ color: "red" }}>{errors.staffEmail}</p>
                                                        }

                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>

                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5
                                }} >
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 10
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }} hidden={formFieldStaff}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Posisi Pekerjaan"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>
                                                        {/* <DropDownForm value={staff.position_id} caption="Staff Position" placeholder="Select Position"
                                            setValueFunction={(e) => {
                                                setStaff({ ...staff, position_id: e.target.value });
                                            }} listOption={listStaffPosition} valueKey="id" labelKey="position_name" /> */}
                                                        <FormSelect caption="Posisi Mekanik" autoFocusValue={staff.position_id === undefined} placeholder="Pilih Posisi..."
                                                            options={staffPositionOptions} value={staffPositionOptions.filter(function (option) {
                                                                return option.id === staff.position_id;
                                                            })} setValueFunction={(e) => {
                                                                setStaff({ ...staff, position_id: e.id });
                                                            }}
                                                        />
                                                        {
                                                            listSpecialization &&
                                                            <Form.Group hidden={formFieldStaff} >
                                                                <Form.Label>Staff Specialization</Form.Label>
                                                                {
                                                                    listSpecialization.map((specialization, index) => {
                                                                        return (
                                                                            <div key={specialization.id}>
                                                                                <Form.Check key={specialization.id} onChange={(e) => {
                                                                                    let tmpListSpec = listSpecialization;
                                                                                    let selectedSpecIdx = tmpListSpec.findIndex(p => p.id === specialization.id);
                                                                                    if (e.target.checked) {
                                                                                        tmpListSpec[selectedSpecIdx].checked = true;
                                                                                        tmpListSpec[selectedSpecIdx].staffId = location.state.staffId;
                                                                                        setListSpecialization(tmpListSpec);
                                                                                    } else {
                                                                                        tmpListSpec[selectedSpecIdx].checked = false;
                                                                                        setListSpecialization(tmpListSpec);
                                                                                    }
                                                                                }} type={"checkbox"} label={specialization.specialization_name} defaultChecked={specialization.checked} disabled={formFieldStaff} >
                                                                                </Form.Check>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }
                                                            </Form.Group>
                                                        }
                                                    </div>



                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingLeft: 10
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }} >
                                            {
                                                location.state.staffId === undefined || location.state.staffId === 0 ?
                                                    <></>
                                                    :
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Akun Pengguna"} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            {
                                                                location.state.staffId !== undefined || location.state.staffId !== 0 ?
                                                                    <Form.Group className="mb-3">
                                                                        {/* <Form.Label>Allow login</Form.Label> */}
                                                                        <Form.Check onClick={async (e) => {
                                                                            if (e.target.checked) {
                                                                                setFormHidden(false);
                                                                                setIsAllowLoginChecked(true);
                                                                                setStaff({ ...staff, allow_login: "true" });
                                                                                // await createStaff(cookies.token, staff, location.state.staffId);

                                                                            } else {
                                                                                setFormHidden(true);
                                                                                setIsAllowLoginChecked(false);
                                                                                setStaff({ ...staff, allow_login: false });
                                                                                // await createStaff(cookies.token, staff, location.state.staffId);
                                                                            }
                                                                        }} label={"izinkan masuk"} defaultChecked={staff.allow_login} type="switch"></Form.Check>
                                                                    </Form.Group> : <></>
                                                            }


                                                            <Table hidden={formHidden}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Id Pengguna</th>
                                                                        <th>email</th>
                                                                        {/* <th>last Login</th> */}
                                                                        <th>Aksi</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        listAccount.map((userAccount, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{userAccount.login_username}</td>
                                                                                    <td>{userAccount.email}</td>
                                                                                    {/* <td>Tes</td> */}
                                                                                    <td><Button variant="primary" onClick={() => {
                                                                                        setUser(userAccount);
                                                                                        //setUserId(userAccount.id);
                                                                                        handleShow();

                                                                                    }}><EyeFill />/<PencilFill /></Button>
                                                                                        <Button className="cancel" variant="danger" onClick={() => {
                                                                                            if (window.confirm('Apakah Anda Ingin Menghapus Data Ini?')) {
                                                                                                setUserIdRemove(userAccount.id);
                                                                                                // removeStaff();
                                                                                            }
                                                                                        }}><Trash /></Button></td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                    }

                                                                </tbody>
                                                            </Table>
                                                            <Button onClick={() => {
                                                                setUser({
                                                                    id: 0,
                                                                    login_username: "",
                                                                    login_password: "",
                                                                    login_password_confirmation: "",
                                                                    email: "",
                                                                    role_id: "",
                                                                    language_id: cookies.languageId,
                                                                    staff_id: location.state.staffId,
                                                                });
                                                                handleShow();
                                                            }} hidden={formHidden}>Tambah Akun</Button>

                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <ButtonForm addData={() => {
                                addStaff();
                                setDisabledButton(true);
                            }} path="/Mechanic" button={disabledButton} />
                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>Tambah Akun</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        // checkUsername();
                        addUser();
                        addStaff();
                    }}>
                        <Form.Group className="mb-3">
                            <Form.Label>id pengguna</Form.Label>
                            <Form.Control onChange={(e) => {
                                setUser({ ...user, login_username: e.target.value })
                            }} type="text" value={user.login_username}  ></Form.Control>
                            {
                                errors.loginUsername && <p style={{ color: "red" }}>{errors.loginUsername}</p>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control onChange={(e) => {
                                setUser({ ...user, email: e.target.value })
                                if (!new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(e.target.value)) {
                                    setErrors({
                                        ...errors,
                                        userEmail: 'Enter the correct email format'
                                    },
                                        setSaveButtonModalDisabled(true))
                                } else {

                                    let emails = omit(errors, "userEmail");
                                    setErrors(emails);
                                    setSaveButtonModalDisabled(false)
                                }
                            }} type="text" value={user.email} ></Form.Control>
                            {
                                errors.userEmail && <p style={{ color: "red" }}>{errors.userEmail}</p>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Status Pengguna</Form.Label>
                            <Form.Select onChange={(e) => {
                                setUser({ ...user, role_id: e.target.value });
                            }} value={user.role_id} >
                                <option value="" selected disabled>
                                    Pilih Status...
                                </option>
                                {
                                    listUserRole.map((userRole, index) => (
                                        <option key={index} value={userRole.id}>{userRole.role_name}</option>
                                    )
                                    )
                                }
                            </Form.Select>
                        </Form.Group>


                        <div>
                            <Form.Group className="mb-3">
                                <Form.Label>Kata Sandi</Form.Label>
                                <input type="password" onChange={(e) => {
                                    setUser({ ...user, login_password: e.target.value })
                                }} class="form-control" value={user.login_password} ></input>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label  >Ulang Kata Sandi</Form.Label>
                                <input type="password" onChange={(e) => {
                                    if (user.login_password !== e.target.value) {
                                        setErrors({
                                            ...errors,
                                            login_password_confirmation: ' Password tidak sama'
                                        })
                                        setSaveButtonModalDisabled(true);
                                    } else {

                                        let pass = omit(errors, "login_password_confirmation");
                                        setErrors(pass);
                                        setSaveButtonModalDisabled(false)
                                    }
                                    setUser({ ...user, login_password_confirmation: e.target.value })
                                }} class="form-control" value={user.login_password_confirmation} />
                                {
                                    errors.login_password_confirmation && <p style={{ color: "red" }}>{errors.login_password_confirmation}</p>
                                }
                            </Form.Group></div>


                        <Button type="submit" disabled={saveButtonModalDisabled}>Simpan</Button>
                        <Button className="cancel" onClick={() => {
                            handleClose();
                            handleResets();
                        }}>Batal</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )

}
