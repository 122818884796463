import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useState, useEffect } from "react";
import moment from "moment";

export default function DynamicLineChartForFuelConsumption({dataArray, minValue, maxValue, stepSize, 
  xAxisFieldName, yAxisFieldName, yAxisLabel}) {
  const [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    setDataToShow(dataArray);
  }, [dataArray])
 
  const xAxisValues = dataToShow ? dataToShow.map(data => moment(data[xAxisFieldName]).format("MM-DD HH:mm:ss")) : [];
  const yAxisValues = dataToShow ? dataToShow.map(data => Number(data[yAxisFieldName])) : [];
  // const avg = dataToShow ? parseFloat(dataToShow[dataToShow.length - 1].compensated_value) - parseFloat(dataToShow[0].compensated_value) : 0;
  // const xAxisValues = dummyData ? dummyData.map(data => data.received_date) : [];
  // const yAxisValues = dummyData ? dummyData.map(data => data.reading_value) : [];

  const data = {
    labels: xAxisValues,
    datasets: [
      {
        label: yAxisLabel,
        data: yAxisValues,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#031e67',
        borderWidth: 2,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspecRatio: false,
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      y: {
        min: minValue,
        max: maxValue,
        ticks: {
          stepSize: stepSize,
          color: '#031e67'
        },
      },
      x: {
        ticks: {
          color: '#031e67'
        }
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    animation: false
  };

  return (
    <>
    {/* {
      avg <= 0 ? (<p>Data Not Available Yet</p>) : (<></>)
    } */}
        <Line
          data={data}
          options={options}
        />
    </>
  );
}
