import './OpenSeaMap.css';
import React, { useEffect, useState, Children } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, { marker } from "leaflet";
import vesselImg from "../Assets/Images/new_arrow_pointer.png";
// import vesselImg from "../Assets/Images/ship-solid-red-resized.png";
import { Button } from "react-bootstrap";
import { EyeFill } from "react-bootstrap-icons";
import 'leaflet-rotatedmarker';
import 'leaflet-ant-path';

export default function OpenSeaMapContainer({ markerChild, isTracking = false }) {
    const [vesselName, setVesselName] = useState();
    const [companyName, setCompanyName] = useState();
    const [vesselType, setVesselType] = useState();
    const [locationData, setLocationData] = useState();
    //const [centerLocation, setCenterLocation] = u
    const defaultPosition = [-6.12747410563145, 106.85523275294094];
    const [cog, setCog] = useState(0);
    // const map = useMap();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0)

    // useEffect(() => {
    //     console.log(vesselDetail);
    //     if (vesselDetail) {
    //         setVesselName(vesselDetail.vessel_name);
    //         setCompanyName(vesselDetail.customer_name);
    //         setVesselType(vesselDetail.vessel_type_name);
    //     }
    // }, [vesselDetail]);

    // useEffect(() => {
    //     console.log('marker', markerChild);
    //     if (markerChild) {
    //         if (markerChild.listMarker) {
    //             let route = markerChild.listMarker.map(marker => marker.props.position);
    //             const path = antPath(route, {
    //                 "delay": 400,
    //                 "dashArray": [
    //                     10,
    //                     20
    //                 ],
    //                 "weight": 5,
    //                 "color": "#0000FF",
    //                 "pulseColor": "#FFFFFF",
    //                 "paused": false,
    //                 "reverse": false,
    //                 "hardwareAccelerated": true
    //             });
    //             map.addLayer(path);
    //         }
    //     }
    // }, [markerChild]);

    // const renderPolyline = () => {
    //     if (markerChild) {
    //         if (markerChild.listMarker) {
    //             let route = markerChild.listMarker.map(marker => marker.props.position);
    //             const path = antPath(route, {
    //                 "delay": 400,
    //                 "dashArray": [
    //                     10,
    //                     20
    //                 ],
    //                 "weight": 5,
    //                 "color": "#0000FF",
    //                 "pulseColor": "#FFFFFF",
    //                 "paused": false,
    //                 "reverse": false,
    //                 "hardwareAccelerated": true
    //             });
    //             map.addLayer(path);
    //         }
    //         return map;
    //     }
    // }

    useEffect(() => {
        console.log('cog', cog);

    }, [cog]);

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const AntPathComponent = ({ marker }) => {
        if (isTracking) {
            console.log(marker);
            const map = useMap();
            let route = marker.map(marker => marker.props.position);
            useEffect(() => {
                const path = L.polyline.antPath(route, {
                    delay: 800,
                    dashArray: [20, 20],
                    weight: 2,
                    color: "#0000FF",
                    pulseColor: "#FFFFFF",
                    paused: false,
                    reverse: false,
                    hardwareAccelerated: true
                });

                path.addTo(map);
                //   map.fitBounds(path.getBounds());

                return () => {
                    map.removeLayer(path);
                };

            }, [map, route]);
        } else {
            console.log(marker);
            marker.forEach((markerEl) => {
                
                const map = useMap();
                let route = markerEl.map(marker => marker.props.position);
                useEffect(() => {
                    const path = L.polyline.antPath(route, {
                        delay: 800,
                        dashArray: [20, 20],
                        weight: 2,
                        color: "#0000FF",
                        pulseColor: "#FFFFFF",
                        paused: false,
                        reverse: false,
                        hardwareAccelerated: true
                    });

                    path.addTo(map);
                    //   map.fitBounds(path.getBounds());

                    return () => {
                        map.removeLayer(path);
                    };

                }, [map, route]);
            });

        }

        return null;
    };

    const ZoomToMarkerButton = ({ position }) => {
        const map = useMap();

        const zoomToMarker = () => {

            map.setView([position.latitude, position.longitude], 20);
        };

        return (
            <Button style={{ margin: '5px' }} onClick={zoomToMarker}>
                <EyeFill />
            </Button>
        );
    };

    const renderListMarker = () => {
        if (markerChild) {
            if (markerChild.listMarker) {
                return (markerChild.listMarker);
            }
        }
    }

    const renderAnPath = () => {
        
        console.log(markerChild);
        if (markerChild) {
            if (markerChild.listLine) {
                return <AntPathComponent marker={markerChild.listLine} />
            }
        }
    }


    const renderListVessel = () => {
        if (markerChild) {
            if (markerChild.listVessel) {
                let listVessel = [];
                markerChild.listVessel.map((vessel, index) => {
                    console.log("VESSEL", vessel);
                    let vesselItem =
                        <div key={index}
                            style={{
                                position: "absolute",
                                bottom: 100,
                                left: 20,
                                width: '15%',
                                height: '80%',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                padding: '10px',
                                borderRadius: "12px",
                                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
                                zIndex: 1000,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '5px' }}>
                                <p style={{ fontSize: 'large', margin: '0px' }}>{vessel.vessel_name}</p>
                                <p style={{ margin: '0px' }}>Company: {vessel.customer_name}</p>
                                <p style={{ margin: '0px' }}>Type: {vessel.vessel_type_name}</p>
                                <ZoomToMarkerButton position={{ latitude: vessel.latitude, longitude: vessel.longitude }} />
                            </div>

                            <div style={{ border: '1px rgb(189, 189, 189) solid', width: '100%', height: '0.5px' }}></div>
                        </div>
                    listVessel.push(vesselItem);
                });

                return (listVessel);
            }
        }
    }

    return (
        <>
            <MapContainer style={{ flex: 1, width: '100%' }} center={defaultPosition} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {renderListMarker()}
                {/* {renderPolyline()} */}
                {renderListVessel()}

                {renderAnPath()}

            </MapContainer>
        </>
    );
}
