import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import {
    isTokenValid, getNewTroubleshootingById, convertBase64, getUserAccessById, convertBase64Image,
    getEquipment, getMechanic, insertUpdateNewTroubleshooting, getVessel, getProject,
    getListStaffGroupMechanic, getStaffPosition, getSubSpecialization, getStaff, getSpecializaton,
    getTroubleshootingPhotoCrew, getDocumentNewTroubleshooting
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft, CameraFill, Trash, XSquareFill, PrinterFill } from "react-bootstrap-icons";
import moment from "moment";
import DailyReportListJob from "../../../../Components/DailyReportListJob";
import NewTroubleshootingListJob from "../../../../Components/NewTroubleshootingListJob";
import Select from "react-select";
import FormSelect from "../../../../Components/FormSelect";
import { Trans, useTranslation } from "react-i18next";

export default function NewTroubleshootingDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [staffPhoto, setStaffPhoto] = useState("");
    const [listProject, setListProject] = useState([]);
    const [svcEqpCount, setSvcEqpCount] = useState([]);
    const [disabledPrint, setDisabledPrint] = useState(false);
    const [crewModal, setCrewModal] = useState("");
    const [indexStaffPhoto, setIndexStaffPhoto] = useState("");
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [listMechanicGroup, setListMechanicGroup] = useState([]);
    const [crewErrorMessage, setCrewErrorMessage] = useState("");
    const [listVessel, setListVessel] = useState([]);
    const [troubleshootingId, setTroubleshootingId] = useState("");
    const [selectedProject, setSelectedProject] = useState({});
    const [disabledAddDetail, setDisableAddDetail] = useState(false);
    const [listStaffPosition, setListStaffPosition] = useState([]);
    const [listStaffSpecialization, setListStaffSpecialization] = useState([]);
    const [listStaff, setListStaff] = useState([]);
    const [listEquipment, setListEquipment] = useState([]);
    const [disabledButtonCrew, setDisabledButtonCrew] = useState(false);
    const [selectedMechanicGroup, setSelectedMechanicGroup] = useState(null)
    const [selectedStaff, setSelectedStaff] = useState({});
    const [reportDate, setReportDate] = useState(null)
    const [reportProjectName, setReportProjectName] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [resetComponent, setResetComponent] = useState(false);
    const [docxData, setDocxData] = useState("");
    const [crewId, setCrewId] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [troubleshooting, setTroubleshooting] = useState({
        id: 0,
        report_date: null,
        description: "",
        location: "",
        mechanic_group_id: null,
        project_id: null
    });
    const [listTimePeriode, setListTimePeriode] = useState([]);
    const [listCrew, setListCrew] = useState([]);
    const [listJob, setListJob] = useState([]);
    const [listJobPhoto, setListJobPhoto] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 30,
            overflowY: "auto"
        }),
    }


    useEffect(() => {
        if (resetComponent)
            setResetComponent(false);
    }, [resetComponent])



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadAccess();
                setLoading(false);
                loadMechanic();
                loadEquipment();
                loadProject();
                loadMechanicGroup();
                loadStaffPosition();
                loadStaffSpecialization();
                setTroubleshootingId(location.state.troubleshootingId);
            }
        }
        checkCookies();
        // if (location.state.reportId === 0) {
        //     setLoading(false);
        // } else {
        //     // initCountry();
        // }
    }, []);

    useEffect(() => {
        if (troubleshootingId !== "" && troubleshootingId !== 0) {
            initTroubleshooting();
        }
    }, [troubleshootingId]);

    useEffect(() => {
        if (docxData.size > 0) {
            // console.log(docxData.size);
            downloadDocxFile();
        }
    }, [docxData]);

    useEffect(() => {
        if (reportDate) {
            setTroubleshooting({ ...troubleshooting, report_date: reportDate });
        }
    }, [reportDate]);

    // useEffect(() => {
    //     if (selectedStaff.staff_name) {
    //         setTroubleshooting({ ...troubleshooting, mechanic_group_id: selectedStaff.id });
    //     } else {
    //         setTroubleshooting({ ...troubleshooting, mechanic_group_id: null });
    //     }
    // }, [selectedStaff]);

    useEffect(() => {
        if (selectedProject.project_name) {
            setTroubleshooting({ ...troubleshooting, project_id: selectedProject.id });
            setReportProjectName(selectedProject.project_name)
        } else {
            setTroubleshooting({ ...troubleshooting, project_id: null })
            setReportProjectName("")
        }
    }, [selectedProject]);

    useEffect(() => {
        if (listProject.length > 0) {
            if (troubleshooting.project_id) {
                let response = listProject.find(p => p.id === troubleshooting.project_id);
                if (response) {
                    setSelectedProject(response);
                }

            }
        }
    }, [listProject])

    useEffect(() => {
        // console.log(listTroubleshootingCrew);
        if (listCrew.length > 0) {
            for (let index = 0; index < listCrew.length; index++) {
                const listTechnician = listCrew[index];
                if (listTechnician.staff_id === "") {
                    setCrewErrorMessage(`${t("detail_troubleshooting.error_list_technician")}`);
                    setDisabledButton(true);
                    setDisabledButtonCrew(true);
                } else {
                    setCrewErrorMessage("");
                    setDisabledButtonCrew(false);
                    validationForm();
                }

            }
        } else {
            setDisabledButtonCrew(false);
            setCrewErrorMessage("");
        }
    }, [listCrew])

    // useEffect(() => {
    //     if (listStaff.length > 0) {
    //         if (troubleshooting.mechanic_group_id) {
    //             let response = listStaff.find(p => p.id === troubleshooting.mechanic_group_id);
    //             if (response) {
    //                 setSelectedStaff(response);
    //             }

    //         }
    //     }
    // }, [listStaff])

    useEffect(() => {
        console.log(troubleshooting);
        validationForm();
        if (troubleshooting.mechanic_group_id) {
            let response = listStaff.find(p => p.id === troubleshooting.mechanic_group_id);
            if (response) {
                setSelectedStaff(response);
            }

        }
        if (troubleshooting.project_id) {
            let response = listProject.find(p => p.id === troubleshooting.project_id);
            if (response) {
                setSelectedProject(response);
            }
        }
    }, [troubleshooting])

    useEffect(() => {
        console.log(listTimePeriode);
        setResetComponent(true);
        setSvcEqpCount(listTimePeriode.length);
        forceUpdate();
    }, [listTimePeriode]);

    useEffect(() => {
        console.log(listJob)
    }, [listJob]);

    useEffect(() => {
        if (indexStaffPhoto !== "") {
            setCrewModal(true);
        }
    }, [indexStaffPhoto])


    useEffect(() => {
        if (listMechanicGroup) {
            // setListMechanicGroupNew(listMechanicGroup)
            let selectedMechanicGroupTmp = listMechanicGroup.find(p => p.id === troubleshooting.mechanic_group_id)

            if (selectedMechanicGroupTmp) {
                setSelectedMechanicGroup(selectedMechanicGroupTmp)
            }
        }
    }, [listMechanicGroup])

    useEffect(() => {
        if (selectedMechanicGroup) {
            if (selectedMechanicGroup.group_name) {
                setTroubleshooting({ ...troubleshooting, mechanic_group_id: selectedMechanicGroup.id });
            } else {
                setTroubleshooting({ ...troubleshooting, mechanic_group_id: null });
            }

        }
    }, [selectedMechanicGroup]);

    useEffect(() => {
        if (crewId !== "") {
            loadCrewPhoto();
        }

    }, [crewId])


    // useEffect(() => {
    //     validationForm();
    // }, [country]);


    const saveData = async () => {
        try {
            let troubleshootingTmp = troubleshooting;
            let listTimeTmp = listTimePeriode;
            for (let index = 0; index < listTimeTmp.length; index++) {
                const newList = listTimeTmp[index];
                newList.list_job = listJob[index]

            }
            troubleshootingTmp.time_periode = listTimeTmp;
            troubleshootingTmp.list_crew = listCrew;
            console.log(troubleshootingTmp);
            let response = await insertUpdateNewTroubleshooting(cookies.token, troubleshootingTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_troubleshooting.alert_success_save_data")}`);
                if (troubleshootingId === 0) {
                    setTroubleshootingId(response.data.id)
                } else {
                    initTroubleshooting();
                }
            }
            setDisabledButton(true);
        } catch (exception) {

        }
    }
    const initTroubleshooting = async () => {
        try {
            let response = await getNewTroubleshootingById(cookies.token, troubleshootingId);
            console.log(response);
            if (response) {
                setTroubleshooting({
                    ...troubleshooting,
                    id: response.id,
                    report_date: response.report_date,
                    description: response.description,
                    location: response.location,
                    mechanic_group_id: response.mechanic_group_id,
                    project_id: response.project_id
                })

                let listTimePeriodeTmp = []
                if (response.time_periode.length > 0) {
                    let listJobTmp = []
                    for (let index = 0; index < response.time_periode.length; index++) {
                        const list = response.time_periode[index];
                        listJobTmp.push(list.list_job)
                        list.list_job = []
                        listTimePeriodeTmp.push(list)
                    }
                    setListJob(listJobTmp);
                }
                setListCrew(response.list_crew)
                // console.log(listTroubleshootingEquipmentTmp)
                setListTimePeriode(listTimePeriodeTmp);

            } else {
                alert(`${t("detail_troubleshooting.alert_failed_load_data")}`);
            }
            setLoading(false);
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (troubleshooting.mechanic_group_id === undefined || troubleshooting.mechanic_group_id === null) {
                    setErrors({
                        ...errors,
                        staffName: `${t("detail_troubleshooting.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (troubleshooting.report_date === undefined || troubleshooting.report_date === null) {
                    setErrors({
                        ...errors,
                        reportDate: `${t("detail_troubleshooting.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (troubleshooting.project_id === undefined || troubleshooting.project_id === null) {
                    setErrors({
                        ...errors,
                        projectName: `${t("detail_troubleshooting.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "staffName", "reportDate", "projectName");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadMechanic = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, "null", undefined, undefined, undefined, true, undefined, undefined, 2);
            let listStaffTmp = response.filter(p => p.is_field_staff === true)
            setListStaff(listStaffTmp);
        } catch (exception) {

        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            setListEquipment(response);
        } catch (exception) {

        }
    }

    // const loadVessel = async () => {
    //     try {
    //         let response = await getVessel(cookies.token, cookies.languageId, "null");
    //         setListVessel(response);
    //     } catch (exception) {

    //     }
    // }

    const handleOnblurDailyReport = (report) => {
        setTroubleshooting(report)

    };

    const handleOnBlur = (dailyReportPeriode, listJob2, index) => {
        let listPeriodeTmp = listTimePeriode;
        listPeriodeTmp[index] = dailyReportPeriode;
        setListTimePeriode(listPeriodeTmp.slice(0));
        let listJobTmp = listJob;
        listJobTmp[index] = listJob2;
        setListJob(listJobTmp.slice(0));

    };

    const handleDelete = (index) => {
        let listTimePeriodeTmp = listTimePeriode;
        let troubleshootingTmp = troubleshooting
        if (!troubleshootingTmp.deleted_detail) {
            let list = [];
            list.push(listTimePeriodeTmp[index]);
            setTroubleshooting({ ...troubleshooting, deleted_detail: list })
        } else {
            let list = troubleshooting.deleted_detail;
            list.push(listTimePeriodeTmp[index])
            setTroubleshooting({ ...troubleshooting, deleted_detail: list })
        }
        listTimePeriodeTmp.splice(index, 1);
        setListTimePeriode(listTimePeriodeTmp.slice(0));

        let listJobTmp = listJob
        listJobTmp.splice(index, 1)

        setListJob(listJobTmp.slice(0))
    };

    const renderTroubleshootingEquipment = () => {
        let renderObj = [];
        // if (listEquipment.length > 0) {
        for (let i = 0; i < listTimePeriode.length; i++) {
            const svcEqp = <div key={i}>
                <NewTroubleshootingListJob
                    resetComponent={resetComponent}
                    index={i}
                    handleOnBlur={handleOnBlur}
                    handleDelete={handleDelete}
                    handleDeleteTroubleshootingJob={handleDeleteTroubleshootingJob}
                    listJob={listJob[i]}
                    // listTroubleshootingJob={listTroubleshootingJob[i]}
                    troubleshooting={troubleshooting}
                    listEquipment={listEquipment}
                    // listVesselEquipment={listVesselEquipment}
                    timePeriode={listTimePeriode[i]}
                    disabledAddDetail={setDisableAddDetail}
                // handleDeleteDailyReportCrew={handleDeleteDailyReportCrew}
                // disabledAddButton={disabledAddButton}
                // vesselId={selectedProject.project_id}
                // troubleshootingId={troubleshootingId}
                // troubleshooting={troubleshooting}
                // listTroubleshootingFinding={listTroubleshootingFinding}
                // disabledCustomerForm={disabledCustomerForm}
                />
            </div>;

            renderObj.push(svcEqp);
        }
        // }
        return renderObj;
    }

    const handleDeleteTroubleshootingJob = (listDeleted, index) => {
        setTroubleshooting({ ...troubleshooting, deleted_job: listDeleted.deleted_job })
    };

    // const handleDeleteDailyReportCrew = (listDeleted, index) => {
    //     setTroubleshooting({ ...troubleshooting, deleted_crew: listDeleted.deleted_crew });
    // }


    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, "null");
            let projects = response.filter(p => p.project_type_name === 'Troubleshooting & Repair');
            console.log(response);
            setListProject(projects);
        } catch (exception) {
        }
    }


    const loadMechanicGroup = async () => {
        try {
            let response = await getListStaffGroupMechanic(cookies.token, "null");
            setListMechanicGroup(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStaffPosition = async () => {
        try {
            let response = await getStaffPosition(cookies.token, cookies.languageId);
            setListStaffPosition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffSpecialization = async () => {
        try {
            let response = await getSpecializaton(cookies.token, cookies.languageId);
            let listSpecialization = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    id: response[i].id,
                    label: response[i].specialization_name

                };
                listSpecialization.push(obj);
            }
            setListStaffSpecialization(listSpecialization);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewPhoto = async () => {
        try {
            let response = await getTroubleshootingPhotoCrew(cookies.token, crewId);
            setStaffPhoto(response.image_base64);
            setCrewId("");
        } catch (exception) {

        }
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${reportProjectName}-${moment(troubleshooting.report_date).format("yyyy-MM-DD")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // console.log(file);
        // console.log(file.length);

        URL.revokeObjectURL(file);
        setLoading(false);

        setDisabledPrint(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getDocumentNewTroubleshooting(cookies.token, troubleshootingId)
            if (docx.size) {
                setDocxData(docx);
            } else {
                alert(`${t("detail_troubleshooting.error_download")}`);
                setLoading(false);
                setDisabledPrint(false);
            }
            // setServiceJobId("")
        } catch (exception) {
            console.log(exception)
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_troubleshooting.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/NewTroubleshooting"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={troubleshootingId !== 0 && troubleshootingId !== "" ? true : false}
                    actionContainerChild={
                        <div>
                            <Button disabled={disabledPrint} variant="secondary" style={{

                            }} onClick={() => {
                                setDisabledPrint(true)
                                createDocument();
                                setLoading(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><PrinterFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_troubleshooting.button_print")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        saveData();
                                        setDisabledButton(true);
                                        setLoading(true);
                                        setTimeout(function () {
                                            window.scrollTo(0, 0);
                                        }, 2);
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_troubleshooting.header_troubleshooting_report")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_troubleshooting.field_project_name")}</Form.Label>


                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.project_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listProject} value={selectedProject} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedProject({})
                                                                } else {
                                                                    setSelectedProject(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                        {
                                                            errors.staffName && <p style={{ color: "red" }}>{errors.staffName}</p>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_troubleshooting.field_group_technician")}</Form.Label>


                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.group_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listMechanicGroup} value={selectedMechanicGroup} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedMechanicGroup({})
                                                                } else {

                                                                    if (troubleshootingId === 0) {
                                                                        let listCrewTmp = e.list_staff.filter(p => p.is_active === true);
                                                                        setListCrew(listCrewTmp)
                                                                        // if (listCrewTmp) {
                                                                        //     handleOnBlurlistCrew(listCrewTmp)
                                                                        // }
                                                                        // let troubleshootingTmp = troubleshootingNew
                                                                        // troubleshootingTmp.staff_pic_id = null
                                                                        // setTroubleshootingNew(troubleshootingTmp)
                                                                        setSelectedMechanicGroup(e);
                                                                    } else {
                                                                        if (window.confirm(`${t("detail_troubleshooting.alert_change_group")}`)) {
                                                                            // let staffPicTmp = e.list_staff.find(p => p.is_leader === true);

                                                                            // if (staffPicTmp) {
                                                                            let troubleshootingTmp = troubleshooting
                                                                            // troubleshootingTmp.staff_pic_id = null;

                                                                            let staffCrew = e.list_staff.filter(p => p.is_active === true);
                                                                            let listStaffs = [];
                                                                            for (let index = 0; index < staffCrew.length; index++) {
                                                                                let listCrew = staffCrew[index];
                                                                                listCrew.id = null;
                                                                                listStaffs.push(listCrew);
                                                                            }
                                                                            let list = [];
                                                                            // let deleteListCrew = troubleshooting.list_crew;
                                                                            let deletedCrews = troubleshooting.deleted_crew
                                                                            if (deletedCrews !== undefined) {
                                                                                for (let i = 0; i < listCrew.length; i++) {
                                                                                    const list = listCrew[i];
                                                                                    deletedCrews.push(list)
                                                                                }
                                                                            } else {
                                                                                deletedCrews = listCrew
                                                                            }

                                                                            troubleshootingTmp.deleted_crew = deletedCrews
                                                                            // setTroubleshootingNew(troubleshootingTmp)

                                                                            if (staffCrew) {
                                                                                // handleOnBlurlistCrew(listStaffs)
                                                                                setListCrew(listStaffs);
                                                                                setTroubleshooting({ ...troubleshooting, deleted_crew: deletedCrews })
                                                                            } else {
                                                                                // handleOnBlurlistCrew([])
                                                                                setListCrew([]);
                                                                                setTroubleshooting({ ...troubleshooting, deleted_crew: deletedCrews })
                                                                            }
                                                                            // }
                                                                            setSelectedMechanicGroup(e);
                                                                        }
                                                                    }

                                                                }
                                                            }}
                                                            isClearable
                                                        // isDisabled={disabledCustomerForm}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_troubleshooting.field_report_date")}</Form.Label>
                                                        <Form.Control required type="date" onChange={(e) => {
                                                            if (e.target.value === "") {
                                                                // setTroubleshooting({ ...troubleshooting, report_date: null });
                                                                setReportDate(null);
                                                            } else {
                                                                // setTroubleshooting({ ...troubleshooting, report_date: e.target.value });
                                                                setReportDate(e.target.value)
                                                            }
                                                        }} value={troubleshooting.report_date ? moment(troubleshooting.report_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                    </Form.Group>
                                                    {
                                                        errors.reportDate && <p style={{ color: "red" }}>{errors.reportDate}</p>
                                                    }
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_troubleshooting.field_location")}</Form.Label>
                                                        <Form.Control type="text" onChange={(e) => {
                                                            setTroubleshooting({ ...troubleshooting, location: e.target.value })
                                                        }} value={troubleshooting.location}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_troubleshooting.field_description")}</Form.Label>
                                                        <Form.Control onChange={(e) => {
                                                            setTroubleshooting({ ...troubleshooting, description: e.target.value });
                                                        }} as="textarea" rows={3}></Form.Control>
                                                    </Form.Group>

                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            width: "100%",
                                            flexWrap: "nowrap",
                                            paddingRight: 5,
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                paddingBottom: 5,
                                                width: "100%",
                                                flexWrap: "nowrap",
                                            }}>


                                                <ContainerBox containerPos="inner" titleCaption={`${t("detail_troubleshooting.header_technician_list")}`}

                                                    childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                padding: 5
                                                            }}>


                                                                {
                                                                    listCrew && listCrew.map((technician, index) => {
                                                                        return (
                                                                            <div key={index} style={{ padding: 5 }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    width: "100%",
                                                                                    flexDirection: "column",
                                                                                    padding: 5,
                                                                                    borderWidth: 2,
                                                                                    borderRadius: 5,
                                                                                    borderStyle: "solid",
                                                                                    borderColor: "rgba(3, 30, 103, 1)"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row"
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 9,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <Form.Label>{t("detail_troubleshooting.table_name1")}</Form.Label>
                                                                                            <FormSelect placeholder=""
                                                                                                options={listStaff.filter((p) => {
                                                                                                    return !listCrew.find((x) => {
                                                                                                        return p.staff_id === x.staff_id
                                                                                                    }) && p.resign_date === null
                                                                                                })} getOptionLabel={(item) => {
                                                                                                    return item.staff_name;
                                                                                                }} clearValue={true}
                                                                                                getOptionValue={(item) => {
                                                                                                    return item.staff_id;
                                                                                                }} setValueFunction={(e) => {
                                                                                                    console.log(e);
                                                                                                    let listTechnician = listCrew;
                                                                                                    listTechnician[index].staff_id = e.staff_id;
                                                                                                    listTechnician[index].crew_position = e.crew_position;
                                                                                                    listTechnician[index].specialization = e.specialization
                                                                                                    setListCrew(listTechnician.slice(0))
                                                                                                }} value={listStaff.filter(function (option) {
                                                                                                    return option.staff_id === technician.staff_id;
                                                                                                })}
                                                                                            // disabledForm={disabledCustomerForm}
                                                                                            />

                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 9,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5,
                                                                                        }}>
                                                                                            <Form.Label>{t("detail_troubleshooting.table_name2")}</Form.Label>
                                                                                            <FormSelect placeholder=""
                                                                                                options={listStaffPosition} getOptionLabel={(item) => {
                                                                                                    return item.position_name;
                                                                                                }} clearValue={true}
                                                                                                getOptionValue={(item) => {
                                                                                                    return item.id;
                                                                                                }} setValueFunction={(e) => {

                                                                                                }} value={listStaffPosition.filter(function (option) {
                                                                                                    return option.id === technician.crew_position;
                                                                                                })} disabledForm={true}
                                                                                            />


                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 9,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5,
                                                                                        }}>

                                                                                            <Form.Label>{t("detail_troubleshooting.table_name3")}</Form.Label>
                                                                                            <div style={{ paddingTop: 5 }}></div>
                                                                                            <Select styles={styles} isMulti={true} placeholder=""
                                                                                                clearValue={true} closeMenuOnSelect={false}
                                                                                                options={listStaffSpecialization} value={technician.specialization} onChange={(e) => {

                                                                                                }} isClearable
                                                                                                isDisabled={true}

                                                                                            />

                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            alignItems: "flex-end",
                                                                                            flex: 1,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <div style={{ paddingTop: 35 }}></div>
                                                                                            <Button onClick={() => {
                                                                                                setIndexStaffPhoto(index);
                                                                                                if (technician.id && troubleshootingId !== 0) {
                                                                                                    console.log(technician.id)
                                                                                                    setCrewId(technician.id);
                                                                                                    setIndexStaffPhoto(index);
                                                                                                } else {
                                                                                                    if (technician.image_base64 !== "") {
                                                                                                        setStaffPhoto(technician.image_base64)
                                                                                                    }
                                                                                                }

                                                                                            }} style={{ maxWidth: 50 }} variant="primary"><CameraFill /></Button>

                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            alignItems: "flex-end",
                                                                                            flex: 1,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <div style={{ paddingTop: 35 }}></div>
                                                                                            <Button onClick={() => {
                                                                                                if (window.confirm(`${t("detail_troubleshooting.delete_confirmation")}`)) {
                                                                                                    let list = listCrew
                                                                                                    if (!troubleshooting.deleted_crew) {
                                                                                                        let listTechnician = [];
                                                                                                        listTechnician.push(list[index]);

                                                                                                        list.splice(index, 1);
                                                                                                        setTroubleshooting({ ...troubleshooting, deleted_crew: listTechnician });
                                                                                                        setListCrew(list.slice(0))
                                                                                                    } else {
                                                                                                        let listTechnician = troubleshooting.deleted_crew;
                                                                                                        listTechnician.push(list[index]);
                                                                                                        list.splice(index, 1);
                                                                                                        setTroubleshooting({ ...troubleshooting, deleted_crew: listTechnician });
                                                                                                        setListCrew(list.slice(0))
                                                                                                    }
                                                                                                }

                                                                                            }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                                        </div>

                                                                                    </div>




                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                                {
                                                                    crewErrorMessage && <p style={{ color: "red" }}>{crewErrorMessage}</p>
                                                                }
                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                padding: 5
                                                            }}>
                                                                <Button disabled={disabledButtonCrew} variant="primary" onClick={() => {
                                                                    let newParameter = {
                                                                        staff_id: "",
                                                                        image_base64: ""
                                                                    };
                                                                    if (listCrew.length === 0) {
                                                                        console.log('tes');
                                                                        let list = [];
                                                                        list.push(newParameter);
                                                                        setListCrew(list.slice(0))
                                                                    } else {
                                                                        console.log('tes2')
                                                                        let list = listCrew;
                                                                        list.push(newParameter);
                                                                        setListCrew(list.slice(0))
                                                                    }

                                                                }}>{t("detail_troubleshooting.add_technician_button")}</Button>
                                                            </div>

                                                        </div>
                                                    }>
                                                </ContainerBox>


                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_troubleshooting.header_list_job")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {

                                                        renderTroubleshootingEquipment()

                                                    }
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button disabled={disabledAddDetail} variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                start_date: null,
                                                                end_date: null,
                                                            };
                                                            if (listTimePeriode.length === 0) {
                                                                let list = [];
                                                                list.push(newParameter);
                                                                // setTroubleshooting({ ...troubleshooting, detail_job: list });
                                                                setListTimePeriode(list.slice(0))
                                                            } else {
                                                                let list = listTimePeriode;
                                                                list.push(newParameter);
                                                                // setTroubleshooting({ ...troubleshooting, detail_job: list });
                                                                setListTimePeriode(list.slice(0))
                                                            }

                                                        }}>{t("detail_troubleshooting.button_add_detail")}</Button>
                                                    </div>
                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                        <div style={{ paddingBottom: 20 }}></div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5,
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                paddingRight: 5,
                                            }}><Button style={{width:100}} disabled={disabledButton} type="submit" variant="primary" >{t("detail_troubleshooting.save_button")}</Button></div>


                                            <div style={{
                                                paddingLeft: 5,
                                            }}>
                                                <Button variant="danger" onClick={() => {
                                                    navigate('/DailyReport');
                                                }}>{t("detail_troubleshooting.cancel_button")}</Button>

                                            </div>
                                        </div>
                                    </Form>

                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox>
                <Modal size="xl" show={crewModal} onHide={
                    () => {
                        setCrewModal(false);
                        setStaffPhoto("");
                        setIndexStaffPhoto("");
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={`${t("detail_troubleshooting.modal_staff_photo")}`}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setCrewModal(false);
                                    setStaffPhoto("");
                                    setIndexStaffPhoto("");

                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>{t("detail_troubleshooting.close_button")}</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>



                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        width: "100%",

                                    }}>
                                        <img src={staffPhoto}
                                            style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_troubleshooting.field_foto")}</Form.Label>
                                            <Form.Control required type="file" onChange={async (e) => {

                                                let base64Img = await convertBase64Image(e.target.files[0]);
                                                const base64ImgString = base64Img.toString();
                                                setStaffPhoto(base64ImgString);

                                            }}></Form.Control>
                                        </Form.Group>

                                    </div>


                                    <div style={{ paddingBottom: 10 }}></div>






                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            paddingRight: 5,
                                        }}><Button style={{width:100}} onClick={() => {
                                            let photoTmp = listCrew;
                                            listCrew[indexStaffPhoto].image_base64 = staffPhoto;
                                            setListCrew(photoTmp.slice(0));
                                            setCrewModal(false);
                                            setIndexStaffPhoto("");
                                            setStaffPhoto("");
                                        }} variant="primary" >{t("detail_troubleshooting.save_button")}</Button></div>

                                        <div style={{
                                            paddingLeft: 5,
                                        }}>
                                            <Button style={{width:100}} variant="danger" onClick={() => {
                                                setCrewModal(false);
                                                setIndexStaffPhoto("");
                                                setStaffPhoto("");
                                            }}>{t("detail_troubleshooting.cancel_button")}</Button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }>
                    </ContainerBox>
                </Modal>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}