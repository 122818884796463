import "../../../SMSApp.css";
import "./index.css";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import NumStat from "../../../Components/NumStat/NumStat";
import logoInfo from "../../../Assets/Images/infoIcon.png";
import { useState } from "react";

export default function PortCyl1() {
    const [dinValue, setDinValue] = useState(0);
    const [correctionFactor, setCorrectionFactor] = useState(0);
    const [realTimeValue, setRealTimeValue] = useState(0);
    const [maxRealTimeValueLimiter, setMaxRealTimeValueLimiter] = useState(0);

    return (
        <div>
            <TopNavBar />
            <TitleAlarmBar text={"MAIN ENGINE > PORT CYL 1"} />
            <div className="containerPortCyl">
                <div className="contentPortCyl">
                    <div className="titleContent">ME PORT Cylinder 1 Temperature</div>
                    <div className="numStatContainer">
                        <NumStat text1={"DIN VALUE"} number={dinValue} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"CORRECTION FACTOR"} text2={"Default: 100"} number={correctionFactor} icon={true} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"REAL TIME VALUE"} number={realTimeValue} />
                    </div>
                    <div className="numStatContainer">
                        <NumStat text1={"MAX REAL TIME VALUE LIMITER"} number={maxRealTimeValueLimiter} icon={true} />
                    </div>
                </div>
                <div className="contentPortCyl">
                    <div className="titleInfo flexRow">
                        <div>
                            <img src={logoInfo} alt="" />
                        </div>
                        <div className="titleContent">Informations</div>
                    </div>
                    <div>
                        <div className="textInfo">
                            <div>DIN VALUE</div>
                            <p>This is the pure number appears in the PLC system and read by the system</p>
                        </div>
                        <div className="textInfo">
                            <div>CORRECTION FACTOR</div>
                            <p>A value to calibrate the reading and show the REAL VALUE compared to calibration tools. Use to compare the read value with the actual reading.</p>
                        </div>
                        <div className="textInfo">
                            <div>REAL TIME VALUE</div>
                            <p>This is the actual unit shown on the system.</p>
                        </div>
                        <div className="textInfo">
                            <div>MAX REAL TIME VALUE LIMITER</div>
                            <p>This value used to compare the maximum reading with the maximum set point before trigger the alarm system.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{paddingBottom:120}}></div>
            <BottomNavBar />
        </div>
    )
}