import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getSMSDeviceLocation
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";
import GoogleMaps from "../../Components/GoogleMaps";
import GoogleMapVessel from "../../Components/GoogleMapVessel";
// import quantumLogo from "../../Assets/Images/quantums.png"
// import { ArrowClockwise, Search, PencilFill, EyeFill, BarChartFill } from "react-bootstrap-icons";
// import Paginations from "../../Components/Pagination";
// import ContainerBox from "../../Components/ContainerBox";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import Loading from "../../Components/Loading";

export default function VesselPositionPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading,setLoading] = useState(true)
    const [listVesselPosition, setListVesselPosition] = useState([]);
    const navigate = useNavigate();
    // const listVesselPosition = [
    //     { id: 1, device_name: 'Kapal 1', customer_name: "Radian Peninsula Indonesia", gps_latitude: -6.127283373056311, gps_longitude: 106.85428522507787 },
    //     { id: 2, device_name: 'Kapal 2', customer_name: "Apartemen SUnter Icon", gps_latitude: -6.134620478539386, gps_longitude: 106.85428522507787 },
    //     { id: 3, device_name: 'Kapal 3', customer_name: "Kota Tua", gps_latitude: -6.194182422829561, gps_longitude: 106.8228661334137 }
    //     // Add more devices as needed
    // ];

    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                //moment.locale('id');
                loadVesselPosition();
            }
        }
        checkCookies();
    }, []);

    const loadVesselPosition = async () => {
        try {
            let response = await getSMSDeviceLocation(cookies.token,cookies.serialNumber);
            console.log(response);
            setListVesselPosition(response);
            setLoading(false);
        } catch (exception) {

        }
    }

    return (
        <>


            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"Vessel Position"} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "nowrap"
            }}>
                <div style={{
                    display: "flex",
                    flex: 1,
                    // borderStyle: "solid"
                    paddingBottom:30
                }}>
                    {/* // <img src={quantumLogo} height={70} /> */}
                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                 
                }}>
                    {/* <Button onClick={()=>{
                        let listPositioTmp = {
                            customer_name: "Dodi",
                            gps_latitude: "-6.127192454045125",
                            gps
                            // -6.127192454045125, 106.8333223188117
                        }
                    }}>Refresh</Button> */}
                    <GoogleMapVessel devices={listVesselPosition} />
                </div>


            </div>
            <Loading
                loading={loading}
              />
            <div style={{paddingBottom: 120}}></div>
            <div>
                <BottomNavBar />
            </div>
        </>
    );

}