import "../App.css";
import { Form, option, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

export default function ButtonForm({
    path,
    addData,
    button,
    viewPhoto,
    idVessel,
    name,
    saveButton,
    cancelButton,
    photoButton
}) {
    const navigate = useNavigate();

    return (

        <div style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            flexWrap: "nowrap",
            padding: 10,
            width: "100%",
            justifyContent: "center",
            fontWeight: "bold"
        }}>
            <div style={{
                display: "flex",
                paddingRight: 5,
            }}><Button onClick=
                {addData}
                className="save-button" disabled={button}>{saveButton}</Button> </div>
            <div style={{
                display: "flex",
                paddingLeft: 5,
                paddingRight: 5
            }}>
                <Button onClick={() => {
                    // navigate(`${path}`,{state:{vesselId: idVessel}})
                    window.history.back();
                }}
                    className="cancel-button" variant="danger">{cancelButton}</Button></div>
            {
                viewPhoto === undefined ?
                    <></>
                    :
                    <>
                        <div style={{
                            display: "flex",
                            paddingLeft: 5
                        }}>
                            {
                                idVessel === undefined ?
                                    <></>
                                    :
                                    <Button onClick={() => {
                                        navigate(`${viewPhoto}`, { state: { vesselId: idVessel, vesselName: name } })
                                    }} className="view-photo-button">{photoButton}</Button>
                            }

                        </div>

                    </>

            }

        </div>
    )
};