// import React, { useState, useEffect } from "react";
// import moment from "react-moment";
import "moment/locale/id";
import Clock from "react-live-clock";
import "moment/locale/id";
import "../../SMSApp.css";
import "./index.css";
import logoOceania from "../../Assets/Images/logo-oceania 1.png";
import ButtonSplashScreen from "../../Components/ButtonSplashScreen/ButtonSplashScreen";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";

export default function SplashScreenPage() {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/MainEngine")
  }

  return (
    <>
      {/* heading */}
      <div className="headingScreen">
        <div className="logoOceania">
          <img src={logoOceania} alt={"logo Oceania"} />
        </div>
        <div style={{ marginTop: 60 }}>
          <div className="dateRoom">
            <div className="textDate">
              <Clock
                className="dashboard-main-header"
                format={"DD/MM/YYYY ddd, hh:mm:ss"}
                ticking={true}
                timezone={"Asia/Jakarta"}
              />
              <p>CHIEF'S ENGINE ROOM</p>
            </div>
          </div>
        </div>
      </div>
      {/* end heading */}

      {/* content */}
      <div className="contentScreen">
        <div>MARINE CONTROL</div>
        <div>ALARM SYSTEM</div>
        <ButtonSplashScreen caption={"Tap to Continue..."}
          icon={faPlay}
          handleOnClick={handleOnClick} />
      </div>
      {/* end content */}

      {/* footer */}
      <Footer />
      {/* end footer */}
    </>
  );
}
