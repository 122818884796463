import "./BarMeter.css"

export default function BarMeter({ valueBar }) {

    return (
        <>
            <div className="meterBar">
                <div className="valueBarStyle" style={{ height: `${valueBar}%` }}>
                    <div className="textBarStyle"> {valueBar} </div>
                </div>
            </div>
        </>
    )
}