import { Line } from "react-chartjs-2";
import { Chart} from "chart.js";
import BitLamp from "../Components/NewBitLampForMotorPump/NewBitLamp";
import BitLamp2 from "./BitLamp2/BitLamp2"
import { useState, useEffect } from "react";
import NewNavLightContainer from "./NewContainerForMotorPump/NewNavLightContainer";

export default function DynamicLineChart(props) {
    const {graphTitle, dataToShow, minValue, maxValue, stepSize, wireBreak, thermalOverload, runningHour, lastValue, contact} = props;

    const xAxisValues = dataToShow ? dataToShow.map(
        data => ""):"";
        // data => new Date(data.received_date).getHours().toString()+":"
        // + new Date(data.received_date).getMinutes().toString()+":" 
        // + new Date(data.received_date).getSeconds().toString()):0;
    const yAxisValues = dataToShow ? dataToShow.map(data => Number(data.reading_value)) : 3000;

    const data = {
        labels: xAxisValues,
        datasets: [
          {
            label: graphTitle,
            data: yAxisValues,
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'green',
            borderWidth: 1,
          }
        ]
      };

      const options = {
          plugins: {
            legend: {
                display: false 
            }
        },
        scales: {
          y: {
            min: minValue,
            max: maxValue,
            ticks: {
              stepSize: stepSize,
              color: 'white'
            },
          },
          x: {
            ticks: {
              color: 'white'
            }
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
      };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '350px', height: '300px', border: '1px #64748b solid', margin: '10px 10px' }}>
      <div style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '30px'}}>
        <div><BitLamp alarm={contact} /></div>
        <div style={{ marginTop: '30px', marginLeft: '10px' }}><p style={{ color: 'white', fontFamily: 'Disket Mono' }}>{graphTitle}</p></div>
      </div>
      <div style={{ display: 'flex', width: '100%', width: '100%', height: '100%',justifyContent: 'center', padding:'10px' }}>
        <div style={{ width: '80%', height: '100%' ,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Line
            data={data}
            options={options}
          />
        </div>
        <div style={{ width: '30%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{ color: 'white', fontFamily: 'Disket Mono' }}>{lastValue}</p>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems:'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '0px 20px'}}>
          <NewNavLightContainer  text={'Running Hours  '+ runningHour} alarm={String(runningHour)} />
          <div style={{marginTop: '5px'}}></div>
          <NewNavLightContainer  text={'Thermal Overload'} alarm={thermalOverload} />
          <div style={{marginTop: '5px'}}></div>
          <NewNavLightContainer  text={'Wire Break'} alarm={wireBreak} />
          <div style={{marginTop: '5px'}}></div>
        </div>
      </div>
        
    </div>
  );
}