import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVessel, getCrewOnVessel, insertUpdateVesselReturn, getVesselReturnById,
    getRequisitionStatus, getAllHandoverDetail, getVesselInventoryHandover, getVesselInventoryHandoverById,
    getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";

import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";

export default function VesselInventoryReturnDetail() {
    const [listVessel, setListVessel] = useState([]);
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [inventoryHandoverId, setInventoryHandoverId] = useState("");
    const [staffOptions, setStaffOptions] = useState([]);
    const [listInventoryHandover, setListInventoryHandover] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [disabledButtonAdd, setDisableButtonAdd] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledForm, setDisabledForm] = useState(false);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [vesselReturn, setVesselReturn] = useState({
        id: 0,
        vessel_id: null,
        staff_id: null,
        list_return: [],
        inventory_handover_id: null,
    })
    const barcodeDiv = useRef(null);
    const [barcodeRef, setBarcodeRef] = useState(barcodeDiv);
    const [barcodeState, setBarcodeState] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVessel();
                loadStaff();
                loadAccess();
                loadStatus();
                loadInventoryHandover();
            }
        }
        checkCookies();
        if (location.state.returnId === 0) {
            setLoading(false);
        } else {
            initVesselReturn();
            setDisabledForm(true);
        }
    }, []);

  

    useEffect(() => {
        // validationForm()
        if (vesselReturn.list_return.length === 0) {
            setErrors({
                ...errors,
                listReturnError: 'Data Barang Harus Diisi'
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "listReturnError");
            setErrors(errorMsg);
            // setDisabledButton(false);
        }
        validationForm()


    }, [vesselReturn]);

   

    useEffect(() => {
        if (inventoryHandoverId) {
            initInventoryHandover();
            loadInventory();
        }
    }, [inventoryHandoverId])


    const loadInventoryHandover = async () => {
        try {
            let response = await getVesselInventoryHandover(cookies.token);
            let listNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name + ` (${response[i].staff_name})`,
                    id: response[i].id

                };

                listNew.push(obj);

            }
            setListInventoryHandover(listNew)
        } catch (exception) {
            console.log(exception);
        }
    }



    const loadVessel = async () => {
        try {
            let response = await getVessel(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listProject = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id

                };

                listProject.push(obj);

            }
            setListVessel(listProject);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadInventory = async () => {
        try {
            let response = await getAllHandoverDetail(cookies.token, inventoryHandoverId);
            let listNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].vessel_inventory_stock_id,
                    label: response[i].vessel_inventory_name + ` (${response[i].stock_internal_code})`,
                    id: response[i].vessel_inventory_stock_id,
              
                };

                listNew.push(obj);

            }
            setInventoryOptions(listNew);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getCrewOnVessel(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, true, undefined, undefined, 2);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id,
                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            // let sparepartReturnRequisitionTmp = vesselReturn;
            // sparepartReturnRequisitionTmp.ext_customer_id = sparepartReturnRequisitionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : vesselReturn.ext_customer_id
            let response = await insertUpdateVesselReturn(cookies.token, vesselReturn);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data');
                navigate('/VesselInventoryReturn');

            } else {
                alert('Gagal Menyimpan Data');
            }

            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initInventoryHandover = async () => {
        try {
            let response = await getVesselInventoryHandoverById(cookies.token, vesselReturn.inventory_handover_id);
            if (response) {
                setVesselReturn({
                    ...vesselReturn,
                    vessel_id: response.vessel_id,
                    staff_id: response.requesting_staff_id,
                })
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initVesselReturn = async () => {
        try {
            let response = await getVesselReturnById(cookies.token, location.state.returnId);
            if (response) {
                setVesselReturn({
                    ...vesselReturn,
                    id: response[0].id,
                    vessel_id: response[0].vessel_id,
                    staff_id: response[0].staff_id,
                    inventory_handover_id: response[0].inventory_handover_id,
                    list_return: response[0].list_return,
                })
                setInventoryHandoverId(response[0].inventory_handover_id)
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

   


    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (vesselReturn.list_return.length > 0) {
                    for (let index = 0; index < vesselReturn.list_return.length; index++) {
                        const list = vesselReturn.list_return[index];
                        if (list.sparepart_catalog_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage("Nama Inventaris Harus Diisi");
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");
                            setDisableButtonAdd(false);
                            setDisabledButton(false);
                        }
                    }
                } else {
                    setErrorMessage("");
                    setDisableButtonAdd(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }


    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true)
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Pengembalian Inventaris Kapal"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/VesselInventoryReturn"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Pengembalian Inventaris Kapal"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                                <FormSelect caption="Form Serah Terima Invetaris" placeholder=""
                                                                    options={listInventoryHandover} value={listInventoryHandover.filter(function (option) {
                                                                        return option.id === vesselReturn.inventory_handover_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryHandoverId(e.id)
                                                                            setVesselReturn({ ...vesselReturn, inventory_handover_id: e.id, list_return: [] });
                                                                        } else {
                                                                            setInventoryHandoverId("")
                                                                            setVesselReturn({
                                                                                ...vesselReturn,
                                                                                vessel_id: null,
                                                                                staff_id: null,
                                                                                list_return: [],
                                                                                inventory_handover_id: null,
                                                                            });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />

                                                                <FormSelect caption="Nama Kapal" placeholder=""
                                                                    options={listVessel} value={listVessel.filter(function (option) {
                                                                        return option.id === vesselReturn.vessel_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setVesselReturn({ ...vesselReturn, vessel_id: e.id });
                                                                        } else {
                                                                            setVesselReturn({ ...vesselReturn, vessel_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }


                                                                <FormSelect caption="Nama Staf" placeholder="Pilih Staf..."
                                                                    options={staffOptions.filter(p => p.resign_date === null)} value={staffOptions.filter(function (option) {
                                                                        return option.id === vesselReturn.staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setVesselReturn({ ...vesselReturn, staff_id: e.id });
                                                                        } else {
                                                                            setVesselReturn({ ...vesselReturn, staff_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }

                                                                {/* <DropDownForm value={vesselReturn.status_id} caption="Status Permintaan" placeholder="Status Permintaan"
                                                                    setValueFunction={(e) => {
                                                                        setVesselReturn({ ...vesselReturn, status_id: e.target.value });
                                                                    }} listOption={listStatusRequisition} valueKey="id" labelKey="status_name" disabledForm={true} /> */}

                                                                {/* {
                                                                errors.equipmentName && <p style={{ color: "red" }}>{errors.equipmentName}</p>
                                                            } */}




                                                            </div>
                                                          
                                                        </div>
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>
                                    {/* {
                                        location.state.returnId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >Cetak <Printer /></Button>
                                            :
                                            <></>
                                    } */}
                                    <div style={{ paddingBottom: 10 }}></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Daftar Barang"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            padding: 5
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 3
                                                            }}>Nama Inventaris</div>
                                                            {/* <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flex: 2
                                                        }}>Jumlah</div> */}
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>Catatan</div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 1
                                                            }}>Hapus</div>
                                                        </div>
                                                        {
                                                            vesselReturn.list_return && vesselReturn.list_return.map((list, index) => {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5,
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 3,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Select placeholder={""}
                                                                                options={inventoryOptions.filter((p) => {
                                                                                    return !vesselReturn.list_return.find((x) => {
                                                                                        return p.id === x.vessel_inventory_stock_id
                                                                                    }) 
                                                                                })} value={inventoryOptions.filter(function (option) {
                                                                                    return option.id === list.vessel_inventory_stock_id;
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    let listReturn = vesselReturn.list_return;
                                                                                    listReturn[index].vessel_inventory_stock_id = e.id;
                                                                                    setVesselReturn({ ...vesselReturn, list_return: listReturn });
                                                                                }}


                                                                            />
                                                                            {/* <FormSelect placeholder=""
                                                                            options={inventoryOptions} value={inventoryOptions.filter(function (option) {
                                                                                return option.id === list.sparepart_catalog_id;
                                                                            })} setValueFunction={(e) => {
                                                                                let listRequisition = sparepartRequisition.list_requisition;
                                                                                listRequisition[index].sparepart_catalog_id = e.id;
                                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                            }}
                                                                        /> */}

                                                                        </div>
                                                                        {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 2,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <Form.Control onChange={(e) => {
                                                                            let listRequisition = sparepartRequisition.list_requisition;
                                                                            listRequisition[index].quantity = formatCurrency(e.target.value);
                                                                            setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                        }} type="text" value={list.quantity} required placeholder=""></Form.Control>



                                                                    </div> */}
                                                                        
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                let listReturn = vesselReturn.list_return;
                                                                                listReturn[index].note = e.target.value;
                                                                                setVesselReturn({ ...vesselReturn, list_return: listReturn });
                                                                            }} type="text" value={list.note} placeholder=""></Form.Control>


                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Button disabled={!deletedButton} onClick={() => {
                                                                                if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                    let list = [...vesselReturn.list_return];
                                                                                    list.splice(index, 1);
                                                                                    setVesselReturn({ ...vesselReturn, list_return: list });
                                                                                }

                                                                            }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        errors.listReturnError && <p style={{ color: "red" }}>{errors.listReturnError}</p>
                                                    }
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Form.Control hidden className="barcode-input" id="barcode-input" onChangeCapture={(e) => {
                                                            // console.log("FROM React", e);
                                                        }} onChange={(e) => {
                                                            setBarcodeState(e.target.value);
                                                            // console.log("FROM React", e.target.value);

                                                        }} value={barcodeState} type="text" disabled placeholder="Barcode" />
                                                        <Button disabled={disabledButtonAdd === true ? disabledButtonAdd : !updatedButton} variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                vessel_inventory_stock_id: "",
                                                              
                                                                note: ""
                                                            };
                                                            if (!vesselReturn.list_return) {
                                                                let listReturn = [];
                                                                listReturn.push(newParameter);
                                                                setVesselReturn({ ...vesselReturn, list_return: listReturn });
                                                            } else {
                                                                let listReturn = vesselReturn.list_return;
                                                                listReturn.push(newParameter);
                                                                setVesselReturn({ ...vesselReturn, list_return: listReturn });
                                                            }

                                                        }}>Tambah Barang</Button>
                                                    </div>
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button className="save-button" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">Simpan</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button className="cancel-button" onClick={() => {
                                                        navigate("/SparepartReturnRequisition")
                                                    }} variant="danger">Batal</Button>
                                                </div>
                                            </div>
                                    }



                                </Form>



                            </div>

                       
                        </div>

                    }>
                </ContainerBox>

        
            </Container>
            <Loading
                loading={loading}
            />
        </>
    );

}