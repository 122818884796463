import "./NewBitLampSquare.css"

export default function BitLamp({ text, alarm, bitlampColor = "" , fontSize=16}) {
    return (
        

        <div className="bitLampNewSquare">
            <div className="innerBitLampContainerLeftSquare">
            {
                alarm === "true" ?
                    <div className="innerBitLampContainerLeftOnSquare"></div> : <div className="innerBitLampContainerLeftOffSquare" ></div>
            }
            </div>
            <div className="innerBitLampContainerRightSquare">
                <p className="textBitLampSquare">{text}</p>
            </div>
        </div>
    )
}