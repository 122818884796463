import "../../../SMSApp.css";
import "./index.css"
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import NumStat from "../../../Components/NumStat/NumStat";
import logoInfo from "../../../Assets/Images/infoIcon.png";
import SwitchToggle from "../../../Components/SwitchToggle/SwitchToggle";
import { useState } from "react";

export default function LOPressLastBearing() {
    const [dinValue, setDinValue] = useState(0);
    const [maxInputValue, setMaxInputValue] = useState(0);
    const [correctionFactor, setCorrectionFactor] = useState(0);
    const [resolutionValue, setResolutionValue] = useState(0);
    const [sensorRange, setSensorRange] = useState(0);
    const [minRealTimeValue, setMinRealTimeValue] = useState(0);
    const [realTimeValue, setRealTimeValue] = useState(0);
    return (
        <>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"MAIN ENGINE > LO PRESS LAST BEARING"} />
            </div>
            <div>
                <div className="containerLOPress">
                    <div className="contentLOPress">
                        <div className="titleContent">ME PORT Lube Oil Pressure Last Bearing</div>
                        <div className="switchToggleContainer">
                            <div className="textSwitchToggle">
                                ACTIVATE CHANNEL
                            </div>
                            <div className="">
                                <SwitchToggle />
                            </div>
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"DIN VALUE"} number={dinValue} />
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"MAX INPUT VALUE"} text2={"Default: 4000"} number={maxInputValue} icon={true} />
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"CORRECTION FACTOR"} number={correctionFactor} text2={"Default: 100"} icon={true} />
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"RESOLUTION VALUE"} text2={""} number={resolutionValue} icon={false} />
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"SENSOR RANGE"} text2={""} number={sensorRange} icon={false} />
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"MIN REAL TIME VALUE"} text2={""} number={minRealTimeValue} icon={false} />
                        </div>
                        <div className="numStatContainer">
                            <NumStat text1={"REAL TIME VALUE"} text2={""} number={realTimeValue} icon={false} />
                        </div>
                    </div>
                    <div className="contentLOPress">
                        <div className="titleInfo flexRow">
                            <div>
                                <img src={logoInfo} alt="" />
                            </div>
                            <div className="titleContent">Informations</div>
                        </div>
                        <div>
                            <div className="textInfo">
                                <div>DIN VALUE</div>
                                <p>This is the pure number appears in the PLC system and read by the system</p>
                            </div>
                            <div className="textInfo">
                                <div>CORRECTION FACTOR</div>
                                <p>A value to calibrate the reading and show the REAL VALUE compared to calibration tools. Use to compare the read value with the actual reading.</p>
                            </div>
                            <div className="textInfo">
                                <div>REAL TIME VALUE</div>
                                <p>This is the actual unit shown on the system.</p>
                            </div>
                            <div className="textInfo">
                                <div>MIN REAL TIME VALUE LIMITER</div>
                                <p>This value used to compare the maximum reading with the maximum set point before trigger the alarm system.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{paddingBottom:150}}></div>
            <div>
                <BottomNavBar />
            </div>
        </>
    );
}