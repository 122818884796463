// import "../../SMSApp.css";
import "./index.css";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { isTokenValid, findAllTank, getPlcValue, getTankLevelCoordinate, getCountCoordinateTankLevel, getWireBreakTankLevel, getEquipmentDetail, calculateTankSounding, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import TankLevel from "../../../Components/TankLevel/TankLevel";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import DropDownForm from "../../../Components/DropDownForm";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import moment from "moment";
import Select from 'react-select';

export default function SMSTankLevel(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [itemPerPage, setItemPerPage] = useState(9);
    const [cookies, setCookie] = useCookies(["token"]);
    const [listTankLevel, setListTankLevel] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [listWireBreak, setListWireBreak] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentid] = useState("");
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([2]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [equipmentModal, setEquipmentModal] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});
    const [selectedTankLevel, setSelectedTankLevel] = useState({
        coordinate_desc: "",
        coordinate_type: "",
        coordinate_number: ""
    });
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
          .utc()
          .subtract(30, "minutes")
          .format("yyyy-MM-DD HH:mm:ss")
      );
      const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
      );
      const dataShow = [
        {
            label : "11:00",
            value : 20
        },
        {
            label : "11:20",
            value : 10
        },
        {
            label : "11:32",
            value : 15
        },
        {
            label : "11:45",
            value : 2
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
    ]
    const durationOption = [
        {
          label: "1 Jam",
          value: 1,
        },
        {
          label: "1 Hari",
          value: 2,
        },
        {
          label: "1 Minggu",
          value: 3,
        },
      ];
    
    const [bitlampList, setBitlampList] = useState({

        AI1:
        {
            text: "FPT Ballast Tank",
            alarm: true,
            coordinate: "AI1",
            value: 0
        }, 
        AI2: {
            text: "APT Ballast Tank (P)",
            alarm: true,
            coordinate: "AI2",
            value: 0
        }, 
        AI3: {
            text: "APT Ballast Tank (S)",
            alarm: true,
            coordinate: "AI3",
            value: 0
        }, 
        AI4: {
            text: "F.O Tank No. 1 (P)",
            alarm: true,
            coordinate: "AI4",
            value: 0
        }, 
        AI5: {
            text: "F.O Tank No. 1 (C)",
            alarm: true,
            coordinate: "AI5",
            value: 0
        }, 
        AI6: {
            text: "F.O Tank No. 1 (S)",
            alarm: true,
            coordinate: "AI6",
            value: 0
        },
        AI7: {
            text: "F.O Tank No. 2 (P)",
            alarm: true,
            coordinate: "AI7",
            value: 0
        },
        AI8: {
            text: "F.O Tank No. 2 (S)",
            alarm: true,
            coordinate: "AI8",
            value: 0
        },
        AI9: {
            text: "F.O Tank No. 3 (P)",
            alarm: true,
            coordinate: "AI9",
            value: 0
        },
        AI10: {
            text: "F.O Tank No. 3 (S)",
            alarm: true,
            coordinate: "AI10",
            value: 0
        },
        AI11: {
            text: "F.O Tank No. 4 (P)",
            alarm: true,
            coordinate: "AI11",
            value: 0
        },
        AI12: {
            text: "F.O Tank No. 4 (C)",
            alarm: true,
            coordinate: "AI12",
            value: 0
        },
        AI13: {
            text: "F.O Tank No. 4 (S)",
            alarm: true,
            coordinate: "AI13",
            value: 0
        },
        AI14: {
            text: "Fuel Oil Daily Tank",
            alarm: true,
            coordinate: "AI14",
            value: 0
        },
        AI15: {
            text: "Fresh Water Tank (P)",
            alarm: true,
            coordinate: "AI15",
            value: 0
        },
        AI16: {
            text: "Fresh Water Tank (S)",
            alarm: true,
            coordinate: "AI16",
            value: 0
        },
        AI17: {
            text: "L.O.T. Lube Oil Tank",
            alarm: true,
            coordinate: "AI17",
            value: 0
        },
        AI18: {
            text: "D. O. T",
            alarm: true,
            coordinate: "AI18",
            value: 0
        },
        AI19: {
            text: "Cofferdam",
            alarm: true,
            coordinate: "AI19",
            value: 0
        },
        AI20: {
            text: "Void (AFT)",
            alarm: true,
            coordinate: "AI20",
            value: 0
        }
    });


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadTankOption();
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log(selectedTankLevel);
    }, [selectedTankLevel])

    // useEffect(() => {
    //     if (vesselEquipmentId !== "")
    //         loadEquipmentDetail();
    // }, [vesselEquipmentId])

    // useEffect(() => {
    //     // if (isSearched !== 0)
    //     loadCoordinate();
    // }, [page]);

    // useEffect(() => {
    //     if (isSearched !== 0)
    //         loadCoordinate();
    // }, [itemPerPage]);


    // useEffect(() => {

    //     if (listTankLevel.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }


    // }, [listTankLevel]);

    // const loadEquipmentDetail = async () => {
    //     try {
    //         let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
    //         console.log(response);
    //         setDetailEquipment(response);
    //         setEquipmentModal(true);
    //     } catch (exception) {

    //     }
    // }

    // const loadBitlampValue = async () => {
    //     try {

    //         let coordinate = listTankLevel.map(p => p.plc_coordinate);
    //         let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
    //         let valueTank = await calculateTankSounding(cookies.token, cookies.vesselId, page , itemPerPage);
           
    //         let updatedList = listTankLevel.map(item=>{
    //             let tank = valueTank.find(p=>item.plc_coordinate === p.plc_coordinate);
    //             if(tank){
    //                 return{...item,volume: tank.volume, tank_level: tank.tank_level}
    //             }else{
    //                 return{...item,volume: item.volume, tank_level: item.tank_level}
    //             }
    //         })
            



    //         let updatedListWireBreak = listWireBreak.map(item => {
    //             let wireBreak = response.find(p => item.plc_coordinate === p.plc_coordinate);
    //             if (wireBreak) {
    //                 return { ...item, alarm: wireBreak.value === 1 };

    //             } else {
    //                 return { ...item, alarm: item.alarm };

    //             }
    //         });
    //         if (isSearched === 0) {
    //             setListTankLevel(updatedList);
    //             setListWireBreak(updatedListWireBreak);
    //         }


    //         setLoading(false);


    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }
    const loadTankOption = async () => {
        try{
            let response = await findAllTank(cookies.token, smsSerialNumber, vesselId);
            setListTankLevel(response);
        }
        catch (exception){

        }
    }
    const loadCoordinate = async () => {
        try {
            console.log('start:', selectStartDate);
            console.log('end:', selectEndDate);
            console.log('serialnumber:', smsSerialNumber);
            console.log('vesselid:', vesselId);
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].received_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "AI" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
            // setIsSearched(1);
            // let count = await getCountCoordinateTankLevel(cookies.token);
            // let totalPage = count / itemPerPage;
            // setTotalPage(totalPage);

            // let response = await getTankLevelCoordinate(cookies.token, page, itemPerPage, cookies.vesselId);


            // let wireBreaks = await getWireBreakTankLevel(cookies.token, page, itemPerPage, cookies.vesselId);
            // setListWireBreak(wireBreaks)
            // setListTankLevel(response);
            // setIsSearched(0);
            // if(response.length < 1){
            //     setLoading(false);
            // }
        } catch (exception) {

        }
    }
    return (
        <>
            <div className="mainPageTank">
                <div className="containerBoxTank">
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'FPT Ballast Tank'} value={bitlampList['AI1'].value} wireBreak={'false'}/>
                        <TankLevel text={'APT Ballast Tank (P)'} value={bitlampList['AI2'].value} wireBreak={'false'}/>
                        <TankLevel text={'APT Ballast Tank (S)'} value={bitlampList['AI3'].value} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'F.O Tank No. 1 (P)'} value={bitlampList['AI4'].value} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No. 1 (C)'} value={bitlampList['AI5'].value} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No. 1 (S)'} value={bitlampList['AI6'].value} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'F.O Tank No. 2 (P)'} value={bitlampList['AI7'].value} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No. 2 (S)'} value={bitlampList['AI8'].value} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No. 3 (P)'} value={bitlampList['AI9'].value} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'F.O Tank No. 3 (S)'} value={bitlampList['AI10'].value} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No. 4 (P)'} value={bitlampList['AI11'].value} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No. 4 (C)'} value={bitlampList['AI12'].value} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'F.O Tank No. 4 (S)'} value={bitlampList['AI13'].value} wireBreak={'false'}/>
                        <TankLevel text={'Fuel Oil Daily Tank'} value={bitlampList['AI14'].value} wireBreak={'false'}/>
                        <TankLevel text={'Fresh Water Tank (P)'} value={bitlampList['AI15'].value} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'Fresh Water Tank (S)'} value={bitlampList['AI16'].value} wireBreak={'false'}/>
                        <TankLevel text={'L.O.T. Lube Oil Tank'} value={bitlampList['AI17'].value} wireBreak={'false'}/>
                        <TankLevel text={'D. O. T'} value={bitlampList['AI18'].value} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', margin:'10px 0px'}}>
                        <TankLevel text={'Cofferdam'} value={bitlampList['AI19'].value} wireBreak={'false'}/>
                        <TankLevel text={'Void (AFT'} value={bitlampList['AI20'].value} wireBreak={'false'}/>
                        {/* <TankLevel text={''} value={40} wireBreak={'false'}/> */}
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexDirection: "row",
                        padding:'5px'}}>
                            <Form.Group style={{width: '20%'}}>
                                <Form.Label style={{color: 'white'}}>{"Pilih Tank"}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.coordinate_desc;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.coordinate_number;
                                        }}
                                        options={listTankLevel} value={selectedTankLevel} onChange={(e) => {
                                            // console.log(e);
                                            if (e === null) {
                                                setSelectedTankLevel({ coordinate_desc: "", coordinate_number: "", coordinate_type: "" });
                                            } else {
                                                setSelectedTankLevel(e);
                                            }
                                        }}
                                        isClearable
                                        isDisabled={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" style={{margin: "10px", marginTop:'17px'}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Mulai</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    // setSelectStartDate(e.target.value);
                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                }}
                                value={
                                    selectStartDate
                                      ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                      : ""
                                }
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{margin: "10px", marginTop:'17px'}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Akhir</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    // setSelectEndDate(e.target.value);
                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                }}
                                value={
                                    selectEndDate
                                      ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                      : ""
                                }
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group style={{margin: "10px", color: 'white', marginTop:'17px'}}>
                            <DropDownForm
                                caption={"Rentang Waktu"}
                                placeholder={"Pilih Waktu"}
                                listOption={durationOption}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                console.log(e);
                                try {
                                    let currentDateTime = new Date();
                                    if (e.target.value === "1") {
                                    console.log("1", currentDateTime);
                                    let startDate = moment(currentDateTime)
                                        // .utc()
                                        .subtract(60, "minutes")
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    let endDate = moment(currentDateTime)
                                        // .utc()
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    // console.log("startdate", startDate);
                                    setSelectStartDate(startDate);
                                    setSelectEndDate(endDate);
                                    } else if (e.target.value === "2") {
                                    console.log("2");
                                    let startDate = moment(currentDateTime)
                                        // .utc()
                                        .subtract(1, "day")
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    let endDate = moment(currentDateTime)
                                        // .utc()
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    setSelectStartDate(startDate);
                                    setSelectEndDate(endDate);
                                    } else if (e.target.value === "3") {
                                    console.log("3");
                                    let startDate = moment(currentDateTime)
                                        // .utc()
                                        .subtract(1, "week")
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    let endDate = moment(currentDateTime)
                                        // .utc()
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    setSelectStartDate(startDate);
                                    setSelectEndDate(endDate);
                                    }
                                } catch (exception) {
                                    console.log(exception);
                                }
                                }}
                            />
                            </Form.Group>

                            <Button
                                variant="dark"
                                style={{ height: "40px", margin: "10px", marginTop: "37px", backgroundColor:'#050512'}}
                                onClick={() => {
                                    loadCoordinate();
                                }}
                            >
                                Tampilkan Data
                            </Button>

                            <Form.Label style={{color: 'white', marginTop:'17px'}}>
                                <input
                                style={{marginTop:"30px"}}
                                type="checkbox"
                                // checked={refreshChecked}
                                onChange={(e) => {
                                    // setRefreshChecked(e.target.checked);
                                }}
                                />
                                Auto Refresh
                            </Form.Label>
                        </div>
                        <div style={{display: 'flex', justifyContent:'space-between', padding: '10px', margin:'10px 0px'}}>
                            <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'Anchor Windlass Power Pack'} useBitLamp={'true'} valueForBitlamp={'false'} useRunningHour={'true'}/>
                        </div>
                </div>
            </div>
        </>
    );
}