import "./index.css";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import moment from "moment";

export default function SMSBilges(props) {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const { smsSerialNumber, vesselId} = props;
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([19]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [bitlampList, setBitlampList] = useState({

        M360:
        {
            text: "Engine Room 1",
            alarm: true,
            coordinate: "M360",
            value: false
        }, 
        M362: {
            text: "Engine Room 2",
            alarm: true,
            coordinate: "M362",
            value: false
        },
        M364: {
            text: "Void",
            alarm: true,
            coordinate: "M364",
            value: false
        }
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (listBitlamp.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listBitlamp]);

    // const loadBilampCoordinate = async () => {
    //     try {
    //         let response = await getPLCCoordinate(cookies.token, "watertight_door", cookies.vesselId);
    //         setListBitlamp(response);
    //         if(response.length < 1){
    //             setLoading(false);
    //         }
    //     } catch (exception) {

    //     }
    // }

    // const loadBilampCoordinate = async () => {
    //     try {
    //         let response = await getPLCCoordinate(cookies.token, "watertight_door", cookies.vesselId);
    //         setListBitlamp(response);
    //         if(response.length < 1){
    //             setLoading(false);
    //         }
    //     } catch (exception) {

    //     }
    // }

    // const loadBitlampValue = async () => {
    //     try {
    //         let coordinate = listBitlamp.map(p => p.plc_coordinate);
    //         let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
    //         let updatedList = listBitlamp.map(item => {
    //             let alarmValue = response.find(p => item.plc_coordinate === p.field);
    //             if (alarmValue) {
    //                 return { ...item, alarm: alarmValue.value === 1 };
    //             } else {
    //                 return { ...item, alarm: item.alarm };
    //             }
    //         });
    //         setListBitlamp(updatedList);

    //         setLoading(false);
    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    // useEffect( () => {
    //     if (response){
    //         loadCoordinate();
    //     }
    // }, [response]);

    useEffect(() => {
        console.log(bitlampList)
    }, [bitlampList]);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].received_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
            // for (let i=0; i<response.length; i++) {
            //     if (response[i].coordinate_number === 0){
            //         setValueWHP(response[i].reading_value);
            //         console.log('WHP', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 266){
            //         setValueERoomP(response[i].reading_value);
            //         console.log('ERoomP', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 268){
            //         setValueERoomSB(response[i].reading_value);
            //         console.log('ERoomSB', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 278){
            //         setValueEmergencyHatchERoom(response[i].reading_value);
            //         console.log('EmergencyHatchERoom', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 270){
            //         setValueCO2Room(response[i].reading_value);
            //         console.log('CO2Room', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 276){
            //         setValueEmergencyHatchCrewRoom(response[i].reading_value);
            //         console.log('EmergencyHatchCrewRoom', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 2){
            //         setValueWHSB(response[i].reading_value);
            //         console.log('WHSB', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 274){
            //         setValueSteeringGearRoomSB(response[i].reading_value);
            //         console.log('SteeringGearRoomSB', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 264){
            //         setValueDeckStore(response[i].reading_value);
            //         console.log('DeckStore', response[i].coordinate_number, response[i].reading_value);
            //     }
            //     else if (response[i].coordinate_number === 272){
            //         setValueSteeringGearRoomP(response[i].reading_value);
            //         console.log('SteeringGearRoomP', response[i].coordinate_number, response[i].reading_value);
            //     }
            // }

            // let WHSB = response[0].reading_value;
            // console.log(WHSB);
            // setValueWHSB(WHSB);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainPageWater">
                <div className="containerBoxWater">
                    <div style={{display: 'flex', justifyContent: 'flex-start', padding: '5%'}}>
                        <div style={{height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: '0px 10px'}}>
                            <BitLamp text={'Engine Room 1'} bitlampColor = "black" alarm={bitlampList["M360"].value}/>
                            <BitLamp text={'Engine Room 2'} bitlampColor = "black" alarm={bitlampList["M362"].value}/>
                            <BitLamp text={'Void'} bitlampColor = "black" alarm={bitlampList["M364"].value}/>
                        </div>
                    </div>
                    <div >
                        {
                            lastReceivedDate && <p style={{color: "white", fontSize: 20, marginLeft: '20px'}}>Last received data on {moment(lastReceivedDate).format("yyyy-MM-DD HH:mm:ss")}</p>
                        }
                    </div>
                </div>
           </div>
           <Loading loading={loading}/>
        </>
    );
}