import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, } from "../../../../Helpers/ApplicationHelpers";
import { getUserRoleXMenuById, insertSystemMenu } from "../../../../Helpers/SystemHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search, ArrowLeft } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import DeniReactTreeView from "deni-react-treeview"
const themes = ['classic', 'metro', 'moonlight', 'purple', 'green', 'orange', 'red', 'silver']

export default function MenuPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listSystemMenu, setListSystemMenu] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadSystemMenu();
                setLoading(false);
            }
        }
        checkCookies();
    }, []);


    const treeviewRef = useRef(null);

    const loadSystemMenu = async () => {
        try {

            let response = await getUserRoleXMenuById(cookies.token, cookies.languageId, location.state.roleId);
            setListSystemMenu(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-item-example">
                <Form.Check
                    type={"checkbox"}
                    id={item.id}
                    label={item.text}
                    //checked={item.selected}
                    defaultChecked={item.selected}
                    onChange={(e) => {
                        let list = listSystemMenu;
                        let selected = false;
                        if (e.target.checked) {
                            selected = true;
                        }
                        if (item.parent_id) {
                            let parentIdx = listSystemMenu.findIndex(p => p.id === item.parent_id);
                            let childIdx = listSystemMenu[parentIdx].children.findIndex(p => p.id === item.id);
                            list[parentIdx].selected = selected;
                            list[parentIdx].children[childIdx].selected = selected;
                        } else {
                            let parentIdx = listSystemMenu.findIndex(p => p.id === item.id);
                            list[parentIdx].selected = selected;
                        }
                        setListSystemMenu(list);
                        /* let listChecked = list.findIndex(p => p.id === item.id);
                        for(menu in listChecked){
                            if(menu.children){
                                let listChildrenChecked = list.find(p => p.id === item.id);
                            }
                        }
                        if (e.target.checked) {
                            list[listChecked].selected = true;
                            list[listChecked].id = item.id;
                            console.log(list);
                        } else {
                            list[listChecked].selected = false;
                            console.log(list);
                        } */
                    }}
                /*  onClick={(e) => {
                     if (e.target.checked) {
                         console.log('true');
                         setIsFieldStaffChecked(true);
                         setFormFieldStaff(false);
                         setStaff({ ...staff, is_field_staff: "true" })
                     }
                     else {
                         console.log('false');
                         setIsFieldStaffChecked(false);
                         setFormFieldStaff(true);
                         setStaff({ ...staff, is_field_staff: false })
                     } */
                />
                {/* <span className="treeview-item-example-text">{item.text}</span> */}
            </div>
        )
    }

    const saveData = async () => {
        try {
            let response = await insertSystemMenu(cookies.token, listSystemMenu, location.state.roleId);
            if (response.data === "sukses") {
                alert("Berhasil Menyimpan Data");
                navigate('/UserRoleXMenu');
            } else {
                alert("Gagal Menyimpan Data");
            }
            loadSystemMenu();
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Menu"
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/UserRoleXMenu"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div className="master-table-inner-container">

                            <DeniReactTreeView
                                style={{ marginRight: '10px', marginBottom: '10px' }}
                                showIcon={false}
                                theme="classic"
                                items={listSystemMenu}
                                ref={treeviewRef}
                                onRenderItem={onRenderItem}
                            />
                            <Loading
                                loading={loading}
                            />
                            <Button onClick={() => {
                                saveData();
                            }
                            }>Save Data</Button>
                        </div>

                    } />


            </Container>



        </>
    );

}