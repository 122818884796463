import React, { useState, useEffect } from "react";
import { Button, Form, Container, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, getLanguage, getEquipmentById, insertUpdateEquipment, getParameterEquipment } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import TextBox from "../../../../Components/Textbox";
import Loading from "../../../../Components/Loading";
import ContainerBox from "../../../../Components/ContainerBox";
import ButtonForm from "../../../../Components/ButtonForm";
import { Trash } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function EquipmentDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [listLanguage, setListLanguage] = useState([]);
    const [parameter, setParameter] = useState([])
    const [equipment, setEquipment] = useState({
        equipment_name: "",
        equipment_description: "",
        languageId: "",
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadLanguge();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
        if (location.state.staffId === "") {
            setLoading(false);

        } else {
            initEquipment();
        }
    }, []);

    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...parameter];
        list[index][name] = value;
        setParameter(list);

    }

    const handleremove = index => {
        const list = [...parameter];
        list.splice(index, 1);
        setParameter(list);
    }


    const handleaddclick = () => {
        setParameter([...parameter, { parameter_name: '', parameter_desc: '' }]);
    }

    const initEquipment = async () => {
        try {
            let response = await getEquipmentById(cookies.token, location.state.equipmentId);
            if (response) {
                setEquipment({
                    ...equipment,
                    languageId: response.language_id,
                    equipment_name: response.equipment_name,
                    equipment_description: response.equipment_description
                })
            }

            let parameter = await getParameterEquipment(cookies.token, location.state.equipmentId);
            setParameter(parameter);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const addEquipment = async () => {
        let response = await insertUpdateEquipment(cookies.token, equipment, location.state.equipmentId, cookies.languageId, parameter);
        if (response.error_code === 0) {
            alert("Berhasil menyimpan Data");
            if (location.state.equipmentId === 0) {
                navigate('/EquipmentCategory')
            }
            else {
                initEquipment();
            }
        } else {
            alert("Gagal Menyimpan Data")
        }
        setDisabledButton(false);
    }

    const loadLanguge = async () => {
        try {
            let listLanguage = await getLanguage(cookies.token);
            setListLanguage(listLanguage);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={"Detail Peralatan"} childContent={
                    <div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            padding: 5,
                            width: "100%",
                            flexWrap: "nowrap",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingRight: 5,
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    paddingBottom: 5,
                                    width: "100%",
                                    flexWrap: "nowrap",
                                }}>
                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Peralatan"} childContent={
                                        <div style={{
                                            padding: 10,
                                        }}>

                                            <TextBox value={equipment.equipment_name} placeholder="Nama Peralatan"
                                                caption="Nama Peralatan" setValueFunction={(e) => {
                                                    setEquipment({ ...equipment, equipment_name: e.target.value });
                                                }} />
                                            {/* {
                                                errors.countryName && <p style={{ color: "red" }}>{errors.countryName}</p>
                                            } */}
                                            <TextBox value={equipment.equipment_description} placeholder="Deskripsi"
                                                caption="Deskripsi" setValueFunction={(e) => {
                                                    setEquipment({ ...equipment, equipment_description: e.target.value });
                                                }} />

                                        </div>
                                    }>
                                    </ContainerBox>
                                </div>
                            </div>


                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingRight: 5,
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    paddingBottom: 5,
                                    width: "100%",
                                    flexWrap: "nowrap",
                                }}>
                                    {
                                        location.state.equipmentId !== 0 ?
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Parameter Detail"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>

                                                    {
                                                        parameter.length === 0 ?
                                                            <></>
                                                            :
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nama Parameter</th>
                                                                        <th>Deskripsi Parameter</th>
                                                                        <th>Hapus</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        parameter.map((x, i) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        parameter.length === 0 ?
                                                                                            <></>
                                                                                            :

                                                                                            <tr>
                                                                                                <td>
                                                                                                    <Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control>

                                                                                                </td>
                                                                                                <td>
                                                                                                    <Form.Control name="parameter_desc" value={x.parameter_desc} type="text" onChange={e => handleinputchange(e, i)} placeholder="Deskripsi Parameter"></Form.Control>
                                                                                                </td>
                                                                                                <td>

                                                                                                    {
                                                                                                        parameter.length >= 1 &&
                                                                                                        <Button variant="danger" onClick={() => {
                                                                                                            if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                                                handleremove(i)
                                                                                                            }
                                                                                                        }}><Trash /></Button>
                                                                                                    }

                                                                                                </td>
                                                                                            </tr>
                                                                                    }


                                                                                </>

                                                                            )


                                                                        })
                                                                    }

                                                                </tbody>
                                                            </Table>
                                                    }

                                                    {
                                                        location.state.vesselEquipmentId === 0 ?
                                                            <></>
                                                            :
                                                            <center><Button className="add-button" variant="primary" onClick={handleaddclick}>Tambah Parameter</Button></center>
                                                    }
                                                </div>

                                            }>
                                            </ContainerBox>
                                            :
                                            <></>
                                    }

                                </div>
                            </div>

                        </div>

                        <ButtonForm addData={() => {
                            setDisabledButton(true);
                            addEquipment();
                        }}
                            saveButton={`${t("button_component.save_button")}`}
                            cancelButton={`${t("button_component.cancel_button")}`}

                            path="/Equipment" button={disabledButton} />
                    </div>

                }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}