import React, { useState, useEffect } from "react";
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getCompany, getVesselByCompany, getVesselxEquipmentById, getEquipment, getParameter, insertUpdateVesselxEquipment } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import { CodeSlash, Search, Trash } from "react-bootstrap-icons";
import Select from 'react-select';
import FormSelect from "../../../../Components/FormSelect";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";

export default function CopyVesselEquipmentPage() {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [vesselSourceOptions, setVesselSourceOptions] = useState([]);
    const [vesselTargetOptions, setVesselTargetOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listCompany, setListCompany] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [vesselIdSource, setVesselIdSource] = useState("");
    const [listEquipment, setListEquipment] = useState([]);
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [companyIdSource, setCompanyIdSource] = useState("");
    const [listVesselTarget, setListVesselTarget] = useState([]);
    const [listVesselSource, setListVesselSource] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [searchItem, setSearchItem] = useState("");
    const [hiddenTable, setHiddenTable] = useState(true);
    const [titleCopy, setTitleCopy] = useState("");
    const [descriptionCopy, setDescriptionCopy] = useState("");
    const [disableTitle, setDisableTitle] = useState(false);
    const [disableDescription, setDisableDescription] = useState(false);
    // const [listParamter, setListParamter] = useState([]);
    const [parameter, setParameter] = useState([]);
    const [vesselEquipment, setVesselEquipment] = useState({
        vessel_id: "",
        equipment_id: "",
        title: "",
        description: ""
    });


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCompany();
                loadEquipment();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadVessel();
    }, [companyId]);

    useEffect(() => {
        setVesselIdSource("");
        loadVessel();
        setVesselEquipment({ ...vesselEquipment, title: "" })
    }, [companyIdSource]);

    // useEffect(() => {
    //     loadVesselXEquipment();
    // }, [vesselEquipment.vessel_id])

    useEffect(() => {
        if (vesselIdSource !== "" && vesselEquipment.equipment_id !== "") {
            loadVesselXEquipment();
        }
    }, [vesselEquipment.equipment_id]);

    useEffect(() => {

        setListVesselEquipment([])
        setTitleCopy("");
        setVesselEquipment({ ...vesselEquipment, title: "" })
    }, [vesselIdSource])

    useEffect(() => {
        loadVesselXEquipment();
    }, [searchItem])

    useEffect(() => {
        copyEquipment();
    }, [vesselEquipmentId])



    const loadCompany = async () => {
        try {
            let response = await getCompany(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListCompany(response);
            console.log(response);
            let listCompanyNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].company_name,
                    id: response[i].id

                };

                listCompanyNew.push(obj);

            }
            setCompanyOptions(listCompanyNew);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadVessel = async () => {
        try {
            let response = await getVesselByCompany(cookies.token, cookies.languageId, companyId);
            setListVesselTarget(response);
            let listVesselTargetNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id

                };

                listVesselTargetNew.push(obj);

            }
            setVesselTargetOptions(listVesselTargetNew);
            if (companyIdSource !== "") {
                let responseTarget = await getVesselByCompany(cookies.token, cookies.languageId, companyIdSource);
                setListVesselSource(responseTarget);
                let listVesselSourceNew = [];
                for (let i = 0; i < responseTarget.length; i++) {
                    var obj = {
                        value: responseTarget[i].id,
                        label: responseTarget[i].vessel_name,
                        id: responseTarget[i].id

                    };

                    listVesselSourceNew.push(obj);

                }
                setVesselSourceOptions(listVesselSourceNew);
            }


        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            setListEquipment(response);
            let listEquipmentNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].equipment_name,
                    id: response[i].id

                };

                listEquipmentNew.push(obj);

            }
            setEquipmentOptions(listEquipmentNew);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselXEquipment = async () => {
        try {
            if (vesselEquipment.equipment_id === "") {
                vesselEquipment.equipment_id = undefined
            }
            if (searchItem === "") {
                setSearchItem(undefined);
            }
            let response = await getVesselxEquipmentById(cookies.token, "", vesselIdSource, vesselEquipment.equipment_id, 0, 50, "vessel_id", "true", searchItem);
            setListVesselEquipment(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const copyEquipment = async () => {
        try {
            let response = await getParameter(cookies.token, vesselEquipmentId);
            setParameter(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...parameter];
        list[index][name] = value;
        setParameter(list);

    }

    const handleremove = index => {
        const list = [...parameter];
        list.splice(index, 1);
        setParameter(list);
    }

    const handleaddclick = () => {
        setParameter([...parameter, { parameter_name: '', parameter_value: '', parameter_desc: '' }]);
    }

    const addVesselEquipment = async () => {
        try {
            let response = await insertUpdateVesselxEquipment(cookies.token, vesselEquipment, 0, parameter, undefined, cookies.languageId);
            if (response.error_code === 0) {
                alert("Berhasil Menyimpan Data");

                navigate('/vesselEquipment/Detail', { state: { vesselEquipmentId: response.data.id } })
            }
            else {
                alert("Gagal Menyimpan Data")
            }
        } catch (error) {

        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Tambah Peralatan Kapal"} useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Peralatan Kapal"} childContent={
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    addVesselEquipment();
                                                }} style={{ padding: 10 }}>
                                                    <Col md={12}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div style={{
                                                                    borderStyle: "solid",
                                                                    borderWidth: 2,
                                                                    borderRadius: 5,
                                                                    borderColor: "#2f71bd",
                                                                }}>

                                                                    <div className="detailscontent">
                                                                        <h4>Sumber Data</h4>
                                                                    </div>
                                                                    {/* <Form.Group>
                                                                   <Form.Label>Nama Perusahaan</Form.Label>
                                                                   <Form.Select onChange={(e) => {
                                                                       setcompanyIdSource(e.target.value);
                                                                   }}>
                                                                       <option value="" selected disabled>Pilih Perusahaan...</option>
                                                                       {
                                                                           listCompany.map((company, index) => {
                                                                               return (
                                                                                   <option key={index} value={company.id}>{company.company_name}</option>
                                                                               )
                                                                           })
                                                                       }
                       
                                                                   </Form.Select>
                                                               </Form.Group> */}
                                                                    <FormSelect caption="Nama Perusahaan" autoFocusValue={companyIdSource === undefined} placeholder="Pilih Perusahaan..."
                                                                        options={companyOptions} value={companyOptions.filter(function (option) {
                                                                            return option.id === companyIdSource;
                                                                        })} setValueFunction={(e) => {
                                                                            setCompanyIdSource(e.id);
                                                                            setVesselEquipment({ ...vesselEquipment, equipment_id: "" })
                                                                        }}
                                                                    />
                                                                    {/* <Form.Group>
                                                                   <Form.Label>Nama Kapal</Form.Label>
                                                                   <Form.Select onChange={(e) => {
                                                                       setVesselIdSource(e.target.value);
                                                                       setVesselEquipment({ ...vesselEquipment, equipment_id: "" });
                                                                   }} value={vesselIdSource}>
                                                                       <option value="" selected disabled>Pilih Kapal...</option>
                                                                       {
                                                                           listVesselSource.map((vessel, index) => {
                                                                               return (
                       
                       
                                                                                   <option key={index} value={vessel.id}>{vessel.vessel_name}</option>
                       
                                                                               )
                                                                           })
                                                                       }
                                                                   </Form.Select>
                                                               </Form.Group> */}
                                                                    <FormSelect caption="Nama Kapal" autoFocusValue={vesselIdSource === undefined} placeholder="Pilih Kapal..."
                                                                        options={vesselSourceOptions} value={vesselSourceOptions.filter(function (option) {
                                                                            return option.id === vesselIdSource;
                                                                        })} setValueFunction={(e) => {
                                                                            setVesselIdSource(e.id);
                                                                            setVesselEquipment({ ...vesselEquipment, equipment_id: "" });
                                                                        }}
                                                                    />
                                                                    {/* <Form.Group>
                                                                   <Form.Label>Tipe Peralatan</Form.Label>
                                                                   <Form.Select onChange={(e) => {
                                                                       setVesselEquipment({ ...vesselEquipment, equipment_id: e.target.value });
                                                                   }} value={vesselEquipment.equipment_id}>
                                                                       <option value="" selected disabled></option>
                                                                       {
                                                                           listEquipment.map((equipment, index) => {
                                                                               return (
                                                                                   <option key={index} value={equipment.id} >{equipment.equipment_name}</option>
                                                                               )
                                                                           })
                                                                       }
                                                                   </Form.Select>
                                                               </Form.Group> */}
                                                                    <FormSelect caption="Tipe Peralatan" autoFocusValue={vesselEquipment.equipment_id === undefined} placeholder="Pilih Peralatan..."
                                                                        options={equipmentOptions} value={equipmentOptions.filter(function (option) {
                                                                            return option.id === vesselEquipment.equipment_id;
                                                                        })} setValueFunction={(e) => {
                                                                            setVesselEquipment({ ...vesselEquipment, equipment_id: e.id });
                                                                        }}
                                                                    />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div style={{

                                                                    borderStyle: "solid",
                                                                    borderWidth: 2,
                                                                    borderRadius: 5,
                                                                    borderColor: "#2f71bd",

                                                                }}>
                                                                    <div className="detailscontent">
                                                                        <h4>Target Salinan</h4>
                                                                    </div>
                                                                    {/* <Form.Group>
                                                                   <Form.Label>Nama Perusahaan</Form.Label>
                                                                   <Form.Select onChange={(e) => {
                                                                       console.log(e.target.value);
                                                                       setCompanyId(e.target.value);
                                                                   }} required>
                                                                       <option value="" selected disabled>Pilih Perusahaan...</option>
                                                                       {
                                                                           listCompany.map((company, index) => {
                                                                               return (
                                                                                   <option key={index} value={company.id}>{company.company_name}</option>
                                                                               )
                                                                           })
                                                                       }
                                                                   </Form.Select>
                                                                   
                                                               </Form.Group> */}
                                                                    <FormSelect caption="Nama Perusahaan" autoFocusValue={companyIdSource === undefined} placeholder="Pilih Perusahaan..."
                                                                        options={companyOptions} value={companyOptions.filter(function (option) {
                                                                            return option.id === companyId;
                                                                        })} setValueFunction={(e) => {
                                                                            setCompanyId(e.id);
                                                                        }}
                                                                    />
                                                                    <FormSelect caption="Nama Kapal" autoFocusValue={vesselEquipment.vessel_id === undefined} placeholder="Pilih Kapal..."
                                                                        options={vesselTargetOptions} value={vesselTargetOptions.filter(function (option) {
                                                                            return option.id === vesselEquipment.vessel_id;
                                                                        })} setValueFunction={(e) => {
                                                                            setVesselEquipment({ ...vesselEquipment, vessel_id: e.id })
                                                                        }}
                                                                    />
                                                                    {/* <Form.Group>
                                                                   <Form.Label>Nama Kapal</Form.Label>
                                                                   <Form.Select onChange={(e) => {
                                                                       setVesselEquipment({ ...vesselEquipment, vessel_id: e.target.value, equipment_id: "" })
                                                                       // setVesselEquipment({ ...vesselEquipment,  });
                                                                   }} required>
                                                                       <option value="" selected disabled>Pilih Kapal....</option>
                                                                       {
                                                                           listVesselTarget.map((vessel, index) => {
                                                                               return (
                                                                                   <option key={index} value={vessel.id}>{vessel.vessel_name}</option>
                                                                               )
                                                                           })
                                                                       }
                                                                   </Form.Select>
                                                               </Form.Group> */}
                                                                    <Form.Group>
                                                                        <Form.Label>Tipe Peralatan</Form.Label>
                                                                        <Form.Select onChange={(e) => {

                                                                        }} value={vesselEquipment.equipment_id} disabled>
                                                                            <option value="" selected disabled></option>
                                                                            {
                                                                                listEquipment.map((equipment, index) => {
                                                                                    return (
                                                                                        <option key={index} value={equipment.id}>{equipment.equipment_name}</option>
                                                                                    )

                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </Form.Group>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <br />

                                                    <div style={{
                                                        borderStyle: "solid",
                                                        borderWidth: 2,
                                                        borderRadius: 5,
                                                        borderColor: "#2f71bd",
                                                        width: "100%",
                                                    }} >
                                                        <div className="detailscontent">
                                                            <h4>Daftar Peralatan</h4>
                                                        </div>
                                                        <div style={{ padding: 10 }}>
                                                            <Form.Group>
                                                                <Form.Control onChange={(e) => {
                                                                    setSearchItem(e.target.value);
                                                                }} type="text" value={searchItem}></Form.Control>
                                                            </Form.Group><br />
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Peralatan</th>
                                                                        <th>Titel</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        listVesselEquipment.length === 0 ?
                                                                            <tr>
                                                                                <td colSpan={3} style={{ textAlign: "center" }}>Tidak ada data</td>
                                                                            </tr>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        listVesselEquipment.map((vesselxequipment, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{vesselxequipment.equipment_name}</td>
                                                                                    <td>{vesselxequipment.title}</td>
                                                                                    <td><Button onClick={() => {
                                                                                        setVesselEquipmentId(vesselxequipment.id);
                                                                                        setTitleCopy(vesselxequipment.title);
                                                                                        setDescriptionCopy(vesselxequipment.description);
                                                                                    }}>Pilih</Button></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </Table>

                                                        </div>

                                                    </div>
                                                    <br />

                                                    <Row>
                                                        <Col md={11}>
                                                            <Form.Group className="mb3">
                                                                <Form.Label>Titel</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, title: e.target.value });
                                                                }} type="text" value={vesselEquipment.title} placeholder="Titel" required disabled={disableTitle}></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={1}>
                                                            <Form.Group className="mb3">
                                                                <Form.Label>Salin Data </Form.Label>
                                                                <Form.Check onClick={(e) => {
                                                                    if (e.target.checked) {
                                                                        setVesselEquipment({ ...vesselEquipment, title: titleCopy });
                                                                        setDisableTitle(true);
                                                                    } else {
                                                                        setVesselEquipment({ ...vesselEquipment, title: "" })
                                                                        setDisableTitle(false);
                                                                    }
                                                                }} type="checkbox" ></Form.Check>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={11}>
                                                            <Form.Group className="mb3">
                                                                <Form.Label>Deskripsi</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setVesselEquipment({ ...vesselEquipment, description: e.target.value });
                                                                }} value={vesselEquipment.description} type="text" placeholder="Deskripsi" disabled={disableDescription}></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={1}>
                                                            <Form.Group className="mb3">
                                                                <Form.Label>Salin Data </Form.Label>
                                                                <Form.Check onClick={(e) => {
                                                                    if (e.target.checked) {
                                                                        setVesselEquipment({ ...vesselEquipment, description: descriptionCopy });
                                                                        setDisableDescription(true);
                                                                    } else {
                                                                        setVesselEquipment({ ...vesselEquipment, description: "" });
                                                                        setDisableDescription(false);
                                                                    }
                                                                }} type="checkbox" ></Form.Check>
                                                            </Form.Group>

                                                        </Col>
                                                    </Row>
                                                    <div style={{
                                                        paddingBottom: 20
                                                    }}></div>
                                                    {
                                                        parameter.length === 0 ?
                                                            <></>
                                                            :
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nama Parameter</th>
                                                                        <th>Nilai Parameter</th>
                                                                        <th>Deskripsi Parameter</th>
                                                                        <th>Hapus</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        parameter.map((x, i) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        parameter.length === 0 ?
                                                                                            <></>
                                                                                            :

                                                                                            <tr>
                                                                                                <td>
                                                                                                    <Form.Group >

                                                                                                        <Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control>
                                                                                                    </Form.Group>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Form.Group >

                                                                                                        <Form.Control name="parameter_value" value={x.parameter_value} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nilai Parameter"></Form.Control>
                                                                                                    </Form.Group>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Form.Group >

                                                                                                        <Form.Control name="parameter_desc" value={x.parameter_desc} type="text" onChange={e => handleinputchange(e, i)} placeholder="Deskripsi Parameter"></Form.Control>
                                                                                                    </Form.Group>
                                                                                                </td>
                                                                                                <td>

                                                                                                    {
                                                                                                        parameter.length >= 1 &&
                                                                                                        <Button variant="danger" onClick={() => {
                                                                                                            if (window.confirm('Apakah anda yakin menghapus item ini?')) {
                                                                                                                handleremove(i)
                                                                                                            }
                                                                                                        }}><Trash /></Button>
                                                                                                    }

                                                                                                </td>
                                                                                            </tr>
                                                                                    }


                                                                                </>

                                                                            )


                                                                        })
                                                                    }

                                                                </tbody>
                                                            </Table>
                                                    }

                                                    {
                                                        parameter.length === 0 ?
                                                            <></>
                                                            :
                                                            <center><Button variant="primary" onClick={handleaddclick}>Tambah Parameter</Button></center>
                                                    }


                                                    <div className="buttonForm">
                                                        <Button type="submit">Simpan</Button>
                                                        <Button onClick={() => {
                                                            navigate('/VesselEquipment');
                                                        }} variant="danger" className="cancel">Batal</Button>
                                                    </div>
                                                </Form>



                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>

                             

                            </div>

                          
                        </div>

                    }>


                </ContainerBox >

            </Container >
            <Loading
                loading={loading}
            />

        </>
    );

}