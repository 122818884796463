import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Modal, Row, Col, Tab, Tabs, } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Select from 'react-select';
import {
    isTokenValid, getStaff, getCurrency, getRackCoordinate, getWarehouse, getUserAccessById,
    getInventoryImage, getInventoryImageById, getCountInventoryImage, deleteInventoryImage,
    insertUpdateInventoryImage, convertBase64, getInventoryStockImage, getInventoryStockImageById,
    getCountInventoryStockImage, deleteInventoryStockImage,  getInventoryBrand,
} from "../../../Helpers/ApplicationHelpers";
import {
    getInventoryPeriod, getInventoryCondition, getInventoryStatus, getInventoryMasterById,
    insertUpdateInventoryStock, checkBarcodeNumber, getInventoryStockCount,
    deleteInventoryStock, getInventoryStock, getInventoryStockById, getDepreciationGroup,
    getInventoryUnitType, getStockPhotoByCount, getStockPhotoById, insertUpdateStockPhoto, deleteStockPhoto,  getInventoryGroup,
    getInventoryMaterial,
    getInventorySubGroupByGroupId, getInventoryMaster,getDivision
} from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import { ArrowLeft, FilePlusFill, PencilFill, Trash, CardChecklist, Search, ArrowClockwise, EyeFill, Download, XSquare, XSquareFill } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import Modals from "../../../Components/modal";
import Navbar from "../../../Components/NavBar";
import { Alert } from "bootstrap";
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import Loading from "../../../Components/Loading";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { triggerBase64Download } from "../../../Helpers/Base64Downloader";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function InventoryStockPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [imageRemoveId, setImageRemoveId] = useState("");
    const [stockImageRemoveId, setStockImageRemoveId] = useState("");
    const [listDepreciation, setListDepreciation] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [listInventoryImage, setListInventoryImage] = useState([]);
    const [listInventoryStockImage, setListInventoryStockImage] = useState([]);
    const [listInventoryStockTmp, setListInventoryStockTmp] = useState([]);
    const [deletedButton, setDeletedButton] = useState("");
    const [listWarehouse, setListWarehouse] = useState([]);
    const [listDivision, setListDivision] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState({id: ""});
    const [selectedCondition, setSelectedCondition] = useState({id: ""});
    const [selectedStatus, setSelectedStatus] = useState({id: ""});
    const [listUnitType, setListUnitType] = useState([]);
    const [listRackCoordinate, setListRackCoordinate] = useState([]);
    const [stockImageDownloadId, setStockImageDownloadId] = useState("");
    const [selectedDepreciation, setSelectedDepreciation] = useState({ id: "" });
    const location = useLocation();
    const [listInventoryStock, setListInventoryStock] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showStockImageModal, setShowStockImageModal] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [searchQueryInventory, setSearchQueryInventory] = useState("");
    const [listResponInventory, setListResponInventory] = useState([]);
    const [disabledButtonInventory, setDisabledButtonInventory] = useState(true);
    const [inventoryStockCount, setinventoryStockCount] = useState(0);
    const [imageIsSearched, setImageIsSearched] = useState(0);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [disabledImageButton, setDisabledImageButton] = useState(false);
    const [imagePage, setImagePage] = useState(0);
    const [imageItemPerpage, setImageItemPerPage] = useState(10);
    const [imagetotalPage, setImageTotalPage] = useState(0);
    const [imageModal, setImageModal] = useState("");
    const [imageItemPerPageSelection, setImageItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [imageResetSearch, setImageResetSearch] = useState(0);
    const [imageSearchQuery, setImageSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [listStockPhoto, setListStockPhoto] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [resetSearch, setResetSearch] = useState(0);
    const [stockPhotoCount, setStockPhotoCount] = useState();
    const [descending, setDescending] = useState(true);
    const [showStockModal, setShowStockModal] = useState(false);
    const [listCurrency, setListCurrency] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [listInventoryStatus, setlistInventoryStatus] = useState([]);
    const [inventoryStockId, setInventoryStockId] = useState("");
    const [inventoryPhotos, setInventoryPhotos] = useState("");
    const [inventoryPhotosId, setInventoryPhotosId] = useState("");
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [listInventoryPeriod, setlistInventoryPeriod] = useState([]);
    const [listStaff, setListStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedRackCoordinate, setSelectedRackCoordinate] = useState({ id: "" });
    const [selectedWarehouse, setSelectedWarehouse] = useState({ id: "" });
    const [downloadId, setDownloadId] = useState("");
    const [imageId, setImageId] = useState("");
    const [imageStockId, setImageStockId] = useState("");
    const [currentIndex, setCurrentIndex] = useState(null);
    const [clickedImg, setClickedImg] = useState(null);
    const [fileImg, setFileImg] = useState("");
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState([]);
    const [inventoryMasterIdToSave, setInventoryMasterIdToSave] = useState("");
    const [inventoryStockIdForPhoto,setInventoryStockIdForPhoto] = useState();
    const [listInventoryGroup, setListInventoryGroup] = useState([]);
    const [listInventorySubGroup, setListInventorySubGroup] = useState([]);
    const [listInventoryMaterial, setListInventoryMaterial] = useState([]);
    const [listInventoryBrand, setListInventoryBrand] = useState([]);
    const [findInventoryModal, setFindInventoryModal] = useState(false);
    const [stockPhoto, setStockPhoto] = useState({
        id: 0,
        base64_image: "",
        label: "",
        is_active: true,
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        inventory_stock_id: "",
    })
    const [selectedInventoryMaster, setSelectedInventoryMaster] = useState({
        id: 0,
        inventory_master_name: "",
        inventory_group_id: "",
        inventory_subgroup_id: "",
        inventory_material_id: "",
        inventory_brand_id: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        inventory_brand_name: "",
        inventory_group_code: "",
        inventory_subgroup_code: "",
        inventory_material_code: "",
        inventory_brand_code: "",
        part_number: "",
        parameters: []
    });
    const [selectedGroup, setSelectedGroup] = useState({
        id: "",
        inventory_group_name: "",
        inventory_group_code: "",
    });
    const [selectedSubGroup, setSelectedSubGroup] = useState({
        id: "",
        inventory_subgroup_name: "",
        inventory_subgroup_code: "",
    });
    const [selectedMaterial, setSelectedMaterial] = useState({
        id: "",
        inventory_material_name: "",
        inventory_material_code: "",
    });
    const [selectedBrand, setSelectedBrand] = useState({
        id: "",
        inventory_brand_name: "",
        inventory_brand_code: "",
    });

    const [inventoryMaster, setInventoryMaster] = useState({
        id: 0,
        inventory_group_id: "",
        inventory_subgroup_id: "",
        inventory_material_id: "",
        inventory_brand_id: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        inventory_brand_name: "",
        inventory_group_code: "",
        inventory_subgroup_code: "",
        inventory_material_code: "",
        inventory_brand_code: "",
    });

    const [orderBy, setOrderBy] = useState({
        label: `${t("inventory_stock.order_name1")}`,
        value: "created_date"
    });

    const orderBySelection = [
        {
            label: `${t("index_inventory_photo.order_name1")}`,
            value: "created_date"
        }
    ]

    const [newDocument, setNewDocument] = useState({
        id: 0,
        inventory_id: "",
        base64_image: "",
        label: "",
        taken_date: null,
        done: false,
    });

    const [stockImage, setStockImage] = useState({
        id: 0,
        inventory_stock_id: null,
        base64_image: "",
        label: "",
        taken_date: null,
        done: false,
    });

    const [stockImageToBeViewed, setStockImageToBeViewed] = useState({
        id: 0,
        inventory_stock_id: null,
        base64_image: "",
        label: "",
        taken_date: null
    })

    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        inventory_id: location.state.inventoryMasterId,
        base64_image: "",
        label: "",
        taken_date: null
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            inventory_id: location.state.inventoryMasterId,
            base64_image: "",
            label: "",
            done: false
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    const resetStockImage = async () => {
        let newDocument = {
            id: 0,
            inventory_stock_id: null,
            base64_image: "",
            label: "",
            done: false
        };
        setStockImage({ ...stockImage, newDocument });
    }

    const orderByList = [
        {
            label: `${t("inventory_stock.order_name1")}`,
            value: "inventory_code"
        },
        {
            label: `${t("inventory_stock.order_name2")}`,
            value: "purchase_date"
        }
        // {
        //     label: "Tanggal Serah Terima",
        //     value: "received_date"
        // },
        // {
        //     label: "Status Barang",
        //     value: "inventory_status_name"
        // },
        // {
        //     label: "Kondisi Barang",
        //     value: "inventory_condition_name"
        // },
        // {
        //     label: "Penanggung Jawab",
        //     value: "staff_name"
        // },


    ]

    // const [inventoryMaster, setInventoryMaster] = useState({
    //     id: 0,
    //     inventory_master_name: "",
    //     division_id: "",
    //     unit: "",
    //     brand: "",
    //     model: "",
    //     quantity: "",
    //     inventory_master_description: "",
    //     inventory_master_note: "",
    //     inventory_type_id: "",
    //     type_name: "",
    //     division_name: "",
    //     division_code: "",
    //     created_by: "",
    //     lead_time: "",
    //     buffer_stock: ""
    // });


    const [inventoryStock, setInventoryStock] = useState({
        id: 0,
        inventory_master_id: "",
        inventory_code: "",
        purchase_date: "",
        received_date: null,
        inventory_period_id: "",
        period_number: "",
        purchase_price: "",
        currency_id: "",
        currency_value_idr: "",
        staff_id: null,
        inventory_status_id: "",
        removed_date: null,
        inventory_condition_id: "",
        depreciation_rate: null,
        inventory_detail_description: "",
        inventory_detail_note: "",
        created_date: "",
        updated_date: "",
        created_by: "",
        updated_by: "",
        is_active: true,
        is_handed_over: false,
        last_checked_date: false,
        barcode_number: "",
        calibration_date: null,
        rack_coordinate_id: null,
        depreciation_group_id: null,
        warehouse_id: null,
        estimation_return_date: null,
        is_consumable: false,
        is_production_material: location.state.isProductionMaterial ? location.state.isProductionMaterial : false,
        quantity: 0,
        division_id: "",
        division_name: "",
        division_code: "",
        currency_name: "",
        inventory_status_name: "",
        inventory_period_name: "",
        inventory_condition_name: "",
        inventory_master_name: "",
        is_consumeable: false,
        part_number: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        brand_name: "",
        // estimation_return_date: null
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryImage();
                loadStaff();
                loadCurrency();
                loadInventoryPeriod();
                loadInventoryStatus();
                loadInventoryCondition();
                loadDepreciationGroup();
                loadDivision();
                loadAccess();
                loadGroup();
                loadSubGroup();
                loadMaterial();
                loadInventoryBrand();
                loadWarehouse();
                // loadInventoryStock();
                findInventoryMasterById();
                initInventoryStock();
                // loadStockPhotos();
            }
        }
        checkCookies();
        console.log(location.state.isProductionMaterial);
    }, []);

    useEffect(() => {
        loadInventoryImage();
    }, [imagePage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryImage();
    }, [imageItemPerpage]);

    useEffect(() => {
        if (imageId !== "")
            loadImageById()
    }, [imageId]);

    useEffect(() => {
        if (imageStockId !== "")
            loadImageStockById();
    }, [imageStockId])

    useEffect(() => {
        if (downloadId !== "")
            downloadData();
    }, [downloadId])

    useEffect(() => {
        if (stockImageDownloadId !== "")
            downloadStockData();
    }, [stockImageDownloadId])

    useEffect(() => {
        if (imageSearchQuery === "")
            loadInventoryImage();
    }, [imageSearchQuery]);

    useEffect(() => {
        if (imageResetSearch !== 0) {
            loadInventoryImage()
        }
    }, [imageResetSearch]);

    // useEffect(() => {
    //     console.log(inventoryStock);
    // }, [inventoryStock]);

    useEffect(() => {
        if (!showImageModal) {
            setDocumentToBeViewed({
                id: 0,
                inventory_id: location.state.inventoryStockId,
                base64_image: "",
                label: "",

            });
            setImageId("")
        }
    }, [showImageModal]);

    useEffect(() => {
        if (!showStockImageModal) {
            setStockImageToBeViewed({
                id: 0,
                inventory_stock_id: null,
                base64_image: "",
                label: "",
                taken_date: null

            });
            setImageStockId("")
        }
    }, [showStockImageModal]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowImageModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (stockImageToBeViewed.id !== 0)
            setShowStockImageModal(true);
    }, [stockImageToBeViewed]);

    // useEffect(() => {
    //     if (imageRemoveId !== "") {
    //         removeInventoryImage();
    //     }
    // }, [imageRemoveId])

    useEffect(() => {
        if (stockImageRemoveId !== "") {
            removeInventoryStockImage();
        }
    }, [stockImageRemoveId])

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadImage();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        if (selectedRackCoordinate.warehouse_id) {
            setInventoryStock({ ...inventoryStock, rack_coordinate_id: selectedRackCoordinate.id });
        } else {
            setInventoryStock({ ...inventoryStock, rack_coordinate_id: null });
        }
    }, [selectedRackCoordinate]);

    useEffect(() => {
        if (selectedWarehouse.warehouse_name) {
            setInventoryStock({ ...inventoryStock, warehouse_id: selectedWarehouse.id });
            loadRackCoordinate();
        } else {
            setInventoryStock({ ...inventoryStock, warehouse_id: null, rack_coordinate_id: null });
            loadRackCoordinate();
            setSelectedRackCoordinate({});
        }
    }, [selectedWarehouse]);

    useEffect(() => {
        if (selectedDepreciation.group_name) {

            setInventoryStock({ ...inventoryStock, depreciation_group_id: selectedDepreciation.id, depreciation_rate: (Number(100) / Number(selectedDepreciation.number_of_mounth)).toFixed(2) });
        } else {
            setInventoryStock({ ...inventoryStock, depreciation_group_id: null, depreciation_rate: null });
        }
    }, [selectedDepreciation]);


    useEffect(() => {
        
        if (inventoryStock.rack_coordinate_id) {
            let rackCoordinate = listRackCoordinate.find(p => p.id === inventoryStock.rack_coordinate_id);
            if (rackCoordinate) {
                setSelectedRackCoordinate(rackCoordinate);
            }

        }
        if (inventoryStock.warehouse_id) {
            let warehouse = listWarehouse.find(p => p.id === inventoryStock.warehouse_id);
            if (warehouse) {
                setSelectedWarehouse(warehouse);
            }

        }
        if (inventoryStock.depreciation_group_id) {
            let response = listDepreciation.find(p => p.id === inventoryStock.depreciation_group_id);
            if (response) {
                setSelectedDepreciation(response);
            }

        }

        if (inventoryStock.inventory_condition_id) {
            let condition = listInventoryCondition.find(p => p.id === inventoryStock.inventory_condition_id);
            if (condition) {
                setSelectedCondition(condition);
            }

        }

        if (inventoryStock.inventory_status_id) {
            let invStatus = listInventoryStatus.find(p => p.id === inventoryStock.inventory_status_id);
            if (invStatus) {
                setSelectedStatus(invStatus);
            }

        }

        console.log(listDivision);
        console.log(inventoryStock);
        if (inventoryStock.division_id) {
            let div = listDivision.find(p => p.id === inventoryStock.division_id);
            console.log(div);
            if (div) {
                setSelectedDivision(div);
            }

        }
        
        
        console.log("USE EFFECT", inventoryStock);
        setInventoryStockIdForPhoto(inventoryStock.id);
        
        if (inventoryStock.id) {
            loadStockPhotos();
        }
    }, [inventoryStock])

    useEffect(() => {
        console.log(selectedDivision);
        if (selectedDivision) {
            setInventoryStock({ ...inventoryStock, division_id: selectedDivision.id, division_code: selectedDivision.code });
        }
    }, [selectedDivision]);

    useEffect(() => {
        console.log(selectedCondition);
        if (selectedCondition) {
            setInventoryStock({ ...inventoryStock, inventory_condition_id: selectedCondition.id, inventory_condition_name: selectedCondition.inventory_condition_name });
        }
    }, [selectedCondition]);

    useEffect(() => {
        console.log(selectedStatus);
        if (selectedStatus) {
            setInventoryStock({ ...inventoryStock, inventory_status_id: selectedStatus.id, inventory_status_name: selectedStatus.inventory_status_name });
        }
    }, [selectedStatus]);

    useEffect(() => {
        console.log(selectedGroup);
        if (selectedGroup) {
            setInventoryMaster({ ...inventoryMaster, inventory_group_id: selectedGroup.id, inventory_group_name: selectedGroup.inventory_group_name, inventory_group_code: selectedGroup.inventory_group_code })
            loadSubGroup();
        }
    }, [selectedGroup]);

    useEffect(() => {
        if (selectedSubGroup) {
            setInventoryMaster({ ...inventoryMaster, inventory_subgroup_id: selectedSubGroup.id, inventory_subgroup_name: selectedSubGroup.inventory_subgroup_name, inventory_subgroup_code: selectedSubGroup.inventory_subgroup_code })
        }
    }, [selectedSubGroup]);

    useEffect(() => {
        if (selectedMaterial) {
            setInventoryMaster({ ...inventoryMaster, inventory_material_id: selectedMaterial.id, inventory_material_name: selectedMaterial.inventory_material_name, inventory_material_code: selectedMaterial.inventory_material_code })
        }
    }, [selectedMaterial]);

    useEffect(() => {
        if (selectedBrand) {
            setInventoryMaster({ ...inventoryMaster, inventory_brand_id: selectedBrand.id, inventory_brand_name: selectedBrand.brand_name, inventory_brand_code: selectedBrand.brand_code })
        }
    }, [selectedBrand]);

    useEffect(() => {
        // console.log(listInventorySubGroup);
        if (inventoryMaster.inventory_subgroup_id !== "") {
            let selSubGroup = listInventorySubGroup.find(p => p.id === inventoryMaster.inventory_subgroup_id);
            setSelectedSubGroup(selSubGroup);
        }
    }, [listInventorySubGroup]);

    const loadGroup = async () => {
        try {
            let listGroup = await getInventoryGroup(cookies.token, cookies.languageId, 0, 1000000);
            setListInventoryGroup(listGroup);
            // console.log(listInventoryGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadSubGroup = async () => {
        try {
            let listSubGroup = await getInventorySubGroupByGroupId(cookies.token, cookies.languageId, cookies.extCustomerId, selectedGroup.id, 0, 1000000);
            setListInventorySubGroup(listSubGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadMaterial = async () => {
        try {
            let listMaterial = await getInventoryMaterial(cookies.token, 0, 1000000);
            setListInventoryMaterial(listMaterial);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryBrand = async () => {

        try {
            let response = await getInventoryBrand(cookies.token);
            setListInventoryBrand(response);
            console.log(listInventoryBrand);
        } catch (exception) {

        }
    }

    const initInventoryStock = async (data) => {
        setLoading(true);
        console.log(data);
        try {
            console.log(location.state.inventoryStockId);
            let response = await getInventoryStockById(cookies.token, location.state.inventoryStockId ? location.state.inventoryStockId : data, cookies.languageId);
            console.log(response);
            if (response) {
                setInventoryStock({
                    id: response.id,
                    inventory_master_id: response.inventory_master_id,
                    inventory_code: response.inventory_code,
                    purchase_date: response.purchase_date,
                    received_date: response.received_date,
                    inventory_period_id: response.inventory_period_id,
                    period_number: response.period_number,
                    purchase_price: response.purchase_price,
                    currency_id: response.currency_id,
                    currency_value_idr: response.currency_value_idr,
                    staff_id: response.staff_id,
                    inventory_status_id: response.inventory_status_id,
                    removed_date: response.removed_date,
                    inventory_condition_id: response.inventory_condition_id,
                    depreciation_rate: response.depreciation_rate,
                    inventory_detail_description: response.inventory_detail_description,
                    inventory_detail_note: response.inventory_detail_note,
                    created_date: response.created_date,
                    updated_date: response.updated_date,
                    created_by: response.created_by,
                    updated_by: response.updated_by,
                    is_active: response.is_active,
                    is_handed_over: response.is_handed_over,
                    last_checked_date: response.last_checked_date,
                    barcode_number: response.barcode_number,
                    calibration_date: response.calibration_date,
                    rack_coordinate_id: response.rack_coordinate_id,
                    depreciation_group_id: response.depreciation_group_id,
                    warehouse_id: response.warehouse_id,
                    estimation_return_date: response.estimation_return_date,
                    is_consumable: response.is_consumable,
                    is_production_material: response.is_production_material,
                    quantity: response.quantity,
                    division_id: response.division_id,
                    division_name: response.division_name,
                    division_code: response.division_code,
                    currency_name: response.currency_name,
                    inventory_status_name: response.inventory_status_name,
                    inventory_period_name: response.inventory_period_name,
                    inventory_condition_name: response.inventory_condition_name,
                    inventory_master_name: response.inventory_master_name,
                    is_consumeable: response.is_consumeable,
                    part_number: response.part_number,
                    inventory_group_name: response.inventory_group_name,
                    inventory_subgroup_name: response.inventory_subgroup_name,
                    inventory_material_name: response.inventory_material_name,
                    brand_name: response.brand_name,

                    // id: response.id,
                    // inventory_master_id: response.inventory_master_id,
                    // inventory_code: response.inventory_code,
                    // purchase_date: response.purchase_date,
                    // received_date: response.received_date,
                    // inventory_period_id: response.inventory_period_id,
                    // period_number: response.period_number,
                    // purchase_price: response.purchase_price,
                    // currency_id: response.currency_id,
                    // currency_value_idr: response.currency_value_idr,
                    // staff_id: response.staff_id,
                    // inventory_status_id: response.inventory_status_id,
                    // removed_date: response.removed_date,
                    // inventory_condition_id: response.inventory_condition_id,
                    // depreciation_rate: response.depreciation_rate,
                    // inventory_detail_description: response.inventory_detail_description,
                    // inventory_detail_note: response.inventory_detail_note,
                    // isIDR: response.currency_value_idr == 1 ? true : false,
                    // last_checked_date: response.last_checked_date,
                    // barcode_number: response.barcode_number,
                    // rack_coordinate_id: response.rack_coordinate_id,
                    // depreciation_group_id: response.depreciation_group_id,
                    // warehouse_id: response.warehouse_id,
                    // calibration_date: response.calibration_date,
                    // estimation_return_date: response.estimation_return_date

                });
                // if(response.currency_value_idr==1) setInventoryStock({ ...inventoryStock, isIDR: true })
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const generateCode = async () => {
        try {
            let randomNumber = Math.floor(Math.random() * 100000000);
            let checkCode = await checkBarcodeNumber(cookies.token, randomNumber);
            if (checkCode != 0) {
                return (generateCode());
            } else {
                return (randomNumber);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const clearInventoryStockModal = async () => {
        inventoryStock.id = 0;
        inventoryStock.inventory_code = "";
        inventoryStock.purchase_date = "";
        inventoryStock.received_date = "";
        inventoryStock.inventory_period_id = "";
        inventoryStock.period_number = "";
        inventoryStock.purchase_price = "";
        inventoryStock.currency_id = "";
        inventoryStock.currency_value_idr = "";
        inventoryStock.staff_id = "";
        inventoryStock.inventory_status_id = "";
        inventoryStock.removed_date = "";
        inventoryStock.inventory_condition_id = "";
        inventoryStock.depreciation_rate = null;
        inventoryStock.inventory_detail_description = "";
        inventoryStock.inventory_detail_note = "";
        inventoryStock.isIDR = false;
        inventoryStock.depreciation_group_id = null;
        inventoryStock.calibration_date = null;
        // inventoryStock.estimation_return_date = null
        setSelectedDepreciation({});
        setSelectedRackCoordinate({});
        setSelectedWarehouse({})


    }

    const loadStockPhotos = async () => {
        // setLoading(true);
        // console.log(inventoryStockIdForPhoto);
        let count = await getStockPhotoByCount(cookies.token, inventoryStock.id);
        console.log(count);
        setStockPhotoCount(count);
        let totalPage = stockPhotoCount / itemPerPage;
        setTotalPage(totalPage);

        let response = await getStockPhotoById(cookies.token, inventoryStock.id, page, itemPerPage, orderBy.value, descending);
        console.log(response);
        setListStockPhoto(response);
        // if (response) {
        //     setInventoryPhoto({
        //         id: response[0].id,
        //         base64_image: response.base64_image,
        //         label: response.label,
        //         is_active: response.is_active,
        //         created_date: response.created_date,
        //         created_by: response.created_by,
        //         updated_date: response.updated_date,
        //         updated_by: response.updated_by,
        //         inventory_id: response.inventory_id,
        //         inventory_master_name: response.inventory_master_name
        //     });
        // }
        
        setIsSearched(0);
        // setLoading(false);
        // setDisabledButton(false);
    }

    const findInventory = async () => {
        let invTmp = inventoryMaster;
        invTmp.inventory_group_id = selectedGroup.id;
        invTmp.inventory_subgroup_id = selectedSubGroup.id;
        invTmp.inventory_material_id = selectedMaterial.id;
        invTmp.inventory_brand_id = selectedBrand.id;
        invTmp.ext_customer_id = "fab7b2b6-cb86-4b74-ba8e-036361f24115"
        console.log(invTmp);

        let response = await getInventoryMaster(cookies.token, cookies.languageId, cookies.extCustomerId, null, 0, 1000, "inventory.model", false, searchQueryInventory, false, invTmp);
        console.log(response);
        setListResponInventory(response);

        // let detailInventory = await getInventoryMasterById(cookies.token, response, cookies.languageId);

    }

    // useEffect(() => {
    //     console.log(inventoryStock);
    // }, [inventoryStock])

    const saveInventoryStock = async () => {
        try {
            //setDisabledButton(true);
            let invTmp = inventoryStock;
            if (inventoryStock.id === 0 || inventoryStock.id === undefined) {
                let barcodeNumber = await generateCode();
                let today = new Date();
                let yyyy = today.getFullYear();
                let invCode = "QPD/" + selectedDivision.division_code + "/EQP/" + yyyy + "/" + barcodeNumber;
                invTmp.inventory_code = invCode;
                invTmp.barcode_number = barcodeNumber;
            }

            let staff_id, currency_value_idr, removed_date, received_date, purchase_price, period_number;
            if (inventoryStock.staff_id == "") staff_id = undefined;
            if (inventoryStock.removed_date == "") removed_date = null;
            if (inventoryStock.received_date == "") received_date = null;
            // if (inventoryStock.currency_value_idr.toString().match(/\d{1,3}/gi)) currency_value_idr = inventoryStock.currency_value_idr.toString().replace(/[^,\d]/g, '');
            if (inventoryStock.purchase_price){
                if (inventoryStock.purchase_price.toString().match(/\d{1,3}/gi)) purchase_price = inventoryStock.purchase_price.toString().replace(/[^,\d]/g, '');
            }
            if (inventoryStock.isIDR == true) currency_value_idr = 1;
            if (inventoryStock.period_number.toString().match(/\d{1,3}/gi)) period_number = inventoryStock.period_number.toString().replace(/[^,\d]/g, '');

            invTmp.is_production_material = location.state.isProductionMaterial === true ? location.state.isProductionMaterial : false
            invTmp.inventory_master_id = inventoryStock.inventory_master_id;
            invTmp.purchase_price = purchase_price;
            invTmp.staff_id = staff_id;
            invTmp.removed_date = removed_date;
            invTmp.received_date = received_date;
            invTmp.currency_value_idr = currency_value_idr;
            invTmp.period_number = period_number;
            invTmp.inventory_condition_id = selectedCondition.id;
            invTmp.inventory_status_id = selectedStatus.id;

            console.log(invTmp);

            let response = await insertUpdateInventoryStock(cookies.token, invTmp);
            if (response.error_code === 0) {
                alert(`${t("inventory_stock.alert_success_save_data")}`);
                console.log( response.data)
                setInventoryStock({ ...inventoryStock, id: response.data.id})
                setShowStockModal(false);
                findInventoryMasterById();
                loadInventoryStock();
            }
            setDisabledButton(false)

        } catch (exception) {
            console.log(exception);
            // setDisabledButton(true);

        }

    }


    const loadCurrency = async () => {
        try {
            let listCurrency = await getCurrency(cookies.token, cookies.languageId);
            setListCurrency(listCurrency);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setlistInventoryPeriod(listInventoryPeriod);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId);
            // console.log(listDivision);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setlistInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }


    const loadStaff = async () => {
        try {
            let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, 0, 100, "employee_number", true);

            // setLoading(true);
            setListStaff(listStaff);
            let listNewStaff = [];
            var opt = {
                value: "",
                label: "Pilih Penanggung Jawab...",
                id: ""

            };
            listNewStaff.push(opt);
            for (let i = 0; i < listStaff.length; i++) {
                var obj = {
                    value: listStaff[i].employee_number,
                    label: listStaff[i].staff_name + " (" + listStaff[i].employee_number + ") ",
                    id: listStaff[i].staff_id

                };

                listNewStaff.push(obj);

            }
            setOptions(listNewStaff);


        } catch (exception) {
            console.log(exception);
        }


    }


    const findInventoryById = async (inventoryMasterId) => {
        // let invTmp = inventoryMaster;
        // invTmp.ext_customer_id = "fab7b2b6-cb86-4b74-ba8e-036361f24115"
        console.log(inventoryMasterId);

        let response = await getInventoryMasterById(cookies.token, inventoryMasterId, cookies.languageId);
        console.log(response);
        setInventoryStock({ 
            inventory_master_id: response.id,
            inventory_master_name: response.inventory_master_name,
            inventory_group_id:response.inventory_group_id,
            inventory_subgroup_id:response.inventory_subgroup_id,
            inventory_material_id:response.inventory_material_id,
            inventory_brand_id:response.inventory_brand_id,
            inventory_group_name:response.inventory_group_name,
            inventory_subgroup_name:response.inventory_subgroup_name,
            inventory_material_name:response.inventory_material_name,
            brand_name:response.brand_name,
            inventory_group_code:response.inventory_group_code,
            inventory_subgroup_code:response.inventory_subgroup_code,
            inventory_material_code:response.inventory_material_code,
            brand_code:response.brand_code,
            part_number:(response.part_number === "" || response.part_number === null) ? "" : response.part_number,
            parameters: response.parameters
        });

    }

    const loadInventoryStock = async () => {
        try {
        

            let response = await getInventoryStock(cookies.token, cookies.languageId, 0, 1, orderBy.value, descending, searchQuery, false, location.state.inventoryStockId);
            console.log("RESPONSE", response);
            // setListInventoryStock(response);
            setInventoryStock({
                    id: response[0].id,
                    inventory_master_id: response[0].inventory_master_id,
                    inventory_code: response[0].inventory_code,
                    purchase_date: response[0].purchase_date,
                    received_date: response[0].received_date,
                    inventory_period_id: response[0].inventory_period_id,
                    period_number: response[0].period_number,
                    purchase_price: response[0].purchase_price,
                    currency_id: response[0].currency_id,
                    currency_value_idr: response[0].currency_value_idr,
                    staff_id: response[0].staff_id,
                    inventory_status_id: response[0].inventory_status_id,
                    removed_date: response[0].removed_date,
                    inventory_condition_id: response[0].inventory_condition_id,
                    depreciation_rate: response[0].depreciation_rate,
                    inventory_detail_description: response[0].inventory_detail_description,
                    inventory_detail_note: response[0].inventory_detail_note,
                    created_date: response[0].created_date,
                    updated_date: response[0].updated_date,
                    created_by: response[0].created_by,
                    updated_by: response[0].updated_by,
                    is_active: response[0].is_active,
                    is_handed_over: response[0].is_handed_over,
                    last_checked_date: response[0].last_checked_date,
                    barcode_number: response[0].barcode_number,
                    calibration_date: response[0].calibration_date,
                    rack_coordinate_id: response[0].rack_coordinate_id,
                    depreciation_group_id: response[0].depreciation_group_id,
                    warehouse_id: response[0].warehouse_id,
                    estimation_return_date: response[0].estimation_return_date,
                    is_consumable: response[0].is_consumable,
                    is_production_material: response[0].is_production_material,
                    quantity: response[0].quantity,
                    division_id: response[0].division_id,
                    division_name: response[0].division_name,
                    division_code: response[0].division_code,
                    currency_name: response[0].currency_name,
                    inventory_status_name: response[0].inventory_status_name,
                    inventory_period_name: response[0].inventory_period_name,
                    inventory_condition_name: response[0].inventory_condition_name,
                    inventory_master_name: response[0].inventory_master_name,
                    is_consumeable: response[0].is_consumeable,
                    part_number: response[0].part_number,
                    inventory_group_name: response[0].inventory_group_name,
                    inventory_subgroup_name: response[0].inventory_subgroup_name,
                    inventory_material_name: response[0].inventory_material_name,
                    brand_name: response[0].brand_name,
            })
            setIsSearched(0)
            setLoading(false);
        } catch (exception) {

        }
    }

    const deleteInventory = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("inventory_stock.delete_confirmation")}`) == true) {
                let result = await deleteInventoryStock(cookies.token, id);
                if (result.success) {
                    if (result.error_code === 0) {
                        alert(`${t("inventory_stock.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("inventory_stock.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("inventory_stock.delete_failed_alert")}`);
                }
                loadInventoryStock();
                findInventoryMasterById();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const findInventoryMasterById = async () => {
        try {
            let response = await getInventoryMasterById(cookies.token, location.state.inventoryMasterId, cookies.languageId);
            console.log(response);
            if (response) {
            
                setInventoryMaster({
                    id: response.id,
                    inventory_master_name: response.inventory_master_name,
                    division_id: response.division_id,
                    unit: response.unit_name,
                    brand: response.brand,
                    model: response.model,
                    quantity: response.quantity,
                    inventory_master_description: response.inventory_master_description,
                    inventory_master_note: response.inventory_master_note,
                    inventory_type_id: response.inventory_type_id,
                    type_name: response.inventory_type_name,
                    division_name: response.division_name,
                    division_code: response.division_code,
                    created_by: response.created_by,
                    is_consumeable: response.is_consumeable,
                    lead_time: response.lead_time,
                    buffer_stock: response.buffer_stock

                });
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const printPageArea = () => {
        //console.log('print');  

        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.print();
        // WinPrint.close();
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadRackCoordinate = async () => {
        try {
            let response = await getRackCoordinate(cookies.token, cookies.extCustomerId);
            let listCoordinate = response.filter(p => p.warehouse_id === selectedWarehouse.id)
            setListRackCoordinate(listCoordinate);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDepreciationGroup = async () => {
        try {
            let response = await getDepreciationGroup(cookies.token);
            setListDepreciation(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadWarehouse = async () => {
        try {
            let response = await getWarehouse(cookies.token, cookies.extCustomerId);
            setListWarehouse(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const printBarcode1 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 1,
                    inv_number: inventoryStock.inventory_code,
                    inv_name: inventoryMaster.inventory_master_name,
                    inv_type: inventoryMaster.type_name,
                    purchase_date: inventoryStock.purchase_date,
                    cal_date: inventoryStock.calibration_date,
                    barcode: inventoryStock.barcode_number
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const printBarcode2 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 2,
                    barcode: inventoryStock.barcode_number
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const printBarcode3 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 3,
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/InventoryMaster");
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("inventory_stock.order_name1")}`,
                value: "inventory_code"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInventoryImage = async () => {
        try {
            let count = await getCountInventoryImage(cookies.token, location.state.inventoryStockId, searchQuery, false);
            let totalPage = count / itemPerPage;
            setImageTotalPage(totalPage);

            let response = await getInventoryImage(cookies.token, location.state.inventoryStockId, imagePage, imageItemPerpage, undefined, undefined, searchQuery, false);

            setListInventoryImage(response);
            setLoading(false);
            setImageIsSearched(0);
            setImageResetSearch(0)
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInvetoryStockImage = async () => {
        try {
            let count = await getCountInventoryStockImage(cookies.token, inventoryStockId, searchQuery, false);
            let totalPage = count / 50;
            setImageTotalPage(totalPage);

            let response = await getInventoryStockImage(cookies.token, inventoryStockId, 0, 50, undefined, undefined, searchQuery, false);

            setListInventoryStockImage(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    const downloadData = async () => {
        try {
            let response = await getInventoryImageById(cookies.token, downloadId);
            triggerBase64Download(response.base64_image, response.label);
            setLoading(false);
            setDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadStockData = async () => {
        try {
            let response = await getInventoryStockImageById(cookies.token, stockImageDownloadId);
            triggerBase64Download(response.base64_image, response.label);
            setLoading(false);
            setStockImageDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }


    const loadImageById = async () => {
        try {
            let response = await getInventoryImageById(cookies.token, imageId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadImageStockById = async () => {
        try {
            let response = await getInventoryStockImageById(cookies.token, imageStockId);
            setStockImageToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const uploadImage = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateInventoryImage(cookies.token, newDocument);

                setImageModal(false);
                resetUploadForm();
                loadInventoryImage();
                setDisabledImageButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }


    // const removeInventoryImage = async () => {
    //     try {
    //         console.log(imageRemoveId);
    //         let response = await deleteInventoryImage(cookies.token, imageRemoveId);
    //         console.log(response);
    //         if (response === 0) {
    //             alert(`${t("inventory_stock.delete_success_alert")}`);
    //         } else {
    //             alert(`${t("inventory_stock.delete_failed_alert")}`);
    //         }

    //         loadInventoryImage();
    //         setImageRemoveId("");
    //     } catch (exception) {

    //     }
    // }

    const removeInventoryStockImage = async () => {
        try {
            console.log(stockImageRemoveId);
            let response = await deleteStockPhoto(cookies.token, stockImageRemoveId);
            console.log(response);
            if (response === 0) {
                alert(`${t("inventory_stock.delete_success_alert")}`);
            } else {
                alert(`${t("inventory_stock.delete_failed_alert")}`);
            }
            setStockImageRemoveId("");
            loadStockPhotos();
            
        } catch (exception) {

        }
    }

    const handleClick = (item, index) =>{
        setCurrentIndex(index);
        setClickedImg(item.base64_image);
    }

    const addPhoto = async () => {
        try {
            // setDisabledButton(true);
            let inventoryPhotoTmp = stockPhoto;
            inventoryPhotoTmp.label = stockPhoto.label;
            inventoryPhotoTmp.base64_image = stockPhoto.base64_image;
            inventoryPhotoTmp.created_by = cookies.userId;
            inventoryPhotoTmp.updated_by = cookies.userId;
            inventoryPhotoTmp.inventory_stock_id = inventoryStock.id;
            console.log(inventoryPhotoTmp);

            let response = await insertUpdateStockPhoto(cookies.token, inventoryPhotoTmp);
            if (response === 0) {
                alert(`${t("index_inventory_photo.alert_success_save_data")}`);
                handleClose();
                loadStockPhotos();
            }
            else {
                alert(`${t("index_inventory_photo.alert_failed_save_data")}`);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleResets = async () => {

        setStockPhoto({
            ... stockPhoto,
            id: 0,
            base64_image: "",
            label: "",
            is_active: true,
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            inventory_id: "",
            inventory_master_name: ""
        });

        setFileImg("");

    }

    const handelRotationRight = () => {
        const totalLength = listStockPhoto.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listStockPhoto[0].base64_image;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listStockPhoto.filter((item) => {
            return listStockPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        console.log('right clicked');
    };

    const handelRotationLeft = () => {
        const totalLength = listStockPhoto.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listStockPhoto[totalLength - 1].base64_image;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listStockPhoto.filter((item) => {
            return listStockPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        console.log('left clicked');
    };


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("inventory_stock.header_title")} ${location.state.inventoryMasterName}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/InventoryAssets"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            padding: 5,
                            width: "100%",
                            flexWrap: "nowrap",
                            paddingTop: 10

                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexWrap: "nowrap",
                                flexDirection: "column",
                                borderWidth: 2,
                                borderColor: "rgba(3, 30, 103, 1)",
                                borderRadius: 10,
                                borderStyle: "solid",

                            }}>
                                  <Tabs
                                defaultActiveKey="stockInformation"
                                // transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                style={{
                                    backgroundColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 5,

                                }}
                            >
                                <Tab eventKey="stockInformation" title={`Informasi Stok`}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        padding: 10
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 10,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            
                                            {inventoryStock.id !== 0 &&
                                                <div style={{
                                                    flex: 1,
                                                    alignItems: "center"
                                                }}>
                                                    <div style={{
                                                        flex: 1,
                                                        padding: 20,
                                                    }}>
                                                        <div style={{ width: "100%", border: '2px solid black', borderRadius: '5px'}}>
                                                        <div style={{ display: "flex", justifyContent: 'space-around', width: "100%"}}>
                                                            <div style={{ display: "flex", flexDirection: 'column', flexWrap: "nowrap", width: "100%", padding: '10px' }}>
                                                                <Form.Group style={{
                                                                    width: "100%",
                                                                    paddingLeft: 5,
                                                                    flex: 1,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.inventory_master_name} placeholder=""
                                                                        caption={"Inventory Item Name"}
                                                                        // setValueFunction={(e) => {
                                                                        //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                        // }}
                                                                        req={true} />
                                                            </Form.Group>
                                                            <Form.Group style={{
                                                                    width: "100%",
                                                                    paddingLeft: 5,
                                                                    flex: 1,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.inventory_group_name} placeholder=""
                                                                        caption={"Group"}
                                                                        // setValueFunction={(e) => {
                                                                        //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                        // }}
                                                                        req={true} />
                                                            </Form.Group>
                                                            <Form.Group style={{
                                                                    width: "100%",
                                                                    paddingLeft: 5,
                                                                    flex: 1,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.inventory_subgroup_name} placeholder=""
                                                                        caption={"Sub Group"}
                                                                        // setValueFunction={(e) => {
                                                                        //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                        // }}
                                                                        req={true} />
                                                            </Form.Group>
                                                            
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: 'column', flexWrap: "nowrap", width: "100%", padding: '10px'}}>
                                                            <Form.Group style={{
                                                                    width: "100%",
                                                                    paddingLeft: 5,
                                                                    flex: 1,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.inventory_material_name} placeholder=""
                                                                        caption={"Material"}
                                                                        // setValueFunction={(e) => {
                                                                        //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                        // }}
                                                                        req={true} />
                                                            </Form.Group>
                                                            <Form.Group style={{
                                                                    width: "100%",
                                                                    paddingLeft: 5,
                                                                    flex: 1,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.brand_name} placeholder=""
                                                                        caption={"Brand"}
                                                                        // setValueFunction={(e) => {
                                                                        //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                        // }}
                                                                        req={true} />
                                                            </Form.Group>
                                                            <Form.Group style={{
                                                                    width: "100%",
                                                                    paddingLeft: 5,
                                                                    flex: 1,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.part_number} placeholder=""
                                                                        caption={"Part Number"}
                                                                        // setValueFunction={(e) => {
                                                                        //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                        // }}
                                                                        req={true} />
                                                            </Form.Group>
                                                            
                                                            
                                                            </div>
                                                            
                                                        </div>
                                                        <div style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    padding: '10px'}}>
                                                                <Button 
                                                                    disabled={inventoryStock.id ? true : false} 
                                                                    // disabled={!updatedButton} 
                                                                    variant="primary" style={{
                                                                        flex: 1,
                                                                    }} onClick={() => {
                                                                        setFindInventoryModal(true);
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            justifyContent: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                padding: '5px',
                                                                            }}><Search size={20} /></div>
                                                                            <div style={{
                                                                                padding: '5px',
                                                                                fontWeight: "bold",
                                                                                fontSize: 20,
                                                                            }}>{"Find Inventory"}</div>
                                                                        </div>
                                                                    </Button>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        {/* <div style={{
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            fontWeight: "bold",
                                                            fontSize: 20,
                                                            marginTop: '50px'
                                                        }}>
                                                            {`${t("inventory_stock.order_name1")}: ${inventoryStock.inventory_code}`}
                                                        </div> */}
                                                        <Form.Group style={{
                                                                    marginTop: '50px',
                                                                    width: "50%",
                                                                    paddingLeft: 5,
                                                                }} className="mb-3">
                                                                    <TextBox disabledForm={true} value={inventoryStock.inventory_code} placeholder=""
                                                                        caption={t("inventory_stock.order_name1")} />
                                                        </Form.Group>
                                                    </div>
                                                    <div style={{
                                                        padding: 20,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flex: 1,
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        alignItems: "flex-start",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                        }}>
                                                            <h6>Barcode</h6>
                                                            <Barcode
                                                                height={160}
                                                                value={inventoryStock.id == undefined || inventoryStock.id == "" ? 0 : inventoryStock.barcode_number}
                                                                displayValue={true}
                                                            />
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,

                                                        }}>
                                                            <h6>QR Code</h6>
                                                            <QRCode
                                                                size={180}
                                                                style={{ padding: 10 }}
                                                                value={inventoryStock.id == undefined || inventoryStock.id == "" ? 0 : inventoryStock.id}
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                saveInventoryStock();
                                                setDisabledButton(true);


                                            }} style={{ padding: 10 }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            paddingRight: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_purchase_date")}</Form.Label>
                                                                    <Form.Control onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, purchase_date: e.target.value })
                                                                    }} type="date" value={inventoryStock.purchase_date} required placeholder="Masukan Tanggal Pembelian"></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_calibration_date")}</Form.Label>
                                                                    <Form.Control onChange={(e) => {
                                                                        if (e.target.value === "") {
                                                                            setInventoryStock({ ...inventoryStock, calibration_date: null })
                                                                        } else {
                                                                            setInventoryStock({ ...inventoryStock, calibration_date: e.target.value })
                                                                        }

                                                                    }} type="date" value={inventoryStock.calibration_date ? moment(inventoryStock.calibration_date).format("yyyy-MM-DD") : ""} placeholder=""></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_checking_item")}</Form.Label>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={4}>
                                                                                <Form.Control
                                                                                    onKeyUp={(e) => {

                                                                                        setInventoryStock({ ...inventoryStock, period_number: formatCurrency(e.target.value) })
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setInventoryStock({ ...inventoryStock, period_number: e.target.value })
                                                                                    }} type="text" value={inventoryStock.period_number} required placeholder=""></Form.Control>
                                                                            </Col>
                                                                            <Col md={4}>
                                                                                <Form.Select required onChange={(e) => {
                                                                                    setInventoryStock({ ...inventoryStock, inventory_period_id: e.target.value })
                                                                                }} value={inventoryStock.inventory_period_id} >
                                                                                    <option selected value="">

                                                                                    </option>
                                                                                    {listInventoryPeriod.map((period, index) => (
                                                                                        <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_purchase_price")}</Form.Label>
                                                                    <Form.Control
                                                                        onKeyUp={(e) => {

                                                                            setInventoryStock({ ...inventoryStock, purchase_price: formatCurrency(e.target.value) })
                                                                        }}

                                                                        onChange={(e) => {
                                                                            setInventoryStock({ ...inventoryStock, purchase_price: e.target.value })
                                                                        }} type="text" value={inventoryStock.purchase_price}  placeholder=""></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_currency")}</Form.Label>
                                                                    <Form.Select  onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, currency_id: e.target.value });
                                                                        inventoryStock.currency_id = e.target.value;
                                                                        listCurrency.map(function (cur) {
                                                                            if (cur.id === inventoryStock.currency_id) {
                                                                                if (cur.currency_code == "IDR") setInventoryStock({ ...inventoryStock, isIDR: true })
                                                                                else setInventoryStock({ ...inventoryStock, isIDR: false })

                                                                            }
                                                                        })
                                                                    }} value={inventoryStock.currency_id} >

                                                                        <option selected value="">

                                                                        </option>
                                                                        {listCurrency.map((currency, index) => (
                                                                            <option key={index} value={currency.id}>{currency.currency_name} (
                                                                                {currency.currency_code}
                                                                                )</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_exchange_rate")}</Form.Label>
                                                                    <Form.Control disabled={inventoryStock.isIDR}
                                                                        onKeyUp={(e) => {

                                                                            setInventoryStock({ ...inventoryStock, currency_value_idr: formatCurrency(e.target.value, 'Rp. ') })
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setInventoryStock({ ...inventoryStock, currency_value_idr: e.target.value })
                                                                        }} type="text" value={inventoryStock.isIDR ? 1 : inventoryStock.currency_value_idr}  placeholder=""></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_depreciation_group")}</Form.Label>
                                                                    <Select placeholder={""}
                                                                        getOptionLabel={(item) => {
                                                                            return item.group_name;
                                                                        }} clearValue={true}
                                                                        getOptionValue={(item) => {
                                                                            return item.id;
                                                                        }}
                                                                        options={listDepreciation} value={inventoryStock.depreciation_group_id ? selectedDepreciation : null} onChange={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedDepreciation({})
                                                                            } else {
                                                                                setSelectedDepreciation(e);
                                                                            }
                                                                        }}
                                                                        isClearable
                                                                        
                                                                    />
                                                                </Form.Group>
                                                            </div>


                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_depreciation_presentation")}</Form.Label>
                                                                    <Form.Control
                                                                        onKeyUp={(e) => {

                                                                            setInventoryStock({ ...inventoryStock, depreciation_rate: formatCurrency(e.target.value) })
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setInventoryStock({ ...inventoryStock, depreciation_rate: e.target.value })
                                                                        }} type="text" value={inventoryStock.depreciation_rate ? inventoryStock.depreciation_rate : ""} placeholder="" disabled="true"></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            paddingLeft: 10,
                                                        }}>
                                                            {/* <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                padding: 5,
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tanggal Pengembalian Barang</Form.Label>
                                                    <Form.Control onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            setInventoryStock({ ...inventoryStock, estimation_return_date: null })
                                                        } else {
                                                            setInventoryStock({ ...inventoryStock, estimation_return_date: e.target.value })
                                                        }

                                                    }} type="date" value={inventoryStock.estimation_return_date ? moment(inventoryStock.estimation_return_date).format("yyyy-MM-DD") : ""} placeholder=""></Form.Control>
                                                </Form.Group>
                                            </div> */}
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label> {t("inventory_stock.field_item_status")}</Form.Label>
                                                                    <Form.Select required onChange={(e) => {
                                                                        let selectedStatus = listInventoryStatus.find(p=>p.id === e.target.value);
                                                                        setSelectedStatus(selectedStatus); 
                                                                        setInventoryStock({ ...inventoryStock, inventory_status_id: e.target.value })
                                                                    }} value={inventoryStock.inventory_status_id} >
                                                                        <option selected value="">

                                                                        </option>
                                                                        {listInventoryStatus.map((status, index) => (
                                                                            <option key={index} value={status.id}>{status.inventory_status_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_item_condition")}</Form.Label>
                                                                    <Form.Select required onChange={(e) => {
                                                                        let selectedCondition = listInventoryCondition.find(p=>p.id === e.target.value);
                                                                        setSelectedCondition(selectedCondition);
                                                                        setInventoryStock({ ...inventoryStock, inventory_condition_id: e.target.value })
                                                                    }} value={inventoryStock.inventory_condition_id} >
                                                                        <option selected value="">

                                                                        </option>
                                                                        {listInventoryCondition.map((condition, index) => (
                                                                            <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Label>{t("inventory_stock.field_warehouse")}</Form.Label>
                                                                <Select
                                                                    getOptionLabel={(item) => {
                                                                        return item.warehouse_name;
                                                                    }} clearValue={true}
                                                                    getOptionValue={(item) => {
                                                                        return item.id;
                                                                    }}
                                                                    options={listWarehouse} value={selectedWarehouse} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedWarehouse({})
                                                                        } else {
                                                                            setSelectedWarehouse(e);
                                                                        }
                                                                    }}
                                                                    isClearable

                                                                />
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Label>{t("inventory_stock.field_item_position")}</Form.Label>
                                                                <Select
                                                                    getOptionLabel={(item) => {
                                                                        if (item.id) {
                                                                            return item.warehouse_code + '/' + (item.rack_code) + '/' + (item.coordinate_name);
                                                                        } else {
                                                                            return ("");
                                                                        }
                                                                    }} clearValue={true}
                                                                    getOptionValue={(item) => {
                                                                        return item.id;
                                                                    }}
                                                                    options={listRackCoordinate} value={selectedRackCoordinate} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedRackCoordinate({})
                                                                        } else {
                                                                            setSelectedRackCoordinate(e);
                                                                        }
                                                                    }}
                                                                    isClearable

                                                                />
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_division")}</Form.Label>
                                                                    <Form.Select required onChange={(e) => {
                                                                        let selectedDivision = listDivision.find(p=>p.id === e.target.value);
                                                                        setSelectedDivision(selectedDivision);
                                                                        setInventoryStock({ ...inventoryStock, division_id: e.target.value })
                                                                    }} value={selectedDivision ? selectedDivision.id : ""} >
                                                                        <option selected value="">

                                                                        </option>
                                                                        {listDivision.map((div, index) => (
                                                                            <option key={index} value={div.id}>{div.division_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_description")}</Form.Label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={inventoryStock.inventory_detail_description}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            setInventoryStock({ ...inventoryStock, inventory_detail_description: e.target.value })
                                                                        }}
                                                                        rows="4"
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                padding: 5,
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_note")}</Form.Label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={inventoryStock.inventory_detail_note}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            setInventoryStock({ ...inventoryStock, inventory_detail_note: e.target.value })
                                                                        }}
                                                                        rows="4"
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                            <></>
                                                            :
                                                            <div style={{
                                                                display: "flex",
                                                                minWidth: "100%",
                                                                flex: 1,
                                                                padding: 10,
                                                            }}>
                                                                <Button style={{
                                                                    width: "100%"
                                                                }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("inventory_stock.save_button")}</Button>
                                                            </div>
                                                    }

                                                    {
                                                        inventoryStock.id == undefined || inventoryStock.id == "" ?

                                                            <></> :
                                                            <div style={{
                                                                display: "flex",
                                                                minWidth: "100%",
                                                                flex: 1,
                                                                padding: 10,
                                                                flexDirection: "row"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                }}><Button onClick={() => {
                                                                    printBarcode1()
                                                                }} >{t("inventory_stock.button_print_big_barcode")}</Button></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                }}><Button onClick={() => {
                                                                    printBarcode2()
                                                                }} >{t("inventory_stock.button_print_small_barcode")}</Button></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                }}><Button onClick={() => {
                                                                    printBarcode3()
                                                                }} >{t("inventory_stock.button_reset_printer")}</Button></div>


                                                            </div>

                                                    }
                                                    <div style={{
                                                        display: "flex",
                                                        minWidth: "100%",
                                                        flex: 1,
                                                        padding: 10,
                                                    }}>
                                                        <Button style={{
                                                            width: "100%"
                                                        }} variant="danger" onClick={() => {
                                                            setShowStockModal(false);
                                                            // navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });
                                                        }}>{t("inventory_stock.cancel_button")}</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        </div>
                                </Tab>
                                <Tab eventKey="stockImageTab" title={`Gambar`}>
                                <div>
                                    <Button disabled={false} variant="secondary" style={{
                                        marginLeft: 5, marginRight: 5
                                    }} onClick={() => {
                                        setFileImg("");
                                        handleShow();
                                        setDisabledButton(true);
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><FilePlusFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("index_inventory_photo.button_add_photo")}</div>
                                        </div>
                                    </Button>
                                    <div style={{padding: 10}}>
                                    <Col md={12}>
                                        <Row>
                                            {
                                                listStockPhoto.map((inventory, index)=> {
                                                    return(
                                                        <Col md={3} key={index}>
                                                <div key={index} style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    flexWrap: "nowrap",
                                                    flexDirection: "column",
                                                    padding: 10,
                                                    borderWidth: 1,
                                                    margin: 5,
                                                    borderStyle: "solid",
                                                    borderColor: "#2f71bd",
                                                    borderRadius: 5,
                                                    height: 400,

                                                }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 5,
                                                    alignContent: "flex-start",
                                                    alignItems: "flex-start",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    flexWrap: "wrap",

                                                }}>
                                                    <img src={inventory.base64_image} onClick={() => {
                                                        handleClick(inventory, index);
                                                    }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                </div>
                                                <div style={{
                                                    flex: 1,
                                                    alignContent: "flex-end",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    alignSelf: "flex-end",
                                                    flexWrap: "nowrap",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                }} >
                                                <div style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    alignContent: "flex-end",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    alignSelf: "flex-end",
                                                    textAlign: "center"
                                                }}><p>{!inventory.label ? "" : inventory.label}</p></div>
                                                <div style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    alignContent: "flex-end",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    alignSelf: "flex-end",
                                                    textAlign: "center"
                                                }}><p>{!inventory.created_date ? "" : moment(inventory.created_date).format("DD-MM-yyyy")}</p>
                                                <div style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    alignContent: "flex-end",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    alignSelf: "flex-end",
                                                }}>
                                                    <Button disabled={false} onClick={() => {
                                                        if (window.confirm(`${t("index_inventory_photo.delete_confirmation")}`)) {
                                                            // console.log(inventory.id);
                                                            let inventoryIdTmp = inventory.id
                                                            setStockImageRemoveId(inventoryIdTmp);
                                                        }
                                                    }} variant="danger"><Trash /></Button>
                                                </div>
                                            </div>

                                            </div>

                                            </div>
                                            </Col>
                                            )
                                            })
                                            }
                                        </Row>
                                    </Col>

                                    <Paginations
                                        itemPerPage={itemPerPage}
                                        totalPage={totalPage}
                                        page={page}
                                        setPage={setPage}
                                        setItemPerPage={setItemPerPage}
                                        itemPerPageSelection={itemPerPageSelection}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                        orderBySelection={orderBySelection}
                                        setIsSearched={setIsSearched}
                                    />

                                    <Loading loading={loading}/>
                                </div>
                                </div>
                                </Tab>
                            </Tabs>

                            </div>
                          
                        
                        
                        
                        </div>
                    }>
                </ContainerBox>

                {/* <div id="printableArea" style={{ display: 'none' }}>
                    <div style={{
                        display: "flex",
                        flexDirection: 1,
                        flexWrap: "nowrap",
                        flexDirection: "row"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: 1,
                            flexWrap: "nowrap",
                            flexDirection: "column"
                        }}>

                            <Barcode
                                height={160}
                                value={inventoryStock.id == undefined || inventoryStock.id == "" ? 0 : inventoryStock.inventory_code.slice(-8)}
                                displayValue={true}
                            />
                        </div>
                        <div style={{ paddingRight: 100 }}></div>
                        <div style={{
                            display: "flex",
                            flexDirection: 1,
                            flexWrap: "nowrap",
                            flexDirection: "column"
                        }}>

                            <QRCode
                                size={180}

                                value={inventoryStock.id == undefined || inventoryStock.id == "" ? 0 : inventoryStock.id}
                                viewBox={`0 0 256 256`}
                            />
                        </div>

                    </div>
                </div> */}
                

                {/* <Modal show={imageModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        resetUploadForm();
                        setImageModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("sparepart_catalog_document.modal_add_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            setDisabledImageButton(true);
                            setNewDocument({ ...newDocument, done: true });
                        }} style={{ padding: 10 }}>
                            <img src={newDocument.base64_image} width={"20%"} style={{ alignContent: "center" }}></img>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("inventory_stock.field_image")}</Form.Label>
                                <Form.Control onChange={async (e) => {
                                    if (e.target.files[0].type.substring(0, e.target.files[0].type.lastIndexOf("/")) === "image") {
                                        let base64Doc = await convertBase64(e.target.files[0]);
                                        setNewDocument({ ...newDocument, base64_image: base64Doc.toString() });
                                        setUploadFileImageError("");
                                        setDisabledImageButton(false)

                                    } else {
                                        setNewDocument({ ...newDocument, base64_image: "" });
                                        setUploadFileImageError(`${t("inventory_stock.error_image_validation")}`)
                                        setDisabledImageButton(true)
                                    }

                                }} type="file" required></Form.Control>
                                {
                                    uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                }
                            </Form.Group>



                            <Form.Group className="mb-3">
                                <Form.Label>{t("inventory_stock.field_label")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, label: e.target.value })
                                }} value={newDocument.label} type="text" placeholder="" required></Form.Control>

                            </Form.Group>



                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                            }}>
                                <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disabledImageButton}>
                                    {t("inventory_stock.save_button")}
                                </Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    resetUploadForm();
                                    loadInventoryImage();
                                    setImageModal(false);
                                }}>
                                    {t("inventory_stock.cancel_button")}
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal> */}

                {/* <Modal className="modal-xxl" show={showImageModal} onHide={() => {
                    setShowImageModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.label}
                        useActionContainer={true}
                        actionContainerChild={
                            <div id="button-color">
                                <Button variant="primary" style={{

                                }} onClick={() => {
                                    setShowImageModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquare size={32} /></div>

                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                <center> <img src={documentToBeViewed.base64_image} style={{ width: "60%" }}></img></center>

                            </div>
                        } />



                </Modal> */}



                
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>{t("index_inventory_photo.modal_add_photo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        // handleResets();
                        addPhoto();
                    }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_inventory_photo")}</Form.Label>
                            <Form.Control onChange={async (e) => {
                                if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                    setFileImg(e.target.value)
                                    let base64Img = await convertBase64(e.target.files[0]);
                                    const base64ImgString = base64Img.toString();
                                    setStockPhoto({ ...stockPhoto, base64_image: base64ImgString});
                                    setUploadFileImageError("");
                                    setDisabledButton(false)

                                } else {
                                    setFileImg("")
                                    // setInventoryPhoto({ ...inventoryPhoto, base64_image: ""});
                                    setUploadFileImageError(`${t("index_inventory_photo.error_image_validation")}`);
                                    setDisabledButton(true)
                                }



                            }} type="file" value={fileImg} required></Form.Control>
                            {
                                uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_photo_label")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setStockPhoto({ ...stockPhoto, label: e.target.value })

                            }} value={stockPhoto.label} type="text" placeholder="" required></Form.Control>
                        {
                            errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                        }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_taken_date")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setStockPhoto({ ...stockPhoto, created_date: e.target.value })
                            }} value={stockPhoto.created_date} type="date" placeholder="" required></Form.Control>
                        {
                            errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                        }
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                // setVesselPhoto({ ...vesselPhoto, description: e.target.value })
                            }} value={'desc'} as="textarea" rows={3} placeholder=""></Form.Control>
                        </Form.Group> */}
                        <Button style={{width:100}} variant="primary" disabled={disabledButton} type="submit">
                        {t("index_inventory_photo.save_button")}
                        </Button>
                        <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                            // handleResets();
                            handleClose();
                        }}>
                            {t("index_inventory_photo.cancel_button")}
                        </Button>
                    </Form>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={findInventoryModal}

                    size={"xl"}
                    onHide={() => {
                        handleResets();
                        setFindInventoryModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{"Find Inventory List"} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Find Inventory")
                            findInventory();


                        }} style={{ padding: 10 }}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_group")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_group_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryGroup} value={selectedGroup} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedGroup({ id: "" });
                                        } else {

                                            setSelectedGroup(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_subgroup")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_subgroup_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventorySubGroup} value={selectedSubGroup} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedSubGroup({ id: "" });
                                        } else {
                                            setSelectedSubGroup(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_material")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_material_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryMaterial} value={selectedMaterial} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedMaterial({ id: "" });
                                        } else {
                                            setSelectedMaterial(e);
                                            setInventoryMaster({ ...inventoryMaster, inventory_material_id: e.id, inventory_material_name: e.inventory_material_name });
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_brand")}</Form.Label>

                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.brand_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryBrand} value={selectedBrand} onChange={(e) => {
                                        if (e === null) {
                                            console.log(e);
                                            setSelectedBrand({})
                                        } else {
                                            setSelectedBrand(e);
                                        }

                                    }}
                                    isClearable
                                    required
                                />

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalogue.search_button_by_name")}</Form.Label>
                                <Form.Control style={{height: '45px'}} value={searchQueryInventory} type="text"
                                onChange={(e) => {
                                    setSearchQueryInventory(e.target.value);
                                }} />
                            </Form.Group>
                            
                            <div className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <Button style={{ width: 100 }} className="save-button" type="submit" disabled={false}>{t("sparepart_catalogue.search_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel cancel-button" variant="danger" onClick={() => {
                                    handleResets();
                                    setFindInventoryModal(false);
                                }}>{t("sparepart_catalogue.cancel_button")}</Button>
                            </div>
                        </Form>
                        <div className="master-table-inner-container">
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 3 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_item_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_group_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_subgroup_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_material_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_brand_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_category")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_select")}</div>
                                </div>  
                                {listResponInventory.map((data, index) => {
                                    return (
                                        <div key={index} className="table-body">
                                            {/* <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div> */}
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{data.inventory_master_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.inventory_group_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.inventory_subgroup_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.inventory_material_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.brand_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.is_consumeable === true ? "Consumable" : data.is_production_material === true ?  "Production Material" : "Assets"}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"  onClick={() => {
                                                        setFindInventoryModal(false);
                                                        handleResets();
                                                        /* setSelectedInventoryItem({
                                                            ...selectedInventoryItem,
                                                            id: data.id,
                                                            inventory_master_name: data.inventory_master_name
                                                        }) */
                                                       console.log("SELECT INVENTORY", data);
                                                       findInventoryById(data.id);
                                                       //setSelectedInventoryMaster(data);
                                                    }}><FilePlusFill />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </Modal>
            {clickedImg && (
                <Modals
                    clickedImg={clickedImg}
                    handelRotationRight={handelRotationRight}
                    setClickedImg={setClickedImg}
                    handelRotationLeft={handelRotationLeft}
                    image={listStockPhoto}
                />
            )}
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}   