import React, { useState, useEffect } from "react";
import Navbar from "../../Components/NavBar";
import ContainerBox from "../../Components/ContainerBox";
import { Button, Form,Container, Accordion } from "react-bootstrap";
import "moment/locale/id";
import { useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
  findSmsVesselPosition,
  isTokenValid,
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
// import quantumLogo from "../../Assets/Images/quantums.png"
import { EyeFill, GeoFill, ArrowLeft } from "react-bootstrap-icons";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import GoogleMapContainer from "../../Components/GoogleMapContainer";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import Loading from "../../Components/Loading";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import DropDownForm from "../../Components/DropDownForm";
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

export default function VesselPositionPage() {
  const [cookies, setCookie] = useCookies(["token"]);
  const [loading, setLoading] = useState(true);
  const [listVesselPosition, setListVesselPosition] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const location = useLocation();
  const [selectStartDate, setSelectStartDate] = useState(
    moment(new Date())
      .utc()
      .subtract(30, "minutes")
      .format("yyyy-MM-DD HH:mm:ss")
  );
  const [selectEndDate, setSelectEndDate] = useState(
    moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
  );
  const [selectTimeStamp, setSelectTimeStamp] = useState("");
  const [refreshChecked, setRefreshChecked] = useState(false);
  const [serialNumber, setSerialNumber] = useState();
  const [smsDeviceId, setSMSDeviceId] = useState();
  const [gpsData, setGpsData] = useState(null);
  const [rpmData, setRpmData] = useState({
    port: {
      labels: [],
      datasets: [
        {
          label: "PORT RPM",
          data: [],
          // backgroundColor: 'aqua',
          borderColor: "rgb(75, 192, 192)",
          // borderwith: 1,
          // pointBorderColor: 'aqua'
        },
      ],
    },
    stbd: {
      labels: [],
      datasets: [
        {
          label: "STBD RPM",
          data: [],
          // backgroundColor: 'aqua',
          borderColor: "rgb(182, 192, 75)",
          // borderwith: 1,
          // pointBorderColor: 'aqua'
        },
      ],
    },
  });
  const defaultCenter = useState({
    lat: -6.127283373056311,
    lng: 106.85529133081604,
  });

  const durationOption = [
    {
      label: "1 Jam",
      value: 1,
    },
    {
      label: "1 Hari",
      value: 2,
    },
    {
      label: "1 Minggu",
      value: 3,
    },
  ];
  const navigate = useNavigate();
  // const listVesselPosition = [
  //     { id: 1, device_name: 'Kapal 1', customer_name: "Radian Peninsula Indonesia", gps_latitude: -6.127283373056311, gps_longitude: 106.85428522507787 },
  //     { id: 2, device_name: 'Kapal 2', customer_name: "Apartemen Sunter Icon", gps_latitude: -6.134620478539386, gps_longitude: 106.85428522507787 },
  //     { id: 3, device_name: 'Kapal 3', customer_name: "Kota Tua", gps_latitude: -6.194182422829561, gps_longitude: 106.8228661334137 }
  //     // Add more devices as needed
  // ];

  const [portRpm, setPortRpm] = useState({
    labels: [],
    datasets: [
      {
        label: "Port RPM",
        data: [],
        borderColor: "rgb(75, 192, 192)",

      },
    ],
  });

  const [stbdRpm, setStbdRpm] = useState({
    labels: [],
    datasets: [
      {
        label: "Starboard RPM",
        data: [],
        borderColor: "rgb(182, 192, 75)",
      },
    ],
  });

  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated) {
        navigate("/");
      } else {
        loadVesselPosition();
      }
    }
    checkCookies();
    
  }, []);

  useEffect(() => {
    /* if (!isDataLoaded) {
      const interval = setInterval(async () => {
        loadVesselPosition();
      }, 5000);

      return () => clearInterval(interval);
    } */
  }, [portRpm, stbdRpm]);

  useEffect( () => {
    if (refreshChecked){
      const interval = setInterval(async()=> {
        loadVesselPosition();
        console.log("refreshed");
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [refreshChecked])

  useEffect(() => {
    console.log(gpsData);
  }, [gpsData]);

  useEffect(() => {
    if (isDataLoaded) {
      setLoading(false);
    }
  }, [isDataLoaded]);

  const loadVesselPosition = async () => {
    try {
      setLoading(true);
      let modConst = 50;
      let durationDiff = moment.duration(
        moment(selectEndDate).diff(moment(selectStartDate))
      );
      if (durationDiff.asDays() > 7) {
        modConst = 1000;
      } else if (durationDiff.asDays() >= 1 && durationDiff.asDays() <= 7) {
        modConst = 250;
      }
      let response = await findSmsVesselPosition(cookies.token,location.state.smsSerialnumber,selectStartDate,selectEndDate,modConst
      );
      console.log(response);
      //setListVesselPosition(response);
      let dateData = response.map((a) => a.created_date);
      let gpsData = response.map((a) => {
        return {
          latitude: a.gps_latitude,
          longitude: a.gps_longitude,
          timestamp_gps: a.timestamp_gps,
        };
      });
      setGpsData(gpsData);
      //dateData.reverse();
      let serialNumber = response.map((a) => a.sms_serial_number);
      console.log(serialNumber);
      let portData = response.map((a) => a.port_rpm);
      //rpmData.reverse();
      let stbdData = response.map((a) => a.stbd_rpm);
      //stbdData.reverse();
      setRpmData({
        ...rpmData,
        port: {
          labels: dateData,
          datasets: [
            {
              label: 'Port RPM',
              data: portData,
              backgroundColor: "rgb(75, 192, 192)",
            },
          ],
        },
        stbd: {
          labels: dateData,
          datasets: [
            {
              label: "Starboard RPM",
              data: stbdData,
              backgroundColor: "rgb(182, 192, 75)",
            },
          ],
        },
      });

      /* setPortRpm({
        ...portRpm,
        labels: dateData,
        datasets: [
          {
            label: "Port RPM",
            data: rpmData,
            backgroundColor: "rgb(75, 192, 192)",
          },
        ],
      });
      setStbdRpm({
        ...stbdRpm,
        labels: dateData,
        datasets: [
          {
            label: "Starboard RPM",
            data: stbdData,
            backgroundColor: "rgb(182, 192, 75)",
          },
        ],
      }); */
      setIsDataLoaded(true);
      setLoading(false);
    } catch (exception) {
      console.log(exception);
    }
  };

  const onMarkerClick = (deviceId) => {};

  // const loadPortRpmGraph = async () => {
  //     try {
  //         setIsDataLoaded(true);
  //         let response = await findPortRpmGraph(cookies.token, 'SMS-002-000005');
  //         console.log(response);
  //         let dateData = response.map(a => a.timestamp_reading);
  //         let rpmData = response.map(a => a.port_rpm);
  //         setPortRpm({
  //             ...portRpm,
  //             labels: dateData,
  //             datasets: [{
  //                 label: 'Port RPM',
  //                 data: rpmData,
  //                 backgroundColor: 'aqua',

  //             }]
  //         })
  //         setIsDataLoaded(false);
  //         setLoading(false);
  //     } catch (exception) {

  //     }
  // }

  return (
    <>
      <Navbar/>
      <Container
        fluid
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <ContainerBox
            titleCaption={location.state.smsSerialnumber}
            useBackButton={true}
            backButtonChild={
                <div>
                    <Button variant="secondary"
                        onClick={() => { navigate("/ListSMSDevice"); }}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                            alignSelf: "center",
                        }}>
                            <div style={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                                flex: 1,
                            }}><ArrowLeft size={32} /></div>
                        </div>
                    </Button></div>
            }
            useActionContainer={true}
            actionContainerChild={
              <>
              <Button variant="secondary" onClick={() => {
                navigate("/Vessel/Detail", {state: {vesselId: location.state.vesselId}});
              }}>
                  <div style={{display: "flex", justifyContent:'space-between'}}>
                    <div>
                      <EyeFill size={32} />
                    </div>
                    <div style={{marginTop:"4px", marginLeft:"4px"}}>
                      {'View Vessel Data'}
                    </div>
                  </div>
                </Button>
              </>
            }
            childContent={
              <>
              <Accordion>
                <Accordion.Item eventKey="0">
                <Accordion.Header>General Information</Accordion.Header>
                <Accordion.Body>
                  <p>{location.state.smsSerialnumber}</p>
                  <p>Customer Name: {location.state.customerName}</p>
                  <p>Vessel Type: {location.state.vesselName}</p>
                </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {gpsData !== null && <GoogleMapContainer devices={gpsData} />}
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems:"center",
                flexDirection: "row",
                padding:'10px'}}>
                    <Form.Group className="mb-3" style={{margin: "10px"}}>
                        <Form.Label>Tanggal Mulai</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          onChange={(e) => {
                            setSelectStartDate(e.target.value);
                          }}
                          value={
                            selectStartDate
                              ? moment(selectStartDate).format("yyyy-MM-DD HH:mm")
                              : ""
                          }
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group className="mb-3" style={{margin: "10px"}}>
                        <Form.Label>Tanggal Akhir</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          onChange={(e) => {
                            setSelectEndDate(e.target.value);
                          }}
                          value={
                            selectEndDate
                              ? moment(selectEndDate).format("yyyy-MM-DD HH:mm")
                              : ""
                          }
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group style={{margin: "10px"}}>
                      <DropDownForm
                        caption={"Rentang Waktu"}
                        placeholder={"Pilih Waktu"}
                        listOption={durationOption}
                        valueKey={"value"}
                        labelKey={"label"}
                        setValueFunction={(e) => {
                          console.log(e);
                          try {
                            let dateTime = new Date();
                            if (e.target.value === "1") {
                              console.log("1");
                              let startDate = moment(dateTime)
                                .utc()
                                .subtract(60, "minutes")
                                .format("yyyy-MM-DD HH:mm:ss");
                              let endDate = moment(dateTime)
                                .utc()
                                .format("yyyy-MM-DD HH:mm:ss");
                              setSelectStartDate(startDate);
                              setSelectEndDate(endDate);
                            } else if (e.target.value === "2") {
                              console.log("2");
                              let startDate = moment(dateTime)
                                .utc()
                                .subtract(1, "day")
                                .format("yyyy-MM-DD HH:mm:ss");
                              let endDate = moment(dateTime)
                                .utc()
                                .format("yyyy-MM-DD HH:mm:ss");
                              setSelectStartDate(startDate);
                              setSelectEndDate(endDate);
                              console.log("tes", startDate, endDate);
                            } else if (e.target.value === "3") {
                              console.log("3");
                              let startDate = moment(dateTime)
                                .utc()
                                .subtract(1, "week")
                                .format("yyyy-MM-DD HH:mm:ss");
                              let endDate = moment(dateTime)
                                .utc()
                                .format("yyyy-MM-DD HH:mm:ss");
                              setSelectStartDate(startDate);
                              setSelectEndDate(endDate);
                            }
                          } catch (exception) {
                            console.log(exception);
                          }
                        }}
                      />
                      </Form.Group>

                      <Button
                        style={{ height: "40px", margin: "10px", marginTop: "35px" }}
                        onClick={() => {
                          loadVesselPosition();
                        }}
                      >
                        Tampilkan Data
                      </Button>

                      <Form.Label>
                        <input
                        style={{marginTop:"30px"}}
                          type="checkbox"
                          checked={refreshChecked}
                          onChange={(e) => {
                            setRefreshChecked(e.target.checked);
                          }}
                        />
                        Auto Refresh
                      </Form.Label>
              </div>

              <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}>
                    <div style={{ width: "600px", height: "400px" }}>
                    <Line
                      data={rpmData.port}
                      options={{
                        scales: {
                          y: {
                            min: 0,
                            max: 4000,
                            ticks: {
                              stepSize: 200,
                            },
                          },
                        },
                      }}
                    ></Line>
                  </div>

                  <div style={{ width: "600px", height: "400px" }}>
                    <Line
                      data={rpmData.stbd}
                      options={{
                        scales: {
                          y: {
                            min: 0,
                            max: 4000,
                            ticks: {
                              stepSize: 200,
                            },
                          },
                        },
                      }}
                    ></Line>
                  </div>


              </div>
              </>

            }
        >
              
        </ContainerBox>
      </Container>
      
      <Loading loading={loading} />
      <div style={{ paddingBottom: 120 }}></div>
    </>
  );
}
