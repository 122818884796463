import './BoxWithText.css'

export default function BoxWithText({title, value, unit}) {

    const CustomProgressBar = ({ now, variant }) => {
        const progressBarStyle = {
            height: '1rem',
            // backgroundColor: 'blue',
            borderRadius: '0.25rem',
            position: 'relative',
        };
    
        const fillStyle = {
            height: '100%',
            width: `${now}%`,
            backgroundColor: variant,
        };
    
        return (
            <div style={progressBarStyle}>
                <div style={fillStyle}></div>
            </div>
        );
    };
    return(
        <>
            <div className="mainBorderBoxNewMSB">
            {/* <div className="customProgressBarContainer">
                <CustomProgressBar now={value} variant={value < 30 ? 'red' : value < 70 ? 'orange' : 'green'} />
                </div> */}
                <div><p className="txtforBoxWithTextPositionMSB">{title}</p></div>
                <div><p className="txtforBoxWithTextPositionMSB">{value}{unit}</p></div>
            </div>
        </>
    )

}