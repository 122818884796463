import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getDivision, deleteDivision, getDivisionById, insertUpdateDivision, getUserAccessById, getDivisionCount } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function DivisionPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [showDivisionModal, setShowDivisionModal] = useState(false);
    const [listDivision, setlistDivision] = useState([]);
    const { t, i18n } = useTranslation();
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_division.order_name1")}`,
        value: "division_name"
    });
    const orderByList = [{
        label: `${t("index_division.order_name1")}`,
        value: "division_name"
    }, {
        label: `${t("index_division.order_name2")}`,
        value: "division_code"
    }];
    const [descending, setDescending] = useState(false);



    const [division, SetDivision] = useState({
        id: 0,
        division_name: "",
        division_code: "",
        division_description: ""
    });




    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadDivision();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadDivision();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadDivision();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadDivision();
    }, [orderBy]);

    useEffect(()=>{
        if(resetSearch !== 0){
            loadDivision()
        }
    },[resetSearch])


    useEffect(() => {
        if(isSearched !== 0)
        loadDivision()
    }, [descending]);


    const initDivision = async (divisionId) => {
        try {
            let response = await getDivisionById(cookies.token, divisionId, cookies.languageId);
             if (response) {
                SetDivision({
                    id: response.id,
                    division_name: response.division_name,
                    division_code: response.division_code,
                    division_description: response.division_description
                });

            }
        } catch (exception) {
            console.log(exception);
        }
    }



    const clearDivisionModal = async () => {
        division.id = 0;
        division.division_name = "";
        division.division_code = "";
        division.division_description = "";
    }


    const addDivision = async () => {
        if (division.id === 0) {
            let response = await insertUpdateDivision(cookies.token, division, cookies.languageId);
            if (response.error_code === 0) {
                alert("Data Berhasil Disimpan");
                setShowDivisionModal(false);
                loadDivision();
            }

        }
        else {
            let response = await insertUpdateDivision(cookies.token, division, cookies.languageId);
            if (response.error_code === 0) {
                alert("Data Berhasil Disimpan");
                setShowDivisionModal(false);
                loadDivision();
            }

        }
    }




    const loadDivision = async () => {
        try {
            let count = await getDivisionCount(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setlistDivision(listDivision);
            setLoading(false)
            setResetSearch(0);
            setIsSearched(0);
        }
        catch (exception) {
            console.log(exception)
        }
    }




    const deleteDiv = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_division.delete_confirmation")}?`) == true) {
                let deleteSelectedDivision = await deleteDivision(cookies.token, id);
                if (deleteSelectedDivision.success) {
                    if (deleteSelectedDivision.error_code === 0) {
                        alert(`${t("index_division.delete_success_alert")}`);
                    }
                    else {
                        alert(deleteSelectedDivision.error_message);
                    }
                }
                else {
                    alert(deleteSelectedDivision.error_message);
                }
                loadDivision();
            }

        } catch (exception) {

            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_division.order_name1")}`,
                value: "division_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_division.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                navigate("/Division/Detail", { state: { divisionId: 0 } })
                                // clearDivisionModal();
                                // setShowDivisionModal(true);
                                // SetDivision({ ...division, id: 0 });
                                // division.id = 0;
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_division.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_division.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                setLoading(true);
                                                await loadDivision();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0)
                                            setLoading(true);
                                            await loadDivision();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadDivision();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_division.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_division.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_division.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_division.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_division.table_name5")}</div>
                                </div>
                                {
                                    listDivision.map((division, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{division.division_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{division.division_code}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{division.division_description}</p></div>
                                            {/* <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button className="m-1" variant="primary" onClick={() => {
                                                        // clearDivisionModal();
                                                        // setShowDivisionModal(true);
                                                        // initDivision(division.id);
                                                        navigate("/Division/Detail", { state: { divisionId: division.id } })
                                                    }}><PencilFill /></Button>
                                                </div></div> */}
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={() => {
                                                            navigate("/Division/Detail", { state: { divisionId: division.id } })
                                                        }}
                                                    ><PencilFill/></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger"
                                                        onClick={event => {
                                                            deleteDiv(division.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>

            {/*============================== MODAL ADD Division============================================ */}

            <Modal show={showDivisionModal}
                size="lg"
                onHide={() => {
                    setShowDivisionModal(false);

                }}>
                <div className="details m-2" >
                    <div className="detailscontent">

                        {division.id == 0 ?
                            <h3>Tambah Data Divisi</h3> :
                            <h3>Informasi Divisi</h3>
                        }
                    </div>

                    <Form onSubmit={(e) => {

                        e.preventDefault();
                        addDivision();


                    }} style={{ padding: 10 }}>

                        <Form.Group className="mb-3">
                            <Form.Label>Nama Divisi</Form.Label>
                            <Form.Control onChange={(e) => {
                                SetDivision({ ...division, division_name: e.target.value })
                            }} type="text" value={division.division_name} required placeholder="Masukan Nama Divisi"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Kode Divisi</Form.Label>
                            <Form.Control onChange={(e) => {
                                SetDivision({ ...division, division_code: e.target.value })
                            }} type="text" value={division.division_code} required placeholder="Masukan Kode Divisi"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Deskripsi Divisi</Form.Label>
                            <textarea
                                className="form-control"
                                value={division.division_description}
                                placeholder="Masukan Deskripsi Divisi"
                                onChange={(e) => {
                                    SetDivision({ ...division, division_description: e.target.value })
                                }}
                                rows="4"
                            />
                        </Form.Group>
                        <Button type="submit">Simpan</Button>
                        <Button className="cancel" variant="danger" onClick={() => {
                            setShowDivisionModal(false);
                        }}>Batal</Button>
                    </Form>



                </div>

            </Modal>
        </>
    )
}   