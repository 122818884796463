import "../../SMSApp.css";
import "./index.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import Navbar from "../../Components/NavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { isTokenValid, getPlcValue, getTankLevelCoordinate, getCountCoordinateTankLevel, getWireBreakTankLevel, getEquipmentDetail, calculateTankSounding } from "../../Helpers/ApplicationHelpers";
import TankLevel from "../../Components/TankLevel/TankLevel";
import Paginations from "../../Components/Pagination";
import Loading from "../../Components/Loading";
import DropDownForm from "../../Components/DropDownForm";

export default function TankLevels() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [itemPerPage, setItemPerPage] = useState(9);
    const [cookies, setCookie] = useCookies(["token"]);
    const [listTankLevel, setListTankLevel] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [listWireBreak, setListWireBreak] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentid] = useState("");
    const [equipmentModal, setEquipmentModal] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [vesselEquipmentId])

    useEffect(() => {
        // if (isSearched !== 0)
        loadCoordinate();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCoordinate();
    }, [itemPerPage]);


    useEffect(() => {

        if (listTankLevel.length > 0) {
            const interval = setInterval(async () => {
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval)
        }


    }, [listTankLevel]);

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            console.log(response);
            setDetailEquipment(response);
            setEquipmentModal(true);
        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {

            let coordinate = listTankLevel.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let valueTank = await calculateTankSounding(cookies.token, cookies.vesselId, page , itemPerPage);
           
            let updatedList = listTankLevel.map(item=>{
                let tank = valueTank.find(p=>item.plc_coordinate === p.plc_coordinate);
                if(tank){
                    return{...item,volume: tank.volume, tank_level: tank.tank_level}
                }else{
                    return{...item,volume: item.volume, tank_level: item.tank_level}
                }
            })
            



            let updatedListWireBreak = listWireBreak.map(item => {
                let wireBreak = response.find(p => item.plc_coordinate === p.plc_coordinate);
                if (wireBreak) {
                    return { ...item, alarm: wireBreak.value === 1 };

                } else {
                    return { ...item, alarm: item.alarm };

                }
            });
            if (isSearched === 0) {
                setListTankLevel(updatedList);
                setListWireBreak(updatedListWireBreak);
            }


            setLoading(false);


        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCoordinate = async () => {
        try {
            // setIsSearched(1);
            // let count = await getCountCoordinateTankLevel(cookies.token);
            // let totalPage = count / itemPerPage;
            // setTotalPage(totalPage);

            let response = await getTankLevelCoordinate(cookies.token, page, itemPerPage, cookies.vesselId);


            let wireBreaks = await getWireBreakTankLevel(cookies.token, page, itemPerPage, cookies.vesselId);
            setListWireBreak(wireBreaks)
            setListTankLevel(response);
            setIsSearched(0);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }
    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Tank Level'}/>
                    <BottomNavBar/>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexDirection: "row",
                        padding:'5px'}}>
                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Mulai</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    
                                }}
                                
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Akhir</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                   
                                }}
                                
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group style={{margin: "10px", color: 'white'}}>
                            <DropDownForm
                                caption={"Rentang Waktu"}
                                placeholder={"Pilih Waktu"}
                                // listOption={}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                console.log(e);
                                try {
                                    let dateTime = new Date();
                                    // if (e.target.value !== "") {
                                    //   setLoading(true);
                                    // }

                                    if (e.target.value === "1") {
                                    console.log("1");
                                    
                                    } else if (e.target.value === "2") {
                                    console.log("2");
                                    
                                    } else if (e.target.value === "3") {
                                    console.log("3");
                                    
                                    }
                                } catch (exception) {
                                    console.log(exception);
                                }
                                }}
                            />
                            </Form.Group>

                            <Button
                                variant="dark"
                                style={{ height: "40px", margin: "10px", marginTop: "35px", backgroundColor:'#050512' }}
                                onClick={() => {
                                
                                }}
                            >
                                Tampilkan Data
                            </Button>

                            <Form.Label style={{color: 'white'}}>
                                <input
                                style={{marginTop:"30px"}}
                                type="checkbox"
                                // checked={refreshChecked}
                                onChange={(e) => {
                                    // setRefreshChecked(e.target.checked);
                                }}
                                />
                                Auto Refresh
                            </Form.Label>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', padding: '10px', margin:'10px 0px'}}>
                        <TankLevel text={'FPT Ballast Tank'} value={40} wireBreak={'false'}/>
                        <TankLevel text={'APT Ballast Tank (P)'} value={40} wireBreak={'false'}/>
                        <TankLevel text={'APT Ballast Tank (S)'} value={40} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', padding: '10px', margin:'10px 0px'}}>
                        <TankLevel text={'F.O Tank No.1 (P)'} value={40} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No.1 (C)'} value={40} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No.1 (S)'} value={40} wireBreak={'false'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', padding: '10px', margin:'10px 0px'}}>
                        <TankLevel text={'F.O Tank No.2 (P)'} value={40} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No.2 (C)'} value={40} wireBreak={'false'}/>
                        <TankLevel text={'F.O Tank No.3 (P)'} value={40} wireBreak={'false'}/>
                    </div>
                </div>
            </div>
        </>
    );
}