// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerPortCyl {
    display: flex;
    flex-direction: row;
}

.contentPortCyl {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 650px;
    background-color: rgba(233, 233, 250, 0.1);
    color: white;
    margin: 10px;
    border-radius: 7px;
}

.contentPortCyl .titleInfo {
    align-items: center;
    margin-left: 10px;
}

.contentPortCyl .titleInfo img {
    height: 25px;
}

.contentPortCyl .textInfo {
    padding: 10px;
}

.contentPortCyl .textInfo div {
    color: #298BFE;
    font-weight: bold;
    font-size: 18px;
}

.contentPortCyl .textInfo p {
    font-size: 18px;
    margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/MainEngine/PortCyl1/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;IACP,aAAa;IACb,0CAA0C;IAC1C,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".containerPortCyl {\n    display: flex;\n    flex-direction: row;\n}\n\n.contentPortCyl {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    height: 650px;\n    background-color: rgba(233, 233, 250, 0.1);\n    color: white;\n    margin: 10px;\n    border-radius: 7px;\n}\n\n.contentPortCyl .titleInfo {\n    align-items: center;\n    margin-left: 10px;\n}\n\n.contentPortCyl .titleInfo img {\n    height: 25px;\n}\n\n.contentPortCyl .textInfo {\n    padding: 10px;\n}\n\n.contentPortCyl .textInfo div {\n    color: #298BFE;\n    font-weight: bold;\n    font-size: 18px;\n}\n\n.contentPortCyl .textInfo p {\n    font-size: 18px;\n    margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
