import "../App.css";
import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { Form, option, Button, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Select from 'react-select';
import { Trash, FilePlusFill, PencilFill, XSquareFill, CameraFill, Image } from "react-bootstrap-icons";
import { isTokenValid, getVesselEquipmentByEquipmentCategory, convertBase64 } from "../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import ContainerBox from "./ContainerBox";
import FormSelect from "./FormSelect";
import { Trans, useTranslation } from "react-i18next";

function ServiceReportDetail({
    serviceJob,
    // steSelectedGroup,
    handleOnBlurServiceJob,
    handleOnBlurSelectedProject,
    listProject,
    serviceJobId,
    listStatus,
    disabledAddCrew,
    listMechanicGroup,
    listStaff,
    handleOnBlurlistCrew,
    listServiceCrew,
    errors,
    disabledCustomerForm
}) {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [serviceJobNew, setServiceJobNew] = useState({});
    const [resetComponent, setResetComponent] = useState(false);
    const [listStatusNew, setListStatusNew] = useState([]);
    const [listProjectNew, setListProjectNew] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedMechanicGroup, setSelectedMechanicGroup] = useState(null)
    const [listMechanicGroupNew, setListMechanicGroupNew] = useState([]);
    const [listStaffNew, setListStaffNew] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null)
    const [serviceDate, setServiceDate] = useState(null);
    // useEffect(() => {
    //     resetComponentProps && setResetComponent(false);
    // }, [resetComponent]);


    useEffect(() => {
        if (serviceJob) {
            setServiceJobNew(serviceJobNew);
        }
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
        }
    }, []);

    useEffect(() => {
        if (serviceJob) {
            setServiceJobNew(serviceJob);
            setServiceDate(serviceJob.service_date)
        }

        if (listProject) {
            setListProjectNew(listProject);
        }

        if (listStatus)
            setListStatusNew(listStatus);

        if (listMechanicGroup) {
            setListMechanicGroupNew(listMechanicGroup);
        }

        if (listStaff) {
            setListStaffNew(listStaff)
        }
    }, [serviceJob]);

    useEffect(() => {
        if (listMechanicGroup) {
            setListMechanicGroupNew(listMechanicGroup)
            let selectedMechanicGroupTmp = listMechanicGroup.find(p => p.id === serviceJob.group_mechanic_id)

            if (selectedMechanicGroupTmp) {
                setSelectedMechanicGroup(selectedMechanicGroupTmp)
            }
        }
    }, [listMechanicGroup])

    useEffect(() => {
        if (listStaff) {
            setListStaffNew(listStaff)
        }
    }, [listStaff])


    useEffect(() => {
        if (selectedProject) {
            let serviceJobTmp = serviceJobNew
            serviceJobTmp.project_id = selectedProject.id
            setServiceJobNew(serviceJobTmp)
            handleOnBlurServiceJob(serviceJobNew)
            handleOnBlurSelectedProject(selectedProject)
        }
    }, [selectedProject]);

    useEffect(() => {
        if (serviceDate) {
            let serviceJobTmp = serviceJobNew
            serviceJobTmp.service_date = serviceDate
            setServiceJobNew(serviceJobTmp)
            handleOnBlurServiceJob(serviceJobNew)
        }
    }, [serviceDate])

    useEffect(() => {
        if (selectedMechanicGroup) {
            let serviceJobTmp = serviceJobNew
            let listStaffTmp = selectedMechanicGroup.list_staff.find(p => p.is_leader === true)
            if (serviceJobTmp.staff_service_pic && serviceJobTmp.group_mechanic_id) {
                let staffTmp = listStaffNew.find(p => p.staff_id === serviceJobTmp.staff_service_pic);
                setSelectedStaff(staffTmp)
            } else {
                let staffTmp = listStaffNew.find(p => p.staff_id === listStaffTmp.staff_id)
                if (staffTmp) {
                    setSelectedStaff(staffTmp);
                    serviceJobTmp.staff_service_pic = listStaffTmp.staff_id

                }
            }


            serviceJobTmp.group_mechanic_id = selectedMechanicGroup.id

            setServiceJobNew(serviceJobTmp)
            handleOnBlurServiceJob(serviceJobNew)
        }
    }, [selectedMechanicGroup]);


    useEffect(() => {
        if (listProjectNew.length > 0) {
            let selectedProjectTmp = listProjectNew.find(p => p.id === serviceJob.project_id)

            if (selectedProjectTmp) {
                setSelectedProject(selectedProjectTmp)
            }
        }
    }, [listProjectNew])


    useEffect(() => {
        if (listMechanicGroupNew.length > 0) {
            let selectedMechanicGroupTmp = listMechanicGroupNew.find(p => p.id === serviceJob.group_mechanic_id)
            // console.log(selectedMechanicGroupTmp)
            if (selectedMechanicGroupTmp) {
                setSelectedMechanicGroup(selectedMechanicGroupTmp)
            }
        }
    }, [serviceJobNew])


    useEffect(() => {
        if (selectedStaff) {
            let serviceJobTmp = serviceJobNew
            serviceJobTmp.staff_service_pic = selectedStaff.staff_id
            setServiceJobNew(serviceJobTmp)
            handleOnBlurServiceJob(serviceJobNew)

        }
    }, [selectedStaff])



    return (
        <div style={{ padding: 10 }}>
            <Form.Group>
                <Form.Label>{t("detail_annual_report.field_project_name")}</Form.Label>
                <Select placeholder={""}
                    getOptionLabel={(item) => {
                        return item.project_name;
                    }} clearValue={true}
                    getOptionValue={(item) => {
                        return item.id;
                    }}
                    options={listProjectNew} value={selectedProject} onChange={(e) => {
                        if (e === null) {
                            setSelectedProject({})
                        } else {
                            setSelectedProject(e);
                        }
                    }}
                    isClearable
                    // isDisabled={disabledAddCrew}
                    isDisabled={serviceJobId !== 0 && serviceJobId !== ""}
                />
            </Form.Group>
            {/* {
                errors.projectName && <p style={{ color: "red" }}>{errors.projectName}</p>
            } */}
            <Form.Group>
                <Form.Label>{t("detail_annual_report.field_service_date")}</Form.Label>
                <Form.Control required type="date" disabled={disabledCustomerForm === true ? true : disabledAddCrew} value={serviceDate ? moment(serviceDate).format("yyyy-MM-DD") : ""} onChange={(e) => {
                    // if (e.target.value === "") {
                    //     setServiceJobNew({ ...serviceJobNew, service_date: null })
                    // } else {
                    //     setServiceJobNew({ ...serviceJobNew, service_date: e.target.value })
                    // }
                    // handleOnBlurServiceJob(serviceJobNew)
                    setServiceDate(e.target.value)
                }} ></Form.Control>
            </Form.Group>
            {/* {
                errors.serviceDate && <p style={{ color: "red" }}>{errors.serviceDate}</p>
            } */}
            <Form.Group>
                <Form.Label>{t("detail_annual_report.field_service_status")}</Form.Label>
                <Form.Select onBlur={e => handleOnBlurServiceJob(serviceJobNew)} disabled={serviceJobId === 0 || disabledCustomerForm === true ? true : false} required value={serviceJobNew.status_id} onChange={(e) => {
                    if (e.target.value === "") {
                        setServiceJobNew({ ...serviceJobNew, status_id: null });
                    } else {
                        setServiceJobNew({ ...serviceJobNew, status_id: e.target.value });
                    }
                    // handleOnBlurServiceJob(serviceJobNew)
                }}>
                    <option></option>
                    {
                        listStatusNew.map((status, index) => {
                            return (
                                <option key={index} value={status.id}>{status.status_name}</option>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group>
                <Form.Label>{t("detail_annual_report.field_technician_group")}</Form.Label>

                <Select placeholder={""}
                    getOptionLabel={(item) => {
                        return item.group_name;
                    }} clearValue={true}
                    getOptionValue={(item) => {
                        return item.id;
                    }}
                    options={listMechanicGroupNew} value={selectedMechanicGroup} onChange={(e) => {
                        if (e === null) {
                            setSelectedMechanicGroup({})
                        } else {

                            if (serviceJobId === 0) {
                                let listCrewTmp = e.list_staff.filter(p => p.is_active === true)
                                if (listCrewTmp) {
                                    handleOnBlurlistCrew(listCrewTmp)
                                }
                                let serviceJobTmp = serviceJobNew
                                serviceJobTmp.staff_service_pic = null
                                setServiceJobNew(serviceJobTmp)
                                setSelectedMechanicGroup(e);
                            } else {
                                if (window.confirm(`${t("detail_annual_report.alert_change_group")}`)) {
                                    let staffPicTmp = e.list_staff.find(p => p.is_leader === true);

                                    if (staffPicTmp) {
                                        let serviceJobTmp = serviceJobNew
                                        serviceJobTmp.staff_service_pic = null

                                        let staffCrew = e.list_staff.filter(p => p.is_active === true);
                                        let listStaffs = [];
                                        for (let index = 0; index < staffCrew.length; index++) {
                                            let listCrew = staffCrew[index];
                                            listCrew.id = null;
                                            listStaffs.push(listCrew);
                                        }
                                        let list = [];
                                        // let deleteListCrew = serviceJob.list_crew;
                                        let deletedCrews = serviceJobNew.deleted_crew
                                        if (deletedCrews !== undefined) {
                                            for (let i = 0; i < listServiceCrew.length; i++) {
                                                const list = listServiceCrew[i];
                                                deletedCrews.push(list)
                                            }
                                        } else {
                                            deletedCrews = listServiceCrew
                                        }

                                        serviceJobTmp.deleted_crew = deletedCrews
                                        setServiceJobNew(serviceJobTmp)

                                        if (staffCrew) {
                                            handleOnBlurlistCrew(listStaffs)
                                            // setServiceJobNew({ ...serviceJobNew, staff_service_pic: staffPic.staff_id, list_crew: listStaffs, deleted_crew: deletedCrews })
                                        } else {
                                            handleOnBlurlistCrew([])
                                            // setServiceJobNew({ ...serviceJobNew, staff_service_pic: staffPic.staff_id, list_crew: [], deleted_crew: deletedCrews })
                                        }
                                    }
                                    setSelectedMechanicGroup(e);
                                }
                            }

                        }
                    }}
                    // isClearable
                    isDisabled={disabledCustomerForm === true ? true : disabledAddCrew}
                />

            </Form.Group>
            {/* {
                errors.mechanicGroup && <p style={{ color: "red" }}>{errors.mechanicGroup}</p>
            } */}
            <Form.Group>
                <Form.Label>{t("detail_annual_report.field_staff_pic")}</Form.Label>
                <Select placeholder={""}
                    getOptionLabel={(item) => {
                        return item.staff_name;
                    }} clearValue={true}
                    getOptionValue={(item) => {
                        return item.id;
                    }}
                    options={listStaff.filter((p) => {
                        return !listServiceCrew.find((x) => {
                            return p.staff_id === x.crew_name
                        }) && p.id !== serviceJobNew.staff_service_pic && p.resign_date === null
                    })} value={selectedStaff} onChange={(e) => {

                        let list = [];

                        let indexCrew = listServiceCrew.findIndex((index) => index.crew_name === serviceJob.staff_service_pic);
                        list.push(listServiceCrew[indexCrew]);
                        let deletedCrews = serviceJobNew.deleted_crew
                        if (deletedCrews !== undefined) {
                            deletedCrews.push(list[0])
                        } else {
                            deletedCrews = list
                        }
                        let serviceJobTmp = serviceJobNew
                        serviceJobTmp.deleted_crew = deletedCrews

                        setServiceJobNew(serviceJobTmp)
                        e.is_leader = true
                        e.crew_name = e.staff_id
                        e.id = null

                        let listServiceCrewTmp = listServiceCrew
                        listServiceCrewTmp.splice(indexCrew, 1)
                        listServiceCrewTmp.unshift(e);
                        handleOnBlurlistCrew(listServiceCrewTmp)
                        setSelectedStaff(e)
                        // handleOnBlurServiceJob(serviceJobNew)
                    }}
                    // isClearable
                    isDisabled={disabledCustomerForm === true ? true : disabledAddCrew}
                />



            </Form.Group>
            <Form.Group>
                <Form.Label>{t("detail_annual_report.field_service_reason")}</Form.Label>
                <Form.Control disabled={disabledCustomerForm === true ? true : disabledAddCrew} onBlur={e => handleOnBlurServiceJob(serviceJobNew)} as={"textarea"} rows={3} value={serviceJobNew.service_reason} onChange={(e) => {
                    setServiceJobNew({ ...serviceJobNew, service_reason: e.target.value })
                }}>
                </Form.Control>
            </Form.Group>
        </div>
    )
}



export default ServiceReportDetail;
