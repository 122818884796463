import React, { useState, useEffect } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, getStaffPositionById, insertUpdateStaffPosition, getLanguage, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import logo from "../../../../Assets/Images/default.jpg";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";


export default function StaffPositionDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const location = useLocation();
    const [listLanguage, setListLanguage] = useState([]);
    const { t, i18n } = useTranslation();
    const [staffPosition, setStaffPosition] = useState({
        positionName: "",
        positionDesc: "",
        languageId: ""
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadLanguge();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
        if (location.state.staffId === "") {

        } else {
            initStaffPosition();
        }
    }, []);


    const initStaffPosition = async () => {
        try {
            let response = await getStaffPositionById(cookies.token, location.state.staffPositionId);
            if (response) {
                setStaffPosition({
                    ...staffPosition,
                    positionName: response.position_name,
                    positionDesc: response.position_desc,
                    languageId: response.languageId
                })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const addStaffPosition = async () => {
        try {
            let response = await insertUpdateStaffPosition(cookies.token, staffPosition, location.state.staffPositionId, cookies.languageId);
            if (response === 0) {
                alert(`${t("detail_staff_position.alert_success_save_data")}`);
                navigate("/StaffPosition");
            } else {
                alert(`${t("detail_staff_position.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadLanguge = async () => {
        try {
            let listLanguage = await getLanguage(cookies.token);
            setListLanguage(listLanguage);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_staff_position.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/StaffPosition"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_staff_position.container_staff_position_title")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>

                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            addStaffPosition();
                                                            setDisabledButton(true);
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_staff_position.field_position_name")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setStaffPosition({ ...staffPosition, positionName: e.target.value })
                                                                }} type="text" value={staffPosition.positionName} required></Form.Control>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_staff_position.field_description")} </Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setStaffPosition({ ...staffPosition, positionDesc: e.target.value })
                                                                }} as="textarea" rows={3} value={staffPosition.positionDesc}  ></Form.Control>
                                                            </Form.Group>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                padding: 5
                                                            }}>

                                                                <Button style={{width:100}} type="submit" variant="primary" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("detail_staff_position.save_button")}</Button>

                                                                <Button style={{width:100}} className="cancel" type="reset" variant="danger" >{t("detail_staff_position.cancel_button")}</Button>

                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>


                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />

        </>
    )
}