import "./NewBitLamp.css"

export default function BitLamp({ text, alarm}) {
    return (
        <div className="containerBitLampForMotorPump">
            {
                alarm === "true" ?
                    <div className="bitLampMotorPumpContainerOn"></div> : <div className="bitLampMotorPumpContainerOff" ></div>
            }
        </div>
    )
}