import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Modal, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVesselDocumentCount, deleteVesselDocument,
    getVesselDocument, convertBase64, insertUpdateVesselDocument,
    getUserAccessById, getVesselDocumentById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import Paginations from "../../../../Components/Pagination";
import {
    BoxArrowUpRight, FileCheckFill, PencilFill, Trash, CameraFill, Download, ChevronDoubleLeft, ChevronDoubleRight,
    FilePlusFill, Search, ArrowClockwise, GearWideConnected, EyeFill, Eye, InfoCircleFill, ZoomIn, ZoomOut, AspectRatioFill,
    XSquareFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import { Trans, useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function VesselPage() {
    const moment = require('moment');
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listDocument, setListDocument] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [loading, setLoading] = useState(true);
    const [documentCount, setDocumentCount] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [documentId, setDocumentId] = useState("");
    const [isSearched,setIsSearched] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [downloadDocumentId, setDownloadDocumentId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [vesselDocumentId, setVesselDocumentId] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([1, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("vessel_document.order_name1")}`,
        value: "document.created_date"
    });
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [newDocument, setNewDocument] = useState({
        id: 0,
        vessel_id: "",
        base64_document: "",
        document_name: "",
        document_desc: "",
        reference_number: "",
        is_active: true,
        done: false,
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        vessel_id: "",
        base64_document: "",
        document_name: "",
        document_desc: "",
        reference_number: "",
        is_active: true,
    });

    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const orderByList = [
        {
            label:`${t("vessel_document.order_name2")}`,
            value: "document.document_name"
        },
        {
            label: `${t("vessel_document.order_name1")}`,
            value: "document.created_date"
        }]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                setVesselId(location.state.vesselId);
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setVesselName(location.state.vesselName);
        setNewDocument({ ...newDocument, vessel_id: vesselId });
        loadDocumentData();
        setLoading(false);
    }, [vesselId]);

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(()=>{
        if(vesselDocumentId !== "")
        loadDocumentById()
    },[vesselDocumentId])

    useEffect(() => {
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                vessel_id: vesselId,
                base64_document: "",
                document_name: "",
                document_desc: "",
                is_active: true,
            });
            setVesselDocumentId("");
        }
    }, [showDocumentDetailModal]);

    useEffect(() => {
        setLoading(true);
        if (vesselId !== "")
            loadDocumentData();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0){
            setLoading(true);
            if (vesselId != "")
                loadDocumentData();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0){
            setLoading(true);
            if (vesselId !== "")
                loadDocumentData();
        }
    }, [orderBy]);

    useEffect(() => {
        setLoading(true);
        if (vesselId !== "")
            removeDocument();
    }, [documentId]);

    useEffect(()=>{
        if(resetSearch !== 0)
        loadDocumentData()
    },[resetSearch])

    useEffect(() => {
        if(isSearched !== 0){
            setLoading(true);
            if (vesselId !== "")
                loadDocumentData();
        }
    }, [descending]);

    useEffect(() => {
        if (downloadDocumentId !== "")
            downloadData();
    }, [downloadDocumentId])



    const loadDocumentData = async () => {
        try {
            let documentCount = await getVesselDocumentCount(cookies.token, vesselId, searchQuery, false);
            setDocumentCount(documentCount);
            let totalPage = documentCount / itemPerPage;
            setTotalPage(totalPage);

            let listDocument = await getVesselDocument(cookies.token, vesselId, page, itemPerPage, orderBy.value, descending, searchQuery);
            setListDocument(listDocument);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0)
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteVesselDocument(cookies.token, documentId);
        if (response) {
            loadDocumentData();
        } else {
            alert(`${t("vessel_document.delete_failed_alert")}`);
            setLoading(false);
        }
    }

    const resetUploadForm = () => {
        let newDocument = {
            id: 0,
            vessel_id: vesselId,
            base64_document: "",
            document_name: "",
            document_desc: "",
            reference_number: "",
            is_active: true,
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateVesselDocument(cookies.token, newDocument);
                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                resolve();
                setDisabledButton(false);
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("vessel_document.order_name1")}`,
                value: "document.created_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDocumentById = async()=>{
        try {
            let response = await getVesselDocumentById(cookies.token, vesselDocumentId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await getVesselDocumentById(cookies.token, downloadDocumentId);
            triggerBase64Download(response.base64_document, response.document_name);
            setLoading(false);
            setDownloadDocumentId("");
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("vessel_document.header_title")} ${vesselName}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Detail", { state: { vesselId: location.state.vesselId } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><InfoCircleFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.button_vessel_information")}</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/EquipmentStructView", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><GearWideConnected size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.button_equipment")}</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/VesselPhoto", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><CameraFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.button_photo")}</div>
                                </div>
                            </Button>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowDocumentUploadModal(true); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.add_button")}</div>
                                </div>
                            </Button>
                        </div>


                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("vessel_document.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                setPage(0);
                                                await loadDocumentData();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadDocumentData();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("vessel_document.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_document.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("vessel_document.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_document.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_document.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_document.table_name6")}</div>
                                </div>
                                {
                                    listDocument.map((document, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setLoading(true);
                                                        setVesselDocumentId(document.id)
                                                        // setDocumentToBeViewed(document);
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                       setDownloadDocumentId(document.id);
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("vessel_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            let documentId = document.id
                                                            setDocumentId(documentId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />


                {/* <Modal show={showDocumentUploadModal} onHide={() => {
                    setShowDocumentUploadModal(false);
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Upload Dokumen`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setNewDocument({ ...newDocument, done: true });
                                    setDisabledButton(true);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>File Dokumen</Form.Label>
                                            <Form.Control onChange={async (e) => {
                                                let base64Doc = await convertBase64(e.target.files[0]);
                                                setNewDocument({ ...newDocument, base64_document: base64Doc.toString(), file: e.target.value });
                                            }} type="file" required></Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Dokumen</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                                }} value={newDocument.document_name} type="text" placeholder="Nama Dokumen" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>No. Referensi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewDocument({ ...newDocument, reference_number: e.target.value })
                                                }} value={newDocument.reference_number} type="text" placeholder="No. Referensi"></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,

                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Deskripsi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setNewDocument({ ...newDocument, document_desc: e.target.value })
                                                }} value={newDocument.document_desc} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            resetUploadForm();
                                            loadDocumentData();
                                            setShowDocumentUploadModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal> */}

                <Modal show={showDocumentUploadModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        resetUploadForm();
                        setShowDocumentUploadModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("vessel_document.modal_add_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                           e.preventDefault();
                           setNewDocument({ ...newDocument, done: true });
                           setDisabledButton(true);
                           setLoading(true);
                        }} style={{ padding: 10 }}>



                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_document.field_document_file")}</Form.Label>
                                <Form.Control onChange={async (e) => {
                                    if (e.target.files[0].type === "application/pdf") {
                                        let base64Doc = await convertBase64(e.target.files[0]);
                                        setNewDocument({ ...newDocument, base64_document: base64Doc.toString(), file: e.target.value });
                                        setUploadFileImageError("");
                                        setDisabledButton(false)

                                    } else {
                                        setNewDocument({ ...newDocument, document_base64: "", file:"" });
                                        setUploadFileImageError(`${t("vessel_document.error_pdf_file")}`)
                                        setDisabledButton(true)
                                    }
                                    
                                }} type="file" required></Form.Control>
                                {
                                    uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_document.field_document_name")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                }} value={newDocument.document_name} type="text" placeholder="" required></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_document.field_reference_number")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, reference_number: e.target.value })
                                }} value={newDocument.reference_number} type="text" placeholder="" ></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("vessel_document.field_description")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, description: e.target.value })
                                }} value={newDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>

                            </Form.Group>

                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flex: 1,
                            }}>
                                <Button style={{width:100}} variant="primary" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>
                                {t("vessel_document.save_button")}
                                </Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    resetUploadForm();
                                    loadDocumentData();
                                    setShowDocumentUploadModal(false);
                                }}>
                                    {t("vessel_document.cancel_button")}
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal>

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name} 
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
    
                            }} onClick={() => { 
                                setShowDocumentDetailModal(false);
                                }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed.base64_document}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                            {(pageNumber + 1) < numPages &&
                                                <Page scale={zoomFactor} pageNumber={pageNumber + 1} />
                                            }

                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                    {t("vessel_document.page_text")} {pageNumber} {t("vessel_document.from_text")} {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.75);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(0.5);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 1.25);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

                </Modal>
            </Container >
        </>
    )


}