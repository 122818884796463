import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getDivisionById, getDivision, insertUpdateDivision, getDepartment, getDepartmentById,
    deleteDepartment, insertUpdateDepartment, getUserAccessById, getDepartmentCount
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import ButtonForm from "../../../../Components/ButtonForm";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft, Trash, FilePlusFill, Search, ArrowClockwise, PencilFill } from "react-bootstrap-icons";
import FormSelect from "../../../../Components/FormSelect";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";


export default function DetailDivisionPage() {

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [listDepartment, setListDepartment] = useState([]);
    const [listDivision, setListDivision] = useState([]);
    const { t, i18n } = useTranslation();
    const [departmentModal, setDepartmentModal] = useState("");
    const [divisionId, setDivisionId] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [resetSearch, setResetSearch] = useState(0);
    const [disabledButtonModal, setDisabledButtonModal] = useState(false);
    const [division, setDivision] = useState({
        id: 0,
        division_name: "",
        division_code: "",
        division_description: "",
        ext_customer_id : cookies.extCustomerId
    });
    const [department, setDepartment] = useState({
        id: 0,
        division_id: "",
        department_name: "",
        department_code: "",
        department_description: "",
    })
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [departmentId, setDepartmentId] = useState("");
    const [page, setPage] = useState(0);
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(5);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_division.order_name1")}`,
        value: "department.department_name"
    });
    const orderByList = [{
        label: `${t("detail_division.order_name1")}`,
        value: "department.department_name"
    },
    {
        label: `${t("detail_division.order_name2")}`,
        value: "department.department_code"
    }];
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadDivision();
                setDivisionId(location.state.divisionId);
                setLoading(false);
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
        // if (location.state.mechanicGroupId === 0) {
        //     setLoading(false);
        // } else {
        //     initDivision();
        // }
    }, []);

    useEffect(() => {
        validationForm();
    }, [division]);


    useEffect(() => {
        if (departmentId !== "")
            initDepartment();
    }, [departmentId]);

    useEffect(() => {
        if (removeId !== "")
            removeDepartment()
    }, [removeId])

    useEffect(() => {
        if (divisionId !== 0) {
            loadDepartment();
            initDivision();
        }

    }, [divisionId])

    useEffect(() => {
        if (divisionId !== 0) 
        loadDepartment();
    }, [page]);

    useEffect(() => {
        if (divisionId !== 0 && isSearched !== 0) 
        loadDepartment();
    }, [itemPerPage]);

    useEffect(() => {
        if (divisionId !== 0 && isSearched !== 0) 
        loadDepartment();
    }, [orderBy]);

    useEffect(() => {
        if (divisionId !== 0 && isSearched !== 0) 
        loadDepartment()
    }, [descending]);

    useEffect(()=>{
        if(resetSearch !== 0)
        loadDepartment()
    },[resetSearch])


    const saveData = async () => {
        try {
            let divisionTmp = division;
            divisionTmp.ext_customer_id = divisionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : divisionTmp.ext_customer_id
            let response = await insertUpdateDivision(cookies.token, divisionTmp, cookies.languageId, location.state.divisionId);
            if (response.error_code === 0) {
                alert(`${t("detail_division.alert_success_save_data")}`);
                // navigate("/Division");
                setDivisionId(response.data.id);
            } else {
                alert(`${t("detail_division.failed_save_data")}`)
            }
        } catch (exception) {
           
        }
    }

    const loadDivision = async () => {
        try {
            let response = await getDivision(cookies.token, cookies.languageId,cookies.extCustomerId);
            let listDivision = []
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].division_name,
                    id: response[i].id,
                };

                listDivision.push(obj);

            }
            setListDivision(listDivision);
        } catch (exception) {
            // console.log(exception);
        }
    }

    const initDivision = async () => {
        try {
            let response = await getDivisionById(cookies.token, divisionId, cookies.languageId);
            if (response) {
                setDivision({
                    ...division,
                    id: response[0].id,
                    division_name: response[0].division_name,
                    division_code: response[0].division_code,
                    division_description: response[0].division_description
                })
                setLoading(false);
            } else {
                alert(`${t("detail_division.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            // console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (division.division_name === undefined || division.division_name === "") {
                    setErrors({
                        ...errors,
                        divisionNameError: `${t("detail_division.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (division.division_code === undefined || division.division_code === "") {
                    setErrors({
                        ...errors,
                        divisionCodeError: `${t("detail_division.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "divisionNameError", "divisionCodeError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                // console.log(exception);
                resolve(false);

            }
        })
    }

    const loadDepartment = async () => {
        try {
            let count = await getDepartmentCount(cookies.token, location.state.divisionId === 0 ? divisionId : location.state.divisionId, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getDepartment(cookies.token, location.state.divisionId === 0 ? divisionId : location.state.divisionId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListDepartment(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            // console.log(exception)
        }
    }

    const initDepartment = async () => {
        try {
            let response = await getDepartmentById(cookies.token, departmentId);
            if (response) {
                setDepartment({
                    ...department,
                    id: response.id,
                    department_name: response.department_name,
                    department_code: response.department_code,
                    department_description: response.department_description,
                    division_id: response.division_id
                })
                setDepartmentModal(true);
                setDepartmentId("");
                setLoading(false);
            } else {
                alert(`${t("detail_division.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            // console.log(exception);
        }

    }

    const removeDepartment = async () => {
        try {
            let response = await deleteDepartment(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("detail_division.delete_success_alert")}`);
                loadDepartment();
            }
            setRemoveId("");
        } catch (exception) {
            // console.log(exception);
        }
    }

    const saveDepartment = async () => {
        try {
            let response = await insertUpdateDepartment(cookies.token, department);
            if (response.error_code === 0) {
                alert(`${t("detail_division.alert_success_save_data")}`);
                setDepartmentModal(false);
                // clearInventoryTypeModal();
                loadDepartment();
            } else {
                alert(`${t("detail_division.failed_save_data")}`)
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            // console.log(exception)
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            // console.log(exception);
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_division.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Division"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_division.container_division_title")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <TextBox value={division.division_name} placeholder=""
                                                    caption={`${t("detail_division.field_division_name")}`} setValueFunction={(e) => {
                                                        setDivision({ ...division, division_name: e.target.value });
                                                    }} />
                                                {
                                                    errors.divisionNameError && <p style={{ color: "red" }}>{errors.divisionNameError}</p>
                                                }
                                                <TextBox value={division.division_code} placeholder=""
                                                    caption={`${t("detail_division.field_division_code")}`} setValueFunction={(e) => {
                                                        setDivision({ ...division, division_code: e.target.value });
                                                    }} />
                                                {
                                                    errors.divisionCodeError && <p style={{ color: "red" }}>{errors.divisionCodeError}</p>
                                                }
                                                <TextBox value={division.division_description} placeholder=""
                                                    caption={`${t("detail_division.field_division_description")}`} setValueFunction={(e) => {
                                                        setDivision({ ...division, division_description: e.target.value });
                                                    }} asType="textarea" rows={3} />


                                            </div>
                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            divisionId !== 0 &&
                                            <ContainerBox containerPos="inner" titleCaption={`${t("detail_division.container_list_department")}`}
                                                useActionContainer={true}
                                                actionContainerChild={
                                                    <div>
                                                        <Button disabled={!updatedButton} variant="secondary" style={{

                                                        }} onClick={() => {
                                                            setDepartment({
                                                                ...department,
                                                                id: 0,
                                                                division_id: divisionId,
                                                                department_name: "",
                                                                department_code: "",
                                                                department_description: ""
                                                            })
                                                            setDepartmentModal(true);
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><FilePlusFill size={32} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 10,
                                                                    fontWeight: "bold",
                                                                    fontSize: 18,
                                                                }}>{t("detail_division.add_button")}</div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                }
                                                childContent={
                                                    <div className="master-table-inner-container">
                                                        <div className="master-table-searchbar-container">
                                                            <div className="master-table-searchbar-textbox">
                                                                <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_division.search_box")}`}
                                                                    onKeyPress={async (e) => {
                                                                        if (e.charCode === 13) {
                                                                            setPage(0)
                                                                            setLoading(true);
                                                                            await loadDepartment();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setSearchQuery(e.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="master-table-searchbar-button">
                                                                <div style={{ paddingRight: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setPage(0)
                                                                        setLoading(true);
                                                                        await loadDepartment();
                                                                    }}><Search /></Button>
                                                                </div>
                                                                <div style={{ paddingLeft: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setLoading(true);
                                                                        setSearchQuery("");
                                                                        // await loadDepartment();
                                                                        setResetSearch(1);
                                                                    }}><ArrowClockwise /></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-container">
                                                            <div className="table-header">
                                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_division.table_name1")}</div>
                                                                <div style={{ flex: 2 }} className="table-header-content">{t("detail_division.table_name2")}</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_division.table_name3")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_division.table_name4")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_division.table_name5")}</div>
                                                            </div>
                                                            {
                                                                listDepartment.map((departments, index) => {
                                                                    return (<div key={index} className="table-body">
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{departments.department_name}</p></div>
                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{departments.department_code}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{departments.department_description}</p></div>
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button  variant="primary" onClick={() => {
                                                                                    setDepartmentId(departments.id);
                                                                                }}><PencilFill /></Button>
                                                                            </div></div>

                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={!deletedButton} variant="danger"
                                                                                    onClick={() => {
                                                                                        if (window.confirm(`${t("detail_division.delete_confirmation")} ${department.department_name}?`))
                                                                                            setRemoveId(departments.id);
                                                                                    }}
                                                                                ><Trash /></Button>
                                                                            </div></div>
                                                                    </div>)
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                        <div style={{
                                                            paddingTop: 20,
                                                        }}>

                                                        </div>
                                                        <Paginations
                                                            itemPerPage={itemPerPage}
                                                            totalPage={totalPage}
                                                            page={page}
                                                            setPage={setPage}
                                                            setItemPerPage={setItemPerPage}
                                                            itemPerPageSelection={itemPerPageSelection}
                                                            orderBy={orderBy}
                                                            setOrderBy={setOrderBy}
                                                            orderBySelection={orderByList}
                                                            isDescActive={descending}
                                                            setIsDescActive={setDescending}
                                                            setIsSearched={setIsSearched}
                                                        />
                                                        <Loading
                                                            loading={loading}
                                                        />
                                                    </div>
                                                } />
                                        }


                                    </div>
                                </div>

                            </div>

                            <ButtonForm addData={() => {
                                saveData();
                            }}
                            saveButton={`${t("detail_division.save_button")}`}
                            cancelButton={`${t("detail_division.cancel_button")}`} 
                            path="/Division" button={disabledButton === true ? disabledButton : !updatedButton} />
                        </div>

                    }>

                </ContainerBox>

                <Modal show={departmentModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setDepartmentModal(false);
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("detail_division.container_add_department")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButtonModal(true);
                            saveDepartment();
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_division.field_department_name")}</Form.Label>
                                <Form.Control value={department.department_name} onChange={(e) => {
                                    setDepartment({ ...department, department_name: e.target.value });
                                }} required></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">

                                <Form.Label>{t("detail_division.field_department_code")}</Form.Label>
                                <Form.Control value={department.department_code} onChange={(e) => {
                                    setDepartment({ ...department, department_code: e.target.value });
                                }} required></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_division.field_department_description")}</Form.Label>
                                <Form.Control as={"textarea"} rows={3} value={department.department_description} onChange={(e) => {
                                    setDepartment({ ...department, department_description: e.target.value });
                                }} ></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{width:100}} type="submit" disabled={disabledButtonModal === true ? disabledButtonModal: !updatedButton}>{t("detail_division.save_button")}</Button>
                                <Button  style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    // clearInventoryTypeModal();
                                    setDepartmentModal(false);
                                }}>{t("detail_division.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}