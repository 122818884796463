import "./index.css";
import "../../../SMSApp.css";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar.js";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar.js";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar.js";
import WidthButton from "../../../Components/WidthButton/WidthButton";
import PictWheelHouse from "../../../Assets/Images/wheelHouse.png";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading.js";
import Navbar from "../../../Components/NavBar";
import { Button } from "react-bootstrap";


export default function WheelHouse() {
    const navigate = useNavigate();
    const navigateMainDeck = () => { navigate("/FireAlarm/MainDeck") };
    const navigateBelowMainDeck = () => { navigate("/FireAlarm/BelowMainDeck") };
    const [loading, setLoading] = useState(true);
    // const wheelhouseBitlamp = [{
    //     text: "Bridge 1",
    //     alarm: true
    // },
    // {
    //     text: "Bridge 2",
    //     alarm: false
    // },
    // {
    //     text: "Manual Call Point Wheel House",
    //     alarm: true
    // }]
    const [cookies, setCookie] = useCookies(["token"]);
    const location = useLocation();
    const [wheelhouseBitlamp, setWheelHouseBitlamp] = useState([])
    // const [wheelhouseBitlamp, setWheelHouseBitlamp] = useState([
    //     { text: "Bridge1", alarm: false, coordinate: "M32" },
    //     { text: "Bridge2", alarm: true, coordinate: "M34" },
    //     { text: "Manual Call Point Wheel House", alarm: false, coordinate: "M52" },
    // ])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (wheelhouseBitlamp.length > 0) {
            const interval = setInterval(async () => {
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval)
        }

    }, [wheelhouseBitlamp]);

    // const loadBitlampValue = async()=>{
    //     try {
    //         // let wheelhouseBitlampTes = wheelhouseBitlamp;
    //         // wheelhouseBitlamp[0].alarm = !wheelhouseBitlamp[0].alarm
    //         // setWheelHouseBitlamp(wheelhouseBitlampTes.slice(0))
    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadBitlampValue = async () => {
        try {
            let coordinate = wheelhouseBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = wheelhouseBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setWheelHouseBitlamp(updatedList);

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "fire_alarm", cookies.vesselId);
            let newLisst = response.filter(p => p.sub_coordinate_group === "wheel_house")
            setWheelHouseBitlamp(newLisst);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    return (
        <>
           <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Fire Alarm - Wheel House'}/>
                    <BottomNavBar/>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm")}}>Events Alarm</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm/Logs")}}>Alarm Logs</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/MainDeck")}}>Main Deck</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/BelowMainDeck")}}>Below Main Deck</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/WheelHouse")}}>Wheel House</Button>
                    <div className="containerImgWH">
                        <div className="containerImgWH">
                            <img src={PictWheelHouse} alt="" />
                        </div>
                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '400px', height: '500px', padding: '5%'}}>
                                <BitLamp text={'Bridge 1'} bitlampColor = "black"/>
                                <BitLamp text={'Bridge 2'} bitlampColor = "black"/>
                                <BitLamp text={'Manual Call Point Wheel House'} bitlampColor = "black"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
    );
}