import "../App.css";
import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { Form, option, Button, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Select from 'react-select';
import { Trash, FilePlusFill, PencilFill, XSquareFill, CameraFill, Image, PeopleFill, PersonPlusFill, PersonLinesFill } from "react-bootstrap-icons";
import {
    isTokenValid, getVesselEquipmentByEquipmentCategory, convertBase64, convertBase64Image, getManufacturer, getEquipmentPosition,
    insertUpdateVesselxEquipment
} from "../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import ContainerBox from "./ContainerBox";
import { Trans, useTranslation } from "react-i18next";

function NewTroubleshootingListJob({
    index,
    listJob,
    listEquipment,
    troubleshootingId,
    troubleshooting,
    handleOnBlur,
    handleDelete,
    handleDeleteTroubleshootingJob,
    resetComponentProps,
    timePeriode,
    // setDisableAddDetail,
    disabledAddDetail,
}) {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listEquipmentNew, setListEquipmentNew] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [indexJobDescription, setIndexJobDescription] = useState("");
    const [indexJobProcess, setIndexJobProcess] = useState("");
    const [indexJobConclusion, setIndexJobConclusion] = useState("");
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [indexJobRecomendation, setIndexJobRecomendation] = useState("");
    const [descriptionModal, setDescriptionModal] = useState("");
    const [processModal, setProcessModal] = useState("");
    const [conclusionModal, setConclusionModal] = useState("");
    const [jobNameError, setJobNameError] = useState("");
    const [recomendationModal, setRecomendationModal] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [fotoModal, setFotoModal] = useState("");
    const [timePeriodeNew, setTimePeriodeNew] = useState({});
    const [listJobNew, setListJobNew] = useState([]);
    const [troubleshootingNew, setTroubleshootingNew] = useState({});
    const [addJobButton, setAddJobButton] = useState(false);
    const [resetComponent, setResetComponent] = useState(false);
    const [indexFotoModal, setIndexFotoModal] = useState("");
    const options = {
        wordwrap: 130
    }

    useEffect(() => {
        resetComponentProps && setResetComponent(false);
    }, [resetComponent]);

    useEffect(() => {
        if (timePeriode) {
            setTimePeriodeNew(timePeriode);
        }
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
          } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
          }
    }, []);

    useEffect(() => {
        if (timePeriode) {
            setTimePeriodeNew(timePeriode)
        }

        if (listJob) {
            setListJobNew(listJob);
        }
        if (listEquipment) {
            setListEquipmentNew(listEquipment.slice(0));
        }
        if (troubleshooting) {
            setTroubleshootingNew(troubleshooting);
        }


    }, [timePeriode])

    useEffect(() => {
        if (timePeriodeNew.start_date !== null) {
            setStartDate(timePeriodeNew.start_date)
        }
        if (timePeriodeNew.end_date !== null) {
            setEndDate(timePeriodeNew.end_date);
        }
    }, [timePeriodeNew])

    useEffect(() => {
        if (startDate) {
            let timePeriodeTmp = timePeriodeNew;
            timePeriodeTmp.start_date = startDate;
            setTimePeriodeNew(timePeriodeTmp);
            handleOnBlur(timePeriodeTmp, listJobNew, index);
        }
    }, [startDate])

    useEffect(() => {
        if (endDate) {
            let timePeriodeTmp = timePeriodeNew;
            timePeriodeTmp.end_date = endDate;
            setTimePeriodeNew(timePeriodeTmp);
            handleOnBlur(timePeriodeTmp, listJobNew, index);
        }
    }, [endDate])

    useEffect(() => {
        if (indexJobDescription !== "") {
            setDescriptionModal(true);
        }
    }, [indexJobDescription]);

    useEffect(() => {
        if (indexJobProcess !== "") {
            setProcessModal(true);
        }
    }, [indexJobProcess]);

    useEffect(() => {
        if (indexJobConclusion !== "") {
            setConclusionModal(true);
        }
    }, [indexJobConclusion]);

    useEffect(() => {
        if (indexJobRecomendation !== "") {
            setRecomendationModal(true);
        }
    }, [indexJobRecomendation]);

    useEffect(() => {
        if (indexFotoModal !== "") {
            setFotoModal(true);
        }
    }, [indexFotoModal]);




    useEffect(() => {
        if (startDate === null || startDate === undefined) {
            setAddJobButton(true);
            disabledAddDetail(true);
            setStartDateError(`${t("detail_troubleshooting.error_empty_field")}`);
        } else {
            setStartDateError("");
        }

        if (endDate === null || endDate === undefined) {
            disabledAddDetail(true)
            setEndDateError(`${t("detail_troubleshooting.error_empty_field")}`);
        } else {
            setAddJobButton(false);
            disabledAddDetail(false)
            setEndDateError("")
        }

      
    }, [startDate, endDate])
    


    useEffect(() => {
        // console.log(listJobNew);
        if(listJobNew.length){
            for (let index = 0; index < listJobNew.length; index++) {
                const listJobTmp = listJobNew[index];
                if (listJobTmp.job_name === "") {
                    setJobNameError(`${t("detail_troubleshooting.error_job_name")}`)
                    disabledAddDetail(true);
                    setAddJobButton(true);
                } else {
                    setJobNameError("");
                    disabledAddDetail(false);
                    setAddJobButton(false);
                }
            }
        }else{
            setJobNameError("");
            disabledAddDetail(false);
            setAddJobButton(false);
        }
       
    }, [listJobNew])


   



    return (
        <div >
            <div style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                flexWrap: "nowrap",
                width: "100%"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 30,
                    flexWrap: "nowrap",
                    width: "100%"
                }}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header style={{
                                // borderStyle: "solid",
                                // borderWidth: 8,
                                borderRadius: 5,
                            }}>



                            </Accordion.Header>
                            <Accordion.Body style={{
                                // backgroundColor: `${troubleshootingEquipmentNew.background_color}`
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            paddingRight: 5
                                        }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{t("detail_troubleshooting.field_start_time")}</Form.Label>
                                                <Form.Control required type="datetime-local" value={startDate ? moment(startDate).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setStartDate(null);
                                                        setEndDate(null);
                                                    } else {
                                                        setStartDate(e.target.value);
                                                    }

                                                }}></Form.Control>
                                            </Form.Group>
                                            {
                                                startDateError && <p style={{ color: "red" }}>{startDateError}</p>
                                            }
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            paddingLeft: 5
                                        }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{t("detail_troubleshooting.field_end_time")}</Form.Label>
                                                <Form.Control disabled={!startDate} required type="datetime-local" value={endDate ? moment(endDate).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setEndDate(null)
                                                    } else {
                                                        if (moment(e.target.value).format("yyyy-MM-DD HH:mm") < moment(startDate).format("yyyy-MM-DD HH:mm")) {
                                                            setEndDateError(`${t("detail_troubleshooting.error_end_date")}`);
                                                            setEndDate(null);
                                                        } else {
                                                            setEndDateError("")
                                                            setEndDate(e.target.value);
                                                        }

                                                    }

                                                }}></Form.Control>
                                            </Form.Group>
                                            {
                                                endDateError && <p style={{ color: "red" }}>{endDateError}</p>
                                            }
                                        </div>
                                    </div>

                                    {listJobNew &&
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }}>

                                            {
                                                listJobNew.map((jobs, idx) => {
                                                    return (

                                                        <div key={idx} style={{ padding: 5 }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                padding: 5,
                                                                borderWidth: 2,
                                                                borderRadius: 5,
                                                                borderStyle: "solid",
                                                                borderColor: "rgba(3, 30, 103, 1)"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        flex: 1,
                                                                        paddingTop: 5,
                                                                        textAlign: "center"
                                                                    }}>
                                                                        {idx + 1}
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 25,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_job_name")}</Form.Label>
                                                                        <Form.Control required onBlur={e => handleOnBlur(timePeriodeNew, listJobNew, index)} value={jobs.job_name} onChange={(e) => {

                                                                            let detailJobs = listJobNew;
                                                                            let orderNumber = ""
                                                                            if (idx < 1) {
                                                                                orderNumber = idx + 1
                                                                            } else {
                                                                                orderNumber = Number(detailJobs[idx - 1].order_number) + 1
                                                                            }

                                                                            detailJobs[idx].job_name = e.target.value
                                                                            detailJobs[idx].order_number = orderNumber
                                                                            setListJobNew(detailJobs.slice(0));


                                                                        }} placeholder=""></Form.Control>

                                                                    </div>
                                                                  
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Button onClick={() => {
                                                                            setIndexFotoModal(idx)
                                                                            let newParameter = {

                                                                            };

                                                                            if (!jobs.list_photo) {
                                                                                let detailJobs = listJobNew;
                                                                                detailJobs[idx].list_photo = []


                                                                                setListJobNew(detailJobs.slice(0))
                                                                            } else {

                                                                            }

                                                                        }} style={{ maxWidth: 50 }} variant="primary"><CameraFill /></Button>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Link to={`/NewTroubleshooting/Photo?id=${jobs.id}&troubleshootingId=${jobs.troubleshooting_id}`} style={{
                                                                            pointerEvents: troubleshootingId === 0 || jobs.id === undefined ? "none" : ""
                                                                        }} target="_blank">
                                                                            <Button disabled={troubleshootingId === 0 || jobs.id === undefined} onClick={() => {

                                                                            }} style={{ maxWidth: 50 }} variant="primary"><Image /></Button>
                                                                        </Link>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Button onClick={() => {
                                                                            if (window.confirm(`${t("detail_troubleshooting.delete_confirmation")}`)) {
                                                                                let newList = timePeriodeNew
                                                                                if (!troubleshootingNew.deleted_job) {
                                                                                    let list = [];
                                                                                    let newList = listJobNew
                                                                                    list.push(newList[idx])
                                                                                    newList.splice(idx, 1);


                                                                                    let troubleshootingTmp = troubleshootingNew;
                                                                                    troubleshootingTmp.deleted_job = list;

                                                                                    setTroubleshootingNew(troubleshootingTmp);
                                                                                    setListJobNew(newList.slice(0));
                                                                                    handleDeleteTroubleshootingJob(troubleshootingTmp, index);
                                                                                } else {

                                                                                    let newList = listJobNew;
                                                                                    let listDeleted = troubleshootingNew
                                                                                    listDeleted.deleted_job.push(newList[idx]);
                                                                                    newList.splice(idx, 1)
                                                                                    setTroubleshootingNew(listDeleted);
                                                                                    handleDeleteTroubleshootingJob(listDeleted, index)
                                                                                    // setServiceJob({ ...troubleshooting, deleted_job: listDeleted });
                                                                                    setListJobNew(newList.slice(0));
                                                                                }

                                                                                handleOnBlur(timePeriodeNew, listJobNew, index);

                                                                            }

                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                    </div>

                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                }}>

                                                                
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_equipment_category")}</Form.Label>
                                                                        <Select placeholder={""}
                                                                            getOptionLabel={(item) => {
                                                                                return item.equipment_name;
                                                                            }} clearValue={true}
                                                                            getOptionValue={(item) => {
                                                                                return item.id;
                                                                            }}
                                                                            options={listEquipmentNew} value={listEquipmentNew.filter(function (option) {
                                                                                return option.id === jobs.equipment_id;
                                                                            })} onChange={(e) => {
                                                                                let detailJobs = listJobNew;
                                                                                if (e === null) {
                                                                                    detailJobs[idx].equipment_id = null
                                                                                    // detailJobs[idx].vessel_equipment_id = null

                                                                                    // setIndexEquipment("");
                                                                                    // setListVesselEquipmentNew([]);
                                                                                    // loadVesselEquipment();
                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                } else {
                                                                                    // setIndexEquipment(idx);
                                                                                    // loadVesselEquipment();
                                                                                    detailJobs[idx].equipment_id = e.id;

                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                }

                                                                                handleOnBlur(timePeriodeNew, listJobNew, index)
                                                                            }}
                                                                            isClearable
                                                                            required
                                                                        />


                                                                    </div>
                                                                    {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>Peralatan Kapal</Form.Label>
                                                                        <Select placeholder={""}
                                                                             getOptionLabel={(item) => {
                                                                                if (item.id) {
                                                                                    return (item.manufacturer_name ? item.manufacturer_name : " ") + ' - ' + item.model + `(${item.serial_number ? item.serial_number : ""})`
                                                                                } else {
                                                                                    return ("");
                                                                                }
                                                                            }}clearValue={true}
                                                                            getOptionValue={(item) => {
                                                                                return item.id;
                                                                            }}
                                                                            options={listVesselEquipmentNew} value={listVesselEquipmentNew.filter(function (option) {
                                                                                return option.id === jobs.vessel_equipment_id;
                                                                            })} onChange={(e) => {
                                                                                let detailJobs = listJobNew;
                                                                                if (e === null) {
                                                                                    detailJobs[idx].vessel_equipment_id = null
                                                                                
                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                } else {
                                                                                   
                                                                                    detailJobs[idx].vessel_equipment_id = e.id;
                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                }

                                                                                handleOnBlur(timePeriodeNew, listJobNew, index)
                                                                            }}
                                                                            isClearable
                                                                            required
                                                                        />

                                                                    </div> */}

                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                }}>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_description")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.description ? convert(jobs.description, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobDescription(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_process_job")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.job_process ? convert(jobs.job_process, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobProcess(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        // width: "100%",

                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 1
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_start_date")}</Form.Label>
                                                                        <Form.Control style={{
                                                                            width: 200
                                                                        }} type="datetime-local" value={jobs.job_start_date ? moment(jobs.job_start_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                            let detailJobs = listJobNew;
                                                                            if (e.target.value === "") {
                                                                                detailJobs[idx].job_start_date = null;
                                                                            } else {
                                                                                detailJobs[idx].job_start_date = e.target.value;
                                                                            }

                                                                            setListJobNew(detailJobs.slice(0))
                                                                        }}></Form.Control>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        // width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 1
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_end_date")}</Form.Label>
                                                                        <Form.Control style={{
                                                                            width: 200
                                                                        }} type="datetime-local" value={jobs.job_end_date ? moment(jobs.job_end_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                            let detailJobs = listJobNew;
                                                                            if (e.target.value === "") {
                                                                                detailJobs[idx].job_end_date = null;
                                                                            } else {
                                                                                detailJobs[idx].job_end_date = e.target.value;
                                                                            }

                                                                            setListJobNew(detailJobs.slice(0))
                                                                        }}></Form.Control>

                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_conclusion")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.job_conclusion ? convert(jobs.job_conclusion, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobConclusion(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_troubleshooting.field_recommendation")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.job_recommendation ? convert(jobs.job_recommendation, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobRecomendation(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                </div>

                                                           

                                                            </div>

                                                        </div>



                                                    )
                                                })
                                            }
                                            {
                                                jobNameError && <p style={{ color: "red" }}>{jobNameError}</p>
                                            }
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",

                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    padding: 5,

                                                }}>
                                                    <Button disabled={addJobButton} variant="primary" onClick={() => {
                                                        let newParameter = {
                                                            job_name: "",
                                                            equipment_id: null,
                                                            // vessel_equipment_id: null,
                                                            job_start_date: null,
                                                            job_end_date: null,
                                                        };
                                                        if (!listJobNew) {
                                                            // let newJobs = []
                                                            let newList = []
                                                            // newList = newJobs;
                                                            newList.push(newParameter);
                                                            setListJobNew(newList.slice(0))
                                                        } else {
                                                            let newList = listJobNew
                                                            newList.push(newParameter);
                                                            // newList.push(newParameter);
                                                            setListJobNew(newList.slice(0))
                                                        }
                                     

                                                    }}><FilePlusFill size={30} /></Button>
                                                </div>

                                            </div>


                                        </div>}
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button variant="danger" onClick={() => {
                                            if (window.confirm(`${t("detail_troubleshooting.delete_confirmation")}`)) {
                                                handleDelete(index)
                                            }


                                        }}><Trash size={30} /></Button>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>


            <div style={{ paddingBottom: 10 }}></div>
            <Modal size="xl" show={descriptionModal} onHide={
                () => {
                    setDescriptionModal(false);
                    setIndexJobDescription("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner"  titleCaption={`${t("detail_troubleshooting.modal_description")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setDescriptionModal(false);
                                setIndexJobDescription("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_troubleshooting.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobDescription !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobDescription].description = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobDescription].description}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={processModal} onHide={
                () => {
                    setProcessModal(false);
                    setIndexJobProcess("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner"  titleCaption={`${t("detail_troubleshooting.modal_process")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setProcessModal(false);
                                setIndexJobProcess("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_troubleshooting.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                 

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobProcess !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobProcess].job_process = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobProcess].job_process}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={conclusionModal} onHide={
                () => {
                    setConclusionModal(false);
                    setIndexJobConclusion("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_troubleshooting.modal_conclusion")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setConclusionModal(false);
                                setIndexJobConclusion("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_troubleshooting.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                   

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobConclusion !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobConclusion].job_conclusion = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobConclusion].job_conclusion}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={recomendationModal} onHide={
                () => {
                    setRecomendationModal(false);
                    setIndexJobRecomendation("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_troubleshooting.modal_recommendation")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setRecomendationModal(false);
                                setIndexJobRecomendation("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_troubleshooting.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobRecomendation !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobRecomendation].job_recommendation = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobRecomendation].job_recommendation}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={fotoModal} onHide={
                () => {
                    setFotoModal(false);
                    setIndexFotoModal("");
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_troubleshooting.modal_staff_photo")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setFotoModal(false);
                                setIndexFotoModal("");

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_troubleshooting.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>


                                <Form >
                                    {
                                        indexFotoModal !== "" && listJobNew[indexFotoModal].list_photo.map((foto, index) => {
                                            return (
                                                <div>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        borderWidth: 2,
                                                        borderRadius: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "rgba(3, 30, 103, 1)",
                                                        padding: 10
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <img src={foto.base64_image}
                                                                style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_troubleshooting.field_foto")}</Form.Label>
                                                                <Form.Control required type="file" onChange={async (e) => {

                                                                    let base64Img = await convertBase64Image(e.target.files[0]);
                                                                    const base64ImgString = base64Img.toString();
                                                                    let detailJobs = listJobNew;
                                                                    detailJobs[indexFotoModal].list_photo[index].base64_image = base64ImgString;
                                                                    setListJobNew(detailJobs.slice(0));

                                                                }}></Form.Control>
                                                            </Form.Group>

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            flex: 1

                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingRight: 5
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_troubleshooting.field_label")}</Form.Label>
                                                                    <Form.Control required type="text" onChange={(e) => {
                                                                        let detailJobs = listJobNew;
                                                                        detailJobs[indexFotoModal].list_photo[index].label = e.target.value;
                                                                        setListJobNew(detailJobs.slice(0))
                                                                    }} value={foto.label}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingLeft: 5

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_troubleshooting.field_taken_date")}</Form.Label>
                                                                    <Form.Control type="date" onChange={(e) => {
                                                                        let detailJobs = listJobNew;
                                                                        detailJobs[indexFotoModal].list_photo[index].taken_date = e.target.value;
                                                                        setListJobNew(detailJobs.slice(0))

                                                                    }} value={foto.taken_date ? moment(foto.taken_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                </Form.Group>
                                                            </div>


                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_troubleshooting.field_explanation")}</Form.Label>
                                                                <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                    let detailJobs = listJobNew;
                                                                    detailJobs[indexFotoModal].list_photo[index].description = e.target.value;
                                                                    setListJobNew(detailJobs.slice(0))
                                                                }} value={foto.description}></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            flex: 1,
                                                            paddingLeft: 5,
                                                            paddingRight: 5
                                                        }}>
                                                            <div style={{ paddingTop: 35 }}></div>
                                                            <Button onClick={() => {
                                                                if (window.confirm(`${t("detail_troubleshooting.delete_confirmation")}`)) {
                                                                    let detailJobs = listJobNew;
                                                                    detailJobs[indexFotoModal].list_photo.splice(index, 1);
                                                                    setListJobNew(detailJobs.slice(0))
                                                                }

                                                            }} variant="danger"><Trash size={20} /></Button>

                                                        </div>
                                                        <div style={{ paddingBottom: 10 }}></div>
                                                    </div>
                                                    <div style={{ paddingBottom: 10 }}></div>
                                                </div>



                                            )
                                        })
                                    }
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button variant="primary" onClick={() => {
                                            let newParameter = {
                                                base64_image: "",
                                                label: "",
                                                taken_date: null,
                                                description: ""
                                            };
                                            if (!listJobNew[indexFotoModal].list_photo) {
                                                let newList = listJobNew
                                                newList[indexFotoModal].list_photo.push(newParameter);
                                                setListJobNew(newList.slice(0))
                                            } else {
                                                let newList = listJobNew
                                                newList[indexFotoModal].list_photo.push(newParameter);
                                                setListJobNew(newList.slice(0))
                                            }

                                        }}>{t("detail_troubleshooting.button_add_photo")}</Button>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            paddingRight: 5,
                                        }}><Button onClick={() => {
                                            setFotoModal(false);
                                            setIndexFotoModal("")
                                            handleOnBlur(timePeriodeNew, listJobNew, index);
                                        }} variant="primary" >{t("detail_troubleshooting.save_button")}</Button></div>


                                        <div style={{
                                            paddingLeft: 5,
                                        }}>
                                            <Button variant="danger" onClick={() => {
                                                setFotoModal(false);
                                                setIndexFotoModal("")

                                            }}>{t("detail_troubleshooting.cancel_button")}</Button>

                                        </div>
                                    </div>
                                </Form>




                            </div>






                        </div>

                    }>
                </ContainerBox>
            </Modal>

        </div>
    )
}



export default NewTroubleshootingListJob;
