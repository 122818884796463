import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getCrewRank, deleteCrewRank, getCountCrewRank, isTokenValid, insertUpdateCrewRank, getCrewRankById,
    getUserAccessById, getCrewType
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";

export default function CrewRankPage() {
    const { t, i18n } = useTranslation();
    const [listCrewRank, setListCrewRank] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listType, setListType] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [crewRankId, setCrewRankId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [crewRankModal, setCrewRankModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_crew_rank.order_name1")}`,
        value: "rank_name"
    });
    const orderByList = [{
        label: `${t("index_crew_rank.order_name1")}`,
        value: "rank_name"
    },];
    const [descending, setDescending] = useState(false);
    const [crewRank, setCrewRank] = useState({
        id: "0",
        rank_name: "",
        rank_desc: "",
        crew_type_id: ""
        // ext_customer_id: cookies.extCustomerId
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadCrewRank();
                loadAccess();
                loadCrewType();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadCrewRank();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewRank();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewRank();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadCrewRank();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewRank()
    }, [descending]);

    useEffect(() => {
        if (crewRankId !== "" && crewRankId !== undefined)
            initCrewRank();
    }, [crewRankId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeCrewRank();
    }, [removeId])

    useEffect(() => {
        if (!crewRankModal)
            setCrewRankId("");
    }, [crewRankModal])


    const loadCrewRank = async () => {
        try {
            let count = await getCountCrewRank(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getCrewRank(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListCrewRank(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }

    }

    const clearCrewRankModal = async () => {
        let crewRankTmp = {};
        crewRankTmp.id = 0;
        crewRankTmp.rank_name = "";
        crewRankTmp.rank_desc = "";
        setCrewRank(crewRankTmp);
    }

    const saveData = async () => {
        try {
            // let crewRankTmp = crewRank;
            // crewRankTmp.ext_customer_id = crewRankTmp.ext_customer_id === null ? null : crewRankTmp.ext_customer_id
            let response = await insertUpdateCrewRank(cookies.token, crewRank);
            if (response === 0) {
                alert(`${t("index_crew_rank.alert_success_save_data")}`);
                setCrewRankModal(false);
                clearCrewRankModal();
                loadCrewRank();

            } else {
                alert(`${t("index_crew_rank.alert_failed_save_data")}`)
            }
            setDisabledButton(false);

        } catch (exception) {
            console.log(exception)
        }
    }

    const initCrewRank = async () => {
        try {
            let response = await getCrewRankById(cookies.token, crewRankId);
            if (response) {
                setCrewRank({
                    ...crewRank,
                    id: response.id,
                    rank_name: response.rank_name,
                    rank_desc: response.rank_desc,
                    crew_type_id: response.crew_type_id
                })
            }
            setCrewRankModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeCrewRank = async () => {
        try {
            let response = await deleteCrewRank(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_crew_rank.delete_success_alert")}`);
                loadCrewRank();
            } else {
                alert(`${t("index_crew_rank.delete_failed_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewType = async () => {
        try {
            let response = await getCrewType(cookies.token)
            setListType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_crew_rank.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setCrewRankModal(true);
                                setCrewRank({ ...crewRank, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_crew_rank.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_crew_rank.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadCrewRank();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadCrewRank();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadCrewRank();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_crew_rank.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_crew_rank.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_crew_rank.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_crew_rank.table_name4")}</div>
                                </div>
                                {
                                    listCrewRank.map((crews, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crews.rank_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crews.rank_desc}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idRank = crews.id;
                                                        setCrewRankId(idRank);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        let idRank = crews.id;
                                                        if (window.confirm(`${t("index_crew_rank.delete_confirmation")}  ${crews.rank_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idRank);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                <Modal show={crewRankModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setCrewRankModal(false);
                        clearCrewRankModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_crew_rank.modal_add_rank")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_crew_rank.field_rank_name")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setCrewRank({ ...crewRank, rank_name: e.target.value })
                                }} value={crewRank.rank_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_crew_rank.field_crew_type")}</Form.Label>
                                <Form.Select required onChange={(e) => {
                                    setCrewRank({ ...crewRank, crew_type_id: e.target.value })
                                }} value={crewRank.crew_type_id} >
                                    <option selected value="">

                                    </option>
                                    {listType.map((type, index) => (
                                        <option key={index} value={type.id}>{type.crew_type_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_crew_rank.field_description")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setCrewRank({ ...crewRank, rank_desc: e.target.value })
                                }} value={crewRank.rank_desc} as="textarea" rows={3} placeholder="" ></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{width:100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_crew_rank.save_button")}</Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    clearCrewRankModal();
                                    setCrewRankModal(false);
                                }}>{t("index_crew_rank.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}