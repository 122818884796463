import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Modals = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
  image,
  description,
  setDescription
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
    <>

      <div className="overlay dismiss" onClick={handleClick}>
        <img style={{
          maxWidth: 1200,
          maxHeight: 500,
          objectFit: "contain",
        }} src={clickedImg} alt="bigger pic" />
        {/* <Carousel infiniteLoop>
          {
            image.map((vessel, index) => {
              return (
                <>
                  <img src={vessel.base64_image}  style={{height:"50%", width:"40%"}}/>
                </>

              )

            })
          }

        </Carousel> */}

        {/* <Carousel autoPlay interval="5000" transitionTime="5000" infiniteLoop>
            <div>
            <img src={image}/>
            </div>

          </Carousel> */}

          
        <span className="dismiss" onClick={handleClick}>
          X
        </span>
        <div onClick={handelRotationLeft} className="overlay-arrows_left">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div onClick={handelRotationRight} className="overlay-arrows_right">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>

      </div>
      {/* <div className="overlayDesc" >{description} </div> */}
    </>
  );
};

export default Modals;