import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal, Nav } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getIncomingInvoiceDocumentById, insertUpdateIncomingInvoice,
    getIncomingInvoice, convertBase64, deleteIncomingInvoice, getCountIncomingInvoice,
    getUserAccessById, getIncomingInvoiceById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Paginations from "../../../Components/Pagination";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill, XSquareFill, PencilFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise
} from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../Helpers/Base64Downloader";
import moment from 'moment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function IncomingInvoicePage() {
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [resetSearch,setResetSearch] = useState(0);
    const [deletedButton, setDeletedButton] = useState("");
    const [uploadFIleImageError, setUploadFileImageError] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [downloadId, setDownloadId] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [invoiceDocumentId, setInvoiceDocumentId] = useState("");
    const [updateDocumentId, setUpdateDocumentId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(0.4);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Dokumen",
        value: "document_name"
    });
    const orderByList = [{
        label: "Nama Dokumen",
        value: "document_name"
    }];
    const [newDocument, setNewDocument] = useState({
        id: 0,
        base64_document: "",
        document_name: "",
        description: "",
        is_active: true,
        done: false,
        invoice_date: "",
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        base64_document: "",
        document_name: "",
        description: "",
        invoice_date: "",
        is_active: true,
        // document_category_id: null
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            base64_document: "",
            document_name: "",
            description: "",
            invoice_date: "",
            is_active: true,
            done: false
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    useEffect(() => {
        loadDocumentData();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0);
        loadDocumentData();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0);
        loadDocumentData();
    }, [orderBy]);

    useEffect(() => {
        if (invoiceDocumentId !== "")
            loadDocumentById()
    }, [invoiceDocumentId]);

    useEffect(() => {
        if (downloadId !== "")
            downloadData();
    }, [downloadId])

   
    useEffect(() => {
        if (updateDocumentId !== "")
            initDocument();
    }, [updateDocumentId])

    useEffect(() => {
        if(isSearched !== 0);
        loadDocumentData()
    }, [descending]);


    useEffect(()=>{
        if(resetSearch !== 0)
        loadDocumentData();
    },[resetSearch])

    const [documentFile, setDocumentFile] = useState("");



    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }


    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        setLoading(true);
        if (documentId !== "")
            removeDocument();
    }, [documentId]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                base64_document: "",
                document_name: "",
                description: "",
                invoice_date: "",
                is_active: true,
            });
            setInvoiceDocumentId("")
        }
    }, [showDocumentDetailModal]);

    useEffect(() => {
        if (!showDocumentUploadModal) {
            // setDocumentToBeViewed({
            //     id: 0,
            //     document_base64: "",
            //     document_name: "",
            //     description: "",
            //     reference_number: "",
            //     is_active: true,
            // });
            setUpdateDocumentId("")
        }
    }, [showDocumentUploadModal]);



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadDocumentData();
                loadAccess();
            }
        }
        checkCookies();
    }, []);


    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateIncomingInvoice(cookies.token, newDocument);

                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                setDisabledButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const loadDocumentData = async () => {
        try {
            let count = await getCountIncomingInvoice(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getIncomingInvoice(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListDocument(response);
            setLoading(false);
            setIsSearched(0);
            setResetSearch(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteIncomingInvoice(cookies.token, documentId);
        if (response === 0) {
            loadDocumentData();
        } else {
            alert("Terjadi kesalahan sistem, mohon hubungi administrator.");
            setLoading(false);
        }
    }

    const loadDocumentById = async () => {
        try {
            let response = await getIncomingInvoiceDocumentById(cookies.token, invoiceDocumentId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await getIncomingInvoiceDocumentById(cookies.token, downloadId);
            triggerBase64Download(response.base64_document, response.document_name);
            setLoading(false);
            setDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const initDocument = async () => {
        try {
            let response = await getIncomingInvoiceById(cookies.token, updateDocumentId);
            if (response) {
                setNewDocument({
                    ...newDocument,
                    id: response.id,
                    document_name: response.document_name,
                    description: response.description,
                    invoice_date: response.invoice_date,
                })
                setShowDocumentUploadModal(true);
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Invoice Masuk"
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Dokumen</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cara (Nama Dokumen)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadDocumentData();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadDocumentData();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                            // await loadDocumentData();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Nama Dokumen</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Keterangan</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Tanggal Invoice</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Tanggal Upload</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Lihat Dokumen</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Download</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listDocument.map((document, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.description}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.invoice_date).format("DD/MM/yyyy")}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!updatedButton} variant="primary" onClick={() => {

                                                        setUpdateDocumentId(document.id)
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setLoading(true);
                                                        setInvoiceDocumentId(document.id)
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setDownloadId(document.id)
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`Apakah Anda Yakin Menghapus  ${document.document_name}?`)) {
                                                            let documentId = document.id
                                                            setDocumentId(documentId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />



                <Modal show={showDocumentUploadModal}
                    size={"lg"}
                    onHide={() => {
                        resetUploadForm();
                        setShowDocumentUploadModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Upload Dokumen </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            setDisabledButton(true);
                            setNewDocument({ ...newDocument, done: true });
                        }} style={{ padding: 10 }}>

                            {
                                newDocument.id === 0 &&
                                <Form.Group className="mb-3">
                                    <Form.Label>File Dokumen</Form.Label>
                                    <Form.Control onChange={async (e) => {
                                        // if (e.target.files[0].type === "application/pdf") {
                                        setDocumentFile(e.target.value)
                                        let base64Doc = await convertBase64(e.target.files[0]);
                                        setNewDocument({ ...newDocument, base64_document: base64Doc.toString() });
                                        // setUploadFileImageError("");
                                        // setDisabledButton(false)

                                        // } else {
                                        //     setDocumentFile("")
                                        //     setNewDocument({ ...newDocument, document_base64: "" });
                                        //     setUploadFileImageError("Hanya Bisa File Pdf")
                                        //     setDisabledButton(true)
                                        // }

                                    }} type="file" required></Form.Control>
                                    {
                                        uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                    }
                                </Form.Group>
                            }



                            <Form.Group className="mb-3">
                                <Form.Label>Nama Dokumen</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, document_name: e.target.value })
                                }} value={newDocument.document_name} type="text" placeholder="" required></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Tanggal Invoice</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, invoice_date: e.target.value })
                                }} value={moment(newDocument.invoice_date).format("yyyy-MM-DD")} type="date" placeholder="" required></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Deskripsi</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, description: e.target.value })
                                }} value={newDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>

                            </Form.Group>

                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flex: 1,
                            }}>
                                <Button variant="primary" type="submit" disabled={disabledButton}>
                                    Simpan
                                </Button>
                                <Button className="cancel" variant="danger" onClick={() => {
                                    resetUploadForm();
                                    loadDocumentData();
                                    setShowDocumentUploadModal(false);
                                }}>
                                    Batal
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal>

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>

                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowDocumentDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Tutup</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                <div>
                                    <Document
                                        file={documentToBeViewed.base64_document}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        loading={() => {

                                        }}
                                    ><div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                overflow: "scroll",
                                            }}>
                                                <Page scale={zoomFactor} pageNumber={pageNumber} />
                                            </div>
                                        </div>
                                    </Document>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                        <p>
                                            Halaman {pageNumber} dari {numPages}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        flexWrap: "wrap",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor <= 0.2}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 0.8);
                                                }}
                                            >

                                                <ZoomOut size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={false}
                                                onClick={() => {
                                                    setZoomFactor(0.4);
                                                }}
                                            >
                                                <AspectRatioFill size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                            flexWrap: "wrap",
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor >= 10}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 2);
                                                }}
                                            >
                                                <ZoomIn size={28} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                            >

                                                <ChevronDoubleLeft size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                            >
                                                <ChevronDoubleRight size={28} />
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        } />

                </Modal>
            </Container>

        </>
    );

}