import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import SMSNavContainerFireAlarm from "../../../Components/NewSMSNavContainerFireAlarm/NewSMSNavContanerFireAlarm";
import CustomerSMSFireAlarmWheelhouse from "../SMSFireAlarmWheelhouse";
import CustomerSMSFireAlarmMainDeck from "../SMSFireAlarmMainDeck";
import CustomerSMSFireAlarmBelowMainDeck from "../SMSFireAlarmBelowMainDeck";
// import BitLamp from "../../../Components/NewBitLampSquare/NewBitLampSquare";
import BitLamp from "../../../Components/BitLampForFireAlarm/BitLampFireAlarm";
import moment from "moment";

export default function CustomerSMSFireAlarmPage(props) {
    const {smsSerialNumber, vesselId} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [currentChild, setCurrentChild] = useState(null);
    // const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [titleBar, setTitleBar] = useState("");
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([21, 20, 4]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [bitlampList, setBitlampList] = useState({

      M32:
      {
        text: "Bridge 1",
        alarm: true,
        coordinate: "M32",
        value: false
      },
      M34: {
        text: "MCP Wheelhouse 1",
        alarm: true,
        coordinate: "M34",
        value: false
      },
      M36: {
        text: "MCP Wheelhouse 2",
        alarm: true,
        coordinate: "M36",
        value: false
      },
      M50:
      {
        text: "Gang/Way",
        alarm: true,
        coordinate: "M50",
        value: false
      },
      M52: {
        text: "Deck Store",
        alarm: true,
        coordinate: "M52",
        value: false
      },
      M40: {
        text: "Saloon",
        alarm: true,
        coordinate: "M40",
        value: false
      },
      M38: {
        text: "Manual Call Point Laundry",
        alarm: true,
        coordinate: "M38",
        value: false
      },
      M42: {
        text: "Crew Room",
        alarm: true,
        coordinate: "M42",
        value: false
      },
      M44: {
        text: "Captain",
        alarm: true,
        coordinate: "M44",
        value: false
      },
      M46: {
        text: "Chief Engineer",
        alarm: true,
        coordinate: "M46",
        value: false
      },
      M48: {
        text: "Galley Heat",
        alarm: true,
        coordinate: "M48",
        value: false
      },
      M296:
      {
        text: "MCP E/R ENT",
        alarm: true,
        coordinate: "M296",
        value: false
      },
      M298:
      {
        text: "Smoke E/R ENT",
        alarm: true,
        coordinate: "M298",
        value: false
      },
      M300:
      {
        text: "Heat E/R ENT",
        alarm: true,
        coordinate: "M300",
        value: false
      },
      M302:
      {
        text: "Smoke 1 ER",
        alarm: true,
        coordinate: "M302",
        value: false
      },
      M304:
      {
        text: "MCP 1 ER",
        alarm: true,
        coordinate: "M302",
        value: false
      },
      M306:
      {
        text: "Heat 1 65C",
        alarm: true,
        coordinate: "M306",
        value: false
      },
      M308:
      {
        text: "MCP 2 ER",
        alarm: true,
        coordinate: "M308",
        value: false
      },
      M310:
      {
        text: "Smoke 2 ER",
        alarm: true,
        coordinate: "M310",
        value: false
      },
      M312:
      {
        text: "Heat 2 65C",
        alarm: true,
        coordinate: "M312",
        value: false
      },
      M314:
      {
        text: "Smoke Steering Gear",
        alarm: true,
        coordinate: "M314",
        value: false
      },
      M316:
      {
        text: "MCP Crew Below Main Deck",
        alarm: true,
        coordinate: "M316",
        value: false
      },
      M318:
      {
        text: "Smoke Crew room",
        alarm: true,
        coordinate: "M318",
        value: false
      },
  });

    useEffect(() => {
      loadCoordinate();
      if (smsSerialNumber !== "") {
        setCurrentChild(<CustomerSMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
      }
    }, []);

    useEffect(() => {
      const interval = setInterval(async () => {
          //console.log("page refreshed", refreshChecked);
          //console.log('SMS Serial Number: ', smsSerialNumber);
          loadCoordinate();
      }, 250);
      return () => clearInterval(interval);
  }, []);

    // const handleMenuClick = (val) => {
    //     console.log(val);
    //     setActiveTab(val);
    //   }
    
      // useEffect(() => {
      //   if (smsSerialNumber !== "") {
      //     setCurrentChild(<CustomerSMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
      //   }
      // }, [smsSerialNumber]);


    useEffect(() => {
        if (smsSerialNumber !== "") {
          switch (activeTab) {
            case 0:
              setCurrentChild(<CustomerSMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
              break;
            case 1:
              setCurrentChild(<CustomerSMSFireAlarmMainDeck smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
              break;
            case 2:
              setCurrentChild(<CustomerSMSFireAlarmBelowMainDeck smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
              break;
            default:
              return (null);
              break;
          }
        }
      }, [activeTab]);

      useEffect(() => {
        console.log(bitlampList)
    }, [bitlampList]);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = moment(response[0].read_date).format("yyyy-MM-DD HH:mm:ss");
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
      <>
        <div className="mainPageFireAlarmRes">
          <div className="allDeckImgContainer">
            <div className="topFireAlarmPos">
              {/*----------------------------- MAIN DECK -----------------------------*/}
              <div className="topFireAlarmPosMAINDECKimage">
                <div className="topFireAlarmPosMAINDECK">
                  <div className="topFireAlarmPosMAINDECKleft">
                    <div style={{ marginLeft: '50px' }}>
                      {/* <BitLamp text={'Steering Gear'} bitlampColor="black" alarm={bitlampList["M314"].value} /> M314 */}
                    </div>
                  </div>
                  <div className="topFireAlarmPosMAINDECKright">
                    <div className="topFireAlarmPosMAINDECKright-1">
                      <div style={{ marginTop: '50px' }}>
                        <BitLamp type={'mcp'} alarm={bitlampList["M38"].value} />{/*M38*/}
                      </div>
                      <div style={{ marginTop: '60px' }}>
                        <BitLamp type={'temp'} alarm={bitlampList["M300"].value} />{/*M300*/}
                      </div>
                      <div style={{ marginTop: '5px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M298"].value} />{/*M298*/}
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <BitLamp type={'mcp'} alarm={bitlampList["M308"].value} />{/*M308*/}
                      </div>
                    </div>
                    <div className="topFireAlarmPosMAINDECKright-2">
                      <div style={{ marginTop: '50px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M40"].value} />{/*M40*/}
                      </div>
                      <div style={{ marginTop: '100px' }}>
                        <BitLamp type={'temp'} alarm={bitlampList["M48"].value} />{/*M48*/}
                      </div>
                    </div>
                    <div className="topFireAlarmPosMAINDECKright-4">
                      <div style={{marginLeft: '20px', marginTop: '50px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M42"].value} />{/*M42*/}
                      </div>
                      <div style={{marginLeft: '20px', marginTop: '50px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M50"].value} />{/*M50*/}
                      </div>
                      <div style={{marginLeft: '20px', marginTop: '30px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M52"].value} />{/*M52*/}
                      </div>
                    </div>
                    <div className="topFireAlarmPosMAINDECKright-5">
                      <div style={{ marginLeft: '20px', marginTop: '50px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M44"].value} />{/*M44*/}
                      </div>
                      <div style={{ marginLeft: '20px', marginTop: '90px' }}>
                        <BitLamp type={'smoke'} alarm={bitlampList["M46"].value} />{/*M46*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*----------------------------- WHEELHOUSE -----------------------------*/}
              <div className="topFireAlarmPosWHimage">
                <div className="topFireAlarmPosMID">
                  <div className="topFireAlarmPosMID-1">
                    <div style={{ marginLeft: '40px', marginTop: '120px' }}>
                      <BitLamp type={'smoke'} alarm={bitlampList["M32"].value} />{/*M32*/}
                    </div>
                  </div>
                  <div className="topFireAlarmPosMID-2">
                    <div style={{ marginTop: '70px' }}>
                      <BitLamp type={'mcp'} alarm={bitlampList["M34"].value} />{/*M34*/}
                    </div>
                    <div style={{ marginTop: '100px' }}>
                      <BitLamp type={'mcp'} alarm={bitlampList["M36"].value} />{/*M36*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*----------------------------- BELOW MAIN DECK -----------------------------*/}
            <div className="bottomFireAlarmPos">
              <div className="bottomFireAlarmPosBELOWMAINDECKimage">
                <div className="bottomFireAlarmPosBELOWMAINDECKmid">
                <div className="bottomFireAlarmPosBELOWMAINDECKmidLeft">
                  <div className="bottomFireAlarmPosBELOWMAINDECKmidLeft-1">
                    <div style={{ marginTop: '80px', marginLeft: '80px' }}>
                      <BitLamp type={'temp'} alarm={bitlampList["M312"].value} />{/*M312*/}
                    </div>
                    <div style={{ marginTop: '50px', marginLeft: '80px' }}>
                      <BitLamp type={'smoke'} alarm={bitlampList["M310"].value} />{/*M310*/}
                    </div>
                    <div style={{ marginTop: '50px', marginLeft: '50px' }}>
                      <BitLamp type={'mcp'} alarm={bitlampList["M304"].value} />{/*M304*/}
                    </div>
                  </div>
                  <div className="bottomFireAlarmPosBELOWMAINDECKmidLeft-2">
                  <div style={{ marginTop: '85px', marginLeft: '20px' }}>
                      <BitLamp type={'smoke'} alarm={bitlampList["M302"].value} />{/*M302*/}
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                      <BitLamp type={'mcp'} alarm={bitlampList["M296"].value} />{/*M296*/}
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                      <BitLamp type={'temp'} alarm={bitlampList["M306"].value} />{/*M306*/}
                    </div>
                  </div>
                </div>
                  <div className="bottomFireAlarmPosBELOWMAINDECKmidRight">
                    <div style={{ marginTop: '100px', marginLeft: '20px' }}>
                      <BitLamp type={'smoke'} alarm={bitlampList["M318"].value} />{/*M318*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
                    </div>
          </div>
        </div>

        
      </>
    );
}