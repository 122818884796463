// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BitLampFireAlarmMainContainer {
    width: 25px;
    height: 25px;
    /* border: 1px red solid */
}`, "",{"version":3,"sources":["webpack://./src/Components/BitLampForFireAlarm/BitLampFireAlarm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,0BAA0B;AAC9B","sourcesContent":[".BitLampFireAlarmMainContainer {\n    width: 25px;\n    height: 25px;\n    /* border: 1px red solid */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
