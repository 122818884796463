import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getLoadCell, getCountLoadCell, getAllLoadCellData, getAllLoadCellDataLocation, getLoadCellDeviceLocation, getCountLoadCellDeviceLocation
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import Loading from "../../Components/Loading";
import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";
import GoogleMaps from "../../Components/GoogleMaps";
// import RPILogo from "../../Assets/Images/rpi-logo.svg";
// import QuantumLogo from "../../Assets/Images/quantum-logo.svg";
// import quantum from "../../Assets/Images/quantum2.png"
import quantumLogo from "../../Assets/Images/quantums.png"
import { ArrowClockwise, Search, PencilFill, EyeFill, BarChartFill } from "react-bootstrap-icons";
import Paginations from "../../Components/Pagination";
import ContainerBox from "../../Components/ContainerBox";

export default function MapsPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [listLoadCell, setListLoadCell] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [listAllLoadCellData, setListAllLoadCellData] = useState([]);
    const navigate = useNavigate();
    const [resetSearch, setResetSearch] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Device Name",
        value: "device_name"
    });
    const orderByList = [{
        label: "Device Name",
        value: "device_name"
    }];
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        // if (isSearched !== 0)
        loadLoadCellData();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadLoadCellData();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadLoadCellData();
    }, [orderBy]);


    useEffect(() => {
        if (resetSearch !== 0) {
            loadLoadCellData()
        }
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0)
            loadLoadCellData()
    }, [descending]);
    // const devices = [
    //     { id: 1, name: 'Device 1 Tes', location: "Radian Peninsula Indonesia", lat: -6.127283373056311, lng:      },
    //     { id: 2, name: 'Device 2', location: "Apartemen SUnter Icon", lat: -6.134620478539386, lng: 106.85428522507787 },
    //     { id: 3, name: 'Device 3', location: "Kota Tua", lat: -6.194182422829561, lng: 106.8228661334137 }
    //     // Add more devices as needed
    // ];
    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                //moment.locale('id');
                loadLoadCellData();
            }
        }
        checkCookies();
    }, []);

    const loadLoadCellData = async () => {
        try {
            let count = await getCountLoadCellDeviceLocation(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getAllLoadCellDataLocation(cookies.token);
            setListAllLoadCellData(response);

            let listData = await getLoadCellDeviceLocation(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListLoadCell(listData)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {

        }
    }

    return (
        <>


            {/* <Container fluid style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                // width: "100%",
             

            }}> */}
            {/* <div style={{
                    padding: 20,
                    justifyContent: "Center",
                   

                }}> */}
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "nowrap"
            }}>
                <div style={{
                    display: "flex",
                    flex: 1,
                    // borderStyle: "solid"
                }}>
                    <img src={quantumLogo} height={70} />
                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",

                }}>

                    <GoogleMaps devices={listAllLoadCellData} />
                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    width: "100%"
                    // marginTop:600
                }}>
                    <ContainerBox
                        titleCaption="Device List"
                        childContent={
                            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>

                                <div className="master-table-inner-container">
                                    <div className="master-table-searchbar-container">
                                        <div className="master-table-searchbar-textbox">
                                            <Form.Control value={searchQuery} type="text" placeholder="Key Word (Device Name)"
                                                onKeyPress={async (e) => {
                                                    if (e.charCode === 13) {
                                                        setPage(0);
                                                        setLoading(true);
                                                        await loadLoadCellData();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchQuery(e.target.value);
                                                }} />
                                        </div>
                                        <div className="master-table-searchbar-button">
                                            <div style={{ paddingRight: 5 }}>
                                                <Button onClick={async () => {
                                                    // setLoading(true);
                                                    // setPage(0);
                                                    // await loadLoadCellData();
                                                }}><Search /></Button>
                                            </div>
                                            <div style={{ paddingLeft: 5 }}>
                                                <Button onClick={async () => {
                                                    setLoading(true);
                                                    setSearchQuery("");
                                                    setResetSearch(1);
                                                    await loadLoadCellData();
                                                }}><ArrowClockwise /></Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                        <div className="table-header">
                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                            <div style={{ flex: 4 }} className="table-header-content">Device Name</div>
                                            <div style={{ flex: 4 }} className="table-header-content">Customer</div>
                                            <div style={{ flex: 1 }} className="table-header-content">Detail</div>
                                            <div style={{ flex: 1 }} className="table-header-content">Graphics</div>
                                        </div>
                                        {
                                            listLoadCell.map((listDevice, index) => {
                                                return (<div className="table-body" key={index}>
                                                    {/* <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div> */}
                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                                                    <div style={{ flex: 4 }} className="table-body-content"><p>{listDevice.device_name}</p></div>
                                                    <div style={{ flex: 4 }} className="table-body-content"><p>{listDevice.customer_name}</p></div>
                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                        <div style={{ padding: 5 }}>
                                                            <Button variant="primary" onClick={() => {
                                                                navigate("/loadCell", { state: { deviceId: listDevice.id } });
                                                            }}><PencilFill />
                                                            </Button>
                                                        </div></div>
                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                        <div style={{ padding: 5 }}>
                                                            <Button variant="primary" onClick={() => {
                                                                navigate("/loadCell/Chart", { state: { deviceId: listDevice.id, deviceName: listDevice.device_name } });
                                                            }}><BarChartFill />
                                                            </Button>
                                                        </div></div>



                                                </div>)
                                            }
                                            )
                                        }
                                    </div>
                                    <div style={{
                                        paddingTop: 20,
                                    }}>

                                    </div>
                                    <Paginations
                                        itemPerPage={itemPerPage}
                                        totalPage={totalPage}
                                        page={page}
                                        setPage={setPage}
                                        setItemPerPage={setItemPerPage}
                                        itemPerPageSelection={itemPerPageSelection}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                        orderBySelection={orderByList}
                                        isDescActive={descending}
                                        setIsDescActive={setDescending}
                                        setIsSearched={setIsSearched}
                                    />
                                    <Loading
                                        loading={loading}
                                    />
                                </div>

                            </div>

                        } />
                </div>


            </div>

            {/* </div>
              <div style={{
                display:"flex",
                flex:1
              }}>
                <p>Tes</p>
              </div>

                    <Loading
                        loading={loading}
                    /> */}
            {/* </Container> */}
        </>
    );

}