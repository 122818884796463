import "./NewSMSNavContainer.css"
import { useEffect, useState } from "react";
import LampNavButton from "../NewLampNavButton/NewLampNavButton";
import NavButton from "../NavButton/NavButton";
import { useNavigate } from "react-router-dom";
import Clock from 'react-live-clock';

export default function SMSNavContainer(props) {
    const { smsSerialNumber, activeTab, childContent, handleMenuClick } = props;
    // const [ datasmsSerialNumber, setDataSmsSerialNumber] = useState(props.smsSerialNumber);
    const navigate = useNavigate();
    const navigateMainEngine = () => { navigate("/MainEngine") }
    const navigateAlarm = () => { navigate("/Alarm", { state: { datasmsSerialNumber: props.smsSerialNumber } });  }

    useEffect(() => {
        console.log('---------------props-----------------',props.activeTab);
    }, []);

    return (
        <>
            <div className="mainMainSMSDashboardContainer">
                <div className="leftNavBarContainer">
                    <LampNavButton text1={"MAIN"} text2={"MENU"} lamp={activeTab === 0 ? true : false} handleOnClick={()=> {
                        handleMenuClick(0);
                    }} />
                    <LampNavButton text1={"MAIN"} text2={"ENGINE"} lamp={activeTab === 1 ? true : false} handleOnClick={()=> {
                        handleMenuClick(1)
                    }} />
                    <LampNavButton text1={"GEARBOX"} text2={""} lamp={activeTab === 2 ? true : false} handleOnClick={()=> {
                        handleMenuClick(2)
                    }} />
                    <LampNavButton text1={"AUX"} text2={"ENGINE"} lamp={activeTab === 3 ? true : false} handleOnClick={()=> {
                        handleMenuClick(3)
                    }} />
                    <LampNavButton text1={"PUMP AND"} text2={"MOTOR"} lamp={activeTab === 4 ? true : false} handleOnClick={()=> {
                        handleMenuClick(4)
                    }} />
                    <LampNavButton text1={"TANK"} text2={"LEVEL"} lamp={activeTab === 5 ? true : false} handleOnClick={()=> {
                        handleMenuClick(5)
                    }} />
                    <LampNavButton text1={"MAIN"} text2={"SWITCHBOARD"} lamp={activeTab === 6 ? true : false} handleOnClick={()=> {
                        handleMenuClick(6)
                    }} />
                    <LampNavButton text1={"COMMON"} text2={"ALARM"} lamp={activeTab === 7 ? true : false} handleOnClick={()=> {
                        handleMenuClick(7)
                    }} />
                    <LampNavButton text1={"FIRE"} text2={"ALARM"} lamp={activeTab === 8 ? true : false} handleOnClick={()=> {
                        handleMenuClick(8)
                    }} />
                    <LampNavButton text1={"NAVIGATION"} text2={"LIGHT"} lamp={activeTab === 9 ? true : false} handleOnClick={()=> {
                        handleMenuClick(9)
                    }} />
                    <LampNavButton text1={"EVENT HISTORY"} text2={""} lamp={activeTab === 10 ? true : false} handleOnClick={()=> {
                        handleMenuClick(10)
                    }} />
                    {/* <LampNavButton text1={"VESSEL"} text2={"LOCATION"} lamp={activeTab === 11 ? true : false} handleOnClick={()=> {
                        handleMenuClick(11)
                    }} /> */}
                    {/* <NavButton text1={"ALARM"} handleOnClick={navigateAlarm} /> */}
                </div>
                {/* <div className="rightSMSDasboardContainer">
                    <div className="containerBoxTop">
                        <p style={{color: 'white', fontSize: 'medium', fontWeight: 'lighter'}}><Clock
                            format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                            ticking={true}
                            timezone={'Asia/Jakarta'} /></p>
                        

                    </div>
                    <div className="containerBoxBottom">
                        {props.childContent}
                    </div>
                </div> */}
            </div>
        </>
    )
}