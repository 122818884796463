import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link, useLocation, useSearchParams } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getLoadCellById, findAllLoadCellReading
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Loading from "../../Components/Loading";
import quantumLogo from "../../Assets/Images/quantums.png"
import moment from "moment";
import { Bar, Line } from "react-chartjs-2";
import ContainerBox from "../../Components/ContainerBox";
import { ArrowLeft, Search, ArrowClockwise, } from "react-bootstrap-icons";
import {
    Chart as ChartJS,
    BarElement, CategoryScale, LinearScale, Tooltip, Legend, LineElement, PointElement
} from "chart.js";
ChartJS.register(
    BarElement, CategoryScale, LinearScale, Tooltip, Legend, LineElement, PointElement
)

export default function LoadCellChartPage() {
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deviceId, setDeviceId] = useState("");
    const [startTime, setStartTime] = useState("");
    const [finalStartTime, setFinalStartTime] = useState("");
    const [finalEndTime, setFinalEndTime] = useState("");
    const [errorStartTime, setErrorStartTime] = useState("");
    const [errorEndTime, setErrorEndTime] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [endTime, setEndTime] = useState("");
    const [totalHours, setTotalHours] = useState("");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isSearched, setIsSearched] = useState(0);
    // const []
    const [deviceDetail, setDeviceDetail] = useState({});
    const degreeSymbol = '\u00b0';
    const [connectedStatus, setConnectedStatus] = useState("");
    const [dataAngel, setDataAngel] = useState({
        labels: [],
        datasets: [{
            label: 'Angle History',
            data: [],
            backgroundColor: 'aqua',
            borderColor: 'black',
            // borderwith: 1,
            // pointBorderColor: 'aqua'
        }]
    })
    const [dataRadius, setDataRadius] = useState({
        labels: [],
        datasets: [{
            label: 'Radius History',
            data: [],
            backgroundColor: 'aqua',
            borderColor: 'black',
            // borderwith: 1,
            // pointBorderColor: 'aqua'
        }]
    })

    const [dataRatedLoad, setDataRatedLoad] = useState({
        labels: [],
        datasets: [{
            label: 'Rated Load History',
            data: [],
            backgroundColor: 'aqua',
            borderColor: 'black',
            // borderwith: 1,
            // pointBorderColor: 'aqua'
        }]
    })

    const [dataMainHoist, setDataMainHoist] = useState({
        labels: [],
        datasets: [{
            label: 'Main Hoist History',
            data: [],
            backgroundColor: 'aqua',
            borderColor: 'black',
            // borderwith: 1,
            // pointBorderColor: 'aqua'
        }]
    })
    const options = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: 20
                    }
                }
            }
        },
        
    }

    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                setDeviceId(location.state.deviceId)
            }
        }
        checkCookies();
    }, []);



    useEffect(() => {
        if (isSearched === 1){
            getLoadCellData();
        }else{
            setLoading(false);
        }

    }, [isSearched])

    useEffect(() => {
        // console.log('startTime==========', startTime);
        // console.log('endTime==========', endTime);
        if (startTime !== "" && endTime !== "") {
            let finalDiffTime = "";
            let newStartTime = new Date(startTime);
            let newEndTime = new Date(endTime);
            let diffTime = Math.abs(newEndTime - newStartTime) / 36e5;
            // console.log(moment(startTime).utc().format("yyyy-MM-DD HH:mm:ss"), tes2);
            if (diffTime > 1) {
                finalDiffTime = Math.round(diffTime);
            } else {
                finalDiffTime = diffTime
            };
           

        } 
        
    }, [startTime, endTime])


    useEffect(() => {
        const interval = setInterval(async () => {
            getLoadCellData();
        }, 300)

        return () => clearInterval(interval)
    });

    useEffect(() => {
        if (deviceId !== "" && deviceId !== null)
            getLoadCellData();
    }, [deviceId])

    useEffect(() => {
        forceUpdate();
        if (finalStartTime !== "" && finalEndTime !== "")
            getLoadCellData()
    }, [finalStartTime, finalEndTime])



    const getLoadCellData = async () => {
        try {
          

            let response = await findAllLoadCellReading(cookies.token, deviceId, finalStartTime, finalEndTime);
            setDeviceName(response[0].device_name)
            let createdDateLabel = response.map(a => a.device_date);
            createdDateLabel.reverse();
            let angelData = response.map(p => p.field_16);
            angelData.reverse();
            let radiusData = response.map(p => p.field_18);
            radiusData.reverse();
            let ratedLoad = response.map(p => p.field_10);
            ratedLoad.reverse();
            let mainHoist = response.map(p => p.field_8);
            mainHoist.reverse();
            console.log(createdDateLabel.length, angelData.length);
            setDataAngel({
                ...dataAngel,
                labels: createdDateLabel,
                datasets: [{
                    label: 'Angle History',
                    data: angelData,
                    backgroundColor: 'aqua',
                    
                }]
            })

            setDataRadius({
                ...dataRadius,
                labels: createdDateLabel,
                datasets: [{
                    label: 'Radius History',
                    data: radiusData,
                    backgroundColor: 'aqua',
                 
                }]
            })

            setDataRatedLoad({
                ...dataRatedLoad,
                labels: createdDateLabel,
                datasets: [{
                    label: 'Rated Load History',
                    data: ratedLoad,
                    backgroundColor: 'aqua',
                  
                }]
            })

            setDataMainHoist({
                ...dataMainHoist,
                labels: createdDateLabel,
                datasets: [{
                    label: 'Main Hoist History',
                    data: mainHoist,
                    backgroundColor: 'aqua',
                 
                }]
            })

            setIsSearched(0)

        } catch (exception) {
            console.log(exception)
        }
    }
    return (
        <>
            {/* <Navbar /> */}
            <Container fluid style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                width: "100%"
            }}>
                <div style={{
                    display: "flex",
                    flex: 1,
                    // borderStyle: "solid"
                }}>
                    <img src={quantumLogo} height={100} />
                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: 10
                }}>
                    <ContainerBox titleCaption={`${deviceName} Chart History`}
                        useBackButton={true}
                        backButtonChild={

                            <Button variant="secondary"
                                onClick={() => { navigate(`/maps`); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button>
                        } childContent={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "rgba(219, 235, 255, 0.9)",
                                minHeight: 700,
                                // marginTop: 60,
                                padding: 10
                                // width:"100%",
                                // justifyContent:"center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // flex: 1,
                                    // marginTop: 20,
                                    paddingBottom: 20,
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingRight: 10
                                    }}>
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control required type="datetime-local" onChange={(e) => {
                                            if (e.target.value === "") {
                                                setStartTime("")
                                            } else {
                                                if (moment(startTime).format("yyyy-MM-DD HH:mm") > moment(endTime).format("yyyy-MM-DD HH:mm")) {
                                                    setErrorStartTime("Start Time Can't Over Than End Time");
                                                    setStartTime("");
                                                } else {
                                                    setStartTime(e.target.value);
                                                    setErrorStartTime("");
                                                }

                                            }


                                        }} value={startTime ? moment(startTime).format("yyyy-MM-DD HH:mm") : ""} ></Form.Control>
                                        {
                                            errorStartTime && <p style={{ color: "red" }}>{errorStartTime}</p>
                                        }
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 10
                                    }}>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control required type="datetime-local" onChange={(e) => {
                                            if (e.target.value === "") {
                                                setEndTime("");
                                            } else {
                                                if (moment(endTime).format("yyyy-MM-DD HH:mm") < moment(startTime).format("yyyy-MM-DD HH:mm")) {
                                                    setErrorEndTime("End Time Can't Less Than Start Time");
                                                    setEndTime("");

                                                } else {
                                                    setEndTime(e.target.value);
                                                    setErrorEndTime("");
                                                }

                                            }


                                        }} value={endTime ? moment(endTime).format("yyyy-MM-DD HH:mm") : ""} ></Form.Control>
                                        {
                                            errorEndTime && <p style={{ color: "red" }}>{errorEndTime}</p>
                                        }
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 10,
                                        // marginBottom:"auto",
                                        paddingTop: 30
                                    }}>

                                        <Button style={{height:40}} onClick={() => {
                                            // console.log('tes');\
                                            setLoading(true);
                                            setFinalEndTime(endTime !== "" ?moment(endTime).utc().format("yyyy-MM-DD HH:mm:ss") : "")
                                            setFinalStartTime(startTime !== "" ?moment(startTime).utc().format("yyyy-MM-DD HH:mm:ss"): "")
                                            setIsSearched(1);


                                        }}><Search size={20}/></Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        paddingLeft: 10,
                                        // marginBottom:"auto",
                                        paddingTop: 30
                                    }}>

                                        <Button style={{height:40}} onClick={() => {
                                            // console.log('tes');
                                            setStartTime("");
                                            setEndTime("");
                                            setFinalEndTime("")
                                            setFinalStartTime("")
                                            setIsSearched(1);
                                            setLoading(true);

                                        }}><ArrowClockwise size={20}/></Button>
                                    </div>


                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingBottom: 10
                                    // width:"100%",
                                    // justifyContent:"center"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "80%",
                                        alignContent: "center",
                                        alignSelf: "center",
                                        marginTop: "auto",
                                        // marginBottom: "auto",
                                        paddingBottom: 20
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,

                                            borderStyle: "solid",
                                            flexDirection: "column"
                                        }}>
                                            <Line
                                                data={dataAngel}
                                                options={options}
                                            ></Line>

                                        </div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "80%",
                                        alignContent: "center",
                                        alignSelf: "center",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        paddingBottom: 20
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,

                                            borderStyle: "solid",
                                            flexDirection: "column"
                                        }}>
                                            <Line
                                                data={dataRadius}
                                                options={options}
                                            ></Line>

                                        </div>

                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingTop: 10
                                    // width:"100%",
                                    // justifyContent:"center"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "80%",
                                        alignContent: "center",
                                        alignSelf: "center",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        paddingBottom: 20
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,

                                            borderStyle: "solid",
                                            flexDirection: "column"
                                        }}>
                                            <Line
                                                data={dataRatedLoad}
                                                options={options}
                                            ></Line>

                                        </div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "80%",
                                        alignContent: "center",
                                        alignSelf: "center",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        // paddingLeft: 5
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,

                                            borderStyle: "solid",
                                            flexDirection: "column"
                                        }}>
                                            <Line
                                                data={dataMainHoist}
                                                options={options}
                                            ></Line>

                                        </div>

                                    </div>
                                </div>



                            </div>
                        } />

                </div>
                <Loading
                    loading={loading}
                />
            </Container >
        </>
    );

}