import React, { useState, useEffect } from "react";
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CustomerNavigatonBar from "../../Components/CustomerNavBar";
import { Container, Button } from 'react-bootstrap';
import Clock from 'react-live-clock';
import ContainerBox from "../../Components/ContainerBox";
import { isTokenValid, getVesselLocationLatestValue,findVesselByIdForCustomerDashboard } from "../../Helpers/ApplicationHelpers";
import {Download, EyeFill } from "react-bootstrap-icons";
import Quantum from "../../../src/Assets/Images/tugboatdamen.png";
import GoogleMapContainer from "../../Components/GoogleMapContainerCustomer"
import moment from "moment";
import Sidebar from "../../Components/FixedSidebar/FixedSidebar";
import QuantumLogo from "../../../src/Assets/Images/QPDNEWLOGO.png";

export default function CustomerDasboardPage() {
    const navigate = useNavigate();
    const [smsSerialNumber, setSmsSerialNumber] = useState('SMS-000-000001');
    const [vesselId, setVesselId] = useState('2d965d55-1311-4214-8383-4b9fb2ee0516');
    const [cookies, setCookie] = useCookies(["token"]);
    const [gpsData, setGpsData] = useState(null);
    const [detailVesselData, setDetailVesselData] = useState({
        vessel_type_name: "",
        country_name: "",
        customer_name: "",
        id: "",
        vessel_name: "",
        company_id: "",
        vessel_type_id: "",
        imo_number: "",
        serial_number: "",
        built_date: "",
        built_shipyard: "",
        created_date: "",
        updated_date: "",
        created_by: "",
        updated_by: "",
        is_active: true,
        vessel_code: "",
        vessel_project_counter: 0,
        gross_register_tonnage: 0,
        deadweight_tonnage: 0,
        lbp: 0,
        bmld: 0,
        hmld: 0,
        flag: "",
        callsign: "",
        class_register_number: "",
        customer_id: "",
        ext_customer_id: "",
        sms_serial_number: "",
        shift_session_id: "",
        part_number: ""
});
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
        //   .utc()
          .subtract(60, "minutes")
          .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).format("yyyy-MM-DD HH:mm:ss")
    );

    const contohGPSData = [
        {
            latitude: -6.12654,
            longitude: 106.855,
            timestamp_gps: 0
        },
        {
            latitude: -6.1346259649457675,
            longitude: 106.85419011636043,
            timestamp_gps: 0
        },
        {
            latitude: -6.121034901958877,
            longitude: 106.8442569439223,
            timestamp_gps: 0
        },
        {
            latitude: -6.101892388816553,
            longitude: 106.87417670522854,
            timestamp_gps: 0
        }
    ]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                loadDetailVesselData();
            }
        }
        checkCookies();
        console.log(cookies)
    }, []);

    // useEffect(() => {
        
    // }, [selectEndDate])

    useEffect(() => {
        console.log('detailVesselData:', detailVesselData);
        console.log('serialnumber:', smsSerialNumber);
        console.log('vesselid:', vesselId);
        loadCoordinate();
    }, [detailVesselData])


    const loadCoordinate = async () => {
        try {
            const response = await getVesselLocationLatestValue(cookies.token, smsSerialNumber, vesselId);
            console.log('getVesselLocation:', response);
            let dateData = response.map((a) => a.received_date);
            let actualGpsData = response.map((a) => {
                return {
                  latitude: +a.latitude,
                  longitude: +a.longitude,
                  timestamp_gps: moment(a.received_date).format("yyyy-MM-DD HH:mm:ss"),
                };
              });
            setGpsData(actualGpsData);
            console.log('actual:', actualGpsData);
            console.log('contoh:', contohGPSData);
        } catch (exception){
            console.log(exception);
        }
    }

    const loadDetailVesselData = async () => {
        try {
            let response = await findVesselByIdForCustomerDashboard(cookies.token, vesselId);
            // console.log(response);
            setDetailVesselData({
                vessel_type_name: response[0].vessel_type_name,
                country_name: response[0].country_name,
                customer_name: response[0].customer_name,
                id: response[0].id,
                vessel_name: response[0].vessel_name,
                company_id: response[0].company_id,
                vessel_type_id: response[0].vessel_type_id,
                imo_number: response[0].imo_number,
                serial_number: response[0].serial_number,
                built_date: response[0].built_date,
                built_shipyard: response[0].built_shipyard,
                created_date: response[0].created_date,
                updated_date: response[0].updated_date,
                created_by: response[0].created_by,
                updated_by: response[0].updated_by,
                is_active: response[0].is_activeue,
                vessel_code: response[0].vessel_code,
                vessel_project_counter: response[0].vessel_project_counter,
                gross_register_tonnage: response[0].gross_register_tonnage,
                deadweight_tonnage: response[0].deadweight_tonnage,
                lbp: response[0].lbp,
                bmld: response[0].bmld,
                hmld: response[0].hmld,
                flag: response[0].flag,
                callsign: response[0].callsign,
                class_register_number: response[0].class_register_number,
                customer_id: response[0].customer_id,
                ext_customer_id: response[0].ext_customer_id,
                sms_serial_number: response[0].sms_serial_number,
                shift_session_id: response[0].shift_session_id,
                part_number: response[0].part_number
            });
        } catch (exception){
            console.log(exception);
        }
    }
    return(
        <>
            <div className="main">
                <Sidebar companyName={detailVesselData.customer_name} smsSerialNumber={detailVesselData.sms_serial_number} vesselId={detailVesselData.id} vesselName={detailVesselData.vessel_name} customerName={cookies.userName} />
                <Container fluid className="mainContainerDashboard">
                    <div className="childMainContainerD">
                        <img className="titleImg" style={{ height: '60%' }} src={QuantumLogo} alt="Quantum Logo" />
                    </div>

                </Container>

                {/* <div className="childMainContainerD">
                        <div className="titleContainerQuantum">
                            <img className="titleImg" style={{ height: '100%' }} src={QuantumLogo} alt="Quantum Logo" />
                            <h2 className="titleVMMSText">VESSEL MANAGEMENT SYSTEM</h2>
                        </div>
                    </div> */}
            </div>
        </>
    )
}
