import "../App.css";
import { Form, option } from "react-bootstrap";
import DP, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import id from 'date-fns/locale/id';



export default function DatePicker({
    caption,
    placeholder,
    dateFormat,
    value,
    onDateChange,
    showTimeSelect = false,
    disabled,

}) {
    registerLocale('id', id);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-start"
        }}>
            <div style={{
                display: "flex",
                fontWeight: "bold",
                paddingBottom: 5
            }}>
                {caption}
            </div>
            <div style={{
                display: "flex",
            }}>
                <DP disabled={disabled}
                    locale="id"
                    onChange={onDateChange}
                    placeholderText={placeholder}
                    showTimeSelect={showTimeSelect}
                    dateFormat={dateFormat === undefined ? "dd/MM/yyyy" : dateFormat}
                    selected={value} />
            </div>
        </div>
    )
};