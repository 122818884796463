import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { ArrowLeft } from "react-bootstrap-icons";
import { isTokenValid, convertBase64, getUserAccessById } from "../../../../../Helpers/ApplicationHelpers";
import {
    getVesselClassificationCharacterById, getVesselClassificationApplication,
    getVesselClassificationSociety, insertUpdateCharacter
} from "../../../../../Helpers/VesselClassification.helpers";
import "../../../../../App.css";
import ContainerBox from "../../../../../Components/ContainerBox";
import Navbar from "../../../../../Components/NavBar";
import Loading from "../../../../../Components/Loading";
import DropDownForm from "../../../../../Components/DropDownForm";
import { Trans, useTranslation } from "react-i18next";


export default function VesselTypeDetailPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [listLanguage, setListLanguage] = useState([]);
    const [listSociety, setListSociety] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [listApplication, setlistApplication] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [character, setCharacter] = useState({
        languageId: "",
        societyId: "",
        applicationId: "",
        characterSymbolImage: "",
        characterDesc: "",
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
        }
        checkCookies();
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
        }
        if (location.state.characterId === 0) {
            loadSociety();
            loadApplication();
            loadAccess();
            setCharacter({ ...character, languageId: cookies.languageId });
            setLoading(false);
        } else {
            loadSociety();
            loadAccess();
            loadApplication();
            initCharacter();
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [character])

    useEffect(() => {
        console.log(errors);
    }, [errors])

    const loadApplication = async () => {
        let listApplication = await getVesselClassificationApplication(cookies.token, cookies.languageId);
        setlistApplication(listApplication);
    }

    const loadSociety = async () => {
        let listSociety = await getVesselClassificationSociety(cookies.token, cookies.languageId);
        setListSociety(listSociety);
    }

    const initCharacter = async () => {
        try {
            let response = await getVesselClassificationCharacterById(cookies.token, location.state.languageId, location.state.characterId);
            if (response) {
                setCharacter({
                    ...character,
                    languageId: response.language_id,
                    societyId: response.vessel_classification_society_id,
                    applicationId: response.vessel_classification_application_id,
                    characterSymbolImage: response.character_symbol_image,
                    characterDesc: response.character_desc,
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (character.characterSymbolImage === "" || character.characterSymbolImage === undefined) {
                    setErrors({
                        ...errors,
                        characterSymbolImageError: `${t("detail_class_character.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (character.societyId === "" || character.societyId === undefined) {
                    setErrors({
                        ...errors,
                        societyIdError: `${t("detail_class_character.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (character.applicationId === "" || character.applicationId === undefined) {
                    setErrors({
                        ...errors,
                        applicationIdError: `${t("detail_class_character.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "characterSymbolImageError", "societyIdError", "applicationIdError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const saveApplication = async () => {
        let response = await insertUpdateCharacter(cookies.token, character, location.state.characterId);
        if (response) {
            alert(`${t("detail_class_character.alert_success_save_data")}`);
            setLoading(false);
            navigate("../.././VesselClassification/Character");
        } else {
            alert(`${t("detail_class_character.alert_failed_save_data")}`);
            setLoading(false);
        }

    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_class_character.header_title")}`} useActionContainer={false}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate(-1); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setLoading(true);
                                saveApplication();
                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <DropDownForm value={character.societyId} caption={`${t("detail_class_character.field_class")}`} placeholder=""
                                        setValueFunction={(e) => {
                                            setCharacter({ ...character, societyId: e.target.value });
                                        }} listOption={listSociety} valueKey="id" labelKey="society_name" />

                                </Form.Group>
                                {
                                    errors.societyIdError && <p style={{ color: "red" }}>{errors.societyIdError}</p>
                                }

                                <Form.Group className="mb-3">
                                    <DropDownForm value={character.applicationId} caption={`${t("detail_class_character.field_aplication")}`} placeholder=""
                                        setValueFunction={(e) => {
                                            setCharacter({ ...character, applicationId: e.target.value });
                                        }} listOption={listApplication} valueKey="id" labelKey="application_name" />

                                </Form.Group>
                                {
                                    errors.applicationIdError && <p style={{ color: "red" }}>{errors.applicationIdError}</p>
                                }
                                <div style={{
                                    display: "flex",
                                    padding: 10,
                                    flexDirection: "row"
                                }}>
                                    <div style={{
                                        padding: 10,
                                        flex: 1,
                                    }}>

                                        {
                                            character.characterSymbolImage === "" || character.characterSymbolImage === undefined ?
                                                <img className="character-symbol-img" src={null} />
                                                :
                                                <img className="character-symbol-img" src={character.characterSymbolImage} />
                                        }
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        padding: 2,
                                        marginBottom: 10,
                                        width: "50%",
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignContent: "flex-end",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            alignSelf: "flex-end"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 2,
                                                flexDirection: "row",
                                            }}>
                                                <Button onClick={() => {
                                                    inputFile.current.click();
                                                }}>{t("detail_class_character.button_choose_symbol")}</Button>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flex: 5,
                                                width: "100%",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}>
                                                <Form.Control onClick={() => {
                                                    inputFile.current.click();
                                                }} placeholder="" type="text" readOnly={true} />
                                                <input onChange={async (e) => {
                                                    if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                                        let base64Img = await convertBase64(e.target.files[0]);
                                                        const base64ImgString = base64Img.toString();
                                                        setCharacter({ ...character, characterSymbolImage: base64ImgString, file: e.target.value });
                                                        setUploadFileImageError("");

                                                    } else {
                                                        setCharacter({ ...character, characterSymbolImage: "", file: "" });
                                                        setUploadFileImageError(`${t("detail_class_character.error_image_file")}`)
                                                        // setDisabledButton(true)
                                                    }
                                                    // let base64Img = await convertBase64(e.target.files[0]);
                                                    // const base64ImgString = base64Img.toString();
                                                    // setCharacter({ ...character, characterSymbolImage: base64ImgString, file: e.target.value });

                                                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />

                                            </div>

                                        </div>
                                        {
                                            errors.characterSymbolImageError && <p style={{ color: "red" }}>{errors.characterSymbolImageError}</p>
                                        }

                                        {
                                            uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                        }
                                    </div>
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_class_character.field_description")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setCharacter({ ...character, characterDesc: e.target.value })
                                    }} as="textarea" rows={3} value={character.characterDesc} ></Form.Control>
                                </Form.Group>
                                <Button style={{ width: 100 }} disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">{t("detail_class_character.save_button")}</Button>
                                <Button style={{ width: 100 }} variant="danger" className="cancel" onClick={() => {
                                    navigate("../.././VesselClassification/Character");
                                }}>{t("detail_class_character.cancel_button")}</Button>
                            </Form>
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>

                    } />
            </Container>
        </>
    )
}