import "../App.css";
import { Spinner } from 'react-bootstrap';


function Loading({
    loading,
    loadingText
}) {
    return (
        <>
            {
                loading &&
                <div style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 999,
                }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        backgroundColor: "#000000AA",
                        top: window.innerHeight / 3,
                        position: "absolute",
                        width: "30%",
                        height: "30%",
                        borderRadius: 10
                    }}>

                        <div style={{
                            flex: 2,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 80,
                        }}>
                            <Spinner
                                variant="info" animation="border" role="status" />
                        </div>
                        <div style={{ paddingTop: 10, flex: 1, }}>
                            <p style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#2f71bd"
                            }}>
                                {/* {loadingText} */}
                            </p>
                        </div>

                    </div> </div >
            }
        </>
    )
}

export default Loading;

