import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useState, useEffect } from "react";
import moment from "moment";

export default function DynamicLineChartForFuelConsumptionDoubleAxis({dataAvg, dataCurrent, minValue, maxValue, stepSize}) {
  const [dataToShowAvg, setDataToShowAvg] = useState([]);
  const [dataToShowCurrent, setDataToShowCurrent] = useState([]);

  useEffect(() => {
    setDataToShowAvg(dataAvg);
  }, [dataAvg]);

  useEffect(() => {
    setDataToShowCurrent(dataCurrent);
  }, [dataCurrent]);

  // const minValue = 0;
  // const maxValue = 100;
  // const stepSize = 25;

  const xAxisValues = dataToShowAvg ? dataToShowAvg.map(data => moment(data.received_date).format("MM-DD HH:mm")) : [];
  const yAxisValuesAvg = dataToShowAvg ? dataToShowAvg.map(data => Number(data.reading_value)) : [];
  const yAxisValuesCurrent = dataToShowCurrent ? dataToShowCurrent.map(data => Number(data.reading_value)) : [];

  const data = {
    labels: xAxisValues,
    datasets: [
      {
        label: 'Average Fuel Consumption',
        data: yAxisValuesAvg,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#031e67',
        borderWidth: 2,
        yAxisID: 'y1', 
      },
      {
        label: 'Current Fuel Consumption',
        data: yAxisValuesCurrent,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: '#ff6384',
        borderWidth: 2,
        yAxisID: 'y2', 
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#031e67'
        }
      },
      y1: {
        min: minValue,
        max: maxValue,
        ticks: {
          stepSize: stepSize,
          color: '#031e67'
        },
        position: 'left'
      },
      y2: {
        min: minValue,
        max: maxValue, 
        ticks: {
          stepSize: stepSize,
          color: '#ff6384'
        },
        position: 'right'
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    animation: false
  };

  return (
    <Line
      data={data}
      options={options}
    />
  );
}