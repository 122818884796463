import "./index.css"
import GaugeComponent from 'react-gauge-component'

export default function NewGaugeComponent2(props) {
    const {value, unit} = props;
  return (
    <>
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.01,
          padding: 0.005,
          cornerRadius: 1,
          // gradient: true,
          subArcs: [
            {
              limit: 1600,
              color: '#f1f5f9',
              showTick: true,
              tooltip: {
                text: ''
              },
              // onClick: () => console.log("test"),
              // onMouseMove: () => console.log("test"),
              // onMouseLeave: () => console.log("test"),
            },
            {
              limit: 1700, 
              color: '#F5CD19', 
              showTick: true,
              tooltip: {
                text: ''
              }
            },
            {
              limit: 1800, 
              color: '#e11d48', 
              showTick: true,
              tooltip: {
                text: ''
            }
          }
          ]
        }}
        pointer={{
          color: '#e11d48',
          length: 1,
          width: 5,
          animate: true,
          animationDelay: 1000,
          animationDuration: 250
          // elastic: true,
        }}
        labels={{
          valueLabel: { 
            hide: true,
          },
          tickLabels: {
            type: 'inner',
            valueConfig: { formatTextValue: value => value + 'Rpm', fontSize: 1 },
            ticks: [
              { value: 0 },
              { value: 100 },
              { value: 200 },
              { value: 300 },
              { value: 400 },
              { value: 500 },
              { value: 600 },
              { value: 700 },
              { value: 800 },
              { value: 900 },
              { value: 1000 },
              { value: 1100 },
              { value: 1200 },
              { value: 1300 },
              { value: 1400 },
              { value: 1500 },
              { value: 1600 },
              { value: 1700 },
              { value: 1800 },
            ],
            // defaultTickValueConfig: { fill: "#f1f5f9"},
          }
        }}
        value={value}
        minValue={0}
        maxValue={1800}
      />
      <div className="valueContainerNew">
        <p className="textForGaugeValue">{value}</p>
        <p className="textForGaugeValue">{unit}</p>
      </div>
     
    </>
  )

}