import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Form, Container, Table, Modal, InputGroup, Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation, Link, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, insertUpdateServiceJob, getStaff, getStaffPosition, getSpecializaton, getProject,
    getServiceJobById, getServiceJobDetail, getContractTaskDetailByVesselType, getMechanicGroup, getEquipment,
    getListStaffGroupMechanic, getServiceJobCrewHistory, getServiceJobCrewHistoryCount, getServiceJobStatus,
    getVesselEquipmentByVessel, getDetailEquipmentService, convertBase64, getServiceReport, getSubSpecialization,
    getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import FormSelect from "../../../../Components/FormSelect";
import Loading from "../../../../Components/Loading";
import { Editor } from '@tinymce/tinymce-react';
import TextBox from "../../../../Components/Textbox";
import { Trash, ArrowLeft, Clock, ArrowClockwise, Search, FilePlusFill, PencilFill, CameraFill, PrinterFill, Cameraplus, Image, XSquareFill } from "react-bootstrap-icons";
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import Paginations from "../../../../Components/Pagination";
import { convert } from 'html-to-text'
import ServiceReportEquipment from "../../../../Components/ServiceReportEquipment";
import ServiceReportDetail from "../../../../Components/ServiceReportDetail";
import { Trans, useTranslation } from "react-i18next";

export default function ServiceJobDetailPage() {
    const ref = useRef();
    const { t, i18n } = useTranslation();
    // const inputRef = useRef()
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [errors, setErrors] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [fotoModal, setFotoModal] = useState("");
    const [disabledPrint, setDisabledPrint] = useState(false);
    const [indexDetailFoto, setIndexDetailFoto] = useState("");
    const [disabledAddButton, setDisabledAddButton] = useState(false);
    const [indexDetailJobFoto, setIndexDetailJobFoto] = useState("");
    const [buttonSaveData, setButtonSaveData] = useState(0);
    const [listProject, setListProject] = useState([]);
    const [listStaff, setListStaff] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [listStaffPosition, setListStaffPosition] = useState([]);
    const [addButtonDetail, setAddButtonDetail] = useState(false);
    const [listMechanicGroup, setListMechanicGroup] = useState([]);
    const [listGroup, setListGroup] = useState([]);
    const [serviceJobId, setServiceJobId] = useState("");
    const [indexDetailJob, setIndexDetailJob] = useState("");
    const [indexDetail, setIndexDetail] = useState("");
    const [indexDetailJobFinding, setIndexDetailJobFinding] = useState("");
    const [indexDetailFinding, setIndexDetailFinding] = useState("");
    const [indexDetailJobRecommendation, setIndexDetailJobRecommendation] = useState("");
    const [indexDetailRecommendation, setIndexDetailRecommendation] = useState("");
    const [noteModal, setNoteModal] = useState("");
    const [findingsModal, setFindingsModals] = useState("");
    const [recommendationModal, setRecommendationModal] = useState("");
    const [equipmentErrorMessage, setEquipmentErrorMessage] = useState("");
    const [selectedProject, setSelectedProject] = useState({ id: "" });
    const [selectedMechanicGroup, setSelectedMechanicGroup] = useState({ id: "" });
    const [selectedEquipment, setSelectedEquipment] = useState({ id: "" });
    const [listServiceJobCrewHistory, setListServiceJobCrewHistory] = useState([]);
    const [historyModal, setHistoryModal] = useState("");
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [listServiceEquipment, setListServiceEquipment] = useState([]);
    const [listServiceJob, setListServiceJob] = useState([]);
    const [page, setPage] = useState(0);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("")
    const [itemPerPage, setItemPerPage] = useState(5);
    const [projectName, setProjectName] = useState("");
    const [docxData, setDocxData] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [totalQuality, setTotalQuality] = useState(0);
    const [disabledAddCrew, setDisabledAddCrew] = useState(false);
    const [disabledButtonCrew, setDisabledButtonCrew] = useState(false);
    const [newListVesselEquipment, setNewListVesselEquipment] = useState([]);
    const [crewErrorMessage, setCrewErrorMessage] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [resetSearch, setResetSearch] = useState(0);
    const [listEquipment, setListEquipment] = useState([]);
    const [svcEqpCount, setSvcEqpCount] = useState([]);
    const [resetComponent, setResetComponent] = useState(false);
    const [listServiceCrew, setListServiceCrew] = useState([]);
    const [arrivedFrom, setArrivedFrom] = useState("");
    const [selectedStaff, setSelectedStaff] = useState(null);
    const serviceEquipmentRef = useRef([]);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);


    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_annual_report.order_name1")}`,
        value: "crewHistory.arrived_at"
    });

    const orderByList = [
        {
            label: `${t("detail_annual_report.order_name1")}`,
            value: "crewHistory.arrived_at"
        }
    ]

    const options = {
        wordwrap: 130
    }

    const [serviceJob, setServiceJob] = useState({
        id: 0,
        project_id: null,
        service_date: null,
        group_mechanic_id: null,
        staff_service_pic: null,
        customer_service_pic: null,
        service_reason: "",
        list_crew: [],
        detail_job: [],
        status_id: null,
        ext_customer_id: cookies.extCustomerId
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadProject();
                loadGroupMechanic();
                loadStaff();
                loadStaffPosition();
                loadSpecialization();
                loadAccess();
                loadStatus();
                loadEquipment();
                if (searchParams.get("id") === null) {
                    setServiceJobId(location.state.serviceJobId);
                } else {
                    setServiceJobId(searchParams.get("id"))
                }

                if (location.state.serviceJobId === 0) {
                    setLoading(false);
                }
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (serviceJob.group_mechanic_id) {
    //         let list = listGroup.find(p => p.id === serviceJob.group_mechanic_id)
    //         if (list) {
    //             setSelectedMechanicGroup(list)
    //         }
    //     }
    // }, [serviceJob.group_mechanic_id]);


    useEffect(() => {
        if (buttonSaveData !== 0) {
            saveData()
        }
    }, [buttonSaveData]);

    useEffect(() => {
        // console.log(listServiceJob);
    }, [listServiceJob])


    useEffect(() => {
        if (docxData.size > 0) {
            // console.log(docxData.size);
            downloadDocxFile();
        }
    }, [docxData]);


    useEffect(() => {
        // console.log(serviceJob);
        if (serviceJob.project_id) {
            let list = listProject.find(p => p.id === serviceJob.project_id);
            if (list) {
                setSelectedProject(list)
            }
        }



        validationForm();
    }, [serviceJob])

    useEffect(() => {
        if (serviceJob.project_id) {
            if (serviceJobId === 0)
                loadDetailEquipmentService();
        }
    }, [serviceJob.project_id]);

    // useEffect(() => {
    //     if (indexDetailJob !== "") {
    //         setNoteModal(true);
    //     }
    // }, [indexDetailJob]);

    // useEffect(() => {
    //     if (indexDetailJobFinding !== "") {
    //         setFindingsModals(true);
    //     }
    // }, [indexDetailJobFinding])

    // useEffect(() => {
    //     if (indexDetailJobRecommendation !== "") {
    //         setRecommendationModal(true);
    //     }
    // }, [indexDetailJobRecommendation])

    // useEffect(() => {
    //     if (indexDetailJobFoto !== "") {
    //         setFotoModal(true);
    //     }
    // }, [indexDetailJobFoto])

    useEffect(() => {
        if (serviceJobId !== 0 && serviceJobId !== "")
            loadServiceJobCrewHistory();
    }, [page]);

    useEffect(() => {
        if (serviceJobId !== 0 && serviceJobId !== "") {
            if (resetSearch !== 0)
                loadServiceJobCrewHistory();
        }

    }, [resetSearch]);

    useEffect(() => {
        if (serviceJobId !== 0 && serviceJobId !== "")
            loadServiceJobCrewHistory();
    }, [itemPerPage]);

    useEffect(() => {
        if (serviceJobId !== 0 && serviceJobId !== "")
            loadServiceJobCrewHistory();
    }, [orderBy]);

    useEffect(() => {
        if (serviceJobId !== 0 && serviceJobId !== "")
            loadServiceJobCrewHistory();
    }, [descending])

    useEffect(() => {
        if (serviceJobId !== 0 && serviceJobId !== "") {
            initServiceJob();
            loadServiceJobCrewHistory();
        }
    }, [serviceJobId])


    // useEffect(() => {
    //     if (!fotoModal) {
    //         setFotoModal(false);
    //         setShowFormFoto(true);
    //     }
    // }, [fotoModal])


    // useEffect(() => {
    //     if (selectedMechanicGroup.group_name) {
    //         setServiceJob({ ...serviceJob, group_mechanic_id: selectedMechanicGroup.id })
    //     } else {
    //         setServiceJob({ ...serviceJob, group_mechanic_id: null });
    //     }
    // }, [selectedMechanicGroup]);

    useEffect(() => {
        if (serviceJobId === 0) {
            let response = listStatus.find(p => p.status_code === "NEW");
            if (response) {
                setServiceJob({ ...serviceJob, status_id: response.id })
            }
        }
    }, [listStatus])

    useEffect(() => {
        if (listServiceCrew.length > 0) {
            for (let index = 0; index < listServiceCrew.length; index++) {
                const listCrew = listServiceCrew[index];
                if (listCrew.crew_name === "") {
                    setCrewErrorMessage(`${t("detail_annual_report.error_technician_name")}`);
                    setDisabledButton(true);
                    setDisabledButtonCrew(true);
                } else {
                    setCrewErrorMessage("");
                    setDisabledButtonCrew(false);
                    validationForm();
                }

            }
        } else {
            setDisabledButtonCrew(false);
            setCrewErrorMessage("");

        }
    }, [listServiceCrew])

    useEffect(() => {
        if (listServiceEquipment.length > 0) {
            for (let index = 0; index < listServiceEquipment.length; index++) {
                const listJob = listServiceEquipment[index];
                if (listJob.equipment_id === null) {
                    setEquipmentErrorMessage(`${t("detail_annual_report.error_equipment_category")}`);
                    setDisabledButton(true);
                    setAddButtonDetail(true);
                } else {
                    setAddButtonDetail(false);
                    setEquipmentErrorMessage("");
                    validationForm();
                }

            }


        } else {
            validationForm()
            setAddButtonDetail(false);
            setEquipmentErrorMessage("");
        }
        setResetComponent(true);
        setSvcEqpCount(listServiceEquipment.length);
        forceUpdate();
    }, [listServiceEquipment]);

    useEffect(() => {
        if (resetComponent)
            setResetComponent(false);
    }, [resetComponent]);


    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListProject(response);
            // setLoading(false);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const scrollTop = async () => {
        window.scrollTo(100, 0)
        document.documentElement.scrollTop = 0;
    }

    const validationForm = async () => {
        try {

            if (serviceJob.project_id === undefined || serviceJob.project_id === null) {
                setErrors({
                    ...errors,
                    projectName: `${t("detail_annual_report.error_empty_field")}`
                },
                    setDisabledButton(true));
            }
            // else if (serviceJob.service_date === undefined || serviceJob.service_date === null) {
            //     setErrors({
            //         ...errors,
            //         serviceDate: '*Harus Diisi'
            //     },
            //         setDisabledButton(true));
            // }
            else if (serviceJob.group_mechanic_id === undefined || serviceJob.group_mechanic_id === null) {
                setErrors({
                    ...errors,
                    mechanicGroup: `${t("detail_annual_report.error_empty_field")}`
                },
                    setDisabledButton(true));
            }

            else {
                let errorMsg = omit(errors, "projectName", "mechanicGroup");
                setErrors(errorMsg);
                setDisabledButton(false);

            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadGroupMechanic = async () => {
        try {
            let mechanicGroup = await getMechanicGroup(cookies.token, "null");
            // console.log(mechanicGroup);
            setListGroup(mechanicGroup);
            let response = await getListStaffGroupMechanic(cookies.token, "null");
            setListMechanicGroup(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, "null", undefined, undefined, undefined, true, undefined, undefined, 2);
            let staff = response.filter(p => p.is_field_staff === true);
            setListStaff(staff);
        } catch (exception) {
            console.log(exception)
        }
    }



    const loadStaffPosition = async () => {
        try {
            let response = await getStaffPosition(cookies.token, cookies.languageId);
            setListStaffPosition(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadSpecialization = async () => {
        try {
            let response = await getSubSpecialization(cookies.token, cookies.languageId);
            let listSpecialization = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    id: response[i].id,
                    label: response[i].sub_specialization_name

                };
                listSpecialization.push(obj);
            }
            setSpecializationOptions(listSpecialization);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 30,
            overflowY: "auto"
        }),
    }

    const saveData = async () => {
        try {
            let newServiceJob = serviceJob
            newServiceJob.ext_customer_id = newServiceJob.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : serviceJob.ext_customer_id
            let newServiceEquipment = listServiceEquipment
            if (newServiceEquipment.length > 0) {
                for (let index = 0; index < listServiceEquipment.length; index++) {
                    const listNew = listServiceEquipment[index];
                    listNew.list_job = listServiceJob[index]
                }
            }
            newServiceJob.detail_job = newServiceEquipment;
            newServiceJob.list_crew = listServiceCrew;
            let response = await insertUpdateServiceJob(cookies.token, newServiceJob);
            if (response.error_code === 0) {
                alert(`${t("detail_annual_report.alert_success_save_data")}`)
                if (serviceJobId === 0) {
                    setServiceJobId(response.data.id)
                } else {
                    initServiceJob()
                }

            } else {
                alert(`${t("detail_annual_report.alert_failed_save_data")}`);
            }
            setDisabledButton(false);
            setButtonSaveData(0);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const initServiceJob = async () => {
        try {
            let response = await getServiceJobById(cookies.token, serviceJobId);
            if (response) {

                setProjectName(response.project_name);
                let listServiceEquipmentTmp = []
                if (response.detail_job.length > 0) {
                    let list = [];
                    for (let index = 0; index < response.detail_job.length; index++) {
                        const listJob = response.detail_job[index];
                        list.push(listJob.list_job);
                        listJob.list_job = []
                        listServiceEquipmentTmp.push(listJob)
                    }
                    setListServiceJob(list);
                }

                setListServiceEquipment(listServiceEquipmentTmp);
                setServiceJob({
                    ...serviceJob,
                    id: response.id,
                    project_id: response.project_id,
                    service_date: response.service_date,
                    group_mechanic_id: response.group_mechanic_id,
                    staff_service_pic: response.staff_service_pic,
                    service_reason: response.service_reason,
                    // list_crew: response.list_crew,
                    status_id: response.status_id,
                    // detail_job: response.detail_job

                })
                setListServiceCrew(response.list_crew)
            }



            let status = await getServiceJobStatus(cookies.token);
            let statusService = status.find(p => p.id === response.status_id);
            if (statusService) {
                if (statusService.status_code === "INPROGRESS") {
                    setDisabledAddCrew(true);
                } else if (statusService.status_code === "CLOSED") {
                    setDisabledAddCrew(true);
                    setDisabledAddButton(true);
                } else {
                    setDisabledAddCrew(false);
                    setDisabledAddButton(false);
                }
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadServiceJobCrewHistory = async () => {
        try {
            let count = await getServiceJobCrewHistoryCount(cookies.token, serviceJobId);
            // let count = await getServiceJobCrewHistory(cookies.token, location.state.serviceJobId, undefined, undefined, undefined, undefined, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getServiceJobCrewHistory(cookies.token, serviceJobId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListServiceJobCrewHistory(response);
            setResetSearch(0);
            // setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getServiceJobStatus(cookies.token);
            setListStatus(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            setListEquipment(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    // const loadVesselEquipment = async () => {
    //     try {
    //         let response = await getVesselEquipmentByVessel(cookies.token, selectedProject.vessel_id, searchQuery, false);
    //         setListVesselEquipment(response);
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    const loadDetailEquipmentService = async () => {
        try {
            let response = await getDetailEquipmentService(cookies.token, selectedProject.contract_id, selectedProject.vessel_type_id, selectedProject.vessel_id, selectedProject.id);
            let newListJob = [];
            let listServiceEquipmentTmp = [];
            for (let index = 0; index < response.length; index++) {
                const listJob = response[index];
                newListJob.push(listJob.list_job);
                listJob.list_job = []
                listServiceEquipmentTmp.push(listJob)
            }
            setListServiceJob(newListJob.slice(0))

            response.list_job = "";
            setListServiceEquipment(listServiceEquipmentTmp.slice(0))
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${projectName}-${moment(serviceJob.service_date).format("yyyy-MM-DD")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // console.log(file);
        // console.log(file.length);

        URL.revokeObjectURL(file);
        setLoading(false);

        setDisabledPrint(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getServiceReport(cookies.token, serviceJobId)
            if (docx.size) {
                setDocxData(docx);
            } else {
                alert(`${t("detail_annual_report.error_download")}`);
                setLoading(false);
                setDisabledPrint(false);
            }
            // setServiceJobId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const bottomToTop = async () => {
        window.scrollTo(0, 0);
    };

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const newList = useMemo(
        () => listServiceJob.slice(0), [listServiceJob]
    )

    const handleOnBlur = (serviceEquipment, listServiceJob2, index) => {
        let listServiceEquipmentTmp = listServiceEquipment;
        listServiceEquipmentTmp[index] = serviceEquipment;
        setListServiceEquipment(listServiceEquipmentTmp.slice(0));
        let listServiceJobTmp = listServiceJob;
        listServiceJobTmp[index] = listServiceJob2;
        setListServiceJob(listServiceJobTmp.slice(0));

    };

    const handleOnBlurServiceJob = (serviceJobs) => {
        setServiceJob(serviceJobs)

    };

    const handleOnBlurSelectedProject = (projects) => {
        if (serviceJobId === 0) {
            setLoading(true);
        }

        setSelectedProject(projects)
    };

    const handleOnBlurlistCrew = (listCrews) => {
        setListServiceCrew(listCrews.slice(0))

    };



    const handleDelete = (listDeleted, index) => {
        setServiceJob({ ...serviceJob, deleted_job: listDeleted.deleted_job })
    };

    const handleDeleteServiceEquipment = (index) => {
        let listServiceEquipmentTmp = listServiceEquipment;
        let serviceJobTmp = serviceJob
        if (!serviceJobTmp.deleted_detail) {
            let list = [];
            list.push(listServiceEquipmentTmp[index]);
            setServiceJob({ ...serviceJob, deleted_detail: list })
        } else {
            let list = serviceJob.deleted_detail;
            list.push(listServiceEquipmentTmp[index])
            setServiceJob({ ...serviceJob, deleted_detail: list })
        }
        listServiceEquipmentTmp.splice(index, 1);
        setListServiceEquipment(listServiceEquipmentTmp.slice(0));

        let listServiceJobTmp = listServiceJob
        listServiceJobTmp.splice(index, 1)

        setListServiceJob(listServiceJobTmp.slice(0))

    };


    const renderServiceEquipment = () => {
        let renderObj = [];
        if (listEquipment.length > 0) {
            for (let i = 0; i < listServiceEquipment.length; i++) {
                const svcEqp = <div key={i}>
                    <ServiceReportEquipment
                        resetComponent={resetComponent}
                        index={i}
                        handleOnBlur={handleOnBlur}
                        handleDelete={handleDelete}
                        handleDeleteServiceEquipment={handleDeleteServiceEquipment}
                        serviceEquipment={listServiceEquipment[i]}
                        listServiceJob={listServiceJob[i]}
                        listEquipment={listEquipment}
                        listVesselEquipment={listVesselEquipment}
                        disabledAddButton={disabledAddButton}
                        vesselId={selectedProject.vessel_id}
                        serviceJobId={serviceJobId}
                        serviceJob={serviceJob}
                        disabledCustomerForm={disabledCustomerForm}
                    />
                </div>;

                renderObj.push(svcEqp);
            }
        }
        return renderObj;
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_annual_report.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/ServiceJob"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={serviceJobId !== 0 && serviceJobId !== "" ? true : false}
                    actionContainerChild={
                        <div>
                            <Button disabled={disabledPrint} variant="secondary" style={{

                            }} onClick={() => {
                                setDisabledPrint(true)
                                createDocument();
                                setLoading(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><PrinterFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_annual_report.print_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>


                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    setDisabledButton(true);
                                    saveData();
                                    setTimeout(function () {
                                        window.scrollTo(0, 0);
                                    }, 2);
                                }}>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={`${t("detail_annual_report.header_service")}`} childContent={
                                                <div>

                                                    <ServiceReportDetail
                                                        serviceJob={serviceJob}
                                                        // setSelectedMechanicGroup={setSelectedMechanicGroup}
                                                        handleOnBlurServiceJob={handleOnBlurServiceJob}
                                                        serviceJobId={serviceJobId}
                                                        listStatus={listStatus}
                                                        disabledAddCrew={disabledAddCrew}
                                                        listProject={listProject}
                                                        handleOnBlurSelectedProject={handleOnBlurSelectedProject}
                                                        listMechanicGroup={listMechanicGroup}
                                                        listStaff={listStaff}
                                                        handleOnBlurlistCrew={handleOnBlurlistCrew}
                                                        listServiceCrew={listServiceCrew}
                                                        disabledCustomerForm={disabledCustomerForm}
                                                    />

                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={`${t("detail_annual_report.header_technician_group")}`}
                                                useActionContainer={serviceJobId !== 0 && serviceJobId !== "" ? true : false}
                                                actionContainerChild={
                                                    <div>
                                                        <Button variant="secondary" style={{

                                                        }} onClick={() => { setHistoryModal(true) }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><Clock size={22} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 10,
                                                                    fontWeight: "bold",
                                                                    fontSize: 18,
                                                                }}>{t("detail_annual_report.button_history")}</div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                }
                                                childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>
                                                            {
                                                                listServiceCrew && listServiceCrew.map((crew, index) => {
                                                                    return (
                                                                        <div key={index} style={{ padding: 5 }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                padding: 5,
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                                borderStyle: "solid",
                                                                                borderColor: "rgba(3, 30, 103, 1)"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>{t("detail_annual_report.field_technician_name")}</Form.Label>
                                                                                        <FormSelect disabledForm={disabledCustomerForm === true ? disabledCustomerForm : disabledAddCrew} placeholder=""
                                                                                            options={listStaff.filter((p) => {
                                                                                                return !listServiceCrew.find((x) => {
                                                                                                    return p.staff_id === x.crew_name
                                                                                                }) && p.id !== serviceJob.staff_service_pic && p.resign_date === null
                                                                                            })} getOptionLabel={(item) => {
                                                                                                return item.staff_name;
                                                                                            }} clearValue={true}
                                                                                            getOptionValue={(item) => {
                                                                                                return item.staff_id;
                                                                                            }} setValueFunction={(e) => {
                                                                                                let listCrew = [...listServiceCrew];
                                                                                                listCrew[index].crew_name = e.staff_id;
                                                                                                listCrew[index].crew_position = e.position_id;
                                                                                                setListServiceCrew(listCrew)
                                                                                            }} value={listStaff.filter(function (option) {
                                                                                                return option.staff_id === crew.crew_name;
                                                                                            })}
                                                                                        />

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>
                                                                                        <Form.Label>{t("detail_annual_report.field_technician_position")}</Form.Label>
                                                                                        <FormSelect placeholder=""
                                                                                            options={listStaffPosition} getOptionLabel={(item) => {
                                                                                                return item.position_name;
                                                                                            }} clearValue={true}
                                                                                            getOptionValue={(item) => {
                                                                                                return item.id;
                                                                                            }} setValueFunction={(e) => {

                                                                                            }} value={listStaffPosition.filter(function (option) {
                                                                                                return option.id === crew.crew_position;
                                                                                            })} disabledForm={true}
                                                                                        />


                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>

                                                                                        <Form.Label>{t("detail_annual_report.field_technician_specialization")}</Form.Label>
                                                                                        <div style={{ paddingTop: 5 }}></div>
                                                                                        <Select styles={styles} isMulti={true} placeholder=""
                                                                                            clearValue={true} closeMenuOnSelect={false}
                                                                                            options={specializationOptions} value={crew.specialization} onChange={(e) => {

                                                                                            }} isClearable isDisabled={true}

                                                                                        />
                                                                                        {/* <FormSelect placeholder="Pilih Posisi..."
                                                                                        options={listStaffPosition} getOptionLabel={(item) => {
                                                                                            return item.position_name;
                                                                                        }} clearValue={true}
                                                                                        getOptionValue={(item) => {
                                                                                            return item.id;
                                                                                        }} setValueFunction={(e) => {

                                                                                        }} value={listStaffPosition.filter(function (option) {
                                                                                            return option.id === crew.crew_position;
                                                                                        })} disabledForm={true}
                                                                                    /> */}


                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        alignItems: "flex-end",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <div style={{ paddingTop: 35 }}></div>
                                                                                        <Button disabled={crew.is_leader === true || disabledCustomerForm === true ? true : disabledAddCrew} onClick={() => {
                                                                                            if (window.confirm(`${t("detail_annual_report.delete_confirmation")}`)) {
                                                                                                let list = listServiceCrew;
                                                                                                if (!serviceJob.deleted_crew) {
                                                                                                    let listCrew = [];
                                                                                                    listCrew.push(list[index]);

                                                                                                    list.splice(index, 1);
                                                                                                    setServiceJob({ ...serviceJob, deleted_crew: listCrew });
                                                                                                    setListServiceCrew(list)
                                                                                                } else {
                                                                                                    let listCrew = serviceJob.deleted_crew;
                                                                                                    listCrew.push(list[index]);
                                                                                                    list.splice(index, 1);
                                                                                                    setServiceJob({ ...serviceJob, deleted_crew: listCrew });
                                                                                                    setListServiceCrew(list)
                                                                                                }

                                                                                            }

                                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                                    </div>

                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>{t("detail_annual_report.field_departure_time")}</Form.Label>
                                                                                        <Form.Control required type="datetime-local" onChange={(e) => {
                                                                                            let listCrew = listServiceCrew;
                                                                                            if (e.target.value === "") {
                                                                                                listCrew[index].departure_date = null;
                                                                                                setListServiceCrew(listCrew.slice(0))
                                                                                            } else {
                                                                                                listCrew[index].departure_date = e.target.value;
                                                                                                setListServiceCrew(listCrew.slice(0))
                                                                                            }


                                                                                        }} disabled={disabledCustomerForm === true ? disabledCustomerForm : disabledAddCrew} value={crew.departure_date ? moment(crew.departure_date).format("yyyy-MM-DD HH:mm") : ""} placeholder="Waktu Kedatangan"></Form.Control>

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>{t("detail_annual_report.field_arrived_date")}</Form.Label>
                                                                                        <Form.Control type="datetime-local" onChange={(e) => {
                                                                                            let listCrew = listServiceCrew;
                                                                                            if (e.target.value === "") {
                                                                                                listCrew[index].arrived_at = null;
                                                                                                setListServiceCrew(listCrew.slice(0))
                                                                                            } else {
                                                                                                listCrew[index].arrived_at = e.target.value;
                                                                                                setListServiceCrew(listCrew.slice(0))
                                                                                            }


                                                                                        }} disabled={disabledCustomerForm === true ? disabledCustomerForm : disabledAddCrew} value={crew.arrived_at ? moment(crew.arrived_at).format("yyyy-MM-DD HH:mm") : ""} placeholder="Waktu Kedatangan"></Form.Control>

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>{t("detail_annual_report.field_leave_time")}</Form.Label>
                                                                                        <Form.Control type="datetime-local" onChange={(e) => {
                                                                                            let listCrew = listServiceCrew;
                                                                                            if (e.target.value === "") {
                                                                                                listCrew[index].leave_time = null;
                                                                                                setListServiceCrew(listCrew.slice(0))
                                                                                            } else {
                                                                                                listCrew[index].leave_time = e.target.value;
                                                                                                setListServiceCrew(listCrew.slice(0))
                                                                                            }


                                                                                        }} disabled={disabledCustomerForm === true ? disabledCustomerForm : disabledAddCrew} value={crew.leave_time ? moment(crew.leave_time).format("yyyy-MM-DD HH:mm") : ""} placeholder="Waktu Kedatangan"></Form.Control>

                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        // width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>
                                                                                        <Form.Label>{t("detail_annual_report.field_arrived_from")}</Form.Label>
                                                                                        <Form.Control type="text" onChange={(e) => {
                                                                                            let listCrew = listServiceCrew;
                                                                                            listCrew[index].arrived_from = e.target.value;
                                                                                            setListServiceCrew(listCrew)
                                                                                        }} disabled={disabledCustomerForm === true ? disabledCustomerForm : disabledAddCrew} value={crew.arrived_from} placeholder="Tiba Dari"></Form.Control>

                                                                                    </div>


                                                                                </div>





                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                crewErrorMessage && <p style={{ color: "red" }}>{crewErrorMessage}</p>
                                                            }
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>
                                                            <Button variant="primary" disabled={disabledAddCrew === true || disabledCustomerForm === true ? true : disabledButtonCrew} onClick={() => {
                                                                let newParameter = {
                                                                    // id: null,
                                                                    crew_name: "",
                                                                    crew_position: "",
                                                                    // crew_specialization: "",
                                                                    departure_date: null,
                                                                    arrived_at: null,
                                                                    // arrived_from: "",
                                                                    leave_time: null
                                                                };
                                                                if (!listServiceCrew) {
                                                                    let listCrew = [];
                                                                    listCrew.push(newParameter);
                                                                    setListServiceCrew(listCrew)
                                                                } else {
                                                                    let listCrew = [...listServiceCrew];
                                                                    listCrew.push(newParameter);
                                                                    setListServiceCrew(listCrew)
                                                                }

                                                            }}>{t("detail_annual_report.button_add_technician")}</Button>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>
                                                            {
                                                                serviceJobId !== 0 && serviceJobId !== "" &&
                                                                <Button variant="primary" disabled={serviceJob.list_crew.length < 1 || disabledAddCrew === true ? true : false} onClick={() => {
                                                                    let status = listStatus.find(p => p.status_code === "INPROGRESS");
                                                                    if (status) {
                                                                        setServiceJob({ ...serviceJob, status_id: status.id });
                                                                        setDisabledAddCrew(true);
                                                                        setButtonSaveData(1);

                                                                    }
                                                                }}>{t("detail_annual_report.button_send_technician")}</Button>
                                                            }

                                                        </div>
                                                    </div>
                                                }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={`${t("detail_annual_report.header_job_detail")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {
                                                        renderServiceEquipment()
                                                    }

                                                    {

                                                        equipmentErrorMessage && <p style={{ color: "red" }}>{equipmentErrorMessage}</p>
                                                    }
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button disabled={addButtonDetail === true || disabledCustomerForm === true ? true : disabledAddButton} variant="primary" onClick={() => {
                                                            let numbersOrder = ""
                                                            if (listServiceEquipment.length > 0) {
                                                                if (listServiceEquipment[listServiceEquipment.length - 1].order_number === null || listServiceEquipment[listServiceEquipment.length - 1].order_number === "") {
                                                                    numbersOrder = listServiceEquipment.length + 1
                                                                } else {
                                                                    numbersOrder = Number(listServiceEquipment[listServiceEquipment.length - 1].order_number) + 1
                                                                }

                                                            } else {
                                                                numbersOrder = listServiceEquipment.length + 1
                                                            }
                                                            let newParameter = {
                                                                equipment_id: null,
                                                                vessel_equipment_id: null,
                                                                start_date: null,
                                                                end_date: null,
                                                                order_number: numbersOrder,
                                                                is_done: false
                                                                // list_job: []

                                                            };

                                                            if (!listServiceEquipment) {
                                                                let list = [];
                                                                list.push(newParameter);
                                                                setListServiceEquipment(list.slice(0))

                                                            } else {
                                                                let list = listServiceEquipment;
                                                                list.push(newParameter);
                                                                setListServiceEquipment(list.slice(0));
                                                            }
                                                            let serviceJobs = listServiceJob
                                                            let newlistJob = [];
                                                            serviceJobs.push(newlistJob);
                                                            setListServiceJob(serviceJobs.slice(0))


                                                        }}>{t("detail_annual_report.button_add_detail")}</Button>
                                                    </div>

                                                </div>
                                            }>

                                            </ContainerBox>


                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        padding: 10,
                                        width: "100%",
                                        justifyContent: "center",
                                        fontWeight: "bold"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            paddingRight: 5,
                                        }}>
                                            <Button style={{width:100}} className="save-button" disabled={disabledButton === true || disabledCustomerForm === true ? true : !updatedButton} type="submit">{t("detail_annual_report.save_button")}</Button>

                                        </div>
                                        <div style={{
                                            display: "flex",
                                            paddingLeft: 5,
                                            paddingRight: 5
                                        }}>
                                            <Button style={{width:100}} onClick={() => {
                                                navigate('/ServiceJob')
                                            }} className="cancel-button" type="reset" variant="danger">{t("detail_annual_report.cancel_button")}</Button>
                                        </div>
                                    </div>

                                </Form>
                            </div>


                        </div >

                    }>


                </ContainerBox >

                <Modal show={historyModal}
                    size={"xl"}
                    onHide={() => {
                        setHistoryModal(false);
                        setLoading(false);
                    }}>
                    <div className="details m-2" >

                        <div className="detailscontent">
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: "100%"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "flex-start"
                                }}>
                                    <h3>{t("detail_annual_report.modal_technician_history")} </h3>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignContent: "flex-end",
                                    alignItems: "flex-end"
                                }}>
                                    <Button variant="secondary" style={{

                                    }} onClick={() => {
                                        setHistoryModal(false);
                                        setLoading(false);
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><XSquareFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("detail_annual_report.close_button")}</div>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>

                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_annual_report.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                await loadServiceJobCrewHistory();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            await loadServiceJobCrewHistory();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadServiceJobCrewHistory();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container" style={{ padding: 10 }}>
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    {/* <div style={{ flex: 3 }} className="table-header-content">Nama Grup Teknisi</div> */}
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name3")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name4")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name5")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name6")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("detail_annual_report.table_name7")}</div>

                                </div>
                                {
                                    listServiceJobCrewHistory.length === 0 &&
                                    <div>
                                        <div colSpan={7} style={{ textAlign: "center" }}>{t("detail_annual_report.text_no_data")}</div>
                                    </div>
                                }

                                {
                                    listServiceJobCrewHistory.map((crew, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.group_name}</p></div>
                                            {/* <div style={{ flex: 3 }} className="table-body-content"><p>{crew.pic_name}</p></div> */}
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.mechanic_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.position_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.departure_date ? moment(crew.departure_date).format("yyyy-MM-DD") : ""}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.arrived_at ? moment(crew.arrived_at).format("yyyy-MM-DD") : ""}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.leave_time ? moment(crew.leave_time).format("yyyy-MM-DD") : ""}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crew.arrived_from}</p></div>


                                        </div>
                                        );
                                    })
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                        </div>





                    </div>

                </Modal>


            </Container >
            <Loading
                loadingText={`${t("loading_component.text")}`}
                loading={loading}
            />


        </>
    )
}
