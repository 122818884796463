import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getVesselMaintenancePlanForCustomerDashboard, getPeriod } from "../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import {EyeFill} from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import TextBox from "../../Components/Textbox";
import { Editor } from '@tinymce/tinymce-react';

export default function CustomerMaintenancePlanPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [titleBar, setTitleBar] = useState("");
    const [listMaintenancePlan, setListMaintenancePlan] = useState([]);
    const [showMaintenancePlanDetailModal, setShowMaintenancePlanDetailModal] = useState(false);
    const [selectedMaintenancePlan, setSelectedMaintenancePlan] = useState({});
    const [listPeriod, setListPeriod] = useState([]);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                // console.log(searchParams.get("smsSerialnumber").replace(/'/g, ""));
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
                setLoading(true);
            }
        }
        checkCookies();
        // console.log(cookies);
        // console.log(vesselId);
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
            
        }
    }, [smsSerialNumber])

    useEffect(() => {
        setLoading(false);
       console.log('listMaintenancePlan', listMaintenancePlan);
    }, [listMaintenancePlan])


    useEffect(() => {
        loadListPeriod();
        loadMaintenancePlan();
        // if(vesselId){
        //     // console.log(vesselId);
            
        // }

     }, [vesselId])


    const loadMaintenancePlan = async () => {
        try {
            // console.log('token', cookies.token);
            // console.log('lang', cookies.languageId);
            // console.log('VESSEL ID', vesselId);
            let response = await getVesselMaintenancePlanForCustomerDashboard(cookies.token, cookies.languageId, vesselId);
            // console.log('RESPONSE',response);
            setListMaintenancePlan(response.data);
        }
        catch (exception){
            console.log(exception);
        }

    }

    const loadListPeriod = async () => {
        try {
            let results = await getPeriod(cookies.token, cookies.languageId);
            if (results) {
                setListPeriod(results);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <div className="main">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerMP">
                    <div className="innerChildMainContainerMP">
                        <ContainerBox
                            titleCaption={vesselName + ' MAINTENANCE PLAN'}
                            // useActionContainer={true}
                            // actionContainerChild={
                            //   <div>
                            //     <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => { navigate("/") }}>
                            //       <div className="btnContainer">
                            //         <div className="btnContainerLeft"><FilePlusFill size={32} /></div>
                            //         <div className="btnContainerRight">{'Add Data'}</div>
                            //       </div>
                            //     </Button>
                            //   </div>
                            // }
                            childContent={
                                <>
                                <div className="master-table-inner-container">
                                    <h4 style={{color: 'black'}}>MAINTENANCE DUE</h4>
                                    <div className="master-table-searchbar-container">
                                    </div>
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_next_maintenance_date")}</div>
                                                <div style={{ flex: 4 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_name")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_count")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_period_long")}</div>
                                                {/* <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_maintenance_history")}</div> */}
                                                {/* <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_description")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_period")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_is_periodic")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_use_contact_count")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_is_repeating")}</div> */}
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_running_hour")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_warning_at")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_description")}</div>
                                            </div>
                                            {
                                                listMaintenancePlan && listMaintenancePlan.map((maint, index) => {
                                                    return (<div key={maint.id} className="table-body">
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{maint.next_maintenance_date}</p></div>
                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{maint.maintenance_plan_name}</p></div>
                                                        <div style={{ flex: 2 }} className="table-body-content">
                                                            {maint.is_periodic && <p>{`${String(maint.maintenance_plan_period_count)}`}</p>}
                                                            {maint.use_contact_count && <p>{`${String(maint.contact_count)} Contact`}</p>}
                                                        </div>
                                                        <div style={{ flex: 2 }} className="table-body-content">
                                                            {maint.is_periodic && <p>{`${maint.period_name}`}</p>}
                                                            {maint.use_contact_count && <p>{`${String(maint.contact_count)} Contact`}</p>}
                                                        </div>
                                                        {/* <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                            <Form.Check
                                                                className="checkbox-lg"
                                                                checked={maint.use_contact_count}
                                                                label=""
                                                                name="use_contact_count"
                                                                disabled
                                                                type={"checkbox"}
                                                                id={`contact-count-chk-${index}`}
                                                            />
                                                        </div>
                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                            <Form.Check
                                                                className="checkbox-lg"
                                                                checked={maint.is_repeating}
                                                                label=""
                                                                name="is_repeating"
                                                                disabled
                                                                type={"checkbox"}
                                                                id={`repeating-chk-${index}`}
                                                            />
                                                        </div> */}
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{maint.running_hour_interval}</p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{maint.warning_at}</p></div>
                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                            <div style={{ padding: 5 }}>
                                                                <Button disabled={false} variant="primary" onClick={() => {
                                                                    setSelectedMaintenancePlan(maint)
                                                                    setShowMaintenancePlanDetailModal(true);
                                                                }}><EyeFill />
                                                                </Button>
                                                            </div></div>
                                                    </div>)
                                                }
                                                )
                                            }
                                    </div>
                                </div>
                                
                                {/* <div className="master-table-inner-container">
                                    <h4 style={{color: 'black'}}>MAINTENANCE HISTORY</h4>
                                    <div className="master-table-searchbar-container">
                                    </div>
                                    <div className="table-container">
                                        <div className="table-header">
                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                            <div style={{ flex: 2 }} className="table-header-content">Name</div>
                                            <div style={{ flex: 2 }} className="table-header-content">Period/Count</div>
                                            <div style={{ flex: 2 }} className="table-header-content">Is Periodic</div>
                                            <div style={{ flex: 2 }} className="table-header-content">Is Repeating</div>
                                            <div style={{ flex: 2 }} className="table-header-content">Image</div>
                                            <div style={{ flex: 2 }} className="table-header-content">View</div>
                                        </div>
                                        <div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"></div>

                                        </div>

                                    </div>
                                </div> */}
                                </>
                            }
                        />
                    </div>
                    <Loading
                    loading={loading}
                    loadingText={`${t("loading_component.text")}`}
                />
                </Container>
                <Modal show={showMaintenancePlanDetailModal}

                    size={"xxl"}
                    onHide={() => {
                        setShowMaintenancePlanDetailModal(false);

                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            {/* {selectedMaintenancePlan.id === 0 && <h3>{t("sparepart_catalog_maintenance_plan.modal_add_maintenance_plan")} </h3>} */}
                            {<h3>{t("sparepart_catalog_maintenance_plan.modal_maintenance_plan_description")} </h3>}
                        </div>

                        <Form onSubmit={(e) => {
                            // setSaveButtonDisabled(true);
                            // e.preventDefault();
                            // saveMaintenancePlan();
                        }} style={{ padding: 10 }}>
                            <TextBox disabledForm={true} value={selectedMaintenancePlan.maintenance_plan_name} placeholder=""
                                caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_name")}
                                // setValueFunction={(e) => {
                                //     setSelectedMaintenancePlan({ ...selectedMaintenancePlan, maintenance_plan_name: e.target.value });
                                // }}
                                req={true} />
                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalog_maintenance_plan.field_maintenance_plan_desc")}</Form.Label>
                                {/* <Form.Control as="textarea" rows={3} 
            onChange={(e) => {
                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, description: e.target.value })
            }} 
            type="text" value={selectedMaintenancePlan.maintenance_plan_desc} placeholder=""></Form.Control> */}
                                <Editor
                                    apiKey='pbjm9oydw65b4p46e89nz1eav5rqqpy66e0ltrlnr15kc5dq'
                                    // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                    // onEditorChange={(evt, editor) => {
                                    //     setSelectedMaintenancePlan({ ...selectedMaintenancePlan, maintenance_plan_desc: evt });

                                    // }}
                                    disabled
                                    value={selectedMaintenancePlan.maintenance_plan_desc}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                            'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                        ],
                                        toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                        toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                        image_title: true,
                                        automatic_uploads: true,
                                        /*
                                          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                          images_upload_url: 'postAcceptor.php',
                                          here we add custom filepicker only to Image dialog
                                        */
                                        file_picker_types: 'image',
                                        /* and here's our custom image picker*/
                                        file_picker_callback: (cb, value, meta) => {
                                            const input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.setAttribute('accept', 'image/*');

                                            input.addEventListener('change', (e) => {
                                                const file = e.target.files[0];
                                                const reader = new FileReader();
                                                reader.addEventListener('load', () => {
                                                    /*
                                                      Note: Now we need to register the blob in TinyMCEs image blob
                                                      registry. In the next release this part hopefully won't be
                                                      necessary, as we are looking to handle it internally.
                                                    */

                                                    // console.log(editorRef.current.getContent())
                                                    const id = 'blobid' + (new Date()).getTime();
                                                    const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                    //   editorRef.current.editorUpload.blobCache

                                                    //Editor.activeEditor.editorUpload.blobCache;

                                                    const base64 = reader.result.split(',')[1];
                                                    const blobInfo = blobCache.create(id, file, base64);
                                                    blobCache.add(blobInfo);

                                                    /* call the callback and populate the Title field with the file name */
                                                    cb(blobInfo.blobUri(), { title: file.name });
                                                });
                                                reader.readAsDataURL(file);
                                            });

                                            input.click();
                                        },
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </Form.Group>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                width: "100%"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    width: "100%"
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%"
                                    }} className="mb-3">
                                        <Form.Label>{t("sparepart_catalog_maintenance_plan.field_is_periodic")}</Form.Label>
                                        <Form.Check
                                            checked={selectedMaintenancePlan.is_periodic}
                                            label=""
                                            name="selected_is_repeating"
                                            type={"checkbox"}
                                            id={`selected_repeating-chk`}
                                            onChange={(e) => {
                                                setSelectedMaintenancePlan({ ...selectedMaintenancePlan, is_periodic: e.target.checked });
                                            }}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%"
                                    }} className="mb-3">
                                        <Form.Label>{t("sparepart_catalog_maintenance_plan.field_is_repeating")}</Form.Label>
                                        <Form.Check
                                            checked={selectedMaintenancePlan.is_repeating}
                                            label=""
                                            name="selected_is_repeating"
                                            type={"checkbox"}
                                            id={`selected_repeating-chk`}
                                            onChange={(e) => {
                                                setSelectedMaintenancePlan({ ...selectedMaintenancePlan, is_repeating: e.target.checked });
                                            }}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%"
                                    }} className="mb-3">
                                        <Form.Label>{t("sparepart_catalog_maintenance_plan.field_use_contact_count")}</Form.Label>
                                        <Form.Check
                                            checked={selectedMaintenancePlan.use_contact_count}
                                            label=""
                                            name="selected_use_contact_count"
                                            type={"checkbox"}
                                            id={`selected_contact-chk`}
                                            onChange={(e) => {
                                                setSelectedMaintenancePlan({ ...selectedMaintenancePlan, use_contact_count: e.target.checked });
                                            }}
                                            disabled
                                        />
                                    </Form.Group>
                                </div>

                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                width: "100%"
                            }}>
                                <Form.Group className="mb-3">
                                    <TextBox style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",

                                    }} disabledForm={true} value={selectedMaintenancePlan.maintenance_plan_period_count} placeholder=""
                                        caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_period_count")}
                                        setValueFunction={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, maintenance_plan_period_count: e.target.value });
                                        }}
                                        req={true} />
                                </Form.Group>
                                <div style={{ paddingLeft: 10 }}></div>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("sparepart_catalog_maintenance_plan.field_maintenance_plan_period")}</Form.Label>
                                    <Form.Select disabled={true} onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, period_id: e.target.value })
                                        } else {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, period_id: null })
                                        }

                                    }} value={selectedMaintenancePlan.period_id ? selectedMaintenancePlan.period_id : ""}>
                                        <option selected value="">

                                        </option>
                                        {listPeriod.map((position, index) => (
                                            <option key={index} value={position.id}>{position.period_name}</option>
                                        ))}

                                    </Form.Select>
                                </Form.Group>
                                <div style={{ paddingLeft: 10 }}></div>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("sparepart_catalog_maintenance_plan.field_or")}</Form.Label>

                                </Form.Group>
                                <div style={{ paddingLeft: 10 }}></div>
                                <Form.Group className="mb-3">
                                    <TextBox style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",

                                    }} disabledForm={true} value={selectedMaintenancePlan.running_hour_interval} placeholder=""
                                        caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_running_hour_interval")}
                                        setValueFunction={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, running_hour_interval: e.target.value });
                                        }}
                                        req={true} />
                                </Form.Group>
                                <div style={{ paddingLeft: 10 }}></div>
                                <Form.Group className="mb-3">
                                    <TextBox style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",

                                    }} disabledForm={true} value={selectedMaintenancePlan.warning_at} placeholder=""
                                        caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_warning_at")}
                                        setValueFunction={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, warning_at: Number(e.target.value) });
                                        }}
                                        req={true} />
                                </Form.Group>
                                <div style={{ paddingLeft: 10 }}></div>
                                <Form.Group className="mb-3">
                                    <TextBox style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",

                                    }} disabledForm={true} value={selectedMaintenancePlan.contact_count} placeholder=""
                                        caption={t("sparepart_catalog_maintenance_plan.field_contact_count")}
                                        setValueFunction={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, contact_count: e.target.value });
                                        }}
                                        req={true} />
                                </Form.Group>
                                <div style={{ paddingLeft: 10 }}></div>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("sparepart_catalog_maintenance_plan.field_maintenance_plan_next_maintenance_date")}</Form.Label>
                                    <Form.Control disabled onChange={(e) => {
                                        setSelectedMaintenancePlan({ ...selectedMaintenancePlan, next_maintenance_date: e.target.value })
                                    }} type="date" value={selectedMaintenancePlan.next_maintenance_date} ></Form.Control>
                                </Form.Group>
                            </div>
                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexWrap: "nowrap",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                {/* <div style={{ display: "flex", width: "100%" }}>
                <Button style={{ width: "100%" }} type="submit" disabled={saveButtonDisabled} ><Check2Circle />{` ${t("sparepart_catalog_maintenance_plan.save_button")}`}</Button>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
                <Button style={{ width: "100%" }} className="cancel" variant="danger" onClick={() => {
                    setShowMaintenancePlanDetailModal(false);
                }}><XCircle />{` ${t("sparepart_catalog_maintenance_plan.cancel_button")}`}</Button>
            </div> */}


                            </div>
                        </Form>
                    </div>
                </Modal>
                
            </div>
        </>
    )
}