import { Button } from "react-bootstrap";
import "./WidthButton.css";

export default function WidthButton({ textBtn, handleOnClick }) {
    return (
        <>
            <Button
                className="widthButton"
                variant="outline-primary"
                onClick={() => handleOnClick()}

            >
                <div>{textBtn}</div>
            </Button>
        </>
    );
}