import "./BitLamp.css"

export default function BitLamp({ text, alarm, bitlampColor = "" , fontSize=16}) {
    return (
        <div className="bitLamp">
            {
                alarm === "true" ?
                    <div className="bitLampOn"></div> : <div className="bitlampBlack" ></div>
            }

            <div className="textBitLamp" style={{ fontSize }}>{text}</div>
        </div>
    )
}