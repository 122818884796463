import "./index.css";
import "../../../SMSApp.css";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import WidthButton from "../../../Components/WidthButton/WidthButton";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import BitLamp2 from "../../../Components/BitLamp2/BitLamp2";
import { useCookies } from "react-cookie";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useState, useEffect } from "react";
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";

export default function BelowMainDeck() {
    const navigate = useNavigate();
    const navigateWheelHouse = () => { navigate("/FireAlarm/WheelHouse") };
    const navigateMainDeck = () => { navigate("/FireAlarm/MainDeck") };
    const [belowMainDeckBitlamp, setBelowMainDeckBitlamp] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    // const [belowMainDeckBitlamp, setBelowMainDeckBitlamp] = useState([
    //     {text: "Manual Call Point Engine Room", alarm: false, coordinate:"M294"},
    //     {text: "Engine Room 4 Heat S", alarm: true, coordinate:"M292"},
    //     {text: "Engine Room 2 Heat P", alarm: false, coordinate:"M288"},
    //     {text: "Crew Room", alarm: false, coordinate:"M40"},
    //     {text: "CO2 Room", alarm: true, coordinate:"M270"},
    //     {text: "Crew Room 1", alarm: true, coordinate:"M282"},
    //     {text: "Crew Room 2", alarm: true, coordinate:"M284"},
    //     {text: "Engine Room 1 Smoke P", alarm: false, coordinate:"M286"},
    //     {text: "Engine Room 3 Smoke S", alarm: true, coordinate:"M290"},
    // ])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (belowMainDeckBitlamp.length > 0) {
            const interval = setInterval(async () => {
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval)
        }

    }, [belowMainDeckBitlamp]);

    const loadBitlampValue = async () => {
        try {
            let coordinate = belowMainDeckBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = belowMainDeckBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setBelowMainDeckBitlamp(updatedList);

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "fire_alarm", cookies.vesselId);
            let newLisst = response.filter(p => p.sub_coordinate_group === "below_main_deck")
            setBelowMainDeckBitlamp(newLisst);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Fire Alarm - Main Deck'}/>
                    <BottomNavBar/>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm")}}>Events Alarm</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/Alarm/Logs")}}>Alarm Logs</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/MainDeck")}}>Main Deck</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/BelowMainDeck")}}>Below Main Deck</Button>
                    <Button variant="dark" style={{backgroundColor: '#050512'}} onClick={() => { navigate("/FireAlarm/WheelHouse")}}>Wheel House</Button>
                    <div className="containerImgBMD">
                        <div className="imgBelowMainDeck">
                            
                            {/* <div className="lampInImg1">
                                <BitLamp2 text={"DINING ROOM"} alarm={false} gapText={10} />
                            </div>
                            <div className="lampInImg2">
                                <BitLamp2 text={"SALOON"} alarm={true} gapText={10} />
                            </div>
                            <div className="lampInImg3">
                                <BitLamp2 text={"CAPTAIN"} alarm={true} gapText={0} />
                            </div>
                            <div className="lampInImg4">
                                <BitLamp2 text={"GALLEY"} alarm={true} gapText={0} />
                            </div>
                            <div className="lampInImg5">
                                <BitLamp2 text={"C/ENG"} alarm={true} gapText={0} />
                            </div> */}
                        </div>
                        <div style={{backgroundColor: 'rgba(233, 233, 250, 0.1)', borderRadius: '12px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '400px', height: '500px', padding: '5%'}}>
                                <BitLamp text={'Manual Call Point ER'} bitlampColor = "black"/>
                                <BitLamp text={'Engine Room 4 Heat S'} bitlampColor = "black"/>
                                <BitLamp text={'Engine Room 2 Heat S'} bitlampColor = "black"/>
                                <BitLamp text={'Crew Room'} bitlampColor = "black"/>
                                <BitLamp text={'CO2 Room'} bitlampColor = "black"/>
                                <BitLamp text={'Crew Room 1'} bitlampColor = "black"/>
                                <BitLamp text={'Crew Room 2'} bitlampColor = "black"/>
                                <BitLamp text={'Engine Room 1 Smoke P'} bitlampColor = "black"/>
                                <BitLamp text={'Engine Room 3 Smoke S'} bitlampColor = "black"/>
                            </div>
                        </div>

                </div>
            </div>
        </div>
        </>
    );
}