import "./SMSNavContainer.css"
import { useEffect, useState } from "react";
import LampNavButton from "../LampNavButton/LampNavButton";
import NavButton from "../NavButton/NavButton";
import { useNavigate } from "react-router-dom";

export default function SMSNavContainer(props) {
    const { smsSerialNumber, activeTab, childContent, handleMenuClick } = props;
    // const [ datasmsSerialNumber, setDataSmsSerialNumber] = useState(props.smsSerialNumber);
    const navigate = useNavigate();
    const navigateMainEngine = () => { navigate("/MainEngine") }
    // const navigateSpeedNTemp = () => { navigate("/MainEngine/SpeedTemp") }
    // const navigatePortCalibration = () => { navigate("/MainEngine/PortCalibration") }
    // const navigatePortCyl1 = () => { navigate("/MainEngine/PortCyl1") }
    // const navigateLOPressLastBearing = () => { navigate("/MainEngine/LOPressLastBearing") }
    // const navigateAuxEngine = () => { navigate("/AuxEngine") };
    // const navigateGearBox = () => { navigate("/GearBox") }
    // const navigateBilgesTanks = () => { navigate("/BilgesTanks") }
    // const navigateLevelSoundingTanks = () => { navigate("/LevelSoundingTanks") }
    // const navigateMiscellaneous = () => { navigate("/Miscellaneous") }
    const navigateAlarm = () => { navigate("/Alarm", { state: { datasmsSerialNumber: props.smsSerialNumber } });  }
    // const navigateWatertightDoor = () => { navigate("/WatertightDoor") }
    // const navigateMSB = () => { navigate("/MSB") }
    // const navigatePumpMotor = () => { navigate("/PumpMotor") }
    // const navigateNavSignalLight = () => { navigate("/NavSignalLightPanel") }

    useEffect(() => {
        console.log(props);
    }, []);

    return (
        <>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div className="bottomNavBarContainer">
                    <LampNavButton text1={"MAIN"} text2={"ENGINE"} lamp={activeTab === 0 ? true : false} handleOnClick={()=> {
                        handleMenuClick(0)
                    }} />
                    <LampNavButton text1={"AUX"} text2={"ENGINE"} lamp={activeTab === 1 ? true : false} handleOnClick={()=> {
                        handleMenuClick(1)
                    }} />
                    <LampNavButton text1={"PUMP AND"} text2={"MOTOR"} lamp={activeTab === 2 ? true : false} handleOnClick={()=> {
                        handleMenuClick(2)
                    }} />
                    <LampNavButton text1={"TANK"} text2={"LEVEL"} lamp={activeTab === 3 ? true : false} handleOnClick={()=> {
                        handleMenuClick(3)
                    }} />
                    <LampNavButton text1={"WATERTIGHT"} text2={"DOOR"} lamp={activeTab === 4 ? true : false} handleOnClick={()=> {
                        handleMenuClick(4)
                    }} />
                    <LampNavButton text1={"MAIN"} text2={"SWITCHBOARD"} lamp={activeTab === 5 ? true : false} handleOnClick={()=> {
                        handleMenuClick(5)
                    }} />
                    <LampNavButton text1={"NAV"} text2={"SIGNAL LIGHT"} lamp={activeTab === 6 ? true : false} handleOnClick={()=> {
                        handleMenuClick(6)
                    }} />
                    <LampNavButton text1={"BILGES"} text2={""} lamp={activeTab === 7 ? true : false} handleOnClick={()=> {
                        handleMenuClick(7)
                    }} />
                    <LampNavButton text1={"VESSEL"} text2={"LOCATION"} lamp={activeTab === 8 ? true : false} handleOnClick={()=> {
                        handleMenuClick(8)
                    }} />
                    <LampNavButton text1={"FIRE"} text2={"ALARM"} lamp={activeTab === 9 ? true : false} handleOnClick={()=> {
                        handleMenuClick(9)
                    }} />
                    <LampNavButton text1={"HISTORY"} text2={""} lamp={activeTab === 10 ? true : false} handleOnClick={()=> {
                        handleMenuClick(10)
                    }} />
                    {/* <NavButton text1={"ALARM"} handleOnClick={navigateAlarm} /> */}
                </div>
                <div style={{display: "flex",flexDirection: "column", marginTop: "20px"}}>
                    {props.childContent}
                </div>
            </div>
        </>
    )
}