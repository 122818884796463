import "./index.css";
import BitLamp from "../../../Components/NewBitLamp/NewBitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
// import { Col, Row } from "react-bootstrap";
import PictMainDeck from "../../../Assets/Images/mainDeck.png";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import moment from "moment";

export default function CustomerSMSFireAlarmMainDeckPage(props) {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(false);
    const { smsSerialNumber, vesselId} = props;
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([20]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [bitlampList, setBitlampList] = useState({

        M50:
        {
            text: "Gang/Way",
            alarm: true,
            coordinate: "M50",
            value: false
        },
        M52: {
            text: "Deck Store",
            alarm: true,
            coordinate: "M52",
            value: false
        },
        M40: {
            text: "Saloon",
            alarm: true,
            coordinate: "M40",
            value: false
        },
        M38: {
            text: "Manual Call Point Laundry",
            alarm: true,
            coordinate: "M38",
            value: false
        },
        M42: {
            text: "Crew Room",
            alarm: true,
            coordinate: "M42",
            value: false
        },
        M44: {
            text: "Captain",
            alarm: true,
            coordinate: "M44",
            value: false
        },
        M46: {
            text: "Chief Engineer",
            alarm: true,
            coordinate: "M46",
            value: false
        },
        M48: {
            text: "Galley Heat",
            alarm: true,
            coordinate: "M48",
            value: false
        }
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log(bitlampList)
    }, [bitlampList]);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].read_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        // <>
        //     <div className="pageMainDeck">
        //         <div style={{display: 'flex', justifyContent: 'space-between'}} className="containerBoxWater">
        //             <div className="containerImgWH">
        //                 <img src={PictMainDeck} alt="" />
        //             </div>
        //             <div style={{display: 'flex', justifyContent: 'flex-start', padding: '5%'}}>
        //                 <div style={{height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: '0px 10px'}}>
        //                     <BitLamp text={'Gang/Way'} bitlampColor = "black" alarm={bitlampList["M50"].value}/>
        //                     <BitLamp text={'Deck Store'} bitlampColor = "black" alarm={bitlampList["M52"].value}/>
        //                     <BitLamp text={'Saloon'} bitlampColor = "black" alarm={bitlampList["M40"].value}/>
        //                     <BitLamp text={'Manual Cal Point Laundry'} bitlampColor = "black" alarm={bitlampList["M38"].value}/>
        //                     <BitLamp text={'Crew Room'} bitlampColor = "black" alarm={bitlampList["M42"].value}/>
        //                     <BitLamp text={'Captain'} bitlampColor = "black" alarm={bitlampList["M44"].value}/>
        //                     <BitLamp text={'Chief Engineer'} bitlampColor = "black" alarm={bitlampList["M46"].value}/>
        //                     <BitLamp text={'Galley Heat'} bitlampColor = "black" alarm={bitlampList["M48"].value}/>
        //                 </div>
        //             </div>
        //         </div>
        //         <div >
        //                 {
        //                     lastReceivedDate && <p style={{color: "white", fontSize: 20, marginLeft: '20px'}}>Last received data on {moment(lastReceivedDate).format("yyyy-MM-DD HH:mm:ss")}</p>
        //                 }
        //         </div>
        //    </div>
        //    <Loading loading={loading}/>
        // </>

        <>
            <div className="containerBoxMainDeck">
                <div className="containerImgWH">
                    <img src={PictMainDeck} alt="" />
                </div>
                <div className="bitlampContainerMainDeck">
                    <BitLamp text={'Gang/Way'} bitlampColor="black" alarm={bitlampList["M50"].value} />
                    <BitLamp text={'Deck Store'} bitlampColor="black" alarm={bitlampList["M52"].value} />
                    <BitLamp text={'Saloon'} bitlampColor="black" alarm={bitlampList["M40"].value} />
                    <BitLamp text={'Manual Cal Point Laundry'} bitlampColor="black" alarm={bitlampList["M38"].value} />
                    <BitLamp text={'Crew Room'} bitlampColor="black" alarm={bitlampList["M42"].value} />
                    <BitLamp text={'Captain'} bitlampColor="black" alarm={bitlampList["M44"].value} />
                    <BitLamp text={'Chief Engineer'} bitlampColor="black" alarm={bitlampList["M46"].value} />
                    <BitLamp text={'Galley Heat'} bitlampColor="black" alarm={bitlampList["M48"].value} />
                </div>
            </div>
        </>
    );
}