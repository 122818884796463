import "./index.css"
import { useState, useEffect, useRef, useMemo } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid, findSmsVesselPosition, getSMSCoordinateByCoordinateTypeForEngine, getPLCReadingValueForEngines } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import { Form, Button } from "react-bootstrap";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import DropDownForm from "../../../Components/DropDownForm";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import ThrottleBar from "../../../Components/ThrottleBar/ThrottleBar";
import GaugeComponent from 'react-gauge-component'
import NewGaugeComponent from "../../../Components/GaugeComponent2/index";
import BoxWithText from "../../../Components/BoxWithText/BoxWithText";
import moment from "moment";


export default function CustomerSMSAuxEnginePage(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    // const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [lastReceivedDateAEP, setLastReceivedDateAEP] = useState();
    const [lastReceivedDateAES, setLastReceivedDateAES] = useState();
    const [selectStartDate, setSelectStartDate] = useState(moment(new Date()).utc().subtract(30, "minutes").format("yyyy-MM-DD HH:mm:ss"));
    const [selectEndDate, setSelectEndDate] = useState(moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss"));
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [dataShowPort, setDataShowPort] = useState();
    const [dataShowSTBD, setDataShowSTBD] = useState();
    const [dataCreatedDate, setDataCreatedDate] = useState();
    const [isDataReady, setIsDataReady]= useState(false);
    const [coordinateValueAEP, setCoordinateValueAEP] = useState("");
    const [coordinateValueAES, setCoordinateValueAES] = useState("");

    const [coordinateList, setCoordinateList] = useState({

        AEP0:
        {
            text: "Engine Speed",
            alarm: false,
            coordinate: "AEP0",
            value: 0,
            value_unit: ""
        }, 
        AEP1:
        {
            text: "Freshwater Temperature",
            alarm: false,
            coordinate: "AEP1",
            value: 0,
            value_unit: ""
        }, 
        AEP2:
        {
            text: "Oil Temperature",
            alarm: false,
            coordinate: "AEP2",
            value: 0,
            value_unit: ""
        }, 
        AEP3:
        {
            text: "Oil Pressure",
            alarm: false,
            coordinate: "AEP3",
            value: 0,
            value_unit: ""
        }, 
        AEP4:
        {
            text: "Battery Voltage",
            alarm: false,
            coordinate: "AEP4",
            value: 0,
            value_unit: ""
        }, 
        AEP5:
        {
            text: "Running Hour",
            alarm: false,
            coordinate: "AEP5",
            value: 0,
            value_unit: ""
        }, 
        AEP6:
        {
            text: "Electrical Load",
            alarm: false,
            coordinate: "AEP6",
            value: 0,
            value_unit: ""
        }, 
        AEP7:
        {
            text: "Overspeed Alarm",
            alarm: false,
            coordinate: "AEP7",
            value: 0,
            value_unit: ""
        }, 
        AEP8:
        {
            text: "Oil Pressure Low Alarm",
            alarm: false,
            coordinate: "AEP8",
            value: 0,
            value_unit: ""
        }, 
        AEP9:
        {
            text: "Oil Pressure High Alarm",
            alarm: false,
            coordinate: "AEP9",
            value: 0,
            value_unit: ""
        }, 
        AEP10:
        {
            text: "Freshwater Temp Low Alarm",
            alarm: false,
            coordinate: "AEP10",
            value: 0,
            value_unit: ""
        }, 
        AEP11:
        {
            text: "Freshwater Temp High Alarm",
            alarm: false,
            coordinate: "AEP11",
            value: 0,
            value_unit: ""
        }, 
        AEP12:
        {
            text: "Oil Temperature Low Alarm",
            alarm: false,
            coordinate: "AEP12",
            value: 0,
            value_unit: ""
        }, 
        AEP13:
        {
            text: "Oil Temperature High Alarm",
            alarm: false,
            coordinate: "AEP13",
            value: 0,
            value_unit: ""
        }, 
        AEP14:
        {
            text: "Fuel Leak Alarm",
            alarm: false,
            coordinate: "AEP14",
            value: 0,
            value_unit: ""
        }, 
        AEP15:
        {
            text: "Battery Voltage Alarm",
            alarm: false,
            coordinate: "AEP15",
            value: 0,
            value_unit: ""
        }, 
        AEP16:
        {
            text: "Daily Running Hour",
            alarm: false,
            coordinate: "AEP16",
            value: 0,
            value_unit: ""
        }, 
        AEP17:
        {
            text: "Average Fuel Economy",
            alarm: false,
            coordinate: "AEP17",
            value: 0,
            value_unit: ""
        },
        AEP18:
        {
            text: "Instantaneous Fuel Economy",
            alarm: false,
            coordinate: "AEP18",
            value: 0,
            value_unit: ""
        },
        AEP19:
        {
            text: "Total Fuel Usage",
            alarm: false,
            coordinate: "AEP18",
            value: 0,
            value_unit: ""
        },





        AES0:
        {
            text: "Engine Speed",
            alarm: false,
            coordinate: "AES0",
            value: 0,
            value_unit: ""
        }, 
        AES1:
        {
            text: "Freshwater Temperature",
            alarm: false,
            coordinate: "AES1",
            value: 0,
            value_unit: ""
        }, 
        AES2:
        {
            text: "Oil Temperature",
            alarm: false,
            coordinate: "AES2",
            value: 0,
            value_unit: ""
        }, 
        AES3:
        {
            text: "Oil Pressure",
            alarm: false,
            coordinate: "AES3",
            value: 0,
            value_unit: ""
        }, 
        AES4:
        {
            text: "Battery Voltage",
            alarm: false,
            coordinate: "AES4",
            value: 0,
            value_unit: ""
        }, 
        AES5:
        {
            text: "Running Hour",
            alarm: false,
            coordinate: "AES5",
            value: 0,
            value_unit: ""
        }, 
        AES6:
        {
            text: "Electrical Load",
            alarm: false,
            coordinate: "AES6",
            value: 0,
            value_unit: ""
        }, 
        AES7:
        {
            text: "Overspeed Alarm",
            alarm: false,
            coordinate: "AES7",
            value: 0,
            value_unit: ""
        }, 
        AES8:
        {
            text: "Oil Pressure Low Alarm",
            alarm: false,
            coordinate: "AES8",
            value: 0,
            value_unit: ""
        }, 
        AES9:
        {
            text: "Oil Pressure High Alarm",
            alarm: false,
            coordinate: "AES9",
            value: 0,
            value_unit: ""
        }, 
        AES10:
        {
            text: "Freshwater Temp Low Alarm",
            alarm: false,
            coordinate: "AES10",
            value: 0,
            value_unit: ""
        }, 
        AES11:
        {
            text: "Freshwater Temp High Alarm",
            alarm: false,
            coordinate: "AES11",
            value: 0,
            value_unit: ""
        }, 
        AES12:
        {
            text: "Oil Temperature Low Alarm",
            alarm: false,
            coordinate: "AES12",
            value: 0,
            value_unit: ""
        }, 
        AES13:
        {
            text: "Oil Temperature High Alarm",
            alarm: false,
            coordinate: "AES13",
            value: 0,
            value_unit: ""
        }, 
        AES14:
        {
            text: "Fuel Leak Alarm",
            alarm: false,
            coordinate: "AES14",
            value: 0,
            value_unit: ""
        }, 
        AES15:
        {
            text: "Battery Voltage Alarm",
            alarm: false,
            coordinate: "AES15",
            value: 0,
            value_unit: ""
        }, 
        AES16:
        {
            text: "Daily Running Hour",
            alarm: false,
            coordinate: "AES16",
            value: 0,
            value_unit: ""
        }, 
        AES17:
        {
            text: "Average Fuel Economy",
            alarm: false,
            coordinate: "AES17",
            value: 0,
            value_unit: ""
        },
        AES18:
        {
            text: "Instantaneous Fuel Economy",
            alarm: false,
            coordinate: "AES18",
            value: 0,
            value_unit: ""
        },
        AES19:
        {
            text: "Total Fuel Usage",
            alarm: false,
            coordinate: "AES18",
            value: 0,
            value_unit: ""
        },
    });

    useEffect(() => {
        console.log('SMS Serial Number: ', smsSerialNumber);
        loadCoordinate();
    }, []);

    
    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    const durationOption = [
        {
            label: "1 Jam",
            value: 1,
        },
        {
            label: "1 Hari",
            value: 2,
        },
        {
            label: "1 Minggu",
            value: 3,
        },
    ];

    const prevCoordinateValueAEP = useRef({
        engineSpeed: {
            coordinate_desc: "Engine Speed",
            value_unit: "RPm",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 0,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        freshWaterTemperature: {
            coordinate_desc: "Freshwater Temperature",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 1,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilTemperature: {
            coordinate_desc: "Oil Temperature",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 2,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilPressure: {
            coordinate_desc: "Oil Pressure",
            value_unit: "MPa",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 3,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        batteryVoltage: {
            coordinate_desc: "Battery Voltage",
            value_unit: "V",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 4,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        runningHours: {
            coordinate_desc: "Running Hour",
            value_unit: "hors",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 5,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        electricalLoad: {
            coordinate_desc: "Electrical Load",
            value_unit: null,
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 6,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        overSpeedAlarm: {
            coordinate_desc: "Overspeed Alarm",
            value_unit: "RPm",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 7,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilPressureLowAlarm: {
            coordinate_desc: "Oil Pressure Low Alarm",
            value_unit: "MPa",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 8,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilPressureHighAlarm: {
            coordinate_desc: "Oil Pressure High Alarm",
            value_unit: "MPa",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 9,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        freshWaterTemperatureLowAlarm: {
            coordinate_desc: "Freshwater Temp Low Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 10,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        freshWaterTemperatureHighlarm: {
            coordinate_desc: "Freshwater Temp High Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 11,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilTemperatureLowAlarm: {
            coordinate_desc: "Oil Temperature Low Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 12,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilTemperatureHighAlarm: {
            coordinate_desc: "Oil Temperature High Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 13,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },


        fuelLeakAlarm: {
            coordinate_desc: "Fuel Leak Alarm",
            value_unit: null,
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 14,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        batteryVoltageAlarm: {
            coordinate_desc: "Battery Voltage Alarm",
            value_unit: "V",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 15,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        dailyRunningHours: {
            coordinate_desc: "Daily Running Hour",
            value_unit: "hours",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 16,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        averageFuelEconomy: {
            coordinate_desc: "Average Fuel Economy",
            value_unit: "L/h",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 17,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },

        instantaneousFuelEconomy: {
            coordinate_desc: "Instantaneous Fuel Economy",
            value_unit: "L/h",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 18,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        totalFuelUsage: {
            coordinate_desc: "Total Fuel Usage",
            value_unit: null,
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 19,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        }
    })

    const prevCoordinateValueAES = useRef({
        engineSpeed: {
            coordinate_desc: "Engine Speed",
            value_unit: "RPm",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 0,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        freshWaterTemperature: {
            coordinate_desc: "Freshwater Temperature",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 1,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilTemperature: {
            coordinate_desc: "Oil Temperature",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 2,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilPressure: {
            coordinate_desc: "Oil Pressure",
            value_unit: "MPa",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 3,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        batteryVoltage: {
            coordinate_desc: "Battery Voltage",
            value_unit: "V",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 4,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        runningHours: {
            coordinate_desc: "Running Hour",
            value_unit: "hors",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 5,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        electricalLoad: {
            coordinate_desc: "Electrical Load",
            value_unit: null,
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 6,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        overSpeedAlarm: {
            coordinate_desc: "Overspeed Alarm",
            value_unit: "RPm",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 7,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilPressureLowAlarm: {
            coordinate_desc: "Oil Pressure Low Alarm",
            value_unit: "MPa",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 8,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilPressureHighAlarm: {
            coordinate_desc: "Oil Pressure High Alarm",
            value_unit: "MPa",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 9,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        freshWaterTemperatureLowAlarm: {
            coordinate_desc: "Freshwater Temp Low Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 10,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        freshWaterTemperatureHighlarm: {
            coordinate_desc: "Freshwater Temp High Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 11,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilTemperatureLowAlarm: {
            coordinate_desc: "Oil Temperature Low Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 12,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        oilTemperatureHighAlarm: {
            coordinate_desc: "Oil Temperature High Alarm",
            value_unit: "°C",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 13,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },


        fuelLeakAlarm: {
            coordinate_desc: "Fuel Leak Alarm",
            value_unit: null,
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 14,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        batteryVoltageAlarm: {
            coordinate_desc: "Battery Voltage Alarm",
            value_unit: "V",
            is_alarm: false,
            coordinate_type: "AEP",
            coordinate_number: 15,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        dailyRunningHours: {
            coordinate_desc: "Daily Running Hour",
            value_unit: "hours",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 16,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        averageFuelEconomy: {
            coordinate_desc: "Average Fuel Economy",
            value_unit: "L/h",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 17,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },

        instantaneousFuelEconomy: {
            coordinate_desc: "Instantaneous Fuel Economy",
            value_unit: "L/h",
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 18,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        },
        totalFuelUsage: {
            coordinate_desc: "Total Fuel Usage",
            value_unit: null,
            is_alarm: false,
            coordinate_type: "AES",
            coordinate_number: 19,
            reading_value: "0",
            received_date: "2024-08-30T17:44:27.701Z"
        }
    })

    useEffect( () => {
        // console.log('AEP & AES to show',coordinateList);
        setIsDataReady(true);
    }, [coordinateList])

    useEffect(() => {
        setLoading(false);
        console.log("AEP to show:", coordinateValueAEP);
        if (coordinateValueAEP !== undefined) {
            if (coordinateValueAES) {
                setIsDataReady(true);
            }
            prevCoordinateValueAEP.current = coordinateValueAEP;
        }
    }, [coordinateValueAEP]);

    useEffect(() => {
        setLoading(false);
        console.log("AES to show:", coordinateValueAES);
        if (coordinateValueAES !== undefined) {
            if (coordinateValueAEP) {
                setIsDataReady(true);
            }
            prevCoordinateValueAES.current = coordinateValueAES;
        }
    }, [coordinateValueAES]);

    const AEPComponent = ({ aepValue }) => {
        const memoizedChild = useMemo(() => {
            return <div>
                <div className="gaugeComponentContainerNew">
                            <div style={{width:'90%'}}><NewGaugeComponent value={aepValue ? aepValue.engineSpeed['reading_value'] : '0'} unit={'RPM'}/></div>
                </div>
                <div className="mainEngineNewResLeftBOTTOM">
                        <div style={{ width: '100%', padding: '10px'}}>
                            <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily:'Disket Mono' }}>{"Fuel Usage"}</p>
                            <div className="fuelUsageContainerBox">
                                <BoxWithText title={'TOTAL'} value={aepValue.totalFuelUsage ? aepValue.totalFuelUsage['reading_value'] : '0'} unit={' L'}/> 
                                <BoxWithText title={'AVG'} value={aepValue.averageFuelEconomy ? aepValue.averageFuelEconomy['reading_value'] : '0'} unit={' L/H'}/>
                                <BoxWithText title={'CURRENT'} value={aepValue.instantaneousFuelEconomy ? aepValue.instantaneousFuelEconomy['reading_value'] : "0"} unit={' L/H'}/>
                            </div>
                        </div>
                        <div style={{ width: '100%', padding: '10px'}}>
                            <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily:'Disket Mono' }}>{"Running Hour"}</p>
                            <div className="fuelUsageContainerBox">
                                <BoxWithText title={'TOTAL'} value={aepValue.runningHours ? aepValue.runningHours['reading_value'] : "0"} unit={' H'} />
                                <BoxWithText title={'AFTER GO'} value={aepValue.runningHours ? aepValue.runningHours['reading_value'] : "0"} unit={' L/H'} />
                                <BoxWithText title={'DAILY'} value={aepValue.dailyRunningHours ? aepValue.dailyRunningHours['reading_value'] : "0"} unit={' H'} />
                            </div>
                        </div>
                        <div className="progressBarContainerNew">
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="L. O Pressure"
                                    value={parseFloat(aepValue.oilPressure ? aepValue.oilPressure['reading_value'] : "0").toFixed(2)}
                                    min={0}
                                    max={2}
                                    redRange={{ min: 0, max: 0.06 }}
                                    yellowRange={{ min: 0.07, max: 0.1 }}
                                    greenRange={{ min: 0.2, max: 2 }}
                                    unit=" MPA"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="F. W Temperature"
                                    value={parseFloat(aepValue.freshWaterTemperature ? aepValue.freshWaterTemperature['reading_value'] : "0").toFixed(0)}
                                    min={0}
                                    max={100}
                                    redRange={{ min: 98, max: 100 }}
                                    yellowRange={{ min: 95, max: 97 }}
                                    greenRange={{ min: 0, max: 94 }}
                                    unit=" °C"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="L. O Temperature"
                                    value={parseFloat(aepValue.oilTemperature ? aepValue.oilTemperature['reading_value'] : "0").toFixed(0)}
                                    min={0}
                                    max={120}
                                    redRange={{ min: 115, max: 120 }}
                                    yellowRange={{ min: 100, max: 114 }}
                                    greenRange={{ min: 0, max: 99 }}
                                    unit=" °C"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="Battery Voltage"
                                    value={parseFloat(aepValue.batteryVoltage ? aepValue.batteryVoltage['reading_value'] : "0").toFixed(0)}
                                    min={0}
                                    max={30}
                                    redRange={{ min: 0, max: 23.3 }}
                                    yellowRange={{ min: 23.4, max: 23.9 }}
                                    greenRange={{ min: 23.9, max: 30 }}
                                    unit=" V"
                                    highValue="0"
                                    lowValue="0"
                                    />
                            </div>
                        </div>
                        <div className="lastReceivedDateContainerNew">
                            <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDateAEP}</p>
                        </div>
                    </div>


            </div>;

        }, [aepValue]);

        return <div className="bgForAuxEngineContainerLeftBottom">
            {memoizedChild}
        </div>;
    }

    const AESComponent = ({ aesValue }) => {
        const memoizedChild = useMemo(() => {
            return <div>
                <div className="gaugeComponentContainerNew">
                            <div style={{width:'90%'}}><NewGaugeComponent value={aesValue ? aesValue.engineSpeed['reading_value'] : '0'} unit={'RPM'}/></div>
                </div>
                <div className="mainEngineNewResLeftBOTTOM">
                        <div style={{ width: '100%', padding: '10px'}}>
                            <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily:'Disket Mono' }}>{"Fuel Usage"}</p>
                            <div className="fuelUsageContainerBox">
                                <BoxWithText title={'TOTAL'} value={aesValue.totalFuelUsage ? aesValue.totalFuelUsage['reading_value'] : '0'} unit={' L'}/> 
                                <BoxWithText title={'AVG'} value={aesValue.averageFuelEconomy ? aesValue.averageFuelEconomy['reading_value'] : '0'} unit={' L/H'}/>
                                <BoxWithText title={'CURRENT'} value={aesValue.instantaneousFuelEconomy ? aesValue.instantaneousFuelEconomy['reading_value'] : ""} unit={' L/H'}/>
                            </div>
                        </div>
                        <div style={{ width: '100%', padding: '10px'}}>
                            <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily:'Disket Mono' }}>{"Running Hour"}</p>
                            <div className="fuelUsageContainerBox">
                                <BoxWithText title={'TOTAL'} value={aesValue.runningHours ? aesValue.runningHours['reading_value'] : "0"} unit={' H'} />
                                <BoxWithText title={'AFTER GO'} value={aesValue.runningHours ? aesValue.runningHours['reading_value'] : "0"} unit={' H'} />
                                <BoxWithText title={'DAILY'} value={aesValue.dailyRunningHours ? aesValue.dailyRunningHours['reading_value'] : "0"} unit={' H'} />
                            </div>
                        </div>
                        <div className="progressBarContainerNew">
                        <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="L. O Pressure"
                                    value={parseFloat(aesValue.oilPressure ? aesValue.oilPressure['reading_value'] : "0").toFixed(2)}
                                    min={0}
                                    max={2}
                                    redRange={{ min: 0, max: 0.06 }}
                                    yellowRange={{ min: 0.07, max: 0.1 }}
                                    greenRange={{ min: 0.2, max: 2 }}
                                    unit=" MPA"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="F. W Temperature"
                                    value={parseFloat(aesValue.freshWaterTemperature ? aesValue.freshWaterTemperature['reading_value'] : "0").toFixed(0)}
                                    min={0}
                                    max={100}
                                    redRange={{ min: 98, max: 100 }}
                                    yellowRange={{ min: 95, max: 97 }}
                                    greenRange={{ min: 0, max: 94 }}
                                    unit=" °C"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="L. O Temperature"
                                    value={parseFloat(aesValue.oilTemperature ? aesValue.oilTemperature['reading_value'] : "0").toFixed(0)}
                                    min={0}
                                    max={120}
                                    redRange={{ min: 115, max: 120 }}
                                    yellowRange={{ min: 100, max: 114 }}
                                    greenRange={{ min: 0, max: 99 }}
                                    unit=" °C"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            <div className="progressBarContainerInner">
                                <ProgressBar
                                    text="Battery Voltage"
                                    value={parseFloat(aesValue.batteryVoltage ? aesValue.batteryVoltage['reading_value'] : "0").toFixed(0)}
                                    min={0}
                                    max={30}
                                    redRange={{ min: 0, max: 23.3 }}
                                    yellowRange={{ min: 23.4, max: 23.9 }}
                                    greenRange={{ min: 23.9, max: 30 }}
                                    unit=" V"
                                    highValue="0"
                                    lowValue="0"
                                    />
                            </div>
                        </div>
                        <div className="lastReceivedDateContainerNew">
                            <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDateAES}</p>
                        </div>
                    </div>

            </div>;

        }, [aesValue]);

        return <div className="bgForAuxEngineContainerRightBottom">
            {memoizedChild}
        </div>;
    }


    const loadCoordinate = async () => {
        try{
            let coordinateTypePort = 'AEP';
            let coordinateTypeSTBD = 'AEP';
            let responseAEP = await getPLCReadingValueForEngines(cookies.token, smsSerialNumber, vesselId, coordinateTypePort, coordinateTypeSTBD);
            //console.log('responseAEP',responseAEP);
            if (responseAEP !== undefined) {
                if (responseAEP != []) {
                    let coordValueObjAEP = {};
                    coordValueObjAEP.engineSpeed = responseAEP.filter(p => p.coordinate_number == 0)[0];
                    coordValueObjAEP.freshWaterTemperature = responseAEP.filter(p => p.coordinate_number == 1)[0];
                    coordValueObjAEP.oilTemperature = responseAEP.filter(p => p.coordinate_number == 2)[0];
                    coordValueObjAEP.oilPressure = responseAEP.filter(p => p.coordinate_number == 3)[0];
                    coordValueObjAEP.batteryVoltage = responseAEP.filter(p => p.coordinate_number == 4)[0];
                    coordValueObjAEP.runningHours = responseAEP.filter(p => p.coordinate_number == 5)[0];
                    coordValueObjAEP.electricalLoad = responseAEP.filter(p => p.coordinate_number == 6)[0];
                    coordValueObjAEP.overSpeedAlarm = responseAEP.filter(p => p.coordinate_number == 7)[0];
                    coordValueObjAEP.oilPressureLowAlarm = responseAEP.filter(p => p.coordinate_number == 8)[0];
                    coordValueObjAEP.oilPressureHighAlarm = responseAEP.filter(p => p.coordinate_number == 9)[0];
                    coordValueObjAEP.fwTemperatureLowAlarm = responseAEP.filter(p => p.coordinate_number == 10)[0];
                    coordValueObjAEP.fwTemperatureHighAlarm = responseAEP.filter(p => p.coordinate_number == 11)[0];
                    coordValueObjAEP.oilTemperatureLowAlarm = responseAEP.filter(p => p.coordinate_number == 12)[0];
                    coordValueObjAEP.oilTemperatureHighAlarm = responseAEP.filter(p => p.coordinate_number == 13)[0];
                    coordValueObjAEP.fuelLeakAlarm = responseAEP.filter(p => p.coordinate_number == 14)[0];
                    coordValueObjAEP.batteryVoltageAlarm = responseAEP.filter(p => p.coordinate_number == 15)[0];
                    coordValueObjAEP.dailyRunningHours = responseAEP.filter(p => p.coordinate_number == 16)[0];
                    coordValueObjAEP.averageFuelEconomy = responseAEP.filter(p => p.coordinate_number == 17)[0];
                    coordValueObjAEP.instantaneousFuelEconomy = responseAEP.filter(p => p.coordinate_number == 18)[0];
                    coordValueObjAEP.totalFuelUsage = responseAEP.filter(p => p.coordinate_number == 19)[0];
                    if (coordValueObjAEP.batteryVoltage !== undefined) {
                        setCoordinateValueAEP(coordValueObjAEP);
                        let receivedDate = moment(responseAEP[0].received_date).format("yyyy-MM-DD HH:mm:ss");
                        setLastReceivedDateAEP(receivedDate);
                    }
                }
            }
            let coordinatePort = 'AES';
            let coordinateSTBD = 'AES';
            let responseAES = await getPLCReadingValueForEngines(cookies.token, smsSerialNumber, vesselId, coordinatePort, coordinateSTBD);
            //console.log('responseAES',responseAES);
            if (responseAES !== undefined) {
                if (responseAES != []) {
                    let coordValueObjAES = {};
                    coordValueObjAES.engineSpeed = responseAES.filter(p => p.coordinate_number == 0)[0];
                    coordValueObjAES.freshWaterTemperature = responseAES.filter(p => p.coordinate_number == 1)[0];
                    coordValueObjAES.oilTemperature = responseAES.filter(p => p.coordinate_number == 2)[0];
                    coordValueObjAES.oilPressure = responseAES.filter(p => p.coordinate_number == 3)[0];
                    coordValueObjAES.batteryVoltage = responseAES.filter(p => p.coordinate_number == 4)[0];
                    coordValueObjAES.runningHours = responseAES.filter(p => p.coordinate_number == 5)[0];
                    coordValueObjAES.electricalLoad = responseAES.filter(p => p.coordinate_number == 6)[0];
                    coordValueObjAES.overSpeedAlarm = responseAES.filter(p => p.coordinate_number == 7)[0];
                    coordValueObjAES.oilPressureLowAlarm = responseAES.filter(p => p.coordinate_number == 8)[0];
                    coordValueObjAES.oilPressureHighAlarm = responseAES.filter(p => p.coordinate_number == 9)[0];
                    coordValueObjAES.fwTemperatureLowAlarm = responseAES.filter(p => p.coordinate_number == 10)[0];
                    coordValueObjAES.fwTemperatureHighAlarm = responseAES.filter(p => p.coordinate_number == 11)[0];
                    coordValueObjAES.oilTemperatureLowAlarm = responseAES.filter(p => p.coordinate_number == 12)[0];
                    coordValueObjAES.oilTemperatureHighAlarm = responseAES.filter(p => p.coordinate_number == 13)[0];
                    coordValueObjAES.fuelLeakAlarm = responseAES.filter(p => p.coordinate_number == 14)[0];
                    coordValueObjAES.batteryVoltageAlarm = responseAES.filter(p => p.coordinate_number == 15)[0];
                    coordValueObjAES.dailyRunningHours = responseAES.filter(p => p.coordinate_number == 16)[0];
                    coordValueObjAES.averageFuelEconomy = responseAES.filter(p => p.coordinate_number == 17)[0];
                    coordValueObjAES.instantaneousFuelEconomy = responseAES.filter(p => p.coordinate_number == 18)[0];
                    coordValueObjAES.totalFuelUsage = responseAES.filter(p => p.coordinate_number == 19)[0];
                    if (coordValueObjAES.batteryVoltage !== undefined) {
                        setCoordinateValueAES(coordValueObjAES);
                        let receivedDate = moment(responseAEP[0].received_date).format("yyyy-MM-DD HH:mm:ss");
                        setLastReceivedDateAES(receivedDate);
                    }
                }
            }
           
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="auxEngineNEWCONTAINER">
                <div className="auxEngineNEWCONTAINERLeft">
                    {/* <div className="titleGaugeLeftAE">
                        <div>
                        <h1 className="txtforTitleOnMainEngine">PORT</h1>
                        <h1 className="txtforDetailOnMainEngine">SDEC SC4H95CF2</h1>
                        <h1 className="txtforDetailOnMainEngine">S/N: H923A023217</h1>
                        </div>
                    </div> */}
                    <AEPComponent aepValue={coordinateValueAEP !== undefined ? coordinateValueAEP : prevCoordinateValueAEP.current} />
                </div>
                <div className="auxEngineNEWCONTAINERRight">
                    {/* <div className="titleGaugeRightAE">
                        <div style={{textAlign: 'right'}}>
                        <h1 className="txtforTitleOnMainEngine">STARBOARD</h1>
                        <h1 className="txtforDetailOnMainEngine">SDEC SC4H95CF2</h1>
                        <h1 className="txtforDetailOnMainEngine">S/N: H923A023223</h1>
                        </div>
                    </div> */}
                    <AESComponent aesValue={coordinateValueAES !== undefined ? coordinateValueAES : prevCoordinateValueAES.current} />
                </div>
            </div>
        </>
    );
}