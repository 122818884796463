import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import {
    isTokenValid, createCountry, getDailyReportById, convertBase64, getUserAccessById, convertBase64Image,
    getEquipment, getMechanic, insertUpdateDailyReport, getVessel, getProject, getReportDailyReport
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft, PrinterFill } from "react-bootstrap-icons";
import moment from "moment";
import DailyReportListJob from "../../../../Components/DailyReportListJob";
import Select from "react-select";
import { Trans, useTranslation } from "react-i18next";

export default function DailyReportDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listProject, setListProject] = useState([]);
    const [svcEqpCount, setSvcEqpCount] = useState([]);
    const location = useLocation();
    const [disabledPrint, setDisabledPrint] = useState(false);
    const [loading, setLoading] = useState(true);
    const [docxData, setDocxData] = useState("");
    const [reportStaffName, setReportStaffName] = useState("");
    const [listVessel, setListVessel] = useState([]);
    const [dailyReportId, setDailyReportId] = useState("");
    const [selectedVessel, setSelectedVessel] = useState({});
    const [disabledAddDetail, setDisableAddDetail] = useState(false);
    const [listStaff, setListStaff] = useState([]);
    const [listEquipment, setListEquipment] = useState([]);
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState({});
    const [reportDate, setReportDate] = useState(null)
    const [updatedButton, setUpdatedButton] = useState("");
    const [resetComponent, setResetComponent] = useState(false);
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [dailyReport, setDailyReport] = useState({
        id: 0,
        report_date: null,
        description: "",
        location: "",
        staff_id: null,
        vessel_id: null
    });
    const [listTimePeriode, setListTimePeriode] = useState([]);
    const [listJob, setListJob] = useState([]);
    const [listJobPhoto, setListJobPhoto] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        if (resetComponent)
            setResetComponent(false);
    }, [resetComponent])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadAccess();
                setLoading(false);
                loadMechanic();
                loadEquipment();
                loadVessel();
                loadProject();
                setDailyReportId(location.state.reportId);
            }
        }
        checkCookies();
        // if (location.state.reportId === 0) {
        //     setLoading(false);
        // } else {
        //     // initCountry();
        // }
    }, []);

    useEffect(() => {
        if (dailyReportId !== "" && dailyReportId !== 0) {
            initDailyReport();
        }
    }, [dailyReportId])

    useEffect(() => {
        if (reportDate) {
            setDailyReport({ ...dailyReport, report_date: reportDate });
        }
    }, [reportDate]);

    useEffect(() => {
        if (selectedStaff.staff_name) {
            setDailyReport({ ...dailyReport, staff_id: selectedStaff.id });
            setReportStaffName(selectedStaff.staff_name);
        } else {
            setDailyReport({ ...dailyReport, staff_id: null });
            setReportStaffName("");
        }
    }, [selectedStaff]);

    useEffect(() => {
        if (selectedVessel.vessel_name) {
            setDailyReport({ ...dailyReport, vessel_id: selectedVessel.id })
        } else {
            setDailyReport({ ...dailyReport, vessel_id: null })
        }
    }, [selectedVessel]);

    useEffect(() => {
        if (listVessel.length > 0) {
            if (dailyReport.vessel_id) {
                let response = listVessel.find(p => p.id === dailyReport.vessel_id);
                if (response) {
                    setSelectedVessel(response);
                }

            }
        }
    }, [listVessel])

    useEffect(() => {
        if (listStaff.length > 0) {
            if (dailyReport.staff_id) {
                let response = listStaff.find(p => p.id === dailyReport.staff_id);
                if (response) {
                    setSelectedStaff(response);
                }

            }
        }
    }, [listStaff])

    useEffect(() => {
        // console.log(dailyReport);
        validationForm();
        if (dailyReport.staff_id) {
            let response = listStaff.find(p => p.id === dailyReport.staff_id);
            if (response) {
                setSelectedStaff(response);
            }

        }
        if (dailyReport.vessel_id) {
            let response = listVessel.find(p => p.id === dailyReport.vessel_id);
            if (response) {
                setSelectedVessel(response);
            }
        }
    }, [dailyReport])

    useEffect(() => {
        if (docxData.size > 0) {
            // console.log(docxData.size);
            downloadDocxFile();
        }
    }, [docxData]);


    useEffect(() => {
        console.log(listTimePeriode);
        setResetComponent(true);
        setSvcEqpCount(listTimePeriode.length);
        forceUpdate();
    }, [listTimePeriode]);

    useEffect(() => {
        console.log(listJob)
    }, [listJob])


    // useEffect(() => {
    //     validationForm();
    // }, [country]);


    const saveData = async () => {
        try {
            let dailyReportTmp = dailyReport;
            let listTimeTmp = listTimePeriode;
            for (let index = 0; index < listTimeTmp.length; index++) {
                const newList = listTimeTmp[index];
                newList.list_job = listJob[index]

            }
            dailyReportTmp.time_periode = listTimeTmp;
            // console.log(dailyReportTmp)
            let response = await insertUpdateDailyReport(cookies.token, dailyReportTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_daily_report.alert_success_save_data")}`);
                if (dailyReportId === 0) {
                    setDailyReportId(response.data.id)
                } else {
                    initDailyReport();
                }
            }
            setDisabledButton(true);
        } catch (exception) {

        }
    }
    const initDailyReport = async () => {
        try {
            let response = await getDailyReportById(cookies.token, dailyReportId);
            // console.log(response);
            if (response) {
                setDailyReport({
                    ...dailyReport,
                    id: response.id,
                    report_date: response.report_date,
                    description: response.description,
                    location: response.location,
                    staff_id: response.staff_id,
                    vessel_id: response.vessel_id
                })

                let listTimePeriodeTmp = []
                if (response.time_periode.length > 0) {
                    let listJobTmp = []
                    for (let index = 0; index < response.time_periode.length; index++) {
                        const list = response.time_periode[index];
                        listJobTmp.push(list.list_job)
                        list.list_job = []
                        listTimePeriodeTmp.push(list)
                    }
                    setListJob(listJobTmp);
                }
                // console.log(listTroubleshootingEquipmentTmp)
                setListTimePeriode(listTimePeriodeTmp);

            } else {
                alert(`${t("detail_daily_report.alert_failed_load_data")}`);
            }
            setLoading(false);
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (dailyReport.staff_id === undefined || dailyReport.staff_id === null) {
                    setErrors({
                        ...errors,
                        staffName: `${t("detail_daily_report.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (dailyReport.report_date === undefined || dailyReport.report_date === null) {
                    setErrors({
                        ...errors,
                        reportDate: `${t("detail_daily_report.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "staffName", "reportDate");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadMechanic = async () => {
        try {
            let response = await getMechanic(cookies.token, cookies.languageId, "null");
            // console.log(response);
            setListStaff(response);
        } catch (exception) {

        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            setListEquipment(response);
        } catch (exception) {

        }
    }

    const loadVessel = async () => {
        try {
            let response = await getVessel(cookies.token, cookies.languageId, "null");
            setListVessel(response);
        } catch (exception) {

        }
    }

    const handleOnblurDailyReport = (report) => {
        setDailyReport(report)

    };

    const handleOnBlur = (dailyReportPeriode, listJob2, index) => {
        let listPeriodeTmp = listTimePeriode;
        listPeriodeTmp[index] = dailyReportPeriode;
        setListTimePeriode(listPeriodeTmp.slice(0));
        let listJobTmp = listJob;
        listJobTmp[index] = listJob2;
        setListJob(listJobTmp.slice(0));

    };

    const handleDelete = (index) => {
        let listTimePeriodeTmp = listTimePeriode;
        let reportTmp = dailyReport
        if (!reportTmp.deleted_detail) {
            let list = [];
            list.push(listTimePeriodeTmp[index]);
            setDailyReport({ ...dailyReport, deleted_detail: list })
        } else {
            let list = dailyReport.deleted_detail;
            list.push(listTimePeriodeTmp[index])
            setDailyReport({ ...dailyReport, deleted_detail: list })
        }
        listTimePeriodeTmp.splice(index, 1);
        setListTimePeriode(listTimePeriodeTmp.slice(0));

        let listJobTmp = listJob
        listJobTmp.splice(index, 1)

        setListJob(listJobTmp.slice(0))
    };

    const renderTroubleshootingEquipment = () => {
        let renderObj = [];
        // if (listEquipment.length > 0) {
        for (let i = 0; i < listTimePeriode.length; i++) {
            const svcEqp = <div key={i}>
                <DailyReportListJob
                    resetComponent={resetComponent}
                    index={i}
                    handleOnBlur={handleOnBlur}
                    handleDelete={handleDelete}
                    handleDeleteDailyReportJob={handleDeleteDailyReportJob}
                    listJob={listJob[i]}
                    // listTroubleshootingJob={listTroubleshootingJob[i]}
                    dailyReport={dailyReport}
                    listEquipment={listEquipment}
                    listVesselEquipment={listVesselEquipment}
                    timePeriode={listTimePeriode[i]}
                    disabledAddDetail={setDisableAddDetail}
                    listStaff={listStaff}
                    handleDeleteDailyReportCrew={handleDeleteDailyReportCrew}
                    listProject={listProject}
                // disabledAddButton={disabledAddButton}
                // vesselId={selectedProject.vessel_id}
                // troubleshootingId={troubleshootingId}
                // troubleshooting={troubleshooting}
                // listTroubleshootingFinding={listTroubleshootingFinding}
                // disabledCustomerForm={disabledCustomerForm}
                />
            </div>;

            renderObj.push(svcEqp);
        }
        // }
        return renderObj;
    }

    const handleDeleteDailyReportJob = (listDeleted, index) => {
        setDailyReport({ ...dailyReport, deleted_job: listDeleted.deleted_job })
    };

    const handleDeleteDailyReportCrew = (listDeleted, index) => {
        setDailyReport({ ...dailyReport, deleted_crew: listDeleted.deleted_crew });
    }


    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, "null");
            let projects = response.filter(p => p.project_type_name === 'Troubleshooting & Repair');
            console.log(response);
            setListProject(projects);
        } catch (exception) {
        }
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${reportStaffName}-${moment(dailyReport.report_date).format("yyyy-MM-DD")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // console.log(file);
        // console.log(file.length);

        URL.revokeObjectURL(file);
        setLoading(false);

        setDisabledPrint(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getReportDailyReport(cookies.token, dailyReportId)
            if (docx.size) {
                setDocxData(docx);
            } else {
                alert(`${t("detail_daily_report.error_download")}`);
                setLoading(false);
                setDisabledPrint(false);
            }
            // setServiceJobId("")
        } catch (exception) {
            console.log(exception)
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_daily_report.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/DailyReport"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={dailyReportId !== 0 && dailyReportId !== "" ? true : false}
                    actionContainerChild={
                        <div>
                            <Button disabled={disabledPrint} variant="secondary" style={{

                            }} onClick={() => {
                                setDisabledPrint(true)
                                createDocument();
                                setLoading(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><PrinterFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.print_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        saveData();
                                        setDisabledButton(true);
                                        setLoading(true);
                                        setTimeout(function () {
                                            window.scrollTo(0, 0);
                                        }, 2);
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_daily_report.header_daily")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_daily_report.field_staff_name")}</Form.Label>


                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.staff_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listStaff} value={selectedStaff} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedStaff({})
                                                                } else {
                                                                    setSelectedStaff(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                        {
                                                            errors.staffName && <p style={{ color: "red" }}>{errors.staffName}</p>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_daily_report.field_vessel_name")}</Form.Label>


                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.vessel_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listVessel} value={selectedVessel} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedVessel({})
                                                                } else {
                                                                    setSelectedVessel(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />

                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_daily_report.field_report_date")}</Form.Label>
                                                        <Form.Control required type="date" onChange={(e) => {
                                                            if (e.target.value === "") {
                                                                // setDailyReport({ ...dailyReport, report_date: null });
                                                                setReportDate(null);
                                                            } else {
                                                                // setDailyReport({ ...dailyReport, report_date: e.target.value });
                                                                setReportDate(e.target.value)
                                                            }
                                                        }} value={dailyReport.report_date ? moment(dailyReport.report_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                    </Form.Group>
                                                    {
                                                        errors.reportDate && <p style={{ color: "red" }}>{errors.reportDate}</p>
                                                    }
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_daily_report.field_location")}</Form.Label>
                                                        <Form.Control type="text" onChange={(e) => {
                                                            setDailyReport({ ...dailyReport, location: e.target.value })
                                                        }} value={dailyReport.location}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_daily_report.field_description")}</Form.Label>
                                                        <Form.Control onChange={(e) => {
                                                            setDailyReport({ ...dailyReport, description: e.target.value });
                                                        }} as="textarea" rows={3}></Form.Control>
                                                    </Form.Group>

                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_daily_report.header_list_job")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {

                                                        renderTroubleshootingEquipment()

                                                    }
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button disabled={disabledAddDetail} variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                start_date: null,
                                                                end_date: null,
                                                            };
                                                            if (listTimePeriode.length === 0) {
                                                                let list = [];
                                                                list.push(newParameter);
                                                                // setTroubleshooting({ ...troubleshooting, detail_job: list });
                                                                setListTimePeriode(list.slice(0))
                                                            } else {
                                                                let list = listTimePeriode;
                                                                list.push(newParameter);
                                                                // setTroubleshooting({ ...troubleshooting, detail_job: list });
                                                                setListTimePeriode(list.slice(0))
                                                            }

                                                        }}>{t("detail_daily_report.button_add_detail")}</Button>
                                                    </div>
                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                        <div style={{ paddingBottom: 20 }}></div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5,
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                paddingRight: 5,
                                            }}><Button style={{width:100}} disabled={disabledButton} type="submit" variant="primary" >{t("detail_daily_report.save_button")}</Button></div>


                                            <div style={{
                                                paddingLeft: 5,
                                                width:100
                                            }}>
                                                <Button variant="danger" onClick={() => {
                                                    navigate('/DailyReport');
                                                }}>{t("detail_daily_report.cancel_button")}</Button>

                                            </div>
                                        </div>
                                    </Form>

                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}