import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form, ListGroup, Modal } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getSparepartCatalog, getSparepartCatalogCount, deleteSparepartCatalog,
    getEquipmentCategoryStruct, getVesselById, getVesselEquipmentByCategory, getEquipment, insertUpdateSparepartCatalog,
    getEquipmentPosition, getSparepartCatalogById, getEquipmentById, deleteEquipmentCategory, getAllSparepartCatalog,
    getSupplier, getManufacturer, insertUpdateManufacturer, getParameterEquipment, getUserAccessById,
    getSparepartBySparepartItem, getInventoryBrand, getSparepartInventory
} from "../../../Helpers/ApplicationHelpers";
import {
    getInventoryType, insertUpdateInventoryMaster, getInventoryMasterById, getInventoryTypeSubItem, getInventoryUnitType, getInventoryPeriod,
    getInventoryCondition, getInventoryStatus, checkBarcodeNumber,
    getInventoryGroup,
    getInventoryMaterial,
    getInventorySubGroupByGroupId, getInventoryMaster, getSelectedInventorySparepart
} from "../../../Helpers/InventoryHelpers";
import DeniReactTreeView from "deni-react-treeview"
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { FileCheckFill, BoxArrowUpRight, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search, PlusSquareFill } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import { omit } from 'lodash';
import Select from 'react-select';
import FormSelect from "../../../Components/FormSelect";
import { Trans, useTranslation } from "react-i18next";
import { TRUE } from "sass";

export default function SparepartCatalogPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [listManufacturer, setListManufacturer] = useState([]);
    const [listSparepart, setListSparepart] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [equipmentCategoryId, setEquipmentCategoryId] = useState("");
    const [sparepartOptions, setSparepartOptions] = useState([]);
    const [listSupplier, setListSupplier] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [manufacturerModal, setManufacturerModal] = useState("");
    const [selectedSupplier, setSelectedSuppliier] = useState({ id: "" });
    const [selectedManufacturer, setSelectedManufacturer] = useState({ id: "" });
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [listSearchParameter, setListSearchParameter] = useState({ parameter: [] });
    const [vessel, setVessel] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [parameter, setParameter] = useState([])
    const [equipmentModal, setEquipmentModal] = useState(false);
    const [listEquipmentCategory, setListEquipmentCategory] = useState([]);
    const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
    const [manufactureNameError, setManufactureNameError] = useState("");
    const [selectedLevel, setSelectedLevel] = useState(-1);
    const [listSearchedEquipmentCategory, setListSearchedEquipmentCategory] = useState([]);
    const [selectedVesselEquipment, setSelectedVesselEquipment] = useState({});
    const [selectedSparepart, setSelectedSparepart] = useState([]);
    const [detailEquipmentCategory, setDetailEquipmentCategoru] = useState([]);
    const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [equipmentId, setEquipmentId] = useState("");
    const [sparepartId, setSparepartId] = useState("");
    const [listEquipment, setListEquipment] = useState([]);
    const [disabledButtonForm, setDisabledButtonForm] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQueryInventory, setSearchQueryInventory] = useState("");
    const [findInventoryModal, setFindInventoryModal] = useState(false);
    const [listInventoryGroup, setListInventoryGroup] = useState([]);
    const [listInventorySubGroup, setListInventorySubGroup] = useState([]);
    const [listInventoryMaterial, setListInventoryMaterial] = useState([]);
    const [listInventoryBrand, setListInventoryBrand] = useState([]);
    const [listResponInventory, setListResponInventory] = useState([]);
    const [disabledButtonInventory, setDisabledButtonInventory] = useState(true);
    const [getFirstlistSelectedInventorySparepart, setGetFirstlistSelectedInventorySparepart] = useState([]);
    const [selectedInventoryMaster, setSelectedInventoryMaster] = useState({
        id: 0,
        inventory_master_name: "",
        inventory_group_id: "",
        inventory_subgroup_id: "",
        inventory_material_id: "",
        inventory_brand_id: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        brand_name: "",
        inventory_group_code: "",
        inventory_subgroup_code: "",
        inventory_material_code: "",
        inventory_brand_code: "",
        part_number: "",
        parameters: []
    });
    const [selectedGroup, setSelectedGroup] = useState({
        id: "",
        inventory_group_name: "",
        inventory_group_code: "",
    });
    const [selectedSubGroup, setSelectedSubGroup] = useState({
        id: "",
        inventory_subgroup_name: "",
        inventory_subgroup_code: "",
    });
    const [selectedMaterial, setSelectedMaterial] = useState({
        id: "",
        inventory_material_name: "",
        inventory_material_code: "",
    });
    const [selectedBrand, setSelectedBrand] = useState({
        id: "",
        inventory_brand_name: "",
        inventory_brand_code: "",
    });

    const [inventoryMaster, setInventoryMaster] = useState({
        id: 0,
        inventory_group_id: "",
        inventory_subgroup_id: "",
        inventory_material_id: "",
        inventory_brand_id: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        brand_name: "",
        inventory_group_code: "",
        inventory_subgroup_code: "",
        inventory_material_code: "",
        inventory_brand_code: "",
    });

    const [selectedInventoryItem, setSelectedInventoryItem] = useState({
        id: 0,
        inventory_master_name: "",
        inventory_group_id: "",
        inventory_subgroup_id: "",
        inventory_material_id: "",
        inventory_brand_id: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        brand_name: "",
        inventory_group_code: "",
        inventory_subgroup_code: "",
        inventory_material_code: "",
        inventory_brand_code: "",
    });

    const [equipmentCategory, setEquipmentCategory] = useState({
        id: 0,
        equipment_name: "",
        equipment_description: "",
        parent_id: null
    })

    const [manufacturer, setManufacturer] = useState({
        id: "0",
        manufacturer_name: "",
        description: "",
    })

    const [sparepartCatalog, setSparepartCatalog] = useState({
        id: 0,
        equipment_id: null,
        title: "",
        description: "",
        model: "",
        manufacture_name: null,
        part_number: "",
        equipment_position_id: null,
        parent_id: null,
        parameters: [],
        supplier_id: "",
        ext_customer_id: cookies.extCustomerId
    });



    useEffect(() => {
        if (sparepartCatalog.sparepart_item_id) {
            let newList = sparepartOptions.find(p => p.id === sparepartCatalog.sparepart_item_id);
            if (newList) {
                setSelectedItem(newList);
            }
        }
        if (sparepartCatalog.manufacture_name) {
            setManufactureNameError("");
            validationForm();
            let response = listManufacturer.find(p => p.id === sparepartCatalog.manufacture_name);
            if (response) {
                setSelectedManufacturer(response);
            }
            else {
                setSelectedManufacturer({})
            }
        } else {
            setManufactureNameError(`${t("sparepart_catalogue.error_empty_field")}`);
            setDisabledButtonForm(true);
        }
        async function loadEquipment() {
            await loadDropdownList();
        }

        loadEquipment();
        validationForm();
        //forceUpdate();
    }, [sparepartCatalog])


    // useEffect(() => {
    //     if (selectedSupplier.supplier_name) {
    //         setSparepartCatalog({ ...sparepartCatalog, supplier_id: selectedSupplier.id });
    //     } else {
    //         setSparepartCatalog({ ...sparepartCatalog, supplier_id: null });
    //     }
    // }, [selectedSupplier]);

    /* useEffect(() => {
        if (selectedManufacturer.manufacturer_name) {
            setSparepartCatalog({ ...sparepartCatalog, manufacture_name: selectedManufacturer.id });
        } else {
            setSparepartCatalog({ ...sparepartCatalog, manufacture_name: null });
        }
    }, [selectedManufacturer]);
 */


    useEffect(() => {
        forceUpdate();
        if (selectedCategory) {
            loadParameterEquipment();

        }
    }, [selectedCategory])

    let listEquipmentCategoryTmp = [];
    let selectedEquipmentCategoryTmp = [];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadEquipmentPosition();
                loadSparepartCatalog();
                loadSupplier();
                loadAccess();
                loadManufacturer();
                loadGroup();
                loadSubGroup();
                loadMaterial();
                loadInventoryBrand();
            }
        }
        checkCookies();
        console.log(cookies);
    }, []);

    useEffect(() => {
        forceUpdate();
        console.log(listSparepart);
    }, [listSparepart])

    useEffect(() => {
        // console.log()
        if (selectedSparepart.inventory_master_id) {

            // setSelectedInventoryMaster(
            // {
            //     id: selectedSparepart.inventory_master_id,
            //     inventory_master_name: selectedSparepart.inventory_master_name,
            //     inventory_group_id: selectedSparepart.inventory_group_id,
            //     inventory_subgroup_id: selectedSparepart.inventory_subgroup_id,
            //     inventory_material_id: selectedSparepart.inventory_material_id,
            //     inventory_brand_id: selectedSparepart.inventory_brand_id,
            //     inventory_group_name: selectedSparepart.inventory_group_name,
            //     inventory_subgroup_name: selectedSparepart.inventory_subgroup_name,
            //     inventory_material_name: selectedSparepart.inventory_material_name,
            //     brand_name: selectedSparepart.brand_name,
            //     inventory_group_code: selectedSparepart.inventory_group_code,
            //     inventory_subgroup_code: selectedSparepart.inventory_subgroup_code,
            //     inventory_material_code: selectedSparepart.inventory_material_code,
            //     brand_code: selectedSparepart.brand_code,
            //     part_number: selectedSparepart.part_number,
            // })
            findInventoryById(selectedSparepart.inventory_master_id);
            forceUpdate();
        }
    }, [selectedSparepart])

    useEffect(() => {
        if (sparepartOptions.length > 0) {
            if (sparepartCatalog.sparepart_item_id) {
                let newList = sparepartOptions.find(p => p.id === sparepartCatalog.sparepart_item_id);
                if (newList) {
                    setSelectedItem(newList);
                }
            }
        }
    }, [sparepartOptions])

    useEffect(() => {
        if (selectedItem.id) {
            console.log(selectedItem);
            setSparepartCatalog({
                ...sparepartCatalog,
                title: selectedItem.title,
                description: selectedItem.description,
                model: selectedItem.model,
                manufacture_name: selectedItem.manufacture_name,
                part_number: selectedItem.part_number,
                equipment_position_id: selectedItem.equipment_position_id,
                parent_id: sparepartId !== 0 && sparepartCatalog.id !== 0 ? sparepartId : null,
                supplier_id: null,
                quantity: selectedItem.quantity,
                parameters: selectedItem.parameters,
                sparepart_item_id: selectedItem.id
            })
        } else {
            setSparepartCatalog({
                ...sparepartCatalog,
                title: "",
                description: "",
                model: "",
                manufacture_name: "",
                part_number: "",
                equipment_position_id: null,
                parent_id: null,
                supplier_id: null,
                quantity: 0,
                parameters: [],
                sparepart_item_id: null
            })
        }
    }, [selectedItem])


    useEffect(() => {
        async function loadEqpCategory() {
            await loadEquipmentCategory();
        }
        loadEqpCategory();
    }, [vessel]);

    const clearManufacturerModal = async () => {
        let manufacturerTmp = {};
        manufacturerTmp.id = 0;
        manufacturerTmp.manufacturer_name = "";
        manufacturerTmp.description = "";
        setManufacturer(manufacturerTmp);
    }

    useEffect(() => {
        if (searchQuery !== "") {

            let list = [];
            for (let index = 0; index < listEquipmentCategory[selectedLevel < 0 ? selectedLevel + 1 : selectedLevel].length; index++) {
                const element = listEquipmentCategory[selectedLevel < 0 ? selectedLevel + 1 : selectedLevel][index];
                list.push(element)
            }
            let listSearchedEquipmentCategory = list.filter(p => (p.equipment_name.toLowerCase().includes(searchQuery.toLowerCase())));
            if (listSearchedEquipmentCategory)
                setListSearchedEquipmentCategory(listSearchedEquipmentCategory);
            else
                setListSearchedEquipmentCategory([]);
        } else {
            setListSearchedEquipmentCategory(listEquipmentCategory);
        }
    }, [searchQuery])

    useEffect(() => {

        if (sparepartId === "") {
            setSelectedSparepart([]);
        }
        async function loadCatalog() {
            await initSparepartCatalog();
        }
        loadCatalog();
        forceUpdate();


    }, [sparepartId])

    useEffect(() => {
        if (equipmentId !== "")
            removeEquipment();
    }, [equipmentId]);

    useEffect(() => {
        setSelectedVesselEquipment({});
    }, [listVesselEquipment]);

    useEffect(() => {
        //forceUpdate();
    }, [selectedVesselEquipment]);

    useEffect(() => {
        setSparepartId("");
        if (selectedLevel >= 0) {
            setEquipmentCategoryId(selectedEquipmentCategory[selectedLevel].id);
        }

        async function loadEqpCategoryById() {
            await loadEquipmentCategoryById();
        }

        loadEqpCategoryById();

        async function loadCatalog() {
            setLoading(true);
            await loadSparepartCatalog();
        }

        loadCatalog();

        async function loadEquipment() {
            setLoading(true);
            await loadDropdownList();
        }
        // setEquipmentCategoryId(selec)
        loadEquipment();
        setSelectedInventoryMaster({
            id: 0,
            inventory_group_id: "",
            inventory_subgroup_id: "",
            inventory_material_id: "",
            inventory_brand_id: "",
            inventory_group_name: "",
            inventory_subgroup_name: "",
            inventory_material_name: "",
            inventory_brand_name: "",
            inventory_group_code: "",
            inventory_subgroup_code: "",
            inventory_material_code: "",
            inventory_brand_code: "",
        })

    }, [selectedEquipmentCategory]);

    useEffect(() => {

        if (equipmentCategory.id) {
            setSparepartCatalog({
                ...sparepartCatalog,
                equipment_id:
                    equipmentCategory.id,
                title: "",
                description: "",
                model: "",
                manufacture_name: null,
                supplier_id: [],
                part_number: "",
                equipment_position_id: null,
                ext_customer_id: cookies.extCustomerId
            })
        }
    }, [equipmentCategory])

    useEffect(() => {
        if (equipmentCategoryId !== "") {
            loadSparepartCatalogBySparepartItem();
        }
    }, [equipmentCategoryId])

    useEffect(() => {
        if (selectedGroup) {
            setInventoryMaster({ ...inventoryMaster, inventory_group_id: selectedGroup.id, inventory_group_name: selectedGroup.inventory_group_name, inventory_group_code: selectedGroup.inventory_group_code })
            loadSubGroup();
        }
    }, [selectedGroup]);

    useEffect(() => {
        if (selectedSubGroup) {
            setInventoryMaster({ ...inventoryMaster, inventory_subgroup_id: selectedSubGroup.id, inventory_subgroup_name: selectedSubGroup.inventory_subgroup_name, inventory_subgroup_code: selectedSubGroup.inventory_subgroup_code })
        }
    }, [selectedSubGroup]);

    useEffect(() => {
        if (selectedMaterial) {
            setInventoryMaster({ ...inventoryMaster, inventory_material_id: selectedMaterial.id, inventory_material_name: selectedMaterial.inventory_material_name, inventory_material_code: selectedMaterial.inventory_material_code })
        }
    }, [selectedMaterial]);

    useEffect(() => {
        if (selectedBrand) {
            setInventoryMaster({ ...inventoryMaster, inventory_brand_id: selectedBrand.id, inventory_brand_name: selectedBrand.brand_name, inventory_brand_code: selectedBrand.brand_code })
        }
    }, [selectedBrand]);

    useEffect(() => {
        console.log(inventoryMaster);
    }, [inventoryMaster]);

    useEffect(() => {
        validationFormModalFindInventory();
    }, [selectedGroup, selectedSubGroup, selectedMaterial, selectedBrand])

    useEffect(() => {
        //loadSelectedInventorySparepart();
    }, [selectedInventoryItem]);


    useEffect(() => {
        // console.log(listInventorySubGroup);
        if (inventoryMaster.inventory_subgroup_id !== "") {
            let selSubGroup = listInventorySubGroup.find(p => p.id === inventoryMaster.inventory_subgroup_id);
            setSelectedSubGroup(selSubGroup);
        }
    }, [listInventorySubGroup]);


    const loadGroup = async () => {
        try {
            let listGroup = await getInventoryGroup(cookies.token, cookies.languageId, 0, 1000000);
            setListInventoryGroup(listGroup);
            // console.log(listInventoryGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadSubGroup = async () => {
        try {
            let listSubGroup = await getInventorySubGroupByGroupId(cookies.token, cookies.languageId, cookies.extCustomerId, selectedGroup.id, 0, 1000000);
            setListInventorySubGroup(listSubGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadMaterial = async () => {
        try {
            let listMaterial = await getInventoryMaterial(cookies.token, 0, 1000000);
            setListInventoryMaterial(listMaterial);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryBrand = async () => {

        try {
            let response = await getInventoryBrand(cookies.token);
            setListInventoryBrand(response);
            console.log(listInventoryBrand);
        } catch (exception) {

        }
    }

    const handleResets = async () => {
        setInventoryMaster({
            ...inventoryMaster,
            id: 0,
            inventory_group_id: "",
            inventory_subgroup_id: "",
            inventory_material_id: "",
            inventory_brand_id: "",
            inventory_group_name: "",
            inventory_subgroup_name: "",
            inventory_material_name: "",
            inventory_brand_name: "",
            inventory_group_code: "",
            inventory_subgroup_code: "",
            inventory_material_code: "",
            inventory_brand_code: "",
        });

    }

    const findInventory = async () => {
        let invTmp = inventoryMaster;
        invTmp.inventory_group_id = selectedGroup.id;
        invTmp.inventory_subgroup_id = selectedSubGroup.id;
        invTmp.inventory_material_id = selectedMaterial.id;
        invTmp.inventory_brand_id = selectedBrand.id;
        invTmp.ext_customer_id = "fab7b2b6-cb86-4b74-ba8e-036361f24115"
        console.log(invTmp);

        let response = await getInventoryMaster(cookies.token, cookies.languageId, cookies.extCustomerId, null, 0, 1000, "inventory.model", false, searchQueryInventory, false, invTmp);
        console.log(response);
        setListResponInventory(response);

        // let detailInventory = await getInventoryMasterById(cookies.token, response, cookies.languageId);

    }

    const findInventoryById = async (inventoryMasterId) => {
        let invTmp = inventoryMaster;
        invTmp.ext_customer_id = "fab7b2b6-cb86-4b74-ba8e-036361f24115"
        console.log(invTmp);

        let response = await getInventoryMasterById(cookies.token, inventoryMasterId, cookies.languageId);
        console.log(response);
        setSelectedInventoryMaster({
            id: response.id,
            inventory_master_name: response.inventory_master_name,
            inventory_group_id: response.inventory_group_id,
            inventory_subgroup_id: response.inventory_subgroup_id,
            inventory_material_id: response.inventory_material_id,
            inventory_brand_id: response.inventory_brand_id,
            inventory_group_name: response.inventory_group_name,
            inventory_subgroup_name: response.inventory_subgroup_name,
            inventory_material_name: response.inventory_material_name,
            brand_name: response.brand_name,
            inventory_group_code: response.inventory_group_code,
            inventory_subgroup_code: response.inventory_subgroup_code,
            inventory_material_code: response.inventory_material_code,
            brand_code: response.brand_code,
            part_number: (response.part_number === "" || response.part_number === null) ? "" : response.part_number,
            parameters: response.parameters
        });

    }

    useEffect(() => {
        console.log("SELECTED INV MASTER", selectedInventoryMaster);
        // setSelectedInventoryItem({ ...})
    }, [selectedInventoryMaster])

    const loadSelectedInventorySparepart = async () => {
        let selectedInventoryItemId = selectedInventoryItem.id;
        let response = await getInventoryMasterById(cookies.token, selectedInventoryItemId, cookies.languageId);
        console.log(response);
        setSelectedInventoryMaster(response);
    }
    const clearEquipmentModal = async () => {
        let equipmentTmp = {};
        equipmentTmp.id = 0;
        equipmentTmp.equipment_name = "";
        equipmentTmp.equipment_description = "";
        equipmentTmp.parent_id = "";
        setEquipmentCategory(equipmentTmp);
        setParameter([]);
    }


    const buildEquipmentCategoryArray = (eqp, level) => {
        try {
            level++;
            let childArray = [];
            for (let i = 0; i < eqp.length; i++) {
                childArray.push(eqp[i]);
                if (eqp[i].children.length > 0) {
                    buildEquipmentCategoryArray(eqp[i].children, level);
                }
            }
            selectedEquipmentCategoryTmp.push({});
            if (!listEquipmentCategoryTmp[level]) {
                listEquipmentCategoryTmp[level] = childArray;
            } else {
                let tmpArr = listEquipmentCategoryTmp[level].concat(childArray);
                listEquipmentCategoryTmp[level] = tmpArr;
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentPosition = async () => {
        try {
            let response = await getEquipmentPosition(cookies.token);
            if (response) {
                setListEquipmentPosition(response);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentCategory = async () => {
        try {
            let listEquipmentCategory = await getEquipmentCategoryStruct(cookies.token, cookies.languageId);
            let level = -1;
            listEquipmentCategoryTmp = [];
            selectedEquipmentCategoryTmp = [];
            buildEquipmentCategoryArray(listEquipmentCategory, level);
            // console.log(listEquipmentCategoryTmp);
            // console.log(selectedEquipmentCategoryTmp);
            setListEquipmentCategory(listEquipmentCategoryTmp);
            setSelectedEquipmentCategory(selectedEquipmentCategoryTmp);
            setListSearchedEquipmentCategory(listEquipmentCategoryTmp);
        } catch (exception) {
            console.log(exception);
        }

    }

    const saveData = async () => {
        try {
            let sparepartTmp = {};
            sparepartTmp.id = sparepartCatalog.id;
            sparepartTmp.equipment_id = sparepartCatalog.equipment_id;
            sparepartTmp.title = sparepartCatalog.title;
            sparepartTmp.description = sparepartCatalog.description;
            sparepartTmp.model = sparepartCatalog.model;
            sparepartTmp.manufacture_name = sparepartCatalog.manufacture_name;
            sparepartTmp.part_number = sparepartCatalog.part_number;
            sparepartTmp.equipment_position_id = sparepartCatalog.equipment_position_id;
            sparepartTmp.parent_id = sparepartCatalog.parent_id;
            sparepartTmp.quantity = sparepartCatalog.quantity;
            sparepartTmp.parameters = sparepartCatalog.parameters;
            sparepartTmp.supplier_id = selectedSupplier.id ? null : selectedSupplier.id;
            sparepartTmp.ext_customer_id = sparepartCatalog.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : sparepartCatalog.ext_customer_id
            sparepartTmp.inventory_master_id = selectedInventoryMaster.id === "" ? null : selectedInventoryMaster.id;

            console.log(sparepartCatalog);

            let response = await insertUpdateSparepartCatalog(cookies.token, sparepartTmp);
            if (response.error_code === 0) {
                alert(`${t("sparepart_catalogue.alert_success_save_data")}`);
                loadSparepartCatalog();
                loadSparepartCatalogBySparepartItem();
                setSparepartId("");

            } else {
                alert(`${t("sparepart_catalogue.alert_failed_save_data")}`);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveDataManufacturer = async () => {
        try {
            let response = await insertUpdateManufacturer(cookies.token, manufacturer);
            if (response.error_code === 0) {
                alert(`${t("sparepart_catalogue.alert_success_save_data")}`);
                setManufacturerModal(false);
                clearManufacturerModal();
                loadManufacturer();
                setDisabledButton(false);
            } else {
                alert(`${t("sparepart_catalogue.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initSparepartCatalog = async () => {
        try {
            if (sparepartId !== "") {
                setSelectedManufacturer({});
                let response = await getSparepartCatalogById(cookies.token, sparepartId);
                console.log("Init Sparepart", response[0]);
                if (response) {
                    setSparepartCatalog({
                        ...sparepartCatalog,
                        id: response[0].id,
                        equipment_id: response[0].equipment_id,
                        title: response[0].title,
                        description: response[0].description,
                        model: response[0].model,
                        manufacture_name: response[0].manufacture_name,
                        supplier_id: response[0].supplier_id,
                        part_number: response[0].part_number,
                        parent_id: response[0].parent_id,
                        equipment_position_id: response[0].equipment_position_id,
                        parameters: response[0].parameters,
                        quantity: response[0].quantity,
                        type: 'mainitem',
                        sparepart_item_id: response[0].sparepart_item_id
                    })

                    let eqp = await getEquipment(cookies.token);
                    let selectedEqp = eqp.find(p => p.id === response[0].equipment_id);
                    // console.log(selectedEqp);
                    setSelectedCategory(selectedEqp);

                }
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    const loadEquipmentCategoryById = async () => {
        try {
            if (selectedEquipmentCategory[selectedLevel] !== undefined) {
                let response = await getEquipmentById(cookies.token, selectedEquipmentCategory[selectedLevel].id);
                // console.log(response);
                setEquipmentCategory({
                    ...equipmentCategory,
                    id: response[0].id,
                    equipment_name: response[0].equipment_name,
                    equipment_description: response[0].equipment_description,
                    parent_id: response[0].parent_id
                })
                if (response[0].parameter_name !== null) {
                    setParameter(response);
                } else {
                    setParameter([]);
                }
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const removeEquipment = async () => {
        // console.log(equipmentId);
        let response = await deleteEquipmentCategory(cookies.token, equipmentId);
        // console.log(response);
        if (response) {
            alert(`${t("sparepart_catalogue.delete_success_alert")}`);
            loadEquipmentCategory();
        } else {
            alert(`${t("sparepart_catalogue.delete_faield_alert")}`);
            setLoading(false);
        }
    }

    const loadSparepartCatalog = async () => {
        try {
            setLoading(true);
            // console.log(selectedEquipmentCategory[selectedLevel]);
            if (selectedEquipmentCategory[selectedLevel] !== undefined) {
                let response = await getAllSparepartCatalog(cookies.token, cookies.languageId, selectedEquipmentCategory[selectedLevel].id, listSearchParameter.parameter, cookies.extCustomerId);

                setListSparepart(response);

                setSearchModal(false)
                setListSearchParameter({ ...listSearchParameter, parameter: [] });
            }
            // console.log(listSearchParameter.parameter);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-item-example">
                <li style={{ listStyle: "none" }}>{item.text} </li>
            </div>
        )
    }

    const loadDropdownList = async () => {
        try {
            let eqp = await getEquipment(cookies.token);

            if (sparepartCatalog.type === "subitem") {
                let response = eqp.filter(p => p.parent_id === selectedSparepart.equipment_id);
                setListEquipment(response);
            } else {
                setListEquipment(eqp);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationFormModalFindInventory = () => {
        try {
            let isError = false;
            if (selectedGroup.id || selectedSubGroup.id || selectedMaterial.id || selectedBrand.id) {

            } else {
                isError = true;
            }

            if (isError) {
                setDisabledButtonInventory(true);
            } else {
                setDisabledButtonInventory(false);
            }
        } catch (exception) {
            setDisabledButtonInventory(true);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (sparepartCatalog.equipment_id === "") {
                    setErrors({
                        ...errors,
                        message: `${t("sparepart_catalogue.error_empty_field")}`
                    },
                        setDisabledButtonForm(true));
                }
                else {
                    let errorMsg = omit(errors, "message");
                    setErrors(errorMsg);
                    // setDisabledButtonForm(false);
                }

                if (sparepartCatalog.equipment_id !== "" && sparepartCatalog.manufacture_name !== null) {

                    setDisabledButtonForm(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const removeSparepartCatalog = async () => {
        try {
            // console.log(sparepartId);
            let response = await deleteSparepartCatalog(cookies.token, sparepartId);
            if (response === 0) {
                alert(`${t("sparepart_catalogue.delete_success_alert")}`);
                loadSparepartCatalog();
                setSparepartId("");
                loadSparepartCatalogBySparepartItem();

            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadSupplier = async () => {
        try {
            let response = await getSupplier(cookies.token, cookies.extCustomerId);
            setListSupplier(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadManufacturer = async () => {
        try {
            let response = await getManufacturer(cookies.token);
            setListManufacturer(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 60,
            overflowY: "auto"
        }),
    }

    const loadParameterEquipment = async () => {
        try {
            let response = await getParameterEquipment(cookies.token, selectedCategory.id);
            if (response) {
                if (sparepartCatalog.id === 0)
                    setSparepartCatalog({ ...sparepartCatalog, parameters: response });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadSparepartCatalogBySparepartItem = async () => {
        try {
            console.log('tes', equipmentCategoryId);
            let response = await getSparepartBySparepartItem(cookies.token, equipmentCategoryId, cookies.extCustomerId);
            console.log(response);
            setSparepartOptions(response)
            // setEquipmentCategoryId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("sparepart_catalogue.header_title")}`}

                    childContent={
                        <div className="master-table-inner-container">
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: 10,
                                    marginBottom: 10,
                                    borderWidth: 2,
                                    borderColor: "#282c34",
                                    borderStyle: "solid",
                                    borderRadius: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        paddingLeft: 10,
                                    }}>
                                        {t("sparepart_catalogue.text_choose_equipment")}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        overflowX: "auto",
                                        maxWidth: "100%",
                                    }}>

                                        {
                                            listEquipmentCategory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        overflowY: "auto",
                                                        flex: 1,
                                                        maxHeight: 480,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        {/* {
                                                            index === 0 &&
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignContent: "center",
                                                                alignItems: "flex-start",
                                                                justifyContent: "flex-start",
                                                                alignSelf: "flex-start",
                                                                width: "100%",
                                                                paddingBottom: 10,
                                                            }}>

                                                                <div className="master-table-searchbar-container" style={{ width: "100%" }}>

                                                                    <Form.Control style={{ width: "100% !important" }} value={searchQuery} type="text" placeholder="Kata Kunci (Kategori Peralatan)"
                                                                        onKeyPress={async (e) => {
                                                                            if (e.charCode === 13) {
                                                                                setLoading(true);
                                                                                // await loadReturnInventory();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setSelectedLevel(-1);
                                                                            setSearchQuery(e.target.value);
                                                                        }} />

                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                // await loadReturnInventory();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                // await loadReturnInventory();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            index > 0 && selectedEquipmentCategory[index - 1].id &&
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignContent: "center",
                                                                alignItems: "flex-start",
                                                                justifyContent: "flex-start",
                                                                alignSelf: "flex-start",
                                                                width: "100%",
                                                                paddingBottom: 10,
                                                            }}>
                                                                <div className="master-table-searchbar-container" style={{ width: "100%" }}>

                                                                    <Form.Control style={{ width: "100% !important" }} value={setSearchQuery} type="text" placeholder="Kata Kunci (Kategori Peralatan)"
                                                                        onKeyPress={async (e) => {
                                                                            if (e.charCode === 13) {
                                                                                setLoading(true);
                                                                                // await loadReturnInventory();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setSearchQuery(e.target.value);
                                                                        }} />

                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                // await loadReturnInventory();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                // await loadReturnInventory();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        } */}

                                                        <ListGroup style={{
                                                            width: "100%"
                                                        }}>

                                                            {
                                                                index === 0 && listEquipmentCategory[index].map((item2, index2) => {
                                                                    return (

                                                                        <ListGroup.Item style={{
                                                                            width: "100%"
                                                                        }} key={index2} action onClick={() => {
                                                                            setSelectedLevel(index);
                                                                            let selectedEquipmentCategory2 = selectedEquipmentCategory.slice();
                                                                            selectedEquipmentCategory2[index] = item2;
                                                                            for (let i = 0; i < selectedEquipmentCategory2.length; i++) {
                                                                                if (i > index) {
                                                                                    selectedEquipmentCategory2[i] = {};
                                                                                }
                                                                            }
                                                                            setSelectedEquipmentCategory(selectedEquipmentCategory2);
                                                                        }} variant={selectedEquipmentCategory[index].id === item2.id ? "primary" : "info"}>
                                                                            <div style={{display: 'flex'}}>
                                                                                <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>{item2.order_number}</div>
                                                                                <br/>
                                                                                <div style={{flex: 2}}>{item2.equipment_name}</div>
                                                                            </div>

                                                                        </ListGroup.Item>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                index > 0 && (index - 1) <= selectedLevel && listEquipmentCategory[index].filter(p => p.parent_id === selectedEquipmentCategory[index - 1].id).map((item2, index2) => {
                                                                    return (
                                                                        <ListGroup.Item style={{
                                                                            width: "100%"
                                                                        }} key={index2} action onClick={() => {
                                                                            setSelectedLevel(index);
                                                                            let selectedEquipmentCategory2 = selectedEquipmentCategory.slice();
                                                                            selectedEquipmentCategory2[index] = item2;
                                                                            for (let i = 0; i < selectedEquipmentCategory2.length; i++) {
                                                                                if (i > index) {
                                                                                    selectedEquipmentCategory2[i] = {};
                                                                                }
                                                                            }
                                                                            setSelectedEquipmentCategory(selectedEquipmentCategory2);
                                                                        }} variant={selectedEquipmentCategory[index].id === item2.id ? "primary" : "info"}>
                                                                            <div style={{display: 'flex'}}>
                                                                                <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>{item2.order_number}</div>
                                                                                <br/>
                                                                                <div style={{flex: 2}}>{item2.equipment_name}</div>
                                                                            </div>

                                                                        </ListGroup.Item>
                                                                    )
                                                                })
                                                            }

                                                        </ListGroup>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>

                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                padding: 10,
                                borderWidth: 2,
                                borderColor: "#282c34",
                                borderStyle: "solid",
                                borderRadius: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    paddingRight: 10,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 5,
                                        paddingLeft: 10,
                                    }}>

                                        {
                                            equipmentCategory.id !== 0 &&
                                            <div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        fontWeight: "bold",
                                                        fontSize: 20,
                                                        paddingRight: 10,
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        {t("sparepart_catalogue.text_list_sparepart")}
                                                    </div>
                                                    {
                                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                            <></>
                                                            :
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <Button disabled={equipmentCategory.id ? !updatedButton : true} style={{
                                                                    marginLeft: 5, marginRight: 5
                                                                }} onClick={() => {
                                                                    let SparepartId = equipmentCategory.id;
                                                                    setSparepartId(0);
                                                                    let sparepart = {
                                                                        id: 0,
                                                                        equipment_id: equipmentCategory.id,
                                                                        title: "",
                                                                        description: "",
                                                                        model: "",
                                                                        manufacture_name: null,
                                                                        part_number: "",
                                                                        equipment_position_id: null,
                                                                        parent_id: null,
                                                                        parameters: [],
                                                                        supplier_id: [],
                                                                        type: "mainitem",
                                                                        ext_customer_id: cookies.extCustomerId
                                                                    }

                                                                    setSparepartCatalog(sparepart);
                                                                    setSelectedCategory(equipmentCategory);
                                                                    setSelectedItem({})
                                                                    // setSparepartCatalog({...sparepartCatalog, equipment_id: equipmentCategory.id})
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><PlusSquareFill size={16} /></div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                    }

                                                    {
                                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                            <></>
                                                            :
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <Button variant="danger" disabled={sparepartId ? !deletedButton : true} style={{
                                                                    marginLeft: 5, marginRight: 5
                                                                }} onClick={() => {
                                                                    if (window.confirm(`${t("sparepart_catalogue.delete_confirmation")} ${selectedInventoryMaster.inventory_master_name}`)) {
                                                                        // setLoading(true);
                                                                        removeSparepartCatalog();
                                                                        // handleremove(i)
                                                                        // let equipmentId = equipmentCategory.id
                                                                        // setEquipmentId(equipmentId);
                                                                    }
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><Trash size={16} /></div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                    }








                                                </div>

                                                <div style={{ paddingBottom: 10 }}></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap"
                                                }}>

                                                    <Form.Control onClick={() => {
                                                        setSearchModal(true);
                                                    }} style={{ maxWidth: 187 }} placeholder=""></Form.Control>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <Button variant="primary" disabled={equipmentCategory.id ? false : true} style={{
                                                            marginLeft: 5, marginRight: 5
                                                        }} onClick={() => {
                                                            setSearchModal(true);
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><Search size={16} /></div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <Button disabled={equipmentCategory.id ? false : true} style={{
                                                            marginLeft: 5, marginRight: 5
                                                        }} onClick={() => {
                                                            setLoading(true);
                                                            loadSparepartCatalog()
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><ArrowClockwise size={16} /></div>
                                                            </div>
                                                        </Button>
                                                    </div>

                                                </div>

                                                <div style={{ paddingBottom: 10 }}></div>
                                                <DeniReactTreeView
                                                    style={{ marginRight: 10, marginBottom: 10, maxWidth: 300 }}
                                                    showCheckbox={false}
                                                    showIcon={false}
                                                    theme="classic"
                                                    items={listSparepart}
                                                    //onRenderItem={onRenderItem}
                                                    onSelectItem={
                                                        (e) => {
                                                            setSparepartId(e.id);
                                                            console.log(e);
                                                            setSelectedSparepart(e);
                                                        }
                                                    }
                                                />

                                            </div>

                                        }
                                        <div style={{ paddingRight: 10 }}></div>
                                        {

                                            sparepartId !== "" &&
                                            <ContainerBox containerPos="inner" titleCaption={`${t("sparepart_catalogue.modal_add_sparepart")}`}
                                                useActionContainer={true}
                                                actionContainerChild={
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row"
                                                    }}>

                                                        {
                                                            sparepartId !== 0 && sparepartCatalog.id !== 0 && (cookies.logingAs === "User" || cookies.extCustomerId === "null") &&
                                                            <div style={{ display: "flex" }}>
                                                                <Button disabled={!updatedButton} variant="secondary" onClick={() => {
                                                                    setSparepartCatalog({
                                                                        ...sparepartCatalog,
                                                                        id: 0,
                                                                        equipment_id: "",
                                                                        title: "",
                                                                        description: "",
                                                                        model: "",
                                                                        manufacture_name: null,
                                                                        supplier_id: [],
                                                                        part_number: "",
                                                                        equipment_position_id: null,
                                                                        parent_id: sparepartId,
                                                                        parameters: [],
                                                                        type: "subitem",
                                                                        ext_customer_id: cookies.extCustomerId
                                                                    })
                                                                    setSelectedCategory({});
                                                                    setSelectedManufacturer({});
                                                                    setSelectedItem({});
                                                                }} style={{

                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={20} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 5,
                                                                            fontWeight: "bold",
                                                                            fontSize: 15,
                                                                        }}>{t("sparepart_catalogue.button_sub_item")}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>

                                                        }
                                                        <div style={{ paddingRight: 10 }}></div>
                                                        {
                                                            sparepartId !== 0 && sparepartCatalog.id !== 0 &&
                                                            <div style={{ display: "flex" }}>
                                                                <Link to={`/InventoryMaster/InventoryDocument?inventoryId=${selectedInventoryMaster.id}&inventoryName=${selectedInventoryMaster.inventory_master_name}&backButton=${'/SparepartCatalog'}`} target="_blank">
                                                                <Button variant="secondary" onClick={() => {
                                                                    // setShowDocumentUploadModal(true);
                                                                    // console.log(selectedInventoryMaster.id);
                                                                    // <Link to="/InventoryMaster/InventoryDocument" state={{state: { inventoryId: selectedInventoryMaster.id, inventoryName: selectedInventoryMaster.inventory_master_name, backButton: '/SparepartCatalog' }}} target="_blank"/>
                                                                    // navigate("/InventoryMaster/InventoryDocument", { state: { inventoryId: selectedInventoryMaster.id, inventoryName: selectedInventoryMaster.inventory_master_name, backButton: '/SparepartCatalog' } });
                                                                }} style={{

                                                                }}>
                                                                    
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FileCheckFill size={20} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 5,
                                                                            fontWeight: "bold",
                                                                            fontSize: 15,
                                                                        }}> {t("sparepart_catalogue.button_document")}</div>
                                                                    </div>
                                                                </Button>
                                                                </Link>
                                                            </div>


                                                        }
                                                        <div style={{ paddingRight: 10 }}></div>
                                                        {
                                                            sparepartId !== 0 && sparepartCatalog.id !== 0 &&
                                                            <div style={{ display: "flex" }}>
                                                                <Link to={`/SparepartCatalog/MaintenancePlan?sparepartCatalogId=${sparepartCatalog.id}&sparepartName=${selectedInventoryMaster.inventory_master_name}&sparepartGroup=${selectedInventoryMaster.inventory_group_name}&sparepartSubGroup=${selectedInventoryMaster.inventory_subgroup_name}&sparepartMaterial=${selectedInventoryMaster.inventory_material_name}&sparepartBrand=${selectedInventoryMaster.brand_name}&sparepartPartNumber=${selectedInventoryMaster.part_number}`} target="_blank">
                                                                <Button variant="secondary" onClick={() => {
                                                                    let sparepart = sparepartCatalog;
                                                                    sparepart.inventory_detail = selectedInventoryMaster;
                                                                    // navigate("/SparepartCatalog/MaintenancePlan", { state: { sparepartCatalogId: sparepartCatalog.id, sparepartCatalog: sparepart } });
                                                                }} style={{

                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FileCheckFill size={20} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 5,
                                                                            fontWeight: "bold",
                                                                            fontSize: 15,
                                                                        }}> {t("sparepart_catalogue.button_maintenance_plan")}</div>
                                                                    </div>
                                                                </Button>
                                                                </Link>
                                                            </div>


                                                        }
                                                        <div style={{ paddingRight: 10 }}></div>
                                                        {
                                                            //sparepartId !== 0 && sparepartCatalog.id !== 0 &&
                                                            //<div style={{ display: "flex" }}>
                                                            /* <Link to={`/SparepartCatalog/Stock?id=${sparepartId}`} target="_blank"> */
                                                            /* <Button variant="secondary" onClick={() => {
                                                                // setShowDocumentUploadModal(true);
                                                                // navigate("/SparepartCatalog/Document" , {state:{sparepartCatalogId: sparepartId}});
                                                            }} style={{

                                                            }}> */
                                                            /* <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><BoxArrowUpRight size={20} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 5,
                                                                    fontWeight: "bold",
                                                                    fontSize: 15,
                                                                }}> {t("sparepart_catalogue.button_stock")}</div>
                                                            </div> */
                                                            /* </Button> */
                                                            /* </Link> */
                                                            //</div>


                                                        }
                                                    </div>
                                                }
                                                childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>
                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            saveData();
                                                            setDisabledButtonForm(true);
                                                        }}>
                                                            <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", flex: 3 }}>
                                                                <Button variant="primary" style={{
                                                                    marginLeft: 5, marginRight: 5
                                                                }} onClick={() => {
                                                                    setFindInventoryModal(true);
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div><Search size={20} /></div>
                                                                        <div style={{
                                                                            paddingLeft: 10, fontSize: 20,
                                                                        }}>{t("sparepart_catalogue.field_inventory")}</div>
                                                                    </div>
                                                                </Button>
                                                                <div style={{ display: "flex", justifyContent: 'space-around', width: "100%" }}>
                                                                    <div style={{ display: "flex", flexDirection: 'column', flexWrap: "nowrap", width: "100%", padding: '10px' }}>
                                                                        <Form.Group>
                                                                            <Form.Label>{t("sparepart_catalogue.field_equipment_category")}</Form.Label>
                                                                            <FormSelect placeholder=""
                                                                                options={listEquipment} getOptionLabel={
                                                                                    (item) => {
                                                                                        return item.equipment_name;
                                                                                    }
                                                                                } getOptionValue={
                                                                                    (item) => {
                                                                                        return item.id;
                                                                                    }
                                                                                } value={selectedCategory} setValueFunction={(e) => {
                                                                                    let selectedEqp = listEquipment.find(p => p.id === e.id);
                                                                                    setSelectedCategory(selectedEqp);
                                                                                    setSparepartCatalog({ ...sparepartCatalog, equipment_id: e.id })
                                                                                    setEquipmentCategoryId(e.id);

                                                                                }}
                                                                                disabledForm={sparepartCatalog.type === "subitem" ? false : true}
                                                                            />
                                                                            {
                                                                                errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                            }

                                                                        </Form.Group>

                                                                        <TextBox disabledForm={true} value={selectedInventoryMaster.inventory_master_name} placeholder=""
                                                                            caption={"Inventory Item Name"}
                                                                            // setValueFunction={(e) => {
                                                                            //     setSparepartCatalog({ ...sparepartCatalog, title: e.target.value });
                                                                            // }}
                                                                            req={true} />

                                                                        <TextBox disabledForm={true} value={selectedInventoryMaster.inventory_group_name} placeholder=""
                                                                            caption={"Group"}
                                                                            // setValueFunction={(e) => {
                                                                            //     setSparepartCatalog({ ...sparepartCatalog, model: e.target.value });
                                                                            // }}
                                                                            req={true} />

                                                                        <TextBox disabledForm={true} value={selectedInventoryMaster.inventory_subgroup_name} placeholder=""
                                                                            caption={"Sub Group"}
                                                                            // setValueFunction={(e) => {
                                                                            //     setSparepartCatalog({ ...sparepartCatalog, model: e.target.value });
                                                                            // }}
                                                                            req={true} />

                                                                        <TextBox disabledForm={true} value={selectedInventoryMaster.inventory_material_name} placeholder=""
                                                                            caption={"Material"}
                                                                            // setValueFunction={(e) => {
                                                                            //     setSparepartCatalog({ ...sparepartCatalog, model: e.target.value });
                                                                            // }}
                                                                            req={true} />

                                                                        <TextBox disabledForm={true} value={selectedInventoryMaster.brand_name} placeholder=""
                                                                            caption={"Brand"}
                                                                            // setValueFunction={(e) => {
                                                                            //     setSparepartCatalog({ ...sparepartCatalog, model: e.target.value });
                                                                            // }}
                                                                            req={true} />

                                                                        <TextBox disabledForm={true} value={selectedInventoryMaster.part_number} placeholder=""
                                                                            caption={"Part Number"}
                                                                            // setValueFunction={(e) => {
                                                                            //     setSparepartCatalog({ ...sparepartCatalog, model: e.target.value });
                                                                            // }}
                                                                            req={true} />
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: 'column', flexWrap: "nowrap", width: "100%", padding: '10px', marginTop: '5px' }}>
                                                                        <Form.Group>

                                                                            <TextBox disabledForm={true} value={selectedInventoryMaster.inventory_group_code + '-' + selectedInventoryMaster.brand_code + '-' + selectedInventoryMaster.inventory_subgroup_code + '-' + selectedInventoryMaster.inventory_material_code} placeholder=""
                                                                                caption={t("sparepart_catalogue.field_code")}
                                                                                setValueFunction={(e) => {
                                                                                    setSparepartCatalog({ ...sparepartCatalog, quantity: e.target.value });
                                                                                }}
                                                                                req={true} />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("sparepart_catalogue.field_supplier")}</Form.Label>
                                                                            <Select styles={styles} isMulti={true} closeMenuOnSelect={false} placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.supplier_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listSupplier} value={sparepartCatalog.supplier_id} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSparepartCatalog({ ...sparepartCatalog, supplier_id: [] })
                                                                                    } else {
                                                                                        setSparepartCatalog({ ...sparepartCatalog, supplier_id: e });
                                                                                    }
                                                                                }}
                                                                                isClearable
                                                                            />
                                                                        </Form.Group>

                                                                        {/* <Form.Group>
                                                                            
                                                                        </Form.Group> */}
                                                                        <TextBox disabledForm={false} value={sparepartCatalog.quantity} placeholder=""
                                                                            caption={t("sparepart_catalogue.field_quantity")}
                                                                            setValueFunction={(e) => {
                                                                                setSparepartCatalog({ ...sparepartCatalog, quantity: e.target.value });
                                                                            }}
                                                                            req={true} />

                                                                        <Form.Group>
                                                                            <Form.Label>{t("sparepart_catalogue.field_position")}</Form.Label>
                                                                            <Form.Select onChange={(e) => {
                                                                                if (e.target.value !== "") {
                                                                                    setSparepartCatalog({ ...sparepartCatalog, equipment_position_id: e.target.value })
                                                                                } else {
                                                                                    setSparepartCatalog({ ...sparepartCatalog, equipment_position_id: null })
                                                                                }

                                                                            }} value={sparepartCatalog.equipment_position_id ? sparepartCatalog.equipment_position_id : ""}>
                                                                                <option selected value="">

                                                                                </option>
                                                                                {listEquipmentPosition.map((position, index) => (
                                                                                    <option key={index} value={position.id}>{position.position}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>

                                                                        <TextBox asType={"textarea"} rows={3} value={sparepartCatalog.description} placeholder=""
                                                                            caption={`${t("sparepart_catalogue.field_description")}`} setValueFunction={(e) => {
                                                                                setSparepartCatalog({ ...sparepartCatalog, description: e.target.value });
                                                                            }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {/* <Form.Group>
                                                                            <Form.Label>Sparepart Item</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.text;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={sparepartOptions} value={sparepartCatalog.sparepart_item_id?selectedItem:""} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedItem({})
                                                                                    } else {
                                                                                        setSelectedItem(e);
                                                                                    }
                                                                                }}
                                                                                isClearable
                                                                                required
                                                                            />
                                                                        </Form.Group> */}

                                                            {/* <Form.Group>
                                                                            <Form.Label>{t("sparepart_catalogue.field_manufacturer_name")}
                                                                                {cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? "" :
                                                                                    <a onClick={() => {
                                                                                        setManufacturerModal(true);
                                                                                        setManufacturer({ ...manufacturer, id: 0 })
                                                                                    }} role="button" style={{ color: "blue", textDecoration: "underline" }}><i className='fa fa-plus text-primary'></i> {t("sparepart_catalogue.add_button")} </a>
                                                                                }
                                                                            </Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.manufacturer_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listManufacturer} value={sparepartCatalog.manufacture_name ? selectedManufacturer : ""} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedManufacturer({})
                                                                                    } else {
                                                                                        let list = e
                                                                                        setSelectedManufacturer(list);
                                                                                    }
                                                                                }}
                                                                                isClearable
                                                                                required
                                                                            />
                                                                            {
                                                                                manufactureNameError && <p style={{ color: "red" }}>{manufactureNameError}</p>
                                                                            }
                                                                        </Form.Group> */}
                                                            {/* <TextBox disabledForm={true} value={sparepartCatalog.manufacture_name} placeholder="Nama Pabrikan"
                                                                        caption="Nama Pabrikan" setValueFunction={(e) => {
                                                                            // setSparepartCatalog({ ...sparepartCatalog, manufacture_name: e.target.value });
                                                                        }} /> */}
                                                            {/* <p>{sparepartCatalog.manufacture_name}</p> */}

                                                            {/* <TextBox req={true} value={sparepartCatalog.part_number} placeholder=""
                                                                            caption={`${t("sparepart_catalogue.field_part_number")}`} setValueFunction={(e) => {
                                                                                if (!new RegExp('^[a-zA-z0-9 ][a-zA-Z0-9 ]*$').test(e.target.value)) {

                                                                                } else {
                                                                                    setSparepartCatalog({ ...sparepartCatalog, part_number: e.target.value });
                                                                                }
                                                                                if (e.target.value === "") {
                                                                                    setSparepartCatalog({ ...sparepartCatalog, part_number: "" })
                                                                                }

                                                                            }} /> */}

                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                padding: 5
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    width: "100%",
                                                                    flexDirection: "row",
                                                                    padding: 5
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 3
                                                                    }}>{t("sparepart_catalogue.table_name1")}</div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 2
                                                                    }}>{t("sparepart_catalogue.table_name2")}</div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 2
                                                                    }}>{t("sparepart_catalogue.table_name3")}</div>
                                                                    {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 1
                                                                    }}>{t("sparepart_catalogue.table_name4")}</div> */}
                                                                </div>
                                                                {
                                                                    selectedInventoryMaster.parameters && selectedInventoryMaster.parameters.map((param, index) => {
                                                                        return (
                                                                            <div key={index} style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "row",
                                                                                padding: 5,
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    width: "100%",
                                                                                    flexDirection: "column",
                                                                                    flex: 3,
                                                                                    paddingLeft: 5,
                                                                                    paddingRight: 5
                                                                                }}>
                                                                                    <Form.Control disabled onChange={(e) => {
                                                                                        let parameters = selectedInventoryMaster.parameters;
                                                                                        parameters[index].parameter_name = e.target.value;
                                                                                        // setSparepartCatalog({ ...sparepartCatalog, parameters: parameters });
                                                                                    }} type="text" value={param.parameter_name ? param.parameter_name : ""} required placeholder=""></Form.Control>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    width: "100%",
                                                                                    flexDirection: "column",
                                                                                    flex: 2,
                                                                                    paddingLeft: 5,
                                                                                    paddingRight: 5
                                                                                }}>
                                                                                    <Form.Control disabled onChange={(e) => {
                                                                                        let parameters = selectedInventoryMaster.parameters;
                                                                                        parameters[index].parameter_value = e.target.value;
                                                                                        // setSparepartCatalog({ ...sparepartCatalog, parameters: parameters });
                                                                                    }} type="text" value={param.parameter_value ? param.parameter_value : ""} placeholder=""></Form.Control>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    width: "100%",
                                                                                    flexDirection: "column",
                                                                                    flex: 2,
                                                                                    paddingLeft: 5,
                                                                                    paddingRight: 5
                                                                                }}>
                                                                                    <Form.Control disabled onChange={(e) => {
                                                                                        let parameters = selectedInventoryMaster.parameters;
                                                                                        parameters[index].parameter_description = e.target.value;
                                                                                        // setSparepartCatalog({ ...sparepartCatalog, parameters: parameters });
                                                                                    }} type="text" value={param.parameter_description ? param.parameter_description : ""} placeholder=""></Form.Control>
                                                                                </div>
                                                                                {/* <div style={{
                                                                                    display: "flex",
                                                                                    width: "100%",
                                                                                    flexDirection: "column",
                                                                                    flex: 1,
                                                                                    paddingLeft: 5,
                                                                                    paddingRight: 5
                                                                                }}>
                                                                                    <Button disabled={!deletedButton} onClick={() => {
                                                                                        if (window.confirm(`${t("sparepart_catalogue.delete_confirmation")}?`)) {
                                                                                            let list = [...selectedInventoryMaster.parameters];
                                                                                            list.splice(index, 1);
                                                                                            setSparepartCatalog({ ...sparepartCatalog, parameters: list });
                                                                                        }

                                                                                    }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                                                                </div> */}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                padding: 5
                                                            }}>
                                                                {/* <Button variant="primary" onClick={() => {
                                                                    let newParameter = {
                                                                        id: "",
                                                                        parameter_name: "",
                                                                        parameter_value: "",
                                                                        parameter_description: "",
                                                                    };
                                                                    if (!sparepartCatalog.parameters) {
                                                                        let parameters = [];
                                                                        parameters.push(newParameter);
                                                                        setSparepartCatalog({ ...sparepartCatalog, parameters: parameters });
                                                                    } else {
                                                                        let parameters = sparepartCatalog.parameters;
                                                                        parameters.push(newParameter);
                                                                        setSparepartCatalog({ ...sparepartCatalog, parameters: parameters });
                                                                    }

                                                                }}>{t("sparepart_catalogue.button_add_parameter")}</Button> */}
                                                            </div>
                                                            <div style={{ paddingBottom: 10 }}></div>
                                                            {
                                                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                                    <></>
                                                                    :
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexWrap: "nowrap",
                                                                        flexDirection: "row",
                                                                        justifyContent: "center"
                                                                    }}>
                                                                        <div style={{ display: "flex" }}>
                                                                            <Button style={{ width: 100 }} type="submit" disabled={false} >{t("sparepart_catalogue.save_button")}</Button>
                                                                        </div>
                                                                        <div style={{ display: "flex" }}>
                                                                            <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                                                                // setVesselEquipmentId("")
                                                                                setSparepartId("")
                                                                                setSelectedSparepart({});
                                                                                loadSparepartCatalog();
                                                                            }}>{t("sparepart_catalogue.cancel_button")}</Button>
                                                                        </div>


                                                                    </div>
                                                            }


                                                        </Form>
                                                    </div>

                                                }>


                                            </ContainerBox>
                                        }

                                    </div>
                                </div>
                            </div>


                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />

                {/* <Modal show={manufacturerModal} onHide={() => {
                    setManufacturerModal(false);
                    clearManufacturerModal();
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Data Pabrikan`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    setDisabledButton(true);
                                    e.preventDefault();
                                    saveDataManufacturer()
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flexWrap: "nowrap",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Pabrikan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setManufacturer({ ...manufacturer, manufacturer_name: e.target.value })
                                                }} value={manufacturer.manufacturer_name} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Deskripsi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setManufacturer({ ...manufacturer, description: e.target.value })
                                                }} value={manufacturer.description} as="textarea" row={3} placeholder="" ></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>


                                    <div style={{
                                        paddingTop: 20,
                                    }}>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            clearManufacturerModal();
                                            setManufacturerModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal> */}

                <Modal show={manufacturerModal}

                    size={"lg"}
                    onHide={() => {
                        setManufacturerModal(false);
                        clearManufacturerModal();
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("sparepart_catalogue.modal_add_manufacturer")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveDataManufacturer()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalogue.field_modal_manufacturer_name")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setManufacturer({ ...manufacturer, manufacturer_name: e.target.value })
                                }} value={manufacturer.manufacturer_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalogue.field_modal_description")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setManufacturer({ ...manufacturer, description: e.target.value })
                                }} value={manufacturer.description} as="textarea" rows={3} placeholder="" ></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "center"
                                    }}>
                                        <Button style={{ width: 100 }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("sparepart_catalogue.save_button")}</Button>
                                        <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                            clearManufacturerModal();
                                            setManufacturerModal(false);
                                        }}>{t("sparepart_catalogue.cancel_button")}</Button>
                                    </div>
                            }


                        </Form>



                    </div>

                </Modal>

                {/* <Modal show={searchModal} onHide={() => {
                    setSearchModal(false);
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Pencarian`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    setDisabledButton(true);
                                    e.preventDefault();
                                }}>



                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            setSearchModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal> */}

                <Modal show={findInventoryModal}

                    size={"xl"}
                    onHide={() => {
                        handleResets();
                        setFindInventoryModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{"Find Inventory List"} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Find Inventory")
                            findInventory();


                        }} style={{ padding: 10 }}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_group")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_group_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryGroup} value={selectedGroup} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedGroup({ id: "" });
                                        } else {

                                            setSelectedGroup(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_subgroup")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_subgroup_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventorySubGroup} value={selectedSubGroup} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedSubGroup({ id: "" });
                                        } else {
                                            setSelectedSubGroup(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_material")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_material_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryMaterial} value={selectedMaterial} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedMaterial({ id: "" });
                                        } else {
                                            setSelectedMaterial(e);
                                            setInventoryMaster({ ...inventoryMaster, inventory_material_id: e.id, inventory_material_name: e.inventory_material_name });
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_brand")}</Form.Label>

                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.brand_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryBrand} value={selectedBrand} onChange={(e) => {
                                        if (e === null) {
                                            console.log(e);
                                            setSelectedBrand({})
                                        } else {
                                            setSelectedBrand(e);
                                        }

                                    }}
                                    isClearable
                                    required
                                />

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalogue.search_button_by_name")}</Form.Label>
                                <Form.Control style={{ height: '45px' }} value={searchQueryInventory} type="text"
                                    onChange={(e) => {
                                        setSearchQueryInventory(e.target.value);
                                    }} />
                            </Form.Group>

                            <div className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <Button style={{ width: 100 }} className="save-button" type="submit" disabled={disabledButtonInventory}>{t("sparepart_catalogue.search_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel cancel-button" variant="danger" onClick={() => {
                                    handleResets();
                                    setFindInventoryModal(false);
                                }}>{t("sparepart_catalogue.cancel_button")}</Button>
                            </div>
                        </Form>
                        <div className="master-table-inner-container">
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 4 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_item_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_group_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_subgroup_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_material_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_brand_name")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalogue.modal_inventory_select")}</div>
                                </div>
                                {listResponInventory.map((data, index) => {
                                    return (
                                        <div key={index} className="table-body">
                                            {/* <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div> */}
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{data.inventory_master_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.inventory_group_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.inventory_subgroup_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.inventory_material_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{data.brand_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setFindInventoryModal(false);
                                                        handleResets();
                                                        /* setSelectedInventoryItem({
                                                            ...selectedInventoryItem,
                                                            id: data.id,
                                                            inventory_master_name: data.inventory_master_name
                                                        }) */
                                                        console.log("SELECT INVENTORY", data);
                                                        findInventoryById(data.id);
                                                        //setSelectedInventoryMaster(data);
                                                    }}><FilePlusFill />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal show={searchModal}
                    size={"lg"}
                    onHide={() => {
                        setSearchModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("sparepart_catalogue.modal_search")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            loadSparepartCatalog()
                        }} style={{ padding: 10 }}>


                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3
                                    }}>{t("sparepart_catalogue.modal_table_name1")}</div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 2
                                    }}>{t("sparepart_catalogue.modal_table_name2")}</div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 1
                                    }}>{t("sparepart_catalogue.modal_table_name3")}</div>
                                </div>
                                {
                                    listSearchParameter.parameter && listSearchParameter.parameter.map((param, index) => {
                                        return (
                                            <div key={index} style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 3,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Form.Control disabled onChange={(e) => {
                                                        let parameters = listSearchParameter.parameter;
                                                        parameters[index].parameter_name = e.target.value;
                                                        setListSearchParameter({ ...listSearchParameter, parameter: parameters });
                                                    }} type="text" value={param.parameter_name ? param.parameter_name : ""} required placeholder=""></Form.Control>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 2,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Form.Control disabled onChange={(e) => {
                                                        let parameters = listSearchParameter.parameter;
                                                        parameters[index].parameter_value = e.target.value;
                                                        setListSearchParameter({ ...listSearchParameter, parameter: parameters });
                                                    }} type="text" value={param.parameter_value ? param.parameter_value : ""} placeholder=""></Form.Control>
                                                </div>

                                                {/* <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 1,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button disabled={!deletedButton} onClick={() => {
                                                        if (window.confirm(`${t("sparepart_catalogue.delete_confirmation")}?`)) {
                                                            let list = [...listSearchParameter.parameter];
                                                            list.splice(index, 1);
                                                            setListSearchParameter({ ...listSearchParameter, parameter: list });
                                                        }

                                                    }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                                </div> */}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <Button variant="primary" onClick={() => {
                                    let newParameter = {
                                        parameter_name: "",
                                        parameter_value: "",
                                    };
                                    if (!listSearchParameter.parameter) {
                                        let parameters = [];
                                        parameters.push(newParameter);
                                        setListSearchParameter({ ...listSearchParameter, parameter: parameters })
                                    } else {
                                        let parameters = listSearchParameter.parameter;
                                        parameters.push(newParameter);
                                        setListSearchParameter({ ...listSearchParameter, parameter: parameters });
                                    }
                                }}>{t("sparepart_catalogue.button_add_parameter")}</Button>
                            </div>
                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexWrap: "nowrap",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <div style={{ display: "flex" }}>
                                    <Button style={{ width: 100 }} type="submit" disabled={listSearchParameter.parameter.length < 1} ><Search /></Button>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                        setSearchModal(false);
                                        setListSearchParameter({ ...listSearchParameter, parameter: [] })
                                    }}>{t("sparepart_catalogue.cancel_button")}</Button>
                                </div>


                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    )


}