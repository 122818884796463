// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomNavBarContainerNew {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    /* border: 1px black solid; */
    width: 100vh;
    margin: 0 30%;
}

.topNavBarContainerNew {
    width: 100vh;
    height: 100%;
    /* border: 1px black solid; */
    display: flex;
    flex-direction: column;
    margin: 0 30%;
}

/* .contentNavBar {
    display: flex;
    gap: 10px;
} */`, "",{"version":3,"sources":["webpack://./src/Components/NewSMSNavContainerFireAlarm/NewSMSNavContainerFireAlrm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,cAAc;IACd,6BAA6B;IAC7B,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;;;GAGG","sourcesContent":[".bottomNavBarContainerNew {\n    display: flex;\n    justify-content: space-around;\n    margin: 10px 0;\n    /* border: 1px black solid; */\n    width: 100vh;\n    margin: 0 30%;\n}\n\n.topNavBarContainerNew {\n    width: 100vh;\n    height: 100%;\n    /* border: 1px black solid; */\n    display: flex;\n    flex-direction: column;\n    margin: 0 30%;\n}\n\n/* .contentNavBar {\n    display: flex;\n    gap: 10px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
