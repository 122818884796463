import React, { useState, useEffect } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { ArrowLeft } from "react-bootstrap-icons";
import { isTokenValid, getCountry, getUserAccessById } from "../../../../../Helpers/ApplicationHelpers";
import {
    getVesselClassificationSocietyById, insertUpdateSociety
} from "../../../../../Helpers/VesselClassification.helpers";
import "../../../../../App.css";
import Navbar from "../../../../../Components/NavBar";
import Loading from "../../../../../Components/Loading";
import ContainerBox from "../../../../../Components/ContainerBox";
import DropDownForm from "../../../../../Components/DropDownForm";
import { Trans, useTranslation } from "react-i18next";


export default function VesselTypeDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [updatedButton,setUpdatedButton] = useState("");
    const [listLanguage, setListLanguage] = useState([]);
    const [listCountry, setListCountry] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [society, setSociety] = useState({
        languageId: "",
        countryId: "",
        societyName: "",
        societyDesc: "",
        societyCode: ""
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
        }
        checkCookies();
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
          } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
          }
        if (location.state.societyId === 0) {
            loadCountry();
            loadAccess();
            setSociety({ ...society, languageId: cookies.languageId });
            setLoading(false);
        } else {
            loadCountry();
            initSociety();
            setLoading(false);
            loadAccess();
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [society])



    const initSociety = async () => {
        try {
            let response = await getVesselClassificationSocietyById(cookies.token, location.state.languageId, location.state.societyId);
            if (response) {
                setSociety({
                    ...society,
                    languageId: response.language_id,
                    countryId: response.country_id,
                    societyName: response.society_name,
                    societyDesc: response.society_desc,
                    societyCode: response.society_code
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (society.societyName === undefined || society.societyName === "") {
                    setErrors({
                        ...errors,
                        societyNameError: `${t("detail_vessel_class.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else if (society.countryId === undefined || society.countryId === null) {
                    setErrors({
                        ...errors,
                        countryIdError: `${t("detail_vessel_class.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else if (society.societyCode === undefined || society.societyCode === null) {
                    setErrors({
                        ...errors,
                        societyCodeError: `${t("detail_vessel_class.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "societyNameError", "countryIdError", "societyCodeError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const saveSociety = async () => {
        let response = await insertUpdateSociety(cookies.token, society, location.state.societyId);
        if (response) {
            alert(`${t("detail_vessel_class.alert_success_save_data")}`);
            setLoading(false);
            navigate("../.././VesselClassification/Society");
        } else {
            alert(`${t("detail_vessel_class.alert_failed_save_data")}`);
            setLoading(false);
        }

    }

    const loadCountry = async () => {
        try {
            let listCountry = await getCountry(cookies.token);
            setListCountry(listCountry);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
                setUpdatedButton(response.updated)
            }
            
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_vessel_class.header_title")}`} useActionContainer={false}
                useBackButton={true}
                backButtonChild={
                    <div>
                        <Button variant="secondary"
                            onClick={() => { navigate(-1); }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                alignSelf: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    alignSelf: "center",
                                    flex: 1,
                                }}><ArrowLeft size={32} /></div>
                            </div>
                        </Button></div>
                }
                    childContent={
                        <div className="master-table-inner-container">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setLoading(true);
                                saveSociety();
                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <DropDownForm value={society.countryId} caption={`${t("detail_vessel_class.field_country")}`} placeholder={`${t("detail_vessel_class.choose_country")}`}
                                        setValueFunction={(e) => {
                                            setSociety({ ...society, countryId: e.target.value });
                                        }} listOption={listCountry} valueKey="id" labelKey="country_name" />
                                    {
                                        errors.countryIdError && <p style={{ color: "red" }}>{errors.countryIdError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_vessel_class.field_class_name")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setSociety({ ...society, societyName: e.target.value })
                                    }} type="text" value={society.societyName} ></Form.Control>
                                    {
                                        errors.societyNameError && <p style={{ color: "red" }}>{errors.societyNameError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_vessel_class.field_class_code")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setSociety({ ...society, societyCode: e.target.value })
                                    }} type="text" value={society.societyCode} ></Form.Control>
                                    {
                                        errors.societyCodeError && <p style={{ color: "red" }}>{errors.societyCodeError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_vessel_class.field_description")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setSociety({ ...society, societyDesc: e.target.value })
                                    }} as="textarea" rows={3} value={society.societyDesc} ></Form.Control>
                                </Form.Group>
                                <Button style={{width:100}} disabled={!updatedButton} type="submit">{t("detail_vessel_class.save_button")}</Button>
                                <Button style={{width:100}} variant="danger" className="cancel" onClick={() => {
                                    navigate("../.././VesselClassification/Society");
                                }}>{t("detail_vessel_class.cancel_button")}</Button>
                            </Form>
                        </div>} />
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />

        </>
    )
}