import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Container, Form, ListGroup, Modal, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  isTokenValid, getEquipmentCategoryStruct,getEquipmentCategoryTree, getVesselById, getVesselEquipmentByCategory,
  getEquipmentPosition, insertUpdateEquipment, getEquipmentById, deleteEquipmentCategory,
  getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, CameraFill, Trash, PencilFill, FilePlusFill, Search, ArrowClockwise, InfoCircleFill, FileCheckFill, PlusSquareFill } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function EquipmentPage() {
  const [cookies, setCookie] = useCookies(["token"]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [updatedButton, setUpdatedButton] = useState("");
  const [deletedButton, setDeletedButton] = useState("");
  const [loading, setLoading] = useState(false);
  const [vessel, setVessel] = useState({});
  const [parameter, setParameter] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [listEquipmentCategory, setListEquipmentCategory] = useState([]);
  const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(-1);
  const [selectedVesselEquipment, setSelectedVesselEquipment] = useState({});
  const [detailEquipmentCategory, setDetailEquipmentCategoru] = useState([]);
  const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState([]);
  const [indexLevel, setIndexLevel] = useState("");
  const [isShownToCustomer, setIsShownToCustomer] = useState(false);
  const [listVesselEquipment, setListVesselEquipment] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [equipmentId, setEquipmentId] = useState("");

  const [equipmentCategory, setEquipmentCategory] = useState({
    id: 0,
    equipment_name: "",
    equipment_description: "",
    order_number: "",
    equipment_code: "",
    parent_id: null,
    is_shown_to_customer: false,
  })

  let listEquipmentCategoryTmp = [];
  let selectedEquipmentCategoryTmp = [];

  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated)
        navigate("/");
      else {
        // setLoading(false);
        loadEquipmentPosition();
        loadAccess();
        if (cookies.language_code === "id-id") {
          i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
          i18n.changeLanguage("en");
        }
      }
    }
    checkCookies();
  }, []);

  useEffect(() => {
    async function loadEqpCategory() {
      await loadEquipmentCategory();
    }
    loadEqpCategory();
  }, [vessel]);

  useEffect(() => {
    if (equipmentId !== "")
      removeEquipment();
  }, [equipmentId]);

  useEffect(() => {
    setSelectedVesselEquipment({});
  }, [listVesselEquipment]);

  useEffect(() => {
    //forceUpdate();
  }, [selectedVesselEquipment]);

  useEffect(() => {
    async function loadEqpCategoryById() {
      await loadEquipmentCategoryById();
    }

    loadEqpCategoryById();

  }, [selectedEquipmentCategory]);


  const clearEquipmentModal = async () => {
    let equipmentTmp = {};
    equipmentTmp.id = 0;
    equipmentTmp.equipment_name = "";
    equipmentTmp.equipment_description = "";
    equipmentTmp.order_number = "";
    equipmentTmp.parent_id = "";
    setEquipmentCategory(equipmentTmp);
    setParameter([]);
  }


  const buildEquipmentCategoryArray = (eqp, level) => {
    try {
      level++;
      let childArray = [];
      for (let i = 0; i < eqp.length; i++) {
        childArray.push(eqp[i]);
        if (eqp[i].children.length > 0) {
          buildEquipmentCategoryArray(eqp[i].children, level);
        }
      }
      selectedEquipmentCategoryTmp.push({});
      if (!listEquipmentCategoryTmp[level]) {
        listEquipmentCategoryTmp[level] = childArray;
      } else {
        let tmpArr = listEquipmentCategoryTmp[level].concat(childArray);
        listEquipmentCategoryTmp[level] = tmpArr;
      }

    } catch (exception) {
      console.log(exception);
    }
  }

  const loadEquipmentPosition = async () => {
    try {
      let response = await getEquipmentPosition(cookies.token);
      if (response) {
        setListEquipmentPosition(response);
      }
    } catch (exception) {
      console.log(exception);
    }
  }

  const loadEquipmentCategory = async () => {
    try {
      setLoading(true);
      // let listEquipmentCategory = await getEquipmentCategoryStruct(cookies.token, cookies.languageId);
      // let level = -1;
      // listEquipmentCategoryTmp = [];
      // selectedEquipmentCategoryTmp = [];
      // buildEquipmentCategoryArray(listEquipmentCategory, level);
      // setListEquipmentCategory(listEquipmentCategoryTmp);
      // setSelectedEquipmentCategory(selectedEquipmentCategoryTmp);
      let listEquipmentCategory = await getEquipmentCategoryTree(cookies.token, cookies.languageId);
      selectedEquipmentCategoryTmp = Array(200).fill({});
      listEquipmentCategoryTmp = listEquipmentCategory;
      setListEquipmentCategory(listEquipmentCategory);
      setSelectedEquipmentCategory(selectedEquipmentCategoryTmp);
      setLoading(false);
    } catch (exception) {
      console.log(exception);
    }
  }

  const saveData = async () => {
    try {
      let equipmentTmp = {};
      equipmentTmp.id = equipmentCategory.id;
      equipmentTmp.equipment_name = equipmentCategory.equipment_name;
      equipmentTmp.equipment_code = equipmentCategory.equipment_code;
      equipmentTmp.order_number = equipmentCategory.order_number;
      equipmentTmp.equipment_description = equipmentCategory.equipment_description;
      equipmentTmp.parent_id = indexLevel === "" ? null : selectedEquipmentCategory[indexLevel].id;
      equipmentTmp.is_shown_to_customer = equipmentCategory.is_shown_to_customer;

      console.log("Payload",equipmentTmp);
      let response = await insertUpdateEquipment(cookies.token, equipmentTmp, undefined, cookies.languageId, parameter);
      if (response.error_code === 0) {
        
        alert(`${t("equipment_category.alert_success_save_data")}`);
        setEquipmentModal(false);
        clearEquipmentModal();
        loadEquipmentCategory();
      } else {
        alert(`${t("equipment_category.alert_failed_save_data")}`)
      }
      setDisabledButton(false);
    } catch (exception) {
      console.log(exception)
    }

  }

  const loadEquipmentCategoryById = async () => {
    try {
      let response = await getEquipmentById(cookies.token, selectedEquipmentCategory[selectedLevel].id);
      setEquipmentCategory({
        ...equipmentCategory,
        id: response[0].id,
        equipment_name: response[0].equipment_name,
        equipment_description: response[0].equipment_description,
        order_number: response[0].order_number,
        equipment_code: response[0].equipment_code,
        parent_id: response[0].parent_id,
        is_shown_to_customer: response[0].is_shown_to_customer,
      })
      if (response[0].parameter_name !== null) {
        setParameter(response);
      } else {
        setParameter([]);
      }
      // setEquipmentCategory({})
    } catch (exception) {
      console.log(exception);
    }
  }


  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...parameter];
    list[index][name] = value;
    setParameter(list);

  }

  const handleremove = index => {
    const list = [...parameter];
    list.splice(index, 1);
    setParameter(list);
  }


  const handleaddclick = () => {
    setParameter([...parameter, { parameter_name: '', parameter_desc: '' }]);
  }

  const removeEquipment = async () => {
    let response = await deleteEquipmentCategory(cookies.token, equipmentId);
    if (response) {
      alert(`${t("equipment_category.delete_success_alert")}`);
      clearEquipmentModal();
      loadEquipmentCategory();
    } else {
      alert(`${t("equipment_category.delete_failed_alert")}`);
      setLoading(false);
    }
  }

  const loadAccess = async () => {
    try {
      // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
      let response = await getUserAccessById(cookies.token, window.location.pathname);
      if (response) {
        setDeletedButton(response.deleted);
        setUpdatedButton(response.updated)
      }
    } catch (exception) {
      console.log(exception);
    }
  }


  return (
    <>
      <Navbar />
      <Container fluid style={{
        display: "flex",
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}>
        <ContainerBox
          titleCaption={`${t("equipment_category.header_title")}`}

          childContent={
            <div className="master-table-inner-container">
              <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: 10,
                  marginBottom: 10,
                  borderWidth: 2,
                  borderColor: "#282c34",
                  borderStyle: "solid",
                  borderRadius: 5
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                    fontSize: 24,
                    paddingLeft: 10,
                  }}>
                    {t("equipment_category.content_title")}
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}>

                    {
                      listEquipmentCategory.map((item, index) => {
                        let number = 0
                        return (
                          <div key={index} style={{
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                            flex: 1,
                            maxHeight: 480,
                            paddingLeft: 5,
                            paddingRight: 5
                          }}>
                            {
                              index === 0 &&
                              <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                alignSelf: "flex-start",
                                width: "100%",
                                paddingBottom: 10,
                              }}>
                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !updatedButton} style={{
                                  width: "100%"
                                }} onClick={() => {
                                  clearEquipmentModal();
                                  setEquipmentModal(true);
                                  setSelectedLevel("");
                                  setIndexLevel("");
                                }}>
                                  <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "flex-start",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    alignSelf: "flex-start",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                  }}>
                                    <div style={{
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      alignSelf: "center",
                                    }}><PlusSquareFill size={16} />
                                      <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                      }}>{t("equipment_category.add_button")}</div>
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            }

                            {
                              index > 0 && selectedEquipmentCategory[index - 1].id &&
                              <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                alignSelf: "flex-start",
                                width: "100%",
                                paddingBottom: 10,
                              }}>
                                <Button disabled={selectedEquipmentCategory[selectedLevel] && (cookies.logingAs === "User" || cookies.extCustomerId === "null") ? !updatedButton : true} style={{
                                  width: "100%"
                                }} onClick={() => {
                                  clearEquipmentModal();
                                  setEquipmentModal(true);
                                  // setSelectedLevel(index - 1);
                                  setIndexLevel(index - 1)
                                }}>
                                  <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "flex-start",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    alignSelf: "flex-start",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                  }}>
                                    <div style={{
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      alignSelf: "center",
                                    }}><PlusSquareFill size={16} />
                                      <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                      }}>{t("equipment_category.add_button")}</div>
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            }
                            <ListGroup style={{
                              width: "100%"
                            }}>

                              {
                                index === 0 && listEquipmentCategory[index].map((item2, index2) => {
                                  return (

                                    <ListGroup.Item style={{
                                      width: "100%"
                                    }} key={index2} action onClick={() => {
                                      setSelectedLevel(index);
                                      let selectedEquipmentCategory2 = selectedEquipmentCategory.slice();
                                      selectedEquipmentCategory2[index] = item2;
                                      for (let i = 0; i < selectedEquipmentCategory2.length; i++) {
                                        if (i > index) {
                                          selectedEquipmentCategory2[i] = {};
                                        }
                                      }
                                      setSelectedEquipmentCategory(selectedEquipmentCategory2);
                                    }} variant={selectedEquipmentCategory[index].id === item2.id ? "primary" : "info"}>
                                      <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>{item2.order_number}</div>
                                        <br />
                                        <div style={{ flex: 2 }}>{item2.equipment_name}</div>
                                      </div>

                                    </ListGroup.Item>
                                  )
                                })
                              }
                              {
                                index > 0 && (index - 1) <= selectedLevel && listEquipmentCategory[index].filter(p => p.parent_id === selectedEquipmentCategory[index - 1].id).map((item2, index2) => {
                                  return (
                                    <ListGroup.Item style={{
                                      width: "100%"
                                    }} key={index2} action onClick={() => {
                                      setSelectedLevel(index);
                                      if (listEquipmentCategory.length - 1 === index) {
                                        let list = [];
                                        listEquipmentCategory.push(list)
                                      }
                                      let selectedEquipmentCategory2 = selectedEquipmentCategory.slice();
                                      selectedEquipmentCategory2[index] = item2;
                                      for (let i = 0; i < selectedEquipmentCategory2.length; i++) {
                                        if (i > index) {
                                          selectedEquipmentCategory2[i] = { id: null, name: "tes" };
                                        }
                                      }
                                      setSelectedEquipmentCategory(selectedEquipmentCategory2);
                                    }} variant={selectedEquipmentCategory[index].id === item2.id ? "primary" : "info"}>
                                      <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>{item2.order_number}</div>
                                        <br />
                                        <div style={{ flex: 2 }}>{item2.equipment_name}</div>
                                      </div>
                                    </ListGroup.Item>
                                  )
                                })
                              }

                            </ListGroup>
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: 10,
                  borderWidth: 2,
                  borderColor: "#282c34",
                  borderStyle: "solid",
                  borderRadius: 5
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    paddingRight: 10,
                  }}>

                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 5,
                      paddingLeft: 10,
                    }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          fontWeight: "bold",
                          fontSize: 24,
                          paddingRight: 10,
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center"
                        }}>
                          {t("equipment_category.detail_text")}
                        </div>
                        {
                          cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                          <></>
                          :
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                          }}>
                            <Button disabled={equipmentCategory.id ? !updatedButton : true} style={{
                              marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                              setEquipmentCategory({ ...equipmentCategory, id: selectedEquipmentCategory[selectedLevel].id })
                              setEquipmentModal(true);
                            }}>
                              <div style={{
                                display: "flex",
                                flex: 1,
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                alignSelf: "center",
                              }}>
                                <div style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  alignSelf: "center",
                                  flex: 1,
                                }}><PencilFill size={16} /></div>
                              </div>
                            </Button>
                          </div>
                        }
                        {
                          cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                          <></>
                          :
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                          }}>
                            <Button variant="danger" disabled={equipmentCategory.id ? !deletedButton : true} style={{
                              marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                              if (window.confirm(`${t("equipment_category.delete_confirmation")}`)) {
                                // handleremove(i)
                                let equipmentId = equipmentCategory.id
                                setEquipmentId(equipmentId);
                                setLoading(true);
                              }
                            }}>
                              <div style={{
                                display: "flex",
                                flex: 1,
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                alignSelf: "center",
                              }}>
                                <div style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  alignSelf: "center",
                                  flex: 1,
                                }}><Trash size={16} /></div>
                              </div>
                            </Button>
                          </div>
                        }
                       
                      
                        {/*
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center"
                        }}>
                          <Button disabled={selectedEquipmentCategory.id ? false : true} style={{
                            marginLeft: 5, marginRight: 5
                          }} onClick={() => {

                          }}>
                            <div style={{
                              display: "flex",
                              flex: 1,
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              alignSelf: "center",
                            }}>
                              <div style={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                                flex: 1,
                              }}><FileCheckFill size={16} /></div>
                            </div>
                          </Button>
                        </div> */}
                      </div>

                      {equipmentCategory.equipment_name &&
                        <div style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          flexWrap: "nowrap",
                          width: "100%"
                        }}>
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            flexWrap: "nowrap",
                            paddingRight: 10
                          }}>
                            <div style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              flexWrap: "nowrap",
                              paddingRight: 10
                            }}>

                              <Form.Group className="mb-3">
                                <Form.Label>{t("equipment_category.field_equipment_name")}</Form.Label>
                                <Form.Control disabled onChange={(e) => {
                                  // setSelectedVesselEquipment({ ...selectedVesselEquipment, title: e.target.value })
                                }} type="text" value={equipmentCategory.equipment_name} required placeholder=""></Form.Control>
                              </Form.Group>
                              {/* <Form.Group className="mb-3">
                                <Form.Label>{t("equipment_category.field_equipment_code")}</Form.Label>
                                <Form.Control disabled onChange={(e) => {
                                  // setSelectedVesselEquipment({ ...selectedVesselEquipment, title: e.target.value })
                                }} type="text" value={equipmentCategory.equipment_code} placeholder=""></Form.Control>
                              </Form.Group> */}
                              <Form.Group className="mb-3">
                                <Form.Label>{t("equipment_category.field_equipment_order_number")}</Form.Label>
                                <Form.Control disabled onChange={(e) => {
                                  // setSelectedVesselEquipment({ ...selectedVesselEquipment, title: e.target.value })
                                }} type="text" value={equipmentCategory.order_number} placeholder=""></Form.Control>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>{t("equipment_category.field_equipment_description")}</Form.Label>
                                <Form.Control disabled onChange={(e) => {
                                  // setSelectedVesselEquipment({ ...selectedVesselEquipment, manufacture_name: e.target.value })
                                }} as="textarea" rows={3} value={equipmentCategory.equipment_description} placeholder=""></Form.Control>
                              </Form.Group>
                              <Form.Group>
                                {/* <Form.Label>Consumable Item</Form.Label> */}
                                <Form.Check disabled checked={equipmentCategory.is_shown_to_customer} label={'Show to Customer'} onChange={(e) => {
                                  // if (e.target.checked) {
                                  //   setIsShownToCustomer(true);
                                  // }
                                  // else {
                                  //   setIsShownToCustomer(false);
                                  // }
                                }}>
                                </Form.Check>
                              </Form.Group>
                            </div>

                          </div>
                          {
                            parameter.length > 0 &&
                            <div style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "column",
                              flexWrap: "nowrap",
                              width: "100%"
                            }}>
                              <div style={{
                                display: "flex",
                                flexDirection: "column",
                                fontWeight: "bold",
                                fontSize: 18,

                              }}>
                                {t("equipment_category.text_parameter")}
                              </div>
                              <div className="table-container">
                                <div className="table-header">
                                  <div style={{ flex: 1 }} className="table-header-content">#</div>
                                  <div style={{ flex: 6 }} className="table-header-content">{t("equipment_category.table_name1")}</div>
                                  <div style={{ flex: 8 }} className="table-header-content">{t("equipment_category.table_name2")}</div>
                                </div>
                                {
                                  parameter.map((parameter, index) => {
                                    return (

                                      <div key={index} className="table-body">
                                        <div style={{ flex: 1 }} className="table-body-content"><p>{(index + 1)}</p></div>
                                        <div style={{ flex: 6 }} className="table-body-content"><p>{parameter.parameter_name}</p></div>
                                        <div style={{ flex: 8 }} className="table-body-content"><p>{parameter.parameter_desc}</p></div>

                                      </div>
                                    )
                                  })

                                }
                              </div>

                            </div>
                          }

                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
              <Loading
                loading={loading}
              />
            </div>
          } />

        {/* <Modal dialogClassName="modal-my" show={equipmentModal} onHide={() => {
          setEquipmentModal(false);
          // resetVessel();
        }}>

          <Modal.Header closeButton onClick={() => {
            // resetVessel();
          }}>
            <Modal.Title>Tambah Peralatan</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Form onSubmit={(e) => {
              e.preventDefault();
              saveData();
            }}>
              <Form.Group>
                <Form.Label>Nama Peralatan</Form.Label>
                <Form.Control onChange={(e) => {
                  setEquipmentCategory({ ...equipmentCategory, equipment_name: e.target.value })
                }} value={equipmentCategory.equipment_name} type="text" placeholder="Masukkan Nama Peralatan" required></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Deskripsi Peralatan</Form.Label>
                <Form.Control onChange={(e) => {
                  setEquipmentCategory({ ...equipmentCategory, equipment_description: e.target.value })
                }} value={equipmentCategory.equipment_description} as="textarea" row={3} placeholder="Masukkan Deskripsi Peralatan"></Form.Control>
              </Form.Group>
              <div style={{ paddingBottom: 20 }}></div>




              {
                parameter.length === 0 ?
                  <></>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>Nama Parameter</th>
                        <th>Deskripsi Parameter</th>
                        <th>Hapus</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        parameter.map((x, i) => {
                          return (
                            <>
                              {
                                parameter.length === 0 ?
                                  <></>
                                  :

                                  <tr>
                                    <td>
                                      <Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control>

                                    </td>
                                    <td>
                                      <Form.Control name="parameter_desc" value={x.parameter_desc} type="text" onChange={e => handleinputchange(e, i)} placeholder="Deskripsi Parameter"></Form.Control>
                                    </td>
                                    <td>

                                      {
                                        parameter.length >= 1 &&
                                        <Button variant="danger" onClick={() => {
                                          if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                            handleremove(i)
                                          }
                                        }}><Trash /></Button>
                                      }

                                    </td>
                                  </tr>
                              }


                            </>

                          )


                        })
                      }

                    </tbody>
                  </Table>
              }


              <center><Button className="add-button" variant="primary" onClick={handleaddclick}>Tambah Parameter</Button></center>





              <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                flex: 1,
              }}>
                <Button variant="primary" type="submit">
                  Simpan
                </Button>
                <Button className="cancel" variant="danger" onClick={() => {
                  clearEquipmentModal();
                  setEquipmentModal(false);
                }}>
                  Batal
                </Button>
              </div>
            </Form>
          </Modal.Body>


        </Modal> */}

        {/* <Modal show={equipmentModal} onHide={() => {
          setEquipmentModal(false);
        }}>
          <ContainerBox
            containerPos="inner"
            titleCaption={`Tambah Kategori Peralatan`}
            childContent={
              <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flex: 1,
                padding: 10,
              }}>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  saveData();
                }}>
                  <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <Form.Label>Nama Peralatan</Form.Label>
                      <div style={{
                        display: "flex",
                        minWidth: "100%",
                      }}>
                        <Form.Control style={{
                          display: "flex",
                          minWidth: "100%",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }} onChange={(e) => {
                          setEquipmentCategory({ ...equipmentCategory, equipment_name: e.target.value })
                        }} value={equipmentCategory.equipment_name} type="text" placeholder="Masukkan Nama Peralatan" required></Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                  <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    flex: 1,

                  }}>
                    <Form.Group style={{
                      display: "flex",
                      minWidth: "100%",
                      flexDirection: "column",
                    }} className="mb-3">
                      <Form.Label>Deskripsi</Form.Label>
                      <div style={{
                        display: "flex",
                        minWidth: "100%",
                      }}>
                        <Form.Control onChange={(e) => {
                          setEquipmentCategory({ ...equipmentCategory, equipment_description: e.target.value })
                        }} value={equipmentCategory.equipment_description} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                  <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}>
                    <Button variant="primary" type="submit">
                      Simpan
                    </Button>
                    <Button className="cancel" variant="danger" onClick={() => {
                      clearEquipmentModal();
                      setEquipmentModal(false);
                    }}>
                      Batal
                    </Button>
                  </div>
                </Form>
              </div>
            }
          />
        </Modal> */}

        {/* <Modal 
        // dialogClassName="modal-my"
        size={"lg"}
         show={equipmentModal} onHide={() => {
          setEquipmentModal(false);
        }}>
          <ContainerBox
            containerPos="inner"
            titleCaption={`Tambah Kategori Peralatan`}
            childContent={
              <div style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
                justifyContent: "center",

              }}>
                <div style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  width: "60%",
                  justifyContent: "center",
                  justifyItems: "center",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}>
                  <div style={{ paddingBottom: 10 }}></div>
                  <Form onSubmit={(e) => {
                    setDisabledButton(true);
                    e.preventDefault();
                    saveData()
                  }}>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      flex: 1,
                      flexWrap: "nowrap"

                    }}>

                      <Form.Group style={{
                        display: "flex",
                        // minWidth: "100%",
                        flexDirection: "column",
                        flex: 1,
                        flexWrap: "nowrap"
                      }} className="mb-3">
                        <Form.Label>Nama Peralatan</Form.Label>
                        <div style={{
                          display: "flex",
                          flex: 1,
                          // maxWidth: 500,
                        }}>
                          <Form.Control onChange={(e) => {
                            setEquipmentCategory({ ...equipmentCategory, equipment_name: e.target.value })
                          }} value={equipmentCategory.equipment_name} type="text" placeholder="" required></Form.Control>
                        </div>
                      </Form.Group>
                    </div>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      flex: 1,
                      flexWrap: "nowrap"
                    }}>
                      <Form.Group style={{
                        display: "flex",
                        minWidth: "100%",
                        flex: 1,
                        flexDirection: "column",
                        flexWrap: "nowrap"
                      }} className="mb-3">
                        <Form.Label>Kode Peralatan</Form.Label>
                        <div style={{
                          display: "flex",
                          // minWidth: 500,
                          flex: 1,
                          flexWrap: "nowrap"
                        }}>
                          <Form.Control onChange={(e) => {
                            setEquipmentCategory({ ...equipmentCategory, equipment_code: e.target.value })
                          }} value={equipmentCategory.equipment_code} type="text" placeholder="" required></Form.Control>
                        </div>
                      </Form.Group>
                    </div>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      flex: 1,
                    }}>
                      <Form.Group style={{
                        display: "flex",
                        minWidth: "100%",
                        flexDirection: "column",
                        flex: 1,
                        flexWrap: "nowrap"
                      }} className="mb-3">
                        <Form.Label>Deskripsi Peralatan</Form.Label>
                        <div style={{
                          display: "flex",
                          // minWidth: 500,
                          flex: 1,
                          flexWrap: "nowrap"
                        }}>
                          <Form.Control onChange={(e) => {
                            setEquipmentCategory({ ...equipmentCategory, equipment_description: e.target.value })
                          }} value={equipmentCategory.equipment_description} as="textarea" row={3} placeholder=""></Form.Control>
                        </div>
                      </Form.Group>
                    </div>

                    {parameter.length > 0 &&
                      <div className="table-container">
                        <div className="table-header">
                          <div style={{ flex: 1, maxWidth: 50 }} className="table-header-content">#</div>
                          <div style={{ flex: 2 }} className="table-header-content">Nama Parameter</div>
                          <div style={{ flex: 2 }} className="table-header-content">Deskripsi </div>
                          <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                        </div>
                        {
                          parameter.map((x, i) => {
                            return (<div className="table-body">
                              <div style={{ flex: 1, justifyContent: "center", maxWidth: 50 }} className="table-body-content"><p>{i + 1}</p></div>
                              <div style={{ flex: 2 }} className="table-body-content"><p><Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control></p></div>
                              <div style={{ flex: 2 }} className="table-body-content"><p> <Form.Control name="parameter_desc" value={x.parameter_desc} type="text" row={1} onChange={e => handleinputchange(e, i)} placeholder="Deskripsi "></Form.Control></p></div>
                              <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                <div style={{ padding: 5 }}>
                                  {
                                    parameter.length >= 1 &&
                                    <Button variant="danger" onClick={() => {
                                      if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                        handleremove(i)
                                      }
                                    }}><Trash /></Button>
                                  }
                                </div></div>


                            </div>)
                          }
                          )
                        }
                      </div>
                    }

                    <div style={{
                      paddingTop: 20,
                    }}>

                    </div>
                    <center><Button className="add-button" variant="primary" onClick={handleaddclick}>Tambah Parameter</Button></center>
                    <div style={{
                      paddingTop: 20,
                    }}>

                    </div>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      flex: 1,
                      paddingBottom:10
                    }}>
                      <Button variant="primary" type="submit" disabled={disabledButton}>
                        Simpan
                      </Button>
                      <Button className="cancel" variant="danger" onClick={() => {
                        clearEquipmentModal();
                        setEquipmentModal(false);
                      }}>
                        Batal
                      </Button>
                    </div>
                  </Form>

                </div>


              </div>
            }
          />
        </Modal> */}

        <Modal show={equipmentModal}
          // dialogClassName="modal-size"
          size={"lg"}
          onHide={() => {
            setEquipmentModal(false);
            // clearInventoryTypeModal()
          }}>
          <div className="details m-2" >
            <div className="detailscontent">
              <h3>{t("equipment_category.modal_add_category")} </h3>
            </div>

            <Form onSubmit={(e) => {
              setDisabledButton(true);
              e.preventDefault();
              saveData();
              setLoading(true);
            }} style={{ padding: 10 }}>



              <Form.Group className="mb-3">
                <Form.Label>{t("equipment_category.field_equipment_name")}</Form.Label>

                <Form.Control onChange={(e) => {
                  setEquipmentCategory({ ...equipmentCategory, equipment_name: e.target.value })
                }} value={equipmentCategory.equipment_name} type="text" placeholder="" required></Form.Control>

              </Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Label>{t("equipment_category.field_equipment_code")}</Form.Label>

                <Form.Control onChange={(e) => {
                  setEquipmentCategory({ ...equipmentCategory, equipment_code: e.target.value })
                }} value={equipmentCategory.equipment_code} type="text" placeholder="" ></Form.Control>

              </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Label>{t("equipment_category.field_equipment_order_number")}</Form.Label>

                <Form.Control onChange={(e) => {
                  setEquipmentCategory({ ...equipmentCategory, order_number: e.target.value })
                }} value={equipmentCategory.order_number} type="text" placeholder="" ></Form.Control>

              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t("equipment_category.field_equipment_description")}</Form.Label>

                <Form.Control onChange={(e) => {
                  setEquipmentCategory({ ...equipmentCategory, equipment_description: e.target.value })
                }} value={equipmentCategory.equipment_description} as="textarea" rows={3} placeholder=""></Form.Control>

              </Form.Group>

              <Form.Group>
                {/* <Form.Label>Consumable Item</Form.Label> */}
                <Form.Check checked={equipmentCategory.is_shown_to_customer} label={'Show to Customer'} onChange={(e) => {
                  if (e.target.checked) {
                    setEquipmentCategory({ ...equipmentCategory, is_shown_to_customer: true })
                  }
                  else {
                    setEquipmentCategory({ ...equipmentCategory, is_shown_to_customer: false })
                  }
                }}>
                </Form.Check>
              </Form.Group>

              {parameter.length > 0 &&
                <div className="table-container">
                  <div className="table-header">
                    <div style={{ flex: 1, maxWidth: 50 }} className="table-header-content">#</div>
                    <div style={{ flex: 2 }} className="table-header-content">{t("equipment_category.table_name1")}</div>
                    <div style={{ flex: 2 }} className="table-header-content">{t("equipment_category.table_name2")} </div>
                    <div style={{ flex: 1 }} className="table-header-content">{t("equipment_category.table_name3")}</div>
                  </div>
                  {
                    parameter.map((x, i) => {
                      return (<div className="table-body">
                        <div style={{ flex: 1, justifyContent: "center", maxWidth: 50 }} className="table-body-content"><p>{i + 1}</p></div>
                        <div style={{ flex: 2 }} className="table-body-content"><p><Form.Control name="parameter_name" value={x.parameter_name} type="text" onChange={e => handleinputchange(e, i)} placeholder="Nama Parameter" required></Form.Control></p></div>
                        <div style={{ flex: 2 }} className="table-body-content"><p> <Form.Control name="parameter_desc" value={x.parameter_desc} type="text" row={1} onChange={e => handleinputchange(e, i)} placeholder="Deskripsi "></Form.Control></p></div>
                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                          <div style={{ padding: 5 }}>
                            {
                              parameter.length >= 1 &&
                              <Button variant="danger" onClick={() => {
                                if (window.confirm(`${t("equipment_category.delete_confirmation")}?`)) {
                                  handleremove(i)
                                }
                              }}><Trash /></Button>
                            }
                          </div></div>


                      </div>)
                    }
                    )
                  }
                </div>
              }

              <div style={{
                paddingTop: 20,
              }}>

              </div>
              <center><Button className="add-button" variant="primary" onClick={handleaddclick}>{t("equipment_category.button_add_parameter")}</Button></center>
              <div style={{
                paddingTop: 20,
              }}>

              </div>




              <div style={{ paddingBottom: 10 }}></div>
              {
                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                  <></>
                  :
                  <div style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center"
                  }}>
                    <Button style={{width:100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("equipment_category.save_button")}</Button>
                    <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                      clearEquipmentModal();
                      setEquipmentModal(false);
                    }}>{t("equipment_category.cancel_button")}</Button>
                  </div>
              }


            </Form>



          </div>

        </Modal>
      </Container >
    </>
  )


}