import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselxEquipment, getVesselxEquipmentCount, deleteVesselxEquipment } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";



export default function VesselEquipmentPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [vesselEquipmentCount, setVesselEquipmentCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Kapal",
        value: "vessel_id"
    });
    const orderByList = [{
        label: "Nama Kapal",
        value: "vessel_id"
    }, {
        label: "Nama Peralatan",
        value: "equipment_id"
    }
    ]

    const [descending, setDescending] = useState(false);
    const [removeId, setRemoveId] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVesselEquipment();
                setLoading(false);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadVesselEquipment();
    }, [page]);

    useEffect(() => {
        loadVesselEquipment();
    }, [itemPerPage]);

    useEffect(() => {
        loadVesselEquipment();
    }, [orderBy]);

    useEffect(() => {
        removeVesselEquipment();
        loadVesselEquipment();
    }, [removeId]);

    useEffect(() => {
        if (searchQuery === "")
            loadVesselEquipment();
    }, [searchQuery]);

    useEffect(() => {
        loadVesselEquipment();
    }, [descending]);



    const loadVesselEquipment = async () => {
        try {
            let vesselEquipmentCount = await getVesselxEquipmentCount(cookies.token, searchQuery, false);
            setVesselEquipmentCount(vesselEquipmentCount);
            let totalPage = vesselEquipmentCount / itemPerPage;
            setTotalPage(totalPage);
            let tmpSearchQuery = undefined;
            if (searchQuery) {
                tmpSearchQuery = searchQuery;
            }
            let listVesselEquipment = await getVesselxEquipment(cookies.token, page, itemPerPage, orderBy.value, descending, tmpSearchQuery, false);
            setListVesselEquipment(listVesselEquipment);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const removeVesselEquipment = async () => {
        try {
            let response = await deleteVesselxEquipment(cookies.token, removeId);
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Peralatan Kapal"
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => { navigate("/VesselEquipment/Detail", { state: { vesselEquipmentId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Peralatan Kapal</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cari (Nama Kapal, Nama Peralatan, titel)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadVesselEquipment();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadVesselEquipment();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadVesselEquipment();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="table-header-caption">
                                        <th>#</th>
                                        <th>Foto</th>
                                        <th>Nama Kapal</th>
                                        <th>Nama Peralatan</th>
                                        <th>Titel</th>
                                        <th>Deskripsi</th>
                                        <th>Detail</th>
                                        <th>Hapus</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        listVesselEquipment.length === 0 ?
                                            <tr>
                                                <td colSpan={8} style={{ textAlign: "center" }}>Tidak Ada Hasil Untuk Pencarian {searchQuery}</td>
                                            </tr>
                                            :
                                            <></>
                                    }
                                    {
                                        listVesselEquipment.map((vesselEquipment, index) => {
                                            return (
                                                <tr key={index} className="table-body-caption">
                                                    <td>{index + 1}</td>
                                                    <td><img src={vesselEquipment.base64_img} style={{ maxWidth: 40, maxHeight: 60 }}></img></td>
                                                    <td>{vesselEquipment.vessel_name}</td>
                                                    <td>{vesselEquipment.equipment_name}</td>
                                                    <td>{vesselEquipment.title}</td>
                                                    <td>{vesselEquipment.description === "" ? <></> : <div className="tooltip"><div className="descriptionelips">{vesselEquipment.description}</div>
                                                        <span className="tooltiptext">{vesselEquipment.description}</span>
                                                    </div>}
                                                    </td>
                                                    <td>
                                                        <div className="table-action-button">
                                                            <div style={{ padding: 5 }}>
                                                                <Button onClick={() => {
                                                                    navigate("/VesselEquipment/Detail", { state: { vesselEquipmentId: vesselEquipment.id } })
                                                                }}> <PencilFill /></Button>
                                                            </div>
                                                            <div style={{ padding: 5 }}>
                                                                <Button className="cancel" onClick={() => {
                                                                    navigate("/VesselEquipment/Photo", { state: { vesselEquipmentId: vesselEquipment.id, vesselName: vesselEquipment.vessel_name, equipmentName: vesselEquipment.equipment_name } });
                                                                }}><CameraFill /></Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-action-button">
                                                            <div style={{ padding: 5 }}>
                                                                <Button className="cancel" onClick={() => {
                                                                    if (window.confirm('Apakah Anda Ingin Menghapus Data Ini?')) {
                                                                        setRemoveId(vesselEquipment.id);
                                                                    }
                                                                }} variant="danger"><Trash /></Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )
                                        })

                                    }
                                </tbody>
                            </Table>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>


        </>
    );

}