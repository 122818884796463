import './NewSideBar.css'
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Routes, Route, Link, useNavigate, NavLink  } from "react-router-dom";
import React from "react";
import QPD from "../../Assets/Images/CustomerDashboard/WMI_logo.png";
import Clock from 'react-live-clock';
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useEffect, useState } from 'react';
import { isTokenValid } from '../../Helpers/ApplicationHelpers';
import { useTranslation } from "react-i18next";
import { List, X } from "react-bootstrap-icons";
import Offcanvas from 'react-bootstrap/Offcanvas';
import QPD_Icon from '../../Assets/Images/qpd_logo.png'

export default function NewSidebar(props) {
    const { companyName, smsSerialNumber, vesselId, vesselName, customerName } = props;
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                i18n.changeLanguage(cookies.language_code === "id-id" ? "id" : "en");
            }
        }
        checkCookies();
    }, [cookies.token, cookies.language_code, i18n, navigate]);

    const logOut = async () => {
        try {
            removeCookie("token", { path: "/" });
            navigate(cookies.logingAs === 'User' ? "/Customer/Login" : "/");
        } catch (exception) {
            console.log(exception);
        }
    }
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenParent, setIsOpenParent] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow(prev => !prev);

    const checkStatus = () => setIsOpen(prev => !prev);
    const checkStatusParent = () => setIsOpenParent(prev => !prev);
    return (
        <div style={{ marginTop: '35px' }}>
            <Button onClick={toggleShow} className='btnStyleForSideBar'>
                <List size={30} />
                {/* <img style={{width: '100%'}} src={QPD_Icon}/> */}
            </Button>
            <Offcanvas style={{width: '300px'}} show={show} onHide={handleClose}>
                <div className='bodySB'>
                    <div style={{ display: "flex", height: "100vh", width: '100%' }}>
                        <Sidebar style={{ width: '300px', background: '#ffffff' }}>
                            <Menu>
                                <div className="menuTOP">
                                    <div className='innerMenuItemTOP'>
                                        <div >
                                            <NavLink exact to="/Customer/Dashboard" activeClassName="activeClicked">
                                                <img style={{ height: '95px' }} src={QPD} alt="Logo" />
                                            </NavLink>
                                        </div>
                                        <div >
                                            <p>{String(companyName).toUpperCase().replace(/'/g, "")}</p>
                                            <p>Welcome, {customerName}<br />
                                                <Clock format={'dddd, DD MMMM YYYY, hh:mm:ss'} ticking={true} timezone={'Asia/Jakarta'} />
                                            </p>
                                        </div>
                                        <div onClick={handleClose} style={{ height: '50px', width: '50px', padding: '5px', backgroundColor: 'rgba(219, 235, 255, 0.9)', cursor: 'pointer' }}>
                                            <X size={32} />
                                        </div>
                                    </div>
                                    <Button onClick={logOut} style={{ color: 'white', width: '90%' }}>Logout</Button>
                                </div>
                                <MenuItem className='menuItem' component={<Link to="/Customer/VesselLocation" className="link" />} > VESSEL LOCATION </MenuItem>
                                <SubMenu className='menuItem' label="TUG & BARGES">
                                    <SubMenu className='menuItem'
                                        label={
                                            <NavLink exact to="/Customer/GeneralSpecification" activeClassName="activeClicked" style={{ textDecoration: 'none', color: 'rgba(3, 30, 103, 1)' }}>
                                                PRIME 201
                                            </NavLink>}>
                                        <NavLink exact to={`/Customer/SMSDashboard?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                            <MenuItem className='menuItem'> <p >SMART MONITORING SYSTEM</p></MenuItem>
                                        </NavLink>
                                        {/* <NavLink exact to={`/Customer/SMSVNC?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                            <MenuItem className='menuItem'> <p>SMART MONITORING SYSTEM <br/> <i>Remote App</i></p></MenuItem>
                                        </NavLink> */}
                                        <SubMenu className='menuItem' label={<p>PLANNED MAINTENANCE SYSTEM</p>}>
                                            <NavLink exact to={`/Customer/VesselEquipment?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                                <MenuItem className='menuItem' > VESSEL EQUIPMENT </MenuItem>
                                            </NavLink>
                                            <NavLink exact to={`/Customer/MaintenancePlan?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                                <MenuItem className='menuItem' > MAINTENANCE </MenuItem>
                                            </NavLink>
                                            <NavLink exact to={`/Customer/TankCapacity?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                                <MenuItem className='menuItem' > TANK CAPACITY </MenuItem>
                                            </NavLink>
                                            <NavLink exact to={`/Customer/FuelConsumption?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                                <MenuItem className='menuItem' > FUEL CONSUMPTION </MenuItem>
                                            </NavLink>
                                        </SubMenu>
                                    </SubMenu>
                                    <MenuItem className='menuItem'> TS 03 </MenuItem>
                                    <MenuItem className='menuItem'> TS 04 </MenuItem>
                                </SubMenu>
                            </Menu>
                        </Sidebar>
                    </div>
                </div>
            </Offcanvas>
        </div>
    )
}
