import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ArrowLeft, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, FileCheckFill, CameraFill } from "react-bootstrap-icons";
import { isTokenValid, getDivision, getUserAccessById, getInventoryBrand } from "../../../../Helpers/ApplicationHelpers";
import {
    getInventoryType, insertUpdateInventoryMaster, getInventoryMasterById, getInventoryTypeSubItem, getInventoryUnitType, getInventoryPeriod,
    getInventoryCondition, getInventoryStatus, checkBarcodeNumber,
    getInventoryGroup,
    getInventoryMaterial,
    getInventorySubGroupByGroupId
} from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Select from 'react-select';
import { omit } from 'lodash';
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

export default function InventoryMasterDetailPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [inventoryId, setInventoryId] = useState("");
    const [listInventoryGroup, setListInventoryGroup] = useState([]);
    const [listInventorySubGroup, setListInventorySubGroup] = useState([]);
    const [listInventoryMaterial, setListInventoryMaterial] = useState([]);
    const [listInventoryBrand, setListInventoryBrand] = useState([]);
    const [listUnitType, setListUnitType] = useState([]);
    const [listInventoryPeriod, setListInventoryPeriod] = useState([]);
    const [listInventoryStatus, setListInventoryStatus] = useState([]);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState({
        id: "",
        inventory_group_name: "",
        inventory_group_code: "",
    });
    const [selectedSubGroup, setSelectedSubGroup] = useState({
        id: "",
        inventory_subgroup_name: "",
        inventory_subgroup_code: "",
    });
    const [selectedMaterial, setSelectedMaterial] = useState({
        id: "",
        inventory_material_name: "",
        inventory_material_code: "",
    });
    const [selectedBrand, setSelectedBrand] = useState({
        id: "",
        inventory_brand_name: "",
        inventory_brand_code: "",
    });
    const [selectedUnitType, setSelectedUnitType] = useState();
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [isConsumeable, setIsConsumeable] = useState();
    const [isProductionMaterial, setIsProductionMaterial] = useState();
    const [showParameterModal, setShowParameterModal] = useState(false);
    const [backButton, setBackButton] = useState();
    const [inventoryMaster, setInventoryMaster] = useState({
        id: 0,
        inventory_master_name: "",
        unit: "",
        reference_price: null,
        brand: "",
        model: "",
        quantity: 0,
        inventory_master_description: "",
        inventory_master_note: "",
        inventory_unit_id: "",
        language_id: cookies.languageId,
        ext_customer_id: cookies.extCustomerId,
        is_consumeable: false,
        lead_time: 0,
        buffer_stock: 0,
        inventory_brand_id: "",
        inventory_material_id: "",
        inventory_master_alias: "",
        inventory_group_id: "",
        inventory_subgroup_id: "",
        is_production_material: false,
        unit_name: "",
        unit_code: "",
        inventory_group_name: "",
        inventory_group_code: "",
        inventory_subgroup_name: "",
        inventory_subgroup_code: "",
        inventory_material_name: "",
        inventory_material_code: "",
        brand_name: "",
        brand_code: "",
        part_number: "",
        parameters: [],
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setInventoryId(location.state.inventoryMasterId);
            }
        }
        checkCookies();
        loadAccess();
        // if (location.state.inventoryMasterId === 0) {

        // } else {
        //     initInventoryMaster();
        // }
    }, []);

    useEffect(() => {
        setBackButton(location.state.backButton);
        loadGroup();
        loadSubGroup();
        loadMaterial();
        loadInventoryBrand();
        loadUnitType();
        loadInventoryPeriod();
        loadInventoryStatus();
        loadInventoryCondition();
        console.log(inventoryMaster);
        if (inventoryId !== "" && inventoryId !== 0) {
            console.log(inventoryId);
            initInventoryMaster();
        }
    }, [inventoryId])

    // useEffect(()=> {
    //     console.log(listInventoryGroup)
    // }, [listInventoryGroup])

    useEffect(() => {
        console.log(inventoryMaster);
        console.log(isFirstLoad);
        if (isFirstLoad) {
            if (inventoryMaster.inventory_group_id !== "") {
                let selGroup = listInventoryGroup.find(p => p.id === inventoryMaster.inventory_group_id);
                setSelectedGroup(selGroup);
            }

            if (inventoryMaster.inventory_material_id !== "") {
                let selMaterial = listInventoryMaterial.find(p => p.id === inventoryMaster.inventory_material_id)
                setSelectedMaterial(selMaterial);
            }

            if (inventoryMaster.inventory_brand_id !== "") {
                let selBrand = listInventoryBrand.find(p => p.id === inventoryMaster.inventory_brand_id)
                setSelectedBrand(selBrand);
            }

            if (inventoryMaster.inventory_unit_id !== "") {
                let selUnit = listUnitType.find(p => p.id === inventoryMaster.inventory_unit_id)
                setSelectedUnitType(selUnit);
            }
        }
        if(inventoryMaster.id !== 0){
            setIsFirstLoad(false);
        }
        validationForm();

        /* if (inventoryMaster.is_consumeable !== "") {
            let setConsumeable = inventoryMaster.is_consumeable;
            setIsConsumeable(setConsumeable);
        } */

        if (inventoryMaster.is_production_material !== "") {
            let setProductionMaterial = inventoryMaster.is_production_material;
            setIsProductionMaterial(setProductionMaterial);
        }


    }, [inventoryMaster])

    useEffect(() => {
        validationForm();
    }, [selectedGroup, selectedSubGroup, selectedMaterial])


    useEffect(() => {
        if (selectedGroup) {
            //setInventoryMaster({ ...inventoryMaster, inventory_group_id: selectedGroup.id, inventory_group_name: selectedGroup.inventory_group_name});
            loadSubGroup();
            // console.log(selectedGroup);
        }
    }, [selectedGroup]);


    useEffect(() => {
        console.log(listInventorySubGroup);
        if (inventoryMaster.inventory_subgroup_id !== "") {
            let selSubGroup = listInventorySubGroup.find(p => p.id === inventoryMaster.inventory_subgroup_id);
            setSelectedSubGroup(selSubGroup);
        }
    }, [listInventorySubGroup]);

    useEffect(() => {
        console.log(listInventoryBrand);

    }, [selectedBrand]);




    const saveInventoryMaster = async () => {
        let invTmp = inventoryMaster;
        let purchase_price
        // if (inventoryMaster.id === 0 && inventoryMaster.is_consumeable) {
        //     let barcodeNumber = await generateCode();
        //     let today = new Date();
        //     let yyyy = today.getFullYear();
        //     let invCode = "QPD/" + inventoryMaster.id + "/EQP/" + yyyy + "/" + barcodeNumber;
        //     invTmp.inventory_code = invCode;
        //     invTmp.barcode_number = barcodeNumber;
        //     if (inventoryMaster.purchase_price.toString().match(/\d{1,3}/gi)) purchase_price = inventoryMaster.purchase_price.toString().replace(/[^,\d]/g, '');
        // }

        console.log("GROUP", selectedGroup);
        console.log("SUBGROUP", selectedSubGroup);
        console.log("MATERIAL", selectedMaterial);
        console.log("BRAND", selectedBrand);

        invTmp.is_consumeable = isConsumeable;
        invTmp.is_production_material = isProductionMaterial;
        invTmp.id = location.state.inventoryMasterId;
        invTmp.language_id = cookies.languageId;
        invTmp.ext_customer_id = invTmp.ext_customer_id === "null" || "undefined" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : inventoryMaster.ext_customer_id
        invTmp.purchase_price = purchase_price;
        invTmp.inventory_group_id = selectedGroup.id;
        invTmp.inventory_subgroup_id = selectedSubGroup.id;
        invTmp.inventory_material_id = selectedMaterial.id;
        invTmp.inventory_brand_id = selectedBrand.id;
        invTmp.inventory_unit_id = selectedUnitType.id;
        invTmp.weight = inventoryMaster.weight;
        console.log(invTmp);

        let response = await insertUpdateInventoryMaster(cookies.token, invTmp);
        if (response.error_code === 0) {
            alert(`${t("detail_inventory_master.alert_success_save_data")}`);
            navigate("/InventoryMaster", { state: {backButton: backButton} });
            console.log(response);
        }
        else {
            alert(`${t("detail_inventory_master.alert_failed_save_data")}`)
        }
    }

    const initInventoryMaster = async () => {
        try {
            let response = await getInventoryMasterById(cookies.token, location.state.inventoryMasterId, cookies.languageId);
            console.log(response);
            if (response) {
                setInventoryMaster({

                    id: response.id,
                    inventory_master_name: response.inventory_master_name,
                    unit: response.unit,
                    reference_price: response.reference_price,
                    brand: response.brand,
                    model: response.model,
                    quantity: response.quantity,
                    inventory_master_description: response.inventory_master_description,
                    inventory_master_note: response.inventory_master_note,
                    inventory_unit_id: response.inventory_unit_id,
                    language_id: response.language_id,
                    ext_customer_id: response.ext_customer_id,
                    is_consumeable: response.is_consumeable,
                    lead_time: response.lead_time,
                    buffer_stock: response.buffer_stock,
                    inventory_brand_id: response.inventory_brand_id,
                    inventory_material_id: response.inventory_material_id,
                    inventory_master_alias: response.inventory_master_alias,
                    inventory_group_id: response.inventory_group_id,
                    inventory_subgroup_id: response.inventory_subgroup_id,
                    is_production_material: response.is_production_material,
                    unit_name: response.unit_name,
                    unit_code: response.unit_code,
                    inventory_group_name: response.inventory_group_name,
                    inventory_group_code: response.inventory_group_code,
                    inventory_subgroup_name: response.inventory_subgroup_name,
                    inventory_subgroup_code: response.inventory_subgroup_code,
                    inventory_material_name: response.inventory_material_name,
                    inventory_material_code: response.inventory_material_code,
                    brand_name: response.brand_name,
                    brand_code: response.brand_code,
                    part_number: response.part_number,
                    weight: response.weight,
                    parameters: response.parameters,

                });
              
            }


        } catch (exception) {
            console.log(exception);
        }
    }


    const loadGroup = async () => {
        try {
            let listGroup = await getInventoryGroup(cookies.token, cookies.languageId, 0, 1000000);
            setListInventoryGroup(listGroup);
            console.log(listInventoryGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadSubGroup = async () => {
        try {
            let listSubGroup = await getInventorySubGroupByGroupId(cookies.token, cookies.languageId, cookies.extCustomerId, selectedGroup.id, 0, 1000000);
            setListInventorySubGroup(listSubGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadMaterial = async () => {
        try {
            let listMaterial = await getInventoryMaterial(cookies.token, 0, 1000000);
            setListInventoryMaterial(listMaterial);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryBrand = async () => {

        try {
            let response = await getInventoryBrand(cookies.token);
            setListInventoryBrand(response);
            console.log(listInventoryBrand);
        } catch (exception) {

        }
    }

    const loadUnitType = async () => {
        try {
            let response = await getInventoryUnitType(cookies.token);
            setListUnitType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            // console.log(response);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setListInventoryPeriod(listInventoryPeriod);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setListInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const generateCode = async () => {
        try {
            let randomNumber = Math.floor(Math.random() * 100000000);
            let checkCode = await checkBarcodeNumber(cookies.token, randomNumber);
            if (checkCode !== 0) {
                return (generateCode());
            } else {
                return (randomNumber);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = async () => {
        try {
            let isError = false;
            console.log('VALIDATION FORM');
            if (selectedGroup) {
                if (selectedGroup.id === "" || selectedGroup.id === undefined || selectedGroup.id === null) {
                    setErrors({
                        ...errors,
                        message: `${t("detail_inventory_master.error_empty_field")}`
                    },
                        setDisabledButton(true));
                    isError = true;
                }
            }
            if (selectedSubGroup) {
                if (selectedSubGroup.id === "" || selectedSubGroup.id === undefined || selectedSubGroup.id === null) {
                    setErrors({
                        ...errors,
                        message: `${t("detail_inventory_master.error_empty_field")}`
                    },
                        setDisabledButton(true));
                    isError = true;
                }
            }
            if (selectedMaterial) {
                if (selectedMaterial.id === "" || selectedMaterial.id === undefined || selectedMaterial.id === null) {
                    setErrors({
                        ...errors,
                        message: `${t("detail_inventory_master.error_empty_field")}`
                    },
                        setDisabledButton(true));
                    isError = true;
                }
            }

            if (selectedBrand) {
                if (selectedBrand.id === "" || selectedBrand.id === undefined || selectedBrand.id === null) {
                    setErrors({
                        ...errors,
                        message: `${t("detail_inventory_master.error_empty_field")}`
                    },
                        setDisabledButton(true));
                    isError = true;
                }
            }

            if (isError) {
                setDisabledButton(true);
            } else {
                setDisabledButton(false);
            }

            /* else {
                let errorMsg = omit(errors, "message", "divisionError");
                setErrors(errorMsg);
                setDisabledButton(false);
            } */
            // console.log(inventoryMaster.inventory_group_id);
            // console.log(inventoryMaster.inventory_subgroup_id);
            // console.log(inventoryMaster.inventory_material_id);
        } catch (exception) {
            console.log(exception);
        }
    }


    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    return (
        <>
            <Navbar />
            <Container>
                <ContainerBox
                    titleCaption={`${t("detail_inventory_master.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { 
                                    // console.log(backButton);
                                    navigate("/InventoryMaster",{ state: {backButton: backButton} }); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            {
                                location.state.inventoryMasterId === 0 ? <></> :
                                    <Button variant="secondary" onClick={(e) => {
                                        navigate("/InventoryStock", { state: { inventoryMasterId: inventoryMaster.id, divisionCode: inventoryMaster.divisionCode } });
                                    }}>{t("detail_inventory_master.button_stock")}</Button>

                            }

                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setShowParameterModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_inventory_master.add_parameter")}</div>
                                </div>
                            </Button>

                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                navigate("/InventoryMaster/InventoryPhoto", { state: { inventoryId: location.state.inventoryMasterId, inventoryName: inventoryMaster.inventory_master_name } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><CameraFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_inventory_master.button_photo")}</div>
                                </div>
                            </Button>

                            <Link to={`/InventoryMaster/InventoryDocument?inventoryId=${location.state.inventoryMasterId}&inventoryName=${inventoryMaster.inventory_master_name}&backButton=${'/InventoryMaster/Detail'}`} target="_blank">
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                // navigate()
                                // navigate("/InventoryMaster/InventoryDocument", { state: { inventoryId: location.state.inventoryMasterId, inventoryName: inventoryMaster.inventory_master_name, backButton: '/InventoryMaster/Detail' } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FileCheckFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_inventory_master.button_document")}</div>
                                </div>
                            </Button>
                            </Link>
                        </div>


                    }
                    childContent={
                        <div>

                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                saveInventoryMaster();

                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_inventory_group")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.inventory_group_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventoryGroup} value={selectedGroup} onChange={(e) => {
                                            console.log(e);
                                            if (e === null) {
                                                setSelectedGroup({ id: "" });
                                            } else {
                                                setSelectedGroup(e);
                                                // setInventoryMaster({ ...inventoryMaster, group: e.inventory_group_name});
                                                // setInventoryMaster({ ...inventoryMaster, inventory_group_id: e.id, inventory_group_name: e.inventory_group_name });
                                                // console.log(inventoryMaster);
                                            }
                                        }}
                                        isClearable
                                        isDisabled={false}
                                    />

                                    {
                                        errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_inventory_subgroup")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.inventory_subgroup_name;
                                        }} 
                                        clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventorySubGroup} value={selectedSubGroup} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedSubGroup({ id: "" });
                                            } else {
                                                setSelectedSubGroup(e);
                                                // setInventoryMaster({ ...inventoryMaster, inventory_subgroup_id: e.id, inventory_subgroup_name: e.inventory_subgroup_name });
                                                // console.log(inventoryMaster);
                                            }
                                        }}
                                        isClearable
                                        isDisabled={false}
                                    />
                                    {
                                        errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_inventory_material")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.inventory_material_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventoryMaterial} value={selectedMaterial} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedMaterial({ id: "" });
                                            } else {
                                                setSelectedMaterial(e);
                                                //setInventoryMaster({ ...inventoryMaster, inventory_material_id: e.id, inventory_material_name: e.inventory_material_name });
                                                // console.log(inventoryMaster);
                                            }
                                        }}
                                        isClearable
                                        isDisabled={false}
                                    />
                                    {
                                        errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_brand")}</Form.Label>

                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.brand_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventoryBrand} value={selectedBrand} onChange={(e) => {
                                            if (e === null) {
                                                console.log(e);
                                                setSelectedBrand({})
                                            } else {
                                                setSelectedBrand(e);
                                                //setInventoryMaster({ ...inventoryMaster, brand: e.brand_name, inventory_master_name: e.brand_name + ' ' + inventoryMaster.model })
                                            }

                                        }}
                                        isClearable
                                        required
                                    />
                                    {/* <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_inventory_master.field_brand")}</Form.Label>
                                            <Form.Control onChange={(e) => {
                                                setInventoryMaster({ ...inventoryMaster, brand: e.target.value, inventory_master_name: e.target.value + ' ' + inventoryMaster.model })
                                            }} type="text" value={inventoryMaster.brand} required placeholder=""></Form.Control>
                                        </Form.Group> */}




                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_model")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setInventoryMaster({ ...inventoryMaster, model: e.target.value, inventory_master_name: inventoryMaster.brand + ' ' + e.target.value })
                                    }} type="text" value={inventoryMaster.model} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_inventory_name")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setInventoryMaster({ ...inventoryMaster, inventory_master_name: e.target.value })
                                    }} type="text" value={inventoryMaster.inventory_master_name} required placeholder="" disabled={true}></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_lead_time")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) || value === "") {
                                            setInventoryMaster({ ...inventoryMaster, lead_time: value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, lead_time: "" });
                                        }


                                    }} type="text" value={inventoryMaster.lead_time} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_buffer_stock")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) || value === "") {
                                            setInventoryMaster({ ...inventoryMaster, buffer_stock: value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, buffer_stock: "" });
                                        }

                                    }} type="text" value={inventoryMaster.buffer_stock} required placeholder="" ></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_weight")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        const value = e.target.value;

                                        if (/^[+-]?(?=.?\d)\d*(\.\d{0,9})?$/.test(value)) {
                                            setInventoryMaster({ ...inventoryMaster, weight: value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, weight: 0 });
                                        }

                                    }} type="text" value={String(inventoryMaster.weight || "")} required placeholder="" ></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{`${selectedGroup ? selectedGroup.inventory_group_code : ""}-${selectedBrand ? selectedBrand.brand_code : ""}-
                                                ${selectedSubGroup ? selectedSubGroup.inventory_subgroup_code : ""}-${selectedMaterial ? selectedMaterial.inventory_material_code : ""}`}</Form.Label>

                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_inventory_master.field_division")}</Form.Label>
                                            <Select placeholder={""}
                                                getOptionLabel={(item) => {
                                                    return item.division_code;
                                                }} clearValue={true}
                                                getOptionValue={(item) => {
                                                    return item.id;
                                                }}
                                                options={listDivision} value={listDivision.filter(function (option) {
                                                    return option.id === inventoryMaster.division_id;
                                                })} onChange={(e) => {
                                                    if (e === null) {
                                                        setInventoryMaster({ ...inventoryMaster, division_id: null })
                                                    } else {
                                                        setInventoryMaster({ ...inventoryMaster, division_id: e.id, division_code: e.division_code })
                                                    }
                                                }}
                                            // isClearable
        
                                            /> */}
                                {/* <Form.Select required onChange={(e) => {
                                                setInventoryMaster({ ...inventoryMaster, division_id: e.target.value })
                                            }} value={inventoryMaster.division_id} >
                                                <option selected value="">
                                                    {t("detail_inventory_master.choose_division")}
                                                </option>
                                                {listDivision.map((division, index) => (
                                                    <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                                ))}
                                            </Form.Select> */}
                                {/* </Form.Group> */}
                                {
                                    // errors.divisionError && <p style={{ color: "red" }}>{errors.divisionError}</p>
                                }

                                {/* <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_inventory_master.field_unit_type")}</Form.Label>
                                            <Form.Control onChange={(e) => {
                                                setInventoryMaster({ ...inventoryMaster, unit: e.target.value })
                                            }} type="text" value={inventoryMaster.unit} required placeholder=""></Form.Control>
                                        </Form.Group> */}
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_unit_type")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.unit_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listUnitType} value={selectedUnitType} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedUnitType({ id: "" });
                                                // setInventoryMaster({ ...inventoryMaster, inventory_unit_id: null })
                                            } else {
                                                setSelectedUnitType(e);
                                                // setInventoryMaster({ ...inventoryMaster, inventory_unit_id: e.id })
                                            }
                                        }}
                                        isClearable

                                    />
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                            <Form.Label>Harga Referensi</Form.Label>
                                            <Form.Control
                                                onKeyUp={(e) => {
                                                    setInventoryMaster({ ...inventoryMaster, reference_price: formatCurrency(e.target.value) })
                                                }}
                                                onChange={(e) => {
                                                    setInventoryMaster({ ...inventoryMaster, reference_price: e.target.value })
                                                }} type="text" value={inventoryMaster.reference_price} placeholder="" required></Form.Control>
                                        </Form.Group> */}


                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_part_number")}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={inventoryMaster.part_number}
                                        placeholder=""
                                        onChange={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, part_number: e.target.value })
                                        }}
                                        rows="1"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_description")}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={inventoryMaster.inventory_master_description}
                                        placeholder=""
                                        onChange={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, inventory_master_description: e.target.value })
                                        }}
                                        rows="4"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_note")}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={inventoryMaster.inventory_master_note}
                                        placeholder=""
                                        onChange={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, inventory_master_note: e.target.value })
                                        }}
                                        rows="4"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    {/* <Form.Label>Consumable Item</Form.Label> */}
                                    <Form.Check checked={isConsumeable} label={'Consumable Item'} onChange={(e) => {
                                        if (e.target.checked) {
                                            setIsConsumeable(true);
                                        }
                                        else {
                                            setIsConsumeable(false);
                                        }
                                    }}>
                                    </Form.Check>
                                </Form.Group>
                                <Form.Group>
                                    {/* <Form.Label>Consumable Item</Form.Label> */}
                                    <Form.Check checked={isProductionMaterial} label={'Production Material'} onChange={(e) => {
                                        if (e.target.checked) {
                                            setIsProductionMaterial(true);
                                        }
                                        else {
                                            setIsProductionMaterial(false);
                                        }
                                    }}>
                                    </Form.Check>
                                </Form.Group>
                                {/*
                                    inventoryMaster.is_consumeable &&
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        flexWrap: "nowrap"
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            paddingRight: 10
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group>
                                                    <Form.Label>{t("inventory_stock.field_purchase_date")}</Form.Label>
                                                    <Form.Control onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            setInventoryMaster({ ...inventoryMaster, purchase_date: null })
                                                        } else {
                                                            setInventoryMaster({ ...inventoryMaster, purchase_date: e.target.value })
                                                        }
                                                    }} type="date" value={inventoryMaster.purchase_date ? moment(inventoryMaster.purchase_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("inventory_stock.field_checking_item")}</Form.Label>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            paddingRight: 10
                                                        }}>
                                                            <Form.Control onChange={(e) => {
                                                                if (e.target.value >= 0) {
                                                                    setInventoryMaster({ ...inventoryMaster, period_number: e.target.value })
                                                                }

                                                            }} type="number" value={inventoryMaster.period_number} required placeholder=""></Form.Control>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            paddingLeft: 10
                                                        }}>
                                                            <Form.Select required onChange={(e) => {
                                                                setInventoryMaster({ ...inventoryMaster, inventory_period_id: e.target.value })
                                                            }} value={inventoryMaster.inventory_period_id} >
                                                                <option selected value="">

                                                                </option>
                                                                {listInventoryPeriod.map((period, index) => (
                                                                    <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </div>




                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group>
                                                    <Form.Label>{t("inventory_stock.field_purchase_price")}</Form.Label>
                                                    <Form.Control
                                                        onKeyUp={(e) => {

                                                            setInventoryMaster({ ...inventoryMaster, purchase_price: formatCurrency(e.target.value) })
                                                        }}

                                                        onChange={(e) => {
                                                            setInventoryMaster({ ...inventoryMaster, purchase_price: e.target.value })
                                                        }} type="text" value={inventoryMaster.purchase_price} required placeholder=""></Form.Control>
                                                </Form.Group>
                                            </div>



                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            paddingLeft: 10
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Total Item</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => {
                                                        if (e.target.value >= 0) {
                                                            setInventoryMaster({ ...inventoryMaster, stock: e.target.value })
                                                        }
                                                    }} value={inventoryMaster.stock} required></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label> {t("inventory_stock.field_item_status")}</Form.Label>
                                                    <Form.Select required onChange={(e) => {
                                                        setInventoryMaster({ ...inventoryMaster, inventory_status_id: e.target.value })
                                                    }} value={inventoryMaster.inventory_status_id} >
                                                        <option selected value="">

                                                        </option>
                                                        {listInventoryStatus.map((status, index) => (
                                                            <option key={index} value={status.id}>{status.inventory_status_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("inventory_stock.field_item_condition")}</Form.Label>
                                                    <Form.Select required onChange={(e) => {
                                                        setInventoryMaster({ ...inventoryMaster, inventory_condition_id: e.target.value })
                                                    }} value={inventoryMaster.inventory_condition_id} >
                                                        <option selected value="">

                                                        </option>
                                                        {listInventoryCondition.map((condition, index) => (
                                                            <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>




                                        </div>


                                    </div>

                                */}
                                <div style={{ paddingBottom: 20 }}></div>
                                {
                                    cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                        <></>
                                        :
                                        <div className=""
                                            style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                            <Button style={{ width: 100 }} className="save-button" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("detail_inventory_master.save_button")}</Button>
                                            <Button style={{ width: 100 }} className="cancel cancel-button" variant="danger" onClick={() => {
                                                navigate("/InventoryMaster");
                                            }}>{t("detail_inventory_master.cancel_button")}</Button>
                                            {/* {
                                                location.state.inventoryMasterId === 0 ? <></>
                                                    : <Button className="cancel save-button" onClick={() => {
                                                        navigate("/InventoryStock", { state: { inventoryMasterId: inventoryMaster.id, departmentCode: inventoryMaster.departementCode } });
                                                    }}>Stock</Button>
                                            } */}

                                        </div>

                                }

                            </Form>





                        </div>} />
            </Container>

            <Modal show={showParameterModal}
                // dialogClassName="modal-size"
                size={"lg"}
                onHide={() => {
                    setShowParameterModal(false);
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("detail_inventory_master.add_parameter")}</h3>
                    </div>
                    <div style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        padding: 5
                    }}>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            padding: 5
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                flex: 3
                            }}>{t("detail_inventory_master.table_name1")}</div>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                flex: 2
                            }}>{t("detail_inventory_master.table_name2")}</div>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                flex: 2
                            }}>{t("detail_inventory_master.table_name3")}</div>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                flex: 1
                            }}>{t("detail_inventory_master.table_name4")}</div>
                        </div>

                        {inventoryMaster.parameters && inventoryMaster.parameters.map((param, index) => {
                            return (
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>
                                        <Form.Control onChange={(e) => {
                                            let parameters = inventoryMaster.parameters;
                                            parameters[index].parameter_name = e.target.value;
                                            setInventoryMaster({ ...inventoryMaster, parameters: parameters });
                                        }} type="text" value={param.parameter_name ? param.parameter_name : ""} required placeholder=""></Form.Control>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 2,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>
                                        <Form.Control onChange={(e) => {
                                            let parameters = inventoryMaster.parameters;
                                            parameters[index].parameter_value = e.target.value;
                                            setInventoryMaster({ ...inventoryMaster, parameters: parameters });
                                        }} type="text" value={param.parameter_value ? param.parameter_value : ""} required placeholder=""></Form.Control>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 2,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>
                                        <Form.Control onChange={(e) => {
                                            let parameters = inventoryMaster.parameters;
                                            parameters[index].parameter_description = e.target.value;
                                            setInventoryMaster({ ...inventoryMaster, parameters: parameters });
                                        }} type="text" value={param.parameter_description ? param.parameter_description : ""} required placeholder=""></Form.Control>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 1,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>
                                        <Button onClick={() => {
                                            if (window.confirm(`${t("detail_inventory_master.delete_confirmation")}?`)) {
                                                let list = [...inventoryMaster.parameters];
                                                list.splice(index, 1);
                                                setInventoryMaster({ ...inventoryMaster, parameters: list });
                                            }
                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                    </div>

                                </div>
                            )
                        })}


                    </div>

                    <div style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        padding: 5
                    }}>
                        <Button variant="primary" onClick={() => {
                            let newParameter = {
                                id: "",
                                parameter_name: "",
                                parameter_value: "",
                                parameter_description: "",
                            };
                            if (!inventoryMaster.parameters) {
                                let parameters = [];
                                parameters.push(newParameter);
                                setInventoryMaster({ ...inventoryMaster, parameters: parameters });
                            }
                            else {
                                let parameters = inventoryMaster.parameters;
                                parameters.push(newParameter);
                                setInventoryMaster({ ...inventoryMaster, parameters: parameters });
                            }
                            console.log(inventoryMaster);
                        }}>{t("detail_inventory_master.button_add_parameter")}</Button>
                    </div>

                </div>

            </Modal>

        </>
    )
}