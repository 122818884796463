import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getSparepartCatalog, getCountSparepartCatalogStock, deleteSparepartCatalog } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search, BoxArrowUpRight } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function SparepartCatalogStockIndexPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [listSparepartCatalog, setListSparepartCatalog] = useState([]);
    const [sparepartCatalogCount, setSparepartCatalogCount] = useState(0);
    const [totalStock, setTotalStock] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [listSparepartCatalogAll, setListSparepartCatalogAll] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [parameterName, setParameterName] = useState("");
    const [parameterValue, setParameterValue] = useState("");
    const [searchByParameter, setSearchByParameter] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_sparepart_stock.order_name1")}`,
        value: "equipment.equipment_name"
    });
    const orderByList = [{
        label: `${t("index_sparepart_stock.order_name1")}`,
        value: "equipment.equipment_name"
    }
    ]

    const [descending, setDescending] = useState(false);
    const [removeId, setRemoveId] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadSparepartCatalog();
                setLoading(false);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadSparepartCatalog();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadSparepartCatalog();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadSparepartCatalog();
    }, [orderBy]);

    useEffect(() => {
        if (removeId !== "")
            deleteData();
    }, [removeId]);

    useEffect(() => {

        loadSparepartCatalog();
    }, [totalStock])

    useEffect(() => {
        if (resetSearch !== 0)
            loadSparepartCatalog();
    }, [resetSearch]);

    useEffect(() => {
        // if(searchByParameter === 1)
        loadSparepartCatalog();
    }, [searchByParameter])

    useEffect(() => {
        if (isSearched !== 0)
            loadSparepartCatalog();
    }, [descending]);



    const loadSparepartCatalog = async () => {
        try {
            let countSparepart = await getCountSparepartCatalogStock(cookies.token, cookies.extCustomerId, searchQuery, false, totalStock, searchByParameter, parameterName, parameterValue);
            // if (totalStock === 0) {
            //     let count = countSparepart.filter(p => p.quantity >= 0);
            //     totalPage = count.length / itemPerPage;
            //     setTotalPage(totalPage);
            // } else {
            //     let count = countSparepart.filter(p => p.quantity > 0);
            //     totalPage = count.length / itemPerPage;
            //     setTotalPage(totalPage);
            // }

            let totalPage = countSparepart / itemPerPage;
            setTotalPage(totalPage);

            let response = await getSparepartCatalog(cookies.token, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, totalStock, searchByParameter, parameterName, parameterValue,);
            // if(totalStock === ">=0"){
            //     let data = response.filter(p=>p.quantity >= 0 );
            //     setListSparepartCatalog(data);
            // }else{
            //     let data = countSparepart.filter(p=>p.quantity > 0 );
            //     setListSparepartCatalog(data);
            // }
            setListSparepartCatalog(response);


            let all = await getSparepartCatalog(cookies.token, cookies.extCustomerId);
            setListSparepartCatalogAll(all);
            setResetSearch(0);
            setLoading(false);
            setIsSearched(0);
            setOrderBy({
                label: `${t("index_sparepart_stock.order_name1")}`,
                value: "equipment.equipment_name"
            })
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const deleteData = async () => {
        try {
            let response = await deleteSparepartCatalog(cookies.token, removeId);
            if (response === 0) {
                loadSparepartCatalog();
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_sparepart_stock.header_title")}`}
                    // useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => { navigate("/SparepartCatalog/Detail", { state: { sparepartCatalogId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Katalog</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">

                            <div className="master-table-searchbar-container">
                                <div style={{
                                    display: "flex",
                                    padding: 10
                                }}>
                                    <Form.Check label={`${t("index_sparepart_stock.check_box_total_stock")}`} onClick={(e) => {
                                        setLoading(true);
                                        if (e.target.checked) {
                                            setTotalStock(1)
                                            // let stock = listSparepartCatalog.filter(p=>p.quantity>0);
                                            // let total = stock.length / itemPerPage;
                                            // setTotalPage(total);
                                            // setListSparepartCatalog(stock);

                                            // console.log('true');
                                        } else {
                                            //  loadSparepartCatalog();
                                            setTotalStock(0)
                                        }
                                    }} defaultChecked={false}
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    padding: 10
                                }}>
                                    <Form.Check label={`${t("index_sparepart_stock.check_box_search")}`} onClick={(e) => {
                                        if (e.target.checked) {
                                            setSearchByParameter(1);
                                            setSearchQuery("");
                                        } else {
                                            setSearchByParameter(0);
                                        }
                                    }} defaultChecked={false}
                                    />
                                </div>

                                <div className="master-table-searchbar-textbox">
                                    {
                                        searchByParameter === 1 ?
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap"
                                            }}>
                                                <div style={{ flex: 1, paddingRight: 5 }}>
                                                    <Form.Control value={parameterName} type="text" placeholder={`${t("index_sparepart_stock.parameter_name")}`}
                                                        onKeyPress={async (e) => {
                                                            if (e.charCode === 13) {
                                                                setLoading(true);
                                                                await loadSparepartCatalog();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setParameterName(e.target.value);
                                                        }} />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <Form.Control value={parameterValue} type="text" placeholder={`${t("index_sparepart_stock.parameter_value")}`}
                                                        onKeyPress={async (e) => {
                                                            if (e.charCode === 13) {
                                                                setLoading(true);
                                                                await loadSparepartCatalog();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setParameterValue(e.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                            :

                                            <Form.Control value={searchQuery} type="text" placeholder={`${t("index_sparepart_stock.search_box")}`}
                                                onKeyPress={async (e) => {
                                                    if (e.charCode === 13) {
                                                        setPage(0);
                                                        setLoading(true);
                                                        await loadSparepartCatalog();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchQuery(e.target.value);
                                                }} />
                                    }

                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadSparepartCatalog();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setParameterName("");
                                            setParameterValue("");
                                            // await loadSparepartCatalog();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_sparepart_stock.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_sparepart_stock.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_sparepart_stock.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_sparepart_stock.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_sparepart_stock.table_name5")}</div>
                                    {/* <div style={{ flex: 1 }} className="table-header-content">Hapus</div> */}
                                </div>
                                {
                                    listSparepartCatalog.map((sparepartCatalog, index) => {
                                        return (<div className="table-body" key={index}>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{sparepartCatalog.manufacturer_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p> {sparepartCatalog.part_number}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{sparepartCatalog.model}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{sparepartCatalog.quantity}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Link to={`/SparepartCatalog/Stock?id=${sparepartCatalog.id}`} target="_blank">
                                                        <Button onClick={() => {
                                                            // navigate("/SparepartCatalog/Detail", { state: { sparepartCatalogId: sparepartCatalog.id } })
                                                        }}> <BoxArrowUpRight /></Button>
                                                    </Link>

                                                </div></div>

                                            {/* <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button className="cancel" onClick={() => {
                                                        if (window.confirm('Apakah Anda Ingin Menghapus Data Ini?')) {
                                                            setRemoveId(sparepartCatalog.id);
                                                        }
                                                    }} variant="danger"><Trash /></Button>
                                                </div></div> */}
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>


        </>
    );

}