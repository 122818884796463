import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useState, useEffect } from "react";
import moment from "moment";

export default function DynamicLineChartForFuelConsumptionDoubleAxis({dataAvg, dataCurrent, dataEngineLoad, minValue, maxValue, stepSize, minValueEngineLoad, maxValueEngineLoad, stepSizeEngineLoad}) {
  const [dataToShowAvg, setDataToShowAvg] = useState([]);
  const [dataToShowCurrent, setDataToShowCurrent] = useState([]);
  const [dataToShowEngineLoad, setDataToShowEngineLoad] = useState([]);

  useEffect(() => {
    setDataToShowAvg(dataAvg);
  }, [dataAvg]);

  useEffect(() => {
    setDataToShowCurrent(dataCurrent);
  }, [dataCurrent]);

  useEffect(() => {
    setDataToShowEngineLoad(dataEngineLoad);
  }, [dataEngineLoad]);

  // const minValue = 0;
  // const maxValue = 100;
  // const stepSize = 25;

  const xAxisValues = dataToShowCurrent ? dataToShowCurrent.map(data => moment(data.received_date).format("MM-DD HH:mm:ss")) : [];
  const yAxisValuesCurrent = dataToShowCurrent ? dataToShowCurrent.map(data => Number(data.reading_value)) : [];
  const yAxisValuesEngineLoad = dataToShowEngineLoad ? dataToShowEngineLoad.map(data => Number(data.reading_value)) : [];
  const avgCurr = dataToShowCurrent.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / dataToShowCurrent.length;
  const avgEL = dataToShowEngineLoad.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / dataToShowEngineLoad.length;

  const data = {
    labels: xAxisValues,
    datasets: [
      {
        label: 'Current Fuel Consumption (L/H)',
        data: yAxisValuesCurrent,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#ff6384',
        borderWidth: 2,
        yAxisID: 'y1', 
      },
      {
        label: 'Engine Load (KW)',
        data: yAxisValuesEngineLoad,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#16a34a',
        borderWidth: 2,
        yAxisID: 'y2', 
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#031e67'
        }
      },
      y1: {
        min: minValue,
        max: maxValue, 
        ticks: {
          stepSize: stepSize,
          color: '#ff6384'
        },
        position: 'left'
      },
      y2: {
        min: minValueEngineLoad,
        max: maxValueEngineLoad, 
        ticks: {
          stepSize: stepSizeEngineLoad,
          color: '#16a34a'
        },
        position: 'right'
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    animation: false
  };

  return (
    <>
    {
      avgCurr <= 0 ? (<p style={{margin: '0px'}}>Curret Fuel Consumption Data Not Available yet</p>):(<></>)
    }
    {
      avgEL <= 0 ? (<p style={{margin: '0px'}}>Engine Load Data Not Available yet</p>):(<></>)
    }
    <Line
      data={data}
      options={options}
    />
    </>
  );
}