import { Container, Nav, Navbar } from "react-bootstrap";
// import logo from "../../Assets/Images/rpi-logo.svg";
import logo from "../../Assets/Images/logo-qpd (2).png";
import Clock from "react-live-clock";
import NavButton from "../../Components/NavButton/NavButton";
import "./TopNavBar.css";
import { useNavigate } from "react-router-dom";

export default function TopNavBar() {
    const navigate = useNavigate();
    const navigateFAWheelHouse = () => { navigate("/FireAlarm/WheelHouse") };
    const navigateNavSignalLightPanel = () => { navigate("/NavSignalLightPanel") };
    const navigateOutsideLighting = () => { navigate("/OutsideLighting") };
    const navigateVesselPosition = () => { navigate("/VesselPosition") };

    return (
        <>
            <Navbar className="paddingNavBar">
                <Container>
                    <Navbar.Brand>
                        <div className="logoTopNav">
                            <img  src={logo} alt={"logo Oceania"} />
                        </div>
                    </Navbar.Brand>
                    <div className="dashboard-main-header" >
                        <Clock
                            format={"DD/MM/YYYY ddd, hh:mm:ss"}
                            ticking={true}
                            timezone={"Asia/Jakarta"}
                        />
                    </div>
                    <Nav>
                        {/* <Nav.Link><NavButton text1={"COMMON"} text2={"LINK"}></NavButton></Nav.Link> */}
                        <Nav.Link><NavButton text1={"FIRE"} text2={"ALARM"} handleOnClick={navigateFAWheelHouse}></NavButton></Nav.Link>
                        <Nav.Link><NavButton text1={"NAV & SIGNAL"} text2={"LIGHT PANEL"} handleOnClick={navigateNavSignalLightPanel}></NavButton></Nav.Link>
                        {/* <Nav.Link><NavButton text1={"OUTSIDE"} text2={"LIGHTING"} handleOnClick={navigateOutsideLighting}></NavButton></Nav.Link> */}
                        <Nav.Link><NavButton text1={"VESSEL"} text2={"POSITION"} handleOnClick={navigateVesselPosition}></NavButton></Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}