import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVessel, getVesselInventoryMaster, getStaff, insertUpdateVesselInventoryRequisition, getVesselInventoryRequisitionById,
    getRequisitionStatus, getUserAccessById, getCrewOnVesselToday
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";


export default function VesselInventoryRequisitionDetailPage() {
    const [vesselOptions, setVesselOptions] = useState([]);
    const { t, i18n } = useTranslation();
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [disabledAddButton, setDisableButtonAdd] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [listCrewVessel, setListCrewVessel] = useState([]);
    const dateNow = new Date();
    const [staffOptions, setStaffOptions] = useState([]);
    const [selectedCrewOnVessel, setSelectedCrewVessel] = useState({});
    const [selectedStaff, setSelectedStaff] = useState({})
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [approvalButton, setApprovalButton] = useState("");
    const [inventoryRequisitionId, setInventoryRequisitionId] = useState("");
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [actionButton, setActionButton] = useState("");
    const [staffId, setStaffId] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [selectedProjectType, setSelectedProjectType] = useState("");
    const [vesselInventoryRequisition, setVesselInventoryRequisition] = useState({
        id: 0,
        vessel_id: null,
        staff_id: null,
        request_date: null,
        status_id: null,
        approved_date: null,
        list_requisition: [],
        approved_by: null,
    })



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (searchParams.get("id") !== null) {
                    setInventoryRequisitionId(searchParams.get("id"))
                } else {
                    setInventoryRequisitionId(location.state.vesselInventoryRequisitionId);
                }
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadVessel();

                loadAccess();
                loadStatus();
                setStaffId(cookies.staffId);

            }
        }
        checkCookies();

        // if (location.state.vesselInventoryRequisitionId === 0) {
        //     setLoading(false);

        // } else {
        //     initVesselInventoryRequisition();
        //     loadStaff();
        // }
    }, []);

    useEffect(() => {
        if (inventoryRequisitionId !== 0 && inventoryRequisitionId !== "") {
            initVesselInventoryRequisition();
            loadStaff();
        } else {
            setLoading(false);
        }
    }, [inventoryRequisitionId])

    useEffect(() => {
        if (listCrewVessel.length > 0) {
            if (vesselInventoryRequisition.id !== 0 && vesselInventoryRequisition.id !== undefined) {
                if (vesselInventoryRequisition.staff_id) {
                    let crewName = listCrewVessel.find(p => p.id === vesselInventoryRequisition.staff_id);
                    if (crewName) {
                        setSelectedCrewVessel(crewName);
                    }
                }
                if (vesselInventoryRequisition.approved_by) {
                    let staffName = staffOptions.find(p => p.id === vesselInventoryRequisition.approved_by);
                    if (staffName) {
                        setSelectedStaff(staffName);
                    }
                }
            }
        }
    }, [listCrewVessel])
    useEffect(() => {
        if (staffOptions.length > 0) {
            if (vesselInventoryRequisition.id !== 0 && vesselInventoryRequisition.id !== undefined) {
                if (vesselInventoryRequisition.approved_by) {
                    let staffName = staffOptions.find(p => p.id === vesselInventoryRequisition.approved_by);
                    if (staffName) {
                        setSelectedStaff(staffName);
                    }
                }
            }
        }
    }, [staffOptions])


    useEffect(() => {
        if (vesselInventoryRequisition.id !== 0) {
            if (vesselInventoryRequisition.staff_id) {
                let crewName = listCrewVessel.find(p => p.id === vesselInventoryRequisition.staff_id);
                if (crewName) {
                    setSelectedCrewVessel(crewName);
                }
            }
            if (vesselInventoryRequisition.approved_by) {
                let staffName = staffOptions.find(p => p.id === vesselInventoryRequisition.approved_by);
                if (staffName) {
                    setSelectedStaff(staffName);
                }
            }
        }

        if (vesselInventoryRequisition.vessel_id === null || vesselInventoryRequisition.staff_id === null) {
            setErrors({
                ...errors,
                message: `${t("detail_vessel_inventory_requisition.error_empty_field")}`
            },
                setDisabledButton(true));
        }
        // else if (vesselInventoryRequisition.list_requisition.length === 0) {
        //     setErrors({
        //         ...errors,
        //         listRequisitionError: `${t("detail_vessel_inventory_requisition.error_list_item")}`
        //     },
        //         setDisabledButton(true))
        // }

        else if (vesselInventoryRequisition.approved_date !== null && vesselInventoryRequisition.approved_by === null) {
            setErrors({
                ...errors,
                givingStaffError: `${t("detail_vessel_inventory_requisition.error_empty_field")}`
            },
                setDisabledButton(true))
        }
        else if (vesselInventoryRequisition.list_requisition.length === 0) {
            setErrors({
                ...errors,
                listRequisitionError: `${t("detail_vessel_inventory_requisition.error_list_item")}`
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "message", "dateMessageApproved", "givingStaffError", "listRequisitionError");
            setErrors(errorMsg);
            validationForm();


        }

    }, [vesselInventoryRequisition]);

    useEffect(() => {
        if (selectedCrewOnVessel.staff_name) {
            setVesselInventoryRequisition({ ...vesselInventoryRequisition, staff_id: selectedCrewOnVessel.id })
        }
    }, [selectedCrewOnVessel]);

    useEffect(() => {
        if (selectedStaff.staff_name) {
            setVesselInventoryRequisition({ ...vesselInventoryRequisition, approved_by: selectedStaff.id })
        }
    }, [selectedStaff]);

    useEffect(() => {
        if (vesselInventoryRequisition.vessel_id !== null || vesselInventoryRequisition.vessel_id !== "") {
            loadVesselInventory();
            loadCrewVessel();
        }
    }, [vesselInventoryRequisition.vessel_id])


    useEffect(() => {
        if (actionButton !== "")
            saveData();
    }, [actionButton])

    useEffect(() => {
        if (inventoryRequisitionId === 0) {
            let today = new Date();
            let date = moment(today).format("yyyy-MM-DD")
            setVesselInventoryRequisition({ ...vesselInventoryRequisition, request_date: date });
        }

    }, [listStatusRequisition])



    const loadVessel = async () => {
        try {
            let response = await getVessel(cookies.token, cookies.languageId, cookies.extCustomerId);

            let listVessel = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id

                };

                listVessel.push(obj);

            }
            setVesselOptions(listVessel);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadVesselInventory = async () => {
        try {
            let response = await getVesselInventoryMaster(cookies.token,);
            let listInventory = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_inventory_name,
                    id: response[i].id
                };

                listInventory.push(obj);

            }
            setInventoryOptions(listInventory);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, true, undefined, undefined, 2);
            // let listStaff = [];
            // for (let i = 0; i < response.length; i++) {
            //     var obj = {
            //         value: response[i].id,
            //         label: response[i].staff_name,
            //         id: response[i].id,
            //         resign_date: response[i].resign_date

            //     };

            //     listStaff.push(obj);

            // }
            setStaffOptions(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewVessel = async () => {
        try {
            let response = await getCrewOnVesselToday(cookies.token, vesselInventoryRequisition.vessel_id);
            // let response = await getStaff(cookies.token, cookies.languageId, "null");
            // let listStaff = [];
            // for (let i = 0; i < response.length; i++) {
            //     var obj = {
            //         value: response[i].id,
            //         label: response[i].staff_name,
            //         id: response[i].id,
            //     };
            //     listStaff.push(obj);

            // }
            setListCrewVessel(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            // let sparepartRequisitionTmp = vesselInventoryRequisition;
            // sparepartRequisitionTmp.ext_customer_id = sparepartRequisitionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : vesselInventoryRequisition.ext_customer_id
            let response = await insertUpdateVesselInventoryRequisition(cookies.token, vesselInventoryRequisition);
            if (response.error_code === 0) {
                alert(`${t("detail_vessel_inventory_requisition.alert_success_save_data")}`);
                navigate('/VesselInventoryRequisition');

            } else {
                alert(`${t("detail_vessel_inventory_requisition.alert_failed_save_data")}`);
            }

            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initVesselInventoryRequisition = async () => {
        try {
            let response = await getVesselInventoryRequisitionById(cookies.token, inventoryRequisitionId);
            if (response) {
                setVesselInventoryRequisition({
                    ...vesselInventoryRequisition,
                    id: response[0].id,
                    vessel_id: response[0].vessel_id,
                    staff_id: response[0].staff_id,
                    request_date: response[0].request_date,
                    status_id: response[0].status_id,
                    approved_date: response[0].approved_date,
                    list_requisition: response[0].list_requisition,
                    approved_by: response[0].approved_by,
                    requested_name: response[0].requested_name,
                    approved_name: response[0].approved_name,
                    vessel_inventory_name: response[0].vessel_inventory_name
                })
            }

            let status = await getRequisitionStatus(cookies.token);
            if (status) {
                let statusCode = status.find(p => p.id === response[0].status_id)
                if (statusCode.status_code === "APPROVED" || statusCode.status_code === "REJECTED") {
                    setDisabledForm(true);
                }
            }

            // setSelectedProjectType(`${response[0].is_project}`);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
            console.log(inventoryRequisitionId);
            if (inventoryRequisitionId === 0 || inventoryRequisitionId === "") {
                let status = await response.find(p => p.status_code === "REQ")
                setVesselInventoryRequisition({ ...vesselInventoryRequisition, status_id: status.id })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (vesselInventoryRequisition.list_requisition.length > 0) {
                    for (let index = 0; index < vesselInventoryRequisition.list_requisition.length; index++) {
                        const list = vesselInventoryRequisition.list_requisition[index];
                        if (list.vessel_inventory_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage(`${t("detail_vessel_inventory_requisition.error_list_inventory")}`);
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");
                            setDisableButtonAdd(false);
                            setDisabledButton(false);
                        }
                    }
                } else {
                    setErrorMessage("")
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }


    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
                setApprovalButton(response.approval);
            }
            setDeletedButton(true);
            setUpdatedButton(true)
            setApprovalButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("detail_vessel_inventory_requisition.print_title")}`;
        WinPrint.print();
        // WinPrint.close();
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_vessel_inventory_requisition.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/VesselInventoryRequisition"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel_inventory_requisition.header_vessel_inventory_requisition")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>

                                                                <FormSelect caption={`${t("detail_vessel_inventory_requisition.field_vessel_name")}`} placeholder=""
                                                                    options={vesselOptions} value={vesselOptions.filter(function (option) {
                                                                        return option.id === vesselInventoryRequisition.vessel_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setVesselInventoryRequisition({ ...vesselInventoryRequisition, vessel_id: e.id });
                                                                        } else {
                                                                            setVesselInventoryRequisition({ ...vesselInventoryRequisition, vessel_id: null });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }


                                                                <FormSelect caption={`${t("detail_vessel_inventory_requisition.field_crew_requested")}`} placeholder=""
                                                                    getOptionLabel={(item) => {
                                                                        return item.staff_name
                                                                    }}
                                                                    getOptionValue={(item) => {
                                                                        return item.id
                                                                    }}
                                                                    options={listCrewVessel.filter(p => moment(dateNow).format("yyyy-MM-DD") >= moment(p.start_date).format("yyyy-MM-DD") && moment(dateNow).format("yyyy-MM-DD") <= moment(p.end_date).format("yyyy-MM-DD"))} value={selectedCrewOnVessel} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSelectedCrewVessel(e);
                                                                        } else {
                                                                            setSelectedCrewVessel({});
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }
                                                                <DropDownForm value={vesselInventoryRequisition.status_id} caption={`${t("detail_vessel_inventory_requisition.field_request_status")}`} placeholder=""
                                                                    setValueFunction={(e) => {
                                                                        // setVesselInventoryRequisition({ ...vesselInventoryRequisition, status_id: e.target.value });
                                                                        let status = listStatusRequisition.find(p => p.id === e.target.value);
                                                                        if (status.status_code === "APPROVED") {
                                                                            var today = new Date(),
                                                                                // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                                date = moment(today).format("yyyy-MM-DD")
                                                                            setVesselInventoryRequisition({ ...vesselInventoryRequisition, status_id: e.target.value, approved_date: date });
                                                                        } else {
                                                                            setVesselInventoryRequisition({ ...vesselInventoryRequisition, status_id: e.target.value, approved_date: null, approved_by: null });

                                                                        }
                                                                    }} listOption={listStatusRequisition} valueKey="id" labelKey="status_name" disabledForm={true} />

                                                                <FormSelect caption={`${t("detail_vessel_inventory_requisition.field_approved_by")}`} placeholder=""
                                                                    options={staffOptions}
                                                                    getOptionLabel={(item) => {
                                                                        return item.staff_name
                                                                    }}
                                                                    getOptionValue={(item) => {
                                                                        return item.id
                                                                    }}
                                                                    value={selectedStaff} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSelectedStaff(e);
                                                                        } else {
                                                                            setSelectedStaff({});
                                                                        }

                                                                    }} disabledForm={vesselInventoryRequisition.approved_date ? disabledForm : true}

                                                                />
                                                                {
                                                                    errors.givingStaffError && <p style={{ color: "red" }}>{errors.givingStaffError}</p>
                                                                }
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>



                                                                <TextBox value={vesselInventoryRequisition.request_date ? moment(vesselInventoryRequisition.request_date).format("yyyy-MM-DD") : ""} placeholder="" controlType="date"
                                                                    caption={`${t("detail_vessel_inventory_requisition.field_request_date")}`} setValueFunction={(e) => {
                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, request_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <TextBox value={vesselInventoryRequisition.approved_date ? moment(vesselInventoryRequisition.approved_date).format("yyyy-MM-DD") : ""} placeholder="" controlType="date"
                                                                    caption={`${t("detail_vessel_inventory_requisition.field_approved_date")}`} setValueFunction={(e) => {
                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, approved_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                                {
                                                                    errors.dateMessageApproved && <p style={{ color: "red" }}>{errors.dateMessageApproved}</p>
                                                                }


                                                            </div>

                                                        </div>
                                                        {
                                                            approvalButton && inventoryRequisitionId !== 0 &&

                                                            <div hidden={staffId === "null"} style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                padding: 10,
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                fontWeight: "bold"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Button style={{width:100}} onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "APPROVED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Approve");
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="success">{t("detail_vessel_inventory_requisition.approved_button")}</Button>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Button style={{width:100}} onClick={() => {
                                                                        let status = listStatusRequisition.find(p => p.status_code === "REJECTED");
                                                                        let today = new Date(),
                                                                            // date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                                                                            date = moment(today).format("yyyy-MM-DD")
                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, status_id: status.id, approved_by: staffId, approved_date: date });
                                                                        setActionButton("Reject")
                                                                    }} disabled={staffId !== "null" ? disabledForm : true} className="cancel-button" variant="danger">{t("detail_vessel_inventory_requisition.rejected_button")}</Button>
                                                                </div>


                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {
                                        inventoryRequisitionId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >{t("detail_vessel_inventory_requisition.button_print")} <Printer /></Button>
                                            :
                                            <></>
                                    }
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel_inventory_requisition.header_inventory_list")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>


                                                    {
                                                        vesselInventoryRequisition.list_requisition && vesselInventoryRequisition.list_requisition.map((list, index) => {
                                                            return (
                                                                <div style={{ padding: 5 }}>
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5,
                                                                        borderWidth: 2,
                                                                        borderRadius: 5,
                                                                        borderStyle: "solid",
                                                                        borderColor: "rgba(3, 30, 103, 1)"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 20,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Select placeholder={`${t("detail_vessel_inventory_requisition.field_inventory")}`}
                                                                                    options={inventoryOptions.filter((p) => {
                                                                                        return !vesselInventoryRequisition.list_requisition.find((x) => {
                                                                                            return p.id === x.vessel_inventory_id
                                                                                        })
                                                                                    })} value={inventoryOptions.filter(function (option) {
                                                                                        return option.id === list.vessel_inventory_id;
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        let listRequisition = vesselInventoryRequisition.list_requisition;
                                                                                        listRequisition[index].vessel_inventory_id = e.id;
                                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, list_requisition: listRequisition });
                                                                                    }}
                                                                                    isDisabled={disabledForm}
                                                                                />

                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                // width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 6,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                    if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                                    } else {
                                                                                        let listRequisition = vesselInventoryRequisition.list_requisition;
                                                                                        listRequisition[index].quantity = e.target.value;
                                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, list_requisition: listRequisition });
                                                                                    }

                                                                                }} type="text" style={{ textAlign: "right" }} value={list.quantity} required placeholder={`${t("detail_vessel_inventory_requisition.field_total")}`} disabled={disabledForm} ></Form.Control>


                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 1,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Button onClick={() => {
                                                                                    if (window.confirm(`${t("detail_vessel_inventory_requisition.delete_confirmation")}?`)) {
                                                                                        let list = [...vesselInventoryRequisition.list_requisition];
                                                                                        list.splice(index, 1);
                                                                                        setVesselInventoryRequisition({ ...vesselInventoryRequisition, list_requisition: list });
                                                                                    }

                                                                                }} style={{ maxWidth: 50 }} variant="danger" disabled={disabledForm === true ? disabledForm : !deletedButton}><Trash /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            paddingTop: 10,
                                                                            paddingBottom: 10

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Form.Control onChange={(e) => {
                                                                                    let listRequisition = vesselInventoryRequisition.list_requisition;
                                                                                    listRequisition[index].note = e.target.value;
                                                                                    setVesselInventoryRequisition({ ...vesselInventoryRequisition, list_requisition: listRequisition });
                                                                                }} as="textarea" rows={3} value={list.note} placeholder={`${t("detail_vessel_inventory_requisition.field_description")}`} disabled={disabledForm}></Form.Control>


                                                                            </div>

                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            );
                                                        })
                                                    }

                                                    {
                                                        errors.listRequisitionError && <p style={{ color: "red" }}>{errors.listRequisitionError}</p>
                                                    }
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                vessel_inventory_id: "",
                                                                quantity: null,
                                                                note: ""
                                                            };
                                                            if (!vesselInventoryRequisition.list_requisition) {
                                                                let listRequisition = [];
                                                                listRequisition.push(newParameter);
                                                                setVesselInventoryRequisition({ ...vesselInventoryRequisition, list_requisition: listRequisition });
                                                            } else {
                                                                let listRequisition = vesselInventoryRequisition.list_requisition;
                                                                listRequisition.push(newParameter);
                                                                setVesselInventoryRequisition({ ...vesselInventoryRequisition, list_requisition: listRequisition });
                                                            }

                                                        }} disabled={disabledForm === false ? disabledAddButton : disabledForm}>{t("detail_vessel_inventory_requisition.button_add_inventory")}</Button>
                                                    </div>
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button style={{width:100}} className="save-button" disabled={disabledForm === false ? disabledButton : !updatedButton} type="submit">{t("detail_vessel_inventory_requisition.save_button")}</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button style={{width:100}} className="cancel-button" onClick={() => {
                                                        navigate("/SparepartRequisition")
                                                    }} variant="danger">{t("detail_vessel_inventory_requisition.cancel_button")}</Button>
                                                </div>


                                            </div>
                                    }



                                </Form>



                            </div>


                        </div>

                    }>
                </ContainerBox>
                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    {/* <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Radian Peninsula Indoneia</p>
                    </div> */}
                    {/* <div style={{
                        marginLeft: 300,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >Form Permintaan Suku Cadang </h3>

                    </div> */}

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_vessel_inventory_requisition.field_crew_requested")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{vesselInventoryRequisition.requested_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_vessel_inventory_requisition.field_approved_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{vesselInventoryRequisition.approved_name}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <Table className="tableClass" >
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_vessel_inventory_requisition.field_request_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{vesselInventoryRequisition.request_date ? moment(vesselInventoryRequisition.request_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_vessel_inventory_requisition.field_approved_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{vesselInventoryRequisition.approved_date ? moment(vesselInventoryRequisition.approved_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>

                        </Table>
                        {/* <p style={{ margin: '0' }} className="text-danger">Tanggal Pengajuan :  {sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("DD-MMM-yyyy") : ""}</p> */}
                        {/* <p style={{ margin: '0' }} className="text-danger">NO&nbsp;&nbsp;&nbsp;:  {returns.form_number}</p> */}
                    </div>
                    <div style={{ paddingBottom: 20 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_vessel_inventory_requisition.table_name1")} </td>
                            <td>{t("detail_vessel_inventory_requisition.table_name2")} </td>
                            <td>{t("detail_vessel_inventory_requisition.table_name3")} </td>
                        </tr>

                        {vesselInventoryRequisition.list_requisition && vesselInventoryRequisition.list_requisition.map((listRequisition, index) => {
                            return (
                                <tr key={index} className="table-caption-body">
                                    <td>{index + 1}</td>
                                    <td>{listRequisition.vessel_inventory_name}</td>
                                    <td>{listRequisition.quantity}</td>
                                    <td>{listRequisition.note}</td>
                                    {/* <td>{listReturn.return_inventory_description}</td> */}
                                </tr>
                            )
                        })
                        }


                    </table>
                    <div style={{ float: 'left', width: '70%', marginTop: '30px' }}>
                        <span >{t("detail_vessel_inventory_requisition.field_crew_requested")} </span>
                        <p style={{ marginTop: '100px' }}>{vesselInventoryRequisition.requested_name}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '30px' }}>
                        <span >{t("detail_vessel_inventory_requisition.field_approved_by")} </span>
                        <p style={{ marginTop: '100px' }}>{vesselInventoryRequisition.approved_name ? vesselInventoryRequisition.approved_name : " "}</p>
                        {vesselInventoryRequisition.approved_name ? "" : <div style={{ paddingBottom: 30 }}></div>}
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>

                </div>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    );

}