import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, convertBase64, insertUpdateVesselxEquipmentPhoto, getVesselxEquipmentById,
     deleteVesselEquipmentPhoto, getVesselxEquipmentPhotoById, getVesselXEquipmentPhotoCount, checkIsCover, getParameter,
     getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import { omit } from 'lodash';
import Modals from "../../../../Components/modal";
import { Trash,InfoCircleFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import Loading from "../../../../Components/Loading";
import ContainerBox from "../../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function VesselEquipmentPhotoPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const showFormPhoto = () => setShow(true);
    const [showEquipmentDetail, setShowEquipmentDetail] = useState("");
    const [listPhoto, setListPhoto] = useState([]);
    const [vesselEquipmentPhotoCount, setVesselEquipmentPhotoCount] = useState(0)
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [disabledButton, setDisabledButton] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 20, 40]);
    const [descending, setDescending] = useState(false);
    const [isSearched, setIsSearched] = useState(0);
    const orderByList = [{
        label: `${t("sparepart_catalog_maintenance_plan.order_name1")}`,
        value: "next_maintenance_date"
    }];
    const [orderBy, setOrderBy] = useState({
        label: `${t("vessel_equipment_photo.order_name1")}`,
        value: "taken_date"
    });
    const [description, setDescription] = useState(null);
    const orderBySelection = [{
        label: `${t("vessel_equipment_photo.order_name1")}`,
        value: "taken_date"
    }]
    const [vesselPhotoEquipment, setvesselPhotoEquipment] = useState({
        vessel_x_equipment_id: searchParams.get("vesselEquipmentId"),
        vessel_id: searchParams.get("vesselid"),
        equipment_id: searchParams.get("equipmentId"),
        base64_img: '',
        title: '',
        description: '',
        taken_date: ''
    });
    const [fileImg, setFileImg] = useState("");
    const [vesselEquipmentPhotoId, setVesselEquipmentPhotoId] = useState("");
    const [listVesselEquipment, setListVesselEqipment] = useState([]);
    const [listParameter, setListParameter] = useState([]);
    const [removeId, setRemoveId] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("")
    const [vesselName, setVesselName] = useState("")
    const [equipmentName, setEquipmentName] = useState("")
    const [equipmentCategoryId, setEquipmentCategoryId] = useState("")
    const [vesselId, setVesselId] = useState("")


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                setVesselEquipmentId(searchParams.get("vesselEquipmentId"));
                setEquipmentName(searchParams.get("vesselEquipmentName"));
                setVesselName(searchParams.get("vesselName"));
                setVesselId(searchParams.get("vesselId"));
                setEquipmentCategoryId(searchParams.get("equipmentId"));
                loadAccess();
                setLoading(false);
                
            }
        }
        checkCookies();
        // console.log(location.state.vesselId);
        // console.log(vesselPhotoEquipment);
    }, []);

    useEffect(() => {
        if (vesselEquipmentPhotoId !== "") {
            setCoverEquipment();
            handleResets();
            loadPhoto();
        }

    }, [vesselEquipmentPhotoId]);

    useEffect(() => {
        // setvesselPhotoEquipment({...vesselPhotoEquipment, equipment_id: equipmentCategoryId, vessel_id: vesselId, vessel_x_equipment_id: vesselEquipmentId})
        if (vesselEquipmentId !== "" && vesselEquipmentId !== undefined) {
            initVesselEquipment()
            handleResets();
            loadPhoto();
        }


    }, [vesselEquipmentId])

    useEffect(() => {
        
        console.log(vesselPhotoEquipment);
    }, [vesselPhotoEquipment]);

    useEffect(() => {
        loadPhoto();
    }, [page]);

    useEffect(() => {
        loadPhoto();
    }, [itemPerPage]);

    useEffect(() => {
        loadPhoto();
    }, [orderBy]);

    useEffect(() => {
        console.log('--LIST PHOTO---',listPhoto );
    }, [listPhoto]);

    useEffect(() => {
        if (removeId !== "") {
            removeVesselPhotoEquipment();
        }
    }, [removeId]);

    const initVesselEquipment = async () => {
        try {
            let response = await getVesselxEquipmentById(cookies.token, vesselEquipmentId);
            setListVesselEqipment(response);
            setvesselPhotoEquipment({
                ...vesselPhotoEquipment,
                vessel_x_equipment_id: response.id,
                vessel_id: response.vessel_id,
                equipment_id: response.equipment_id,
                description: response.description,
            });

            let parameter = await getParameter(cookies.token, vesselEquipmentId);
            setListParameter(parameter);

        } catch (exception) {
            console.log(exception);
        }
    }

    const addVesselEquipmentPhoto = async () => {
        try {
            let response = await insertUpdateVesselxEquipmentPhoto(cookies.token, vesselPhotoEquipment);
            if (response === 0) {
                alert(`${t("vessel_equipment_photo.alert_success_save_data")}`);
                setShow(false);
                handleResets();
                loadPhoto();
            }
            else {
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleResets = async () => {

        setvesselPhotoEquipment({
            base64_img: '',
            title: '',
            description: '',
            taken_date: ''

        });

        setFileImg("");

    }

    const loadPhoto = async () => {
        try {
            let vesselEquipmentPhotoCount = await getVesselXEquipmentPhotoCount(cookies.token, vesselEquipmentId);
            setVesselEquipmentPhotoCount(vesselEquipmentPhotoCount);
            let totalPage = vesselEquipmentPhotoCount / itemPerPage;
            setTotalPage(totalPage);
            let listPhoto = await getVesselxEquipmentPhotoById(cookies.token, vesselEquipmentId, page, itemPerPage, orderBy.value);
            setListPhoto(listPhoto);
        }


        catch (exception) {
            console.log(exception);
        }

    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.base64_img);
        setDescription(item.description)
    };

    const handelRotationRight = () => {
        const totalLength = listPhoto.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listPhoto[0].base64_img;
            const descUrl = listPhoto[0].description;
            setClickedImg(newUrl);
            setDescription(descUrl)
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        })
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const handelRotationLeft = () => {
        const totalLength = listPhoto.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listPhoto[totalLength - 1].base64_img;
            const descUrl = listPhoto[totalLength - 1].description;

            setClickedImg(newUrl);
            setDescription(descUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };


    const setCoverEquipment = async () => {
        try {
            let response = await checkIsCover(cookies.token, vesselEquipmentPhotoId, vesselEquipmentId);
            if (response === 0) {
                alert(`${t("vessel_equipment_photo.alert_set_cover")}`);
                window.location.reload();
                loadPhoto();
            }
        } catch (exception) {
            console.loge(exception);
        }
    }

    const removeVesselPhotoEquipment = async () => {
        let response = await deleteVesselEquipmentPhoto(cookies.token, removeId);
        if (response.error_code === 0) {
            // window.location.reload();
            loadPhoto();
            initVesselEquipment();
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/Vessel");
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("vessel_equipment_photo.order_name1")}`,
                value: "taken_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${vesselName} ${equipmentName} Photo`}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex" }}>
                                                <Button style={{width:130}} onClick={() => {
                                                    setShowEquipmentDetail(true)
                                                }}><InfoCircleFill/> {t("vessel_equipment_photo.button_detail")}</Button>
                                            </div>

                                            <div style={{ display: "flex" }}>
                                                <Button disabled={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? true :!updatedButton} style={{width:130}} className="cancel" onClick={() => {
                                                    showFormPhoto();
                                                    setvesselPhotoEquipment({
                                                        base64_img: '',
                                                        title: '',
                                                        description: '',
                                                        taken_date: ''

                                                    });
                                                    initVesselEquipment();
                                                    setFileImg("");

                                                }}>{t("vessel_equipment_photo.button_add_photo")}</Button>
                                            </div>
                                        </div>

                                        <br /><br />
                                        <Col md={12}>
                                            <Row>
                                                {
                                                    listPhoto.map((photo, index) => {
                                                        return (

                                                            <Col md={3}>
                                                                <div key={index} style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flexWrap: "nowrap",
                                                                    flexDirection: "column",
                                                                    padding: 10,
                                                                    borderWidth: 1,
                                                                    margin: 5,
                                                                    borderStyle: "solid",
                                                                    borderColor: "#2f71bd",
                                                                    borderRadius: 5,
                                                                    height: 400,

                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 5,
                                                                        alignContent: "flex-start",
                                                                        alignItems: "flex-start",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flexWrap: "wrap",

                                                                    }}>
                                                                        <img src={photo.base64_img} onClick={() => {
                                                                            handleClick(photo, index)
                                                                        }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                                    </div>
                                                                    <div style={{
                                                                        flex: 1,
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        justifyContent: "flex-end",
                                                                        alignSelf: "flex-end",
                                                                        flexWrap: "nowrap",
                                                                        flexDirection: "row",
                                                                        width: "100%",
                                                                    }} >
                                                                        <div style={{
                                                                            flex: 1,
                                                                            flexDirection: "column",
                                                                            alignContent: "flex-end",
                                                                            alignItems: "flex-end",
                                                                            justifyContent: "flex-end",
                                                                            alignSelf: "flex-end",
                                                                            textAlign: "center"
                                                                        }}><p>{!photo.title ? "" : photo.title}</p></div>
                                                                        <div style={{
                                                                            flex: 1,
                                                                            flexDirection: "column",
                                                                            alignContent: "flex-end",
                                                                            alignItems: "flex-end",
                                                                            justifyContent: "flex-end",
                                                                            alignSelf: "flex-end",
                                                                            textAlign: "center"
                                                                        }}><p>{!photo.description ? "" : photo.description}</p></div>
                                                                        <div style={{
                                                                            flex: 1,
                                                                            flexDirection: "column",
                                                                            alignContent: "flex-end",
                                                                            alignItems: "flex-end",
                                                                            justifyContent: "flex-end",
                                                                            alignSelf: "flex-end",
                                                                            textAlign: "center"
                                                                        }}><p>{!photo.taken_date ? "" : photo.taken_date}</p>
                                                                            <div style={{
                                                                                flex: 1,
                                                                                flexDirection: "column",
                                                                                alignContent: "flex-end",
                                                                                alignItems: "flex-end",
                                                                                justifyContent: "flex-end",
                                                                                alignSelf: "flex-end",
                                                                                textAlign: "center"
                                                                            }}>
                                                                                <Button disabled={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? true : !deletedButton} style={{}} onClick={() => {
                                                                                    if (window.confirm(`${t("vessel_equipment_photo.delete_confirmation")}?`)) {
                                                                                        setRemoveId(photo.photo_id)

                                                                                    }
                                                                                }} variant="danger"><Trash /></Button>

                                                                            </div>

                                                                        </div>

                                                                        {/* {
                                                                            photo.is_cover === true ?

                                                                                <Form.Check onClick={() => {
                                                                                    setVesselEquipmentPhotoId(photo.photo_id);
                                                                                }} type="checkbox" defaultChecked={true} label={`${t("vessel_equipment_photo.cover_label")}`} />
                                                                                :
                                                                                <>
                                                                                    <input onClick={() => {
                                                                                        setVesselEquipmentPhotoId(photo.photo_id);
                                                                                    }} type="checkbox" />
                                                                                    <label>&nbsp; {t("vessel_equipment_photo.cover_label")}</label>
                                                                                </>
                                                                        } */}

                                                                    </div>

                                                                </div>

                                                            </Col>

                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col><br /><br />
                                    </div>
                                </div>



                            </div>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />

                            <Loading
                                loading={loading}
                            />
                        </div>

                    }>


                </ContainerBox >


            </Container >

            {clickedImg && (
                <Modals
                    clickedImg={clickedImg}
                    handelRotationRight={handelRotationRight}
                    setClickedImg={setClickedImg}
                    handelRotationLeft={handelRotationLeft}
                    image={listPhoto}
                    description={description}
                    setDescription={setDescription}
                />
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>{t("vessel_equipment_photo.modal_add_photo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        setDisabledButton(true);
                        addVesselEquipmentPhoto();
                    }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_equipment_photo.field_photo")}</Form.Label>
                            <Form.Control onChange={async (e) => {
                                setFileImg(e.target.value)
                                let base64Img = await convertBase64(e.target.files[0]);
                                const base64ImgString = base64Img.toString();
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, base64_img: base64ImgString, equipment_id: equipmentCategoryId, vessel_id: vesselId, vessel_x_equipment_id: vesselEquipmentId })
                                

                            }} type="file" value={fileImg} required></Form.Control>
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Vessel Name</Form.Label>
                            <Form.Control onChange={(e) => {

                            }} value="{vesselPhoto.name}" type="text" placeholder="Enter Photo Name" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Equipment Name</Form.Label>
                            <Form.Control onChange={(e) => {

                            }} value="{vesselPhoto.name}" type="text" placeholder="Enter Photo Name" required></Form.Control>
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_equipment_photo.field_photo_label")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, title: e.target.value })
                            }} value={vesselPhotoEquipment.title} type="text" placeholder="" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_equipment_photo.field_taken_date")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, taken_date: e.target.value })
                            }} value={vesselPhotoEquipment.taken_date} type="date" placeholder="" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_equipment_photo.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, description: e.target.value })
                            }} value={vesselPhotoEquipment.description} as="textarea" rows={3} placeholder=""></Form.Control>
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, vessel_x_equipment_id: e.target.value })
                            }} value={vesselPhotoEquipment.vessel_x_equipment_id} type="text" placeholder="Enter description" hidden={true}></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, vessel_id: e.target.value })
                            }} value={vesselPhotoEquipment.vessel_id} type="text" placeholder="Enter description" hidden={true}></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control onChange={(e) => {
                                setvesselPhotoEquipment({ ...vesselPhotoEquipment, equipment_id: e.target.value })
                            }} value={vesselPhotoEquipment.equipment_id} type="text" placeholder="Enter description" hidden={true}></Form.Control>
                        </Form.Group> */}
                        <div className="buttonForm">
                            <Button style={{width:100}} variant="primary" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">
                            {t("vessel_equipment_photo.save_button")}
                            </Button>
                            <Button style={{width:100}} variant="danger" className="cancel" onClick={() => {
                                handleResets();
                                handleClose();
                            }}>
                                {t("vessel_equipment_photo.cancel_button")}
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={showEquipmentDetail} >
                <Modal.Header closeButton onClick={() => {
                    setShowEquipmentDetail(false);
                }}>
                    <Modal.Title>{t("vessel_equipment_photo.modal_detail_equipment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{t("vessel_equipment_photo.field_vessel_name")}</Form.Label>
                    <Form.Control type="text" value={vesselName} disabled></Form.Control>
                    <Form.Label>{t("vessel_equipment_photo.field_equipment_name")}</Form.Label>
                    <Form.Control type="text" value={equipmentName} disabled></Form.Control><br />
                    {
                        listParameter.length === 0 ?
                            <></>
                            :
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>{t("vessel_equipment_photo.table_name1")} </th>
                                        <th>{t("vessel_equipment_photo.table_name2")}</th>
                                        <th>{t("vessel_equipment_photo.table_name3")} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listParameter.map((vesselEqipment, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{vesselEqipment.parameter_name}</td>
                                                        <td>{vesselEqipment.parameter_value}</td>
                                                        <td>{vesselEqipment.parameter_desc}</td>
                                                    </tr>

                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                    }


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        setShowEquipmentDetail(false);
                    }}>
                        {t("vessel_equipment_photo.button_closed")}
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}