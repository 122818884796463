import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Modal, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import Select from 'react-select';
import {
    isTokenValid, getStaff, getCurrency, getSparepartCatalogById, getEquipment, getEquipmentPosition,
    insertUpdateSparepartCatalogStock, getSparepartCatalogStock, getSparepartStockCount, getSparepartCatalogStockById,
    deleteSparepartStock, getCheckCondition, getSupplier, getManufacturer, getUserAccessById
} from "../../../../../Helpers/ApplicationHelpers";
import {
    getInventoryPeriod, getInventoryCondition, getInventoryStatus, getInventoryMasterById,
    insertUpdateInventoryStock, checkBarcodeNumber, getInventoryStockCount,
    deleteInventoryStock, getInventoryStockById, getDepreciationGroup
} from "../../../../../Helpers/InventoryHelpers";
import "../../../../../App.css";
import { ArrowLeft, FilePlusFill, PencilFill, Trash, CardChecklist } from "react-bootstrap-icons";
import Paginations from "../../../../../Components/Pagination";
import Navbar from "../../../../../Components/NavBar";
import { Alert } from "bootstrap";
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import ContainerBox from "../../../../../Components/ContainerBox";
import TextBox from "../../../../../Components/Textbox";
import Loading from "../../../../../Components/Loading";
import FormSelect from "../../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function SparepartCatalogStockPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listDepreciation, setListDepreciation] = useState([]);
    const [listSupplier, setListSupplier] = useState();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [selectedSupplier, setSelectedSuppliier] = useState({ id: "" });
    const [listSparepartStock, setListSparepartStock] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [sparepartCatalogCount, setSparepartCatalogCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [listEquipment, setListEquipment] = useState([]);
    const [isDescActive, setIsDescActive] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [listCheckCondition, setListCheckCondition] = useState([]);
    const [showStockModal, setShowStockModal] = useState(false);
    const [listCurrency, setListCurrency] = useState([]);
    const [listInventoryStatus, setlistInventoryStatus] = useState([]);
    const [listManufacturer, setListManufacturer] = useState([]);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [listInventoryPeriod, setlistInventoryPeriod] = useState([]);
    const [sparepartId, setSparepartId] = useState([]);
    const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
    const [selectedDepreciation, setSelectedDepreciation] = useState({ id: "" });
    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_stock_sparepart.order_name1")}`,
        value: "sparepart_catalog_code"
    });

    const orderByList = [
        {
            label: `${t("detail_stock_sparepart.order_name1")}`,
            value: "sparepart_catalog_code"
        },
        // {
        //     label: "Tanggal Pembelian",
        //     value: "purchase_date"
        // },
        // {
        //     label: "Tanggal Serah Terima",
        //     value: "received_date"
        // },
        // {
        //     label: "Status Barang",
        //     value: "status_name"
        // },
        // {
        //     label: "Kondisi Barang",
        //     value: "condition_name"
        // }

    ]

    const [sparepartCatalogMaster, setSparepartCatalogMaster] = useState({
        id: 0,
        equipment_id: null,
        title: "",
        description: "",
        model: "",
        manufacture_name: null,
        part_number: "",
        equipment_position_id: null,
        quantity: ""
    });


    const [sparepartCatalogStock, setSparepartCatalogStock] = useState({
        id: 0,
        sparepart_catalog_id: "",
        sparepart_catalog_code: "",
        purchase_date: "",
        received_date: "",
        periode_id: null,
        periode_number: "",
        purchase_price: "",
        currency_id: "",
        currency_code: "",
        isIDR: false,
        currency_value_idr: "",
        status_id: "",
        removed_date: "",
        condition_id: "",
        depreciation_rate: null,
        description: "",
        note: "",
        last_checked_date: "",
        barcode_number: "",
        condition_status: "",
        supplier_id: null,
        depreciation_group_id: null

    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                findSparepartCatalogMasterById();
                loadCurrency();
                loadInventoryPeriod();
                loadInventoryStatus();
                loadInventoryCondition();
                loadSparepartCatalogStock();
                loadEquipmentCategory();
                loadEquipmentPosition();
                loadCheckCondition();
                loadSupplier();
                loadDepreciationGroup();
                loadManufacturer();
                loadAccess();

            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (sparepartCatalogStock.supplier_id) {
            let response = listSupplier.find(p => p.id === sparepartCatalogStock.supplier_id);
            if (response) {
                setSelectedSuppliier(response);
            }

        }
        if (sparepartCatalogStock.depreciation_group_id) {
            let response = listDepreciation.find(p => p.id === sparepartCatalogStock.depreciation_group_id);
            if (response) {
                setSelectedDepreciation(response);
            }

        }
    }, [sparepartCatalogStock])

    useEffect(() => {
        if (selectedDepreciation.group_name) {

            setSparepartCatalogStock({ ...sparepartCatalogStock, depreciation_group_id: selectedDepreciation.id, depreciation_rate: (Number(100) / Number(selectedDepreciation.number_of_mounth)).toFixed(2) });
        } else {
            setSparepartCatalogStock({ ...sparepartCatalogStock, depreciation_group_id: null });
        }
    }, [selectedDepreciation]);

    useEffect(() => {
        loadSparepartCatalogStock();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadSparepartCatalogStock();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadSparepartCatalogStock();
    }, [orderBy]);

    useEffect(() => {
        if (isSearched !== 0)
            loadSparepartCatalogStock();
    }, [isDescActive]);

    useEffect(() => {
        if (selectedSupplier.supplier_name) {
            setSparepartCatalogStock({ ...sparepartCatalogStock, supplier_id: selectedSupplier.id });
        } else {
            setSparepartCatalogStock({ ...sparepartCatalogStock, supplier_id: null });
        }
    }, [selectedSupplier]);

    const initSparepartCatalogStock = async (sparepartCatalogId) => {
        try {
            let response = await getSparepartCatalogStockById(cookies.token, sparepartCatalogId, cookies.languageId);
            if (response) {
                setSparepartCatalogStock({
                    ...sparepartCatalogStock,
                    id: response.id,
                    sparepart_catalog_id: response.sparepart_catalog_id,
                    sparepart_catalog_code: response.sparepart_catalog_code,
                    purchase_date: response.purchase_date,
                    received_date: response.received_date,
                    periode_id: response.periode_id,
                    periode_number: response.periode_number,
                    purchase_price: response.purchase_price,
                    currency_id: response.currency_id,
                    currency_value_idr: response.currency_value_idr,
                    status_id: response.status_id,
                    removed_date: response.removed_date,
                    condition_id: response.condition_id,
                    depreciation_rate: response.depreciation_rate,
                    description: response.description,
                    note: response.note,
                    isIDR: response.currency_value_idr == 1 ? true : false,
                    last_checked_date: response.last_checked_date,
                    barcode_number: response.barcode_number,
                    condition_status: response.condition_status_id,
                    supplier_id: response.supplier_id,
                    depreciation_group_id: response.depreciation_group_id,
                    equipment_name: response.equipment_name

                });
                // if(response.currency_value_idr==1) setSparepartCatalogStock({ ...sparepartCatalogStock, isIDR: true })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const generateCode = async () => {
        try {
            let randomNumber = Math.floor(Math.random() * 100000000);
            let checkCode = await checkBarcodeNumber(cookies.token, randomNumber);
            if (checkCode != 0) {
                return (generateCode());
            } else {
                return (randomNumber);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentCategory = async () => {
        try {
            let eqp = await getEquipment(cookies.token);
            setListEquipment(eqp);
        } catch (exception) {
            console.log(exception);
        }
    }

    const clearSparepartCatalogStockModal = async () => {

        sparepartCatalogStock.id = 0;
        sparepartCatalogStock.sparepart_catalog_code = "";
        sparepartCatalogStock.purchase_date = "";
        sparepartCatalogStock.received_date = "";
        sparepartCatalogStock.periode_id = null;
        sparepartCatalogStock.periode_number = "";
        sparepartCatalogStock.purchase_price = "";
        sparepartCatalogStock.currency_id = "";
        sparepartCatalogStock.currency_value_idr = "";
        sparepartCatalogStock.status_id = "";
        sparepartCatalogStock.removed_date = "";
        sparepartCatalogStock.condition_id = "";
        sparepartCatalogStock.depreciation_rate = null;
        sparepartCatalogStock.description = "";
        sparepartCatalogStock.note = "";
        sparepartCatalogStock.isIDR = false;
        sparepartCatalogStock.condition_status = "";
        sparepartCatalogStock.supplier_id = "";
        sparepartCatalogStock.depreciation_group_id = null;

        // let stockTmp = {};
        // stockTmp.id = 0;
        // stockTmp.sparepart_catalog_code = "";
        // stockTmp.purchase_date = "";
        // stockTmp.received_date = "";
        // stockTmp.periode_id = "";
        // stockTmp.periode_number = "";
        // stockTmp.purchase_price = "";
        // stockTmp.currency_id = "";
        // stockTmp.currency_value_idr = "";
        // stockTmp.status_id = "";
        // stockTmp.removed_date = "";
        // stockTmp.condition_id = "";
        // stockTmp.depreciation_rate = "";
        // stockTmp.description = "";
        // stockTmp.note = "";
        // stockTmp.isIDR = false;
        // stockTmp.condition_status = "";
        // stockTmp.supplier_id = "";
        // setSparepartCatalogStock(stockTmp);
        // setSelectedSuppliier({});

        setSelectedSuppliier({});
        setSelectedDepreciation({});


    }

    const loadEquipmentPosition = async () => {
        try {
            let response = await getEquipmentPosition(cookies.token);
            if (response) {
                setListEquipmentPosition(response);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            //setDisabledButton(true);
            let sparepartTmp = sparepartCatalogStock;
            if (sparepartCatalogStock.id === 0) {
                let barcodeNumber = await generateCode();
                let today = new Date();
                let yyyy = today.getFullYear();
                let codes = "RPI/" + "PART/" + yyyy + "/" + barcodeNumber;
                sparepartTmp.sparepart_catalog_code = codes;
                sparepartTmp.barcode_number = barcodeNumber;
            }

            let currency_value_idr, removed_date, received_date, purchase_price, periode_number;
            if (sparepartCatalogStock.removed_date == "") removed_date = null;
            if (sparepartCatalogStock.received_date == "") received_date = null;
            if (sparepartCatalogStock.currency_value_idr.toString().match(/\d{1,3}/gi)) currency_value_idr = sparepartCatalogStock.currency_value_idr.toString().replace(/[^,\d]/g, '');
            if (sparepartCatalogStock.purchase_price.toString().match(/\d{1,3}/gi)) purchase_price = sparepartCatalogStock.purchase_price.toString().replace(/[^,\d]/g, '');
            if (sparepartCatalogStock.isIDR == true) currency_value_idr = 1;
            if (sparepartCatalogStock.periode_number.toString().match(/\d{1,3}/gi)) periode_number = sparepartCatalogStock.periode_number.toString().replace(/[^,\d]/g, '');

            sparepartTmp.sparepart_catalog_id = sparepartCatalogMaster.id;
            sparepartTmp.purchase_price = purchase_price;
            sparepartTmp.removed_date = removed_date;
            sparepartTmp.received_date = received_date;
            sparepartTmp.currency_value_idr = currency_value_idr;
            sparepartTmp.periode_number = periode_number;
            sparepartTmp.sparepart_catalog_id = sparepartCatalogMaster.id;


            let response = await insertUpdateSparepartCatalogStock(cookies.token, sparepartTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_stock_sparepart.alert_success_save_data")}`);
                setShowStockModal(false);
                findSparepartCatalogMasterById();
                loadSparepartCatalogStock();
            }
            setDisabledButton(false);

        } catch (exception) {
            console.log(exception);
            setDisabledButton(true);

        }

    }


    const loadCurrency = async () => {
        try {
            let listCurrency = await getCurrency(cookies.token, cookies.languageId);
            setListCurrency(listCurrency);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setlistInventoryPeriod(listInventoryPeriod);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setlistInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const loadSparepartCatalogStock = async () => {
        try {
            // let count = await getSparepartStockCount(cookies.token, searchParams.get("id"), cookies.languageId);
            let count = await getSparepartCatalogStock(cookies.token, cookies.languageId, searchParams.get("id"));
            let finalCount = count.filter(p => p.out_stock == false)
            setSparepartCatalogCount(finalCount.length);
            let totalPage = finalCount.length / itemPerPage;
            setTotalPage(totalPage);
            let descending = isDescActive;
            let response = await getSparepartCatalogStock(cookies.token, cookies.languageId, searchParams.get("id"), page, itemPerPage, orderBy.value, descending);
            let list = response.filter(p => p.out_stock == false)
            setListSparepartStock(list);
        } catch (exception) {
            console.log(exception)
        }

    }

    const removeSparepartStock = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("detail_stock_sparepart.delete_confirmation")}?`) == true) {
                let result = await deleteSparepartStock(cookies.token, id);
                if (result.success) {
                    if (result.error_code === 0) {
                        alert(`${t("detail_stock_sparepart.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("detail_stock_sparepart.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("detail_stock_sparepart.delete_failed_alert")}`);
                }
                loadSparepartCatalogStock();
                findSparepartCatalogMasterById();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const findSparepartCatalogMasterById = async () => {
        try {
            let response = await getSparepartCatalogById(cookies.token, searchParams.get("id"));
            if (response) {
                setSparepartCatalogMaster({
                    ...sparepartCatalogMaster,
                    id: response[0].id,
                    equipment_id: response[0].equipment_id,
                    title: response[0].title,
                    description: response[0].description,
                    model: response[0].model,
                    manufacture_name: response[0].manufacture_name,
                    part_number: response[0].part_number,
                    equipment_position_id: response[0].equipment_position_id,
                    parameters: response[0].parameters,
                    type: 'mainitem',
                    quantity: response[0].quantity
                })

            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const printPageArea = () => {
        //console.log('print');  

        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.print();
        // WinPrint.close();
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadCheckCondition = async () => {
        try {
            let response = await getCheckCondition(cookies.token);
            setListCheckCondition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadSupplier = async () => {
        try {
            let response = await getSupplier(cookies.token);
            setListSupplier(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDepreciationGroup = async () => {
        try {
            let response = await getDepreciationGroup(cookies.token);
            setListDepreciation(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadManufacturer = async () => {
        try {
            let response = await getManufacturer(cookies.token);
            setListManufacturer(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("detail_stock_sparepart.order_name1")}`,
                value: "sparepart_catalog_code"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const printBarcode1 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 1,
                    inv_number: sparepartCatalogStock.sparepart_catalog_code,
                    inv_name: sparepartCatalogMaster.title,
                    inv_type: sparepartCatalogStock.equipment_name,
                    purchase_date: sparepartCatalogStock.purchase_date,
                    cal_date: "",
                    barcode: sparepartCatalogStock.barcode_number
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const printBarcode2 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 2,
                    barcode: sparepartCatalogStock.barcode_number
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const printBarcode3 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 3,
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_stock_sparepart.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => {
                                    navigate("/SparepartCatalog/StockIndex");
                                }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }

                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingTop: 10

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 10,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" useBackButton={true}
                                            backButtonChild={
                                                <div>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flexDirection: "row",
                                                        alignSelf: "center",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            alignSelf: "center",
                                                            flex: 1,
                                                        }}><CardChecklist color="white" size={32} /></div>
                                                    </div></div>
                                            } titleCaption={`${t("detail_stock_sparepart.header_sparepart_information")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        flex: 3
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>


                                                                <Form.Group>
                                                                    <Form.Label>{t("detail_stock_sparepart.field_equipment_category")}</Form.Label>
                                                                    <Form.Select value={sparepartCatalogMaster.equipment_id} disabled={true}>
                                                                        {
                                                                            listEquipment.map((equipment, index) => {
                                                                                return <option key={index} value={equipment.id}>{equipment.equipment_name}</option>
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Form.Group>
                                                                <TextBox value={sparepartCatalogMaster.title} placeholder=""
                                                                    caption={`${t("detail_stock_sparepart.field_part_name")}`} setValueFunction={(e) => {
                                                                        setSparepartCatalogMaster({ ...sparepartCatalogMaster, title: e.target.value });
                                                                    }} disabledForm={true} />
                                                                <TextBox value={sparepartCatalogMaster.model} placeholder=""
                                                                    caption={`${t("detail_stock_sparepart.field_model")}`} setValueFunction={(e) => {
                                                                        setSparepartCatalogMaster({ ...sparepartCatalogMaster, model: e.target.value });
                                                                    }} disabledForm={true} />
                                                                <Form.Group>
                                                                    <Form.Label>{`${t("detail_stock_sparepart.field_manufacturer_name")}`} </Form.Label>
                                                                    <Form.Select disabled={true} value={sparepartCatalogMaster.manufacture_name}>
                                                                        <option value=""></option>
                                                                        {
                                                                            listManufacturer.map((manufactur, index) => {
                                                                                return (
                                                                                    <option key={index} value={manufactur.id}>{manufactur.manufacturer_name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Form.Group>
                                                                {/* <TextBox value={sparepartCatalogMaster.manufacture_name} placeholder="Nama Pabrikan"
                                                                    caption="Nama Pabrikan" setValueFunction={(e) => {
                                                                        setSparepartCatalogMaster({ ...sparepartCatalogMaster, manufacture_name: e.target.value });
                                                                    }} disabledForm={true} /> */}



                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>

                                                                <TextBox value={sparepartCatalogMaster.part_number} placeholder=""
                                                                    caption={`${t("detail_stock_sparepart.field_part_number")}`} setValueFunction={(e) => {
                                                                        setSparepartCatalogMaster({ ...sparepartCatalogMaster, part_number: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <TextBox value={sparepartCatalogMaster.quantity} placeholder=""
                                                                    caption={`${t("detail_stock_sparepart.field_stock")}`} setValueFunction={(e) => {
                                                                        setSparepartCatalogMaster({ ...sparepartCatalogMaster, quantity: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <Form.Group>
                                                                    <Form.Label>{t("detail_stock_sparepart.field_position")}</Form.Label>
                                                                    <Form.Select disabled={true} required onChange={(e) => {
                                                                        if (e.target.value !== "") {
                                                                            setSparepartCatalogMaster({ ...sparepartCatalogMaster, equipment_position_id: e.target.value })
                                                                        } else {
                                                                            setSparepartCatalogMaster({ ...sparepartCatalogMaster, equipment_position_id: null })
                                                                        }

                                                                    }} value={sparepartCatalogMaster.equipment_position_id}>
                                                                        <option selected value="">
                                                                            
                                                                        </option>
                                                                        {listEquipmentPosition.map((position, index) => (
                                                                            <option key={index} value={position.id}>{position.position}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                                <TextBox asType={"textarea"} rows={3} value={sparepartCatalogMaster.description} placeholder=""
                                                                    caption={`${t("detail_stock_sparepart.field_description")}`} setValueFunction={(e) => {
                                                                        setSparepartCatalogMaster({ ...sparepartCatalogMaster, description: e.target.value });
                                                                    }} disabledForm={true} />

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                            }>


                                        </ContainerBox>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        flexWrap: "nowrap",
                                    }}>

                                        <ContainerBox
                                            titleCaption={`${t("detail_stock_sparepart.header_stock_sparepart")}`}
                                            useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                            containerPos="inner"
                                            actionContainerChild={
                                                <div>
                                                    <Button disabled={!updatedButton} variant="secondary" style={{

                                                    }} onClick={() => {
                                                        clearSparepartCatalogStockModal();
                                                        setShowStockModal(true);
                                                        setSparepartCatalogStock({ ...sparepartCatalogStock, id: 0 });
                                                        sparepartCatalogStock.id = 0;
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            alignSelf: "center",
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                flex: 1,
                                                            }}><FilePlusFill size={32} /></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 8,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                paddingLeft: 10,
                                                                fontWeight: "bold",
                                                                fontSize: 18,
                                                            }}>{t("detail_stock_sparepart.add_button")}</div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            }
                                            childContent={
                                                <div className="master-table-inner-container">
                                                    <div className="table-container">
                                                        <div className="table-header">
                                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                            <div style={{ flex: 3 }} className="table-header-content">{t("detail_stock_sparepart.table_name1")}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{t("detail_stock_sparepart.table_name2")}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{t("detail_stock_sparepart.table_name3")}</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">{t("detail_stock_sparepart.table_name4")}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{t("detail_stock_sparepart.table_name5")}</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">{t("detail_stock_sparepart.table_name6")}</div>
                                                        </div>
                                                        {
                                                            listSparepartStock.map((sparepart, index) => {
                                                                return (<div className="table-body">
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{sparepart.sparepart_catalog_code}</p></div>
                                                                    <div style={{ flex: 2 }} className="table-body-content"><p> Rp. {formatRupiah(sparepart.purchase_price || 0)}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{sparepart.status_name}</p></div>
                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{sparepart.condition_name}</p></div>
                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                        <div style={{ padding: 5 }}>
                                                                            <Button variant="primary" onClick={async () => {

                                                                                // navigate("/InventoryStock/Detail", { state: { inventoryStockId:  inventory.id,inventoryMasterId: location.state.inventoryMasterId,departmentCode:location.state.departmentCode} });
                                                                                clearSparepartCatalogStockModal();
                                                                                await initSparepartCatalogStock(sparepart.id);
                                                                                // setSparepartCatalogStock({ ...sparepartCatalogStock, id: sparepart.id });
                                                                                setShowStockModal(true);


                                                                            }}><PencilFill /></Button>
                                                                        </div></div>

                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                        <div style={{ padding: 5 }}>
                                                                            <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                                                onClick={event => {
                                                                                    removeSparepartStock(sparepart.id);
                                                                                }}
                                                                            ><Trash /></Button>
                                                                        </div></div>
                                                                </div>)
                                                            }
                                                            )
                                                        }
                                                    </div>
                                                    <div style={{
                                                        paddingTop: 20,
                                                    }}>

                                                    </div>

                                                    {/* {listInventoryStock.length == 0 ?
                                                        <center>Belum Ada Data </center> : <div></div>

                                                    } */}

                                                    <Paginations
                                                        itemPerPage={itemPerPage}
                                                        totalPage={totalPage}
                                                        page={page}
                                                        setPage={setPage}
                                                        setItemPerPage={setItemPerPage}
                                                        itemPerPageSelection={itemPerPageSelection}
                                                        orderBy={orderBy}
                                                        setOrderBy={setOrderBy}
                                                        orderBySelection={orderByList}
                                                        isDescActive={isDescActive}
                                                        setIsDescActive={setIsDescActive}
                                                        setIsSearched={setIsSearched}
                                                    />
                                                    <Loading
                                                        loading={loading}
                                                        loadingText={`${t("loading_component.text")}`}
                                                    />
                                                </div>
                                            } />
                                    </div>

                                </div>


                            </div>
                        </div>

                    }>


                </ContainerBox>

                <Modal show={showStockModal}
                    size="xl"
                    onHide={() => {
                        setShowStockModal(false);

                    }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("detail_stock_sparepart.modal_add_stock")}`}
                        childContent={
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                {sparepartCatalogStock.id !== 0 &&
                                    <div style={{
                                        flex: 1,
                                        alignItems: "center"
                                    }}>
                                        <div style={{
                                            flex: 1,
                                            padding: 20,
                                        }}>
                                            <div style={{
                                                justifyContent: "center",
                                                alignContent: "center",
                                                fontWeight: "bold",
                                                fontSize: 18
                                            }}>
                                                {`{t("detail_stock_sparepart.text_item_code")}: ${sparepartCatalogStock.sparepart_catalog_code}`}
                                            </div>
                                        </div>
                                        <div style={{
                                            padding: 20,
                                            display: "flex",
                                            flexDirection: "row",
                                            flex: 1,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "flex-start",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 1,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                            }}>
                                                <h6>Barcode</h6>
                                                <Barcode
                                                    height={160}
                                                    value={sparepartCatalogStock.id == undefined || sparepartCatalogStock.id == "" ? 0 : sparepartCatalogStock.barcode_number}
                                                    displayValue={true}
                                                />
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 1,
                                                paddingLeft: 20,
                                                paddingRight: 20,

                                            }}>
                                                <h6>QR Code</h6>
                                                <QRCode
                                                    size={180}
                                                    style={{ padding: 10 }}
                                                    value={sparepartCatalogStock.id == undefined || sparepartCatalogStock.id == "" ? 0 : sparepartCatalogStock.id}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                }

                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();

                                }} style={{ padding: 10 }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                paddingRight: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_purchase_date")}</Form.Label>
                                                        <Form.Control onChange={(e) => {
                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, purchase_date: e.target.value })
                                                        }} type="date" value={moment(sparepartCatalogStock.purchase_date).format("yyyy-MM-DD")} required placeholder="Masukan Tanggal Pembelian"></Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_checking_item")}</Form.Label>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Form.Control
                                                                        onKeyUp={(e) => {

                                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, periode_number: formatCurrency(e.target.value) })
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, periode_number: e.target.value })
                                                                        }} type="text" value={sparepartCatalogStock.periode_number} placeholder=""></Form.Control>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Form.Select onChange={(e) => {
                                                                        setSparepartCatalogStock({ ...sparepartCatalogStock, periode_id: e.target.value })
                                                                    }} value={sparepartCatalogStock.periode_id} >
                                                                        <option selected value="">
                                                                            
                                                                        </option>
                                                                        {listInventoryPeriod.map((period, index) => (
                                                                            <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_purchase_price")}</Form.Label>
                                                        <Form.Control
                                                            onKeyUp={(e) => {

                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, purchase_price: formatCurrency(e.target.value) })
                                                            }}

                                                            onChange={(e) => {
                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, purchase_price: e.target.value })
                                                            }} type="text" value={sparepartCatalogStock.purchase_price} required placeholder=""></Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_currency")}</Form.Label>
                                                        <Form.Select required onChange={(e) => {
                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, currency_id: e.target.value });
                                                            sparepartCatalogStock.currency_id = e.target.value;
                                                            listCurrency.map(function (cur) {
                                                                if (cur.id === sparepartCatalogStock.currency_id) {
                                                                    if (cur.currency_code == "IDR") setSparepartCatalogStock({ ...sparepartCatalogStock, isIDR: true })
                                                                    else setSparepartCatalogStock({ ...sparepartCatalogStock, isIDR: false })

                                                                }
                                                            })
                                                        }} value={sparepartCatalogStock.currency_id} >

                                                            <option selected value="">
                                                                
                                                            </option>
                                                            {listCurrency.map((currency, index) => (
                                                                <option key={index} value={currency.id}>{currency.currency_name} (
                                                                    {currency.currency_code}
                                                                    )</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_exchange_rate")}</Form.Label>
                                                        <Form.Control disabled={sparepartCatalogStock.isIDR}
                                                            onKeyUp={(e) => {

                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, currency_value_idr: formatCurrency(e.target.value, 'Rp. ') })
                                                            }}
                                                            onChange={(e) => {
                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, currency_value_idr: e.target.value })
                                                            }} type="text" value={sparepartCatalogStock.isIDR ? 1 : sparepartCatalogStock.currency_value_idr} required placeholder=""></Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_depreciation_group")}</Form.Label>
                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.group_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listDepreciation} value={sparepartCatalogStock.depreciation_group_id ? selectedDepreciation : null} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedDepreciation({})
                                                                } else {
                                                                    setSelectedDepreciation(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_depreciation_presentation")}</Form.Label>
                                                        <Form.Control
                                                            onKeyUp={(e) => {

                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, depreciation_rate: formatCurrency(e.target.value) })
                                                            }}
                                                            onChange={(e) => {
                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, depreciation_rate: e.target.value })
                                                            }} type="text" value={sparepartCatalogStock.depreciation_rate} required placeholder="" disabled={true}></Form.Control>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                paddingLeft: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label> {t("detail_stock_sparepart.field_item_status")}</Form.Label>
                                                        <Form.Select required onChange={(e) => {
                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, status_id: e.target.value })
                                                        }} value={sparepartCatalogStock.status_id} >
                                                            <option selected value="">
                                                             
                                                            </option>
                                                            {listInventoryStatus.map((status, index) => (
                                                                <option key={index} value={status.id}>{status.inventory_status_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_item_condition")}</Form.Label>
                                                        <Form.Select required onChange={(e) => {
                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, condition_id: e.target.value })
                                                        }} value={sparepartCatalogStock.condition_id} >
                                                            <option selected value="">
                                                             
                                                            </option>
                                                            {listInventoryCondition.map((condition, index) => (
                                                                <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label> {t("detail_stock_sparepart.field_status_condition")}</Form.Label>
                                                        <Form.Select required onChange={(e) => {
                                                            setSparepartCatalogStock({ ...sparepartCatalogStock, condition_status: e.target.value })
                                                        }} value={sparepartCatalogStock.condition_status} >
                                                            <option selected value="">
                                                              
                                                            </option>
                                                            {listCheckCondition.map((statusCondition, index) => (
                                                                <option key={index} value={statusCondition.id}>{statusCondition.check_condition_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_supplier")}</Form.Label>
                                                        <Select placeholder={"Pilih Supplier"}
                                                            getOptionLabel={(item) => {
                                                                return item.supplier_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listSupplier} value={selectedSupplier} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedSuppliier({})
                                                                } else {
                                                                    setSelectedSuppliier(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_description")}</Form.Label>
                                                        <textarea
                                                            className="form-control"
                                                            value={sparepartCatalogStock.description}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, description: e.target.value })
                                                            }}
                                                            rows="4"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_stock_sparepart.field_note")}</Form.Label>
                                                        <textarea
                                                            className="form-control"
                                                            value={sparepartCatalogStock.note}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                setSparepartCatalogStock({ ...sparepartCatalogStock, note: e.target.value })
                                                            }}
                                                            rows="4"
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                <></>
                                                :
                                                <div style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    flex: 1,
                                                    padding: 10,
                                                }}>
                                                    <Button style={{
                                                        width: "100%"
                                                    }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("detail_stock_sparepart.save_button")}</Button>
                                                </div>
                                        }

                                        {
                                            sparepartCatalogStock.id !== undefined && sparepartCatalogStock.id !== "" &&
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                flex: 1,
                                                padding: 10,
                                                flexDirection: "row"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                }}><Button onClick={() => {
                                                    printBarcode1()
                                                }} >{t("detail_stock_sparepart.button_print_big_barcode")}</Button></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                }}><Button onClick={() => {
                                                    printBarcode2()
                                                }} >{t("detail_stock_sparepart.button_print_small_barcode")}</Button></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                }}><Button onClick={() => {
                                                    printBarcode3()
                                                }} >{t("detail_stock_sparepart.button_reset_printer")}</Button></div>


                                            </div>
                                        }

                                     
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flex: 1,
                                            padding: 10,
                                        }}>
                                            <Button style={{
                                                width: "100%"
                                            }} variant="danger" onClick={() => {
                                                setShowStockModal(false);
                                                // navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });
                                            }}>{t("detail_stock_sparepart.cancel_button")}</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        } />
                </Modal>


                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}

                    <div style={{
                        display: "flex",
                        flexDirection: 1,
                        flexWrap: "nowrap",
                        flexDirection: "row"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: 1,
                            flexWrap: "nowrap",
                            flexDirection: "column"
                        }}>

                            <Barcode
                                height={160}
                                value={sparepartCatalogStock.id == undefined || sparepartCatalogStock.id == "" ? 0 : sparepartCatalogStock.sparepart_catalog_code.slice(-8)}
                                displayValue={true}
                            />
                        </div>
                        <div style={{ paddingRight: 100 }}></div>
                        <div style={{
                            display: "flex",
                            flexDirection: 1,
                            flexWrap: "nowrap",
                            flexDirection: "column"
                        }}>

                            <QRCode
                                size={180}

                                value={sparepartCatalogStock.id == undefined || sparepartCatalogStock.id == "" ? 0 : sparepartCatalogStock.id}
                                viewBox={`0 0 256 256`}
                            />
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}   