import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, getCountInventoryType } from "../../../Helpers/ApplicationHelpers";
import { getInventoryType, deleteInventoryType, getInventoryTypeById, insertUpdateInventoryType } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import PaginationsInventory from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function InventoryTypePage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [listInventoryType, setlistInventoryType] = useState([]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageInventory, setPageInventory] = useState(0);
    const [itemPerPageInventory, setItemPerPageInventory] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [totalPageInventory, setTotalPageInventory] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInventory, setSeacrhInventory] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [listSearchInventory, setListSearchInventory] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_type.order_name1")}`,
        value: "inventory_type_name"
    });
    const orderByList = [{
        label: `${t("index_inventory_type.order_name1")}`,
        value: "inventory_type_name"
    }];
    const [inventoryCount, setInventoryCount] = useState(0);
    const [descending, setDescending] = useState(false);



    const [inventoryType, setInventoryType] = useState({
        id: 0,
        inventory_type_name: "",
        inventory_type_description: "",
        language_id: cookies.languageId,
        type: []
    });


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryType();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadInventoryType();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryType();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryType();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadInventoryType();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryType()
    }, [descending]);

    useEffect(() => {
        setListSearchInventory(inventoryType.type.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
        let count = inventoryType.type.length / itemPerPageInventory;
        setTotalPageInventory(count);
    }, [inventoryType])

    useEffect(() => {
        if (searchInventory !== "") {
            let response = inventoryType.type.filter(p => p.inventory_type_name.toLowerCase().includes(searchInventory.toLowerCase()));
            if (response) {
                setListSearchInventory(response.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
                // setTotalPageInventory(response.length);
            }
            else {
                setListSearchInventory([]);
            }

        } else {
            // setTotalPageInventory(inventoryType.type.length)
            setListSearchInventory(inventoryType.type.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
        }
        setLoading(false);
    }, [searchInventory])

    useEffect(() => {
        // loadReturn();
        setListSearchInventory(inventoryType.type.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
    }, [pageInventory]);

    useEffect(() => {
        // loadReturn();
        setListSearchInventory(inventoryType.type.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
    }, [itemPerPageInventory]);





    const initInventoryType = async (inventoryTypeId) => {
        try {
            let response = await getInventoryTypeById(cookies.token, inventoryTypeId, cookies.languageId);
            if (response) {
                setInventoryType({
                    id: response.id,
                    inventory_type_name: response.inventory_type_name,
                    inventory_type_description: response.inventory_type_description,
                    language_id: response.language_id,
                    type: response.type
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }



    const clearInventoryTypeModal = async () => {
        inventoryType.id = 0;
        inventoryType.inventory_type_name = "";
        inventoryType.inventory_type_description = "";
        inventoryType.type = [];
        setSeacrhInventory("");
        setPageInventory(0);
    }


    const saveInventoryType = async () => {
        if (inventoryType.id === 0) {
            let response = await insertUpdateInventoryType(cookies.token, inventoryType);
            if (response === 0) {
                alert("Data Berhasil Disimpan");
                setShowTypeModal(false);
                loadInventoryType();
            }

        }
        else {
            let response = await insertUpdateInventoryType(cookies.token, inventoryType);
            if (response === 0) {
                alert("Data Berhasil Disimpan");
                setShowTypeModal(false);
                loadInventoryType();
            }

        }
    }

    const loadInventoryType = async () => {
        try {
            let inventoryCount = await getCountInventoryType(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery, false);
            let count = inventoryCount;
            setInventoryCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let listInventoryType = await getInventoryType(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setlistInventoryType(listInventoryType);
            setLoading(false);
            setResetSearch(0)
            setIsSearched(0)
        }
        catch (exception) {
            console.log(exception)
        }
    }




    const deleteInventory = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_inventory_type.delete_confirmation")}`) == true) {
                let deleteSelectedInventoryType = await deleteInventoryType(cookies.token, id);
                if (deleteSelectedInventoryType.success) {
                    if (deleteSelectedInventoryType.error_code === 0) {
                        alert(`${t("index_inventory_type.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_inventory_type.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_inventory_type.delete_failed_alert")}`);
                }
                loadInventoryType();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_type.order_name1")}`,
                value: "inventory_type_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_inventory_type.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                navigate("/InventoryType/Detail", { state: { inventoryTypeId: 0 } });
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_type.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_inventory_type.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                setLoading(true);
                                                await loadInventoryType();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0)
                                            setLoading(true);
                                            await loadInventoryType();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadInventoryType();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_type.table_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_type.table_name2")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_type.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_type.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_type.table_name5")}</div>
                                </div>
                                {
                                    listInventoryType.map((inventory, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{inventory.inventory_type_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.inventory_type_code}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{inventory.inventory_type_description}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/InventoryType/Detail", { state: { inventoryTypeId: inventory.id } });
                                                    }}><PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                        onClick={event => {
                                                            deleteInventory(inventory.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>
            <Modal show={showTypeModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setShowTypeModal(false);

                }}>
                <div className="details m-2" >
                    <div className="detailscontent">

                        {inventoryType.id == 0 ?
                            <h3>Tambah Jenis Inventory </h3> :
                            <h3>Informasi Jenis Inventaris </h3>
                        }
                    </div>

                    <Form onSubmit={(e) => {

                        e.preventDefault();
                        saveInventoryType();


                    }} style={{ padding: 10 }}>

                        <Form.Group className="mb-3">
                            <Form.Label>Jenis Inventory</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryType({ ...inventoryType, inventory_type_name: e.target.value })
                            }} type="text" value={inventoryType.inventory_type_name} required placeholder="Masukan Nama Jenis Inventory"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Deskripsi</Form.Label>
                            <textarea
                                className="form-control"
                                value={inventoryType.inventory_type_description}
                                placeholder="Masukan Deskripsi Inventory"
                                onChange={(e) => {
                                    setInventoryType({ ...inventoryType, inventory_type_description: e.target.value })
                                }}
                                rows="4"
                            />
                        </Form.Group>
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchInventory} type="text" placeholder="Cari (Nama Inventaris)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                // await loadInventoryType();
                                                setSeacrhInventory(e.target.value);

                                            }
                                        }}
                                        onChange={(e) => {
                                            setSeacrhInventory(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            // setSeacrhInventory(searchInventory);
                                            // await loadInventoryType();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSeacrhInventory("");
                                            // await loadInventoryType();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Nama Jenis Inventory</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Deskripsi</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    inventoryType.type && listSearchInventory.map((type, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(pageInventory * (itemPerPageInventory)) + (index + 1)}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content"><p>
                                                <Form.Control style={{ width: 270 }} onChange={(e) => {
                                                    let invType = inventoryType.type;
                                                    invType[(pageInventory * (itemPerPageInventory)) + (index)].inventory_type_name = e.target.value;
                                                    setInventoryType({ ...inventoryType, type: invType });
                                                }} type="text" value={type.inventory_type_name} required placeholder=""></Form.Control>
                                            </p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content"><p>
                                                <Form.Control style={{ width: 270 }} onChange={(e) => {
                                                    let invType = inventoryType.type;
                                                    invType[(pageInventory * (itemPerPageInventory)) + (index)].inventory_type_description = e.target.value;
                                                    setInventoryType({ ...inventoryType, type: invType });
                                                }} type="text" value={type.inventory_type_description} placeholder=""></Form.Control>
                                            </p></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button onClick={() => {
                                                        if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                            let list = [...inventoryType.type];
                                                            list.splice((pageInventory * (itemPerPageInventory)) + (index), 1);
                                                            setInventoryType({ ...inventoryType, type: list });
                                                            if (Math.ceil((inventoryType.type.length - 1) / itemPerPageInventory) === pageInventory) {
                                                                setPageInventory(pageInventory - 1)
                                                            }
                                                        }
                                                    }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                        
                            <Paginations
                                itemPerPage={itemPerPageInventory}
                                totalPage={totalPageInventory}
                                page={pageInventory}
                                setPage={setPageInventory}
                            />

                        </div>

                        <div style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            padding: 5
                        }}>
                            <Button variant="primary" onClick={() => {
                                let newParameter = {
                                    id: "",
                                    inventory_type_name: "",
                                    inventory_type_description: ""
                                };
                                if (!inventoryType.type) {
                                    let invType = [];
                                    invType.push(newParameter);
                                    setInventoryType({ ...inventoryType, type: invType });
                                } else {
                                    let invType = inventoryType.type;
                                    invType.push(newParameter);
                                    setInventoryType({ ...inventoryType, type: invType });
                                }

                                if (Math.ceil(inventoryType.type.length / itemPerPageInventory) - 1 > pageInventory) {
                                    setPageInventory(pageInventory + ((Math.ceil(inventoryType.type.length / itemPerPageInventory) - 1) - pageInventory))
                                }

                                // if(totalPageInventory)
                            }}>Tambah Detail</Button>
                        </div>
                        <div style={{ paddingBottom: 10 }}></div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <Button type="submit">Simpan</Button>
                            <Button className="cancel" variant="danger" onClick={() => {
                                setShowTypeModal(false);
                            }}>Batal</Button>
                        </div>

                    </Form>



                </div>

            </Modal>


        </>
    )
}   