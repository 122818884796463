// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableRowHead{
    background-color: black;
}

.tableRowHeadContent{
    color: white;
    font-size: large;
}

.tableRowBodyContent{
    color: white;
    font-size: medium;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Alarm/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".tableRowHead{\n    background-color: black;\n}\n\n.tableRowHeadContent{\n    color: white;\n    font-size: large;\n}\n\n.tableRowBodyContent{\n    color: white;\n    font-size: medium;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
