import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVessel, getStaff, insertUpdateVesselSparepartHandover, getVesselSparepartHandoverById,
    getRequisitionStatus, getVesselSparepartStock, getVesselSparepartHandoverList, getUserAccessById, getCrewOnVessel,
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";

export default function VesselSparepartHandoverDetailPage() {
    const [listVessel, setListVessel] = useState([]);
    const [listVesselSparepart, setListVesselSparepart] = useState([]);
    const [listSparepartStock, setListSparepartStock] = useState([]);
    const [staffMessage, setStaffMessage] = useState("");
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [listCrew, setListCrew] = useState([]);
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [listHandoverIndex, setListHandoverIndex] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledButtonAdd, setDisableButtonAdd] = useState(false);
    const [sparepartHandover, setSparepartHandover] = useState({
        id: 0,
        vessel_id: null,
        requesting_staff_id: null,
        request_date: null,
        approved_date: null,
        approved_by: null,
        vessel_sparepart_requisition_id: null,
        giving_staff_id: null,
        list_handover: []
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVessel();
                loadStaff();
                loadStatus();
                loadAccess();
                loadCrewStaff();
                // loadVesselSparepart();
            }
        }
        checkCookies();
        if (location.state.sparepartHandoverId === 0) {
            setLoading(false);
        } else {
            initVesselHandover();
        }
    }, []);

    useEffect(() => {
        if (sparepartHandover.vessel_sparepart_requisition_id) {
            loadVesselSparepart();
        }
        validationForm()
        if (sparepartHandover.giving_staff_id === null || sparepartHandover.giving_staff_id === "") {
            setDisabledButton(true);
            setStaffMessage("*Harus Diisi");
        } else {
            setStaffMessage("");
            setDisabledButton(false);
        }


    }, [sparepartHandover])

    useEffect(() => {
        if (listHandoverIndex === 1) {
            if (sparepartHandover.list_handover.length < 1) {
                let list = [];
                for (let index = 0; index < listSparepartStock.length; index++) {
                    let listRequest = listSparepartStock[index];
                    var obj = {
                        sparepart_catalog_stock_id: listRequest.id,
                        title: listRequest.model,
                        note: listRequest.note
                    };
                    list.push(obj)
                }
                setSparepartHandover({ ...sparepartHandover, list_handover: list })

            }
        }

    }, [listHandoverIndex])




    const loadVessel = async () => {
        try {
            let response = await getVessel(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listTmp = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id

                };

                listTmp.push(obj);

            }
            setListVessel(listTmp);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadVesselSparepart = async () => {
        try {
            let responses = await getVesselSparepartHandoverList(cookies.token, sparepartHandover.vessel_sparepart_requisition_id);
            setListSparepartStock(responses);

            let response = await getVesselSparepartStock(cookies.token, cookies.languageId);
            let listTmp = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].sparepart_catalog_code + ` (${response[i].part_number? response[i].part_number : ""})`,
                    id: response[i].id,
                    is_handed_over: response[i].is_handed_over
                };

                listTmp.push(obj);

            }
            setListVesselSparepart(listTmp);
            setListHandoverIndex(1);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewStaff = async () => {
        try {
            let response = await getCrewOnVessel(cookies.token);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id

                };

                listStaff.push(obj);

            }
            setListCrew(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateVesselSparepartHandover(cookies.token, sparepartHandover);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data');
                navigate('/VesselSparepartHandover');

            } else {
                alert('Gagal Menyimpan Data');
            }

            setDisabledButton(false);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initVesselHandover = async () => {
        try {
            let response = await getVesselSparepartHandoverById(cookies.token, location.state.sparepartHandoverId);
            if (response) {
                setSparepartHandover({
                    ...sparepartHandover,
                    id: response.id,
                    vessel_id: response.vessel_id,
                    requesting_staff_id: response.requesting_staff_id,
                    request_date: response.request_date,
                    approved_date: response.approved_date,
                    approved_by: response.approved_by,
                    vessel_sparepart_requisition_id: response.vessel_sparepart_requisition_id,
                    giving_staff_id: response.giving_staff_id,
                    list_handover: response.list_handover,

                })
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {

                if (sparepartHandover.list_handover.length > 0) {
                    for (let index = 0; index < sparepartHandover.list_handover.length; index++) {
                        const list = sparepartHandover.list_handover[index];
                        if (list.sparepart_catalog_stock_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage("Nama Inventaris Harus Diisi");
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");

                            setDisabledButton(false);

                            setDisableButtonAdd(false);
                        }
                    }
                } else {
                 
                    setErrorMessage("");

                    setDisableButtonAdd(false);

                }


                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = "Form Serah Terima Suku Cadang";
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true)
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Serah Terima Sparepart"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/VesselSparepartHandover"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Serah Terima Sparepart"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                                <FormSelect caption="Nama Kapal" placeholder="Pilih Kapal..."
                                                                    options={listVessel} value={listVessel.filter(function (option) {
                                                                        return option.id === sparepartHandover.vessel_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, vessel_id: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, vessel_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />


                                                                <FormSelect caption="Staf Yang Mengajukan" placeholder="Pilih Staf..."
                                                                    options={listCrew} value={listCrew.filter(function (option) {
                                                                        return option.id === sparepartHandover.requesting_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, requesting_staff_id: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, requesting_staff_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {/* {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                } */}

                                                                <FormSelect caption="Staf Yang Menyetujui" placeholder=""
                                                                    options={staffOptions} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartHandover.approved_by;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, approved_by: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, approved_by: null });
                                                                        }

                                                                    }} disabledForm={true}

                                                                />

                                                                <TextBox value={sparepartHandover.request_date ? moment(sparepartHandover.request_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Request" controlType="date"
                                                                    caption="Tanggal Request" setValueFunction={(e) => {
                                                                        setSparepartHandover({ ...sparepartHandover, request_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>





                                                                <TextBox value={sparepartHandover.approved_date ? moment(sparepartHandover.approved_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption="Tanggal Disetujui" setValueFunction={(e) => {
                                                                        setSparepartHandover({ ...sparepartHandover, approved_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <FormSelect caption="Staf Yang Memberikan" autoFocusValue={sparepartHandover.giving_staff_id === undefined} placeholder="Pilih Staf..."
                                                                    options={staffOptions.filter(p => p.id !== sparepartHandover.requesting_staff_id)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartHandover.giving_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, giving_staff_id: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, giving_staff_id: null });
                                                                        }

                                                                    }}
                                                                />
                                                                {
                                                                    staffMessage && <p style={{ color: "red" }}>{staffMessage}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {/* {
                                        location.state.sparepartHandoverId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >Cetak <Printer /></Button>
                                            :
                                            <></>
                                    } */}
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Daftar Sparepart"} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            padding: 5
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 3
                                                            }}>Nama Sparepart</div>

                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>Catatan</div>

                                                        </div>
                                                        {
                                                            sparepartHandover.list_handover && sparepartHandover.list_handover.map((list, index) => {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5,
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 3,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>

                                                                            <Select placeholder={""}
                                                                                options={listVesselSparepart.filter((p) => {
                                                                                    return !sparepartHandover.list_handover.find((x) => {
                                                                                        return p.id === x.sparepart_catalog_stock_id
                                                                                    }) && p.out_stock === false
                                                                                })} value={listVesselSparepart.filter(function (option) {
                                                                                    return option.id === list.sparepart_catalog_stock_id;
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    let listHandover = sparepartHandover.list_handover;
                                                                                    listHandover[index].sparepart_catalog_stock_id = e.id;
                                                                                    listHandover[index].price = e.price;
                                                                                    setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                                                }}
                                                                                isDisabled={true}
                                                                            />


                                                                        </div>


                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                let listHandover = sparepartHandover.list_handover;
                                                                                listHandover[index].note = e.target.value;
                                                                                setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                                            }} type="text" value={list.note} placeholder="" ></Form.Control>


                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>
                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button className="save-button" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">Simpan</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button className="cancel-button" onClick={() => {
                                                        navigate("/SparepartHandover")
                                                    }} variant="danger">Batal</Button>
                                                </div>
                                            </div>
                                    }



                                </Form>



                            </div>

                            
                        </div>

                    }>
                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    );

}