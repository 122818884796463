import "./NewLampNavButton.css"
import { Button } from "react-bootstrap";
import "../../Assets/Images/Fonts/Disket-Mono-Regular.ttf"

export default function LampNavButton({ text1, text2, handleOnClick, lamp }) {
    return (
        // <>
        //     <div className={lamp === false ? "polygon" : "polygonGreyedOut"} onClick={() => handleOnClick()}>
        //         <p className="txtforNewLampBtn">{text1}</p>
        //         <p className="txtforNewLampBtn">{text2}</p>
        //     </div>
        // </>

        <>
            <div className={lamp === false ? "polygon" : "polygonGreyedOut"} onClick={() => handleOnClick()}>
                <p className="txtforNewLampBtn">{text1}</p>
                <p className="txtforNewLampBtn">{text2}</p>
            </div>
        </>
    );
}