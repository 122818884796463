import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getStaff, getStaffCount, deleteStaff, getStaffDocumentById, getMechanicCount, getMechanic,
    getUserAccessById, getCrewCount, getCrew
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, Play, BorderStyle } from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function StaffPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation()
    const [listStaff, setListStaff] = useState([]);
    const [loading, setLoading] = useState(true);
    const [staffCount, setStaffCount] = useState(0);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const { t, i18n } = useTranslation();
    const [staffIds, setStaffIds] = useState("");
    const [isResign, setIsResign] = useState(0);
    const [isResignChecked, setIsResingChecked] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [page, setPage] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "",
        value: "staff_name"
    });

    const orderByList = [{
        label: `${t("index_staff_page.order_name2")}`,
        value: "employee_number"
    }, {
        label: `${t("index_staff_page.order_name1")}`,
        value: "staff_name"
    }
    ]
    const [descending, setDescending] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadStaff();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        // if(isSearched !== 0)
        loadStaff();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadStaff();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadStaff();
    }, [orderBy]);

    useEffect(() => {
        // if (isSearched !== 0)
        loadStaff();
    }, [isResign])

    useEffect(() => {
        if (staffIds !== "")
            removeStaff();
        // loadStaff();
    }, [staffIds]);

    useEffect(() => {
        if (resetSearch === 1)
            loadStaff();
    }, [resetSearch])



    useEffect(() => {
        if (isSearched !== 0)
            loadStaff();
    }, [descending]);

    useEffect(() => {
        if (isResignChecked === true && isActiveChecked === true) {
            setIsResign(3);
        }
        else if (isResignChecked === true && isActiveChecked === false) {
            setIsResign(1);
        } else if (isResignChecked === false && isActiveChecked === true) {
            setIsResign(0)
        } else {
            setListStaff([]);
            setTotalPage(0);
            setLoading(false);
            setIsResign("");
        }


    }, [isResignChecked, isActiveChecked])


    const loadStaff = async () => {
        if (isResign !== "") {
            if (window.location.pathname === "/Staff") {
                let staffCount = await getStaffCount(cookies.token, cookies.languageId, searchQuery, false, isResign, cookies.extCustomerId);
                let totalPage = staffCount / itemPerPage;
                setTotalPage(totalPage);
            } else if (window.location.pathname === "/Crew") {
                let CrewCount = await getCrewCount(cookies.token, cookies.languageId, searchQuery, false, isResign, cookies.extCustomerId);
                let totalPage = CrewCount / itemPerPage;
                setTotalPage(totalPage);
            } else {
                let mechanicCount = await getMechanicCount(cookies.token, cookies.languageId, searchQuery, false, isResign, cookies.extCustomerId);

                let totalPage = mechanicCount / itemPerPage;
                setTotalPage(totalPage);
            }


            if (window.location.pathname === "/Mechanic") {
                let listMechanic = await getMechanic(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, isResign,);
                setListStaff(listMechanic)
            } else if (window.location.pathname === "/Crew") {
                let listCrew = await getCrew(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, isResign);
                setListStaff(listCrew)
            } else {
                let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, isResign);
                let listStaffTmp = listStaff.filter(p => p.is_crew === false);
                setListStaff(listStaffTmp)
            }

            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        }

        // setIsResign("")

    }

    const removeStaff = async () => {
        try {
            let response = await deleteStaff(cookies.token, staffIds);
            if (response === 0) {
                setLoading(false);
                alert(`${t("index_staff_page.delete_success_alert")}`)
                loadStaff();
            } else {
                alert(`${t("index_staff_page.delete_failed_alert")}`)
                setLoading(false)
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, "/Staff");
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated);
            }
            setOrderBy({
                label: `${t("index_staff_page.order_name1")}`,
                value: "staff_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={window.location.pathname === "/Staff" ? `${t("index_staff_page.header_title")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.crew_header")}` : `${t("index_technician_page.header_title")}`}
                    useActionContainer={(window.location.pathname === "/Staff" || window.location.pathname === "/Crew") && (cookies.logingAs === "User" || cookies.extCustomerId === "null") ? true : false}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                if (window.location.pathname === "/Crew") {
                                    navigate("/Crew/Detail", { state: { staffId: 0, pathname: window.location.pathname } })
                                } else {
                                    navigate("/Staff/Detail", { state: { staffId: 0, pathname: window.location.pathname } })
                                }

                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_staff_page.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">

                            <div className="master-table-searchbar-container">
                                <div style={{
                                    display: "flex",
                                    padding: 10
                                }}>
                                    <Form.Check label={window.location.pathname === "/Mechanic" ? `${t("index_technician_page.checkbox_active_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.checkbox_active_crew")}` : `${t("index_staff_page.checkbox_active_staff")}`} onClick={(e) => {
                                        setLoading(true);
                                        if (e.target.checked) {
                                            // setIsResign(0)
                                            setIsActiveChecked(true)
                                        } else {
                                            setIsActiveChecked(false)
                                        }
                                        setIsSearched(1)
                                    }} defaultChecked={isActiveChecked}
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    padding: 10
                                }}>
                                    <Form.Check label={window.location.pathname === "/Mechanic" ? `${t("index_technician_page.leave_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.leave_crew")}` : `${t("index_staff_page.leave_staff")}`} onClick={(e) => {
                                        setLoading(true);
                                        if (e.target.checked) {
                                            // setIsResign(1);
                                            setIsResingChecked(true);
                                        } else {
                                            setIsResingChecked(false)
                                        }
                                    }} defaultChecked={isResignChecked}
                                    />
                                </div>
                                <div className="master-table-searchbar-textbox">

                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_staff_page.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadStaff();

                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadStaff();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                            // await loadStaff();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{`${t("index_staff_page.table_name1")}`}</div>
                                    <div style={{ flex: 5 }} className="table-header-content">{`${t("index_staff_page.table_name2")}`}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{`${t("index_staff_page.table_name3")}`}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{`${t("index_staff_page.table_name4")}`}</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{`${t("index_staff_page.table_name5")}`}</div>
                                    {window.location.pathname === "/Mechanic" ? <div style={{ flex: 3 }} className="table-header-content">{`${t("index_technician_page.table_name6")}`}</div> : ""}
                                    {window.location.pathname === "/Crew" && <div style={{ flex: 3 }} className="table-header-content">{`${t("index_staff_page.table_name6")}`}</div>}
                                    <div style={{ flex: 1 }} className="table-header-content">{`${t("index_staff_page.table_name7")}`}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{`${t("index_staff_page.table_name8")}`}</div>

                                </div>
                                {
                                    listStaff.map((staff, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p><img src={staff.photo_base64} style={{ maxWidth: 80, maxHeight: 80 }} /></p></div>
                                            <div style={{ flex: 5 }} className="table-body-content"><p>{staff.staff_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{staff.employee_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{staff.phone_number_1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{staff.email}</p></div>
                                            {window.location.pathname === "/Mechanic" ? <div style={{ flex: 3 }} className="table-body-content"><p>{staff.position_name}</p></div> : ""}
                                            {window.location.pathname === "/Crew" && <div style={{ flex: 3 }} className="table-body-content"><p>{staff.status_vaksin}</p></div>}
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        if (window.location.pathname === "/Crew") {
                                                            navigate("/Crew/Detail", { state: { staffId: staff.id, staffName: staff.staff_name, pathname: window.location.pathname } });
                                                        } else {
                                                            navigate("/Staff/Detail", { state: { staffId: staff.id, staffName: staff.staff_name, pathname: window.location.pathname } });
                                                        }

                                                    }}><PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} className="cancel" variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_staff_page.delete_confirmation")} ${staff.staff_name}?`)) {
                                                            let staffIds = staff.id
                                                            setStaffIds(staffIds);
                                                            setLoading(true)
                                                        }
                                                    }}><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                isSearched={isSearched}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>

        </>
    )
}