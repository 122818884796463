import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link, useLocation, useSearchParams } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getLoadCellById, findLastLoadCellReading
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Paginations from "../../Components/Pagination";
import quantumLogo from "../../Assets/Images/quantums.png"
import moment from "moment";
import { ArrowLeft, ArrowLeftSquareFill } from "react-bootstrap-icons";

export default function LoadCellPage() {
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [customerName, setCustomerName] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deviceId, setDeviceId] = useState("");
    const [deviceName, setDeviceName] = useState("");
    // const []
    const [deviceDetail, setDeviceDetail] = useState({});
    const degreeSymbol = '\u00b0';
    const [connectedStatus, setConnectedStatus] = useState("");

    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                //moment.locale('id');
                if (searchParams.get("id") !== null) {
                    setDeviceId(searchParams.get("id"))
                } else {
                    setDeviceId(location.state.deviceId);
                }

            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (deviceId !== "" && deviceId !== null) {
                getLoadCellData();
            }
        }, 1000)


        return () => clearInterval(interval)
    }, [deviceDetail]);

    useEffect(() => {
        if (deviceId !== "" && deviceId !== null)
            getLoadCellData();
    }, [deviceId])

    const getLoadCellData = async () => {
        try {
            let newDate = new Date();

            let response = await findLastLoadCellReading(cookies.token, deviceId);
            setDeviceName(response.device_name);
            setCustomerName(response.customer_name);
            setDeviceDetail(response);
            let deviceDate = new Date(response.created_date);
            const diffTime = Math.abs(newDate - deviceDate) / 36e5;
            // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            if (diffTime >= 6) {
                setConnectedStatus("Disconnected")
            } else {
                setConnectedStatus("")
            }

        } catch (exception) {
            console.log(exception)
        }
    }
    return (
        <>
            {/* <Navbar /> */}
            <Container fluid style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                width: "100%",

            }}>
                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row"
                    // borderStyle: "solid"
                }}>
                    <div style={{ display: "flex", flex: 1 }}>
                        <img src={quantumLogo} height={100} />
                    </div>


                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingTop:10
                }}>
                    <ContainerBox titleCaption={`${deviceName} Detail`}
                        useBackButton={true}
                        backButtonChild={

                            <Button variant="secondary"
                                onClick={() => { navigate(`/maps`); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button>
                        } childContent={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "rgba(219, 235, 255, 0.9)",
                                minHeight: 700,
                            }}>
                                <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
                                    <h3>Last Connected  : {deviceDetail.created_date ? moment(deviceDetail.created_date).format("DD-MM-yyyy HH:mm") : ""}</h3>
                                    <h3>Customer Name  : {customerName ? customerName : ""}</h3>
                                    <h2 style={{ color: "red" }}>{connectedStatus}</h2>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // padding: 5,
                                    // marginTop: "auto",
                                    marginBottom: "auto",
                                    padding: 20
                                }}>
                                    <div style={{
                                        display: "flex",
                                        borderStyle: 'solid',
                                        flex: 1,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        height: 400,
                                        marginRight: 5,
                                        flexDirection: "column",
                                        padding: 10,

                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                borderStyle: "solid",
                                                flexDirection: "column",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                width: 600
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    height: 200,
                                                    borderBottomStyle: "solid",
                                                }}>
                                                    <div className="loadcell-uppercase-radius">
                                                        <p>Radius</p>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 10,
                                                        padding: 10,
                                                        borderLeftStyle: "solid",
                                                        flexDirection: "column"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            borderBottomStyle: "solid"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 3,
                                                                fontSize: 30,
                                                                paddingLeft: 5
                                                            }}>
                                                                <p>Angle :</p>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 9,
                                                                fontSize: 30,
                                                                justifyContent: "center"
                                                            }}>
                                                                <div>{deviceDetail.field_16} {degreeSymbol}</div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: 5,
                                                            marginTop: "auto",
                                                            marginBottom: "auto"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                backgroundColor: "white",
                                                                color: "black solid",
                                                                fontWeight: "bold",
                                                                flex: 9,
                                                                height: 90,
                                                                justifyContent: "right",
                                                                padding: 5,
                                                                fontSize: 50,
                                                                width: "90%",
                                                                marginTop: "auto",
                                                                marginBottom: "auto"
                                                            }}>
                                                                <div>{deviceDetail.field_18} m</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderBottomStyle: "solid",
                                                    // height: 80
                                                    height: 170
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 3,
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                        padding: 9,
                                                        justifyContent: "center",
                                                        position: 'relative',
                                                        letterSpacing: 2,

                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            borderRadius: "50%",
                                                            backgroundColor: deviceDetail.field_16 >= 40 && deviceDetail.field_16 <= 87 ? "green" : "red",
                                                            // width: 37,
                                                            // height: 37,
                                                            width: 67,
                                                            height: 67
                                                        }}></div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 14,
                                                        borderLeftStyle: "solid",
                                                        flexDirection: "column",
                                                        padding: 10
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: 5,
                                                            marginTop: "auto",
                                                            marginBottom: "auto",
                                                            height: 200
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                backgroundColor: "white",
                                                                color: "black solid",
                                                                fontWeight: "bold",
                                                                flex: 9,
                                                                height: 60,
                                                                width: "90%",
                                                                justifyContent: "center",
                                                                padding: 5,
                                                                fontSize: 30,
                                                                // marginTop: "auto",
                                                                // marginBottom: "auto",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: "auto",
                                                                    marginBottom: "auto"
                                                                }}>
                                                                    {
                                                                        deviceDetail.field_16 >= 40 && deviceDetail.field_16 <= 87 ?
                                                                            <div>Normal</div> : <div>LockOutRadL</div>
                                                                    }
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            backgroundColor: "#E72D2D",
                                            color: "black solid",
                                            fontWeight: "bold",
                                            flex: 9,
                                            justifyContent: "right",
                                            padding: 5,
                                            fontSize: 50,
                                            marginTop: "auto",
                                            marginBottom: "auto"
                                        }}>
                                            <div>101.0%</div>
                                        </div>
                                    </div> */}
                                            </div>

                                        </div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        borderStyle: 'solid',
                                        flex: 1,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        height: 400,
                                        marginLeft: 5,
                                        flexDirection: "column",
                                        padding: 10,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                borderStyle: "solid",
                                                flexDirection: "column",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                width: 600
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    height: 200,
                                                    borderBottomStyle: "solid",
                                                }}>
                                                    <div className="loadcell-uppercase-main">
                                                        <p>Main Hoist</p>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 10,
                                                        padding: 10,
                                                        borderLeftStyle: "solid",
                                                        flexDirection: "column"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            borderBottomStyle: "solid"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 5,
                                                                fontSize: 30,
                                                                paddingLeft: 5
                                                            }}>
                                                                <p>Rated Load:</p>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 9,
                                                                fontSize: 30,
                                                                justifyContent: "center"
                                                            }}>
                                                                <div>{deviceDetail.field_10} T</div>
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            padding: 5,
                                                            marginTop: "auto",
                                                            marginBottom: "auto",
                                                            width: "90%"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                backgroundColor: "white",
                                                                color: "black solid",
                                                                fontWeight: "bold",
                                                                flex: 9,
                                                                height: 90,
                                                                justifyContent: "right",
                                                                padding: 5,
                                                                fontSize: 50,
                                                                marginTop: "auto",
                                                                marginBottom: "auto",
                                                                
                                                            }}>
                                                                <div>{deviceDetail.field_8} T</div>
                                                            </div>
                                                            {/* <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                marginLeft: 5,
                                                                marginTop: "auto",
                                                                marginBottom: "auto",
                                                                fontSize: 50
                                                            }}>
                                                                <div></div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    height: 80,
                                                    borderBottomStyle: "solid"
                                                }}><div style={{
                                                    display: "flex",
                                                    flex: 3,
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    padding: 0,
                                                    justifyContent: "center",
                                                    position: 'relative',
                                                    letterSpacing: 2,

                                                }}>
                                                        <div style={{
                                                            display: "flex",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#40C110",
                                                            width: 37,
                                                            height: 37,
                                                        }}></div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 16,
                                                        borderLeftStyle: "solid",
                                                        flexDirection: "column",
                                                        padding: 10
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: 5,
                                                            marginTop: "auto",
                                                            marginBottom: "auto"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                backgroundColor: "white",
                                                                color: "black solid",
                                                                fontWeight: "bold",
                                                                flex: 10,
                                                                height: 60,
                                                                width: "90%",
                                                                justifyContent: "center",
                                                                padding: 5,
                                                                fontSize: 30,
                                                                marginTop: "auto",
                                                                marginBottom: "auto"
                                                            }}>
                                                                {
                                                                    (deviceDetail.field_8 / deviceDetail.field_10) * 100 > 100 ?
                                                                        <div>Overload</div>
                                                                        :
                                                                        <div>Normal</div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div></div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        backgroundColor: "White",
                                                        color: "black solid",
                                                        fontWeight: "bold",
                                                        flex: 9,
                                                        justifyContent: "right",
                                                        padding: 5,
                                                        fontSize: 50,
                                                        marginTop: "auto",
                                                        marginBottom: "auto"
                                                    }}>
                                                        <div>{(deviceDetail.field_8 / deviceDetail.field_10) * 100} %</div>
                                                    </div></div>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                        } />

                </div>



                <Loading
                    loading={loading}
                />
            </Container>
        </>
    );

}