// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageMSB {
    margin: 0 0%;
}

.containerBoxMSB {
    border: 1px solid black;
    background-color: rgba(233, 233, 250, 0.1);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}



.contentBilgesTanks {
    height: 650px;
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 7px;
    margin: 10px;
    color: white;
    padding: 8px;
}

.contentBTContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.contentBTContainer .bitLampContainer {
    margin: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/SMSDashboard/SMSMSB/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B;;;;AAIA;IACI,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".mainPageMSB {\n    margin: 0 0%;\n}\n\n.containerBoxMSB {\n    border: 1px solid black;\n    background-color: rgba(233, 233, 250, 0.1);\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n    border-radius: 12px;\n    -webkit-border-radius: 12px;\n    -moz-border-radius: 12px;\n    -ms-border-radius: 12px;\n    -o-border-radius: 12px;\n}\n\n\n\n.contentBilgesTanks {\n    height: 650px;\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 7px;\n    margin: 10px;\n    color: white;\n    padding: 8px;\n}\n\n.contentBTContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n}\n\n.contentBTContainer .bitLampContainer {\n    margin: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
