import "./NewNavLightContainer.css"

export default function BitLamp({ text, alarm, bitlampColor = "" , fontSize=16}) {
    return (
        // <div className="bitLamp">
            // {
            //     alarm === "true" ?
            //         <div className="bitLampOn"></div> : <div className="bitlampBlack" ></div>
            // }

        //     <div className="textBitLamp" style={{ fontSize }}>{text}</div>
        // </div>
        <>
            {
                alarm === 'true' ?
                    <div className="mainContainerOn">
                        <div className="containerTextTitleNew"><p className="textBitLampNav">{text}</p></div>
                    </div> :
                    <div className="mainContainerOff">
                        <div className="containerTextTitleNew"><p className="textBitLampNav">{text}</p></div>
                    </div>
            }
        </>
    )
}