// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainMenuEngineRes {
    width: 100%;
    height: 100%;
    /* border: 2px rgb(255, 0, 0) solid; */
    /* display: flex; */
    /* justify-content: space-around; */
    /* align-items: center; */
    /* background-image: url('/src/Assets/Images/CustomerDashboard/mainPageDashboardMidBottom.png');
    background-size: contain;
    background-position:center;
    background-repeat: no-repeat;
    background-attachment: local; */
    /* margin: 120px 0; */
}


.containerBoxMenuBottom {
    /* border: 1px solid black; */
    /* background-color: rgba(233, 233, 250, 0.1); */
    width: 90%;
    height: 100%;
    padding: 10px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.txtforTitleOnMainEngineMainMenu{
    margin-left: 5px;
    color: #2563eb;
    font-size: medium;
    font-weight: bold;
    font-family: "Disket Mono";
}

.txtforDetailOnMainEngineMainMenu{
    margin-left: 5px;
    color: #f1f5f9;
    font-size: medium;
    font-weight: bold;
    font-family: "Disket Mono";
}`, "",{"version":3,"sources":["webpack://./src/CustomerPages/SMS/SMSMainMenu/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,mBAAmB;IACnB,mCAAmC;IACnC,yBAAyB;IACzB;;;;mCAI+B;IAC/B,qBAAqB;AACzB;;;AAGA;IACI,6BAA6B;IAC7B,gDAAgD;IAChD,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,0BAA0B;AAC9B","sourcesContent":[".mainMenuEngineRes {\n    width: 100%;\n    height: 100%;\n    /* border: 2px rgb(255, 0, 0) solid; */\n    /* display: flex; */\n    /* justify-content: space-around; */\n    /* align-items: center; */\n    /* background-image: url('/src/Assets/Images/CustomerDashboard/mainPageDashboardMidBottom.png');\n    background-size: contain;\n    background-position:center;\n    background-repeat: no-repeat;\n    background-attachment: local; */\n    /* margin: 120px 0; */\n}\n\n\n.containerBoxMenuBottom {\n    /* border: 1px solid black; */\n    /* background-color: rgba(233, 233, 250, 0.1); */\n    width: 90%;\n    height: 100%;\n    padding: 10px;\n    border-radius: 12px;\n    -webkit-border-radius: 12px;\n    -moz-border-radius: 12px;\n    -ms-border-radius: 12px;\n    -o-border-radius: 12px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 20px;\n}\n\n.txtforTitleOnMainEngineMainMenu{\n    margin-left: 5px;\n    color: #2563eb;\n    font-size: medium;\n    font-weight: bold;\n    font-family: \"Disket Mono\";\n}\n\n.txtforDetailOnMainEngineMainMenu{\n    margin-left: 5px;\n    color: #f1f5f9;\n    font-size: medium;\n    font-weight: bold;\n    font-family: \"Disket Mono\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
