// MyGoogleMapComponent.jsx

import React, { useState, useRef, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GeoFill } from 'react-bootstrap-icons';

const MyGoogleMapComponent = ({ google, devices }) => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const degreeSymbol = '\u00b0';
    const navigate = useNavigate();
    const buttonRef = useRef();



    const onMarkerClick = (props, marker) => {
        setActiveMarker(marker);
        setSelectedDevice(props.device);
    };

    const onClose = () => {
        setActiveMarker(null);
        setSelectedDevice(null);
    };

    const handleButtonClick = (deviceId) => {
        console.log('tes');
        navigate('/loadCell', { state: { deviceId } });
    };


    const defaultCenter = {
        lat: -6.127283373056311,
        lng: 106.85529133081604,
    };

    useEffect(() => {
        const button = document.getElementById('tes');

        const handleButtonClick = () => {
            console.log('Button clicked');
            // Add your navigation logic here
        };

        if (button) {
            button.addEventListener('click', handleButtonClick);
        }

        return () => {
            // Cleanup the event listener when the component unmounts
            if (button) {
                button.removeEventListener('click', handleButtonClick);
            }
        };
    }, []);
    return (

        <div style={{
            display: "flex",
            flex: 0,
            alignContent: "center",
            justifyContent: "center",
        }}>
            <Map

                containerStyle={{
                    position: 'relative',
                    width: 1000,
                    height: 500,
                    x: 0,
                    y: 0
                }}
                google={google}
                zoom={14}
                style={{
                    position: 'absolute',
                    width: 1000,
                    height: 500,
                }}
                initialCenter={defaultCenter}
            >
                {devices.map(device => (
                    <Marker
                        key={device.id}
                        position={{ lat: device.gps_latitude, lng: device.gps_longitude }}
                        title={device.device_name}
                        onClick={onMarkerClick}
                        device={device} // Attach device information to the marker
                    />
                ))}

                <InfoWindow
                    marker={activeMarker}
                    visible={activeMarker !== null}
                    onClose={onClose}
                    disableAutoPan={true}
                    zIndex={1000}
                >
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            borderBottomStyle: "solid"
                        }}>
                            <div style={{ display: "flex", paddingRight: 2 }}><GeoFill size={25} /></div>
                            <div style={{ display: "flex", paddingLeft: 2 }}><h5>{selectedDevice && selectedDevice.customer_name}</h5></div>
                        </div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            paddingTop: 10
                        }}>
                            {selectedDevice && (
                                <table style={{ padding: 5 }}>
                                    <tr>
                                        <td>Vessel Name</td>
                                        <td>:</td>
                                        <td>{selectedDevice.vessel_name}</td>
                                    </tr>
                                   

                                    {/* <p>
                                        Customer Name : {selectedDevice.customer_name}
                                    </p>
                                    <p>radius: {selectedDevice.field_18} m</p>
                                    <p>Angle: {selectedDevice.field_16} {degreeSymbol}</p>
                                    <center>
                                        <a href={`/loadCell?id=${selectedDevice.id}`} type='button'><Button>Detail</Button></a>
                                     
                                    </center> */}
                                </table>



                            )}

                        </div>

                        {/* <center><Button ref={buttonRef} onClick={() => {
                            console.log('tes');
                            navigate('/loadCell', { state: { deviceId: selectedDevice.id } })
                        }}>Detail</Button></center> */}
                    </div>


                </InfoWindow>

            </Map>


        </div>



    );
};



export default GoogleApiWrapper({
    apiKey: 'AIzaSyAiJLI_QqRdHV6SHSkfe4etYhTpi5LsJ9Q', // Replace with your Google Maps API key
})(MyGoogleMapComponent);
