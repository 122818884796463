// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentOutsideLighting {
    color: white;
    height: 630px;
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.contentOutsideLighting .sizedBoxSwitch {
    width: 350px;
    height: auto;
}

.contentOutsideLighting .flexColumn {
    gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/OutsideLighting/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".contentOutsideLighting {\n    color: white;\n    height: 630px;\n    margin: 20px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n}\n\n.contentOutsideLighting .sizedBoxSwitch {\n    width: 350px;\n    height: auto;\n}\n\n.contentOutsideLighting .flexColumn {\n    gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
