import "./index.css";
// import "../../SMSApp.css";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue, getVesselLocation } from "../../../Helpers/ApplicationHelpers";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import { useStateManager } from "react-select";
import GoogleMapContainer from "../../../Components/GoogleMapContainer";
import DropDownForm from "../../../Components/DropDownForm";
import moment from "moment";

export default function CustomerSMSLocationPage(props) {
    const { smsSerialNumber, vesselId, vesselName } = props;
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [idCoodinateGroup, setIdCoodinateGroup] = useState(5);
    const [coordinateType, setCoordinateType] = useState('M');
    const [vesselCoordinate, setVesselCoordinate] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectTimeStamp, setSelectTimeStamp] = useState("");
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [serialNumber, setSerialNumber] = useState();
    const [smsDeviceId, setSMSDeviceId] = useState();
    const [gpsData, setGpsData] = useState(null);
    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
        //   .utc()
          .subtract(30, "minutes")
          .format("yyyy-MM-DD HH:mm:ss")
      );
      const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).format("yyyy-MM-DD HH:mm:ss")
      );
    
      const durationOption = [
        {
          label: "1 Jam",
          value: 1,
        },
        {
          label: "1 Hari",
          value: 2,
        },
        {
          label: "1 Minggu",
          value: 3,
        },
      ];

    const contohGPSData = [
        {
            latitude: -6.114021666666667,
            longitude: 106.862305,
            timestamp_gps: 0
        },
        // {
        //     latitude: -6.1346259649457675,
        //     longitude: 106.85419011636043,
        //     timestamp_gps: 0
        // },
        // {
        //     latitude: -6.121034901958877,
        //     longitude: 106.8442569439223,
        //     timestamp_gps: 0
        // },
        // {
        //     latitude: -6.101892388816553,
        //     longitude: 106.87417670522854,
        //     timestamp_gps: 0
        // }
    ]

    // const [readGpsData, setReadGpsData] = useState({
    //     latitude: -6.101892388816553,
    //     longitude: 106.87417670522854,
    //     timestamp_gps:0
    // })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
        console.log(cookies)
    }, []);

    useEffect(() => {
        console.log('start:', selectStartDate);
        console.log('end:', selectEndDate);
    }, [selectStartDate, selectEndDate])


    const loadCoordinate = async () => {
        try {
            console.log('start:', selectStartDate);
            console.log('end:', selectEndDate);
            console.log('serialnumber:', smsSerialNumber);
            console.log('vesselid:', vesselId);
            const response = await getVesselLocation(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, vesselId);
            console.log('getVesselLocation:', response);
            let dateData = response.map((a) => a.received_date);
            let actualGpsData = response.map((a) => {
                return {
                  latitude: +a.latitude,
                  longitude: +a.longitude,
                  timestamp_gps: moment(a.received_date).format("yyyy-MM-DD HH:mm:ss"),
                };
              });
            setGpsData(actualGpsData);
            console.log('actual:', actualGpsData);
            // console.log('contoh:', contohGPSData);
        } catch (exception){
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainPageLocation">
                <div className="containerBoxLocationBottom">
                <div className="innercontainerBoxLocationBottom">
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexDirection: "row",
                        padding:'5px'}}>
                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Mulai</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    // setSelectStartDate(e.target.value);
                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                }}
                                value={
                                    selectStartDate
                                      ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                      : ""
                                }
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Akhir</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    // setSelectEndDate(e.target.value);
                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                }}
                                value={
                                    selectEndDate
                                      ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                      : ""
                                }
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group style={{margin: "10px", color: 'white'}}>
                            <DropDownForm
                                caption={"Waktu Terkini"}
                                placeholder={"Pilih Waktu"}
                                listOption={durationOption}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                console.log(e);
                                try {
                                    let currentDateTime = new Date();
                                    if (e.target.value === "1") {
                                    console.log("1", currentDateTime);
                                    let startDate = moment(currentDateTime)
                                        // .utc()
                                        .subtract(60, "minutes")
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    let endDate = moment(currentDateTime)
                                        // .utc()
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    // console.log("startdate", startDate);
                                    setSelectStartDate(startDate);
                                    setSelectEndDate(endDate);
                                    } else if (e.target.value === "2") {
                                    console.log("2");
                                    let startDate = moment(currentDateTime)
                                        // .utc()
                                        .subtract(1, "day")
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    let endDate = moment(currentDateTime)
                                        // .utc()
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    setSelectStartDate(startDate);
                                    setSelectEndDate(endDate);
                                    } else if (e.target.value === "3") {
                                    console.log("3");
                                    let startDate = moment(currentDateTime)
                                        // .utc()
                                        .subtract(1, "week")
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    let endDate = moment(currentDateTime)
                                        // .utc()
                                        .format("yyyy-MM-DD HH:mm:ss");
                                    setSelectStartDate(startDate);
                                    setSelectEndDate(endDate);
                                    }
                                } catch (exception) {
                                    console.log(exception);
                                }
                                }}
                            />
                            </Form.Group>

                            <Button
                                variant="dark"
                                style={{ height: "40px", margin: "10px", marginTop: "35px", backgroundColor:'#050512' }}
                                onClick={() => {
                                    loadCoordinate();
                                }}
                            >
                                Tampilkan Data
                            </Button>

                            {/* <Form.Label style={{color: 'white'}}>
                                <input
                                style={{marginTop:"30px"}}
                                type="checkbox"
                                // checked={refreshChecked}
                                onChange={(e) => {
                                    // setRefreshChecked(e.target.checked);
                                }}
                                />
                                Auto Refresh
                            </Form.Label> */}
                    </div>
                    <div className="googleMapContainerNew">
                        {gpsData !== null && <GoogleMapContainer devices={gpsData} vessel={vesselName}/>}
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}