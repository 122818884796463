import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, updatePassword } from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import { omit } from 'lodash';
import Navbar from "../../Components/NavBar";
import FormSelect from "../../Components/FormSelect";
import ContainerBox from "../../Components/ContainerBox";
import Loading from "../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function ChangePassword() {
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState({
        login_password: "",
        password_confirmation: ""
    })
    const [changeLanguage, setChangeLanguage] = useState(0);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setLoading(false);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (password.password_confirmation !== password.login_password) {
            setErrorMessage(`${t("change_password.error_confirmation_password")}`);
            setDisabledButton(true)
        } else {
            setErrorMessage("");
            setDisabledButton(false);
        }
    }, [password])

    const changePassword = async () => {
        try {
            console.log(cookies);
            let res = await updatePassword(cookies.token, cookies.userId, password.login_password);
            if (res.success) {
                if (res.error_code === 0) {
                    alert(`${t("change_password.alert_success_save_password")}`);
                }
                else {
                    alert(`${t("change_password.alert_failed_save_password")}`);
                }
            } else {
                alert(`${t("change_password.alert_failed_save_password")}`);
            }
            setLoading(false);
            setDisabledButton(false);
        }
        catch (exception) {
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("change_password.header_title")}`}
                    useBackButton={false}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Language"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }

                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        padding: 10,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            setDisabledButton(true);
                                            changePassword();
                                            setLoading(true);

                                        }}>
                                            <Form.Group className="mb3">
                                                <Form.Label>{t("change_password.field_new_password")}</Form.Label>
                                                <Form.Control type="password" onChange={(e) => {
                                                    setPassword({ ...password, login_password: e.target.value })
                                                }} value={password.login_password} required></Form.Control>

                                            </Form.Group>
                                            <Form.Group className="mb3">
                                                <Form.Label>{t("change_password.field_new_password_confirmation")}</Form.Label>

                                                <Form.Control type="password" onChange={(e) => {
                                                    setPassword({ ...password, password_confirmation: e.target.value })
                                                }} value={password.password_confirmation}></Form.Control>
                                            </Form.Group>
                                            {
                                                errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                            }
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexWrap: "nowrap",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                paddingTop: 10
                                            }}>

                                                <Button style={{ width: 100 }} type="submit" variant="primary" disabled={disabledButton} >{t("language_setting.save_button")}</Button>

                                                <Button style={{ width: 100 }} className="cancel" type="reset" variant="danger" >{t("language_setting.cancel_button")}</Button>

                                            </div>
                                        </Form>

                                    </div>
                                </div>


                            </div>


                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}