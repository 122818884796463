// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentGearBox {
    display: flex;
    flex-direction: row;
    color: white;
    height: 670px;
    padding: 10px;
    gap: 10px;
}

.leftContentGearBox {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100%;
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 7px;
}

.rightContentGearBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1;
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/GearBox/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;IACP,YAAY;IACZ,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,SAAO;IACP,0CAA0C;IAC1C,kBAAkB;AACtB","sourcesContent":[".contentGearBox {\n    display: flex;\n    flex-direction: row;\n    color: white;\n    height: 670px;\n    padding: 10px;\n    gap: 10px;\n}\n\n.leftContentGearBox {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    height: 100%;\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 7px;\n}\n\n.rightContentGearBox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    flex: 1;\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
