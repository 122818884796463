import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUser, getUserCount } from "../../../Helpers/ApplicationHelpers";
import { getUserRoleXMenu, getUserRoleXMenuCount } from "../../../Helpers/SystemHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function UserAccountXMenuPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [listuserAccount, setListUserAccount] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_user_access.order_name1")}`,
        value: "userAccount.login_username"
    });
    const orderByList = [{
        label: `${t("index_user_access.order_name1")}`,
        value: "userAccount.login_username"
    },
    ]

    const [descending, setDescending] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setLoading(false);
                loadUserAccount();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadUserAccount();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadUserAccount();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadUserAccount();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
        loadUserAccount();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadUserAccount()
    }, [descending]);



    const loadUserAccount = async () => {
        try {
            let count = await getUserCount(cookies.token, cookies.extCustomerId, searchQuery, false);
           
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
            let response = await getUser(cookies.token, undefined,cookies.extCustomerId,page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListUserAccount(response);
            setLoading(false);
            setResetSearch(0);
            setOrderBy({
                label: `${t("index_user_access.order_name1")}`,
                value: "userAccount.login_username"
            })
        } catch (exception) {
            console.log(exception)
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_user_access.header_title")}`}
                    useActionContainer={true}
                    // actionContainerChild={
                    //     <div>
                    //         <Button variant="secondary" style={{

                    //         }} onClick={() => { navigate("/UserRoleXMenu/Detail", { state: { roleId: 0 } }) }}>
                    //             <div style={{
                    //                 display: "flex",
                    //                 flex: 1,
                    //                 alignContent: "center",
                    //                 alignItems: "center",
                    //                 justifyContent: "center",
                    //                 flexDirection: "row",
                    //                 alignSelf: "center",
                    //             }}>
                    //                 <div style={{
                    //                     display: "flex",
                    //                     alignContent: "center",
                    //                     alignItems: "center",
                    //                     justifyContent: "center",
                    //                     alignSelf: "center",
                    //                     flex: 1,
                    //                 }}><FilePlusFill size={32} /></div>
                    //                 <div style={{
                    //                     display: "flex",
                    //                     flex: 8,
                    //                     alignContent: "center",
                    //                     alignItems: "center",
                    //                     justifyContent: "center",
                    //                     alignSelf: "center",
                    //                     paddingLeft: 10,
                    //                     fontWeight: "bold",
                    //                     fontSize: 18,
                    //                 }}>Tambah Akses</div>
                    //             </div>
                    //         </Button>
                    //     </div>
                    // }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_user_access.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadUserAccount();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadUserAccount();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadUserAccount();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_user_access.table_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_user_access.table_name2")}</div>
                                </div>
                                {
                                    listuserAccount.map((UserAccount, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 0.5 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{UserAccount.login_username}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button onClick={() => {
                                                        navigate("/UserAccountXMenu/Detail", { state: { userAccountId: UserAccount.id, lngId: UserAccount.language_id } })
                                                    }}> <PencilFill /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>


        </>
    );

}