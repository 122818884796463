import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getStaff, insertUpdateMechanicGroup, getMechanicGroupById, getStaffSpecialization,
    getStaffMechanicGroup, getMechanicGroupStaff, getMechanicGroupStaffById, insertUpdateMechanicGroupStaff,
    deleteMechanicGroupStaff, getUserAccessById, getMechanicGroupStaffCount
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import ButtonForm from "../../../../Components/ButtonForm";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft, Trash, FilePlusFill, Search, ArrowClockwise, PencilFill } from "react-bootstrap-icons";
import FormSelect from "../../../../Components/FormSelect";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function DetailMechanicGroupPage() {

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [listMechanicGroupStaff, setListMechanicGroupStaff] = useState([]);
    const [loading, setLoading] = useState(true);
    const [leader, setLeader] = useState([]);
    const [errors, setErrors] = useState({});
    const [staffErrorMessage, setStaffErrorMessage] = useState("");
    const { t, i18n } = useTranslation();
    const [staffModal, setStaffModal] = useState("");
    const [listMechanic, setListMechanic] = useState([]);
    const [disabledButtonModal, setDisabledButtonModal] = useState(false);
    const [listCrew, setListCrew] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [mechanicGroupStaffId, setMechanicGroupStaffId] = useState("");
    const [isListStaff, setIsListStaff] = useState([]);
    const [isSearched, setIsSearched] = useState(0)
    const [listStaff, setListStaff] = useState([]);
    const [mechanicGroupId, setMechanicGroupId] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [mechanicGroup, setMechaniGroup] = useState({
        id: 0,
        group_name: "",
        leader_id: null,
        ext_customer_id: cookies.extCustomerId
    })
    const [mechanicGroupStaff, setMechanicGroupStaff] = useState({
        id: 0,
        mechanic_group_id: location.state.mechanicGroupId,
        staff_id: null
    });

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const [page, setPage] = useState(0);
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(5);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_group_technician.order_name1")}`,
        value: "staff.staff_name"
    });
    const orderByList = [{
        label: `${t("detail_group_technician.order_name1")}`,
        value: "staff.staff_name"
    }];
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadStaff();
                loadAccess();
                setMechanicGroupId(location.state.mechanicGroupId);
                setLoading(false);
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
        // if (location.state.mechanicGroupId === 0) {
        //     setLoading(false);
        // } else {
        //     initMechanicGroup();
        // }
    }, []);

    useEffect(() => {
        validationForm();
    }, [mechanicGroup]);

    useEffect(() => {
        if (mechanicGroupStaffId !== "") {
            initMechanicGroupStaff()
        }
    }, [mechanicGroupStaffId]);

    useEffect(() => {
        validationGroupStaff();
    }, [mechanicGroupStaff])

    useEffect(() => {

        if (mechanicGroupId !== 0) {
            initMechanicGroup();
            loadMechanicGroupStaff();
        }

    }, [mechanicGroupId])

    useEffect(() => {
        if (mechanicGroup.leader_id !== null)
            loadCrewMechanic()
    }, [mechanicGroup.leader_id]);

    useEffect(() => {
        // if(isSearched !== 0)
        loadMechanicGroupStaff();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadMechanicGroupStaff();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadMechanicGroupStaff();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0) {
            loadMechanicGroupStaff();
        }
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0)
            loadMechanicGroupStaff()
    }, [descending]);

    useEffect(() => {
        if (removeId !== "")
            removeMechanicGroupStaff()
    }, [removeId]);

    const saveData = async () => {
        try {
            let mechanicGroupTmp = mechanicGroup;
            mechanicGroupTmp.ext_customer_id = mechanicGroupTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : mechanicGroup.ext_customer_id
            let response = await insertUpdateMechanicGroup(cookies.token, mechanicGroup, location.state.mechanicGroupId);
            if (response.error_code === 0) {
                alert(`${t("detail_group_technician.alert_success_save_data")}`);
                setMechanicGroupId(response.data.id);
                loadStaff();
            } else {
                alert(`${t("detail_group_technician.alert_failed_save_data")}`)
            }
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const saveMechanicGroupStaff = async () => {
        try {
            let responseGroup = await insertUpdateMechanicGroup(cookies.token, mechanicGroup, location.state.mechanicGroupId);

            if (responseGroup.error_code === 0) {
                setMechanicGroupId(responseGroup.data.id);
                // loadStaff();
            }
            let response = await insertUpdateMechanicGroupStaff(cookies.token, mechanicGroupStaff);
            if (response.error_code === 0) {
                alert(`${t("detail_group_technician.alert_success_save_data")}`);
                setStaffModal(false);
                // clearInventoryTypeModal();
                loadMechanicGroupStaff();
                loadStaff();
            } else {
                alert(`${t("detail_group_technician.alert_failed_save_data")}`)
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCrewMechanic = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            let crewStaff = response.filter(p => p.id !== mechanicGroup.leader_id && p.is_field_staff === true);
            // setListCrew(crewStaff);
            let crewMechanic = []
            for (let i = 0; i < crewStaff.length; i++) {
                var obj = {
                    value: crewStaff[i].id,
                    label: crewStaff[i].staff_name + ' - ' + (crewStaff[i].position_name ? crewStaff[i].position_name : ""),
                    id: crewStaff[i].id,
                    name: 'crew_id'

                };

                crewMechanic.push(obj);

            }
            setListCrew(crewMechanic);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaff = async () => {
        try {
            let list = await getStaffMechanicGroup(cookies.token);
            setIsListStaff(list)
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListMechanic(response);
            let listMechanic = response.filter(p => p.is_field_staff === true);
            let listStaff = [];
            for (let i = 0; i < listMechanic.length; i++) {
                var obj = {
                    value: listMechanic[i].id,
                    label: listMechanic[i].staff_name,
                    id: listMechanic[i].id,
                    position: listMechanic[i].position_name

                };

                listStaff.push(obj);

            }
            setListStaff(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initMechanicGroup = async () => {
        try {
            let response = await getMechanicGroupById(cookies.token, mechanicGroupId);
            if (response) {
                setMechaniGroup({
                    ...mechanicGroup,
                    id: response[0].id,
                    group_name: response[0].group_name,
                    leader_id: response[0].leader_id,
                })


                let staff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
                let listLeader = staff.find(p => p.id === response[0].leader_id);
                if (listLeader) {

                    setLeader(listLeader);
                }

            } else {
                alert(`${t("detail_group_technician.alert_failed_load_data")}`);
            }
            setLoading(false);
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (mechanicGroup.group_name === undefined || mechanicGroup.group_name === "") {
                    setErrors({
                        ...errors,
                        groupNameError: `${t("detail_group_technician.error_message")}`
                    },
                        setDisabledButton(true))
                }
                else if (mechanicGroup.leader_id === undefined || mechanicGroup.leader_id === null) {
                    setErrors({
                        ...errors,
                        leaderIdError: `${t("detail_group_technician.error_message")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "groupNameError", "leaderIdError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const validationGroupStaff = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (mechanicGroupStaff.staff_id === undefined || mechanicGroupStaff.staff_id === null) {
                    setStaffErrorMessage(`${t("detail_group_technician.error_message")}`);
                    setDisabledButtonModal(true);
                }
                else {
                    setStaffErrorMessage("");
                    setDisabledButtonModal(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadMechanicGroupStaff = async () => {
        try {
            // let count = await getM(cookies.token, cookies.languageId, location.state.mechanicGroupId === 0 ? mechanicGroupId : location.state.mechanicGroupId);
            let count = await getMechanicGroupStaffCount(cookies.token, location.state.mechanicGroupId === 0 ? mechanicGroupId : location.state.mechanicGroupId, searchQuery, false)
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getMechanicGroupStaff(cookies.token, cookies.languageId, location.state.mechanicGroupId === 0 ? mechanicGroupId : location.state.mechanicGroupId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListMechanicGroupStaff(response);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initMechanicGroupStaff = async () => {
        try {
            let response = await getMechanicGroupStaffById(cookies.token, mechanicGroupStaffId);
            if (response) {
                setMechanicGroupStaff({
                    ...mechanicGroupStaff,
                    id: response.id,
                    mechanic_group_id: response.mechanic_group_id,
                    staff_id: response.staff_id
                })
                setStaffModal(true);
                setMechanicGroupStaffId("");
                setLoading(false);
            } else {
                alert(`${t("detail_group_technician.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const removeMechanicGroupStaff = async () => {
        try {
            let responseGroup = await insertUpdateMechanicGroup(cookies.token, mechanicGroup, location.state.mechanicGroupId);

            if (responseGroup.error_code === 0) {
                setMechanicGroupId(responseGroup.data.id);
                // loadStaff();
            }
            let response = await deleteMechanicGroupStaff(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("detail_group_technician.delete_success_alert")}`);
                loadMechanicGroupStaff();
                loadStaff();
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated);
                setDeletedButton(response.deleted);
            }
            setOrderBy({
                label: `${t("detail_group_technician.order_name1")}`,
                value: "staff_name"
              })
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_group_technician.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/MechanicGroup"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_group_technician.container_group_teknisi_title")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <TextBox value={mechanicGroup.group_name} placeholder=""
                                                    caption={`${t("detail_group_technician.field_group_name")}`} setValueFunction={(e) => {
                                                        setMechaniGroup({ ...mechanicGroup, group_name: e.target.value });
                                                    }} />
                                                {
                                                    errors.groupNameError && <p style={{ color: "red" }}>{errors.groupNameError}</p>
                                                }

                                                <FormSelect caption={`${t("detail_group_technician.field_group_leader")}`} autoFocusValue={mechanicGroup.leader_id === undefined} placeholder="Pilih Staf..."
                                                    options={listStaff.filter((p) => {
                                                        return !isListStaff.find((x) => {
                                                            return p.id === x.id
                                                        })
                                                    })} value={listStaff.filter(function (option) {
                                                        return option.id === mechanicGroup.leader_id;
                                                    })} setValueFunction={(e) => {
                                                        let tes = isListStaff.findIndex((testing) => testing.id === mechanicGroup.leader_id);
                                                        isListStaff.splice(tes, 1)
                                                        let leader = {
                                                            staff_name: e.label,
                                                            position_name: e.position
                                                        }

                                                        setLeader(leader);
                                                        if (e !== null) {
                                                            setMechaniGroup({ ...mechanicGroup, leader_id: e.id });
                                                        } else {
                                                            setMechaniGroup({ ...mechanicGroup, leader_id: "" });
                                                        }

                                                    }}
                                                />
                                                {
                                                    errors.leaderIdError && <p style={{ color: "red" }}>{errors.leaderIdError}</p>
                                                }
                                                {/* <TextBox value={country.country_code} placeholder="Kode Negara"
                                                    caption="Kode Negara" setValueFunction={(e) => {
                                                        setCountry({ ...country, country_code: e.target.value });
                                                    }} />
                                                <TextBox value={country.country_flag_url} placeholder="URL Bendera Negara"
                                                    caption="URL Bendera Negara" setValueFunction={(e) => {
                                                        setCountry({ ...country, country_flag_url: e.target.value });
                                                    }} /> */}

                                            </div>
                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {
                                            mechanicGroupId !== 0 &&
                                            <ContainerBox containerPos="inner" titleCaption={`${t("detail_group_technician.container_technician_title")}`}
                                                useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                actionContainerChild={
                                                    <div>
                                                        <Button disabled={!updatedButton} variant="secondary" style={{

                                                        }} onClick={() => {
                                                            setMechanicGroupStaff({
                                                                ...mechanicGroupStaff,
                                                                id: 0,
                                                                mechanic_group_id: mechanicGroupId,
                                                                staff_id: null
                                                            })
                                                            setStaffModal(true);
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                flexDirection: "row",
                                                                alignSelf: "center",
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flex: 1,
                                                                }}><FilePlusFill size={32} /></div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    paddingLeft: 10,
                                                                    fontWeight: "bold",
                                                                    fontSize: 18,
                                                                }}>{t("detail_group_technician.add_technician_button")}</div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                }
                                                childContent={
                                                    <div className="master-table-inner-container">
                                                        <div className="master-table-searchbar-container">
                                                            <div className="master-table-searchbar-textbox">
                                                                <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_group_technician.search_box")}`}
                                                                    onKeyPress={async (e) => {
                                                                        if (e.charCode === 13) {
                                                                            setPage(0)
                                                                            setLoading(true);
                                                                            await loadMechanicGroupStaff();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setSearchQuery(e.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="master-table-searchbar-button">
                                                                <div style={{ paddingRight: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setPage(0)
                                                                        setLoading(true);
                                                                        await loadMechanicGroupStaff();
                                                                    }}><Search /></Button>
                                                                </div>
                                                                <div style={{ paddingLeft: 5 }}>
                                                                    <Button onClick={async () => {
                                                                        setLoading(true);
                                                                        setSearchQuery("");
                                                                        setResetSearch(1);
                                                                        // await loadMechanicGroupStaff();
                                                                    }}><ArrowClockwise /></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-container">
                                                            <div className="table-header">
                                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_group_technician.table_name1")}</div>
                                                                <div style={{ flex: 2 }} className="table-header-content">{t("detail_group_technician.table_name2")}</div>
                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_group_technician.table_name3")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_group_technician.table_name4")}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_group_technician.table_name5")}</div>
                                                            </div>

                                                            <div className="table-body">
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>1</p></div>
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{leader.staff_name}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{leader.position_name}</p></div>
                                                                <div style={{ flex: 3 }} className="table-body-content"></div>
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                    <div style={{ padding: 5 }}>
                                                                        <Button disabled variant="primary" onClick={() => {
                                                                            setMechanicGroupStaffId(leader.id);
                                                                        }}><PencilFill /></Button>
                                                                    </div></div>

                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                    <div style={{ padding: 5 }}>
                                                                        <Button disabled variant="danger"
                                                                            onClick={() => {
                                                                                if (window.confirm(`${t("detail_group_technician.confirmation_delete")} ${leader.staff_name}?`))
                                                                                    setRemoveId(leader.id);
                                                                            }}
                                                                        ><Trash /></Button>
                                                                    </div></div>
                                                            </div>



                                                            {
                                                                listMechanicGroupStaff.map((groupStaff, index) => {
                                                                    return (<div key={index} className="table-body">
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 2)}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{groupStaff.staff_name}</p></div>
                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{groupStaff.position_name}</p></div>
                                                                        <div style={{ flex: 3 }} className="table-body-content">{groupStaff.specialization.map((specialization, index) => {
                                                                            return (
                                                                                specialization.sub_specialization_name + (index === groupStaff.specialization.length - 1 ? "" : ", ")
                                                                            )
                                                                        })}</div>
                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !updatedButton} variant="primary" onClick={() => {
                                                                                    setMechanicGroupStaffId(groupStaff.id);
                                                                                }}><PencilFill /></Button>
                                                                            </div></div>

                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                            <div style={{ padding: 5 }}>
                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                                                    onClick={() => {
                                                                                        if (window.confirm(`${t("detail_group_technician.confirmation_delete")} ${groupStaff.staff_name}?`))
                                                                                            setRemoveId(groupStaff.id);
                                                                                    }}
                                                                                ><Trash /></Button>
                                                                            </div></div>
                                                                    </div>)
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                        <div style={{
                                                            paddingTop: 20,
                                                        }}>

                                                        </div>
                                                        <Paginations
                                                            itemPerPage={itemPerPage}
                                                            totalPage={totalPage}
                                                            page={page}
                                                            setPage={setPage}
                                                            setItemPerPage={setItemPerPage}
                                                            itemPerPageSelection={itemPerPageSelection}
                                                            orderBy={orderBy}
                                                            setOrderBy={setOrderBy}
                                                            orderBySelection={orderByList}
                                                            isDescActive={descending}
                                                            setIsDescActive={setDescending}
                                                            setIsSearched={setIsSearched}
                                                        />
                                                        <Loading
                                                            loading={loading}
                                                        />
                                                    </div>
                                                } />
                                        }


                                    </div>
                                </div>

                            </div>

                            {
                                (cookies.logingAs === "User" || cookies.logingAs === "null") &&
                                <ButtonForm addData={() => {
                                    saveData();
                                }} path="/MechanicGroup" button={disabledButton === true ? disabledButton : !updatedButton} 
                                saveButton={`${t("detail_group_technician.save_button")}`}
                                cancelButton={`${t("detail_group_technician.cancel_button")}`}
                                />

                            }
                        </div>

                    }>

                </ContainerBox>

                <Modal show={staffModal}
                    size={"lg"}
                    onHide={() => {
                        setStaffModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("detail_group_technician.modal_add_technician_title")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButtonModal(true);
                            saveMechanicGroupStaff();
                        }} style={{ padding: 10 }}>

                            <FormSelect placeholder={`${t("detail_group_technician.field_technician_name")}`}
                                options={listCrew.filter((p) => {
                                    return !isListStaff.find((x) => {
                                        return p.id === x.id
                                    })
                                })} value={listCrew.filter(function (option) {
                                    return option.id === mechanicGroupStaff.staff_id;
                                })} setValueFunction={(e) => {
                                    setMechanicGroupStaff({ ...mechanicGroupStaff, staff_id: e.id })
                                }}
                            />
                            {
                                staffErrorMessage && <p style={{ color: "red" }}>{staffErrorMessage}</p>
                            }

                            <div style={{ paddingBottom: 10 }}></div>

                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{ width: 100 }} type="submit" disabled={disabledButtonModal}>{t("detail_group_technician.save_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    // clearInventoryTypeModal();
                                    setStaffModal(false);
                                }}>{t("detail_group_technician.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}