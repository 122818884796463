import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Tab, Tabs, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getCompanyCategory, getCompanyByid, insertUpdateCompany, convertBase64, convertBase64Image,
    getCompanyContact, getCompanyContactById, getCompanyContactCount, insertUpdateCompanyContact, deleteCompanyContact,
    getTitle, getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import { omit } from 'lodash';
import AvatarIcon from "../../../../Assets/Images/default-logo.png"
import { BorderLeft } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import DropDownForm from "../../../../Components/DropDownForm";
import TextBox from "../../../../Components/Textbox";
import ButtonForm from "../../../../Components/ButtonForm";
import { ArrowLeft, FilePlusFill, Search, ArrowClockwise, Trash, PencilFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function CompanyDetailPage() {
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const { t, i18n } = useTranslation();
    const [listCompanyCategory, setListCompanyCategory] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [fileImg, setFileImg] = useState("");
    const [errors, setErrors] = useState({});
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [companyNameError, setCompanyNameError] = useState("");
    const [companyCategoryError, setCompanyCategoryError] = useState("");
    const [companyContactModal, setCompanyContactModal] = useState("");
    const [disabledButtonModal, setDisabledButtonModal] = useState(false);
    const [listCompanyContact, setListCompanyContact] = useState([]);
    const [resetSearch, setResetSearch] = useState(0);
    const [companyId, setCompanyId] = useState("");
    const [listTitle, setListTitle] = useState([]);
    const [companyContactId, setCompanyContactId] = useState("");
    const [page, setPage] = useState(0);
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [descending, setDescending] = useState(false);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_company.order_name1")}`,
        value: "companyContact.contact_name"
    });
    const orderByList = [{
        label: `${t("detail_company.order_name1")}`,
        value: "contact.contact_name"
    },];

    const [company, setCompany] = useState({
        company_name: "",
        company_category_id: null,
        address: "",
        phone_number1: "",
        phone_number2: "",
        fax: "",
        email: "",
        website: "",
        logo_image: "",
        file: "",
        ext_customer_id: cookies.extCustomerId
    })

    const [companyContact, setCompanyContact] = useState({
        company_id: null,
        phone_number1: "",
        phone_number2: "",
        contact_name: "",
        email: "",
        title: null,
        description: ""
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCompanyCategory();
                loadTitle();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
        if (location.state.companyId === 0) {
            setLoading(false);
        } else {
            setCompanyId(location.state.companyId)
            initCompany();
        }

    }, []);

    useEffect(() => {
        if (companyId !== "" && location.state.companyId !== 0)
            loadCompanyContact();
    }, [page]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadCompanyContact()
    }, [resetSearch])

    useEffect(() => {
        if (companyId !== "" && location.state.companyId !== 0 && isSearched !== 0)
            loadCompanyContact();
    }, [itemPerPage]);

    useEffect(() => {
        if (companyId !== "" && location.state.companyId !== 0 && isSearched !== 0)
            loadCompanyContact();
    }, [orderBy]);


    useEffect(() => {
        if (companyId !== "" && location.state.companyId !== 0 && isSearched !== 0)
            loadCompanyContact()
    }, [descending]);

    useEffect(() => {
        if (companyContactId !== "")
            initCompanyContact()
    }, [companyContactId])

    useEffect(() => {
        if (removeId !== "")
            removeData()
    }, [removeId])


    useEffect(() => {
        if (company.company_name === undefined || company.company_name === "") {
            setCompanyNameError(`${t("detail_company.error_empty_field")}`)
            setDisabledButton(true);
        } else {
            setCompanyNameError("")
        }

        if (company.company_category_id === undefined || company.company_category_id === null || company.company_category_id === "") {
            setCompanyCategoryError(`${t("detail_company.error_empty_field")}`)
            setDisabledButton(true);
        } else {
            setCompanyCategoryError("")
        }

        validationForm();
    }, [company]);

    useEffect(() => {
        if (companyId !== "")
            loadCompanyContact();
    }, [companyId]);


    const loadCompanyCategory = async () => {
        try {
            let listCompanyCategory = await getCompanyCategory(cookies.token, cookies.languageId)
            setListCompanyCategory(listCompanyCategory);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const initCompany = async () => {
        try {
            let response = await getCompanyByid(cookies.token, cookies.languageId, location.state.companyId);
            if (response) {
                setCompany({
                    ...company,
                    company_name: response.company_name,
                    company_category_id: response.company_category_id,
                    address: response.address,
                    phone_number1: response.phone_number_1,
                    phone_number2: response.phone_number_2,
                    email: response.email,
                    fax: response.fax,
                    website: response.website,
                    logo_image: response.logo_image

                })
                setLoading(false);

            } else {
                setLoading(false);
            }
        }

        catch (exception) {
            console.log(exception);
        }

    }

    const saveData = async () => {
        try {
            let companyTmp = company
            companyTmp.ext_customer_id = companyTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : companyTmp.ext_customer_id
            let response = await insertUpdateCompany(cookies.token, companyTmp, location.state.companyId);
            if (response.error_code === 0) {
                alert(`${t("detail_company.alert_success_save_data")}`);
                if (location.state.companyId === 0) {
                    navigate('/Company');
                } else {
                    navigate('/Company');

                    // initCompany();
                }
            }
            else {
                alert(`${t("detail_company.alert_failed_save_data")}`);
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
            setDisabledButton(false);
        }

    }

    const saveContact = async () => {
        try {
            let response = await insertUpdateCompanyContact(cookies.token, companyContact);
            if (response.error_code === 0) {
                alert(`${t("detail_company.alert_success_save_data")}`);
                setCompanyContactModal(false);
                clearCompanyContactModal();
                loadCompanyContact();
            } else {
                alert(`${t("detail_company.alert_failed_save_data")}`);
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCompanyContact = async () => {
        try {
            let counts = await getCompanyContactCount(cookies.token, companyId, searchQuery, false);
            let totalPage = counts / itemPerPage
            setTotalPage(totalPage);

            let response = await getCompanyContact(cookies.token, companyId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListCompanyContact(response);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception)
        }
    }

    const clearCompanyContactModal = async () => {
        let companyContactTmp = {};
        companyContactTmp.id = 0;
        companyContactTmp.company_id = "";
        companyContactTmp.phone_number1 = "";
        companyContactTmp.phone_number2 = "";
        companyContactTmp.email = "";
        companyContactTmp.contact_name = "";
        companyContactTmp.title = "";
        companyContactTmp.description = "";
        setCompanyContact(companyContactTmp);

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (company.company_name !== "" && company.company_category_id !== "" && company.company_category_id !== null && errors.companyEmail === undefined) {
                    setDisabledButton(false);
                }



                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadTitle = async () => {
        try {
            let response = await getTitle(cookies.token);
            setListTitle(response)
        } catch (exception) {
            console.log(exception)
        }
    }

    const initCompanyContact = async () => {
        try {
            let response = await getCompanyContactById(cookies.token, companyContactId);
            if (response) {
                setCompanyContact({
                    ...companyContact,
                    id: response.id,
                    company_id: response.company_id,
                    phone_number1: response.phone_number1,
                    phone_number2: response.phone_number2,
                    contact_name: response.contact_name,
                    email: response.email,
                    title: response.title,
                    description: response.description
                })
            }
            setCompanyContactModal(true);
            setCompanyContactId("")
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeData = async () => {
        try {
            let response = await deleteCompanyContact(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("detail_company.delete_success_alert")}`);
                loadCompanyContact();
                setRemoveId("");
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_company.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Company"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>

                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexWrap: "nowrap",
                                    flexDirection: "column",
                                    borderWidth: 2,
                                    borderColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 10,
                                    borderStyle: "solid",

                                }}>
                                    <Tabs
                                        defaultActiveKey="companyProfile"
                                        // transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                        style={{
                                            backgroundColor: "rgba(3, 30, 103, 1)",
                                            borderRadius: 5,

                                        }}
                                    >

                                        <Tab eventKey="companyProfile" title={`${t("detail_company.tab_1")}`}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        paddingBottom: 5,
                                                        width: "100%",
                                                        flexWrap: "nowrap",
                                                    }}>
                                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_company.container_company_profile")}`} childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>
                                                                <div style={{
                                                                    padding: 10
                                                                }}>

                                                                    {

                                                                        company.logo_image === "" || company.logo_image === undefined ?
                                                                            <img className="company-portrait-img" src={AvatarIcon} />
                                                                            :
                                                                            <img className="company-portrait-img" src={company.logo_image} />

                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    padding: 2,
                                                                    borderStyle: "solid",
                                                                    borderRadius: 5,
                                                                    borderWidth: 1,
                                                                    borderColor: "#bf0000",
                                                                    marginBottom: 10,
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 2,
                                                                            flexDirection: "row"
                                                                        }}>
                                                                            <Button onClick={() => {
                                                                                inputFile.current.click();
                                                                            }}>{t("detail_company.photo_button")}</Button>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 10,
                                                                            width: "100%",
                                                                            flexDirection: "column"
                                                                        }}>
                                                                            <Form.Control onClick={() => {
                                                                                inputFile.current.click();
                                                                            }} value={company.file} placeholder={`${t("detail_company.field_choose_photo")}`} type="text" readOnly={true} />
                                                                            <input onChange={async (e) => {
                                                                                if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                                                                    let base64Img = await convertBase64Image(e.target.files[0]);
                                                                                    const base64ImgString = base64Img.toString();
                                                                                    setCompany({ ...company, logo_image: base64ImgString, file: e.target.value });
                                                                                    setUploadFileImageError("");

                                                                                } else {
                                                                                    // setCompany({ ...company, logo_image: "", file: "" });
                                                                                    setUploadFileImageError(`${t("detail_company.error_image_validation")}`)
                                                                                    // setDisabledButton(true)
                                                                                }

                                                                                // let base64Img = await convertBase64(e.target.files[0]);
                                                                                // const base64ImgString = base64Img.toString();
                                                                                // setCompany({ ...company, logo_image: base64ImgString, file: e.target.value });
                                                                                // console.log(e.target.value);

                                                                            }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                                                                            {
                                                                                uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <TextBox value={company.company_name} placeholder=""
                                                                    caption={`${t("detail_company.field_company_name")}`} setValueFunction={(e) => {
                                                                        setCompany({ ...company, company_name: e.target.value });
                                                                    }} />
                                                                {
                                                                    companyNameError && <p style={{ color: "red" }}>{companyNameError}</p>
                                                                }
                                                                <DropDownForm value={company.company_category_id} caption={`${t("detail_company.field_company_category")}`} placeholder=""
                                                                    setValueFunction={(e) => {
                                                                        setCompany({ ...company, company_category_id: e.target.value });
                                                                    }} listOption={listCompanyCategory} valueKey="id" labelKey="company_category" />
                                                                {
                                                                    companyCategoryError && <p style={{ color: "red" }}>{companyCategoryError}</p>
                                                                }

                                                            </div>
                                                        }>
                                                        </ContainerBox>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    alignItems: "flex-end",
                                                    // paddingLeft: 5,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        paddingBottom: 5,
                                                        width: "100%",
                                                        flexWrap: "nowrap",
                                                    }}>

                                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_company.container_contact_information")}`} childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>

                                                                <TextBox value={company.address} asType={"textarea"} row={3} placeholder=""
                                                                    caption={`${t("detail_company.field_company_address")}`} setValueFunction={(e) => {
                                                                        setCompany({ ...company, address: e.target.value });
                                                                    }} />
                                                                {/* {
                                                errors.company_address && <p style={{ color: "red" }}>{errors.company_address}</p>
                                            } */}
                                                                <TextBox value={company.phone_number1} placeholder=""
                                                                    caption={`${t("detail_company.field_phone_number1")}`} setValueFunction={(e) => {
                                                                        if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                                        } else {
                                                                            setCompany({ ...company, phone_number1: e.target.value });
                                                                        }

                                                                    }} />
                                                                {
                                                                    errors.company_phone && <p style={{ color: "red" }}>{errors.company_phone}</p>
                                                                }
                                                                <TextBox value={company.phone_number2} placeholder=""
                                                                    caption={`${t("detail_company.field_phone_number2")}`} setValueFunction={(e) => {
                                                                        if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                                        } else {
                                                                            setCompany({ ...company, phone_number2: e.target.value });
                                                                        }


                                                                    }} />
                                                                <TextBox value={company.email} placeholder=""
                                                                    caption={`${t("detail_company.field_email")}`} setValueFunction={(e) => {
                                                                        if (e.target.value !== "") {
                                                                            if (!new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(e.target.value)) {
                                                                                setErrors({
                                                                                    ...errors,
                                                                                    companyEmail: `${t("detail_company.error_email")}`
                                                                                },
                                                                                    setDisabledButton(true))
                                                                            } else {

                                                                                let emails = omit(errors, "companyEmail");
                                                                                setErrors(emails);
                                                                                setDisabledButton(false)
                                                                            }
                                                                        } else {
                                                                            let emails = omit(errors, "companyEmail");
                                                                            setErrors(emails);
                                                                            setDisabledButton(false)
                                                                        }

                                                                        setCompany({ ...company, email: e.target.value });

                                                                    }} />
                                                                {
                                                                    errors.companyEmail && <p style={{ color: "red" }}>{errors.companyEmail}</p>
                                                                }
                                                                <TextBox value={company.website} placeholder=""
                                                                    caption={`${t("detail_company.field_company_web_address")}`} setValueFunction={(e) => {
                                                                        setCompany({ ...company, website: e.target.value });
                                                                    }} />
                                                            </div>
                                                        }>
                                                        </ContainerBox>

                                                    </div>

                                                </div>
                                            </div>

                                        </Tab>


                                        <Tab eventKey="contact" title={`${t("detail_company.tab_2")}`} disabled={location.state.companyId === 0 ? true : false}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                paddingBottom: 5,
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <ContainerBox containerPos="inner" titleCaption={`${t("detail_company.container_contact_person")}`}
                                                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                    actionContainerChild={
                                                        <div>
                                                            <Button disabled={!updatedButton} variant="secondary" style={{

                                                            }} onClick={() => {
                                                                // navigate("/Country/Detail", { state: { countryId: 0 } }) 
                                                                setCompanyContact({ ...companyContact, id: 0, company_id: location.state.companyId })
                                                                setCompanyContactModal(true);
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    flexDirection: "row",
                                                                    alignSelf: "center",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flex: 1,
                                                                    }}><FilePlusFill size={32} /></div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 8,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        paddingLeft: 10,
                                                                        fontWeight: "bold",
                                                                        fontSize: 18,
                                                                    }}>{t("detail_company.add_button")}</div>
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    }
                                                    childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>
                                                                <div className="master-table-inner-container">
                                                                    <div className="master-table-searchbar-container">
                                                                        <div className="master-table-searchbar-textbox">
                                                                            <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_company.search_box")}`}
                                                                                onKeyPress={async (e) => {
                                                                                    if (e.charCode === 13) {
                                                                                        setPage(0)
                                                                                        setLoading(true);
                                                                                        await loadCompanyContact();
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    setSearchQuery(e.target.value);
                                                                                }} />
                                                                        </div>
                                                                        <div className="master-table-searchbar-button">
                                                                            <div style={{ paddingRight: 5 }}>
                                                                                <Button onClick={async () => {
                                                                                    setPage(0)
                                                                                    setLoading(true);
                                                                                    await loadCompanyContact();
                                                                                }}><Search /></Button>
                                                                            </div>
                                                                            <div style={{ paddingLeft: 5 }}>
                                                                                <Button onClick={async () => {
                                                                                    setLoading(true);
                                                                                    setSearchQuery("");
                                                                                    // await loadCompanyContact();
                                                                                    setResetSearch(1);
                                                                                }}><ArrowClockwise /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="table-container">
                                                                        <div className="table-header">
                                                                            <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                                                            <div style={{ flex: 3 }} className="table-header-content">{t("detail_company.table_name1")} </div>
                                                                            <div style={{ flex: 1 }} className="table-header-content">{t("detail_company.table_name2")}</div>
                                                                            <div style={{ flex: 1 }} className="table-header-content">{t("detail_company.table_name3")}</div>
                                                                        </div>
                                                                        {
                                                                            listCompanyContact.map((contacts, index) => {
                                                                                return (<div key={index} className="table-body">
                                                                                    <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{contacts.contact_name}</p></div>
                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>
                                                                                            <Button variant="primary" onClick={() => {
                                                                                                let idContact = contacts.id;
                                                                                                setCompanyContactId(idContact);
                                                                                            }}><PencilFill />
                                                                                            </Button>
                                                                                        </div></div>
                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>
                                                                                            <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true :!deletedButton} variant="danger" onClick={() => {
                                                                                                // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                                                                let idContact = contacts.id;
                                                                                                if (window.confirm(`${t("detail_company.delete_confirmation")} ${contacts.contact_name}?`))
                                                                                                    setRemoveId(idContact);
                                                                                            }}><Trash />
                                                                                            </Button>
                                                                                        </div></div>


                                                                                </div>)
                                                                            }
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div style={{
                                                                        paddingTop: 20,
                                                                    }}>

                                                                    </div>
                                                                    <Paginations
                                                                        itemPerPage={itemPerPage}
                                                                        totalPage={totalPage}
                                                                        page={page}
                                                                        setPage={setPage}
                                                                        setItemPerPage={setItemPerPage}
                                                                        itemPerPageSelection={itemPerPageSelection}
                                                                        orderBy={orderBy}
                                                                        setOrderBy={setOrderBy}
                                                                        orderBySelection={orderByList}
                                                                        isDescActive={descending}
                                                                        setIsDescActive={setDescending}
                                                                        setIsSearched={setIsSearched}
                                                                    />
                                                                    <Loading
                                                                        loading={loading}
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    }>
                                                </ContainerBox>
                                            </div>
                                        </Tab>



                                    </Tabs>

                                </div>


                            </div>
                            {
                                (cookies.logingAs === "User" || cookies.extCustomerId === "null") &&
                                <ButtonForm addData={() => {
                                    saveData();
                                    setDisabledButton(true);
                                    setLoading(true);
                                }}
                                saveButton={`${t("detail_company.save_button")}`}
                                cancelButton={`${t("detail_company.cancel_button")}`}
                                 path="/Company" button={disabledButton === true ? disabledButton : !updatedButton} />
                            }

                        </div>


                    }>


                </ContainerBox >
                <Modal size="xl" show={companyContactModal} onHide={
                    () => {
                        setCompanyContactModal(false);
                        clearCompanyContactModal();
                    }
                }>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("detail_company.container_contact_detail")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                padding: 10,
                                flex: 1,
                                flexWrap: "nowrap"
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButtonModal(true);
                                    saveContact();

                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <div style={{ flex: 3 }}>
                                                <Form.Group style={{
                                                    width: "100%",
                                                    paddingRight: 10,
                                                }} className="mb-3">
                                                    <Form.Label>{t("detail_company.field_title")}</Form.Label>
                                                    <Form.Select onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            setCompanyContact({ ...companyContact, title: null });
                                                        } else {
                                                            setCompanyContact({ ...companyContact, title: e.target.value });
                                                        }

                                                    }} value={companyContact.title} placeholder="" required>\
                                                        <option value=""></option>
                                                        {
                                                            listTitle.map((title, index) => {
                                                                return (
                                                                    <option key={index} value={title.id}>{title.title_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div style={{ flex: 10 }}>
                                                <Form.Group style={{
                                                    width: "100%",
                                                    paddingLeft: 5,
                                                }} className="mb-3">
                                                    <Form.Label>{t("detail_company.field_contact_name")}</Form.Label>
                                                    <Form.Control type="text" onChange={(e) => {
                                                        setCompanyContact({ ...companyContact, contact_name: e.target.value });
                                                    }} value={companyContact.contact_name} placeholder="" required></Form.Control>
                                                </Form.Group>
                                            </div>


                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 10
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_company.field_contact_phone_number1")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                    } else {
                                                        setCompanyContact({ ...companyContact, phone_number1: e.target.value });
                                                    }

                                                }} value={companyContact.phone_number1} placeholder=""></Form.Control>
                                            </Form.Group>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_company.field_contact_phone_number2")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[0-9]{0,30}$/).test(e.target.value)) {

                                                    } else {
                                                        setCompanyContact({ ...companyContact, phone_number2: e.target.value });
                                                    }

                                                }} value={companyContact.phone_number2} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_company.field_contact_email")}</Form.Label>
                                                <Form.Control type="email" onChange={(e) => {
                                                    setCompanyContact({ ...companyContact, email: e.target.value });
                                                }} value={companyContact.email} placeholder=""></Form.Control>                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_company.field_contact_explanation")}</Form.Label>
                                                <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                    setCompanyContact({ ...companyContact, description: e.target.value });
                                                }} value={companyContact.description} placeholder=""></Form.Control>                                            </Form.Group>
                                        </div>

                                        {
                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>:
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5,
                                                justifyContent: "center"
                                            }}>
                                                <Button style={{ width: 100 }} disabled={disabledButtonModal === true ? disabledButtonModal : !updatedButton} type="submit" variant="primary">{t("detail_company.save_button")}</Button>
                                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                                    clearCompanyContactModal();
                                                    setCompanyContactModal(false);
                                                }}>{t("detail_company.cancel_button")}</Button>
                                            </div>

                                        }
                                     
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal>
            </Container >
            <Loading
                loading={loading}
            />
        </>
    )
}