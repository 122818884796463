import "./BarGraph.css";
import ReloadPict from "../../Assets/Images/reload-pict.png";

export default function BarGraph({ text, valueBG, wireBreak = false, shortError = false }) {
    return (
        <div className="barGraphPanel">
            <div className="contentBarGraph">
                <div className="firstRow">
                    {/* <div className="titleBarGraph">
                        {text}
                    </div> */}
                    {/* <div>
                        <img src={ReloadPict} alt="" />
                    </div> */}
                </div>
                <div className="secondRow">
                    <div className="xBarGraph">
                        <div className="valuexBarGraph" style={{ width: `${valueBG}%` }}>
                            <div className="txtXBarGraph">{valueBG}%</div>
                        </div>
                    </div>
                    {/* <div className="smallBtn">
                        <div className={wireBreak ? "btnBGTrue" : "btnBGFalse"}>
                            Wire Break
                        </div>
                        <div className={shortError ? "btnBGTrue" : "btnBGFalse"}>
                            Short/Error
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}