import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, convertBase64} from "../../../../../Helpers/ApplicationHelpers";
import { getInventoryPhotoByCount, getInventoryPhotoById, insertUpdateInventoryPhoto, deleteInventoryPhoto } from "../../../../../Helpers/InventoryHelpers";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { Trash, FilePlusFill, InfoCircleFill, FileCheckFill, GearWideConnected, ArrowLeft } from "react-bootstrap-icons";
import Navbar from "../../../../../Components/NavBar";
import Paginations from "../../../../../Components/Pagination";
import Modals from "../../../../../Components/modal";
import Loading from "../../../../../Components/Loading";
import ContainerBox from "../../../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";
import { omit } from 'lodash';
import moment from "moment";

export default function InventoryPhotoPage () {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    // const [disabledCustomerForm, setDisabledCustomerForm] = useState(true);
    const [deletedButton, setDeletedButton] = useState("");
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const handleShow = () => setShow(true);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 20, 40]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_photo.order_name1")}`,
        value: "created_date"
    });
    const orderBySelection = [
        {
            label: `${t("index_inventory_photo.order_name1")}`,
            value: "created_date"
        }
    ]
    const [errors, setErrors] = useState([]);
    const [listInventoryPhoto, setListInventoryPhoto] = useState([]);
    const [inventoryPhotosId, setInventoryPhotosId] = useState("");
    const [inventoryPhotosName, setInventoryPhotosName] = useState("");
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [inventoryPhotoCount, setInventoryPhotoCount] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [inventoryPhoto, setInventoryPhoto] = useState({
        id: 0,
        base64_image: "",
        label: "",
        is_active: true,
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        inventory_id: "",
        inventory_master_name: ""
    })
    const [fileImg, setFileImg] = useState("");
    const [coverId, setCoverId] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                if (location.state.inventoryId) {
                    loadInventoryPhotos();
                }
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     console.log(inventoryPhoto);
    // }, [inventoryPhoto])

    useEffect(() => {
        if (inventoryPhotosId !== ""){
            removeInventoryPhoto();
        }
    }, [inventoryPhotosId])

    useEffect(() => {
        validationForm();
    }, [inventoryPhoto])

    const loadInventoryPhotos = async () => {
        // setLoading(true);
        // setDisabledButton(true);
        let inventoryPhotoCount = await getInventoryPhotoByCount(cookies.token, location.state.inventoryId);
        setInventoryPhotoCount(inventoryPhotoCount);
        let totalPage = inventoryPhotoCount / itemPerPage;
        setTotalPage(totalPage);

        let response = await getInventoryPhotoById(cookies.token, location.state.inventoryId, page, itemPerPage, orderBy.value);
        console.log(response);
        setListInventoryPhoto(response);
        // if (response) {
        //     setInventoryPhoto({
        //         id: response[0].id,
        //         base64_image: response.base64_image,
        //         label: response.label,
        //         is_active: response.is_active,
        //         created_date: response.created_date,
        //         created_by: response.created_by,
        //         updated_date: response.updated_date,
        //         updated_by: response.updated_by,
        //         inventory_id: response.inventory_id,
        //         inventory_master_name: response.inventory_master_name
        //     });
        // }
        
        setIsSearched(0);
        // setLoading(false);
        // setDisabledButton(false);
    }

    const addPhoto = async () => {
        try {
            // setDisabledButton(true);
            let inventoryPhotoTmp = inventoryPhoto;
            inventoryPhotoTmp.created_by = cookies.userId;
            inventoryPhotoTmp.updated_by = cookies.userId;
            inventoryPhotoTmp.inventory_id = location.state.inventoryId;
            console.log(inventoryPhotoTmp);

            let response = await insertUpdateInventoryPhoto(cookies.token, inventoryPhotoTmp);
            if (response === 0) {
                alert(`${t("index_inventory_photo.alert_success_save_data")}`);
                handleClose();
                loadInventoryPhotos();
            }
            else {
                alert(`${t("index_inventory_photo.alert_failed_save_data")}`);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeInventoryPhoto = async () => {
        let response = await deleteInventoryPhoto(cookies.token, inventoryPhotosId);
        if (response === 0) {
            // window.location.reload();
            loadInventoryPhotos();
        }
    }

    const handleClick = (item, index) =>{
        setCurrentIndex(index);
        setClickedImg(item.base64_image);
    }

    const handelRotationRight = () => {
        const totalLength = listInventoryPhoto.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listInventoryPhoto[0].base64_image;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listInventoryPhoto.filter((item) => {
            return listInventoryPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        console.log('right clicked');
    };

    const handelRotationLeft = () => {
        const totalLength = listInventoryPhoto.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listInventoryPhoto[totalLength - 1].base64_image;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listInventoryPhoto.filter((item) => {
            return listInventoryPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        console.log('left clicked');
    };

    const validationForm = async () => {
        try{
            if (!inventoryPhoto.base64_image){
                setUploadFileImageError("*required .png or .jpeg file")
                setDisabledButton(true);
            } else if (!inventoryPhoto.label){
                setErrors({
                    ...errors,
                    message: `${t("detail_inventory_master.error_empty_field")}`});
                setDisabledButton(true);
            } else if (!inventoryPhoto.created_date){
                setErrors({
                    ...errors,
                    message: `${t("detail_inventory_master.error_empty_field")}`});
                setDisabledButton(true);
            }
            else {
                let errorMsg = omit(errors, "message", "divisionError");
                setErrors(errorMsg);
                setDisabledButton(false);
            }
        } catch(exception){
            console.log(exception);
        }
    }

    const handleResets = async () => {

        setInventoryPhoto({
            ... inventoryPhoto,
            id: 0,
            base64_image: "",
            label: "",
            is_active: true,
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            inventory_id: "",
            inventory_master_name: ""
        });

        setFileImg("");

    }

    return(
        <>
         <Navbar />
            <Container>
                <ContainerBox
                    titleCaption={location.state.inventoryName}
                    useActionContainer={true}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/InventoryMaster/Detail", {state: {inventoryMasterId: location.state.inventoryId}}); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    actionContainerChild={
                        <div>
                            <Button disabled={false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setFileImg("");
                                handleShow();
                                setDisabledButton(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_photo.button_add_photo")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{padding: 10}}>
                            <Col md={12}>
                                <Row>
                                    {
                                        listInventoryPhoto.map((inventory, index)=> {
                                            return(
                                                <Col md={3} key={index}>
                                        <div key={index} style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flexWrap: "nowrap",
                                            flexDirection: "column",
                                            padding: 10,
                                            borderWidth: 1,
                                            margin: 5,
                                            borderStyle: "solid",
                                            borderColor: "#2f71bd",
                                            borderRadius: 5,
                                            height: 400,

                                        }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 5,
                                            alignContent: "flex-start",
                                            alignItems: "flex-start",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flexWrap: "wrap",

                                        }}>
                                            <img src={inventory.base64_image} onClick={() => {
                                                handleClick(inventory, index);
                                            }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            alignSelf: "flex-end",
                                            flexWrap: "nowrap",
                                            flexDirection: "row",
                                            width: "100%",
                                        }} >
                                        <div style={{
                                            flex: 1,
                                            flexDirection: "column",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            alignSelf: "flex-end",
                                            textAlign: "center"
                                        }}><p>{!inventory.label ? "" : inventory.label}</p></div>
                                        <div style={{
                                            flex: 1,
                                            flexDirection: "column",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            alignSelf: "flex-end",
                                            textAlign: "center"
                                        }}><p>{!inventory.created_date ? "" : moment(inventory.created_date).format("DD-MM-yyyy")}</p>
                                        <div style={{
                                            flex: 1,
                                            flexDirection: "column",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            alignSelf: "flex-end",
                                        }}>
                                            <Button disabled={false} onClick={() => {
                                                if (window.confirm(`${t("index_inventory_photo.delete_confirmation")}`)) {
                                                    let inventoryIdTmp = inventory.id
                                                    setInventoryPhotosId(inventoryIdTmp);
                                                }
                                            }} variant="danger"><Trash /></Button>
                                        </div>
                                    </div>

                                    </div>

                                    </div>
                                    </Col>
                                    )
                                    })
                                    }
                                </Row>
                            </Col>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderBySelection}
                                setIsSearched={setIsSearched}
                            />

                            <Loading loading={loading}/>
                        </div>
                    }
                />
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>{t("index_inventory_photo.modal_add_photo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleResets();
                        addPhoto();
                    }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_inventory_photo")}</Form.Label>
                            <Form.Control onChange={async (e) => {
                                if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                    setFileImg(e.target.value)
                                    let base64Img = await convertBase64(e.target.files[0]);
                                    const base64ImgString = base64Img.toString();
                                    setInventoryPhoto({ ...inventoryPhoto, base64_image: base64ImgString});
                                    setUploadFileImageError("");
                                    setDisabledButton(false)

                                } else {
                                    setFileImg("")
                                    setInventoryPhoto({ ...inventoryPhoto, base64_image: ""});
                                    setUploadFileImageError(`${t("index_inventory_photo.error_image_validation")}`);
                                    setDisabledButton(true)
                                }



                            }} type="file" value={fileImg} required></Form.Control>
                            {
                                uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_photo_label")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryPhoto({ ...inventoryPhoto, label: e.target.value })

                            }} value={inventoryPhoto.label} type="text" placeholder="" required></Form.Control>
                        {
                            errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                        }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_taken_date")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryPhoto({ ...inventoryPhoto, created_date: e.target.value })
                            }} value={inventoryPhoto.created_date} type="date" placeholder="" required></Form.Control>
                        {
                            errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                        }
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                // setVesselPhoto({ ...vesselPhoto, description: e.target.value })
                            }} value={'desc'} as="textarea" rows={3} placeholder=""></Form.Control>
                        </Form.Group> */}
                        <Button style={{width:100}} variant="primary" disabled={disabledButton} type="submit">
                        {t("index_inventory_photo.save_button")}
                        </Button>
                        <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                            handleResets();
                            handleClose();
                        }}>
                            {t("index_inventory_photo.cancel_button")}
                        </Button>
                    </Form>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            {clickedImg && (
                <Modals
                    clickedImg={clickedImg}
                    handelRotationRight={handelRotationRight}
                    setClickedImg={setClickedImg}
                    handelRotationLeft={handelRotationLeft}
                    image={listInventoryPhoto}
                />
            )}
        </>
    )
}