import "./index.css";
import Navbar from "../../Components/NavBar";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isTokenValid } from "../../Helpers/ApplicationHelpers";
import { Button, Offcanvas, Form, Accordion} from 'react-bootstrap';
import DropDownForm from "../../Components/DropDownForm";
import DynamicLineChart from "../../Components/DynamicLineChart";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import MainEngineHistory from "./MainEngineHistory";
import WatertigthDoorHistory from "./WatertightDoorHistory";
import HistoryChartHandler from "./HistoryChartHandler";



export default function DataHistoryChart() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [chartToShow, setChartToShow] = useState();
    const [display, setDisplay] = useState();
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [titleBar, setTitleBar] = useState("History Chart");
    const [showContent, setShowContent] = useState();
    const [activeTab, setActiveTab] = useState();

    useEffect(() => {
        async function checkCookies() {
          let isAuthenticated = await isTokenValid(cookies.token);
          if (!isAuthenticated) navigate("/");
          else {
            if (location.state.smsSerialNumber) {
              setSmsSerialNumber(location.state.smsSerialNumber);
            } else {
              navigate("/");
            }
          }
        }
        checkCookies();
      }, []);


    const handleOnClick = (val) => {
        console.log("click");
        // setActiveTab(val);
        switch (val) {
            case 0:
                setShowContent(<MainEngineHistory smsSerialNumber={location.state.smsSerialNumber}/>);
                // navigate("/DataHistoryChart")
                break;
            case 1:
                setShowContent();
                break;
            case 2:
                setShowContent();
                break;
            case 3:
                setShowContent();
                break;
            case 4:
                setShowContent(<WatertigthDoorHistory smsSerialNumber={location.state.smsSerialNumber}/>);
                break;
            default:
                return(null);
                break;

        }
    }

    return(
        <>
            <Navbar/>
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar
                        text={titleBar}
                        smsSerialNumber={location.state.smsSerialNumber}
                    />
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{border: '1px black solid', borderRadius: '5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', flex: 1, margin: '5px', display: 'flex', flexDirection: 'column'}}>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}} onClick={() => {handleOnClick(0)}}>Main Engine</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}}>Aux Engine</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}}>Pump and Motor</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}}>Tank Level</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}} onClick={() => {handleOnClick(4)}}>Watertight Door</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}}>Main Switchboard</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}}>Nav Signal Light</Button>
                            <Button variant="outline-light" style={{border: 'none', textAlign: 'left'}}>Alarm</Button>
                        </div>
                        <div style={{border: '1px black solid', borderRadius: '5px', backgroundColor: 'rgba(233, 233, 250, 0.1)', flex: 5, margin: '5px', display: 'flex', flexDirection: 'column'}}>
                            <HistoryChartHandler 
                                smsSerialNumber={location.state.smsSerialNumber} 
                                childContent={showContent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} 
