import { Button, NavbarBrand } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { isTokenValid } from '../../Helpers/ApplicationHelpers';
// import { getAuthorizedMenu } from '../Helpers/AccountHelpers';
import { Trans, useTranslation } from "react-i18next";
import Dropdown from 'react-multilevel-dropdown';
import { HouseFill } from 'react-bootstrap-icons';
import RPILogo from "../../Assets/Images/logo-qpd (2).png";


export default function CustomerNavigatonBar() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const [companyLogo, setCompanyLogo] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        async function checkCookies() {
          let isAuthenticated = await isTokenValid(cookies.token);
          if (!isAuthenticated)
            navigate("/Customer/Login");
          else {
            if (cookies.language_code === "id-id") {
              i18n.changeLanguage("id");
            } else if (cookies.language_code === "en-us") {
              i18n.changeLanguage("en");
            }
            
          }
        }
        checkCookies();
      }, []);

    const logOut = async () => {
        try {
          // removeCookie("token");
          // navigate("/");
          removeCookie("token",{ path: "/" });
          if (cookies.logingAs === 'User') {
            navigate("/Customer/Login");
          } else {
            navigate("/");
          }
          // window.location.href = '/';
          // return false;
        } catch (exception) {
          console.log(exception);
        }
      }
    return(
        <>
            <Navbar bg="light" expand="lg" style={{ padding: '20px' }}>
                <Container style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div style={{ marginTop: '20px'  }}>
                        <img className="titleImg" style={{ height: '70px'}} src={RPILogo} />
                    </div>
                    <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => { navigate("/Customer/Dashboard") }}>
                        <div className="btnContainer">
                            <div><HouseFill size={32} /></div>
                        </div>
                    </Button>
                    <Dropdown
                        style={{ width: '270px', borderColor: 'white' }}
                        title='ARMADA'
                        position='right'
                    >
                        <Dropdown.Item >
                            COASTER/CARGOSHIPS
                        </Dropdown.Item>
                        <Dropdown.Item>
                            PASSENGER VESSEL/FERRIES
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Tug & Barges
                            <Dropdown.Submenu position='right'>
                                <Link to={'/Customer/GeneralSpecification'} target="_blank">
                                <Dropdown.Item>
                                    PRIME201
                                    <Dropdown.Item>
                                        <Dropdown.Submenu position='right'>
                                            <Dropdown.Item>General Specification</Dropdown.Item>
                                            <Dropdown.Item>Drawing</Dropdown.Item>
                                            <Dropdown.Item>Class Approved Drawing</Dropdown.Item>
                                            <Dropdown.Item>Kalibrasi Tangki</Dropdown.Item>
                                            <Dropdown.Item>DJP</Dropdown.Item>
                                            <Dropdown.Item>Gallery</Dropdown.Item>
                                            <Dropdown.Item>360 View</Dropdown.Item>

                                            <Dropdown.Item>Vessel Certification</Dropdown.Item>
                                            <Dropdown.Item>Class Document</Dropdown.Item>
                                            <Dropdown.Item>SEACOM</Dropdown.Item>
                                            <Dropdown.Item>Equipment</Dropdown.Item>
                                        </Dropdown.Submenu>
                                    </Dropdown.Item>
                                </Dropdown.Item>
                                </Link>
                            </Dropdown.Submenu>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            TANKERS
                        </Dropdown.Item>
                        <Dropdown.Item>
                            CREW SUPPLY VESSEL
                        </Dropdown.Item>
                        <Dropdown.Item>
                            PATROL BOATS
                        </Dropdown.Item>
                        <Dropdown.Item>
                            DREDGERS
                        </Dropdown.Item>
                        <Dropdown.Item>
                            SPECIAL PURPOSE VESSEL
                        </Dropdown.Item>
                        <Dropdown.Item>
                            EXPERIENCE LIST
                        </Dropdown.Item>
                    </Dropdown>
                    
                    <Dropdown
                        style={{width: '270px', borderColor: 'white'}}
                        title='SMS'
                        position='right'
                    >
                        <Dropdown.Item>
                            Tug & Barges
                            <Dropdown.Submenu position='right'>
                                <Dropdown.Item>
                                    PRIME201
                                    <Dropdown.Item>
                                        <Dropdown.Submenu position='right'>
                                            <Link to={'/Customer/SMSDashboard'} target="_blank">
                                                <Dropdown.Item>Dashboard</Dropdown.Item>
                                            </Link>
                                        </Dropdown.Submenu>
                                    </Dropdown.Item>
                                </Dropdown.Item>
                            </Dropdown.Submenu>
                        </Dropdown.Item>
                        <Link to={'/Customer/SMS'} target="_blank">
                            <Dropdown.Item>
                                View All
                            </Dropdown.Item>
                        </Link>
                    </Dropdown>

                    <Dropdown
                        style={{width: '270px', borderColor: 'white'}}
                        title='MAINTENANCE'
                        position='right'
                    >
                        <Dropdown.Item>
                            Tug & Barges
                            <Dropdown.Submenu position='right'>
                                <Dropdown.Item >
                                    PRIME201
                                    <Dropdown.Item>
                                        <Dropdown.Submenu position='right'>
                                            <Dropdown.Item>Docking Record</Dropdown.Item>
                                        </Dropdown.Submenu>
                                    </Dropdown.Item>
                                </Dropdown.Item>
                            </Dropdown.Submenu>
                        </Dropdown.Item>
                    </Dropdown>

                    <Dropdown
                        style={{ width: '270px', borderColor: 'white' }}
                        title='CREW'
                        position='right'
                    >
                        <Dropdown.Item>
                            Tug & Barges
                            <Dropdown.Submenu position='right'>
                                <Dropdown.Item>
                                    PRIME201
                                    <Dropdown.Item>
                                        <Dropdown.Submenu position='right'>
                                            <Dropdown.Item>Crew</Dropdown.Item>
                                            <Dropdown.Item>Master</Dropdown.Item>
                                            <Dropdown.Item>Permanent (Ijazah)</Dropdown.Item>
                                            <Dropdown.Item>Non Permanent (Bast)</Dropdown.Item>
                                            <Dropdown.Item>Chief Officer</Dropdown.Item>
                                        </Dropdown.Submenu>
                                    </Dropdown.Item>
                                </Dropdown.Item>
                            </Dropdown.Submenu>
                        </Dropdown.Item>
                    </Dropdown>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            {t("navbar_item.sign_in_as")}: <a href="#">{cookies.userName}</a>
                            {/* <span><FontAw esomeIcon icon={faCoffee} /></span> */}

                        </Navbar.Text>
                        {
                            companyLogo === "" || companyLogo === null ?
                                <img height={20} src={require("../../Assets/Images/logo-qpd (2).png")} />
                                :
                                // <img height={20} src={companyLogo} />
                                <img height={20} src={require("../../Assets/Images/logo-qpd (2).png")} />

                        }

                        <NavDropdown className="navbar-caption">
                            <NavDropdown.Item cl="navbar-text" href="#" onClick={event => {
                                navigate("/User/Detail", { state: { userId: cookies.userId, page: "ubahProfil" } });
                            }} >{t("navbar_item.change_profil")}</NavDropdown.Item>
                            <NavDropdown.Item cl="navbar-text" href="#" onClick={event => {
                                navigate("/LanguageSetting", { state: { userId: cookies.userId } });
                            }} >{t("navbar_item.language")}</NavDropdown.Item>

                            <NavDropdown.Item class="navbar-text" href="#" onClick={event => {
                                navigate("/ChangePassword", { state: { staffId: cookies.staffId } });
                            }}>{t("navbar_item.change_password")}</NavDropdown.Item>
                            <NavDropdown.Item class="navbar-text" href="#" onClick={event => {
                                logOut();
                            }} >{t("navbar_item.log_out")}</NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </>
    )
}