import './index.css'
import RPILogo from "../../Assets/Images/quantum_logo.png";
import { Button } from "react-bootstrap";
import React from 'react';
import { useCookies } from "react-cookie";
import { useNavigate, NavLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import { isTokenValid } from '../../Helpers/ApplicationHelpers';
import { useTranslation } from "react-i18next";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { List, X } from "react-bootstrap-icons";
import Clock from 'react-live-clock';

export default function Sidebar(props) {
    const { companyName, smsSerialNumber, vesselId, vesselName, customerName } = props;
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                i18n.changeLanguage(cookies.language_code === "id-id" ? "id" : "en");
            }
        }
        checkCookies();
    }, [cookies.token, cookies.language_code, i18n, navigate]);

    const logOut = async () => {
        try {
            removeCookie("token", { path: "/" });
            navigate(cookies.logingAs === 'User' ? "/Customer/Login" : "/");
        } catch (exception) {
            console.log(exception);
        }
    }

    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenParent, setIsOpenParent] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow(prev => !prev);

    const checkStatus = () => setIsOpen(prev => !prev);
    const checkStatusParent = () => setIsOpenParent(prev => !prev);

    return (
        <div style={{ marginTop: '35px' }}>
            <Button onClick={toggleShow} style={{ height: '50px', width: '20px', padding: '5px', backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                <List size={10} />
            </Button>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header style={{ backgroundColor: 'rgba(3, 30, 103, 1)' }}>
                    <NavLink exact to="/Customer/Dashboard" activeClassName="activeClicked">
                        <img className="titleImg" style={{ height: '70px' }} src={RPILogo} alt="Logo" />
                    </NavLink>
                    <Offcanvas.Title style={{ fontSize: '15px', color: '#f0f0f0' }}>
                        {String(companyName).toUpperCase().replace(/'/g, "")}<br />
                        Welcome, {customerName}<br />
                        <Clock format={'dddd, DD MMMM YYYY, hh:mm:ss'} ticking={true} timezone={'Asia/Jakarta'} />
                        <Button onClick={logOut} style={{ color: 'white', width: '30%' }}>Logout</Button>
                    </Offcanvas.Title>
                    <Button onClick={handleClose} style={{ height: '50px', width: '50px', padding: '5px', borderColor: 'rgba(3, 30, 103, 1)' }}>
                        <X size={32} />
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Accordion defaultActiveKey="0" onClick={checkStatus}>
                        <Accordion.Item>
                            <Accordion.Header>
                                <NavLink exact to="/Customer/VesselLocation" activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                    Vessel Location
                                </NavLink>
                            </Accordion.Header>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey="0" onClick={checkStatus}>
                        <Accordion.Item>
                            <Accordion.Header>TUG & BARGES</Accordion.Header>
                            <Accordion.Body></Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {isOpen && (
                      <>
                        <Accordion defaultActiveKey="0" onClick={checkStatusParent}>
                            <Accordion.Item>
                                <Accordion.Header>
                                    <NavLink exact to="/Customer/GeneralSpecification" activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                        PRIME201
                                    </NavLink>
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                      </>
                    )}
                    {isOpenParent && (
                        <>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                    <Accordion.Header className="no-arrow">
                                        <NavLink exact to={`/Customer/SMSDashboard?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                            <div style={{ width: '100%', border: '1px rgba(219, 235, 255, 0.9) solid', margin: '5px', padding: '10px' }}>Smart Monitoring System</div>
                                        </NavLink>
                                    </Accordion.Header>
                                    <Accordion.Body></Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                    <Accordion.Header>Planned Maintenance System</Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink exact to={`/Customer/VesselEquipment?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                            <div style={{ width: '100%', border: '1px rgba(219, 235, 255, 0.9) solid', margin: '5px', padding: '10px' }}>Vessel Equipment</div>
                                        </NavLink>
                                        <NavLink exact to={`/Customer/MaintenancePlan?smsSerialnumber=${smsSerialNumber}&customerName=${companyName}&vesselName=${vesselName}&vesselId=${vesselId}`} activeClassName="activeClicked" style={{ textDecoration: 'none' }}>
                                            <div style={{ width: '100%', border: '1px rgba(219, 235, 255, 0.9) solid', margin: '5px', padding: '10px' }}>Maintenance</div>
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    )}
                    {isOpen && (
                      <Accordion defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header>
                                    {/* <NavLink exact to="" activeClassName="activeClicked" style={{ textDecoration: 'none' }}> */}
                                        TS 03
                                    {/* </NavLink> */}
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}
                    {isOpen && (
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header>
                                    {/* <NavLink exact to="" activeClassName="activeClicked" style={{ textDecoration: 'none' }}> */}
                                        TS 04
                                    {/* </NavLink> */}
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};