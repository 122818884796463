import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table, FormControl, FormLabel } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, insertUpdatePortalContent, getPortalContentById, convertBase64, getUserAccessById, convertBase64Image } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import moment from "moment";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import { Trans, useTranslation } from "react-i18next";

export default function DetailPortalContentPage() {
    const { t, i18n } = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [isPublishChecked, setIsPublishChecked] = useState(false);
    const [content, setContent] = useState({
        id: 0,
        content_title: "",
        start_publish_date: null,
        end_publish_date: "",
        content_description: "",
        content_date: null,
        content_photo: ""
        // publish_status: ""
    })
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadAccess();
            }
        }
        checkCookies();
        if (location.state.contentId === 0) {
            setLoading(false);
        } else {
            initContent();
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [content]);



    const saveData = async () => {
        try {
            let response = await insertUpdatePortalContent(cookies.token, content, isPublishChecked);
            if (response === 0) {
                alert(`${t("detail_content.alert_success_save_data")}`);
                navigate("/content")
            } else {
                alert(`${t("detail_content.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const initContent = async () => {
        try {
            let response = await getPortalContentById(cookies.token, location.state.contentId);
            if (response) {
                setContent({
                    ...content,
                    id: response.id,
                    content_title: response.content_title,
                    start_publish_date: response.start_publish_date,
                    end_publish_date: response.end_publish_date,
                    content_description: response.content_description,
                    content_date: response.content_date,
                    publish_status: response.publish_status,
                    content_photo: response.content_photo
                })
                setIsPublishChecked(response.publish_status)
                setLoading(false);
            } else {
                alert(`${t("detail_content.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (content.content_title === undefined || content.content_title === "") {
                    setErrors({
                        ...errors,
                        contentTitle: `${t("detail_content.error_empty_field")}`
                    },
                        setDisabledButton(true))
                } else if (content.content_description === undefined || content.content_description === "") {
                    setErrors({
                        ...errors,
                        contentDescription: `${t("detail_content.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "contentTitle", "contentDescription");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated)
            }
            setUpdatedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_content.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Content"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_content.header_content")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>


                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                    setDisabledButton(true);
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_content.field_start_publication_date")}</Form.Label>
                                                        <Form.Control required onChange={(e) => {
                                                            if (e.target.value !== "") {
                                                                setContent({ ...content, start_publish_date: e.target.value })
                                                            } else {
                                                                setContent({ ...content, start_publish_date: null, end_publish_date: null })
                                                            }
                                                        }} type="date" value={content.start_publish_date ? moment(content.start_publish_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_content.field_end_publication_date")}</Form.Label>
                                                        <Form.Control required disabled={!content.start_publish_date} onChange={(e) => {
                                                            if (e.target.value !== "") {
                                                                if (moment(e.target.value).format("yyyy-MM-DD") < moment(content.start_publish_date).format("yyyy-MM-DD")) {
                                                                    setContent({ ...content, end_publish_date: null })
                                                                    setErrorMessage(`${t("detail_content.error_end_date")}`)
                                                                } else {
                                                                    setContent({ ...content, end_publish_date: e.target.value })
                                                                    setErrorMessage("");
                                                                }

                                                            } else {
                                                                setErrorMessage("");
                                                                setContent({ ...content, end_publish_date: null })
                                                            }
                                                        }} type="date" value={content.end_publish_date ? moment(content.end_publish_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                    </Form.Group>
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_content.field_content_date")}</Form.Label>
                                                        <Form.Control required onChange={(e) => {
                                                            if (e.target.value !== "") {
                                                                setContent({ ...content, content_date: e.target.value })
                                                            } else {
                                                                setContent({ ...content, content_date: null })
                                                            }
                                                        }} type="date" value={content.content_date ? moment(content.content_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_content.field_content_image")}</Form.Label>
                                                        <Form.Control onChange={async (e) => {
                                                            let base64Img = await convertBase64Image(e.target.files[0]);
                                                            const base64ImgString = base64Img.toString();
                                                            setContent({ ...content, content_photo: base64ImgString });
                                                        }} type="file"></Form.Control>
                                                    </Form.Group>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        justifyContent: "center"
                                                    }}>
                                                        <img src={content.content_photo} width={"20%"} />
                                                    </div>


                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_content.field_content_title")}</Form.Label>
                                                        <Editor
                                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                            onEditorChange={(evt, editor) => {
                                                                setContent({ ...content, content_title: evt })


                                                            }}
                                                            value={content.content_title}
                                                            init={{
                                                                height: 500,
                                                                menubar: false,
                                                                plugins: [
                                                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                ],
                                                                toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                image_title: true,
                                                                automatic_uploads: true,
                                                                /*
                                                                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                  images_upload_url: 'postAcceptor.php',
                                                                  here we add custom filepicker only to Image dialog
                                                                */
                                                                file_picker_types: 'image',
                                                                /* and here's our custom image picker*/
                                                                file_picker_callback: (cb, value, meta) => {
                                                                    const input = document.createElement('input');
                                                                    input.setAttribute('type', 'file');
                                                                    input.setAttribute('accept', 'image/*');

                                                                    input.addEventListener('change', (e) => {
                                                                        const file = e.target.files[0];
                                                                        const reader = new FileReader();
                                                                        reader.addEventListener('load', () => {
                                                                            /*
                                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                                              registry. In the next release this part hopefully won't be
                                                                              necessary, as we are looking to handle it internally.
                                                                            */

                                                                            // console.log(editorRef.current.getContent())
                                                                            const id = 'blobid' + (new Date()).getTime();
                                                                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                            //   editorRef.current.editorUpload.blobCache

                                                                            //Editor.activeEditor.editorUpload.blobCache;

                                                                            const base64 = reader.result.split(',')[1];
                                                                            const blobInfo = blobCache.create(id, file, base64);
                                                                            blobCache.add(blobInfo);

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                                        });
                                                                        reader.readAsDataURL(file);
                                                                    });

                                                                    input.click();
                                                                },
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    {
                                                        errors.contentTitle && <p style={{ color: "red" }}>{errors.contentTitle}</p>
                                                    }
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_content.field_content")}</Form.Label>
                                                        <Editor
                                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                            onEditorChange={(evt, editor) => {
                                                                setContent({ ...content, content_description: evt })


                                                            }}
                                                            value={content.content_description}
                                                            init={{
                                                                height: 500,
                                                                menubar: false,
                                                                plugins: [
                                                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                ],
                                                                toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                image_title: true,
                                                                automatic_uploads: true,
                                                                /*
                                                                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                  images_upload_url: 'postAcceptor.php',
                                                                  here we add custom filepicker only to Image dialog
                                                                */
                                                                file_picker_types: 'image',
                                                                /* and here's our custom image picker*/
                                                                file_picker_callback: (cb, value, meta) => {
                                                                    const input = document.createElement('input');
                                                                    input.setAttribute('type', 'file');
                                                                    input.setAttribute('accept', 'image/*');

                                                                    input.addEventListener('change', (e) => {
                                                                        const file = e.target.files[0];
                                                                        const reader = new FileReader();
                                                                        reader.addEventListener('load', () => {
                                                                            /*
                                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                                              registry. In the next release this part hopefully won't be
                                                                              necessary, as we are looking to handle it internally.
                                                                            */

                                                                            // console.log(editorRef.current.getContent())
                                                                            const id = 'blobid' + (new Date()).getTime();
                                                                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                            //   editorRef.current.editorUpload.blobCache

                                                                            //Editor.activeEditor.editorUpload.blobCache;

                                                                            const base64 = reader.result.split(',')[1];
                                                                            const blobInfo = blobCache.create(id, file, base64);
                                                                            blobCache.add(blobInfo);

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                                        });
                                                                        reader.readAsDataURL(file);
                                                                    });

                                                                    input.click();
                                                                },
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    {
                                                        errors.contentDescription && <p style={{ color: "red" }}>{errors.contentDescription}</p>
                                                    }

                                                    {
                                                        location.state.contentId === 0 &&
                                                        <Form.Group className="mb-3">

                                                            <Form.Check onClick={(e) => {
                                                                if (e.target.checked) {
                                                                    setIsPublishChecked(true);
                                                                    setContent({ ...content, publish_status: true })
                                                                } else {
                                                                    setIsPublishChecked(false);
                                                                    setContent({ ...content, publish_status: false })
                                                                }
                                                            }} label={`${t("detail_content.checkbox_publish")}`} defaultChecked={content.publish_status} >
                                                            </Form.Check>
                                                        </Form.Group>

                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "row",
                                                        padding: 5,
                                                        justifyContent: "center"
                                                    }}>
                                                        <div style={{
                                                            paddingRight: 5,
                                                        }}><Button style={{width:100}} disabled={disabledButton} type="submit" variant="primary" >{t("detail_content.save_button")}</Button></div>


                                                        <div style={{
                                                            paddingLeft: 5,
                                                        }}>
                                                            <Button style={{width:100}} variant="danger" onClick={() => {
                                                                navigate('/Content');
                                                            }}>{t("detail_content.cancel_button")}</Button>

                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>

                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}