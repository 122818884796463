import "./index.css";
import BitLamp from "../../../Components/NewBitLamp/NewBitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import PictWheelHouse from "../../../Assets/Images/wheelHouse.png";
// import { Col, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
import moment from "moment";

export default function CustomerSMSFireAlarmWheelhousePage(props) {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(false);
    const { smsSerialNumber, vesselId} = props;
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([4]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [bitlampList, setBitlampList] = useState({

        M32:
        {
            text: "Bridge 1",
            alarm: true,
            coordinate: "M32",
            value: false
        },
        M34: {
            text: "MCP Wheelhouse 1",
            alarm: true,
            coordinate: "M34",
            value: false
        },
        M36: {
            text: "MCP Wheelhouse 2",
            alarm: true,
            coordinate: "M36",
            value: false
        }
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log(bitlampList)
    }, [bitlampList]);

    const loadCoordinate = async () => {
        try {
            setLoading(true);
            const response =  await getPLCReadingValue (cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
            // console.log(response);
            let receivedDate = response[0].read_date;
            setLastReceivedDate(receivedDate);
            let bitlampListTmp = bitlampList;
            for (let bl in bitlampList) {
                let find = response.find(p=>p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                if (find) {
                    bitlampListTmp[bl].value = find.reading_value;
                }
            }
            // console.log(bitlampListTmp);
            setBitlampList(bitlampListTmp);
            console.log(bitlampList)
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="containerBoxWheelhouse">
                <div className="containerImgWH">
                    <img src={PictWheelHouse} alt="" />
                </div>
                <div className="bitlampContainerWheelhouse">
                    <BitLamp text={'Bridge 1'} bitlampColor="black" alarm={bitlampList["M32"].value} />
                    <BitLamp text={'Manual Call Point Wheelhouse 1'} bitlampColor="black" alarm={bitlampList["M34"].value} />
                    <BitLamp text={'Manual Call Point Wheelhouse 2'} bitlampColor="black" alarm={bitlampList["M36"].value} />
                </div>
            </div>
        </>
    );
}