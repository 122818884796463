import React, { useState } from "react";
import "./index.css";
import mainVideo from "../../../Assets/Images/vid/mainvideo.mp4";
import { useNavigate } from "react-router-dom";

export default function CustomerSMSMainMenuPage() {
    // const [hasPlayed, setHasPlayed] = useState(false);
    // const navigate = useNavigate();

    // const handleVideoEnd = () => {
    //     setHasPlayed(true);
    //     console.log("Video has ended, running the function...");
    //     // Call the function you want to run here
        
    // };

    return (
        // <>
        //     <div className="mainPageEngine">
        //         <div className="containerBoxMenuBottom">
        //             {!hasPlayed && (
        //                 <video className="w-75" autoPlay muted onEnded={handleVideoEnd}>
        //                     <source src={mainVideo} type="video/mp4" />
        //                     Your browser does not support the video tag.
        //                 </video>
        //             )}
        //         </div>
        //     </div>
        // </>

        <>
            <div className="mainMenuEngineRes">
                <div>
                        {/* <div className="titleGaugeLeft">
                            <div style={{display: 'flex', width: '200px', justifyContent: 'space-between'}}>
                                <h1 className="txtforTitleOnMainEngineMainMenu">SHIP</h1>
                                <h1 className="txtforDetailOnMainEngineMainMenu">TB PRIME 201</h1>
                            </div>
                            <div style={{display: 'flex', width: '200px', justifyContent: 'space-between'}}>
                                <h1 className="txtforTitleOnMainEngineMainMenu">IMO</h1>
                                <h1 className="txtforDetailOnMainEngineMainMenu">XXXXXXX</h1>
                            </div>
                        </div> */}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
                <video className="w-75" autoPlay loop>
                    <source src={mainVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </div>
        </>
    );
}

