import "./index.css";
import { Container, Button, Form } from "react-bootstrap";
import { FileCheckFill, CameraFill, EyeFill, Search, ArrowClockwise, FilePlusFill } from "react-bootstrap-icons";
import ContainerBox from "../../Components/ContainerBox";
import { useEffect, useState } from "react";
import { getListSMSDevice, getListSMSDeviceCount, isTokenValid } from "../../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from 'moment';
import Clock from 'react-live-clock';
import Paginations from "../../Components/Pagination";
import Loading from "../../Components/Loading";
import CustomerNavigatonBar from "../../Components/CustomerNavBar";
import Sidebar from "../../Components/SideBar";


export default function CustomerSMS() {
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [serialNumber, setSerialNumber] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [isDescending, setIsDescending] = useState(true);
  const [listSMS, setListSMS] = useState();
  const [page, setPage] = useState(0);
  const [resetSearch, setResetSearch] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [totalPage, setTotalPage] = useState(0);
  const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
  const [loading, setLoading] = useState(true);
  const [refreshed, setRefreshed] = useState(false);
  const [orderBy, setOrderBy] = useState({
    label: "Commissioned Date",
    value: "commisioned_date"
  });
  const [isSearched, setIsSearched] = useState(0);

  const orderByList = [
    {
      label: "Serial Number",
      value: "smsDevice.sms_serial_number"
    },
    {
      label: "Vessel Name",
      value: "vessel.vessel_name"
    },
    {
      label: "Customer Name",
      value: "customer.customer_name"
    },
    {
      label: "Commissioned Date",
      value: "smsDevice.commisioned_date"
    }
  ]

  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token)
      if (!isAuthenticated) {
        navigate("/")
      }
      else {
        loadListSMSDevice();
      }
    }
    checkCookies();
  }, [])

  const loadListSMSDevice = async () => {
    try {
      let listSMSDeviceCount = await getListSMSDeviceCount(cookies.token, orderBy, searchQuery, isDescending);
      let totalPage = listSMSDeviceCount / itemPerPage;

      setTotalPage(totalPage);
      let response = await getListSMSDevice(cookies.token, page, itemPerPage, orderBy.value, isDescending, searchQuery);
      console.log(response)
      setListSMS(response)
      setResetSearch(0);
      setRefreshed(false);
      setLoading(false);
      setIsSearched(0);
      // let customerName = response.map( (a) => a.customer_name)
    }
    catch (exception) {
      console.log(exception);
    }
  }
  return (
    <>
      <div className="main">
        <Sidebar companyName={"PT.WHS Global Mandiri"} />
        <Container fluid className="mainContainerSMSD">
          <div className="welcomeUser">
            <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
              <h2 style={{ fontSize: '20px', color: 'white' }}>Welcome, {cookies.userName}</h2>
            </div>
            <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
              <Clock
                style={{ fontSize: '20px', color: 'white' }}
                format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                ticking={true}
                timezone={'Asia/Jakarta'} />
            </div>
          </div>
          <div className="childMainContainerSMSD">
            <ContainerBox
              titleCaption={'List SMS Device'}
              // useActionContainer={true}
              // actionContainerChild={
              //   <div>
              //     <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => { navigate("/") }}>
              //       <div className="btnContainer">
              //         <div className="btnContainerLeft"><FilePlusFill size={32} /></div>
              //         <div className="btnContainerRight">{'Add Data'}</div>
              //       </div>
              //     </Button>
              //   </div>
              // }
              childContent={
                <div className="master-table-inner-container">
                  <div className="master-table-searchbar-container">
                    <div className="master-table-searchbar-textbox">
                      <Form.Control
                        value={searchQuery}
                        type="text"
                        placeholder="Keywords (Name, Number)"
                        onChange={(e) => {
                          setSearchQuery(e.target.value)
                        }
                        }
                      />
                    </div>

                    <div className="master-table-searchbar-button">
                      <div style={{ paddingRight: 5 }}>
                        <Button onClick={async () => {
                          setPage(0);
                          await loadListSMSDevice();
                        }}>
                          <Search />
                        </Button>
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <Button onClick={async () => {
                          setSearchQuery("");
                          setRefreshed(true);
                        }}>
                          <ArrowClockwise />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="table-container">
                    <div className="table-header">
                      <div style={{ flex: 1 }} className="table-header-content">#</div>
                      <div style={{ flex: 2 }} className="table-header-content">Customer Name</div>
                      <div style={{ flex: 2 }} className="table-header-content">Serial Number</div>
                      <div style={{ flex: 2 }} className="table-header-content">Vessel Name</div>
                      <div style={{ flex: 2 }} className="table-header-content">Commissioned Date</div>
                      <div style={{ flex: 2 }} className="table-header-content">Dashboard</div>
                    </div>
                    {
                      listSMS && listSMS.map((data, index) => {
                        return (
                          <div key={index} className="table-body">
                            <div style={{ flex: 1 }} className="table-body-content">{index + 1}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{data.customer_name}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{data.sms_serial_number}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{data.vessel_name}</div>
                            <div style={{ flex: 2 }} className="table-body-content">{moment(data.commisioned_date).format("YYYY-MM-DD")}</div>
                            <div style={{ flex: 2 }} className="table-body-content">
                              <Link to={!data.is_sms_lite ? `/Customer/SMSDashboard?smsSerialnumber=${data.sms_serial_number}&customerName='${data.customer_name}'&vesselName='${data.vessel_name}&vesselId=${data.id}` : `/Customer/SMS`} target="_blank">
                                <Button style={{ marginLeft: '35%', marginRight: '35%' }} variant="primary" onClick={() => {
                                  // if (data.is_sms_lite) {
                                  //   navigate("/SMSVesselPosition", { state: { smsSerialnumber: data.sms_serial_number, customerName: data.customer_name, vesselName: data.vessel_name, vesselId: data.id } });
                                  // } else {
                                  //   navigate("/SMSDashboard", { state: { smsSerialnumber: data.sms_serial_number, customerName: data.customer_name, vesselName: data.vessel_name, vesselId: data.id } });
                                  // }

                                }}><EyeFill />
                                </Button>
                              </Link>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              }
            />
          </div>
        </Container>
      </div>

    </>
  )
}