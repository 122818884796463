import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import { getUserAccountxMenu, insertSystemMenuForUserAccount } from "../../../../Helpers/SystemHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import DeniReactTreeView from "deni-react-treeview";
import { Trans, useTranslation } from "react-i18next";
const themes = ['classic', 'metro', 'moonlight', 'purple', 'green', 'orange', 'red', 'silver']

export default function UserAccountXMenuDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [listSystemMenu, setListSystemMenu] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadSystemMenu();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                // setLoading(false);
            }
        }
        checkCookies();
    }, []);


    const treeviewRef = useRef(null);

    const loadSystemMenu = async () => {
        try {
            let response = await getUserAccountxMenu(cookies.token, cookies.languageId, location.state.userAccountId);

            setListSystemMenu(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-item-example">
                <Form.Check
                    type={"checkbox"}
                    id={item.id}
                    label={item.text}
                    //checked={item.selected}
                    defaultChecked={item.selected}
                    onChange={(e) => {
                        let list = listSystemMenu;
                        let selected = false;
                        if (e.target.checked) {
                            selected = true;
                        }
                        if (item.parent_id) {

                            let parentIdx = listSystemMenu.findIndex(p => p.id === item.parent_id);
                            let childIdx = listSystemMenu[parentIdx].children.findIndex(p => p.id === item.id);
                            if (list[parentIdx].selected !== true) {
                                list[parentIdx].selected = selected;
                            }

                            list[parentIdx].children[childIdx].selected = selected;
                        } else {
                            let parentIdx = listSystemMenu.findIndex(p => p.id === item.id);
                            if (parentIdx >= 0) {
                                list[parentIdx].selected = selected;
                            }

                        }
                        if(item.parent_menu_id === ""){
                            let parentIdx = listSystemMenu.findIndex(p => p.id === item.menu_id);
                            let aksesId = listSystemMenu[parentIdx].children.findIndex(p => p.text === item.text);
                            if(aksesId >=0){
                                list[parentIdx].children[aksesId].selected = selected
                            }
                        }
                        if (item.parent_menu_id) {

                            let parentIdx = listSystemMenu.findIndex(p => p.id === item.parent_menu_id);
                            let childIdx = listSystemMenu[parentIdx].children.findIndex(p => p.id === item.menu_id);
                            let aksesId = listSystemMenu[parentIdx].children[childIdx].children.findIndex(p => p.text === item.text);
                            if (aksesId >= 0) {
                                list[parentIdx].children[childIdx].children[aksesId].selected = selected
                            }
                        }

                        setListSystemMenu(list);

                    }}

                />
            </div>
        )
    }

    const saveData = async () => {
        try {
            let response = await insertSystemMenuForUserAccount(cookies.token, listSystemMenu, location.state.userAccountId, cookies.languageId);
            if (response.data === "sukses") {
                alert(`${t("detail_user_access.alert_success_save_data")}`);
                navigate('/UserAccountXMenu');
            } else {
                alert(`${t("detail_user_access.alert_failed_save_data")}`);
            }
            loadSystemMenu();
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
               setUpdatedButton(response.updated)
            }
            // setUpdatedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("detail_user_access.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/UserAccountXMenu"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div className="master-table-inner-container">

                            <DeniReactTreeView
                                style={{ marginRight: '10px', marginBottom: '10px' }}
                                showIcon={false}
                                theme="classic"
                                items={listSystemMenu}
                                ref={treeviewRef}
                                onRenderItem={onRenderItem}
                            />
                            <Loading
                                loading={loading}
                            />
                            <Button disabled={disabledButton === true ? disabledButton : !updatedButton} onClick={() => {
                                setLoading(true);
                                setDisabledButton(true);
                                saveData();
                            }
                            }>{t("detail_user_access.save_button")}</Button>
                        </div>

                    } />


            </Container>



        </>
    );

}