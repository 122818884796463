import "./NewSMSNavContainerFireAlrm.css"
import { useEffect, useState } from "react";
import LampNavButton from "../NewLampNavButton/NewLampNavButton";
import NavButton from "../NavButton/NavButton";
import { useNavigate } from "react-router-dom";

export default function SMSNavContainerFireAlarm(props) {
    const { smsSerialNumber, activeTab, childContent, handleMenuClick } = props;
    // const [ datasmsSerialNumber, setDataSmsSerialNumber] = useState(props.smsSerialNumber);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(props);
    }, []);

    return (
        <>
            <div style={{display: "flex", flexDirection: "column", height: '100%', justifyContent: 'space-between'}}>
                <div className="topNavBarContainerNew">
                    {props.childContent}
                </div>
                <div className="bottomNavBarContainerNew">
                    <LampNavButton text1={"WHEELHOUSE"} text2={""} lamp={activeTab === 0 ? true : false} handleOnClick={()=> {
                        handleMenuClick(0)
                    }} />
                    <LampNavButton text1={"MAIN"} text2={"DECK"} lamp={activeTab === 1 ? true : false} handleOnClick={()=> {
                        handleMenuClick(1)
                    }} />
                    <LampNavButton text1={"BELOW"} text2={"MAIN DECK"} lamp={activeTab === 2 ? true : false} handleOnClick={()=> {
                        handleMenuClick(2)
                    }} />
                </div>
            </div>
        </>
    )
}