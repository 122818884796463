import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal, Nav } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getDailyReportCrewById, insertUpdateDailyReportCrew,
    getDailyReportCrew, convertBase64, deleteDailyReportCrew, getDocumentSparepartById,
    getUserAccessById, getCountDailyReportCrew, getMechanic
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import Modals from "../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise, PencilFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import FormSelect from "../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import moment from 'moment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function DailyReportCrew() {
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [dailyReportId, setDailyReportId] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listStaff, setListStaff] = useState([]);
    const [listCrew, setListCrew] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [downloadId, setDownloadId] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [updatedDocumentId, setUpdatedDocumentId] = useState("");
    const [crewId, setCrewId] = useState("");
    const [dailyReportJobId, setDailyReportJobId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [descending, setDescending] = useState(false);
    const [showAddStaffModal, setShowAddStaffModal] = useState(false);
    const [showStaffDetailModal, setShowStaffDetailModal] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(0.4);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Staff",
        value: "staff.staff_name"
    });
    const orderByList = [{
        label: "Nama Staff",
        value: "staff._staff_name"
    }];
    const [newStaff, setNewStaff] = useState({
        id: 0,
        daily_report_job_id: "",
        image_base64: "",
        staff_id: "",
        daily_report_id: "",
        is_active: true,
        done: false,
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        daily_report_job_id: "",
        image_base64: "",
        staff_id: "",
        daily_report_id: "",
        is_active: true,
    });

    const resetUploadForm = async () => {
        let newStaff = {
            id: 0,
            daily_report_job_id: dailyReportJobId,
            image_base64: "",
            staff_id: "",
            daily_report_id: dailyReportId,
            is_active: true,
            done: false
        };
        setNewStaff({ ...newStaff, newStaff });
    }

    useEffect(() => {
        loadCrewData();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewData();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewData();
    }, [orderBy]);



    useEffect(() => {
        if (crewId !== "")
            loadCrewById()
    }, [crewId]);

    useEffect(() => {
        if (downloadId !== "")
            downloadData();
    }, [downloadId])

    useEffect(() => {
        if (searchQuery === "")
            loadCrewData();
    }, [searchQuery]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewData()
    }, [descending]);

    const [documentFile, setDocumentFile] = useState("");



    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }


    useEffect(() => {
        async function submitNewStaff() {
            if (newStaff.done) {
                await addStaff();
            }
        }
        submitNewStaff();
    }, [newStaff]);

    useEffect(() => {
        setLoading(true);
        if (dailyReportJobId !== "")
            removeDocument();
    }, [removeId]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowStaffDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (!showStaffDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                daily_report_job_id: "",
                image_base64: "",
                staff_id: "",
                daily_report_id: "",
                is_active: true,
            });
            setCrewId("")
        }
    }, [showStaffDetailModal]);



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setDailyReportJobId(searchParams.get("id"));
                setDailyReportId(searchParams.get("dailyReportId"));
                loadAccess();
                loadMechanic();
                // loadCrewData();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setNewStaff({ ...newStaff, daily_report_job_id: dailyReportJobId, daily_report_id: dailyReportId });
        loadCrewData();

    }, [dailyReportJobId])

    const addStaff = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateDailyReportCrew(cookies.token, newStaff);

                setShowAddStaffModal(false);
                resetUploadForm();
                loadCrewData();
                setDisabledButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const loadCrewData = async () => {
        try {
            let count = await getCountDailyReportCrew(cookies.token, searchParams.get("id"), searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getDailyReportCrew(cookies.token, searchParams.get("id"), page, itemPerPage, orderBy.value, descending, searchQuery, false);
            console.log(response);
            setListCrew(response);
            setLoading(false);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadMechanic = async () => {
        try {
            let response = await getMechanic(cookies.token, cookies.languageId, "null");
            console.log(response);
            setListStaff(response);
        } catch (exception) {

        }
    }

    const removeDocument = async () => {
        let response = await deleteDailyReportCrew(cookies.token, removeId);
        if (response) {
            loadCrewData();
        } else {
            alert("Terjadi kesalahan sistem, mohon hubungi administrator.");
            setLoading(false);
        }
    }

    const loadCrewById = async () => {
        try {
            let response = await getDailyReportCrewById(cookies.token, crewId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await getDailyReportCrewById(cookies.token, downloadId);
            triggerBase64Download(response.image_base64, response.staff_id);
            setLoading(false);
            setDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setUpdatedButton(true);
            setDeletedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Teknisi"
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowAddStaffModal(true) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Teknisi</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cara (Nama staff)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadCrewData();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadCrewData();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadCrewData();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Nama Teknisi</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Lihat Foto</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Download</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listCrew.map((crew, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{crew.staff_name}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setLoading(true);
                                                        setCrewId(crew.id)
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setDownloadId(crew.id)
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`Apakah Anda Yakin Menghapus  ${crew.staff_name}?`)) {
                                                            let removeId = crew.id
                                                            setRemoveId(removeId);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                {/* <Modal show={showAddStaffModal} onHide={() => {
                    resetUploadForm();
                    setShowAddStaffModal(false);
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Upload Dokumen`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    setDisabledButton(true);
                                    setNewStaff({ ...newStaff, done: true });
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>File Dokumen</Form.Label>
                                            <Form.Control onChange={async (e) => {
                                                console.log(e.target.files[0])
                                                setDocumentFile(e.target.value)
                                                let base64Doc = await convertBase64(e.target.files[0]);
                                                setNewStaff({ ...newStaff, image_base64: base64Doc.toString() });
                                            }} type="file" required></Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Dokumen</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewStaff({ ...newStaff, staff_id: e.target.value })
                                                }} value={newStaff.staff_id} type="text" placeholder="Nama Dokumen" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nomor Refensi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setNewStaff({ ...newStaff, reference_number: e.target.value })
                                                }} value={newStaff.reference_number} type="text" placeholder="Nomor Referensi" ></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Deskripsi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setNewStaff({ ...newStaff, daily_report_id: e.target.value })
                                                }} value={newStaff.daily_report_id} as="textarea" rows={3} placeholder="Deskripsi"></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            resetUploadForm();
                                            loadCrewData();
                                            setShowAddStaffModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />


                </Modal> */}

                <Modal show={showAddStaffModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        resetUploadForm();
                        setShowAddStaffModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Upload Dokumen </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            setDisabledButton(true);
                            setNewStaff({ ...newStaff, done: true });
                        }} style={{ padding: 10 }}>


                            <Form.Group className="mb-3">
                                <Form.Label>Nama Teknisi</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.staff_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listStaff.filter((p) => {
                                        return !listCrew.find((x) => {
                                            return p.staff_id === x.staff_id
                                        })
                                    })} value={listStaff.filter(function (option) {
                                        return option.staff_id === newStaff.staff_id;
                                    })} onChange={(e) => {
                                        if (e === null) {

                                            setNewStaff({ ...newStaff, staff_id: null })

                                        } else {
                                            setNewStaff({ ...newStaff, staff_id: e.id })
                                        }
                                    }}
                                    isClearable
                                    required
                                />
                                {/* {
                                                                    errors.staffName && <p style={{ color: "red" }}>{errors.staffName}</p>
                                                                } */}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Foto</Form.Label>
                                <Form.Control onChange={async (e) => {
                                    // if (e.target.files[0].type === "application/pdf") {
                                    setDocumentFile(e.target.value)
                                    let base64Doc = await convertBase64(e.target.files[0]);
                                    setNewStaff({ ...newStaff, image_base64: base64Doc.toString() });
                                    // setUploadFileImageError("");
                                    setDisabledButton(false)

                                    // } else {
                                    //     setDocumentFile("")
                                    //     setNewStaff({ ...newStaff, image_base64: "" });
                                    //     setUploadFileImageError("Hanya Bisa File Pdf")
                                    //     setDisabledButton(true)
                                    // }

                                }} type="file" required></Form.Control>
                                {/* {
                                    uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                } */}
                            </Form.Group>





                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flex: 1,
                            }}>
                                <Button variant="primary" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>
                                    Simpan
                                </Button>
                                <Button className="cancel" variant="danger" onClick={() => {
                                    resetUploadForm();
                                    loadCrewData();
                                    setShowAddStaffModal(false);
                                }}>
                                    Batal
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal>

                <Modal className="modal-xxl" show={showStaffDetailModal} onHide={() => {
                    setShowStaffDetailModal(false);
                }}>

                    <ContainerBox containerPos="inner" titleCaption={""}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowStaffDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Tutup</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                <center> <img src={documentToBeViewed.image_base64} style={{ width: "50%" }}></img></center>
                            </div>
                        } />

                </Modal>
            </Container>

        </>
    );

}