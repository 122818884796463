// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bitLamp2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
}

.bitLamp2On {
    background: #40C110;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 17px;
    height: 17px;
}

.bitLamp2Off {
    background: #E72D2D;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 17px;
    height: 17px;
}

.bitLamp2Black {
    background: #050512;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 17px;
    height: 17px;
}

.textBitLamp2 {
    font-size: 10px;
    text-align: center;
    color: aliceblue;
}`, "",{"version":3,"sources":["webpack://./src/Components/BitLamp2/BitLamp2.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".bitLamp2 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 60px;\n}\n\n.bitLamp2On {\n    background: #40C110;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 17px;\n    height: 17px;\n}\n\n.bitLamp2Off {\n    background: #E72D2D;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 17px;\n    height: 17px;\n}\n\n.bitLamp2Black {\n    background: #050512;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 17px;\n    height: 17px;\n}\n\n.textBitLamp2 {\n    font-size: 10px;\n    text-align: center;\n    color: aliceblue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
