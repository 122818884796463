// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headingScreen {
    display: flex;
    justify-content: space-between;
  }
  
  .logoOceania {
    margin: 17px;
  }
  
  .dashboard-main-header {
    color: #eaf4ff;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
  }
  
  .dateRoom {
    width: 500px;
    height: 80px;
    margin-right: 25px;
    padding-right: 25px;
    background-color: rgba(233, 233, 250, 0.1);
    display: flex;
    justify-content: flex-end;
    border-radius: 7px;
  }
  
  .dateRoom p {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .textDate {
    padding-top: 18px;
    font-weight: 500;
  }
  
  .contentScreen {
    color: white;
    margin-top: 180px;
    margin-left: 150px;
  }
  
  .contentScreen div {
    font-size: 36px;
    font-weight: 800;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/SplashScreen/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,cAAc;IACd,eAAe;IACf,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,0CAA0C;IAC1C,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".headingScreen {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .logoOceania {\n    margin: 17px;\n  }\n  \n  .dashboard-main-header {\n    color: #eaf4ff;\n    font-size: 14px;\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .dateRoom {\n    width: 500px;\n    height: 80px;\n    margin-right: 25px;\n    padding-right: 25px;\n    background-color: rgba(233, 233, 250, 0.1);\n    display: flex;\n    justify-content: flex-end;\n    border-radius: 7px;\n  }\n  \n  .dateRoom p {\n    display: flex;\n    justify-content: flex-end;\n    font-size: 16px;\n    font-weight: bold;\n    color: white;\n  }\n  \n  .textDate {\n    padding-top: 18px;\n    font-weight: 500;\n  }\n  \n  .contentScreen {\n    color: white;\n    margin-top: 180px;\n    margin-left: 150px;\n  }\n  \n  .contentScreen div {\n    font-size: 36px;\n    font-weight: 800;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
