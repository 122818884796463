import "../App.css";
import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { Form, option, Button, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Select from 'react-select';
import { Trash, FilePlusFill, PencilFill, XSquareFill, CameraFill, Image } from "react-bootstrap-icons";
import {
    isTokenValid, getVesselEquipmentByEquipmentCategory, convertBase64, convertBase64Image, getManufacturer, getEquipmentPosition,
    insertUpdateVesselxEquipment
} from "../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import ContainerBox from "./ContainerBox";
import { Trans, useTranslation } from "react-i18next";

function ServiceReportEquipment({
    index,
    serviceEquipment,
    listServiceJob,
    listEquipment,
    // ref,
    disabledAddButton,
    vesselId,
    serviceJobId,
    serviceJob,
    // setListServiceJob,
    // setDetailJobComponent,
    handleOnBlur,
    handleDelete,
    handleDeleteServiceEquipment,
    // listServiceEquipment,
    resetComponentProps,
    disabledCustomerForm
}) {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [serviceEquipmentNew, setServiceEquipmentNew] = useState({});
    const [listServiceJobNew, setListServiceJobNew] = useState([]);
    const [indexFindingModal, setIndexFindingModal] = useState("");
    const [vesselEquipmentModal, setVesselEquipmentModal] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [indexRecommendationModal, setIndexRecommendationModal] = useState("");
    const [findingsModal, setFindingsModals] = useState("");
    const [indexFotoModal, setIndexFotoModal] = useState("");
    const [fotoModal, setFotoModal] = useState("");
    const [serviceJobNew, setServiceJobNew] = useState({})
    const [recommendationModal, setRecommendationModal] = useState("");
    const [indexDetailJob, setIndexDetailJob] = useState("");
    const [descriptionModal, setDescriptionModal] = useState("");
    const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
    const [listVesselEquipmentNew, setListVesselEquipmentNew] = useState([]);
    const [selectedVesselEquipment, setSelectedVesselEquipment] = useState(null);
    const [listEquipmentNew, setListEquipmentNew] = useState([]);
    const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState(null);
    const [startJobDate, setStartJobDate] = useState(null);
    const [endJobDate, setEndJobDate] = useState(null);
    const [resetComponent, setResetComponent] = useState(false);
    const [errorJobName, setErrorJobName] = useState("");
    const [disablePlusButton, setDisablePlusButton] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("white")
    const [listManufacturer, setListManufacturer] = useState("");
    const [selectedManufacturer, setSelectedManufacturer] = useState({ id: "" });
    const [vesselEquipment, setVesselEquipment] = useState({
        id: 0,
        title: "",
        model: "OEM",
        manufacture_name: null,
        equipment_position_id: null,
        equipment_id: null,
        vessel_id: null,
        name: ""
    })

    const options = {
        wordwrap: 130
    }
    // useImperativeHandle(ref, () => {
    //     return {
    //         serviceEquipmentNew,
    //         listServiceJobNew
    //     }
    //     // solarPanel
    // });

    useEffect(() => {
        resetComponentProps && setResetComponent(false);
    }, [resetComponent]);

    useEffect(() => {
        if (selectedVesselEquipment) {
            let serviceEquipmentTmp = serviceEquipmentNew;
            serviceEquipmentTmp.vessel_equipment_id = selectedVesselEquipment.id;
            setServiceEquipmentNew(serviceEquipmentTmp);
            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

        }

    }, [selectedVesselEquipment]);

    useEffect(() => {
        // console.log(selectedEquipmentCategory);
        if (selectedEquipmentCategory) {
            let serviceEquipmentTmp = serviceEquipmentNew;
            serviceEquipmentTmp.equipment_id = selectedEquipmentCategory.id;
            serviceEquipmentTmp.equipment_name = selectedEquipmentCategory.equipment_name
            setServiceEquipmentNew(serviceEquipmentTmp);
            setSelectedVesselEquipment(null)
            loadVesselEquipment();
            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

        }
    }, [selectedEquipmentCategory]);

    useEffect(() => {
        if (startJobDate) {
            let serviceEquipmentTmp = serviceEquipmentNew;
            serviceEquipmentNew.start_date = startJobDate;
            setServiceEquipmentNew(serviceEquipmentTmp);
            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

        }
    }, [startJobDate]);

    useEffect(() => {
        if (selectedManufacturer) {
            setVesselEquipment({ ...vesselEquipment, manufacture_name: selectedManufacturer.id, name: selectedManufacturer.manufacturer_name });
        }
    }, [selectedManufacturer]);


    useEffect(() => {
        if (indexDetailJob !== "") {
            setDescriptionModal(true);
        }
    }, [indexDetailJob])

    useEffect(() => {
        if (indexFindingModal !== "") {
            setFindingsModals(true);
        }
    }, [indexFindingModal])

    useEffect(() => {
        if (indexFotoModal !== "") {
            setFotoModal(true);
        }
    }, [indexFotoModal])

    useEffect(() => {
        if (indexRecommendationModal !== "") {
            setRecommendationModal(true);
        }
    }, [indexRecommendationModal])

    useEffect(() => {
        if (endJobDate) {
            let serviceEquipmentTmp = serviceEquipmentNew;
            serviceEquipmentNew.end_date = endJobDate;
            setServiceEquipmentNew(serviceEquipmentTmp);
            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

        }
    }, [endJobDate]);

    useEffect(() => {
        // console.log(listEquipmentNew.length)
        if (listEquipmentNew.length > 0) {
            let selectedEquipmentCategoryTmp = listEquipmentNew.find(p => p.id === serviceEquipmentNew.equipment_id)

            if (selectedEquipmentCategoryTmp) {
                setSelectedEquipmentCategory(selectedEquipmentCategoryTmp)
            }
        }
    }, [listEquipmentNew])

    useEffect(() => {
        if (listVesselEquipmentNew) {
            let selectedVesselEquipmentTmp = listVesselEquipmentNew.find(p => p.id === serviceEquipmentNew.vessel_equipment_id)
            if (selectedVesselEquipmentTmp) {
                setSelectedVesselEquipment(selectedVesselEquipmentTmp)
            }
        }
    }, [listVesselEquipmentNew])

    useEffect(() => {
        if (serviceEquipment) {
            setServiceEquipmentNew(serviceEquipment);
        }
        loadManufacturer();
        loadEquipmentPosition();
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
          } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
          }

        // if (listServiceJob)
        //     setListServiceJobNew(listServiceJob);

        // if (listEquipment) {
        //     setListEquipmentNew(listEquipment.slice(0));
        // }


        // if (serviceJob) {
        //     setServiceJobNew(serviceJob)
        // }



    }, []);

    useEffect(() => {
        if (serviceEquipment) {
            setServiceEquipmentNew(serviceEquipment);
        }


        if (listServiceJob)
            setListServiceJobNew(listServiceJob);

        if (listEquipment) {
            setListEquipmentNew(listEquipment.slice(0));
        }


        if (serviceJob) {
            setServiceJobNew(serviceJob)
        }



    }, [serviceEquipment]);

    useEffect(() => {
        if (listServiceJobNew.length > 0) {
            for (let i = 0; i < listServiceJobNew.length; i++) {
                const listTask = listServiceJobNew[i];
                if (listTask.contract_task_detail_name === "") {
                    setDisablePlusButton(true);
                    setErrorJobName(`${t("detail_annual_report.error_job_name")}`)
                } else {
                    setDisablePlusButton(false);
                    setErrorJobName("")
                }

            }
            if(serviceEquipment.is_done === false){
                let serviceEquipmentTmp = serviceEquipmentNew;
                serviceEquipmentTmp.header_color = "#ffffc5";
                serviceEquipmentTmp.background_color = "#ffffc5"
            }
           
        }
        else {
            setDisablePlusButton(false);
            setErrorJobName("");
            if(serviceEquipment.is_done === false){
                let serviceEquipmentTmp = serviceEquipmentNew;
                serviceEquipmentTmp.header_color = "#ff9e81";
                serviceEquipmentTmp.background_color = "#ff9e81"
            }
           

        }


    }, [listServiceJobNew])
    

    useEffect(()=>{
        if(!serviceEquipmentNew.vessel_equipment_id){
            let serviceEquipmentTmp = serviceEquipmentNew;
            serviceEquipmentTmp.header_color = "#ff9e81";
            serviceEquipmentTmp.background_color = "#ff9e81"
        }
    },[serviceEquipmentNew])


    const getserviceEquipmentState = (serviceEquipment) => {
        return (serviceEquipment);
    }

    const loadVesselEquipment = async () => {
        try {
            // console.log(serviceEquipmentNew.equipment_id)
            let listVesselEquipmentTmp = await getVesselEquipmentByEquipmentCategory(cookies.token, serviceEquipmentNew.equipment_id, vesselId);
            // console.log(listVesselEquipmentTmp)
            setListVesselEquipmentNew(listVesselEquipmentTmp)
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadManufacturer = async () => {
        try {
            let response = await getManufacturer(cookies.token);
            setListManufacturer(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentPosition = async () => {
        try {
            let response = await getEquipmentPosition(cookies.token);
            if (response) {
                setListEquipmentPosition(response);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveDataVesselEquipment = async () => {
        try {
            let vesselEquipmentTmp = vesselEquipment;
            vesselEquipmentTmp.title = vesselEquipment.model + ' ' + vesselEquipment.name
            vesselEquipmentTmp.equipment_id = serviceEquipmentNew.equipment_id
            vesselEquipmentTmp.vessel_id = vesselId
            vesselEquipmentTmp.sparepart_catalog_id = null

            let response = await insertUpdateVesselxEquipment(cookies.token, vesselEquipmentTmp, cookies.languageId);
            if (response.error_code === 0) {
                alert(`${t("detail_annual_report.alert_success_save_data")}`);
                loadVesselEquipment();
            }
            else {
                alert(`${t("detail_annual_report.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
            setVesselEquipmentModal(false);

        } catch (exception) {
            console.log(exception)
            // setDisabledButton(false);
        }
    }

    return (
        <div >
            <div style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                flexWrap: "nowrap",
                width: "100%"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    flexWrap: "nowrap",
                    width: "100%",
                    justifyContent: "center",

                }}>
                    <Form.Check onClick={(e) => {
                        if (e.target.checked) {
                            let serviceEquipmentTmp = serviceEquipmentNew;
                            serviceEquipmentTmp.is_done = true;
                            serviceEquipmentTmp.header_color = "#98FB98";
                            serviceEquipmentTmp.background_color = "#98FB98"
                            setServiceEquipmentNew(serviceEquipmentTmp);
                            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                        }
                        else {
                            let serviceEquipmentTmp = serviceEquipmentNew;
                            serviceEquipmentTmp.is_done = false;
                            if(listServiceJobNew.length>0){
                                serviceEquipmentTmp.header_color = "#ffffc5";
                                serviceEquipmentTmp.background_color = "#ffffc5"
                            }else if(!serviceEquipmentNew.vessel_equipment_id){
                                serviceEquipmentTmp.header_color = "#ff9e81";
                                serviceEquipmentTmp.background_color = "#ff9e81"
                            }else{
                                serviceEquipmentTmp.header_color = "white";
                                serviceEquipmentTmp.background_color = "white"
                            }
                           
                            setServiceEquipmentNew(serviceEquipmentTmp);
                            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                        }


                    }} defaultChecked={serviceEquipmentNew.is_done}></Form.Check>
                    <Form.Label >Done</Form.Label>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 30,
                    flexWrap: "nowrap",
                    width: "100%"
                }}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header style={{
                                borderStyle: "solid",
                                borderWidth: 8,
                                borderRadius: 5,
                                borderColor: `${serviceEquipmentNew.header_color}`
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    flex: 1,
                                    width: "100%",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 6,
                                        flexWrap: "nowrap",
                                        flexDirection: "column",
                                        color: "black",
                                        fontSize: 18
                                    }}>
                                        <p>{serviceEquipmentNew.equipment_name ? serviceEquipmentNew.equipment_name : ""}</p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 5,
                                        flexWrap: "nowrap",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            flexWrap: "nowrap",
                                            width: "100%",
                                            paddingRight: 5,
                                            paddingLeft: 5
                                        }}>
                                            <table>
                                                <tr>
                                                    <td>{t("detail_annual_report.text_manufacturer")}</td>
                                                    <td>:</td>
                                                    <td>{serviceEquipmentNew.manufacturer_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("detail_annual_report.text_model")}</td>
                                                    <td>:</td>
                                                    <td>{serviceEquipmentNew.model}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("detail_annual_report.text_job_name")}</td>
                                                    <td>:</td>
                                                    <td>{serviceEquipmentNew.title}</td>
                                                </tr>

                                            </table>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            flexWrap: "nowrap",
                                            width: "100%",
                                            paddingRight: 5,
                                            paddingLeft: 5
                                        }}>
                                            <table>

                                                <tr>
                                                    <td>{t("detail_annual_report.text_serial_number")}</td>
                                                    <td>:</td>
                                                    <td>{serviceEquipmentNew.serial_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("detail_annual_report.text_equipment_position")}</td>
                                                    <td>:</td>
                                                    <td>{serviceEquipmentNew.position_name}</td>
                                                </tr>
                                            </table>

                                        </div>
                                    </div>

                                </div>



                            </Accordion.Header>
                            <Accordion.Body style={{
                                backgroundColor: `${serviceEquipmentNew.background_color}`
                            }}>


                                <div >
                                    <Form.Label>{t("detail_annual_report.field_equipment_category")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.equipment_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listEquipment} isDisabled={serviceEquipmentNew.disabled === "true" || disabledCustomerForm === true ? true : false} 
                                        value={selectedEquipmentCategory} onChange={(e) => {
                                            // let detailJobs = serviceEquipmentNew;
                                            // detailJobs.equipment_id = e.id;
                                            // detailJobs.equipment_name = e.equipment_name;
                                            // detailJobs.vessel_equipment_id = null;
                                            // detailJobs.manufacturer_name = "";
                                            // detailJobs.serial_number = "";
                                            // detailJobs.position_name = ""
                                            // detailJobs.model = "";
                                            // let newVesselEquipment = listVesselEquipment.filter(p => p.equipment_id === e.id);
                                            // setSelectedVesselEquipment(null);
                                            // setListVesselEquipmentNew(newVesselEquipment);
                                            // setServiceEquipmentNew(detailJobs);
                                            // if (!listServicejob[serviceEquipmentIndex]) {
                                            //     let list = [];
                                            //     let listJob = listServicejob;
                                            //     listJob.push(list);
                                            //     setListServiceJob(listJob.slice(0))
                                            // }
                                            setSelectedEquipmentCategory(e)

                                        }}
                                        isClearable

                                    />  
                                    {/* {

                                equipmentErrorMessage && <p style={{ color: "red" }}>{equipmentErrorMessage}</p>
                            } */}
                                    <div style={{ paddingBottom: 10 }}></div>

                                    <Form.Label>{t("detail_annual_report.field_vessel_equipment")} <a onClick={() => {
                                        let vesselEquipmentTmp = {
                                            id: 0,
                                            title: "",
                                            model: "OEM",
                                            manufacture_name: null,
                                            equipment_position_id: null,
                                            equipment_id: null,
                                            vessel_id: null,
                                            name: ""
                                        }
                                        setSelectedManufacturer({})
                                        setVesselEquipment(vesselEquipmentTmp);
                                        setVesselEquipmentModal(true)
                                    }} role="button" style={{ color: "blue", textDecoration: "underline" }}> <i className='fa fa-plus text-primary'></i> {t("detail_annual_report.add_vessel_equipment")} </a></Form.Label>
                                    <Select placeholder={""} isDisabled={!serviceEquipmentNew.equipment_id || disabledCustomerForm === true ? true:false}
                                        getOptionLabel={(item) => {
                                            if (item.id) {
                                                return (item.manufacturer_name ? item.manufacturer_name : " ") + ' - ' + item.model + `(${item.serial_number ? item.serial_number : ""})`
                                            } else {
                                                return ("");
                                            }
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id
                                        }}
                                        options={listVesselEquipmentNew} value={selectedVesselEquipment} onChange={(e) => {
                                            /* let detailJobsTmp = serviceEquipmentNew;
                                            detailJobsTmp.vessel_equipment_id = e.id
                                            detailJobsTmp.manufacturer_name = e.manufacturer_name
                                            detailJobsTmp.model = e.model
                                            detailJobsTmp.serial_number = e.serial_number
                                            detailJobsTmp.position_name = e.position_name
                                            setServiceEquipmentNew(detailJobsTmp); */
                                            // console.log(e);
                                            if (e !== null) {
                                                setSelectedVesselEquipment(e);
                                            } else {
                                                setSelectedVesselEquipment(null)
                                                let serviceEquipmentTmp = serviceEquipmentNew;
                                                serviceEquipmentTmp.vessel_equipment_id = null;
                                                setServiceEquipmentNew(serviceEquipmentTmp);
                                                handleOnBlur(serviceEquipmentNew, [], index);

                                            }

                                        }}
                                        isClearable


                                    />
                                    <div style={{ paddingBottom: 10 }}></div>
                                    {!selectedVesselEquipment ?
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: 20,
                                                color: "red"
                                            }}>
                                            <p>{t("detail_annual_report.error_no_equipment")}</p>

                                        </div>
                                        :
                                        <div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flex: 1,
                                                flexWrap: "nowrap",
                                                width: "100%"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    paddingRight: 5,
                                                    // paddingLeft: 5
                                                }}>
                                                    <Form.Group >
                                                        <Form.Label>{t("detail_annual_report.field_job_start_date")}</Form.Label>
                                                        <Form.Control disabled={disabledCustomerForm} type="datetime-local" value={startJobDate ? moment(startJobDate).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                            /* let detailJobs = serviceEquipmentNew;
                                                            if (e.target.value === "") {
                                                                detailJobs.start_date = null;
                                                            } else {
                                                                detailJobs.start_date = e.target.value;
                                                            }
        
                                                            setServiceEquipmentNew(detailJobs); */
                                                            setStartJobDate(e.target.value);
                                                        }}></Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingRight: 5,
                                                    paddingLeft: 5

                                                }}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>{t("detail_annual_report.field_job_end_date")}</Form.Label>
                                                        <Form.Control disabled={disabledCustomerForm} type="datetime-local" value={endJobDate ? moment(endJobDate).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                            /* let detailJobs = serviceEquipmentNew;
                                                            if (e.target.value === "") {
                                                                detailJobs.end_date = null;
                                                            } else {
                                                                detailJobs.end_date = e.target.value;
                                                            }
        
                                                            setServiceEquipmentNew(detailJobs) */
                                                            setEndJobDate(e.target.value);
                                                        }}></Form.Control>
                                                    </Form.Group>
                                                </div>



                                            </div>
                                            {listServiceJobNew && <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "column",
                                                padding: 5
                                            }}>

                                                {
                                                    selectedVesselEquipment.id && listServiceJobNew.map((jobDesc, idx) => {
                                                        return (

                                                            <div style={{ padding: 5 }}>
                                                                <div key={idx} style={{
                                                                    display: "flex",
                                                                    width: "100%",
                                                                    flexDirection: "column",
                                                                    padding: 5,
                                                                    borderWidth: 2,
                                                                    borderRadius: 5,
                                                                    borderStyle: "solid",
                                                                    borderColor: "rgba(3, 30, 103, 1)"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            paddingTop: 5,
                                                                            textAlign: "center"
                                                                        }}>
                                                                            {idx + 1}
                                                                        </div>

                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 25,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_job_name")}</Form.Label>
                                                                            <Form.Control required onBlur={e => handleOnBlur(serviceEquipmentNew, listServiceJobNew, index)} value={jobDesc.contract_task_detail_name} onChange={(e) => {
                                                                                let detailJobs = listServiceJobNew;
                                                                                let orderNumber = ""
                                                                                if (idx < 1) {
                                                                                    orderNumber = idx + 1
                                                                                } else {
                                                                                    orderNumber = Number(detailJobs[idx - 1].order_number) + 1
                                                                                }

                                                                                // console.log(detailJobs[idx])
                                                                                detailJobs[idx].contract_task_detail_name = e.target.value
                                                                                detailJobs[idx].order_number = orderNumber
                                                                                // console.log(detailJobs);
                                                                                setListServiceJobNew(detailJobs.slice(0));
                                                                                // setDetailJobComponent(detailJobs.slice(0))

                                                                            }} disabled={disabledCustomerForm} placeholder=""></Form.Control>

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            // width: "100%",
                                                                            flexDirection: "column",
                                                                            // alignItems: "flex-end",
                                                                            flex: 4,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_bobot")}</Form.Label>
                                                                            <Form.Control disabled={disabledCustomerForm} onBlur={e => handleOnBlur(serviceEquipmentNew, listServiceJobNew, index)} value={jobDesc.quality} onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9.]*$/).test(e.target.value)) {

                                                                                } else {
                                                                                    let detailJobs = listServiceJobNew;
                                                                                    detailJobs[idx].quality = e.target.value;
                                                                                    setListServiceJobNew(detailJobs.slice(0))
                                                                                }


                                                                            }}></Form.Control>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            // width: "100%",
                                                                            flexDirection: "column",
                                                                            // alignItems: "flex-end",
                                                                            flex: 4,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_progress")}</Form.Label>
                                                                            <Form.Control disabled={disabledCustomerForm} onBlur={e => handleOnBlur(serviceEquipmentNew, listServiceJobNew, index)} value={jobDesc.progress} onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9.]*$/).test(e.target.value)) {

                                                                                } else {
                                                                                    let detailJobs = listServiceJobNew;
                                                                                    detailJobs[idx].progress = e.target.value;
                                                                                    setListServiceJobNew(detailJobs.slice(0))
                                                                                }


                                                                            }}></Form.Control>

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            alignItems: "flex-end",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <div style={{ paddingTop: 30 }}></div>
                                                                            <Button disabled={disabledCustomerForm === true ? true :disabledAddButton} onClick={() => {
                                                                                setIndexFotoModal(idx)
                                                                                let newParameter = {

                                                                                };

                                                                                if (!jobDesc.list_photo) {
                                                                                    let detailJobs = listServiceJobNew;
                                                                                    detailJobs[idx].list_photo = []


                                                                                    setListServiceJobNew(detailJobs.slice(0))
                                                                                } else {

                                                                                }

                                                                            }} style={{ maxWidth: 50 }} variant="primary"><CameraFill /></Button>

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            alignItems: "flex-end",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <div style={{ paddingTop: 30 }}></div>
                                                                            <Link to={`/ServiceJob/Photo?id=${jobDesc.id}`} style={{
                                                                                pointerEvents: serviceJobId === 0 || jobDesc.id === undefined ? "none" : ""
                                                                            }} target="_blank">
                                                                                <Button disabled={serviceJobId === 0 || jobDesc.id === undefined} onClick={() => {

                                                                                }} style={{ maxWidth: 50 }} variant="primary"><Image /></Button>
                                                                            </Link>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            alignItems: "flex-end",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <div style={{ paddingTop: 30 }}></div>
                                                                            <Button disabled={jobDesc.deleted === "false" || disabledCustomerForm === true ? true : disabledAddButton} onClick={() => {
                                                                                if (window.confirm(`${t("detail_annual_report.delete_confirmation")}?`)) {
                                                                                    let newList = listServiceJobNew
                                                                                    if (!serviceJobNew.deleted_job) {
                                                                                        let list = [];
                                                                                        let newList = listServiceJobNew
                                                                                        list.push(newList[idx])
                                                                                        newList.splice(idx, 1);


                                                                                        // setServiceJob({ ...serviceJob, deleted_job: list });
                                                                                        let serviceJobTmp = serviceJobNew;
                                                                                        serviceJobTmp.deleted_job = list;
                                                                                        setServiceJobNew(serviceJobTmp);
                                                                                        setListServiceJobNew(newList.slice(0));
                                                                                        handleDelete(serviceJobTmp, index)
                                                                                    } else {
                                                                                        let newList = listServiceJobNew
                                                                                        let listDeleted = serviceJobNew
                                                                                        listDeleted.deleted_job.push(newList[idx]);
                                                                                        newList.splice(idx, 1)
                                                                                        setServiceJobNew(listDeleted);
                                                                                        handleDelete(listDeleted, index)
                                                                                        // setServiceJob({ ...serviceJob, deleted_job: listDeleted });
                                                                                        setListServiceJobNew(newList.slice(0));
                                                                                    }

                                                                                    handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

                                                                                }

                                                                            }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        paddingTop: 10,
                                                                        paddingBottom: 10,
                                                                        flexDirection: "row",
                                                                        flexWrap: "nowrap"

                                                                    }}>

                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                            flex: 10
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_description")}</Form.Label>
                                                                            <InputGroup className="mb-2">


                                                                                <Form.Control className="descriptionelips" disabled type="text" value={jobDesc.description ? convert(jobDesc.description, options) : ""} placeholder=""></Form.Control>
                                                                                <InputGroup.Text style={{
                                                                                    backgroundColor: "rgba(3, 30, 103, 1)"
                                                                                }} onClick={() => {
                                                                                    setIndexDetailJob(idx);
                                                                                }}><PencilFill color="white" /></InputGroup.Text>
                                                                            </InputGroup>

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            // width: "100%",

                                                                            flexDirection: "column",
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                            flex: 1
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_start_date")}</Form.Label>
                                                                            <Form.Control disabled={disabledCustomerForm} style={{
                                                                                width: 200
                                                                            }} type="datetime-local" value={jobDesc.start_date ? moment(jobDesc.start_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                                let detailJobs = listServiceJobNew;
                                                                                if (e.target.value === "") {
                                                                                    detailJobs[idx].start_date = null;
                                                                                } else {
                                                                                    detailJobs[idx].start_date = e.target.value;
                                                                                }

                                                                                setListServiceJobNew(detailJobs.slice(0))
                                                                            }}></Form.Control>

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            // width: "100%",
                                                                            flexDirection: "column",
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                            flex: 1
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_end_date")}</Form.Label>
                                                                            <Form.Control disabled={disabledCustomerForm} style={{
                                                                                width: 200
                                                                            }} type="datetime-local" value={jobDesc.end_date ? moment(jobDesc.end_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                                let detailJobs = listServiceJobNew;
                                                                                if (e.target.value === "") {
                                                                                    detailJobs[idx].end_date = null;
                                                                                } else {
                                                                                    detailJobs[idx].end_date = e.target.value;
                                                                                }

                                                                                setListServiceJobNew(detailJobs.slice(0))
                                                                            }}></Form.Control>

                                                                        </div>


                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        width: "100%",
                                                                        flexWrap: "nowrap",
                                                                        flex: 1
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                            flex: 5
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_finding")}</Form.Label>

                                                                            <InputGroup className="mb-2">


                                                                                <Form.Control className="descriptionelips" disabled type="text" value={jobDesc.findings ? convert(jobDesc.findings, options) : ""} placeholder=""></Form.Control>
                                                                                <InputGroup.Text style={{
                                                                                    backgroundColor: "rgba(3, 30, 103, 1)"
                                                                                }} onClick={() => {
                                                                                    setIndexFindingModal(idx)
                                                                                }}><PencilFill color="white" /></InputGroup.Text>
                                                                            </InputGroup>



                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                            flex: 5
                                                                        }}>
                                                                            <Form.Label>{t("detail_annual_report.field_recommendation")}</Form.Label>


                                                                            <InputGroup className="mb-2">


                                                                                <Form.Control className="descriptionelips" disabled type="text" value={jobDesc.recommendation ? convert(jobDesc.recommendation, options) : ""} placeholder=""></Form.Control>
                                                                                <InputGroup.Text style={{
                                                                                    backgroundColor: "rgba(3, 30, 103, 1)"
                                                                                }} onClick={() => {
                                                                                    setIndexRecommendationModal(idx)
                                                                                }}><PencilFill color="white" /></InputGroup.Text>
                                                                            </InputGroup>




                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>



                                                        )
                                                    })
                                                }
                                                {
                                                    errorJobName && <p style={{ color: "red" }}>{errorJobName}</p>
                                                }
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",

                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5,

                                                    }}>
                                                        <Button disabled={disabledAddButton === true || disabledCustomerForm === true ? true : disablePlusButton} variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                contract_task_detail_name: "",
                                                                quality: null,
                                                                progress: null,
                                                            };
                                                            if (!listServiceJobNew) {
                                                                let newJobs = []
                                                                let newList = listServiceJobNew
                                                                newList = newJobs;
                                                                newList.push(newParameter);
                                                                setListServiceJobNew(newList.slice(0))
                                                            } else {
                                                                let newList = listServiceJobNew
                                                                newList.push(newParameter);
                                                                // newList.push(newParameter);
                                                                setListServiceJobNew(newList.slice(0))
                                                            }
                                                            // handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                                                            // setServiceEquipmentIndexComponent(index)


                                                        }}><FilePlusFill size={30} /></Button>
                                                    </div>

                                                </div>


                                            </div>}
                                        </div>

                                    }


                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button disabled={disabledCustomerForm === true ? true :disabledAddButton} variant="danger" onClick={() => {
                                            if (window.confirm(`${t("detail_annual_report.delete_confirmation")}?`)) {
                                                let detailEquipment = serviceEquipmentNew;
                                                if (!serviceEquipmentNew.deleted_detail) {
                                                    // let list = [];
                                                    // list.push(detailEquipment[i]);
                                                    // let listJob = listServiceJobNew
                                                    // listJob.splice(serviceEquipmentIndex, 1)
                                                    // setListServiceJobNew([]);


                                                    // setServiceJob({ ...serviceJob, deleted_detail: list });
                                                    // detailEquipment.splice(serviceEquipmentIndex, 1)
                                                    // setListServiceEquipment(detailEquipment.slice(0))
                                                    // setServiceEquipmentNew({})
                                                } else {
                                                    // setListServiceJobNew([]);
                                                    // setServiceEquipmentNew({})

                                                    // let list = serviceJob.deleted_detail;
                                                    // list.push(detailEquipment[i]);
                                                    // detailEquipment.splice(i, 1);
                                                    // let listJob = listServicejob
                                                    // listJob.splice(i, 1)
                                                    // setListServiceJob(listJob.slice(0));
                                                    // setServiceJob({ ...serviceJob, deleted_detail: list });
                                                    // setListServiceEquipment(detailEquipment.slice(0))

                                                }

                                                handleDeleteServiceEquipment(index)
                                            }


                                        }}><Trash size={30} /></Button>
                                    </div>



                                </div>



                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>


            <div style={{ paddingBottom: 10 }}></div>

            <Modal size="xl" show={descriptionModal} onHide={
                () => {
                    setDescriptionModal(false);
                    setIndexDetailJob("");
                    handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_annual_report.modal_description")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setDescriptionModal(false);
                                setIndexDetailJob("");
                                handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_annual_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                  

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexDetailJob !== "" &&
                                            <Editor
                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listServiceJobNew;
                                                    detailJobs[indexDetailJob].description = evt;
                                                    setListEquipmentNew(detailJobs.slice(0))


                                                }}
                                                value={listServiceJobNew[indexDetailJob].description}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={findingsModal} onHide={
                () => {
                    setFindingsModals(false);
                    setIndexFindingModal("");
                    handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

                }
            }>
                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_annual_report.modal_finding")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setFindingsModals(false);
                                setIndexFindingModal("");
                                handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_annual_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                  

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>

                                        {
                                            indexFindingModal !== "" &&
                                            <Editor
                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listServiceJobNew;
                                                    detailJobs[indexFindingModal].findings = evt;
                                                    setListServiceJobNew(detailJobs.slice(0));
                                                }}
                                                value={listServiceJobNew[indexFindingModal].findings}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={recommendationModal} onHide={
                () => {
                    setRecommendationModal(false);
                    setIndexRecommendationModal("")
                    handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

                }
            }>
                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_annual_report.modal_recommendation")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setRecommendationModal(false);
                                setIndexRecommendationModal("")
                                handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_annual_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>

                                 
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>

                                        {
                                            indexRecommendationModal !== "" &&
                                            <Editor
                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listServiceJobNew;
                                                    detailJobs[indexRecommendationModal].recommendation = evt;
                                                    setListServiceJobNew(detailJobs.slice(0));
                                                }}
                                                value={listServiceJobNew[indexRecommendationModal].recommendation}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>


            <Modal size="xl" show={fotoModal} onHide={
                () => {
                    setFotoModal(false);
                    setIndexFotoModal("");
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_annual_report.modal_add_photo")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setFotoModal(false);
                                setIndexFotoModal("");

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_annual_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>


                                <Form >
                                    {
                                        indexFotoModal !== "" && listServiceJobNew[indexFotoModal].list_photo.map((foto, index) => {
                                            return (
                                                <div>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        borderWidth: 2,
                                                        borderRadius: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "rgba(3, 30, 103, 1)",
                                                        padding: 10
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <img src={foto.base64_image}
                                                                style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_annual_report.field_photo")}</Form.Label>
                                                                <Form.Control required type="file" onChange={async (e) => {

                                                                    let base64Img = await convertBase64Image(e.target.files[0]);
                                                                    const base64ImgString = base64Img.toString();
                                                                    let detailJobs = listServiceJobNew;
                                                                    detailJobs[indexFotoModal].list_photo[index].base64_image = base64ImgString;
                                                                    setListServiceJobNew(detailJobs.slice(0));

                                                                }}></Form.Control>
                                                            </Form.Group>

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            flex: 1

                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingRight: 5
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_annual_report.field_label")}</Form.Label>
                                                                    <Form.Control required type="text" onChange={(e) => {
                                                                        let detailJobs = listServiceJobNew;
                                                                        detailJobs[indexFotoModal].list_photo[index].label = e.target.value;
                                                                        setListServiceJobNew(detailJobs.slice(0))
                                                                    }} value={foto.label}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingLeft: 5

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_annual_report.field_taken_date")}</Form.Label>
                                                                    <Form.Control type="date" onChange={(e) => {
                                                                        let detailJobs = listServiceJobNew;
                                                                        detailJobs[indexFotoModal].list_photo[index].taken_date = e.target.value;
                                                                        setListServiceJobNew(detailJobs.slice(0))
                                                                        // handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);

                                                                    }} value={foto.taken_date ? moment(foto.taken_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                </Form.Group>
                                                            </div>


                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_annual_report.field_explanation")}</Form.Label>
                                                                <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                    let detailJobs = listServiceJobNew;
                                                                    detailJobs[indexFotoModal].list_photo[index].description = e.target.value;
                                                                    setListServiceJobNew(detailJobs.slice(0))
                                                                }} value={foto.description}></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            flex: 1,
                                                            paddingLeft: 5,
                                                            paddingRight: 5
                                                        }}>
                                                            <div style={{ paddingTop: 35 }}></div>
                                                            <Button onClick={() => {
                                                                if (window.confirm(`${t("detail_annual_report.delete_confirmation")}`)) {
                                                                    let detailJobs = listServiceJobNew;
                                                                    detailJobs[indexFotoModal].list_photo.splice(index, 1);
                                                                    setListServiceJobNew(detailJobs.slice(0))
                                                                }

                                                            }} variant="danger"><Trash size={20} /></Button>

                                                        </div>
                                                        <div style={{ paddingBottom: 10 }}></div>
                                                    </div>
                                                    <div style={{ paddingBottom: 10 }}></div>
                                                </div>



                                            )
                                        })
                                    }
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button variant="primary" onClick={() => {
                                            let newParameter = {
                                                base64_image: "",
                                                label: "",
                                                taken_date: null,
                                                description: ""
                                            };
                                            if (!listServiceJobNew[indexFotoModal].list_photo) {
                                                let newList = listServiceJobNew
                                                newList[indexFotoModal].list_photo.push(newParameter);
                                                setListServiceJobNew(newList.slice(0))
                                            } else {
                                                let newList = listServiceJobNew
                                                newList[indexFotoModal].list_photo.push(newParameter);
                                                setListServiceJobNew(newList.slice(0))
                                            }

                                        }}>{t("detail_annual_report.button_add_photo")}</Button>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            paddingRight: 5,
                                        }}><Button style={{width:100}} onClick={() => {
                                            setFotoModal(false);
                                            setIndexFotoModal("")
                                            handleOnBlur(serviceEquipmentNew, listServiceJobNew, index);
                                        }} variant="primary" >{t("detail_annual_report.save_button")}</Button></div>


                                        <div style={{
                                            paddingLeft: 5,
                                        }}>
                                            <Button style={{width:100}} variant="danger" onClick={() => {
                                                setFotoModal(false);
                                                setIndexFotoModal("")

                                            }}>{t("detail_annual_report.cancel_button")}</Button>

                                        </div>
                                    </div>
                                </Form>




                            </div>






                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal show={vesselEquipmentModal}
                // dialogClassName="modal-size"
                size={"lg"}
                onHide={() => {
                    setVesselEquipmentModal(false);

                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("detail_annual_report.modal_add_vessel_equipment")} </h3>
                    </div>

                    <Form onSubmit={(e) => {
                        // e.preventDefault();


                    }} style={{ padding: 10 }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_annual_report.field_model")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselEquipment({ ...vesselEquipment, model: e.target.value })
                            }} value={vesselEquipment.model} type="text" placeholder="" required></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Nama Pabrikan</Form.Label>
                            <Select placeholder={""}
                                getOptionLabel={(item) => {
                                    return item.manufacturer_name;
                                }} clearValue={true}
                                getOptionValue={(item) => {
                                    return item.id;
                                }}
                                options={listManufacturer} value={selectedManufacturer} onChange={(e) => {
                                    if (e === null) {
                                        setSelectedManufacturer({})
                                    } else {
                                        setSelectedManufacturer(e);
                                    }
                                }}
                                isClearable
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_annual_report.field_equipment_position")}</Form.Label>
                            <Form.Select onChange={(e) => {
                                if (e.target.value !== "") {
                                    setVesselEquipment({ ...vesselEquipment, equipment_position_id: e.target.value })
                                } else {
                                    setVesselEquipment({ ...vesselEquipment, equipment_position_id: null })
                                }
                            }} value={vesselEquipment.equipment_position_id} >
                                <option selected value="">
                                {t("detail_annual_report.choose_equipment_position")}
                                </option>

                                {listEquipmentPosition.map((position, index) => (
                                    <option key={index} value={position.id}>{position.position}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>


                        <div style={{ paddingBottom: 10 }}></div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <Button style={{width:100}} onClick={() => {
                                saveDataVesselEquipment();
                                setDisabledButton(true);
                            }} type="submit" disabled={disabledButton}>{t("detail_annual_report.save_button")}</Button>
                            <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {

                                setVesselEquipmentModal(false);
                            }}>{t("detail_annual_report.cancel_button")}</Button>
                        </div>

                    </Form>



                </div>

            </Modal>

        </div>
    )
}



export default ServiceReportEquipment;
