import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import SMSNavContainerFireAlarm from "../../../Components/SMSNavContainerFireAlarm/SMSNavContanerFireAlarm";
import SMSFireAlarmWheelhouse from "../SMSFireAlarmWheelhouse";
import SMSFireAlarmMainDeck from "../SMSFireAlarmMainDeck";
import SMSFireAlarmBelowMainDeck from "../SMSFireAlarmBelowMainDeck";

export default function SMSFireAlarm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [currentChild, setCurrentChild] = useState(null);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [titleBar, setTitleBar] = useState("");

    useEffect(() => {
        async function checkCookies() {
          let isAuthenticated = await isTokenValid(cookies.token);
          if (!isAuthenticated)
            navigate("/");
          else {
            if (location.state.smsSerialnumber) {
              setSmsSerialNumber(location.state.smsSerialnumber);
              // setVesselId(location.state.vesselId);
            } else {
              //navigate("/");
            }
          }
        }
        checkCookies();
        console.log(cookies);
        // console.log(vesselId);
    }, []);

    const handleMenuClick = (val) => {
        console.log(val);
        setActiveTab(val);
      }
    
      useEffect(() => {
        if (smsSerialNumber !== "") {
          setCurrentChild(<SMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
        }
      }, [smsSerialNumber]);


    useEffect(() => {
        if (smsSerialNumber !== "") {
          switch (activeTab) {
            case 0:
              setCurrentChild(<SMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
              break;
            case 1:
              setCurrentChild(<SMSFireAlarmMainDeck smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
              break;
            case 2:
              setCurrentChild(<SMSFireAlarmBelowMainDeck smsSerialNumber={smsSerialNumber} vesselId={location.state.vesselId}/>);
              break;
            default:
              return (null);
              break;
          }
        }
      }, [activeTab]);

    return (
        <>
            <SMSNavContainerFireAlarm
                handleMenuClick={handleMenuClick}
                activeTab={activeTab}
                childContent={currentChild ? currentChild : null}
                smsSerialNumber={smsSerialNumber}
            />
      <Loading loading={false} />
        </>
    );
}