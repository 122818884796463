import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid,
    getStaffBirthDateForCalender, getPublishContent, getCountPublishContent, getPublishWisdomWord,
    getCarouselForDashboard, getAboutUs
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Paginations from "../../Components/Pagination";
import { XSquareFill } from "react-bootstrap-icons";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "../../react-big-calendar.css";
import Carousel from 'react-bootstrap/Carousel';
import WHSLogo from "../../Assets/Images/WHS-logo.png"
import RadianLogo from "../../Assets/Images/rpi-logo.svg"
import Quantum from "../../Assets/Images/quantums.png"
import AvatarIcon from "../../Assets/Images/default.jpg"
import { Trans, useTranslation } from "react-i18next";


export default function BlogPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const localizer = momentLocalizer(moment);
    const [calendarEvent, setCalendarEvent] = useState([]);
    const [aboutUs, setAboutUs] = useState(null);
    const [listCarousel, setListCarousel] = useState([]);
    const [listPublishContent, setListPublishContent] = useState([]);
    const [listStaffBirthDate, setListStaffBirthDate] = useState([]);
    const { t, i18n } = useTranslation();
    const [publishWisdomWord, setPublishWisdomWord] = useState({});
    const [showStaffDetail, setShowStaffDetail] = useState("");
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState({
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
    });

    const [staffDetailToBeViewed, setStaffDetailToBeViewed] = useState({
        staff_name: "",
        date_of_birth: "",
        photo_base64: ""
    })
    const [isSearched, setIsSearched] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(3);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([3, 6, 9]);
    const [orderBy, setOrderBy] = useState({
        label: "Judul Konten",
        value: "content_title"
    });
    const orderByList = [{
        label: "Judul Konten",
        value: "content_title"
    },
    {
        label: "Tanggal Konten",
        value: "content_date"
    },
    {
        label: "Tanggal Publish",
        value: "publish_date"
    }];
    const [descending, setDescending] = useState(false);


    useEffect(() => {
        // if (isSearched !== 0)
        loadPublishContent();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadPublishContent();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadPublishContent();
    }, [orderBy]);


    useEffect(() => {
        if (resetSearch !== 0) {
            loadPublishContent()
        }
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0)
            loadPublishContent()
    }, [descending]);



    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                loadPublishContent();
                loadStaffBirthDate();
                loadPublishWisdomWord();
                loadCarouselDashboard();
                loadAboutUs();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);


    const loadPublishContent = async () => {
        try {
            let count = await getCountPublishContent(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let listContent = await getPublishContent(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListPublishContent(listContent)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {

        }
    }

    const loadAboutUs = async () => {
        try {
            let response = await getAboutUs(cookies.token);
            setAboutUs(response[0])
        } catch (exception) {

        }
    }
    const loadCarouselDashboard = async () => {
        try {
            let response = await getCarouselForDashboard(cookies.token);
            for (let index = 0; index < response.length; index++) {
                const list = response[index];
                let img = document.createElement("img")
                img.setAttribute("src", list.base64_image)

                console.log(img.height);
            }
            setListCarousel(response);
        } catch (exception) {

        }
    }

    const loadPublishWisdomWord = async () => {
        try {
            let response = await getPublishWisdomWord(cookies.token);
            setPublishWisdomWord(response);
        } catch (exception) {

        }
    }

    const loadStaffBirthDate = async () => {
        try {
            let response = await getStaffBirthDateForCalender(cookies.token);
            setListStaffBirthDate(response);
            let listEvent = []
            for (let index = 0; index < response.length; index++) {
                const listBirthday = response[index];
                let dates = new Date();
                let birthDate = new Date(listBirthday.date_of_birth)
                let year = dates.getFullYear();
                let month = birthDate.getMonth() + 1;
                let date = birthDate.getDate();
                let fullDate = year + '-' + month + '-' + date
                let evt = {
                    id: listBirthday.id,
                    title: `${listBirthday.staff_name}`,
                    allDay: true,
                    start: new Date(fullDate),
                    end: new Date(fullDate),
                    event_type: "ulang_tahun",
                    detail: listBirthday,
                };
                listEvent.push(evt);
            }
            setCalendarEvent(listEvent);
        } catch (exception) {

        }
    }

    const getDayProps = (e) => {
        let dayProps = {
            style: {

            }
        };
        let evtIdx = listStaffBirthDate.findIndex(p => new Date(p.date_of_birth).getDate() === new Date(e).getDate() &&
            new Date(p.date_of_birth).getMonth() === new Date(e).getMonth());
        if (evtIdx) {
            dayProps.style = {
                backgroundColor: "#fff"
            }
        } else {
            dayProps.style = {
                backgroundColor: "#74ad31"
            }
        }
        return (dayProps);
    }

    const getEventProps = (e) => {
        let eventProps = {
            style: {

            }
        };
        if (e.event_type === "ulang_tahun") {
            eventProps.style = {
                backgroundColor: "#b1eb34",
                color: "black"
            }
        }
        // else if (e.event_type === "survey_due_date") {
        //     eventProps.style = {
        //         backgroundColor: "#ff0000"
        //     }
        // } else if (e.event_type === "scheduled_survey_date") {
        //     eventProps.style = {
        //         backgroundColor: "#00bfff"
        //     }
        // }

        return (eventProps);
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                width: "90%"
            }}>

                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start"
                    }}>
                        <h2 className="dashboard-main-header">{`${t("index_blog_page.header_title")}, ${cookies.userName} !`}</h2>
                    </div>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end"
                    }}>
                        <Clock
                            className="dashboard-main-header"
                            format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                            ticking={true}
                            timezone={'Asia/Jakarta'} />
                    </div>
                </div>
                {
                    listCarousel.length > 0 &&
                    <div style={{
                        display: "flex",
                        width: "100%",
                        height: "50%",
                        paddingBottom: 20,
                        alignSelf: "center",
                        justifyContent: "center",
                        backgroundColor: "#ffffffee",
                        borderBottomStyle: "groove"
                    }}>
                        <Carousel >

                            {
                                listCarousel.map((list, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <img
                                                className="d-block w-100"
                                                src={list.base64_image}
                                                alt="Third slide"
                                                height={550}
                                            />
                                            {/* <Carousel.Caption> */}
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                position: "relative",
                                                zIndex: 999,
                                                justifyContent: "center",
                                                textAlign: "center",
                                                // color: "black"
                                            }}>
                                                <h5>{list.image_title}</h5>
                                                <div>
                                                    {list.description}
                                                </div>
                                            </div>

                                            {/* </Carousel.Caption> */}
                                        </Carousel.Item>

                                    )
                                })
                            }

                        </Carousel>

                    </div>
                }

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#ffffffee",
                    borderBottomStyle: "groove",

                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        // padding: 10
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            width: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                borderRightStyle: "groove"
                            }}>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 10,
                                    // padding:10

                                }}>

                                    {
                                        listPublishContent.map((content, index) => {
                                            return (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    paddingBottom: 20,
                                                    paddingRight: 5
                                                    // padding: 15
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        backgroundColor: "white",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: 10,

                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                minHeight: 300,
                                                                borderStyle: "groove",
                                                                minWidth: 300,
                                                            }}>

                                                                {
                                                                    content.photo_base64 !== "" && content.photo_base64 !== null &&
                                                                    <img src={content.content_photo} width={400} height={300}></img>
                                                                }

                                                            </div>

                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            width: "100%",
                                                            padding: 10,

                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                borderBottomStyle: "groove"
                                                            }}>
                                                                {
                                                                    content.content_title &&
                                                                    <div dangerouslySetInnerHTML={{ __html: content.content_title }}></div>
                                                                }

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",

                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                }}>
                                                                    {
                                                                        content.content_date &&
                                                                        <div>{t("index_blog_page.event_date")} : {moment(content.content_date).format("DD-MM-yyyy")}</div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column"
                                                            }}>
                                                                {
                                                                    content.content_description &&
                                                                    <div className="content-elipsis" dangerouslySetInnerHTML={{ __html: content.content_description }}></div>
                                                                }


                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                // paddingTop: 5,
                                                                marginBottom: "auto"
                                                            }}>
                                                                <Link to={`/Blog/Detail?id=${content.id}`} target="_blank">
                                                                    <Button >{t("index_blog_page.content_button")}</Button>
                                                                </Link>

                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                paddingTop: 5,

                                                            }}>
                                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}>
                                                                        {
                                                                            content.publish_date &&
                                                                            <div>{t("index_blog_page.publish_date")} : {moment(content.publish_date).format("DD-MM-yyyy")}, </div>
                                                                        }

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5
                                                                    }}>
                                                                        {
                                                                            content.login_username &&
                                                                            <div>{t("index_blog_page.publish_by")} : {content.login_username} </div>
                                                                        }

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        listPublishContent.length > 0 &&
                                        <div style={{ display: "flex", flexDirection: "column", paddingLeft: 10 }}>
                                            <Paginations
                                                // itemPerPage={itemPerPage}
                                                totalPage={totalPage}
                                                page={page}
                                                setPage={setPage}
                                                // setItemPerPage={setItemPerPage}
                                                // itemPerPageSelection={itemPerPageSelection}
                                                // orderBy={orderBy}
                                                // setOrderBy={setOrderBy}
                                                // orderBySelection={orderByList}
                                                isDescActive={descending}
                                                setIsDescActive={setDescending}
                                                setIsSearched={setIsSearched}
                                            /></div>

                                    }


                                </div>

                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 4,
                                padding: 5,
                                // justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    height: 450,
                                    width: 480,
                                    paddingBottom: 10,
                                    fontSize: 10
                                }}>
                                    <Calendar
                                        localizer={localizer}
                                        events={calendarEvent}
                                        defaultDate={new Date()}
                                        selectable
                                        popup
                                        startAccessor="start"
                                        endAccessor="end"
                                        messages={{
                                            agenda: `${t("index_blog_page.calendar_agenda")}`,
                                            allDay: `${t("index_blog_page.calendar_allday")}`,
                                            month: `${t("index_blog_page.calendar_month")}`,
                                            day: `${t("index_blog_page.calendar_day")}`,
                                            today: `${t("index_blog_page.calendar_today")}`,
                                            previous: `${t("index_blog_page.calendar_before")}`,
                                            next: `${t("index_blog_page.calendar_next")}`,
                                            date: `${t("index_blog_page.calendar_date")}`,
                                            noEventsInRange: `${t("index_blog_page.event_desc")}`,
                                            time: `${t("index_blog_page.calendar_periode")}`,
                                            tomorrow: 'Besok',
                                            week: `${t("index_blog_page.calendar_week")}`,
                                            work_week: 'Hari Kerja',
                                            yesterday: 'Kemarin',
                                            event: `${t("index_blog_page.birthday_person")}`

                                        }}
                                        onSelectEvent={(e) => {
                                            setStaffDetailToBeViewed(e.detail);
                                            setShowStaffDetail(true);
                                        }}
                                        onRangeChange={(e) => {
                                            // // console.log(e);
                                            // setDateRangeCrew(e);
                                        }}
                                        // dayPropGetter={getDayPropsCrew}
                                        // eventPropGetter={getEventPropsCrew}
                                        dayProps={getDayProps}
                                        eventPropGetter={getEventProps}
                                    />
                                </div>
                                {
                                    publishWisdomWord &&
                                    <div style={{ display: "flex", flexDirection: "column", borderStyle: "groove", borderRadius: "20%" }}>
                                        <div style={{ fontSize: 20, justifyContent: "center", textAlign: "center", paddingTop: 20 }} dangerouslySetInnerHTML={{ __html: publishWisdomWord.description }}></div>
                                        <div style={{ fontSize: 18, justifyContent: "center", textAlign: "center" }}>- {publishWisdomWord.author} -</div>
                                    </div>
                                }

                            </div>

                        </div>


                    </div>

                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#ffffffee",
                    padding: 20
                    // borderBottomStyle:"groove",

                }}>
                    {
                        aboutUs &&
                        <div dangerouslySetInnerHTML={{ __html: aboutUs.description }}></div>
                    }
                </div>

                <Modal size={"s"} show={showStaffDetail} onHide={() => {
                    setShowStaffDetail(false);
                }}>

                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Detail Staf`}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowStaffDetail(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Tutup</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    justifyContent: "center",
                                    width: "100%",
                                    alignItems: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        minHeight: 300,
                                        borderStyle: "groove",
                                        minWidth: 300
                                    }}>
                                        {
                                            staffDetailToBeViewed.photo_base64 === "" ?
                                                <img src={AvatarIcon} width={300} height={300}></img>
                                                :
                                                <img src={staffDetailToBeViewed.photo_base64} width={300} height={300}></img>
                                        }

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        {staffDetailToBeViewed.staff_name}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        {staffDetailToBeViewed.date_of_birth}
                                    </div>
                                </div>

                            </div>
                        }
                    />

                </Modal>

                <Loading
                    loading={loading}
                />
            </Container>
        </>
    );

}