import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getLanguagebyId, getLanguage, insertUpdateLanguage, getUserAccessById } from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import { omit } from 'lodash';
import Navbar from "../../Components/NavBar";
import FormSelect from "../../Components/FormSelect";
import ContainerBox from "../../Components/ContainerBox";
import Loading from "../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function LanguageSetting() {
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState({});
    const [listLanguage, setListLanguage] = useState([]);
    const [language, setLanguage] = useState({
        language_id: "",
        language_code: ""
    })
    const [changeLanguage, setChangeLanguage] = useState(0);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                console.log(cookies.language_code)
                loadLanguage();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

            }
        }
        checkCookies();
        if (location.state.languageId === "") {

        } else {
            initLanguage();
        }
    }, []);

    useEffect(()=>{
        if(changeLanguage !== 0){
            updateLanguage();
        }
    },[changeLanguage])

    useEffect(() => {
        if (language.language_id === undefined || language.language_id === "") {
            setErrors({
                ...errors,
                languageError: `${t("language_setting.error_empty_field")}`
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "languageError");
            setErrors(errorMsg);
            setDisabledButton(false);
        }
    }, [language])

    const loadLanguage = async () => {
        try {
            let response = await getLanguage(cookies.token);
            setListLanguage(response);

        } catch (exception) {
            console.log(exception)

        }
    }
    
    const updateLanguage = async () =>{
        try {
            console.log(language.language_id);
            console.log(language.language_code);
            setCookie("languageId", language.language_id, { path: "/" });
            setCookie("language_code", language.language_code, { path: "/" });
            setChangeLanguage(0);
            window.location.reload();
        } catch (error) {
            
        }
    }

    const initLanguage = async () => {
        try {
            let response = await getLanguagebyId(cookies.token, cookies.languageId);
            console.log(response);
            if (response) {
                setLanguage({
                    ...language,
                    language_id: response.id,
                    language_code: response.language_iso_code
                })
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("language_setting.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Language"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }

                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("language_setting.header_language")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>

                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            setDisabledButton(true);
                                                            setChangeLanguage(1);
                                                            setLoading(true);
                                                          
                                                        }}>
                                                            <FormSelect caption={`${t("language_setting.field_language")}`}
                                                                placeholder=""
                                                                options={listLanguage}
                                                                getOptionLabel={
                                                                    (item) => {
                                                                        return item.language_name;
                                                                    }
                                                                }
                                                                getOptionValue={
                                                                    (item) => {
                                                                        return item.id;
                                                                    }
                                                                }
                                                                value={listLanguage.filter(function (option) {
                                                                    return option.id === language.language_id;
                                                                })} setValueFunction={(e) => {
                                                                    setLanguage({ ...language, language_id: e.id, language_code: e.language_iso_code });
                                                                }}
                                                            />
                                                            {
                                                                errors.languageError && <p style={{ color: "red" }}>{errors.languageError}</p>
                                                            }

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                padding: 5
                                                            }}>

                                                                <Button style={{ width: 100 }} type="submit" variant="primary" disabled={disabledButton} >{t("language_setting.save_button")}</Button>

                                                                <Button style={{ width: 100 }} className="cancel" type="reset" variant="danger" >{t("language_setting.cancel_button")}</Button>

                                                            </div>
                                                        </Form>



                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>


                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}