// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meterBar {
    border: 2px solid #298BFE;
    width: 44px;
    height: 175px;
    border-radius: 7px;
    display: flex;
    align-items: flex-end;
}

.valueBarStyle {
    background: #298BFE;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.textBarStyle {
    color: white;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/BarMeter/BarMeter.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,8BAA8B;IAC9B,+BAA+B;IAC/B,aAAa;IACb,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".meterBar {\n    border: 2px solid #298BFE;\n    width: 44px;\n    height: 175px;\n    border-radius: 7px;\n    display: flex;\n    align-items: flex-end;\n}\n\n.valueBarStyle {\n    background: #298BFE;\n    width: 100%;\n    border-bottom-left-radius: 5px;\n    border-bottom-right-radius: 5px;\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n}\n\n.textBarStyle {\n    color: white;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
