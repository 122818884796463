import "./NewSMSNavContainerRight.css"
import { useEffect, useState } from "react";
import LampNavButton from "../NewLampNavButtonRight/NewLampNavButtonRight";
import NavButton from "../NavButton/NavButton";
import { useNavigate } from "react-router-dom";
import Clock from 'react-live-clock';

export default function SMSNavContainerRight(props) {
    const { smsSerialNumber, activeTab, childContent, handleMenuClick, data, connectionStatus } = props;
    // const [ datasmsSerialNumber, setDataSmsSerialNumber] = useState(props.smsSerialNumber);
    // const navigate = useNavigate();
    // const navigateMainEngine = () => { navigate("/MainEngine") }
    // const navigateAlarm = () => { navigate("/Alarm", { state: { datasmsSerialNumber: props.smsSerialNumber } });  }
    const csMEP = connectionStatus.find(cs => cs.coordinate_type === "MEP");
    const csMES = connectionStatus.find(cs => cs.coordinate_type === "MES");
    const csAEP = connectionStatus.find(cs => cs.coordinate_type === "AEP");
    const csAES = connectionStatus.find(cs => cs.coordinate_type === "AES");
    useEffect(() => {
        console.log('---------------connectionStatus-----------------', connectionStatus);
    }, []);

    return (
        <>
            <div className="mainMainSMSDashboardContainerRight">
                <div className="rightNavBarContainer">
                    <LampNavButton text1={"CPU"} text2={""} />
                    <LampNavButton text1={"DATA"} text2={"ACQUISITION UNIT"} />
                    {/* <LampNavButton text1={"MAIN ENGINE"} text2={"PORT COMM"} lamp={csMEP.reading_value !== "" && csMEP.reading_value !== "0" && csMEP.reading_value !== "false"} />
                    <LampNavButton text1={"MAIN ENGINE"} text2={"STBD COMM"} lamp={csMES.reading_value !== "" && csMES.reading_value !== "0" && csMES.reading_value !== "false"} />
                    <LampNavButton text1={"AUX ENGINE"} text2={"PORT COMM"} lamp={csAEP.reading_value !== "" && csAEP.reading_value !== "0" && csAEP.reading_value !== "false"} />
                    <LampNavButton text1={"AUX ENGINE"} text2={"STBD COMM"} lamp={csAES.reading_value !== "" && csAES.reading_value !== "0" && csAES.reading_value !== "false"} /> */}
                    <LampNavButton text1={"MAIN ENGINE"} text2={"PORT COMM"}  />
                    <LampNavButton text1={"MAIN ENGINE"} text2={"STBD COMM"}  />
                    <LampNavButton text1={"AUX ENGINE"} text2={"PORT COMM"}  />
                    <LampNavButton text1={"AUX ENGINE"} text2={"STBD COMM"}  />
                    <LampNavButton text1={"ANALOG"} text2={"MODULE 1"} />
                    <LampNavButton text1={"ANALOG"} text2={"MODULE 2"} />
                    <LampNavButton text1={"ANALOG"} text2={"MODULE 3"} />
                    <LampNavButton text1={"ANALOG"} text2={"MODULE 4"} />
                    
                    {/* <NavButton text1={"ALARM"} handleOnClick={navigateAlarm} /> */}
                </div>
                {/* <div className="rightSMSDasboardContainer">
                    <div className="containerBoxTop">
                        <p style={{color: 'white', fontSize: 'medium', fontWeight: 'lighter'}}><Clock
                            format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                            ticking={true}
                            timezone={'Asia/Jakarta'} /></p>
                        

                    </div>
                    <div className="containerBoxBottom">
                        {props.childContent}
                    </div>
                </div> */}
            </div>
        </>
    )
}