import "../../SMSApp.css";
import "./index.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import BitLamp from "../../Components/BitLamp/BitLamp";

export default function GearBox() {
    return (
        <>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"GEAR BOX"} />
            </div>
            <div className="contentGearBox">
                <div className="leftContentGearBox">
                    <div className="titleContent">Gear Box PORT</div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Oil Temp High"} />
                    </div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Trust Bear Temp Gear High"} />
                    </div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Cool Water Press Low"} />
                    </div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Oil Press Clutches Low"} />
                    </div>
                </div>
                <div className="rightContentGearBox">
                    <div className="titleContent">Gear Box STBD</div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Oil Temp High"} />
                    </div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Trust Bear Temp Gear High"} />
                    </div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Cool Water Press Low"} />
                    </div>
                    <div className="bitLampContainer">
                        <BitLamp text={"Oil Press Clutches Low"} />
                    </div>
                </div>
            </div>
            <div style={{paddingBottom:120}}></div>
            <div>
                <BottomNavBar />
            </div>
        </>
    );
}