import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, getCountInventoryType } from "../../../Helpers/ApplicationHelpers";
import { getInventoryStockCount, getInventoryStock, deleteInventoryStock, getInventoryMasterCount, getInventoryMaster, getInventoryMaterial, getInventoryMaterialCount, deleteInventoryMaterial, getInventoryTypeById, insertUpdateInventoryMaterial, checkMaterialCodeExists } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search, ArrowBarRight, ArrowUpRight, ArrowUpRightSquare, BoxArrowUpRight } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import PaginationsInventory from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

export default function InventoryProductionMaterialPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [division, setDivision] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState();
    const [inventoryProductionMaterialCount, setInventoryProductionMaterialCount] = useState();
    const [listInventoryProductionMaterial, setListInventoryProductionMaterial] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [totalPage, setTotalPage] = useState();
    const [dateNow, setDateNow] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [descending, setDescending] = useState(true);
    const [resetSearch, setResetSearch] = useState(0);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("")
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_production_material.order_name2")}`,
        value: "inventory_master_name"
    });
    const orderByList = [{
        label: `${t("index_inventory_production_material.order_name1")}`,
        value: "inventory_group_name"
    }];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryProductionMaterial();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        // setLoading(true);
        console.log(page);
        loadInventoryProductionMaterial();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryProductionMaterial();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            // setLoading(true);
            loadInventoryProductionMaterial();
        }
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadInventoryProductionMaterial();
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryProductionMaterial();
        }
    }, [descending]);

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_consumeable.order_name2")}`,
                value: "inventory_master_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInventoryProductionMaterial = async () => {
        try{
            console.log(cookies)
            let count = await getInventoryStockCount(cookies.token, cookies.languageId, searchQuery, false, true);
            console.log(count);
            setInventoryProductionMaterialCount(count);
            let totalPage = inventoryProductionMaterialCount / itemPerPage;
            setTotalPage(totalPage);
            let tmpSearchQuery = undefined;
            if (searchQuery) {
                tmpSearchQuery = searchQuery;
            }
            
            let response = await getInventoryStock(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false, true);
            setListInventoryProductionMaterial(response);
            console.log(response);
            let dates = new Date()
            setDateNow(moment(dates).format("DD-MM-yyyy HH:mm"))
        } catch(exception) {

        }
    }

    const deleteMaterial = async (inventoryStockId) => {
        try {
            let response = await deleteInventoryStock(cookies.token, inventoryStockId)
            if (response.error_code === 0) {
            alert(`${t("inventory_stock.delete_success_alert")}`)
            loadInventoryProductionMaterial();
        }
        } catch (exception) {
            console.log(exception);
        }
        
    }
    return(
        <>
        <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_inventory_production_material.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button 
                            disabled={false} 
                            // disabled={!updatedButton} 
                            variant="secondary" style={{

                            }} onClick={() => {
                                navigate("/InventoryStock", {state: {inventoryStockId: "", inventoryMasterName: "", isProductionMaterial: true}})
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_assets.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_inventory_production_material.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                // setLoading(true);
                                                await loadInventoryProductionMaterial();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            // setPage(0)
                                            // setLoading(true);
                                            // await loadInventoryMaterial();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            // setLoading(true);
                                            // setSearchQuery("");
                                            // // await loadInventoryType();
                                            // setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_production_material.table_header_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_production_material.table_header_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_production_material.table_header_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_production_material.table_header_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_production_material.table_header_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_production_material.table_header_name6")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_production_material.table_header_name7")}</div>
                                </div>
                                {
                                    listInventoryProductionMaterial.map((assets, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{assets.inventory_master_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{assets.inventory_group_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{assets.inventory_subgroup_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{assets.inventory_material_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{assets.brand_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // setInventoryMaterialDetail({
                                                        //     id: material.id,
                                                        //     inventory_material_name: material.inventory_material_name,
                                                        //     inventory_material_code: material.inventory_material_code,
                                                        //     inventory_material_desc: material.inventory_material_desc
                                                        // });
                                                        // setShowDetailModal(true);

                                                        navigate("/InventoryStock", {state: {inventoryStockId: assets.id, inventoryMasterName: assets.inventory_master_name}})
                                                    }}><PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                        onClick={event => {
                                                            alert(`${t("inventory_stock.delete_confirmation")}`)
                                                            deleteMaterial(assets.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            {/* <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            /> */}
                        </div>
                    } />
            </Container>
        </>
    )
}