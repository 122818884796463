// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bitLamp {
    display: flex;
    align-items: center;
}

.bitLampOn {
    background: #40C110;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.bitLampOff {
    background: #E72D2D;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.bitlampRed {
    background: #E72D2D;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}


.bitlampGreen {
    background: #40C110;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.bitlampYellow {
    background: yellow;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.bitlampBlack {
    background: black;
    border: 0.8px solid #050512;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.textBitLamp {
    /* font-size: 16px; */
    color: aliceblue;
    margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/BitLamp/BitLamp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".bitLamp {\n    display: flex;\n    align-items: center;\n}\n\n.bitLampOn {\n    background: #40C110;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n}\n\n.bitLampOff {\n    background: #E72D2D;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n}\n\n.bitlampRed {\n    background: #E72D2D;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n}\n\n\n.bitlampGreen {\n    background: #40C110;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n}\n\n.bitlampYellow {\n    background: yellow;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n}\n\n.bitlampBlack {\n    background: black;\n    border: 0.8px solid #050512;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n}\n\n.textBitLamp {\n    /* font-size: 16px; */\n    color: aliceblue;\n    margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
