import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getCountry, getLanguagebyId, insertUpdateLanguage, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import FormSelect from "../../../../Components/FormSelect";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function LanguageDetailPage() {
    const [countryOptions, setCountryOptions] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState({});
    const [listLanguage, setListLanguage] = useState([]);
    const [listCountry, setListCountry] = useState([]);
    const [language, setLanguage] = useState({
        country: "",
        language_name: "",
        language_iso_code: ""
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCountry();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

            }
        }
        checkCookies();
        if (location.state.languageId === "") {

        } else {
            initLanguage();
        }
    }, []);

    useEffect(() => {
        if (language.country === undefined || language.country === "") {
            setErrors({
                ...errors,
                countryName: `${t("detail_language.error_empty_field")}`
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "countryName");
            setErrors(errorMsg);
            setDisabledButton(false);
        }
    }, [language])

    const loadCountry = async () => {
        try {
            let listCountry = await getCountry(cookies.token);
            setListCountry(listCountry);
            let listCountryNew = [];
            for (let i = 0; i < listCountry.length; i++) {
                var obj = {
                    value: listCountry[i].id,
                    label: listCountry[i].country_name,
                    id: listCountry[i].id

                };

                listCountryNew.push(obj);

            }
            setCountryOptions(listCountryNew);

        } catch (exception) {
            console.log(exception)

        }
    }

    const initLanguage = async () => {
        try {
            let response = await getLanguagebyId(cookies.token, location.state.languageId);
            console.log(response);
            if (response) {
                setLanguage({
                    ...language,
                    country: response.country_id,
                    language_name: response.language_name,
                    language_iso_code: response.language_iso_code

                })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const addLanguage = async () => {
        try {
            let response = await insertUpdateLanguage(cookies.token, language, location.state.languageId);
            if (response === 0) {
                alert(`${t("detail_language.alert_success_save_data")}`);
                navigate("/Language");
            } else {
                alert(`${t("detail_language.alert_failed_save_data")}`)
            }
            setLoading(false);
            setDisabledButton(false);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if(response){
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_language.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Language"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }

                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_language.container_language_detail")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>

                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            addLanguage();
                                                            setDisabledButton(true);
                                                        }}>
                                                            <FormSelect caption={`${t("detail_language.field_name_country")}`} autoFocusValue={language.country === undefined} placeholder=""
                                                                options={countryOptions} value={countryOptions.filter(function (option) {
                                                                    return option.id === language.country;
                                                                })} setValueFunction={(e) => {
                                                                    setLanguage({ ...language, country: e.id });
                                                                }}
                                                            />
                                                            {
                                                                errors.countryName && <p style={{ color: "red" }}>{errors.countryName}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_language.field_name_language")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setLanguage({ ...language, language_name: e.target.value })
                                                                }} type="text" value={language.language_name} required></Form.Control>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_language.field_name_iso_code")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setLanguage({ ...language, language_iso_code: e.target.value })
                                                                }} type="text" value={language.language_iso_code} required ></Form.Control>
                                                            </Form.Group>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                padding: 5
                                                            }}>

                                                                <Button style={{width:100}} type="submit" variant="primary" disabled={disabledButton === true ? disabledButton : !updatedButton} >{t("detail_language.save_button")}</Button>

                                                                <Button style={{width:100}} className="cancel" type="reset" variant="danger" >{t("detail_language.cancel_button")}</Button>

                                                            </div>
                                                        </Form>



                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>


                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}