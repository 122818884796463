import React, { useState, useEffect } from "react";
import { Button, Form, Container, Col, Row, Table, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVesselById, insertUpdateVessel, getCompany,
    getVesselType, getCountry, getVessel, getEquipment, getCustomer, getUserAccessById,
    getVesselShiftSession
} from "../../../../Helpers/ApplicationHelpers";
import { getVesselClassificationSociety, getVesselClassificationCharacter, getVesselClassificationNotation } from "../../../../Helpers/VesselClassification.helpers";
import {
    ArrowLeft, FileCheckFill, PencilFill, Trash, CameraFill, CalendarEventFill,
    FilePlusFill, InfoCircleFill, ArrowClockwise, GearWideConnected
} from "react-bootstrap-icons";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import { omit } from 'lodash';
import ContainerBox from "../../../../Components/ContainerBox";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import ButtonForm from "../../../../Components/ButtonForm";
import Loading from "../../../../Components/Loading";
import FormSelect from "../../../../Components/FormSelect";
import { Trans, useTranslation } from "react-i18next";

export default function VesselDetailPage() {
    const [vesselId, setVesselId] = useState(0);
    const [vesselTypeOptions, setVesselTypeOptions] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [, updateState] = React.useState();
    const [listShift, setListShift] = useState([]);
    const { t, i18n } = useTranslation();
    const [updatedButton, setUpdatedButton] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [listCustomer, setListCustomer] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [showCharacterClassModal, setShowCharacterClassModal] = useState(false);
    const [showNotationClassModal, setShowNotationClassModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [listCharacter, setListCharacter] = useState([]);
    const [listSearchedCharacter, setListSearchedCharacter] = useState([]);
    const [listSearchedSelectedCharacter, setListSearchedSelectedCharacter] = useState([]);
    const [listNotation, setListNotation] = useState([]);
    const [listSearchedNotation, setListSearchedNotation] = useState([]);
    const [listSearchedSelectedNotation, setListSearchedSelectedNotation] = useState([]);
    const [listSelectedCharacter, setListSelectedCharacter] = useState([]);
    const [listSelectedNotation, setListSelectedNotation] = useState([]);
    const [searchListCharacterQuery, setSearchListCharacterQuery] = useState("");
    const [searchListSelectedCharacterQuery, setSearchListSelectedCharacterQuery] = useState("");
    const [searchListNotationQuery, setSearchListNotationQuery] = useState("");
    const [searchListSelectedNotationQuery, setSearchSelectedListNotationQuery] = useState("");
    const [characterSaveButtonDisabled, setCharacterSaveButtonDisabled] = useState(false);
    const [notationSaveButtonDisabled, setNotationSaveButtonDisabled] = useState(false);

    let listCharacter2 = [];
    let listNotation2 = [];


    const [vessel, setVessel] = useState({
        vessel_name: "",
        vessel_type_id: "",
        imo_number: "",
        serial_number: "",
        built_date: "",
        built_shipyard: "",
        vessel_code: "",
        gross_register_tonnage: 0,
        deadweight_tonnage: 0,
        lbp: 0,
        bmld: 0,
        shift_session_id: null,
        hmld: 0,
        callsign: "",
        flag: "",
        customer_id: null,
        class_register_number: "",
        ext_customer_id: cookies.extCustomerId
    })
    const [listCountry, setListCountry] = useState([]);
    const [listVesselType, setListVesselType] = useState([]);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadVesselShiftSession();
                if (location.state.vesselId === 0) {
                    setLoading(false);
                } else {
                    setVesselId(location.state.vesselId);
                    if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                        setDisabledCustomerForm(true);
                    }
                }
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();

    }, []);

    useEffect(() => {
        async function handleVesselIdChange() {
            loadCountry();
            loadCustomer();
            loadVesselType();
            loadAccess();
            await loadCharacter();
            await loadNotation();
            if (vesselId === 0) {
                setListSelectedCharacter([]);
                setListSelectedNotation([]);
                setListSearchedSelectedCharacter([]);
                setListSearchedSelectedNotation([]);
            } else {
                await initVessel();
            }
        }
        handleVesselIdChange();
    }, [vesselId]);

    useEffect(() => {
        validationForm();
    }, [vessel]);

    useEffect(() => {
        loadCharacterByQuery(false);
    }, [searchListCharacterQuery]);

    useEffect(() => {
        loadCharacterByQuery(false);
    }, [searchListCharacterQuery]);
    useEffect(() => {
        loadCharacterByQuery(true);
    }, [searchListSelectedCharacterQuery]);
    useEffect(() => {
        loadNotationByQuery(false);
    }, [searchListNotationQuery]);
    useEffect(() => {
        loadNotationByQuery(false);
    }, [searchListSelectedNotationQuery]);

    const loadCountry = async () => {
        try {
            let listCountry = await getCountry(cookies.token);
            setListCountry(listCountry);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCharacter = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let listCharacter = await getVesselClassificationCharacter(cookies.token, cookies.languageId, 0, 100000, "society.society_name", false, undefined, false);
                listCharacter2 = listCharacter;
                setListCharacter(listCharacter);
                setListSearchedCharacter(listCharacter);
                resolve(true);
            }
            catch (exception) {
                console.log(exception);
                resolve(false);
            }
        })

    }

    const loadNotation = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let listNotation = await getVesselClassificationNotation(cookies.token, cookies.languageId, 0, 100000);
                listNotation2 = listNotation;
                setListNotation(listNotation);
                setListSearchedNotation(listNotation);
                resolve(true);
            }
            catch (exception) {
                console.log(exception);
                resolve(false);
            }
        });
    }

    const resetCharacterSearch = async (isSelected = false) => {
        try {
            if (isSelected) {
                let listSearchedCharacter = listSelectedCharacter;
                setSearchListSelectedCharacterQuery("");
                setListSearchedSelectedCharacter(listSearchedCharacter);
            } else {
                let listSearchedCharacter = listCharacter;
                setSearchListCharacterQuery("");
                setListSearchedCharacter(listSearchedCharacter);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselShiftSession = async () => {
        try {
            let response = await getVesselShiftSession(cookies.token);
            setListShift(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCharacterByQuery = async (isSelected = false) => {
        try {
            if (isSelected) {
                let listSearchedCharacter = listSelectedCharacter.filter(p => p.application_name.toLowerCase().includes(searchListSelectedCharacterQuery));
                setListSearchedSelectedCharacter(listSearchedCharacter);
            } else {
                let listSearchedCharacter = listCharacter.filter(p => p.application_name.toLowerCase().includes(searchListCharacterQuery));
                setListSearchedCharacter(listSearchedCharacter);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadNotationByQuery = async (isSelected = false) => {
        try {
            if (isSelected) {
                let listSearchedNotation = listSelectedNotation.filter(p => p.notation_name.toLowerCase().includes(searchListNotationQuery) || p.notation_code.toLowerCase().includes(searchListNotationQuery));
                setListSearchedSelectedNotation(listSearchedNotation);
            } else {
                let listSearchedNotation = listNotation.filter(p => p.notation_name.toLowerCase().includes(searchListNotationQuery) || p.notation_code.toLowerCase().includes(searchListNotationQuery));
                setListSearchedNotation(listSearchedNotation);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const initVessel = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await getVesselById(cookies.token, location.state.vesselId, cookies.languageId);
                if (response) {
                    setVessel({
                        ...vessel,
                        vesselId: response.id,
                        vessel_name: response.vessel_name,
                        customer_id: response.customer_id,
                        vessel_type_id: response.vessel_type_id,
                        imo_number: response.imo_number,
                        serial_number: response.serial_number,
                        built_date: response.built_date,
                        built_shipyard: response.built_shipyard,
                        vessel_code: response.vessel_code,
                        gross_register_tonnage: response.gross_register_tonnage,
                        deadweight_tonnage: response.deadweight_tonnage,
                        lbp: response.lbp,
                        bmld: response.bmld,
                        hmld: response.hmld,
                        callsign: response.callsign,
                        flag: response.flag,
                        class_register_number: response.class_register_number,
                        shift_session_id: response.shift_session_id
                    });
                    let listSelectedCharacterTmp = Array.from(response.character);
                    let listSelectedNotationTmp = Array.from(response.notation);
                    setListSelectedCharacter(response.character);
                    setListSearchedSelectedCharacter(response.character);
                    setListSelectedNotation(response.notation);
                    setListSearchedSelectedNotation(response.notation);
                    let listCharacterIdxToRemove = [];
                    let listNotationIdxToRemove = [];
                    for (let i = 0; i < listSelectedCharacterTmp.length; i++) {
                        let idx = listCharacter2.findIndex(p => p.id === listSelectedCharacterTmp[i].id);
                        listCharacterIdxToRemove.push(idx);
                    }

                    for (let i = listCharacterIdxToRemove.length - 1; i >= 0; i--) {
                        listCharacter2.splice(listCharacterIdxToRemove[i], 1);
                    }

                    for (let i = 0; i < listSelectedNotationTmp.length; i++) {
                        let idx = listNotation2.findIndex(p => p.id === listSelectedNotationTmp[i].id);
                        listNotationIdxToRemove.push(idx);
                    }

                    for (let i = listNotationIdxToRemove.length - 1; i >= 0; i--) {
                        listNotation2.splice(listNotationIdxToRemove[i], 1);
                    }

                    setListCharacter(listCharacter2);
                    setListSearchedCharacter(listCharacter2);
                    setListNotation(listNotation2);
                    setListSearchedNotation(listNotation2);
                } else {
                }
                setLoading(false);
                resolve(true);
            }
            catch (exception) {
                setLoading(false);
                resolve(false);
            }
        });
    }

    const saveData = async () => {
        try {
            let vesselTmp = vessel;
            vesselTmp.character = listSelectedCharacter;
            vesselTmp.notation = listSelectedNotation;
            vesselTmp.ext_customer_id = vesselTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : vessel.ext_customer_id
            setVessel(vesselTmp);
            let response = await insertUpdateVessel(cookies.token, vessel, location.state.vesselId);
            if (response.error_code === 0) {
                alert(`${t("detail_vessel.alert_success_save_data")}`);
                if (location.state.vesselId === 0) {
                    navigate("/Vessel");
                } else {
                    navigate("/Vessel");
                    // initVessel();
                }
            }
            else {
                alert(`${t("detail_vessel.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
            setDisabledButton(false);
        }

    }

    const loadVesselType = async () => {
        try {
            let listVesselType = await getVesselType(cookies.token, cookies.languageId);
            setListVesselType(listVesselType);
            let listVesselTypeNew = [];
            for (let i = 0; i < listVesselType.length; i++) {
                var obj = {
                    value: listVesselType[i].id,
                    label: listVesselType[i].vessel_type_name,
                    id: listVesselType[i].id

                };

                listVesselTypeNew.push(obj);

            }
            setVesselTypeOptions(listVesselTypeNew);
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (vessel.vessel_name === undefined || vessel.vessel_name === "") {
                    setErrors({
                        ...errors,
                        vesselName: `${t("detail_vessel.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (vessel.customer_id === undefined || vessel.customer_id === null) {
                    setErrors({
                        ...errors,
                        vesselCustomer:`${t("detail_vessel.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else if (vessel.flag === undefined || vessel.flag === "") {
                    setErrors({
                        ...errors,
                        countryIdError: `${t("detail_vessel.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else if (vessel.vessel_type_id === undefined || vessel.vessel_type_id === "") {
                    setErrors({
                        ...errors,
                        vesselType: `${t("detail_vessel.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "vesselName", "vesselCustomer", "countryIdError", "vesselType");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadCustomer = async () => {
        try {
            let response = await getCustomer(cookies.token, cookies.extCustomerId);
            let newList = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].customer_name,
                    id: response[i].id

                };

                newList.push(obj);

            }
            setListCustomer(newList);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Vessel"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={location.state.vesselId === 0 ? true : false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/SurveySchedule", { state: { vesselId: location.state.vesselId, vesselName: vessel.vessel_name, customerId: vessel.customer_id } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><CalendarEventFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_vessel.button_assesment")}</div>
                                </div>
                            </Button>
                            <Button disabled={location.state.vesselId === 0 ? true : false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/VesselPhoto", { state: { vesselId: location.state.vesselId, vesselName: vessel.vessel_name } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><CameraFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_vessel.button_photo")}</div>
                                </div>
                            </Button>
                            <Button disabled={location.state.vesselId === 0 ? true : false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Document", { state: { vesselId: location.state.vesselId, vesselName: vessel.vessel_name } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FileCheckFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_vessel.button_document")}</div>
                                </div>
                            </Button>
                            <Button disabled={location.state.vesselId === 0 ? true : false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/EquipmentStructView", { state: { vesselId: location.state.vesselId } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><GearWideConnected size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_vessel.button_equipment")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    titleCaption={`${t("detail_vessel.header_title")}`} childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                    padding: 10

                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        justifyContent: "space-between"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            paddingBottom: 10,
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel.header_vessel_identity")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>

                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.vessel_name} placeholder=""
                                                                caption={`${t("detail_vessel.field_vessel_name")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, vessel_name: e.target.value });
                                                                }} />
                                                            {
                                                                errors.vesselName && <p style={{ color: "red" }}>{errors.vesselName}</p>
                                                            }

                                                            <FormSelect disabledForm={disabledCustomerForm} caption={`${t("detail_vessel.field_customer_name")}`} autoFocusValue={vessel.customer_id === undefined} placeholder=""
                                                                options={listCustomer} value={listCustomer.filter(function (option) {
                                                                    return option.id === vessel.customer_id;
                                                                })} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, customer_id: e.id });
                                                                }}
                                                            />
                                                            {
                                                                errors.vesselCustomer && <p style={{ color: "red" }}>{errors.vesselCustomer}</p>
                                                            }
                                                            <FormSelect disabledForm={disabledCustomerForm} caption={`${t("detail_vessel.field_vessel_type")}`} autoFocusValue={vessel.vessel_type_id === undefined} placeholder=""
                                                                options={vesselTypeOptions} value={vesselTypeOptions.filter(function (option) {
                                                                    return option.id === vessel.vessel_type_id;
                                                                })} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, vessel_type_id: e.id });
                                                                }}
                                                            />
                                                            {
                                                                errors.vesselType && <p style={{ color: "red" }}>{errors.vesselType}</p>
                                                            }

                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.imo_number} placeholder=""
                                                                caption={`${t("detail_vessel.field_vessel_imo_number")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, imo_number: e.target.value });
                                                                }} />

                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.class_register_number} placeholder=""
                                                                caption={`${t("detail_vessel.field_class_registration_number")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, class_register_number: e.target.value });
                                                                }} />
                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.vessel_code} placeholder=""
                                                                caption={`${t("detail_vessel.field_vessel_code")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, vessel_code: e.target.value });
                                                                }} />
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            paddingLeft: 10
                                                        }}>

                                                            <DropDownForm disabledForm={disabledCustomerForm} value={vessel.flag} caption={`${t("detail_vessel.field_nation_flag")}`} placeholder=""
                                                                setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, flag: e.target.value });
                                                                }} listOption={listCountry} valueKey="id" labelKey="country_name" />
                                                            {
                                                                errors.countryIdError && <p style={{ color: "red" }}>{errors.countryIdError}</p>
                                                            }
                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.serial_number} placeholder=""
                                                                caption={`${t("detail_vessel.field_yard_number")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, serial_number: e.target.value });
                                                                }} />
                                                            {/* {
                                                errors.company_names && <p style={{ color: "red" }}>{errors.company_names}</p>
                                            } */}
                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.built_date} placeholder="" controlType="date"
                                                                caption={`${t("detail_vessel.field_built_date")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, built_date: e.target.value });
                                                                }} />
                                                            {/* {
                                                errors.company_names && <p style={{ color: "red" }}>{errors.company_names}</p>
                                            } */}

                                                            <TextBox disabledForm={disabledCustomerForm} value={vessel.callsign} placeholder=""
                                                                caption={`${t("detail_vessel.field_call_sign")}`} setValueFunction={(e) => {
                                                                    setVessel({ ...vessel, callsign: e.target.value });
                                                                }} />

                                                            <DropDownForm disabledForm={disabledCustomerForm} value={vessel.shift_session_id} caption={`${t("detail_vessel.field_work_shift")}`} placeholder=""
                                                                setValueFunction={(e) => {
                                                                    if (e.target.value === "") {
                                                                        setVessel({ ...vessel, shift_session_id: null });
                                                                    } else {
                                                                        setVessel({ ...vessel, shift_session_id: e.target.value });
                                                                    }

                                                                }} listOption={listShift} valueKey="id" labelKey="shift_name" />

                                                        </div>
                                                    </div>
                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            paddingBottom: 10,
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel.header_dimension_and_ship_weight")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1
                                                            }}>
                                                                <h5>{t("detail_vessel.dimension_text")}</h5>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    flex: 1,
                                                                    justifyContent: "flex-start"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>
                                                                        <TextBox disabledForm={disabledCustomerForm} value={vessel.lbp} placeholder=""
                                                                            caption={`${t("detail_vessel.field_lbp")}`} setValueFunction={(e) => {
                                                                                setVessel({ ...vessel, lbp: e.target.value });
                                                                            }} />
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>
                                                                        <TextBox disabledForm={disabledCustomerForm} value={vessel.bmld} placeholder=""
                                                                            caption={`${t("detail_vessel.field_bmld")}`} setValueFunction={(e) => {
                                                                                setVessel({ ...vessel, bmld: e.target.value });
                                                                            }} />
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>
                                                                        <TextBox disabledForm={disabledCustomerForm} value={vessel.hmld} placeholder=""
                                                                            caption={`${t("detail_vessel.field_hmld")}`} setValueFunction={(e) => {
                                                                                setVessel({ ...vessel, hmld: e.target.value });
                                                                            }} />
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1
                                                            }}>
                                                                <h5>{t("detail_vessel.text_weight")}</h5>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    flex: 1,
                                                                    justifyContent: "flex-start"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>
                                                                        <TextBox disabledForm={disabledCustomerForm} value={vessel.gross_register_tonnage} placeholder=""
                                                                            caption={`${t("detail_vessel.field_gross_register_tonnage")}`} setValueFunction={(e) => {
                                                                                setVessel({ ...vessel, gross_register_tonnage: e.target.value });
                                                                            }} />
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>
                                                                        <TextBox disabledForm={disabledCustomerForm} value={vessel.deadweight_tonnage} placeholder=""
                                                                            caption={`${t("detail_vessel.field_deadweight_tonnage")}`} setValueFunction={(e) => {
                                                                                setVessel({ ...vessel, deadweight_tonnage: e.target.value });
                                                                            }} />
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        justifyContent: "flex-start"
                                                                    }}>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>} />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            paddingBottom: 10,
                                        }}>
                                            <ContainerBox
                                                containerPos="inner"
                                                captionSize="small"
                                                titleCaption={"Kelas"}
                                                childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            margin: 5,
                                                            width: "100%"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row"
                                                            }}>
                                                                <h5>{t("detail_vessel.text_character")}</h5>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                justifyContent: "flex-end",
                                                                alignContent: "flex-end"
                                                            }}>
                                                                <Button disabled={disabledCustomerForm} variant="primary" style={{

                                                                }} onClick={() => {
                                                                    setShowCharacterClassModal(true);
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><PencilFill size={18} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 16,
                                                                        }}>{t("detail_vessel.button_edit_character")}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            flexWrap: "nowrap",
                                                            borderStyle: "solid",
                                                            borderWidth: 1,
                                                            borderColor: "#031e67",
                                                            borderRadius: 10,
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 5
                                                            }}>
                                                                {
                                                                    listSelectedCharacter && listSelectedCharacter.map((character, index) => {
                                                                        return (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1
                                                                            }}>
                                                                                <img style={{ width: 48, height: 48 }} src={character.character_symbol_image} />
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            margin: 5,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                margin: 5,
                                                                width: "100%"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "row"
                                                                }}>
                                                                    <h5>{t("detail_vessel.text_notation")}</h5>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "row",
                                                                    justifyContent: "flex-end",
                                                                    alignContent: "flex-end"
                                                                }}>
                                                                    <Button disabled={disabledCustomerForm} variant="primary" style={{

                                                                    }} onClick={() => {
                                                                        setShowNotationClassModal(true);
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><PencilFill size={18} /></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 8,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                paddingLeft: 10,
                                                                                fontWeight: "bold",
                                                                                fontSize: 16,
                                                                            }}>{t("detail_vessel.button_edit_notation")}</div>
                                                                        </div>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            flexWrap: "nowrap",
                                                            borderStyle: "solid",
                                                            borderWidth: 1,
                                                            borderColor: "#031e67",
                                                            borderRadius: 10,
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 5
                                                            }}>
                                                                {
                                                                    listSelectedNotation && listSelectedNotation.map((notation, index) => {
                                                                        return (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                paddingRight: 20,
                                                                            }}>
                                                                                <p style={{
                                                                                    fontFamily: "Times New Roman",
                                                                                    fontSize: 20,
                                                                                }}>{notation.notation_code}</p>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                } />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                disabledCustomerForm !== true &&
                                <ButtonForm addData={() => {
                                    saveData();
                                    setDisabledButton(true);
                                }}
                                    saveButton={`${t("button_component.save_button")}`}
                                    cancelButton={`${t("button_component.cancel_button")}`}
                                    photoButton={`${t("button_component.photo_button")}`}
                                    path="/Vessel" button={disabledButton === true ? disabledButton : !updatedButton} viewPhoto="/Vessel/VesselPhoto" idVessel={vessel.vesselId} name={vessel.vessel_name} />

                            }


                        </div>

                    }>


                </ContainerBox>
                <Modal enforceFocus={true} onBackdropClick={() => {

                }} show={showCharacterClassModal}
                    size="xl"
                    onHide={() => {
                        setShowCharacterClassModal(false);

                    }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("detail_vessel.modal_add_character")}`}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" disabled={characterSaveButtonDisabled} onClick={(e) => {
                                    setCharacterSaveButtonDisabled(true);
                                    setShowCharacterClassModal(false);
                                    setCharacterSaveButtonDisabled(false);
                                }}>{t("detail_vessel.button_save_and_close")}</Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                minHeight: 600,
                                maxHeight: 800,
                                overflowY: 'scroll',
                                padding: 10
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flex: 1,
                                    flexDirection: "row",
                                    alignSelf: "center",
                                    justifyContent: "flex-start",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        alignSelf: "flex-start",
                                        justifyContent: "flex-start"
                                    }}>
                                        <div>
                                            <h5>{t("detail_vessel.field_vessel_class")}</h5>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flex: 1,
                                            flexDirection: "column",
                                            justifyContent: "flex-start"
                                        }}>
                                            <Form.Control value={searchListSelectedCharacterQuery} type="text" placeholder={`${t("detail_vessel.placeholder_vessel_class")}`}
                                                onKeyPress={async (e) => {
                                                    if (e.charCode === 13) {

                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchListSelectedCharacterQuery(e.target.value);
                                                }} />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            {
                                                listSearchedSelectedCharacter !== undefined && listSearchedSelectedCharacter.map((character, index) => {
                                                    return (
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            flexDirection: 'row',
                                                            padding: 10,
                                                            borderWidth: 1,
                                                            borderStyle: "solid",
                                                            borderColor: "#031e67",
                                                            borderRadius: 10,
                                                            margin: 5
                                                        }} key={index}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }} onClick={(e) => {
                                                                let listCharacterTmp = Array.from(listCharacter);
                                                                let listSearchedCharacterTmp = Array.from(listSearchedCharacter);
                                                                let listSelectedCharacterTmp = Array.from(listSelectedCharacter);
                                                                let listSearchedSelectedCharacterTmp = Array.from(listSearchedSelectedCharacter);
                                                                listCharacterTmp.push(character);
                                                                listSearchedCharacterTmp.push(character);
                                                                let idx = listSelectedCharacterTmp.findIndex(p => p.id === character.id);
                                                                let idx2 = listSearchedSelectedCharacterTmp.findIndex(p => p.id === character.id);
                                                                listSelectedCharacterTmp.splice(idx, 1);
                                                                listSearchedSelectedCharacterTmp.splice(idx2, 1);
                                                                setListCharacter(listCharacterTmp);
                                                                setListSearchedCharacter(listSearchedCharacterTmp);
                                                                setListSelectedCharacter(listSelectedCharacterTmp);
                                                                setListSearchedSelectedCharacter(listSearchedSelectedCharacterTmp);
                                                                forceUpdate();
                                                            }}><img style={{ width: 80, height: 80 }} src={character.character_symbol_image} /></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                {character.application_name}
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>{t("detail_vessel.button_description")}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {character.character_desc}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        alignSelf: "flex-start",
                                        justifyContent: "flex-end",
                                        paddingLeft: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-end"
                                        }}>
                                            <h5>{t("detail_vessel.field_available_class")}</h5>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flex: 1,
                                            flexDirection: "column",
                                            justifyContent: "flex-start"
                                        }}>
                                            <Form.Control value={searchListCharacterQuery} type="text" placeholder={`${t("detail_vessel.placeholder_available_class")}`}
                                                onKeyPress={async (e) => {
                                                    if (e.charCode === 13) {

                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchListCharacterQuery(e.target.value);
                                                }} />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            {
                                                listSearchedCharacter !== undefined && listSearchedCharacter.map((character, index) => {
                                                    return (
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            flexDirection: 'row',
                                                            padding: 10,
                                                            borderWidth: 1,
                                                            borderStyle: "solid",
                                                            borderColor: "#031e67",
                                                            borderRadius: 10,
                                                            margin: 5
                                                        }} key={index}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }} onClick={(e) => {

                                                                let listCharacterTmp = Array.from(listCharacter);
                                                                let listSearchedCharacterTmp = Array.from(listSearchedCharacter);
                                                                let listSelectedCharacterTmp = Array.from(listSelectedCharacter);
                                                                let listSearchedSelectedCharacterTmp = Array.from(listSearchedSelectedCharacter);
                                                                listSelectedCharacterTmp.push(character);
                                                                listSearchedSelectedCharacterTmp.push(character);
                                                                let idx = listCharacterTmp.findIndex(p => p.id === character.id);
                                                                let idx2 = listSearchedCharacterTmp.findIndex(p => p.id === character.id);
                                                                listCharacterTmp.splice(idx, 1);
                                                                listSearchedCharacterTmp.splice(idx2, 1);
                                                                setListCharacter(listCharacterTmp);
                                                                setListSearchedCharacter(listSearchedCharacterTmp);
                                                                setListSelectedCharacter(listSelectedCharacterTmp);
                                                                setListSearchedSelectedCharacter(listSelectedCharacterTmp);
                                                                forceUpdate();
                                                            }}><img style={{ width: 80, height: 80 }} src={character.character_symbol_image} /></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                {character.application_name}
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>{t("detail_vessel.button_description")}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {character.character_desc}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } />
                </Modal>
                <Modal enforceFocus={true} onBackdropClick={() => {

                }} show={showNotationClassModal}
                    size="xl"
                    onHide={() => {
                        setShowNotationClassModal(false);
                    }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("detail_vessel.modal_add_notation")}`}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" disabled={notationSaveButtonDisabled} onClick={(e) => {
                                    setNotationSaveButtonDisabled(true);
                                    setShowNotationClassModal(false);
                                    setNotationSaveButtonDisabled(false);
                                }}>{t("detail_vessel.button_save_and_close")}</Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                minHeight: 600,
                                maxHeight: 800,
                                overflowY: 'scroll',
                                padding: 10
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flex: 1,
                                    flexDirection: "row",
                                    alignSelf: "center",
                                    justifyContent: "flex-start",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        alignSelf: "flex-start",
                                        justifyContent: "flex-start"
                                    }}>
                                        <div>
                                            <h5>{t("detail_vessel.field_vessel_notation")}</h5>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flex: 1,
                                            flexDirection: "column",
                                            justifyContent: "flex-start"
                                        }}>
                                            <Form.Control value={searchListSelectedNotationQuery} type="text" placeholder={`${t("detail_vessel.placeholder_vessel_notation")}`}
                                                onKeyPress={async (e) => {
                                                    if (e.charCode === 13) {

                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchListSelectedCharacterQuery(e.target.value);
                                                }} />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            {
                                                listSearchedSelectedNotation !== undefined && listSearchedSelectedNotation.map((notation, index) => {
                                                    return (
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            flexDirection: 'row',
                                                            padding: 10,
                                                            borderWidth: 1,
                                                            borderStyle: "solid",
                                                            borderColor: "#031e67",
                                                            borderRadius: 10,
                                                            margin: 5
                                                        }} key={index}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }} onClick={(e) => {
                                                                let listNotationTmp = Array.from(listNotation);
                                                                let listSearchedNotationTmp = Array.from(listSearchedNotation);
                                                                let listSelectedNotationTmp = Array.from(listSelectedNotation);
                                                                let listSearchedSelectedNotationTmp = Array.from(listSearchedSelectedNotation);
                                                                listNotationTmp.push(notation);
                                                                listSearchedNotationTmp.push(notation);
                                                                let idx = listSelectedNotationTmp.findIndex(p => p.id === notation.id);
                                                                let idx2 = listSearchedSelectedNotationTmp.findIndex(p => p.id === notation.id);
                                                                listSelectedNotationTmp.splice(idx, 1);
                                                                listSearchedSelectedNotationTmp.splice(idx2, 1);
                                                                setListNotation(listNotationTmp);
                                                                setListSearchedNotation(listSearchedNotationTmp);
                                                                setListSelectedNotation(listSelectedNotationTmp);
                                                                setListSearchedSelectedNotation(listSearchedSelectedNotationTmp);
                                                                forceUpdate();
                                                            }}><p style={{
                                                                fontFamily: "Times New Roman",
                                                                fontSize: 20,
                                                            }}>{notation.notation_code}</p></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                {notation.notation_name}
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>{t("detail_vessel.button_description")}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {notation.notation_desc}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        alignSelf: "flex-start",
                                        justifyContent: "flex-end",
                                        paddingLeft: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-end"
                                        }}>
                                            <h5>{t("detail_vessel.field_notation_available")}</h5>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flex: 1,
                                            flexDirection: "column",
                                            justifyContent: "flex-start"
                                        }}>
                                            <Form.Control value={searchListNotationQuery} type="text" placeholder={`${t("detail_vessel.placeholder_vessel_notation")}`}
                                                onKeyPress={async (e) => {
                                                    if (e.charCode === 13) {

                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchListNotationQuery(e.target.value);
                                                }} />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            {
                                                listSearchedNotation !== undefined && listSearchedNotation.map((notation, index) => {
                                                    return (
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            flexDirection: 'row',
                                                            padding: 10,
                                                            borderWidth: 1,
                                                            borderStyle: "solid",
                                                            borderColor: "#031e67",
                                                            borderRadius: 10,
                                                            margin: 5
                                                        }} key={index}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }} onClick={(e) => {
                                                                let listNotationTmp = Array.from(listNotation);
                                                                let listSearchedNotationTmp = Array.from(listSearchedNotation);
                                                                let listSelectedNotationTmp = Array.from(listSelectedNotation);
                                                                let listSearchedSelectedNotationTmp = Array.from(listSearchedSelectedNotation);
                                                                listSelectedNotationTmp.push(notation);
                                                                listSearchedSelectedNotationTmp.push(notation);
                                                                let idx = listNotationTmp.findIndex(p => p.id === notation.id);
                                                                let idx2 = listSearchedNotationTmp.findIndex(p => p.id === notation.id);
                                                                listNotationTmp.splice(idx, 1);
                                                                listSearchedNotationTmp.splice(idx2, 1);
                                                                setListNotation(listNotationTmp);
                                                                setListSearchedNotation(listSearchedNotationTmp);
                                                                setListSelectedNotation(listSelectedNotationTmp);
                                                                setListSearchedSelectedNotation(listSelectedNotationTmp);
                                                                forceUpdate();
                                                            }}><p style={{
                                                                fontFamily: "Times New Roman",
                                                                fontSize: 20,
                                                            }}>{notation.notation_code}</p></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                {notation.notation_name}
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                backgroundColor: "white",
                                                                paddingRight: 20,
                                                            }}>
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>{t("detail_vessel.button_description")}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {notation.notation_desc}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } />
                </Modal>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component_text")}`}
            />
        </>
    )
}