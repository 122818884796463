// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxLST {
    height: 650px;
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 7px;
    margin: 10px;
    color: white;
    padding: 8px;
}

.contentLST {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.barGraphContainer {
    margin: 8px 0px;
}

.boxLST .headingContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btnNext {
    width: 120px;
    background: #14163F;
    border-radius: 7px;
    border: solid #298BFE;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btnPrevNext {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.flexColumnP4 {
    position: relative;
    height: 550px;
    display: flex;
    flex-direction: column;
}

.botBtnP4Container {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
}

.botBtnP4 {
    width: auto;
    margin: 10px 0px;
    background: #14163F;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LevelSoundingTanks/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".boxLST {\n    height: 650px;\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 7px;\n    margin: 10px;\n    color: white;\n    padding: 8px;\n}\n\n.contentLST {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n}\n\n.barGraphContainer {\n    margin: 8px 0px;\n}\n\n.boxLST .headingContent {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.btnNext {\n    width: 120px;\n    background: #14163F;\n    border-radius: 7px;\n    border: solid #298BFE;\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n}\n\n.btnPrevNext {\n    display: flex;\n    flex-direction: row;\n    gap: 25px;\n}\n\n.flexColumnP4 {\n    position: relative;\n    height: 550px;\n    display: flex;\n    flex-direction: column;\n}\n\n.botBtnP4Container {\n    position: absolute;\n    bottom: 0px;\n    display: flex;\n    flex-direction: column;\n}\n\n.botBtnP4 {\n    width: auto;\n    margin: 10px 0px;\n    background: #14163F;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
