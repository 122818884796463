// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentMisc {
    height: 650px;
    background-color: rgba(233, 233, 250, 0.1);
    border-radius: 7px;
    margin: 10px;
    color: white;
    padding: 10px;
}

.headingContentMisc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.miscBitLampContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Miscellaneous/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":[".contentMisc {\n    height: 650px;\n    background-color: rgba(233, 233, 250, 0.1);\n    border-radius: 7px;\n    margin: 10px;\n    color: white;\n    padding: 10px;\n}\n\n.headingContentMisc {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.miscBitLampContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
