import "../../SMSApp.css";
import "./index.css";
import Navbar from "../../Components/NavBar";
import DynamicLineChart from "../../Components/DynamicLineChart";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BarGraph from "../../Components/BarGraph/BarGraph";
import { Anchor, Button, Col, Row, Modal, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill, GearWideConnected } from "react-bootstrap-icons";
import BitLamp from "../../Components/BitLamp/BitLamp";
import {
    isTokenValid, getPLCCoordinatePumpMotor, getPlcValue, getCoordinateRunningHour, getPumpAverageValue, getPLCCoordinatePumpMotorContact,
    getPLCCoordinatePumpMotorThermalOverload, getPLCCoordinatePumpMotorWireBreak, getEquipmentDetail, getEquipmentMaintenanceScheduleByVesselEquipment,
    getCountEquipmentMaintenanceScheduleByVesselEquipment, insertUpdateMaintenanceTime
} from "../../Helpers/ApplicationHelpers";
import Loading from "../../Components/Loading";
import moment from "moment";
import ContainerBox from "../../Components/ContainerBox";
import Paginations from "../../Components/Pagination";
import DropDownForm from "../../Components/DropDownForm";

export default function PumpMotor() {
    // const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listPumpMotor, setListPumpMotor] = useState([]);
    const [listContact, setListContact] = useState([]);
    const [listThermalOverload, setListThermalOverload] = useState([]);
    const [listWireBreak, setListWireBreak] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vesselEquipmentId, setVesselEquipmentid] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});
    const [equipmentModal, setEquipmentModal] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [totalMaintenanceTime, setTotalMaintenanceTime] = useState(0);
    const [maintenanceScheduleId, setMaintenanceScheduleId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [listMaintenanceSchedule, setListMaintenanceSchedule] = useState([]);
    const [totalHour, setTotalHour] = useState(0);
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                // loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (listPumpMotor.length > 0) {
            const interval = setInterval(async () => {
                loadBitlampValue()
            }, 1000)


            return () => clearInterval(interval)
        }

    }, [listPumpMotor]);

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [page]);

    useEffect(()=>{
        if(maintenanceScheduleId !== ""){
            updateMaintenanceTime();
        }
    },[maintenanceScheduleId])

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail()
    }, [vesselEquipmentId])

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            setDetailEquipment(response);

            let count = await getCountEquipmentMaintenanceScheduleByVesselEquipment(cookies.token, cookies.vesselId, vesselEquipmentId, undefined, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let list = await getEquipmentMaintenanceScheduleByVesselEquipment(cookies.token, cookies.vesselId, vesselEquipmentId, page, itemPerPage, undefined, undefined, undefined, false);
            for (let index = 0; index < list.length; index++) {
                const listScheduleTmp = list[index];
                if (listScheduleTmp.maintenance_time % Number(totalHour).toFixed(0) === listScheduleTmp.maintenance_time) {
                    let total = 0
                    if(listScheduleTmp.next_maintenance_hour){
                        total = Number(listScheduleTmp.next_maintenance_hour)
                    }else{
                       total =  Number(listScheduleTmp.maintenance_time) + totalHour;
                    } 
                    if ((total - totalHour) < 100) {
                        listScheduleTmp.background_color = '#ffff00'
                    }else{
                        listScheduleTmp.background_color = ''
                    }
                } else {
                    let total = 0;
                    if(listScheduleTmp.next_maintenance_hour){
                        total =  Number(listScheduleTmp.next_maintenance_hour) - totalHour;
                    }else{
                        total =  Number(listScheduleTmp.maintenance_time) - totalHour;
                    }
                   
                    if (total < 100) {
                        listScheduleTmp.background_color = '#ffff00'
                    }else{
                        listScheduleTmp.background_color = ''
                    }
                }
            }
            setListMaintenanceSchedule(list)

            setIsSearched(0);
            setEquipmentModal(true);
        } catch (exception) {
            // console.log(exception);
        }
    }

    const updateMaintenanceTime = async()=>{
        try {
            let customerId = cookies.extCustomerId === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : cookies.extCustomerId
            let response = await insertUpdateMaintenanceTime(cookies.token, totalHour, totalMaintenanceTime, maintenanceScheduleId, cookies.languageId, customerId);
            if(response.error_code === 0){
                alert('Data Has Been Saved');
                loadEquipmentDetail();
                setMaintenanceScheduleId("");
                setTotalMaintenanceTime(0);
            }
        } catch (exception) {
            
        }
    }

    const loadBitlampValue = async () => {
        try {
            let newListCoordinate = listPumpMotor.concat(listContact, listThermalOverload, listWireBreak);
            if (newListCoordinate.length === 0) {
                setLoading(false);
            }
            let coordinate = newListCoordinate.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = listThermalOverload.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };

                } else {
                    return { ...item, alarm: item.alarm };
                }
            });

            let listRh = await getCoordinateRunningHour(cookies.token, cookies.serialNumber);
            let newList = listContact.map(item => {
                let rh = listRh.find(p => item.plc_coordinate === p.coordinate)
                if (rh) {
                    let totalHour = (rh.running_hour / (1000 * 60 * 60)).toFixed(1);

                    const duration = moment.duration(rh.running_hour);
                    const days = Math.floor(duration.asDays());
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    let totalDays = days + ' days ' + hours + ' hours: ' + minutes + ' minutes' + ' (' + totalHour + ' Hours )'
                    return { ...item, running_hour: rh.running_hour, total_day: totalDays, total_hour: totalHour, last_data_read: rh.created_date };
                } else {
                    return { ...item, running_hour: item.running_hour, total_day: item.total_day, total_hour: item.totalHour, last_data_read: item.last_data_read };
                }
            })

            let rhStatus = newList.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };

                } else {
                    return { ...item, alarm: item.alarm };
                }
            });

            let listValue = await getPumpAverageValue(cookies.token, cookies.serialNumber);
            let listPumpMotorTmp = listPumpMotor.map(item => {
                let lv = listValue.find(p => item.plc_coordinate === p.field);

                if (lv) {
                    let valueVib = ((Number(lv.value) / 4095) * 20);
                    let finalValue = ((valueVib - 4) / 16) * 100
                    // let valueVibration = ((Number(lv.value))/(4095-0))*100
                    return { ...item, value: finalValue.toFixed(2) };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            })
            setListThermalOverload(updatedList);
            setListContact(rhStatus);
            setListPumpMotor(listPumpMotorTmp)

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    // const loadCoordinate = async () => {
    //     try {
    //         let response = await getPLCCoordinatePumpMotor(cookies.token, cookies.vesselId, 0, 6);
    //         let contact = await getPLCCoordinatePumpMotorContact(cookies.token, cookies.vesselId, 0, 6);
    //         let thermal = await getPLCCoordinatePumpMotorThermalOverload(cookies.token, cookies.vesselId, 0, 6);
    //         let wire = await getPLCCoordinatePumpMotorWireBreak(cookies.token, cookies.vesselId, 0, 6);
    //         setListPumpMotor(response);
    //         setListContact(contact);
    //         setListThermalOverload(thermal);
    //         setListWireBreak(wire);

          
    //         if (response.length < 1) {
    //             setLoading(false);
    //         }
    //     } catch (exception) {

    //     }
    // }

    const dataShow = [
        {
            label : "11:00",
            value : 20
        },
        {
            label : "11:20",
            value : 10
        },
        {
            label : "11:32",
            value : 15
        },
        {
            label : "11:45",
            value : 2
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
    ]
    return (
        <>
            <Navbar />
            <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Pump and Motor'}/>
                    <BottomNavBar/>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexDirection: "row",
                        padding:'5px'}}>
                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Mulai</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    
                                }}
                                
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Akhir</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                   
                                }}
                                
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group style={{margin: "10px", color: 'white'}}>
                            <DropDownForm
                                caption={"Rentang Waktu"}
                                placeholder={"Pilih Waktu"}
                                // listOption={}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                console.log(e);
                                try {
                                    let dateTime = new Date();
                                    // if (e.target.value !== "") {
                                    //   setLoading(true);
                                    // }

                                    if (e.target.value === "1") {
                                    console.log("1");
                                    
                                    } else if (e.target.value === "2") {
                                    console.log("2");
                                    
                                    } else if (e.target.value === "3") {
                                    console.log("3");
                                    
                                    }
                                } catch (exception) {
                                    console.log(exception);
                                }
                                }}
                            />
                            </Form.Group>

                            <Button
                                variant="dark"
                                style={{ height: "40px", margin: "10px", marginTop: "35px", backgroundColor:'#050512' }}
                                onClick={() => {
                                
                                }}
                            >
                                Tampilkan Data
                            </Button>

                            <Form.Label style={{color: 'white'}}>
                                <input
                                style={{marginTop:"30px"}}
                                type="checkbox"
                                // checked={refreshChecked}
                                onChange={(e) => {
                                    // setRefreshChecked(e.target.checked);
                                }}
                                />
                                Auto Refresh
                            </Form.Label>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', padding: '10px', margin:'10px 0px'}}>
                        <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'Anchor Windlass Power Pack'} useBitLamp={'true'} useRunningHour={'true'}/>
                        <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'GS/Fire Pump'} useBitLamp={'true'} useRunningHour={'true'}/>
                        <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'F.O Transfer Pump'} useBitLamp={'true'} useRunningHour={'true'}/>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', padding: '10px', margin:'10px 0px'}}>
                        <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'FW Domestic Pump'} useBitLamp={'true'} useRunningHour={'true'}/>
                        <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'Steering Pump 1'} useBitLamp={'true'} useRunningHour={'true'}/>
                        <DynamicLineChart style={{flex: 1}} colorShow={'green'} dataShow={dataShow} labelText={'Steering Pump 2'} useBitLamp={'true'} useRunningHour={'true'}/>
                    </div>
                </div>
            </div>
        </>
    );
}