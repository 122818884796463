import "./BitLamp2.css"

export default function BitLamp2({ text, alarm, gapText }) {
    return (
        <div className="bitLamp2" style={{ gap: gapText }}>
            {
                alarm === "true" ?
                <div className="bitLamp2On"></div>
                : <div className="bitLamp2Black" ></div>
            }
            <div className="textBitLamp2">{text}</div>
        </div>
    )
}