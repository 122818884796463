import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, getCountInventoryType } from "../../../Helpers/ApplicationHelpers";
import { getInventoryGroup, getInventoryGroupCount, deleteInventoryGroup, getInventoryTypeById, insertUpdateInventoryGroup, checkGroupCodeExists } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import { EyeFill, PencilFill, Trash, BoxArrowUpRight, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import PaginationsInventory from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function InventoryGroupPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [listInventoryGroup, setListInventoryGroup] = useState([]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageInventory, setPageInventory] = useState(0);
    const [itemPerPageInventory, setItemPerPageInventory] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [totalPageInventory, setTotalPageInventory] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInventory, setSeacrhInventory] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [listSearchInventory, setListSearchInventory] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_group.order_name1")}`,
        value: "inventory_group_name"
    });
    const orderByList = [{
        label: `${t("index_inventory_group.order_name1")}`,
        value: "inventory_group_code"
    }];
    const [inventoryGroupCount, setInventoryGroupCount] = useState(0);
    const [inventoryGroupDetail, setInventoryGroupDetail] = useState({
        id: 0,
        inventory_group_name: "",
        inventory_group_code: "",
        inventory_group_desc: "",
        language_id: cookies.languageId
    });
    const [descending, setDescending] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState("");

    const [inventoryType, setInventoryType] = useState({
        id: 0,
        inventory_type_name: "",
        inventory_type_description: "",
        language_id: cookies.languageId,
        type: []
    });
    const [isCodeExists, setIsCodeExists] = useState(true);
    const [existingCodeGroupName, setExistingCodeGroupName] = useState("");
    const [isInventoryGroupNameValid, setIsInventoryGroupNameValid] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryGroup();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setLoading(true);
        // console.log(page);
        loadInventoryGroup();
    }, [page]);

    useEffect(() => {
        if (listInventoryGroup) {
            console.log(listInventoryGroup);
        }
    }, [listInventoryGroup])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryGroup();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryGroup();
        }
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadInventoryGroup();
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryGroup();
        }
    }, [descending]);

    useEffect(() => {
        async function checkCodeExists() {
            if (inventoryGroupDetail.inventory_group_code.length >= 2) {
                let checkRes = await checkGroupCodeExists(cookies.token, inventoryGroupDetail.inventory_group_code);
                if (checkRes) {
                    setIsCodeExists(true);
                    setExistingCodeGroupName(checkRes);
                }
                else {
                    setIsCodeExists(false);
                    setExistingCodeGroupName("");
                }
            }

            if (inventoryGroupDetail.inventory_group_name.length >= 2) {
                setIsInventoryGroupNameValid(true);
            } else {
                setIsInventoryGroupNameValid(false);
            }
        }

        checkCodeExists();

    }, [inventoryGroupDetail]);

    /* useEffect(() => {
        setListSearchInventory(inventoryType.type.slice(pageInventory * itemPerPageInventory, itemPerPageInventory * (pageInventory + 1)));
        let count = inventoryType.type.length / itemPerPageInventory;
        setTotalPageInventory(count);
    }, [inventoryType])
 */




    const initInventoryGroup = async (inventoryTypeId) => {
        try {
            let response = await getInventoryTypeById(cookies.token, inventoryTypeId, cookies.languageId);
            if (response) {
                setInventoryType({
                    id: response.id,
                    inventory_group_name: response.inventory_type_name,
                    inventory_group_code: response.inventory_group_code,
                    language_id: response.language_id,
                    type: response.type
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveInventoryGroup = async () => {
        let response = await insertUpdateInventoryGroup(cookies.token, inventoryGroupDetail);
        // console.log(response);
        if (response === 0) {
            alert(t("index_inventory_group.save_success_alert"));
            loadInventoryGroup();
        } else {
            alert(t("index_inventory_group.save_failed_alert"));
        }
        setShowDetailModal(false);
    }

    const loadInventoryGroup = async () => {
        try {
            let inventoryGroupCount = await getInventoryGroupCount(cookies.token, cookies.languageId, 0, 10000000, orderBy.value, descending, searchQuery, false);
            setInventoryGroupCount(inventoryGroupCount);
            console.log(inventoryGroupCount);
            let totalPage = inventoryGroupCount / itemPerPage;
            setTotalPage(totalPage);

            let response = await getInventoryGroup(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery, false);

            setListInventoryGroup(response);
            console.log(listInventoryGroup);
            setLoading(false);
            setResetSearch(0)
            setIsSearched(0)
        }
        catch (exception) {
            console.log(exception)
        }
    }




    const deleteGroup = async (id) => {
        try {
            if (window.confirm(`${t("index_inventory_group.delete_confirmation")}`) == true) {
                let deleteSelectedInventoryType = await deleteInventoryGroup(cookies.token, id);
                if (deleteSelectedInventoryType.success) {
                    if (deleteSelectedInventoryType.error_code === 0) {
                        alert(`${t("index_inventory_group.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_inventory_group.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_inventory_group.delete_failed_alert")}`);
                }
                loadInventoryGroup();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_group.order_name1")}`,
                value: "inventory_type_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_inventory_group.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setInventoryGroupDetail({
                                    id: 0,
                                    inventory_group_name: "",
                                    inventory_group_code: "",
                                    inventory_group_desc: "",
                                    language_id: cookies.languageId
                                });
                                setExistingCodeGroupName("");
                                setIsCodeExists(false);
                                setShowDetailModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_group.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_inventory_group.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                setLoading(true);
                                                await loadInventoryGroup();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0)
                                            setLoading(true);
                                            await loadInventoryGroup();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadInventoryType();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_group.table_header_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_header_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_group.table_header_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_header_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_header_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_header_name6")}</div>
                                </div>
                                {
                                    listInventoryGroup.map((group, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{group.inventory_group_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{group.inventory_group_code}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{group.inventory_group_desc}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setInventoryGroupDetail({
                                                            id: group.id,
                                                            inventory_group_name: group.inventory_group_name,
                                                            inventory_group_code: group.inventory_group_code,
                                                            inventory_group_desc: group.inventory_group_desc,
                                                            language_id: cookies.languageId
                                                        });
                                                        setShowDetailModal(true);
                                                    }}><PencilFill /></Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                       navigate("/InventorySubGroup", { state: { inventoryGroupId: group.id } });
                                                    }}><BoxArrowUpRight /></Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                        onClick={event => {
                                                            deleteGroup(group.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>
            <Modal show={showDetailModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setShowDetailModal(false);

                }}>
                <div className="details m-2" >
                    <div className="detailscontent">

                        {inventoryGroupDetail.id == 0 ?
                            <h3>{t("index_inventory_group.add_group_title")}</h3> :
                            <h3>{t("index_inventory_group.edit_group_title")}</h3>
                        }
                    </div>

                    <Form onSubmit={async (e) => {
                        e.preventDefault();
                        console.log(inventoryGroupDetail);
                        await saveInventoryGroup();
                    }} style={{ padding: 10 }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_group.table_header_name1")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryGroupDetail({ ...inventoryGroupDetail, inventory_group_name: e.target.value })
                            }} type="text" value={inventoryGroupDetail.inventory_group_name} required placeholder={t("index_inventory_group.placeholder_input_group_name")}></Form.Control>
                            {!isInventoryGroupNameValid && <p style={{ color: "red" }}>{t("index_inventory_group.message_group_name_error")}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_group.table_header_name2")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setInventoryGroupDetail({ ...inventoryGroupDetail, inventory_group_code: e.target.value })
                            }} type="text" value={inventoryGroupDetail.inventory_group_code} required placeholder={t("index_inventory_group.placeholder_input_group_code")}></Form.Control>
                            {isCodeExists && <p style={{ color: "red" }}>{t("index_inventory_group.message_code_exists", { group_name: existingCodeGroupName })}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_group.table_header_name3")}</Form.Label>
                            <textarea
                                className="form-control"
                                value={inventoryGroupDetail.inventory_group_desc}
                                placeholder={t("index_inventory_group.placeholder_input_group_desc")}
                                onChange={(e) => {
                                    setInventoryGroupDetail({ ...inventoryGroupDetail, inventory_group_desc: e.target.value })
                                }}
                                rows="4"
                            />
                        </Form.Group>

                        <div style={{ paddingBottom: 10 }}></div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <Button disabled={isCodeExists || !isInventoryGroupNameValid} type="submit">{t(inventoryGroupDetail.id === 0 ? "index_inventory_group.button_add_detail" : "index_inventory_group.button_edit_detail")}</Button>
                            <Button className="cancel" variant="danger" onClick={() => {
                                setShowDetailModal(false);
                            }}>{t("index_inventory_group.button_cancel")}</Button>
                        </div>

                    </Form>
                </div>
            </Modal>


        </>
    )
}   