import { config } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import { json } from "react-router-dom";
import Config from "../Config/Config";

export const getSystemMenu = async (token,languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/findAllMenu`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getUserRoleXMenu = async (token, offset = 0, limit = 50, orderBy = "rolexMenu.menu_id", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/allUserRoleXMenu`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSystemMenuById = async (token, systeMenuId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/findByIdMenu`, {
            params: {
                id: systeMenuId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getUserRoleXMenuById = async (token, languageId, userRoleXMenuId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/findAllRolexMenu`, {
            params: {
                user_role_id: userRoleXMenuId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSystemMenuCount = async (token,languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/countMenu`, {
            headers: {
                token: token
            },params:{
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getUserRoleXMenuCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/countUserRoleXMenu`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertSystemMenu = async (token, listSystemMenu, roleId) => {
    let payload = {
        systemMenu: listSystemMenu,
        user_role_id: roleId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SystemMenu/insertSystemMenu`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }



    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getUserAccountxMenu = async (token, languageId, UserAccountxMenuId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/findAllUserAccountxMenu`, {
            params: {
                user_account_id: UserAccountxMenuId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertSystemMenuForUserAccount = async (token, listSystemMenu, userAccountId, languageId) => {
    let payload = {
        systemMenu: listSystemMenu,
        user_account_id: userAccountId,
        language_id: languageId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SystemMenu/insertSystemMenuForUserAccount`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }



    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}