// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/Assets/Images/Fonts/Disket-Mono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.valueContainerNew{
    /* border: 1px black solid; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.textForGaugeValue{
    margin: 0;
    color: #f1f5f9;
    font-size: medium;
    font-family: "Disket Mono";
}

@font-face {
    font-family: "Disket Mono";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}`, "",{"version":3,"sources":["webpack://./src/Components/GaugeComponent3/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,cAAc;IACd,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,4CAA4D;AAChE","sourcesContent":[".valueContainerNew{\n    /* border: 1px black solid; */\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.textForGaugeValue{\n    margin: 0;\n    color: #f1f5f9;\n    font-size: medium;\n    font-family: \"Disket Mono\";\n}\n\n@font-face {\n    font-family: \"Disket Mono\";\n    src: url(\"/src/Assets/Images/Fonts/Disket-Mono-Regular.ttf\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
