import "../../SMSApp.css";
import "./index.css";
import Navbar from "../../Components/NavBar";
import DynamicLineChart from "../../Components/DynamicLineChart";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid, getPLCCoordinate, getPlcValue, getPLCCoordinateAuxEngine, getEquipmentDetail } from "../../Helpers/ApplicationHelpers";
import Speedometer, { Arc, Needle, Progress, Marks, Indicator, DangerPath } from 'react-speedometer';
import Loading from "../../Components/Loading";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { Row, Form, Modal, Button } from "react-bootstrap";
import ContainerBox from "../../Components/ContainerBox";
import DropDownForm from "../../Components/DropDownForm";

export default function AuxEngine() {

    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listAuxEngine, setListAuxEngine] = useState([]);
    const [listEngine1, setListEngine1] = useState([]);
    const [listEngine2, setListEngine2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [equipmentModal, setEquipmentModal] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (listEngine1.length > 0) {

            const interval = setInterval(async () => {
                loadCoordinateValue()
            }, 1000)


            return () => clearInterval(interval)
        }

    }, [listEngine1]);

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [vesselEquipmentId]);

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            console.log(response);
            setDetailEquipment(response);
            setEquipmentModal(true);
        } catch (exception) {

        }
    }



    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinateAuxEngine(cookies.token, "aux_engine", cookies.vesselId);
            setListAuxEngine(response);
            let engine1 = response.filter(p => p.coordinate_group === "aux_engine1" || p.coordinate_group === 'ae1_dummy');
            setListEngine1(engine1);
            let engine2 = response.filter(p => p.coordinate_group === "aux_engine2");
            setListEngine2(engine2);

            if (response.length < 1) {
                setLoading(false);
            }
            setLoading(false);
        } catch (exception) {

        }
    }

    const loadCoordinateValue = async () => {
        try {

            let coordinate = listAuxEngine.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            console.log(listEngine1)
            let updatedList = listEngine1.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    let valueEngine = 0
                    if(item.plc_coordinate === 'AI11'){
                      let valueMa = (Number(alarmValue.value)/4095)*20
                      valueEngine = ((valueMa - 4) / 16) * (10 - 1.01325) + 1.01325
                    }else if(item.plc_coordinate === 'AI10'){
                        let valueMa = ((Number(alarmValue.value)-815)/4095)*200
                        valueEngine = valueMa * 1
                    }else{
                        valueEngine = alarmValue.value
                    }
                    
                    return { ...item, value: valueEngine };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            });
            setListEngine1(updatedList);

            let updatedList2 = listEngine2.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.plc_coordinate);
                if (alarmValue) {
                    return { ...item, value: alarmValue.value };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            });
            setListEngine2(updatedList2);

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }
    const dataShow = [
        {
            label : "11:00",
            value : 3
        },
        {
            label : "11:20",
            value : 20
        },
        {
            label : "11:32",
            value : 12
        },
        {
            label : "11:45",
            value : 16
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 13
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 10
        },
        {
            label : "11:50",
            value : 17
        },
        {
            label : "11:50",
            value : 17
        },
    ]

    return (
        <>
           <Navbar />
           <div className="mainPage">
                <div className="containerBox">
                    <TitleAlarmBar text={'Auxiliary Engine'} />
                    <BottomNavBar/>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexDirection: "row",
                        padding:'5px'}}>
                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Mulai</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                    
                                }}
                                
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{margin: "10px"}}>
                                <Form.Label style={{color: 'white'}}>Tanggal Akhir</Form.Label>
                                <Form.Control
                                type="datetime-local"
                                onChange={(e) => {
                                   
                                }}
                                
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group style={{margin: "10px", color: 'white'}}>
                            <DropDownForm
                                caption={"Rentang Waktu"}
                                placeholder={"Pilih Waktu"}
                                // listOption={}
                                valueKey={"value"}
                                labelKey={"label"}
                                setValueFunction={(e) => {
                                console.log(e);
                                try {
                                    let dateTime = new Date();
                                    // if (e.target.value !== "") {
                                    //   setLoading(true);
                                    // }

                                    if (e.target.value === "1") {
                                    console.log("1");
                                    
                                    } else if (e.target.value === "2") {
                                    console.log("2");
                                    
                                    } else if (e.target.value === "3") {
                                    console.log("3");
                                    
                                    }
                                } catch (exception) {
                                    console.log(exception);
                                }
                                }}
                            />
                            </Form.Group>

                            <Button
                                variant="dark"
                                style={{ height: "40px", margin: "10px", marginTop: "35px", backgroundColor:'#050512' }}
                                onClick={() => {
                                
                                }}
                            >
                                Tampilkan Data
                            </Button>

                            <Form.Label style={{color: 'white'}}>
                                <input
                                style={{marginTop:"30px"}}
                                type="checkbox"
                                // checked={refreshChecked}
                                onChange={(e) => {
                                    // setRefreshChecked(e.target.checked);
                                }}
                                />
                                Auto Refresh
                            </Form.Label>
                    </div>
                    <div style={{display: 'flex', margin:'25px 0px'}}>
                        <DynamicLineChart colorShow={'yellow'} dataShow={dataShow} labelText={'PORT'}/>
                        <div className="statusTable">
                            <h4>AUXILIARY ENGINE (PORT)</h4>
                            <h1>ECU NOT CONNECTED</h1>
                            <table>
                                <tr>
                                    <td width={'90%'}>Coolant Pressure</td>
                                    <td width={'5%'}>0.0</td>
                                    <td width={'5%'}>Bar</td>
                                </tr>
                                <tr>
                                    <td width={'90%'}>Coolant Temp</td>
                                    <td width={'5%'}>0.0</td>
                                    <td width={'5%'}>°C</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div style={{display: 'flex', margin:'25px 0px'}}>
                        <DynamicLineChart colorShow={'green'} dataShow={dataShow} labelText={'STARBOARD'}/>
                        <div className="statusTable">
                            <h4>AUXILIARY ENGINE (STBD)</h4>
                            <h1>ECU NOT CONNECTED</h1>
                            <table>
                            <tr>
                                    <td width={'90%'}>Coolant Pressure</td>
                                    <td width={'5%'}>0.0</td>
                                    <td width={'5%'}>Bar</td>
                                </tr>
                                <tr>
                                    <td width={'90%'}>Coolant Temp</td>
                                    <td width={'5%'}>0.0</td>
                                    <td width={'5%'}>°C</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    
                </div>
           </div>
        </>
    );
}