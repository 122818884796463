import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getVessel, getStaff, insertUpdateVesselHandover, getVesselInventoryHandoverById,
    getRequisitionStatus, getVesselInventoryStock, getVesselHandoverList, getUserAccessById, getCrewOnVessel, getVesselInventoryMaster,
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer, Image } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function VesselHandoverDetailPage() {
    const [listVessel, setListVessel] = useState([]);
    const { t, i18n } = useTranslation();
    const [listVesselInventory, setListVesselInventory] = useState([]);
    const [listInventoryStock, setListInventoryStock] = useState([]);
    const [staffMessage, setStaffMessage] = useState("");
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [listCrew, setListCrew] = useState([]);
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [listHandoverIndex, setListHandoverIndex] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledButtonAdd, setDisableButtonAdd] = useState(false);
    const [inventoryHandover, setInventoryHandover] = useState({
        id: 0,
        vessel_id: null,
        requesting_staff_id: null,
        request_date: null,
        approved_date: null,
        approved_by: null,
        vessel_inventory_requisition_id: null,
        giving_staff_id: null,
        list_handover: []
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadVessel();
                loadStaff();
                loadStatus();
                loadAccess();
                loadCrewStaff();
                // loadVesselInventory();
            }
        }
        checkCookies();
        if (location.state.inventoryHandoverId === 0) {
            setLoading(false);
        } else {
            initVesselHandover();
        }
    }, []);

    useEffect(() => {
        if (inventoryHandover.vessel_inventory_requisition_id) {
            loadVesselInventory();
        }
        validationForm()
        if (inventoryHandover.giving_staff_id === null || inventoryHandover.giving_staff_id === "") {
            setDisabledButton(true);
            setStaffMessage(`${t("detail_vessel_inventory_handover.error_empty_field")}`);
        } else {
            setStaffMessage("");
            setDisabledButton(false);
        }


    }, [inventoryHandover])

    useEffect(() => {
        if (listHandoverIndex === 1) {
            if (inventoryHandover.list_handover.length < 1) {
                let list = [];
                for (let index = 0; index < listInventoryStock.length; index++) {
                    let listRequest = listInventoryStock[index];
                    var obj = {
                        vessel_inventory_stock_id: listRequest.vessel_inventory_id,
                        title: listRequest.vessel_inventory_name,
                        quantity: listRequest.quantity
                    };
                    list.push(obj)
                }
                setInventoryHandover({ ...inventoryHandover, list_handover: list })

            }
        }

    }, [listHandoverIndex])




    const loadVessel = async () => {
        try {
            let response = await getVessel(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listTmp = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id

                };

                listTmp.push(obj);

            }
            setListVessel(listTmp);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadVesselInventory = async () => {
        try {
            let responses = await getVesselHandoverList(cookies.token, inventoryHandover.vessel_inventory_requisition_id);
            setListInventoryStock(responses);

            let response = await getVesselInventoryMaster(cookies.token);
            let listTmp = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_inventory_name,
                    id: response[i].id,
                };

                listTmp.push(obj);

            }
            setListVesselInventory(listTmp);
            setListHandoverIndex(1);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewStaff = async () => {
        try {
            let response = await getCrewOnVessel(cookies.token);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id

                };

                listStaff.push(obj);

            }
            setListCrew(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {

            let response = await insertUpdateVesselHandover(cookies.token, inventoryHandover);
            if (response.error_code === 0) {
                alert(`${t("detail_vessel_inventory_handover.alert_success_save_data")}`);
                navigate('/VesselInventoryHandover');

            } else {
                alert(`${t("detail_vessel_inventory_handover.alert_faield_save_data")}`);
            }

            setDisabledButton(false);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initVesselHandover = async () => {
        try {
            let response = await getVesselInventoryHandoverById(cookies.token, location.state.inventoryHandoverId);
            console.log(response);
            if (response) {
                setInventoryHandover({
                    ...inventoryHandover,
                    id: response.id,
                    vessel_id: response.vessel_id,
                    requesting_staff_id: response.requesting_staff_id,
                    request_date: response.request_date,
                    approved_date: response.approved_date,
                    approved_by: response.approved_by,
                    vessel_inventory_requisition_id: response.vessel_inventory_requisition_id,
                    giving_staff_id: response.giving_staff_id,
                    list_handover: response.list_handover,

                })
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {

                if (inventoryHandover.list_handover.length > 0) {
                    for (let index = 0; index < inventoryHandover.list_handover.length; index++) {
                        const list = inventoryHandover.list_handover[index];
                        if (list.vessel_inventory_stock_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage(`${t("detail_vessel_inventory_handover.error_list_inventory")}`);
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");

                            setDisabledButton(false);

                            setDisableButtonAdd(false);
                        }
                    }
                } else {

                    setErrorMessage("");

                    setDisableButtonAdd(false);

                }


                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = "Form Serah Terima Suku Cadang";
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true)
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_vessel_inventory_handover.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/VesselInventoryHandover"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel_inventory_handover.handover_header")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                                <FormSelect caption={`${t("detail_vessel_inventory_handover.field_vessel_name")}`} placeholder=""
                                                                    options={listVessel} value={listVessel.filter(function (option) {
                                                                        return option.id === inventoryHandover.vessel_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryHandover({ ...inventoryHandover, vessel_id: e.id });
                                                                        } else {
                                                                            setInventoryHandover({ ...inventoryHandover, vessel_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />


                                                                <FormSelect caption={`${t("detail_vessel_inventory_handover.field_staff_requested")}`} placeholder=""
                                                                    options={listCrew} value={listCrew.filter(function (option) {
                                                                        return option.id === inventoryHandover.requesting_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryHandover({ ...inventoryHandover, requesting_staff_id: e.id });
                                                                        } else {
                                                                            setInventoryHandover({ ...inventoryHandover, requesting_staff_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {/* {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                } */}

                                                                <FormSelect caption={`${t("detail_vessel_inventory_handover.field_approved_by")}`} placeholder=""
                                                                    options={staffOptions} value={staffOptions.filter(function (option) {
                                                                        return option.id === inventoryHandover.approved_by;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryHandover({ ...inventoryHandover, approved_by: e.id });
                                                                        } else {
                                                                            setInventoryHandover({ ...inventoryHandover, approved_by: null });
                                                                        }

                                                                    }} disabledForm={true}

                                                                />

                                                                <TextBox value={inventoryHandover.request_date ? moment(inventoryHandover.request_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Request" controlType="date"
                                                                    caption={`${t("detail_vessel_inventory_handover.field_request_date")}`} setValueFunction={(e) => {
                                                                        setInventoryHandover({ ...inventoryHandover, request_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>





                                                                <TextBox value={inventoryHandover.approved_date ? moment(inventoryHandover.approved_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption={`${t("detail_vessel_inventory_handover.field_approved_date")}`} setValueFunction={(e) => {
                                                                        setInventoryHandover({ ...inventoryHandover, approved_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <FormSelect caption={`${t("detail_vessel_inventory_handover.field_given_by")}`} autoFocusValue={inventoryHandover.giving_staff_id === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== inventoryHandover.requesting_staff_id)} value={staffOptions.filter(function (option) {
                                                                        return option.id === inventoryHandover.giving_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setInventoryHandover({ ...inventoryHandover, giving_staff_id: e.id });
                                                                        } else {
                                                                            setInventoryHandover({ ...inventoryHandover, giving_staff_id: null });
                                                                        }

                                                                    }}
                                                                />
                                                                {
                                                                    staffMessage && <p style={{ color: "red" }}>{staffMessage}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {/* {
                                        location.state.inventoryHandoverId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >Cetak <Printer /></Button>
                                            :
                                            <></>
                                    } */}
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel_inventory_handover.header_list_inventory")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    {
                                                        inventoryHandover.list_handover && inventoryHandover.list_handover.map((list, index) => {
                                                            return (
                                                                <div style={{ padding: 5 }}>
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5,
                                                                        borderWidth: 2,
                                                                        borderRadius: 5,
                                                                        borderStyle: "solid",
                                                                        borderColor: "rgba(3, 30, 103, 1)"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                flex: 1,
                                                                                marginTop: "auto",
                                                                                marginBottom: "auto",
                                                                                textAlign: "center"
                                                                            }}>
                                                                                {index + 1}
                                                                            </div>

                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 25,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <Form.Label>{t("detail_vessel_inventory_handover.field_inventory_name")}</Form.Label>
                                                                                <Select placeholder={""}
                                                                                    options={listVesselInventory.filter((p) => {
                                                                                        return !inventoryHandover.list_handover.find((x) => {
                                                                                            return p.id === x.vessel_inventory_stock_id
                                                                                        })
                                                                                    })} value={listVesselInventory.filter(function (option) {
                                                                                        return option.id === list.vessel_inventory_stock_id;
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        let listHandover = inventoryHandover.list_handover;
                                                                                        listHandover[index].vessel_inventory_stock_id = e.id;
                                                                                        listHandover[index].price = e.price;
                                                                                        setInventoryHandover({ ...inventoryHandover, list_handover: listHandover });
                                                                                    }}
                                                                                    isDisabled={true}
                                                                                />

                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                // width: "100%",
                                                                                flexDirection: "column",
                                                                                // alignItems: "flex-end",
                                                                                flex: 4,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                            }}>
                                                                                <Form.Label>{t("detail_vessel_inventory_handover.field_total")}</Form.Label>
                                                                                <Form.Control onChange={(e) => {
                                                                                    let listHandover = inventoryHandover.list_handover;
                                                                                    listHandover[index].quantity = e.target.value;
                                                                                    setInventoryHandover({ ...inventoryHandover, list_handover: listHandover });
                                                                                }} type="text" value={list.quantity} placeholder="" disabled={true}></Form.Control>


                                                                            </div>

                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                alignItems: "flex-end",
                                                                                flex: 1,
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5
                                                                            }}>
                                                                                <div style={{ paddingTop: 30 }}></div>
                                                                                <Link to={`/VesselInventoryHandoverDocument?id=${list.id}`} style={{
                                                                                    pointerEvents: list.id === 0 || list.id === undefined ? "none" : ""
                                                                                }} target="_blank">
                                                                                    <Button disabled={location.state.inventoryHandoverId === 0} onClick={() => {

                                                                                    }} style={{ maxWidth: 50 }} variant="primary"><Image /></Button>
                                                                                </Link>
                                                                            </div>

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            paddingTop: 10,
                                                                            paddingBottom: 10,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap"

                                                                        }}>

                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                                flex: 10
                                                                            }}>
                                                                                <Form.Label>{t("detail_vessel_inventory_handover.field_note")}</Form.Label>
                                                                                <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                                    let listHandover = inventoryHandover.list_handover;
                                                                                    listHandover[index].note = e.target.value;
                                                                                    setInventoryHandover({ ...inventoryHandover, list_handover: listHandover });
                                                                                }} type="text" value={list.note} placeholder="" ></Form.Control>


                                                                            </div>


                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>
                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button style={{width:100}} className="save-button" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">{t("detail_vessel_inventory_handover.save_button")}</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button style={{width:100}} className="cancel-button" onClick={() => {
                                                        navigate("/SparepartHandover")
                                                    }} variant="danger">{t("detail_vessel_inventory_handover.cancel_button")}</Button>
                                                </div>
                                            </div>
                                    }



                                </Form>



                            </div>


                        </div>

                    }>
                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    );

}