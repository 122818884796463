import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, insertUpdateVesselSchedule, getVesselScheduleById, getUserAccessById, getVessel, getCrewXVesselByVesselScheduleId,
    getCrew, getCrewPositionByDepartment, getCrewDepartment, getAllCrewXVessel, getVesselScheduleForCrew,
    getVesselScheduleForCrewByVesselId
} from "../../../../../Helpers/ApplicationHelpers";
import "../../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../../Components/NavBar";
import ContainerBox from "../../../../../Components/ContainerBox";
import Loading from "../../../../../Components/Loading";
import TextBox from "../../../../../Components/Textbox";
import { ArrowLeft } from "react-bootstrap-icons";
import { FilePlusFill, Search, ArrowClockwise, PencilFill, Trash } from "react-bootstrap-icons";
import moment from "moment";
import Select from "react-select"
import FormSelect from "../../../../../Components/FormSelect";
import Paginations from "../../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function VesselScheduleDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [indexListCrew, setIndexListCrew] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const [disabledPosition, setDisablePosition] = useState(false);
    const [listVessel, setListVessel] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [errorStartDate, setErrorStartDate] = useState("");
    const [errorEndDate, setErrorEndDate] = useState("");
    const [listCrewDepartment, setListCrewDepartment] = useState([]);
    const [listAllCrewVessel, setListAllCrewVessel] = useState([]);
    const [listVesselSchedule, setListVesselSchedule] = useState([]);
    const [listCrewPositions, setListCrewPositions] = useState([]);
    const [errorVesselScheduleStartDate, setErrorVesselScheduleStartDate] = useState("")
    const [errorVesselScheduleEndDate, setErrorVesselScheduleEndDate] = useState("");
    const [disabledButtonCrew, setDisableButtonCrew] = useState(false);
    const [selectedVessel, setSelectedVessel] = useState({});
    const [errorCrew, setErrorCrew] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [addVesselCrewModal, setAddVesselCrewModal] = useState(false);
    const [selectedCrewPosition, setSelectedCrewPosition] = useState({});
    const [errorWorkDays, setErrorWorkDays] = useState("");
    const [selectedCrew, setSelectedCrew] = useState({});
    const [loading, setLoading] = useState(true);
    const [listCrewVessel, setListCrewVessel] = useState([]);
    const [listCrewVesselSearched, setListCrewVesselSearched] = useState([]);
    const [removeScheduleId, setRemoveScheduleId] = useState("");
    const [totalWorkDays, setTotalWorkDays] = useState(0);
    const [isSearched, setIsSearched] = useState("");
    const [listCrew, setListCrew] = useState([]);
    const [crewScheduleId, setCrewScheduleId] = useState("");
    const [vesselScheduleId, setVesselScheduleId] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [vesselSchedule, setVesselSchedule] = useState({
        id: 0,
        vessel_id: "",
        start_date: null,
        end_date: null,
        departing_from: "",
        departure_destination: "",
        note: ""
    })
    const [addedVesselCrew, setAddedVesselCrew] = useState({
        id: 0,
        vessel_id: vesselSchedule.id,
        crew_id: null,
        department_id: null,
        start_date: null,
        end_date: null,
        crew_positions_id: null,
        staff_name: "",
        position_name: ""
        // ext_customer_id: staff.ext_customer_id
    })

    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [descending, setDescending] = useState(true);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([50, 100, 150, 250, 500]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("vessel_schedule_detail.order_name1")}`,
        value: "staff.staff_name"
    });
    const orderByList = [{
        label:  `${t("vessel_schedule_detail.order_name1")}`,
        value: "staff.staff_name"
    }];

    const [searchSchedule, setSearchSchedule] = useState("");
    const [resetSearchSchedule, setResetSearchSchedule] = useState(0);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadAccess();
                loadVessel();
                loadCrew();
                loadCrewXVessel();
                loadCrewPositions();
                loadCrewDepartment();
                setVesselScheduleId(searchParams.get("id"))
            }
        }
        checkCookies();

    }, []);

    useEffect(() => {
        if (vesselSchedule.vessel_id) {
            let vessel = listVessel.find(p => p.id === vesselSchedule.vessel_id);
            if (vessel) {
                setSelectedVessel(vessel);
            }

        }
        // validationForm();
    }, [vesselSchedule]);

    useEffect(() => {
        if (listVessel.length > 0) {
            if (vesselSchedule.vessel_id !== null || vesselSchedule.vessel_id !== undefined) {
                let vessel = listVessel.find(p => p.id === vesselSchedule.vessel_id);
                if (vessel) {
                    setSelectedVessel(vessel);
                }
            }
        }
    }, [listVessel])

    useEffect(() => {
        if (listVessel.length > 0 && vesselScheduleId === "") {
            let listTmp = listVessel.find(p => p.id === searchParams.get("vesselId"))
            if (listTmp) {
                setSelectedVessel(listTmp)
            }
        }

    }, [listVessel])

    useEffect(() => {
        if (listCrewVessel.length > 0) {
            setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)));

            let totalPage = listCrewVessel.length / itemPerPage;
            setTotalPage(totalPage);
        } else {

        }
    }, [listCrewVessel])

    useEffect(() => {
        if (searchSchedule !== "") {
            let listCrewVesselSearched = listCrewVessel.filter(p => p.staff_name.toLowerCase().includes(searchSchedule.toLowerCase()));
            if (listCrewVesselSearched)
                setListCrewVesselSearched(listCrewVesselSearched);
            else
                setListCrewVesselSearched([]);
        } else {
            setListCrewVesselSearched(listCrewVesselSearched);
        }
    }, [searchSchedule])

    useEffect(() => {
        setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)));
    }, [page]);

    useEffect(() => {
        setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)));
    }, [itemPerPage]);

    useEffect(() => {
        if (orderBy.value === 'staff.staff_name') {
            setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.staff_name > b.staff_name ? 1 : -1)));
        } else {
            setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.staff_name > b.staff_name ? 1 : -1)));
        }
    }, [orderBy]);

    useEffect(() => {
        if (descending === true) {
            if (orderBy.value === 'staff.staff_name') {
                setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.staff_name > b.staff_name ? 1 : -1)));
            }
        } else {
            if (orderBy.value === 'staff.staff_name') {
                setListCrewVesselSearched(listCrewVessel.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.staff_name > b.staff_name ? -1 : 1)));
            }
        }

    }, [descending]);


    useEffect(() => {
        if (selectedVessel.vessel_name) {
            setVesselSchedule({ ...vesselSchedule, vessel_id: selectedVessel.id });
        } else {
            setVesselSchedule({ ...vesselSchedule, vessel_id: null });
        }
    }, [selectedVessel]);

    useEffect(() => {
        if (selectedDepartment.department_name) {
            setAddedVesselCrew({ ...addedVesselCrew, department_id: selectedDepartment.id });
            loadCrewPositions();
        } else {
            setAddedVesselCrew({ ...addedVesselCrew, department_id: null, crew_positions_id: null });
            // setSelectedCrewPosition({})
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedCrewPosition.position_name) {
            setAddedVesselCrew({ ...addedVesselCrew, crew_positions_id: selectedCrewPosition.id, position_name: selectedCrewPosition.position_name });
        } else {
            setAddedVesselCrew({ ...addedVesselCrew, crew_positions_id: null, position_name: "" });
        }
    }, [selectedCrewPosition]);

    useEffect(() => {
        if (selectedCrew.staff_name) {
            if (addedVesselCrew.id === "") {
                setAddedVesselCrew({ ...addedVesselCrew, crew_id: selectedCrew.id, staff_name: selectedCrew.staff_name });
            } else {
                setAddedVesselCrew({ ...addedVesselCrew, crew_id: selectedCrew.id, staff_name: selectedCrew.staff_name, start_date: null, end_date: null });
            }

        } else {
            setAddedVesselCrew({ ...addedVesselCrew, crew_id: null, staff_name: "", department_id: null, crew_positions_id: null, start_date: null, end_date: null });
        }
    }, [selectedCrew]);

    useEffect(() => {
        if (vesselScheduleId !== 0 && vesselScheduleId !== "") {
            initVesselSchedule();
            loadCrewVesselByVesselSchedule();
        } else {
            setLoading(false)
        }

    }, [vesselScheduleId]);

    useEffect(() => {

        if (addedVesselCrew.start_date !== null && addedVesselCrew.end_date !== null) {
            // if(addedVesselCrew.id !== ""){
            for (let index = 0; index < listAllCrewVessel.length; index++) {
                const listScheduleTmp = listAllCrewVessel[index];
                if (listScheduleTmp.crew_id === addedVesselCrew.crew_id) {
                    if (moment(listScheduleTmp.start_date).format("yyyy-MM-DD") > moment(addedVesselCrew.start_date).format("yyyy-MM-DD") && moment(listScheduleTmp.start_date).format("yyyy-MM-DD") < moment(addedVesselCrew.end_date).format("yyyy-MM-DD") || moment(addedVesselCrew.start_date).format("yyyy-MM-DD") > moment(listScheduleTmp.start_date).format("yyyy-MM-DD") && moment(addedVesselCrew.start_date).format("yyyy-MM-DD") < moment(listScheduleTmp.end_date).format("yyyy-MM-DD")) {
                        setErrorEndDate(`${t("vessel_schedule_detail.error_schedule")}`);

                        setAddedVesselCrew({ ...addedVesselCrew, end_date: null })
                    }
                }

            }
            // }

        }

    }, [addedVesselCrew.start_date, addedVesselCrew.end_date])

    useEffect(()=>{
        if(vesselSchedule.vessel_id !== ""){
            loadVesselSchedule();

        }
    },[vesselSchedule.vessel_id])

    useEffect(() => {

        if (vesselSchedule.start_date !== null && vesselSchedule.end_date !== null) {
            // if(addedVesselCrew.id !== "")
            for (let index = 0; index < listVesselSchedule.length; index++) {
                const listScheduleTmp = listVesselSchedule[index];
                if (listScheduleTmp.vessel_id === vesselSchedule.vessel_id) {
                    if (moment(listScheduleTmp.start_date).format("yyyy-MM-DD") > moment(vesselSchedule.start_date).format("yyyy-MM-DD") && moment(listScheduleTmp.start_date).format("yyyy-MM-DD") < moment(vesselSchedule.end_date).format("yyyy-MM-DD") || moment(vesselSchedule.start_date).format("yyyy-MM-DD") > moment(listScheduleTmp.start_date).format("yyyy-MM-DD") && moment(vesselSchedule.start_date).format("yyyy-MM-DD") < moment(listScheduleTmp.end_date).format("yyyy-MM-DD")) {
                        setErrorVesselScheduleEndDate(`${t("vessel_schedule_detail.error_schedule")}`);

                        setVesselSchedule({ ...vesselSchedule, end_date: null })
                    }
                }

            }
            // }

        }

    }, [vesselSchedule.start_date, vesselSchedule.end_date])

    // const checkScheduleDate =  (endDate) => {
    //     try {
    //         if (vesselSchedule.start_date !== null && vesselSchedule.end_date !== null) {
    //                     for (let index = 0; index < listVesselSchedule.length; index++) {
    //                         const listScheduleTmp = listVesselSchedule[index];
    //                         if (listScheduleTmp.vessel_id === vesselSchedule.vessel_id) {
    //                             if (moment(listScheduleTmp.start_date).format("yyyy-MM-DD") > moment(vesselSchedule.start_date).format("yyyy-MM-DD") && moment(listScheduleTmp.start_date).format("yyyy-MM-DD") < moment(endDate).format("yyyy-MM-DD") || moment(vesselSchedule.start_date).format("yyyy-MM-DD") > moment(listScheduleTmp.start_date).format("yyyy-MM-DD") && moment(vesselSchedule.start_date).format("yyyy-MM-DD") < moment(listScheduleTmp.end_date).format("yyyy-MM-DD")) {
    //                                 setErrorVesselScheduleEndDate("Jadwal Sudah Terisi");

    //                                 setVesselSchedule({ ...vesselSchedule, end_date: null })
    //                             }
    //                         }

    //                     }


    //                 }
    //         } catch (exception) {

    //         }
    //     }

    const resetAddVesselCrew = async () => {
            let crewScheduleTmp = {};
            crewScheduleTmp.id = 0;
            crewScheduleTmp.vessel_id = vesselSchedule.id;
            crewScheduleTmp.department_id = null;
            crewScheduleTmp.crew_id = null;
            crewScheduleTmp.start_date = null;
            crewScheduleTmp.end_date = null;
            crewScheduleTmp.crew_positions_id = null;

            setAddedVesselCrew(crewScheduleTmp);
            setErrorEndDate("");
            setErrorStartDate("");
        }

        useEffect(() => {
            if (addedVesselCrew.crew_id) {
                let crew = listCrew.find(p => p.id === addedVesselCrew.crew_id);
                if (crew) {
                    setSelectedCrew(crew);
                }

            }

            if (addedVesselCrew.department_id) {
                let department = listCrewDepartment.find(p => p.id === addedVesselCrew.department_id);
                if (department) {
                    setSelectedDepartment(department);
                }
            }

            if (addedVesselCrew.crew_positions_id) {
                let crewPositions = listCrewPositions.find(p => p.id === addedVesselCrew.crew_positions_id);
                if (crewPositions) {
                    setSelectedCrewPosition(crewPositions)
                }

            }

            if (addedVesselCrew.crew_id === null || addedVesselCrew.crew_id === undefined) {
                setErrorCrew(`${t("vessel_schedule_detail.error_empty_field")}`);
                setDisableButtonCrew(true)
            } else {
                setErrorCrew("");
                setDisableButtonCrew(false);
            }


        }, [addedVesselCrew]);

        useEffect(() => {
            if (listCrewPositions.length > 0) {
                if (addedVesselCrew.crew_positions_id !== null && addedVesselCrew.crew_positions_id !== "") {
                    let crewPositionTmp = listCrewPositions.find(p => p.id === addedVesselCrew.crew_positions_id);
                    if (crewPositionTmp) {
                        setSelectedCrewPosition(crewPositionTmp)
                    }
                } else {
                    setSelectedCrewPosition({})
                }
            }
        }, [listCrewPositions, addedVesselCrew.crew_positions_id])

        useEffect(() => {
            if (indexListCrew !== "") {
                initListCrew();
            }
        }, [indexListCrew])


        const loadVessel = async () => {
            try {
                let response = await getVessel(cookies.token, cookies.languageId, "null");
                setListVessel(response);
            } catch (exception) {
                console.log(exception)
            }
        }


        const loadCrewPositions = async () => {
            try {
                let response = await getCrewPositionByDepartment(cookies.token, selectedDepartment.id);
                setListCrewPositions(response.slice(0));
            } catch (exception) {

            }
        }

        const loadCrewDepartment = async () => {
            try {
                let response = await getCrewDepartment(cookies.token);
                setListCrewDepartment(response);
            } catch (exception) {
                console.log(exception)
            }
        }



        const saveData = async () => {
            try {
                let vesselScheduleTmp = vesselSchedule;
                vesselSchedule.list_crew = listCrewVessel
                let response = await insertUpdateVesselSchedule(cookies.token, vesselScheduleTmp);
                if (response.error_code === 0) {
                    alert(`${t("vessel_schedule_detail.alert_success_save_data")}`);
                    if (vesselScheduleId === "") {
                        setVesselScheduleId(response.data.id)
                    } else {
                        initVesselSchedule();
                        loadCrewVesselByVesselSchedule();
                    }
                } else {
                    alert(`${t("vessel_schedule_detail.alert_failed_save_data")}`)
                }
                setDisabledButton(false)
            } catch (exception) {
                // setErrorMessage("Data Gagal Disimpan");
            }
        }

        const initVesselSchedule = async () => {
            try {
                let response = await getVesselScheduleById(cookies.token, vesselScheduleId);
                if (response) {
                    setVesselSchedule({
                        ...vesselSchedule,
                        id: response.id,
                        vessel_id: response.vessel_id,
                        start_date: response.start_date,
                        end_date: response.end_date,
                        departing_from: response.departing_from,
                        departure_destination: response.departure_destination,
                        note: response.note
                    })
                    setLoading(false);
                } else {
                    alert(`${t("vessel_schedule_detail.alert_failed_load_data")}`);
                }
            }
            catch (exception) {
                console.log(exception);
            }

        }

        const loadCrew = async () => {
            try {
                let response = await getCrew(cookies.token, cookies.languageId, "null");
                setListCrew(response);
            } catch (exception) {
                console.log(exception)
            }
        }

        const loadCrewVesselByVesselSchedule = async () => {
            try {
                let response = await getCrewXVesselByVesselScheduleId(cookies.token, vesselScheduleId);
                setListCrewVessel(response);
            } catch (exception) {
                console.log(exception)
            }
        }

        const loadCrewXVessel = async () => {
            try {
                let response = await getAllCrewXVessel(cookies.token);
                setListAllCrewVessel(response);
            } catch (exception) {
                console.log(exception)
            }
        }

        const addListData = async () => {
            try {
                if (addedVesselCrew.indexCrew === undefined) {
                    setListCrewVessel([...listCrewVessel, addedVesselCrew]);
                    resetAddVesselCrew();
                } else {
                    let listCrewVesselTmp = listCrewVessel
                    listCrewVesselTmp[addedVesselCrew.indexCrew] = addedVesselCrew
                    setListCrewVessel(listCrewVesselTmp.slice(0));
                    resetAddVesselCrew();
                }
                setAddVesselCrewModal(false);
                setDisableButtonCrew(false);
            } catch (exception) {
                console.log(exception)
            }
        }

        const initListCrew = async () => {
            try {
                let response = listCrewVesselSearched[indexListCrew];
                setAddedVesselCrew({
                    ...addedVesselCrew,
                    id: "",
                    staff_name: response.staff_name,
                    position_name: response.position_name,
                    vessel_id: response.vessel_id,
                    crew_id: response.crew_id,
                    department_id: response.department_id,
                    crew_positions_id: response.crew_positions_id,
                    start_date: response.start_date,
                    end_date: response.end_date,
                    indexCrew: indexListCrew
                })
                setAddVesselCrewModal(true);
                setIndexListCrew("");
            } catch (exception) {
                console.log(exception);
            }
        }

        // const validationForm = () => {
        //     return new Promise(async (resolve, reject) => {
        //         try {
        //             if (vesselSchedule.vessel_id === undefined || vesselSchedule.vessel_id === "") {
        //                 setErrors({
        //                     ...errors,
        //                     countryName: 'Harus Diisi'
        //                 },
        //                     setDisabledButton(true))
        //             }
        //             else {
        //                 let errorMsg = omit(errors, "countryName");
        //                 setErrors(errorMsg);
        //                 setDisabledButton(false);
        //             }
        //             resolve(true);

        //         } catch (exception) {
        //             console.log(exception);
        //             resolve(false);

        //         }
        //     })
        // }

        const loadAccess = async () => {
            try {
                // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
                let response = await getUserAccessById(cookies.token, "/Vessel");
                if (response) {
                    setUpdatedButton(response.updated)
                }
                setOrderBy({
                    label: `${t("vessel_schedule_detail.order_name1")}`,
                    value: "staff.staff_name"
                })

            } catch (exception) {
                console.log(exception);
            }
        }

        const loadVesselSchedule = async () => {
            try {
                let response = await getVesselScheduleForCrewByVesselId(cookies.token, vesselSchedule.vessel_id);
                // console.log(response);
                setListVesselSchedule(response);
            } catch (exception) {
                console.log(exception);
            }
        }




        return (
            <>
                <Navbar />
                <Container fluid style={{
                    display: "flex",
                    flex: 1,
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center"
                }}>
                    <ContainerBox titleCaption={`${t("vessel_schedule_detail.header_title")}`}

                        childContent={
                            <div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    padding: 5,
                                    width: "100%",
                                    flexWrap: "nowrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("vessel_schedule_detail.header_schedule")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("vessel_schedule_detail.field_vessel_name")}</Form.Label>
                                                        <Select placeholder={"Pilih Kapal"}
                                                            getOptionLabel={(item) => {
                                                                return item.vessel_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listVessel} value={selectedVessel} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedVessel({})
                                                                } else {
                                                                    setSelectedVessel(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            isDisabled={true}
                                                        />

                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("vessel_schedule_detail.field_start_date")}</Form.Label>
                                                        <Form.Control value={vesselSchedule.start_date ? moment(vesselSchedule.start_date).format("yyyy-MM-DD") : ""}
                                                            type="date" onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    setVesselSchedule({ ...vesselSchedule, start_date: null })
                                                                } else {
                                                                    let isScheduled = 0
                                                                    for (let index = 0; index < listVesselSchedule.length; index++) {
                                                                        const listScheduleTmp = listVesselSchedule[index];
                                                                        if (listScheduleTmp.vessel_id === vesselSchedule.vessel_id) {
                                                                            if (moment(e.target.value).format("yyyy-MM-DD") >= moment(listScheduleTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(listScheduleTmp.end_date).format("yyyy-MM-DD")) {
                                                                                setErrorVesselScheduleStartDate("Jadwal Sudah Terisi");

                                                                                setVesselSchedule({ ...vesselSchedule, start_date: null, end_date: null })
                                                                                isScheduled = 1
                                                                            }
                                                                        }

                                                                    }
                                                                    if (isScheduled === 1) {
                                                                        // setVesselSchedule({ ...vesselSchedule, start_date: null })
                                                                    } else {
                                                                        setVesselSchedule({ ...vesselSchedule, start_date: e.target.value })
                                                                        setErrorVesselScheduleStartDate("")
                                                                    }

                                                                }
                                                            }} disabled={listCrewVesselSearched.length > 0 || vesselSchedule.vessel_id === null}></Form.Control>
                                                    </Form.Group>
                                                    {
                                                        errorVesselScheduleStartDate && <p style={{ color: "red" }}>{errorVesselScheduleStartDate}</p>
                                                    }
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("vessel_schedule_detail.field_end_date")}</Form.Label>
                                                        <Form.Control disabled={listCrewVesselSearched.length > 0 || vesselSchedule.start_date === null} value={vesselSchedule.end_date ? moment(vesselSchedule.end_date).format("yyyy-MM-DD") : ""}
                                                            type="date" onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    setVesselSchedule({ ...vesselSchedule, end_date: null })
                                                                } else {
                                                                    let isScheduled = 0
                                                                    if (moment(e.target.value).format("yyyy-MM-DD") < moment(vesselSchedule.start_date).format("yyyy-MM-DD")) {
                                                                        setErrorVesselScheduleEndDate(`${t("vessel_schedule_detail.error_end_date")}`);
                                                                        isScheduled = 1
                                                                    } else {
                                                                        for (let index = 0; index < listVesselSchedule.length; index++) {
                                                                            const listScheduleTmp = listVesselSchedule[index];
                                                                            if (listScheduleTmp.vessel_id === vesselSchedule.vessel_id) {
                                                                                if (moment(e.target.value).format("yyyy-MM-DD") >= moment(listScheduleTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(listScheduleTmp.end_date).format("yyyy-MM-DD")) {
                                                                                    setErrorVesselScheduleEndDate(`${t("vessel_schedule_detail.error_crew_schedule")}`);

                                                                                    setVesselSchedule({ ...vesselSchedule, end_date: null })
                                                                                    isScheduled = 1
                                                                                }
                                                                            }

                                                                        }
                                                                    }

                                                                    if (isScheduled === 1) {
                                                                        // setVesselSchedule({ ...vesselSchedule, start_date: null })
                                                                    } else {
                                                                        setVesselSchedule({ ...vesselSchedule, end_date: e.target.value });
                                                                        setErrorVesselScheduleEndDate("")
                                                                    }

                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>

                                                    {
                                                        errorVesselScheduleEndDate && <p style={{ color: "red" }}>{errorVesselScheduleEndDate}</p>
                                                    }
                                                    <Form.Group>
                                                        <Form.Label>{t("vessel_schedule_detail.field_from")}</Form.Label>
                                                        <Form.Control type="text" value={vesselSchedule.departing_from} onChange={(e) => {
                                                            setVesselSchedule({ ...vesselSchedule, departing_from: e.target.value })
                                                        }}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>{t("vessel_schedule_detail.field_to")}</Form.Label>
                                                        <Form.Control type="text" value={vesselSchedule.departure_destination} onChange={(e) => {
                                                            setVesselSchedule({ ...vesselSchedule, departure_destination: e.target.value })
                                                        }}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>{t("vessel_schedule_detail.field_note")}</Form.Label>
                                                        <Form.Control as={"textarea"} rows={3} value={vesselSchedule.note} onChange={(e) => {
                                                            setVesselSchedule({ ...vesselSchedule, note: e.target.value })
                                                        }}></Form.Control>
                                                    </Form.Group>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                        padding: 10,
                                                        width: "100%",
                                                        justifyContent: "center",
                                                        fontWeight: "bold"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            paddingRight: 5,
                                                        }}>
                                                            <Button style={{width:100}} className="save-button" disabled={disabledButton} type="submit" onClick={() => {
                                                                setDisabledButton(true);
                                                                saveData();
                                                            }}>{t("vessel_schedule_detail.save_button")}</Button>

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            paddingLeft: 5,
                                                            paddingRight: 5
                                                        }}>
                                                            <Button style={{width:100}} onClick={() => {
                                                                // navigate('/ServiceJob')
                                                            }} className="cancel-button" type="reset" variant="danger">{t("vessel_schedule_detail.cancel_button")}</Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                        {
                                            vesselScheduleId !== 0 && vesselScheduleId !== "" &&
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                // paddingBottom: 5,
                                                width: "100%",
                                                flexWrap: "nowrap",
                                            }}>
                                                <ContainerBox
                                                    containerPos="inner"
                                                    titleCaption={`${t("vessel_schedule_detail.header_crew_list")}`}
                                                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                    actionContainerChild={
                                                        <div>
                                                            <Button disabled={!updatedButton} variant="secondary" style={{

                                                            }} onClick={() => {
                                                                setAddVesselCrewModal(true);
                                                                resetAddVesselCrew();
                                                                setSelectedCrewPosition({});
                                                                setSelectedCrew({});
                                                                setSelectedDepartment({})
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    flexDirection: "row",
                                                                    alignSelf: "center",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flex: 1,
                                                                    }}><FilePlusFill size={32} /></div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 8,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        paddingLeft: 10,
                                                                        fontWeight: "bold",
                                                                        fontSize: 18,
                                                                    }}>{t("vessel_schedule_detail.add_button")}</div>
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    }

                                                    childContent={
                                                        <div className="master-table-inner-container">
                                                            <div className="master-table-searchbar-container">
                                                                <div className="master-table-searchbar-textbox">
                                                                    <Form.Control value={searchSchedule} type="text" placeholder={`${t("vessel_schedule_detail.search_box")}`}
                                                                        onKeyPress={async (e) => {
                                                                            if (e.charCode === 13) {
                                                                                setPage(0)
                                                                                // setLoading(true);
                                                                                // await loadCrewSchedule();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setSearchSchedule(e.target.value);
                                                                        }} />
                                                                </div>
                                                                <div className="master-table-searchbar-button">
                                                                    <div style={{ paddingRight: 5 }}>
                                                                        <Button onClick={async () => {
                                                                            // setLoading(true);
                                                                            setPage(0)
                                                                            // await loadCrewSchedule();
                                                                        }}><Search /></Button>
                                                                    </div>
                                                                    <div style={{ paddingLeft: 5 }}>
                                                                        <Button onClick={async () => {
                                                                            // setLoading(true);
                                                                            setSearchSchedule("");
                                                                            setResetSearchSchedule(1);
                                                                        }}><ArrowClockwise /></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{

                                                                maxHeight: 400,
                                                                overflowY: "auto",
                                                                border: "1px solid #ccc"
                                                            }}>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("vessel_schedule_detail.table_name1")}</div>
                                                                        <div style={{ flex: 3 }} className="table-header-content">{t("vessel_schedule_detail.table_name2")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("vessel_schedule_detail.table_name3")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("vessel_schedule_detail.table_name4")}</div>
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("vessel_schedule_detail.table_name5")}</div>
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("vessel_schedule_detail.table_name6")}</div>

                                                                    </div>
                                                                    {
                                                                        listCrewVesselSearched.map((crews, index) => {
                                                                            return (
                                                                                <div key={index} className="table-body">
                                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                    <div style={{ flex: 4 }} className="table-body-content"><p>{crews.staff_name}</p></div>
                                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{crews.position_name}</p></div>
                                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{crews.start_date ? moment(crews.start_date).format("DD/MM/yyyy") : ""}</p></div>
                                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{crews.end_date ? moment(crews.end_date).format("DD/MM/yyyy") : ""}</p></div>

                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>

                                                                                            <Button variant="primary" onClick={() => {
                                                                                                setIndexListCrew(index);
                                                                                            }} >
                                                                                                <PencilFill size={20} />


                                                                                            </Button>

                                                                                        </div></div>


                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>
                                                                                            <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : false} variant="danger" onClick={() => {
                                                                                                if (window.confirm(`${t("vessel_schedule_detail.delete_confirmation")}  ${crews.vessel_name}?`)) {
                                                                                                    const list = [...listCrewVessel];
                                                                                                    list.splice(index, 1);
                                                                                                    setListCrewVessel(list);

                                                                                                }
                                                                                            }}><Trash />
                                                                                            </Button>
                                                                                        </div></div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>
                                                                </div>
                                                            </div>
                                                            <Paginations
                                                                itemPerPage={itemPerPage}
                                                                totalPage={totalPage}
                                                                page={page}
                                                                setPage={setPage}
                                                                setItemPerPage={setItemPerPage}
                                                                itemPerPageSelection={itemPerPageSelection}
                                                                orderBy={orderBy}
                                                                setOrderBy={setOrderBy}
                                                                orderBySelection={orderByList}
                                                                isDescActive={descending}
                                                                setIsDescActive={setDescending}
                                                                setIsSearched={setIsSearched}
                                                            />

                                                        </div>
                                                    } />
                                            </div>
                                        }

                                    </div>


                                </div>


                            </div>

                        }>

                    </ContainerBox>

                    <Modal show={addVesselCrewModal}
                        size={"lg"}
                        onHide={() => {
                            setAddVesselCrewModal(false);
                            resetAddVesselCrew();
                            setSelectedCrewPosition({});
                            setSelectedDepartment({})
                            setListCrewPositions([])
                            setSelectedCrew({});
                        }}>
                        <div className="details m-2" >
                            <div className="detailscontent">
                                <h3>{t("vessel_schedule_detail.modal_add_schedule")} </h3>
                            </div>

                            <Form onSubmit={(e) => {
                                setDisableButtonCrew(true);
                                e.preventDefault();
                                addListData();
                                setErrorWorkDays("");
                                // saveCrewSchedule()
                            }} style={{ padding: 10 }}>

                                <FormSelect caption={`${t("vessel_schedule_detail.field_crew")}`} placeholder=""
                                    options={listCrew} value={selectedCrew} setValueFunction={(e) => {

                                        if (e === null) {
                                            setSelectedCrew({});
                                            setSelectedDepartment({});
                                            setDisablePosition(false);
                                        } else {
                                            if (e.crew_type_name === "Non Perwira") {
                                                setDisablePosition(true)
                                            } else {
                                                setDisablePosition(false);
                                            }
                                            setSelectedCrew(e);
                                        }
                                    }}
                                    getOptionLabel={
                                        (item) => {
                                            return item.staff_name;
                                        }
                                    } getOptionValue={
                                        (item) => {
                                            return item.id;
                                        }
                                    }
                                />
                                {
                                    errorCrew && <p style={{ color: "red" }}>{errorCrew}</p>
                                }
                                <FormSelect caption={`${t("vessel_schedule_detail.field_department")}`} placeholder=""
                                    options={listCrewDepartment} value={selectedDepartment} setValueFunction={(e) => {
                                        // setSelectedCrewPosition({});
                                        // setCrewSchedule({...crewSchedule,crew_positions_id:null})
                                        if (e === null) {
                                            setSelectedDepartment({});

                                        } else {
                                            setSelectedDepartment(e);
                                        }
                                    }}
                                    getOptionLabel={
                                        (item) => {
                                            return item.department_name;
                                        }
                                    } getOptionValue={
                                        (item) => {
                                            return item.id;
                                        }
                                    } disabledForm={(cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                />

                                <FormSelect caption={`${t("vessel_schedule_detail.field_position")}`} placeholder=""
                                    options={listCrewPositions} value={selectedCrewPosition} setValueFunction={(e) => {
                                        if (e === null) {
                                            setSelectedCrewPosition({})
                                        } else {
                                            setSelectedCrewPosition(e);
                                        }
                                    }}
                                    getOptionLabel={
                                        (item) => {
                                            return item.position_name;
                                        }
                                    } getOptionValue={
                                        (item) => {
                                            return item.id;
                                        }
                                    }
                                    disabledForm={disabledPosition}
                                />
                                {/* {
                                errorCrewPosition && <p style={{ color: "red" }}>{errorCrewPosition}</p>
                            } */}

                                <TextBox disabledForm={addedVesselCrew.crew_id === null} req={true} value={addedVesselCrew.start_date ? moment(addedVesselCrew.start_date).format("yyyy-MM-DD") : ""} controlType="date" placeholder=""
                                    caption={`${t("vessel_schedule_detail.field_start_date")}`} setValueFunction={(e) => {
                                        if (e.target.value === "") {
                                            setAddedVesselCrew({ addedVesselCrew, start_date: null, end_date: null })
                                        } else {
                                            let isScheduled = 0;
                                            if (moment(e.target.value).format("yyyy-MM-DD") >= moment(vesselSchedule.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(vesselSchedule.end_date).format("yyyy-MM-DD")) {
                                                let crewTmp = listCrewVesselSearched.find(p => p.crew_id === addedVesselCrew.crew_id);
                                               
                                                if (crewTmp) {
                                                    if (moment(e.target.value).format("yyyy-MM-DD") >= moment(crewTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(crewTmp.end_date).format("yyyy-MM-DD")) {
                                                        setErrorStartDate(`${t("vessel_schedule_detail.error_schedule")} ${crewTmp.staff_name} ${t("vessel_schedule_detail.error_schedule2")} ${moment(e.target.value).format("DD-MM-yyyy")}`)
                                                        isScheduled = 1
                                                    } else {
                                                        let scheduleTmp = listAllCrewVessel.filter(p => p.crew_id === addedVesselCrew.crew_id);
                                                        if (scheduleTmp.length > 0) {
                                                            for (let index = 0; index < scheduleTmp.length; index++) {
                                                                const crewScheduleTmp = scheduleTmp[index];
                                                                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                                                                const firstDate = moment(crewScheduleTmp.start_date);
                                                                const secondDate = moment(crewScheduleTmp.end_date);

                                                                const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                                                                crewScheduleTmp.total_days = diffDays
                                                                if (moment(e.target.value).format("yyyy-MM-DD") >= moment(crewScheduleTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(crewScheduleTmp.end_date).format("yyyy-MM-DD")) {
                                                                    setErrorStartDate(`${t("vessel_schedule_detail.error_schedule")} ${crewTmp.staff_name} ${t("vessel_schedule_detail.error_schedule2")} ${moment(e.target.value).format("DD-MM-yyyy")}`);
                                                                    isScheduled = 1;
                                                                }

                                                            }

                                                        }

                                                    }
                                                } else {
                                                    let scheduleTmp = listAllCrewVessel.filter(p => p.crew_id === addedVesselCrew.crew_id);
                                                    if (scheduleTmp.length > 0) {
                                                        for (let index = 0; index < scheduleTmp.length; index++) {
                                                            const crewScheduleTmp = scheduleTmp[index];
                                                            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                                                            const firstDate = moment(crewScheduleTmp.start_date);
                                                            const secondDate = moment(crewScheduleTmp.end_date)

                                                            const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                                                            crewScheduleTmp.total_days = diffDays
                                                            if (moment(e.target.value).format("yyyy-MM-DD") >= moment(crewScheduleTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(crewScheduleTmp.end_date).format("yyyy-MM-DD")) {
                                                                setErrorStartDate(`{t("vessel_schedule_detail.error_schedule")} ${crewScheduleTmp.staff_name} {t("vessel_schedule_detail.error_schedule2")} ${moment(e.target.value).format("DD-MM-yyyy")}`);
                                                                isScheduled = 1;
                                                            }

                                                        }

                                                    }
                                                }
                                                if (isScheduled === 1) {

                                                    setAddedVesselCrew({ ...addedVesselCrew, start_date: null, end_date: null })
                                                } else {
                                                    let total = 0
                                                    let scheduleTmp = listAllCrewVessel.filter(p => p.crew_id === addedVesselCrew.crew_id);
                                                    for (let index = 0; index < scheduleTmp.length; index++) {
                                                        const totalDays = scheduleTmp[index].total_days;
                                                        total += Number(totalDays);

                                                    }
                                                    setTotalWorkDays(total);
                                                    if (total > 180) {
                                                        setErrorWorkDays(`${t("vessel_schedule_detail.error_work_day")}`);
                                                    }
                                                    setAddedVesselCrew({ ...addedVesselCrew, start_date: e.target.value });
                                                    isScheduled = 0;
                                                    setErrorStartDate("");
                                                }


                                            } else {
                                                setErrorStartDate(`${t("vessel_schedule_detail.error_start_date")} ${moment(vesselSchedule.start_date).format("DD-MM-yyyy")} ${t("vessel_schedule_detail.error_start_date2")} ${moment(vesselSchedule.end_date).format("DD-MM-yyyy")}`);
                                                setAddedVesselCrew({ ...addedVesselCrew, start_date: null });
                                                setErrorWorkDays("")
                                            }
                                        }



                                    }} />

                                {
                                    errorStartDate && <p style={{ color: "red" }}>{errorStartDate}</p>
                                }

                                <TextBox disabledForm={addedVesselCrew.start_date === null} req={true} value={addedVesselCrew.end_date ? moment(addedVesselCrew.end_date).format("yyyy-MM-DD") : ""} controlType="date" placeholder=""
                                    caption={`${t("vessel_schedule_detail.field_start_date")}`} setValueFunction={(e) => {
                                        let today = new Date();
                                        if (e.target.value === "") {
                                            setAddedVesselCrew({ ...addedVesselCrew, end_date: null });
                                        } else {
                                            let isScheduled = 0;
                                            if (moment(e.target.value).format("yyyy-MM-DD") >= moment(vesselSchedule.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(vesselSchedule.end_date).format("yyyy-MM-DD")) {
                                                if (moment(e.target.value).format("yyyy-MM-DD") < moment(addedVesselCrew.start_date).format("yyyy-MM-DD")) {
                                                    setErrorEndDate(`${t("vessel_schedule_detail.error_end_schedule")} ${moment(addedVesselCrew.start_date).format("DD-MM-yyyy")}`);
                                                    isScheduled = 1
                                                } else {
                                                    let crewTmp = listCrewVesselSearched.find(p => p.crew_id === addedVesselCrew.crew_id);
                                                    if (crewTmp) {
                                                        if (moment(e.target.value).format("yyyy-MM-DD") >= moment(crewTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(crewTmp.end_date).format("yyyy-MM-DD")) {
                                                            setErrorEndDate(`${t("vessel_schedule_detail.error_schedule")} ${crewTmp.staff_name} ${t("vessel_schedule_detail.error_schedule2")} ${moment(e.target.value).format("DD-MM-yyyy")}`)
                                                            isScheduled = 1
                                                        } else {
                                                            let scheduleTmp = listAllCrewVessel.filter(p => p.crew_id === addedVesselCrew.crew_id);
                                                            if (scheduleTmp.length > 0) {
                                                                for (let index = 0; index < scheduleTmp.length; index++) {
                                                                    const crewScheduleTmp = scheduleTmp[index];
                                                                    if (moment(e.target.value).format("yyyy-MM-DD") >= moment(crewScheduleTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(crewScheduleTmp.end_date).format("yyyy-MM-DD")) {
                                                                        setErrorEndDate(`${t("vessel_schedule_detail.error_schedule")} ${crewTmp.staff_name} ${t("vessel_schedule_detail.error_schedule2")} ${moment(e.target.value).format("DD-MM-yyyy")}`);
                                                                        isScheduled = 1;
                                                                    }

                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        let scheduleTmp = listAllCrewVessel.filter(p => p.crew_id === addedVesselCrew.crew_id);
                                                        if (scheduleTmp.length > 0) {
                                                            for (let index = 0; index < scheduleTmp.length; index++) {
                                                                const crewScheduleTmp = scheduleTmp[index];
                                                                if (moment(e.target.value).format("yyyy-MM-DD") >= moment(crewScheduleTmp.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(crewScheduleTmp.end_date).format("yyyy-MM-DD")) {
                                                                    setErrorEndDate(`${t("vessel_schedule_detail.error_schedule")} ${crewScheduleTmp.staff_name} ${t("vessel_schedule_detail.error_schedule2")} ${moment(e.target.value).format("DD-MM-yyyy")}`);
                                                                    isScheduled = 1;
                                                                }

                                                            }
                                                        }
                                                    }
                                                    if (isScheduled === 1) {
                                                        setAddedVesselCrew({ ...addedVesselCrew, end_date: null })
                                                    } else {
                                                        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                                                        const firstDate = moment(addedVesselCrew.start_date);
                                                        const secondDate = moment(e.target.value)
                                                        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                                                        let total = Number(totalWorkDays) + Number(diffDays);
                                                        if (total > 180) {
                                                            setErrorWorkDays(`${t("vessel_schedule_detail.error_work_day")}`)
                                                        }
                                                        setAddedVesselCrew({ ...addedVesselCrew, end_date: e.target.value })
                                                        isScheduled = 0;
                                                        setErrorEndDate("")
                                                        // checkScheduleDate(e.target.value)
                                                    }
                                                }

                                            } else {
                                                setErrorEndDate(`${t("vessel_schedule_detail.error_end_date_schedule")} ${moment(vesselSchedule.start_date).format("DD-MM-yyyy")} ${t("vessel_schedule_detail.error_end_date_schedule2")} ${moment(vesselSchedule.end_date).format("DD-MM-yyyy")}`);
                                                setAddedVesselCrew({ ...addedVesselCrew, end_date: null });
                                                setErrorWorkDays("")
                                            }
                                        }

                                        // if (e.target.value === "") {
                                        //     setCrewSchedule({ ...crewSchedule, end_date: null })
                                        // } else {
                                        //     if (moment(e.target.value).format("yyyy-MM-DD") < moment(crewSchedule.start_date).format("yyyy-MM-DD")) {
                                        //         setErrorEndDate('Tanggal Berakhir Tidak Boleh Kurang Dari Tanggal Mulai');
                                        //         setCrewSchedule({ ...crewSchedule, end_date: null });
                                        //     } else {
                                        //         setErrorEndDate("")
                                        //         let errorsDate = 0
                                        //         for (let index = 0; index < listCrewSchedule.length; index++) {
                                        //             const scheduleTmp = listCrewSchedule[index];
                                        //             if ((moment(e.target.value).format("yyyy-MM-DD") >= moment(scheduleTmp.start_date).format("yyyy-MM-DD")) && (moment(e.target.value).format("yyyy-MM-DD") <= moment(scheduleTmp.end_date).format("yyyy-MM-DD"))) {
                                        //                 setErrorEndDate('Jadwal Sudah Terisi');
                                        //                 errorsDate = 1;
                                        //                 setCrewSchedule({ ...crewSchedule, end_date: null });
                                        //             }

                                        //         }
                                        //         if (errorsDate === 0) {
                                        //             setCrewSchedule({ ...crewSchedule, end_date: e.target.value });
                                        //             setErrorEndDate("")
                                        //         }

                                        //     }

                                        // }

                                    }} />

                                {
                                    errorEndDate && <p style={{ color: "red" }}>{errorEndDate}</p>
                                }
                                {
                                    errorWorkDays && addedVesselCrew.end_date !== null && <p style={{ color: "red" }}>{errorWorkDays}</p>
                                }


                                <div style={{ paddingBottom: 10 }}></div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center"
                                }}>
                                    <Button type="submit" disabled={disabledButtonCrew} >{t("vessel_schedule_detail.save_button")}</Button>
                                    <Button className="cancel" variant="danger" onClick={() => {
                                        resetAddVesselCrew();
                                        setAddVesselCrewModal(false);
                                        // setErrorStartDate("");
                                        // setErrorEndDate("");
                                    }}>{t("vessel_schedule_detail.cancel_button")}</Button>
                                </div>

                            </Form>



                        </div>

                    </Modal>

                </Container>
                <Loading
                    loading={loading}
                    loadingText={`${t("loading_component.text")}`}
                />
            </>
        )
    }