import axios from "axios";
import { json } from "react-router-dom";
import Config from "../Config/Config";

export const getSurveyType = async (token, languageId, offset = 0, limit = 50, orderBy, descending = true, searchQuery = undefined) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveyType/findAll`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery !== undefined ? searchQuery: "",
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getSurveyTypeById = async (token, surveyTypeId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveyType/findById`, {
            params: {
                id: surveyTypeId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getSurveyTypeCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveyType/count`, {
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        } else {
            return (0);
        }
    }
    catch (exception) {
        return (0);
    }
}

export const insertUpdateSurveyType = async (token, surveyType) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SurveyType/insertUpdate`, surveyType, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return (false)
        }
    }
    catch (exception) {
        console.log(exception);
        return (false);
    }
}

export const deleteSurveyType = async (token, surveyTypeId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SurveyType/delete`, {
            id: surveyTypeId
        }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (true);
        } else {
            return (false);
        }
    }
    catch (exception) {
        console.log(exception);
        return (false);
    }
}