// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentAnalogPort {
    /* background: tomato; */
    height: 335px;
}

.greyBox {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 450px;
    background-color: rgba(233, 233, 250, 0.1);
    color: white;
    margin: 10px;
    flex: 1 1;
    border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/MainEngine/PortCalibration/index.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,0CAA0C;IAC1C,YAAY;IACZ,YAAY;IACZ,SAAO;IACP,kBAAkB;AACtB","sourcesContent":[".contentAnalogPort {\n    /* background: tomato; */\n    height: 335px;\n}\n\n.greyBox {\n    display: flex;\n    flex-direction: column;\n    height: auto;\n    width: 450px;\n    background-color: rgba(233, 233, 250, 0.1);\n    color: white;\n    margin: 10px;\n    flex: 1;\n    border-radius: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
