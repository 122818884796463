import React, { useState, useEffect, useRef } from "react";
import { Spinner, Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getContractTaskById, getVesselType, getContract, getEquipment,
    insertUpdateContractTask, getContractTaskDetail, getContractById, getUserAccessById
} from "../../../../../Helpers/ApplicationHelpers";
import { getInventoryType, getInventoryTypeSubItem } from "../../../../../Helpers/InventoryHelpers";
import { Editor } from '@tinymce/tinymce-react';
// import Editor from "tinymce";
import "../../../../../App.css";
import { ArrowLeft, EyeFill, Trash, PencilFill } from "react-bootstrap-icons";
import Navbar from "../../../../../Components/NavBar";
import ContainerBox from "../../../../../Components/ContainerBox";
import Loading from "../../../../../Components/Loading";
import FormSelect from "../../../../../Components/FormSelect";
import ButtonForm from "../../../../../Components/ButtonForm";
import Select from 'react-select';
import TextBox from "../../../../../Components/Textbox";
import { omit } from 'lodash';
import { List } from "react-bootstrap-icons/dist";
import { InfoCircleFill, XSquareFill } from "react-bootstrap-icons";
import DatePicker from "../../../../../Components/DatePicker";
import Moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function ContractTaskDetailPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [vesselTypeOptions, setVessselTypeOptions] = useState([]);
    const { t, i18n } = useTranslation();
    const [contractOptions, setContractOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [indexDescription, setIndexDescription] = useState("");
    const [showModalDescription, setShowModalDescription] = useState("");
    const [disabledForm, setDisabledForm] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [listInventory, setListInventory] = useState([]);
    const [disabledButtonDetailJob, setDisableButtonDetailJob] = useState(false);
    const [sumMessage, setSumMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [typeForm, setTypeForm] = useState("");
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [showDetailContract, setShowDetailContract] = useState(false);
    const [errorMessageMinus, setErrorMessageMinus] = useState("");
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCheckList, setShowCheckList] = useState(false);
    const [contract, setContract] = useState({
        id: 0,
        contract_name: "",
        contract_date: null,
        contract_external_number: null,
        company_id: null,
        description: "",
        start_date: null,
        end_date: null,
        contract_reference_number: "",
        status_id: null,

    });
    const [indexDetail, setIndexDetail] = useState("");
    const [jumlah, setJumlah] = useState(0);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [contractTaskId, setContractTaskId] = useState("");
    // const [contractTaskDetail, setContractTaskDetail] = useState([]);
    const [contractId, setContractId] = useState("");
    const [contractTask, setContractTask] = useState({
        id: 0,
        contract_id: null,
        vessel_type_id: [],
        equipment_id: null,
        quality: 0,
        detail_job: [],
        title: "",
        // description: null
        order_number: ""
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setContractTaskId(location.state.contractTaskId);
                setTypeForm(location.state.type)
                loadContract();
                loadEquipment();
                loadVesselType();
                loadInventoryType();
                initContract();
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();

    }, []);

    useEffect(() => {
        if (indexDescription !== "") {
            setShowModalDescription(true);
        }
    }, [indexDescription])

    useEffect(() => {

        if (contractTaskId !== 0 && contractTaskId !== "") {
            initContractTask();
            // setDisabledButton(true);
        } else {
            setContractTask({ ...contractTask, contract_id: location.state.contractId })
        }

    }, [contractTaskId])

    useEffect(() => {
        if (indexDetail !== "")
            setShowCheckList(true);
    }, [indexDetail])


    useEffect(() => {
        validationForm();

        if (contractTask.detail_job.length > 0) {
            handleSum();
            for (let index = 0; index < contractTask.detail_job.length; index++) {
                const detailTask = contractTask.detail_job[index];
                if (detailTask.contract_task_detail_name === "") {
                    // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                    setErrorMessageMinus(`${t("detail_contract_task.error_empty_field")}`)
                    setDisableButtonDetailJob(true);
                    setDisabledButton(true)
                }
                // else if (detailTask.working_tool.length < 1) {
                //     // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                //     setErrorMessageMinus("*Harus Diisi")
                //     setDisableButtonDetailJob(true);
                //     setDisabledButton(true)
                // }
                else {
                    setErrorMessageMinus("");
                    setDisableButtonDetailJob(false);
                    // setDisabledButton(false)
                    validationForm();
                }
            }

        }



    }, [contractTask])



    useEffect(() => {
        if (contractTask.quality !== 0 && contractTask.quality !== "") {
            let total = 0;
            total = Number(contractTask.quality) + Number(location.state.totalQty)

            if (total > 100) {
                setSumMessage(`${t("detail_contract_task.error_total_more_than_100")} ${total}`);
                // setDisableButtonDetailJob(true);
                setDisabledButton(true)
            }
            else if (total < 0) {
                setSumMessage(`${t("detail_contract_task.error_total_less_than_100")} ${total}`);
                // setDisableButtonDetailJob(true);
                setDisabledButton(true);
            } else if (contractTask.quality < 0) {
                setSumMessage(`${t("detail_contract_task.error_quality")}`);
                // setDisableButtonDetailJob(true);
                setDisabledButton(true)
            } else {
                setSumMessage("");
                // setDisableButtonDetailJob(false);
                // setDisabledButton(false);
                validationForm();
            }
        }


    }, [contractTask.quality])

    const loadVesselType = async () => {
        try {
            let response = await getVesselType(cookies.token, cookies.languageId);
            let listVesselType = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_type_name,
                    id: response[i].id

                };
                listVesselType.push(obj);
            }
            setVessselTypeOptions(listVesselType);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const loadEquipment = async () => {
        try {
            let response = await getEquipment(cookies.token);
            let listEquipment = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].equipment_name,
                    id: response[i].id

                };
                listEquipment.push(obj);
            }
            setEquipmentOptions(listEquipment);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const loadInventoryType = async () => {
        try {
            let response = await getInventoryTypeSubItem(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listInventory = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    id: response[i].id,
                    label: response[i].inventory_type_name

                };
                listInventory.push(obj);
            }
            setListInventory(listInventory);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 40,
            // minWidth:400,
            overflowY: "auto"
        }),
    }

    const loadContract = async () => {
        try {
            // setContractTask({ ...contractTask, contract_id: location.state.contractId });
            let response = await getContract(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listContract = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].contract_name,
                    id: response[i].id

                };
                listContract.push(obj);
            }
            setContractOptions(listContract);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const initContractTask = async () => {
        try {
            let response = await getContractTaskById(cookies.token, contractTaskId);
            if (response) {
                setContractTask({
                    ...contractTask,
                    id: typeForm === "duplicate" ? 0 : response.id,
                    contract_id: response.contract_id,
                    vessel_type_id: response.vessel_type,
                    equipment_id: response.equipment_id,
                    quality: response.quality,
                    detail_job: response.detail_job,
                    title: response.title,
                    order_number: response.order_number
                })
            }

            setLoading(false);
            // setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const saveData = async () => {
        try {
            setTypeForm("");
            let response = await insertUpdateContractTask(cookies.token, contractTask, contractTaskId);
            if (response.error_code === 0) {
                alert(`${t("detail_contract_task.alert_success_save_data")}`)
                // if (contractTaskId === 0) {
                setContractTaskId(response.data.id);
                // } else {
                // initContractTask();
                // }


            } else {
                alert(`${t("detail_contract_task.alert_failed_save_data")}`);
                setLoading(false);
                setDisabledButton(false);
            }
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }




    const handleSum = async () => {
        try {
            let total = 0;
            for (let index = 0; index < contractTask.detail_job.length; index++) {
                total += Number(contractTask.detail_job[index].quality);
            }
            if (total > 100) {
                // alert('Total Tidak Boleh Lebih Dari 100');
                setErrorMessage(`${t("detail_contract_task.error_weight_more_than_100")}`);
                setDisabledButton(true);
            } else if (total < 0) {
                // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                setErrorMessage(`${t("detail_contract_task.error_weight_less_than_100")}`)
                setDisabledButton(true);
            } else {
                setErrorMessage("");
                // setDisabledButton(false);
                validationForm();
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = async () => {
        try {
            if (contractTask.contract_id === null || contractTask.contract_id === undefined) {

                setErrors({
                    ...errors,
                    contractMessage: `${t("detail_contract_task.error_empty_field")}`
                });
                setDisabledButton(true);
            }
            // }
            else if (contractTask.vessel_type_id.length < 1) {
                setErrors({
                    ...errors,
                    vesselTypeMessage:`${t("detail_contract_task.error_empty_field")}`
                });
                setDisabledButton(true)
            }
            else if (contractTask.equipment_id === null || contractTask.equipment_id === undefined) {
                setErrors({
                    ...errors,
                    equipmentMessage: `${t("detail_contract_task.error_empty_field")}`
                });
                setDisabledButton(true)
            }
            else if (contractTask.order_number === "" || contractTask.order_number === undefined) {
                setErrors({
                    ...errors,
                    orderNumberMessage: `${t("detail_contract_task.error_empty_field")}`
                });
                setDisabledButton(true)
            }
            else if (contractTask.quality === "") {
                setErrors({
                    ...errors,
                    qualityMessage: `${t("detail_contract_task.error_empty_field")}`
                });
                setDisabledButton(true)
            }
            else {
                let errorMsg = omit(errors, "contractMessage", "vesselTypeMessage", "equipmentMessage", "qualityMessage", "orderNumberMessage");
                setErrors(errorMsg);
                setDisabledButton(false);
            }


        } catch (exception) {
            console.log(exception);

        }
    }

    const initContract = async () => {
        try {
            let response = await getContractById(cookies.token, location.state.contractId);
            if (response) {
                setContract({
                    ...contract,
                    id: response.id,
                    contract_name: response.contract_name,
                    contract_date: response.contract_date,
                    contract_external_number: response.contract_external_number,
                    company_id: response.company_name,
                    description: response.description,
                    start_date: response.start_date,
                    end_date: response.end_date,
                    contract_reference_number: response.contract_reference_number,
                    status_id: response.contract_status_code
                })


            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }


    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/Contract");
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${contract.contract_name}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Contract/ContractTask", { state: { contractId: location.state.contractId } }); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            {
                                location.state.contractId !== 0 &&
                                <div>

                                    <Button variant="secondary" style={{
                                        marginLeft: 5, marginRight: 5
                                    }} onClick={() => {
                                        setShowDetailContract(true);
                                        // initContract()
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><InfoCircleFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("detail_contract_task.button_detail")}</div>
                                        </div>
                                    </Button>
                                </div>

                            }


                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>


                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_contract_task.header_contract_task")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <FormSelect caption={`${t("detail_contract_task.field_contract_name")}`} autoFocusValue={contractTask.contract_id === undefined} placeholder=""
                                                    options={contractOptions.filter(p => p.id === location.state.contractId)} value={contractOptions.find(function (option) {
                                                        return option.id === contractTask.contract_id;
                                                    })} setValueFunction={(e) => {
                                                        setContractTask({ ...contractTask, contract_id: e.id });
                                                    }}
                                                    disabledForm={true}
                                                />

                                                <Form.Group>
                                                    <Form.Label>{t("detail_contract_task.field_vessel_type")}</Form.Label>
                                                    <Select styles={styles} isMulti={true} autoFocus={contractTask.vessel_type_id === undefined} placeholder=""
                                                        clearValue={true} closeMenuOnSelect={false}
                                                        options={vesselTypeOptions} value={contractTask.vessel_type_id} onChange={(e) => {
                                                            setContractTask({ ...contractTask, vessel_type_id: e });
                                                        }} isClearable
                                                        isDisabled={disabledCustomerForm}
                                                    />
                                                </Form.Group>


                                                {
                                                    errors.vesselTypeMessage && <p style={{ color: "red" }}>{errors.vesselTypeMessage}</p>
                                                }

                                                <FormSelect caption={`${t("detail_contract_task.field_equipment_category")}`} autoFocusValue={contractTask.equipment_id === undefined} placeholder=""
                                                    options={equipmentOptions} value={equipmentOptions.filter(function (option) {
                                                        return option.id === contractTask.equipment_id;
                                                    })} setValueFunction={(e) => {
                                                        setContractTask({ ...contractTask, equipment_id: e.id });
                                                    }} disabledForm={disabledCustomerForm}
                                                />
                                                {
                                                    errors.equipmentMessage && <p style={{ color: "red" }}>{errors.equipmentMessage}</p>
                                                }

                                                <TextBox value={contractTask.quality} placeholder=""
                                                    caption={`${t("detail_contract_task.field_bobot")}`} controlType="text" setValueFunction={(e) => {
                                                        if (e.target.value === "") {
                                                            setContractTask({ ...contractTask, quality: 0 });
                                                        } else {
                                                            setContractTask({ ...contractTask, quality: e.target.value.toString().replace(/[^\d]/g, '') });

                                                        }

                                                    }} disabledForm={disabledCustomerForm} />
                                                {
                                                    errors.qualityMessage && <p style={{ color: "red" }}>{errors.qualityMessage}</p>
                                                }

                                                {
                                                    sumMessage && <p style={{ color: "red" }}>{sumMessage}</p>
                                                }
                                                <TextBox value={contractTask.order_number} placeholder=""
                                                    caption={`${t("detail_contract_task.field_order_number")}`} setValueFunction={(e) => {
                                                        if (e.target.value === "") {
                                                            setContractTask({ ...contractTask, order_number: "" });
                                                        } else {
                                                            setContractTask({ ...contractTask, order_number: e.target.value.toString().replace(/[^\d]/g, '') });

                                                        }

                                                    }} disabledForm={disabledCustomerForm} />

                                                {
                                                    errors.orderNumberMessage && <p style={{ color: "red" }}>{errors.orderNumberMessage}</p>
                                                }


                                            </div>

                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_contract_task.header_task_detail")}`} useActionContainer={true}
                                            childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <TextBox disabledForm={disabledCustomerForm} value={contractTask.title} placeholder=""
                                                        caption={`${t("detail_contract_task.field_job_name")}`} controlType="text" setValueFunction={(e) => {
                                                            setContractTask({ ...contractTask, title: e.target.value });
                                                        }} />

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap"

                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "nowrap"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                            }}>#</div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 8,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center"
                                                            }}>{t("detail_contract_task.table_name1")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 8,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center"
                                                            }}>{t("detail_contract_task.table_name2")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 3,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center"
                                                            }}>{t("detail_contract_task.table_name3")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 2,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center"
                                                            }}>{t("detail_contract_task.table_name4")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 2,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center"
                                                            }}>{t("detail_contract_task.table_name5")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 2,
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center"
                                                            }}>{t("detail_contract_task.table_name6")}</div>
                                                        </div>
                                                        {
                                                            contractTask.detail_job && contractTask.detail_job.map((detailJob, index) => {
                                                                return (
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center"
                                                                        }}>{index + 1}</div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center"
                                                                        }}><Form.Control disabled={disabledCustomerForm} onChange={(e) => {
                                                                            let detailJob = contractTask.detail_job;
                                                                            detailJob[index].contract_task_detail_name = e.target.value;
                                                                            detailJob[index].order_number = index + 1
                                                                            setContractTask({ ...contractTask, detail_job: detailJob });
                                                                        }} type="text" value={detailJob.contract_task_detail_name} required placeholder=""></Form.Control></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            flexDirection: "column",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center",
                                                                            width: "100%",
                                                                        }}>

                                                                            <Select styles={styles} isMulti={true} autoFocus={detailJob.working_tool === undefined} placeholder=""
                                                                                clearValue={true} closeMenuOnSelect={false}
                                                                                options={listInventory} value={detailJob.working_tool} onChange={(e) => {
                                                                                    let detailJob = contractTask.detail_job;
                                                                                    detailJob[index].working_tool = e;
                                                                                    setContractTask({ ...contractTask, detail_job: detailJob });
                                                                                }} isClearable
                                                                                isDisabled={disabledCustomerForm}
                                                                            />

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 3,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center"
                                                                        }}>  <Form.Control disabled={disabledCustomerForm} onChange={(e) => {
                                                                            let detailJob = contractTask.detail_job;
                                                                            if (e.target.value === "") {
                                                                                detailJob[index].quality = 0
                                                                            } else {
                                                                                detailJob[index].quality = e.target.value.toString().replace(/[^\d]/g, '');
                                                                            }

                                                                            setContractTask({ ...contractTask, detail_job: detailJob });
                                                                        }} type="text" value={detailJob.quality} required placeholder=""></Form.Control></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 2,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center"
                                                                        }}> <Button onClick={() => {
                                                                            // setIndexDetail(index);
                                                                            setIndexDescription(index)

                                                                        }} style={{ maxWidth: 50 }} variant="primary"><PencilFill /></Button></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 2,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center"
                                                                        }}> <Button onClick={() => {
                                                                            setIndexDetail(index);

                                                                        }} style={{ maxWidth: 50 }} variant="primary"><PencilFill /></Button></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 2,
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10,
                                                                            justifyContent: "center"
                                                                        }}> <Button disabled={disabledCustomerForm === true ? true : !deletedButton} onClick={() => {
                                                                            if (window.confirm(`${t("detail_contract_task.delete_confirmation")}`)) {
                                                                                let list = [...contractTask.detail_job];
                                                                                list.splice(index, 1);
                                                                                setContractTask({ ...contractTask, detail_job: list });
                                                                            }

                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button></div>


                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5,
                                                        flex: 2,
                                                    }}>
                                                        <Button variant="primary" disabled={disabledCustomerForm === true ? true : disabledButtonDetailJob} onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                contract_task_detail_name: "",
                                                                working_tool: [],
                                                                quality: 0,
                                                                order_number: "",
                                                                // title: "",
                                                                working_list: null,
                                                                description: ""
                                                            };
                                                            if (!contractTask.detail_job) {
                                                                let detailJob = [];
                                                                detailJob.push(newParameter);
                                                                setContractTask({ ...contractTask, detail_job: detailJob });
                                                            } else {
                                                                let detailJob = contractTask.detail_job;
                                                                detailJob.push(newParameter);
                                                                setContractTask({ ...contractTask, detail_job: detailJob });
                                                            }

                                                        }}>{t("detail_contract_task.button_add_detail")}</Button>
                                                    </div>

                                                </div>

                                            }>
                                        </ContainerBox>

                                    </div>
                                </div>



                            </div>



                            <ButtonForm addData={() => {
                                setDisabledButton(true)
                                setLoading(true);
                                setContractTaskId("")
                                saveData();
                            }}
                                saveButton={`${t("button_component.save_button")}`}
                                cancelButton={`${t("button_component.cancel_button")}`}
                                path={"/ContractTask"} button={disabledButton === true || disabledCustomerForm === true ? true : !updatedButton} />
                        </div>

                    }>


                </ContainerBox>

                <Modal size="xl" show={showCheckList} onHide={
                    () => {
                        setShowCheckList(false);
                        setIndexDetail("")
                    }
                }>
                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_contract_task.modal_add_sop")}`} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5
                                    }}>

                                      

                                    </div>
                                    {
                                        contractTask.detail_job && contractTask.detail_job.map((detailJob, index) => {
                                            return (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5,
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 3,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        {/* <Form.Control onChange={(e) => {
                                                            let detailJob = contractTask.detail_job;
                                                            detailJob[index].contract_task_detail_name = e.target.value;
                                                            detailJob[index].order_number = index + 1
                                                            setContractTask({ ...contractTask, detail_job: detailJob });
                                                        }} type="text" value={detailJob.contract_task_detail_name} required placeholder="Masukkan Detail Pekerjaan"></Form.Control> */}
                                                        {
                                                            index === indexDetail &&
                                                            // console.log(index,indexDetail)
                                                            <Editor
                                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                                onEditorChange={(evt, editor) => {
                                                                    let detailJob = contractTask.detail_job;
                                                                    detailJob[indexDetail].working_list = evt;
                                                                    setContractTask({ ...contractTask, detail_job: detailJob });
                                                                }}
                                                                value={detailJob.working_list}
                                                                init={{
                                                                    height: 500,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                    ],
                                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  | image code fullscreen',
                                                                    image_title: true,
                                                                    automatic_uploads: true,
                                                                    /*
                                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                      images_upload_url: 'postAcceptor.php',
                                                                      here we add custom filepicker only to Image dialog
                                                                    */
                                                                    file_picker_types: 'image',
                                                                    /* and here's our custom image picker*/
                                                                    file_picker_callback: (cb, value, meta) => {
                                                                        const input = document.createElement('input');
                                                                        input.setAttribute('type', 'file');
                                                                        input.setAttribute('accept', 'image/*');

                                                                        input.addEventListener('change', (e) => {
                                                                            const file = e.target.files[0];
                                                                            const reader = new FileReader();
                                                                            reader.addEventListener('load', () => {
                                                                                /*
                                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                                  registry. In the next release this part hopefully won't be
                                                                                  necessary, as we are looking to handle it internally.
                                                                                */

                                                                                // console.log(editorRef.current.getContent())
                                                                                const id = 'blobid' + (new Date()).getTime();
                                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                                //   editorRef.current.editorUpload.blobCache

                                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                                const base64 = reader.result.split(',')[1];
                                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                                blobCache.add(blobInfo);

                                                                                /* call the callback and populate the Title field with the file name */
                                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                                            });
                                                                            reader.readAsDataURL(file);
                                                                        });

                                                                        input.click();
                                                                    },
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        }

                                                    </div>

                                                </div>
                                            );
                                        })
                                    }
                                </div>



                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <Button  variant="primary" onClick={() => {
                                        setShowCheckList(false);
                                        setIndexDetail("");

                                    }}>{t("detail_contract_task.save_button")}</Button>
                                    <div style={{ paddingBottom: 10 }}></div>
                                    <Button  variant="danger" onClick={() => {
                                        let detailJob = contractTask.detail_job;
                                        detailJob[indexDetail].working_list = null;
                                        setContractTask({ ...contractTask, detail_job: detailJob });
                                        setShowCheckList(false);

                                    }}>{t("detail_contract_task.cancel_button")}</Button>
                                </div>

                            </div>

                        }>
                    </ContainerBox>
                </Modal>

                <Modal size="xl" show={showModalDescription} onHide={
                    () => {
                        setShowModalDescription(false);
                        setIndexDescription("")
                    }
                }>
                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_contract_task.modal_add_description")}`} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5
                                    }}>

                                 
                                    </div>
                                    {
                                        contractTask.detail_job && contractTask.detail_job.map((detailJob, index) => {
                                            return (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5,
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 3,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        {/* <Form.Control onChange={(e) => {
                                                            let detailJob = contractTask.detail_job;
                                                            detailJob[index].contract_task_detail_name = e.target.value;
                                                            detailJob[index].order_number = index + 1
                                                            setContractTask({ ...contractTask, detail_job: detailJob });
                                                        }} type="text" value={detailJob.contract_task_detail_name} required placeholder="Masukkan Detail Pekerjaan"></Form.Control> */}
                                                        {
                                                            index === indexDescription &&
                                                            // console.log(index,indexDetail)
                                                            <Editor
                                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                                onEditorChange={(evt, editor) => {
                                                                    let detailJob = contractTask.detail_job;
                                                                    detailJob[indexDescription].description = evt;
                                                                    setContractTask({ ...contractTask, detail_job: detailJob });
                                                                }}
                                                                value={detailJob.description}
                                                                init={{
                                                                    height: 500,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                    ],
                                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  | image code fullscreen',
                                                                    image_title: true,
                                                                    automatic_uploads: true,
                                                                    /*
                                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                      images_upload_url: 'postAcceptor.php',
                                                                      here we add custom filepicker only to Image dialog
                                                                    */
                                                                    file_picker_types: 'image',
                                                                    /* and here's our custom image picker*/
                                                                    file_picker_callback: (cb, value, meta) => {
                                                                        const input = document.createElement('input');
                                                                        input.setAttribute('type', 'file');
                                                                        input.setAttribute('accept', 'image/*');

                                                                        input.addEventListener('change', (e) => {
                                                                            const file = e.target.files[0];
                                                                            const reader = new FileReader();
                                                                            reader.addEventListener('load', () => {
                                                                                /*
                                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                                  registry. In the next release this part hopefully won't be
                                                                                  necessary, as we are looking to handle it internally.
                                                                                */

                                                                                // console.log(editorRef.current.getContent())
                                                                                const id = 'blobid' + (new Date()).getTime();
                                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                                //   editorRef.current.editorUpload.blobCache

                                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                                const base64 = reader.result.split(',')[1];
                                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                                blobCache.add(blobInfo);

                                                                                /* call the callback and populate the Title field with the file name */
                                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                                            });
                                                                            reader.readAsDataURL(file);
                                                                        });

                                                                        input.click();
                                                                    },
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        }

                                                    </div>

                                                </div>
                                            );
                                        })
                                    }
                                </div>



                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <Button variant="primary" onClick={() => {
                                        setShowModalDescription(false);
                                        setIndexDescription("");

                                    }}>{t("detail_contract_task.save_button")}</Button>
                                    <div style={{ paddingBottom: 10 }}></div>
                                    <Button variant="danger" onClick={() => {
                                        let detailJob = contractTask.detail_job;
                                        detailJob[indexDescription].description = "";
                                        setContractTask({ ...contractTask, detail_job: detailJob });
                                        setShowModalDescription(false);

                                    }}>{t("detail_contract_task.cancel_button")}</Button>
                                </div>

                            </div>

                        }>
                    </ContainerBox>
                </Modal>

                <Modal size="xl" show={showDetailContract} onHide={
                    () => {
                        setShowDetailContract(false);

                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={`${t("detail_contract_task.modal_contract_detail")}`}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowDetailContract(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>{t("detail_contract_task.close_button")}</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={

                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <TextBox value={contract.contract_name} placeholder=""
                                            caption={`${t("detail_contract_task.field_modal_contract_name")}`} setValueFunction={(e) => {
                                                setContract({ ...contract, contract_name: e.target.value });
                                            }} disabledForm={true} />
                                    </div>

                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <TextBox value={contract.company_id} placeholder=""
                                            caption={`${t("detail_contract_task.field_modal_company_name")}`} setValueFunction={(e) => {
                                                setContract({ ...contract, company_id: e.target.value });
                                            }} disabledForm={true} />
                                    </div>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <DatePicker disabled={true} caption={`${t("detail_contract_task.field_modal_contract_date")}`} placeholder="" onDateChange={(date) => {
                                            setContract({ ...contract, contract_date: new Date(date) });
                                        }} value={Moment(contract.contract_date).isValid() ? Moment(contract.contract_date).toDate() : ""} />
                                    </div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <DatePicker disabled={true} caption={`${t("detail_contract_task.field_modal_contract_start_date")}`} placeholder="" onDateChange={(date) => {
                                            setContract({ ...contract, start_date: new Date(date) });
                                        }} value={Moment(contract.start_date).isValid() ? Moment(contract.start_date).toDate() : ""} />
                                    </div>
                                    <div style={{ width: "100%", padding: 5 }}>

                                        <DatePicker disabled={true} caption={`${t("detail_contract_task.field_modal_contract_end_date")}`} placeholder="" onDateChange={(date) => {
                                            setContract({ ...contract, end_date: new Date(date) });
                                        }} value={Moment(contract.end_date).isValid() ? Moment(contract.end_date).toDate() : ""} />
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <TextBox value={contract.contract_external_number} placeholder=""
                                            caption={`${t("detail_contract_task.field_contract_number")}`} setValueFunction={(e) => {
                                                setContract({ ...contract, contract_external_number: e.target.value });
                                            }} disabledForm={true} />
                                    </div>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <TextBox value={contract.contract_reference_number} placeholder=""
                                            caption={`${t("detail_contract_task.field_contract_reference_number")}`} setValueFunction={(e) => {
                                                setContract({ ...contract, contract_reference_number: e.target.value });
                                            }} disabledForm={true} />
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <Form.Group>
                                            <TextBox value={contract.status_id} placeholder=""
                                                caption={`${t("detail_contract_task.field_contract_status")}`} setValueFunction={(e) => {
                                                    setContract({ ...contract, status_id: e.target.value });
                                                }} disabledForm={true} />

                                        </Form.Group>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <div style={{ width: "100%", padding: 5 }}>
                                        <TextBox value={contract.description} placeholder=""
                                            caption={`${t("detail_contract_task.field_description")}`} setValueFunction={(e) => {
                                                setContract({ ...contract, description: e.target.value });
                                            }} asType="textarea" disabledForm={true} />
                                    </div>


                                </div>
                            </div>
                        }>
                    </ContainerBox>
                </Modal>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />


        </>
    )
}