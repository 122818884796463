import "./index.css";
import Navbar from "../../../Components/NavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import SMSNavContainer from "../../../Components/SMSNavContainer/SMSNavContainer";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getPLCReadingValueForConnectionStatus } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import CustomerNavigatonBar from "../../../Components/CustomerNavBar";
import CustomerSMSMainEnginePage from "../SMSMainEngine";
import CustomerSMSAuxEnginePage from "../SMSAuxEngine";
import CustomerSMSGearBoxPage from "../SMSGearBox";
import CustomerSMSPumpMotorPage from "../SMSPumpMotor";
// import CustomerSMSTankLevelPage from "../SMSTankLevel";
import CustomerSMSTankLevelPage from "../SMSTankLevelTmp";
import CustomerSMSWatertightDoorPage from "../SMSWatertightDoor";
import CustomerSMSMainSwitchBoardPage from "../SMSMSB";
import CustomerSMSNavLightpage from "../SMSNavLight";
import CustomerSMSBilgesPage from "../SMSBilges";
import CustomerSMSLocationPage from "../SMSLocation";
import CustomerSMSFireAlarmPage from "../SMSFireAlarm";
import CustomerSMSAlarmEventHistory from "../SMSAlarmEventHistory";
import CustomerSMSMainMenu from "../SMSMainMenu";
// import LampNavButton from "../../../Components/NewLampNavButton/NewLampNavButton";
import NewSMSNavContainer from "../../../Components/NewSMSNavContainer/NewSMSNavContainer"
import NewSMSNavContainerRight from "../../../Components/NewSMSNavContainerRight/NewSMSNavContainerRight"
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";
import Clock from 'react-live-clock';

export default function CustomerSMSDashboardPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [currentChild, setCurrentChild] = useState(null);
    const [firstDetailPort, setFirstDetailPort] = useState('');
    const [secondDetailPort, setSecondDetailPort] = useState("");
    const [titlePort, setTitlePort] = useState('');
    const [titleSTDB, setTitleSTBD] = useState('');
    const [firstDetailSTBD, setFirstDetailSTBD] = useState('');
    const [secondDetailSTBD, setSecondDetailSTBD] = useState("");
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [titleBar, setTitleBar] = useState("");
    const [connectionStatus, setConnectionStatus] = useState([]);

    useEffect(() => {
        async function checkCookies() {
          let isAuthenticated = await isTokenValid(cookies.token);
          if (!isAuthenticated)
            navigate("/");
          else {
            // console.log(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
          }
        }
        checkCookies();
        console.log(cookies);
        // console.log(vesselId);
    }, []);

    useEffect(() => {
        if (smsSerialNumber !== "") {
          setCurrentChild(<CustomerSMSMainMenu smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
        }
    }, [smsSerialNumber]);

    useEffect(()=>{
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])
    
    useEffect(()=>{
        if (smsSerialNumber !== "") {
            loadConnection();
            console.log('SMS SERIAL NUMBER: ', smsSerialNumber);
            console.log('Customer Name: ', customerName);
            console.log('Vessel Name: ', vesselName);
            console.log('Vessel ID: ', vesselId);
            switch (activeTab) {
                case 0:
                    setCurrentChild(<CustomerSMSMainMenu smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]}/>);
                    setTitleBar("Main Menu");
                    setFirstDetailPort("TB PRIME 201");
                    setSecondDetailPort("XXXXXXX");
                    break;
                case 1:
                    setCurrentChild(<CustomerSMSMainEnginePage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]}/>);
                    setTitleBar("Main Engine");
                    setTitlePort('PORT');
                    setTitleSTBD('STARBOARD');
                    setFirstDetailPort("SDEC SC38W1000.12CA1");
                    setSecondDetailPort("S/N: W9239000695");
                    setFirstDetailSTBD("SDEC SC38W1000.12CA1");
                    setSecondDetailSTBD("S/N: W9239000697");
                    break;
                case 2:
                    setCurrentChild(<CustomerSMSGearBoxPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]}/>);
                    setTitleBar("Gearbox");
                    setTitlePort('PORT');
                    setTitleSTBD('STARBOARD');
                    setFirstDetailPort("GEARBOX [PORT]");
                    setSecondDetailPort("HANGZHOU FADA GBG [S/N: 2312198]");
                    setFirstDetailSTBD("GEARBOX [STARBOARD]");
                    setSecondDetailSTBD("HANGZHOU FADA GBG [S/N: 2312196]");
                    break;
                case 3:
                    setCurrentChild(<CustomerSMSAuxEnginePage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]}/>);
                    setTitleBar("Auxiliary Engine");
                    setTitlePort('PORT');
                    setTitleSTBD('STARBOARD');
                    setFirstDetailPort("SDEC SC4H95CF2");
                    setSecondDetailPort("S/N: H923A023217");
                    setFirstDetailSTBD("SDEC SC4H95CF2");
                    setSecondDetailSTBD("S/N: H923A023223");
                    break;
                case 4:
                    setCurrentChild(<CustomerSMSPumpMotorPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={'AI'} idCoordinateGroup={[1]}/>);
                    setTitleBar("Pump and Motor");
                    setTitleBar("Auxiliary Engine");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                case 5:
                    setCurrentChild(<CustomerSMSTankLevelPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]}/>);
                    setTitleBar("Tank Level");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                case 6:
                    setCurrentChild(<CustomerSMSMainSwitchBoardPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} coordinateType={''} idCoordinateGroup={[]}/>);
                    setTitleBar("Main Switchboard");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                case 7:
                    setCurrentChild(<CustomerSMSWatertightDoorPage smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
                    setTitleBar("Common Alarm");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                case 8:
                    setCurrentChild(<CustomerSMSFireAlarmPage smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
                    setTitleBar("Fire Alarm");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                case 9:
                    setCurrentChild(<CustomerSMSNavLightpage smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
                    setTitleBar("Navigation Light");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                case 10:
                    setCurrentChild(<CustomerSMSAlarmEventHistory smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
                    setTitleBar("Alarm Event History");
                    setTitlePort('');
                    setTitleSTBD('');
                    setFirstDetailPort("");
                    setSecondDetailPort("");
                    setFirstDetailSTBD("");
                    setSecondDetailSTBD("");
                    break;
                // case 11:
                //     setCurrentChild(<CustomerSMSLocationPage smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName}/>);
                //     setTitleBar("Vessel Location");
                //     break;
            }
        }
    }, [activeTab])
    
    useEffect(()=>{
        console.log('connectionStatus', connectionStatus);
     }, [connectionStatus])

    const handleMenuClick = (val) => {
        console.log(val);
        setActiveTab(val);
    }

    const loadConnection = async () => {
        try{
            let response = await getPLCReadingValueForConnectionStatus(cookies.token, smsSerialNumber, vesselId);
            setConnectionStatus(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>{
            vesselId ? (<>
                <div className="mainPageDashboardRes">
                    <div className="mainPageDashboardLeft">
                        <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                        <NewSMSNavContainer handleMenuClick={handleMenuClick}
                            activeTab={activeTab}
                            childContent={currentChild ? currentChild : null}
                            smsSerialNumber={smsSerialNumber} />
                    </div>
                    <div className="mainPageDashboardMid">
                        <div className="mainPageDashboardMidTop">
                            <div className="mainPageDashboardMidTop-1">
                                <Clock
                                    format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                                    ticking={true}
                                    timezone={'Asia/Jakarta'}
                                    style={{ color: '#f1f5f9', fontSize: 'medium', fontFamily: 'Disket Mono', margin: '10px 10px' }}
                                />
                                <p style={{ color: '#f1f5f9', fontSize: 'medium', fontFamily: 'Disket Mono', margin: '10px 10px' }}>{'V. 1. 1. 0. 0'}</p>
                            </div>
                            {
                                activeTab === 0 ? (
                                    <div className="titleGaugeLeftOnDashboard">
                                        <div style={{ display: 'flex', width: '200px', justifyContent: 'space-between' }}>
                                            <h1 className="txtforTitleOnMainEngineMainMenu">SHIP</h1>
                                            <h1 className="txtforDetailOnMainEngineMainMenu">TB PRIME 201</h1>
                                        </div>
                                        <div style={{ display: 'flex', width: '200px', justifyContent: 'space-between' }}>
                                            <h1 className="txtforTitleOnMainEngineMainMenu">IMO</h1>
                                            <h1 className="txtforDetailOnMainEngineMainMenu">XXXXXXX</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mainPageDashboardMidTop-2">
                                        <div className="titleGaugeLeftOnDashboard">
                                            <h1 className="txtforTitleOnDashboard">{titlePort}</h1>
                                            <h1 className="txtforDetailOnDashboard">{firstDetailPort}</h1>
                                            <h1 className="txtforDetailOnDashboard">{secondDetailPort}</h1>
                                        </div>
                                        <div className="titleGaugeRightOnDashboard">
                                            <h1 className="txtforTitleOnDashboard">{titleSTDB}</h1>
                                            <h1 className="txtforDetailOnDashboard">{firstDetailSTBD}</h1>
                                            <h1 className="txtforDetailOnDashboard">{secondDetailSTBD}</h1>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        <div className="mainPageDashboardMidBottom">
                            {currentChild}
                        </div>
                    </div>
                    <div className="mainPageDashboardRight">
                        <NewSMSNavContainerRight
                            // handleMenuClick={handleMenuClick}
                            activeTab={activeTab}
                            childContent={currentChild ? currentChild : null}
                            smsSerialNumber={smsSerialNumber}
                            connectionStatus={connectionStatus} />
                    </div>
                </div>
                <Loading loading={false} />
            </>) : (<></>)
        }
            
        </>
    )
}