import "./SwitchToggle.css";

export default function SwitchToggle() {
    return (
        <label className="toggle">
            <input type="checkbox" />
            <span className="slider"></span>
            <span className="labels" data-on="ON" data-off="OFF"></span>
        </label>
    );
}